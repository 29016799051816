<template>
  <MaskView
    border-style="1px solid #eee"
    outer-border-style="1px dashed #02a7f0"
  >
    <div class="vo-column w-100" ref="historyWrap">
      <!-- <div v-if="showFillLike" class="vo-row justify-content-end mt-2">
        <el-image :src="require('@/assets/images/brand/ic_like_fill.svg')" />
      </div> -->
      <HistoryCurve
        :width="curveWidth"
        :power="power"
        :resistance="1.2"
        :max-power="12"
      />

      <div class="ml-1 mr-1 mt-2">
        <div>{{ curveName }}</div>
        <div v-if="showFillLike > 1">{{ description }}</div>
      </div>
    </div>
    <template #top>
      <div class="vo-row align-items-center vo-gap-4 justify-content-end p-2">
        <el-image
          v-if="showViewBtn == 1"
          :src="require('@/assets/images/brand/ic_eyes.svg')"
          @click="$emit('clickView')"
        />
        <el-image
          v-if="showFillLike == 0"
          @click="$emit('clickStatus')"
          :src="require('@/assets/images/brand/ic_like.svg')"
        />
        <el-image
          v-if="showFillLike == 1"
          @click="$emit('clickStatus')"
          :src="require('@/assets/images/brand/ic_like_fill.svg')"
        />
        <el-image
          v-has="'curvesMgmt::Modify'"
          @click="$emit('clickCurveEdit')"
          :src="require('@/assets/images/brand/ic_brand_edit.svg')"
        />
      </div>
    </template>
  </MaskView>
</template>


<script>
import MaskView from "@/components/MaskView.vue";
import HistoryCurve from "@/components/HistoryCurve.vue";

export default {
  name: "CurveItem",
  components: { HistoryCurve, MaskView },
  props: {
    power: {
      type: Array,
      default: () => [],
    },
    curveName: {
      type: String,
      default: "",
    },
    showFillLike: {
      type: Number,
      default: 0,
    },
    description: {
      type: String,
      default: "",
    },
    showViewBtn: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      curveWidth: "0px",
    };
  },
  methods: {
    //按钮权限判断
    btnMenu(value) {
      return this.$_has(value);
    },
    //账户层级
    isAccountLevel() {
      return localStorage.accountLevel == 0;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      if (this.$refs.historyWrap) {
        this.curveWidth = `${this.$refs.historyWrap.clientWidth}px`;
      }
    });
    this.curveWidth = `${this.$refs.historyWrap.clientWidth}px`;
  },
};
</script>

<style scoped lang="scss">
</style>