<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="4">
        <b-card class="text-center">
          <h3>
            {{ selectUserReport.totalUsers }}
            <!-- <i class="ion ion-md-arrow-round-up text-success"></i> -->
          </h3>
          <p>Total Users</p>
        </b-card>
      </b-col>
      <b-col lg="4">
        <b-card class="text-center">
          <h3>
            {{ selectUserReport.todayNewUsers }}
            <!-- <i class="ion ion-md-arrow-round-up text-success"></i> -->
          </h3>
          <p>Today New Users</p>
        </b-card>
      </b-col>
      <b-col lg="4" class="text-center">
        <b-card>
          <h3>
            {{ selectUserReport.activeUsers }}
            <!-- <i class="ion ion-md-arrow-round-up text-success"></i> -->
          </h3>
          <p>Active Users</p>
        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <b-row>
        <b-col lg="6">
          <div class="button-items">
            <b-button variant="outline-primary" @click="get_user(1, ['', ''])"
              >Today</b-button
            >
            <b-button variant="outline-primary" @click="get_user(2, ['', ''])"
              >Yesterday</b-button
            >
            <b-button variant="outline-primary" @click="get_user(3, ['', ''])"
              >Last Week</b-button
            >
            <b-button variant="outline-primary" @click="get_user(4, ['', ''])"
              >Last Months</b-button
            >
          </div>
        </b-col>
        <b-col lg="6">
          <b-form-group
            label="Select Time"
            label-cols-md="6"
            label-align-md="right"
            label-for="select-time"
          >
            <date-picker
              v-model="selectTime"
              format="MM/DD/YYYY"
              range
              append-to-body
              lang="en"
              placeholder="Select date"
              @change="get_user('', selectTime)"
            ></date-picker>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-card title="Active Users" style="height: 340px">
            <chartist
              ratio="ct-chart wid"
              :data="activeUsersLineChart.data"
              :options="usre_options"
              type="Line"
            ></chartist>
          </b-card>
        </b-col>
        <b-col lg="6">
          <b-card title="New Users" style="height: 340px">
            <chartist
              ratio="ct-chart wid"
              :data="newUsersLineChart.data"
              :options="usre_options"
              type="Line"
            ></chartist>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="8">
          <b-card title="User distribution">
            <b-row>
              <!-- <b-col md="12">
                <b-card title="Age distribution">
                  <chartist
                    ratio="ct-chart wid"
                    :data="ageUsersBarChart.data"
                    :options="ageUsersBarChart.options"
                    type="Bar"
                  ></chartist>
                </b-card>
              </b-col> -->
              <b-col md="12">
                <b-card title="Age distribution">
                  <apexchart
                    class="apex-charts"
                    ref="ageUsersBarChart"
                    dir="ltr"
                    type="bar"
                    height="300"
                    :series="basicColumChart.series"
                    :options="basicColumChart.chartOptions"
                  ></apexchart>
                </b-card>
              </b-col>
              <b-col md="12">
                <b-card title="Mobile phone model distribution">
                  <apexchart
                    class="apex-charts"
                    height="320"
                    type="pie"
                    :series="simplePieChart.series"
                    :options="simplePieChart.chartOptions"
                  ></apexchart>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <!-- 用户地区分布 -->
        <b-col lg="4">
          <b-card title="Regional distribution of users">
            <div class="table-responsive mb-0">
              <b-table
                show-empty
                empty-text="No records"
                id="region-table"
                head-variant="light"
                striped
                :fields="fields"
                :items="provider"
                :busy="isBusy"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

                <template v-slot:cell(ranking)="data">
                  <span
                    class="badge"
                    :class="{
                      'badge-danger': data.value === 1,
                      'badge-warning': data.value === 2,
                      'badge-success': data.value === 3,
                    }"
                    >{{ data.value }}</span
                  >
                </template>
              </b-table>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <!-- end main -->
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import DatePicker from "vue2-datepicker";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

import {
  activeUsersLineChart,
  newUsersLineChart,
  ageUsersBarChart,
  simplePieChart,
  basicColumChart,
} from "./data-user";

export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    timeZoneConversion,
  },
  data() {
    return {
      isBusy: false,
      title: "User Analysis",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Consumption Report",
          // href: "/"
        },
        {
          text: "User Analysis",
          active: true,
        },
      ],
      activeUsersLineChart: activeUsersLineChart,
      newUsersLineChart: newUsersLineChart,
      ageUsersBarChart: ageUsersBarChart,
      basicColumChart: basicColumChart,
      fields: [
        { key: "ranking", label: "#", class: "text-center" },
        { key: "region", label: "Region", class: "text-center" },
        { key: "pacent", label: "Pacent", class: "text-center" },
      ],
      selectTime: [],
      simplePieChart: simplePieChart,
      search_condition: {
        createTime: "",
        endTime: "",
      },
      usre_options: {
        fullWidth: true,
        height: 300,
      },
      selectUserReport: {
        activeUsers: "",
        todayNewUsers: "",
        totalUsers: "",
      },
    };
  },
  watch: {
    // selectTime: function(val) {
    //   this.search_condition.createTime = val[0];
    //   this.search_condition.endTime = val[1];
    // },
  },

  computed: {},
  created() {
    this.getSelectUserReport();
    this.getSelectUserMobilePhoneList();
    this.getAgeDistributionList();
    this.get_user(1, []);
  },
  mounted() {},
  methods: {
    getSelectUserReport() {
      this.$api.Statistics.selectUserReport({
        supplierId: localStorage.supplierId,
      }).then((res) => {
        if (res.success) {
          this.selectUserReport = res.data;
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    getSelectUserMobilePhoneList() {
      this.simplePieChart.chartOptions.labels = [];
      this.$api.Statistics.selectUserMobilePhoneList({
        supplierId: localStorage.supplierId,
      }).then((res) => {
        if (res.success) {
          this.simplePieChart.series = [];
          res.data.forEach((item) => {
            this.simplePieChart.chartOptions.labels.push(item.mobilePhoneBrand);
            this.simplePieChart.series.push(item.brandCount);
          });
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    getAgeDistributionList() {
      this.$api.Statistics.ageDistributionList({
        supplierId: localStorage.supplierId,
      }).then((res) => {
        if (res.success) {
          // let series = [];
          // this.ageUsersBarChart.data.labels = [];
          this.basicColumChart.chartOptions.xaxis.categories = [];
          this.basicColumChart.series[0].data = [];
          res.data.forEach((item) => {
            // this.ageUsersBarChart.data.labels.push(item.name);
            // series.push(item.count);
            this.basicColumChart.chartOptions.xaxis.categories.push(item.name);
            this.basicColumChart.series[0].data.push(item.count);
          });
          // this.ageUsersBarChart.data.series = [series];
          this.$refs.ageUsersBarChart.refresh();
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    provider() {
      this.isBusy = true;
      return this.$api.Statistics.user_region({ type: 1 }).then((res) => {
        this.isBusy = false;
        if (res.success) {
          return res.data;
        } else {
          if (res.message) {
            this.$toast(res);
          }
          return [];
        }
      });
    },
    get_user(status, selectTime) {
      //时间范围三个月内
      if (selectTime[0] && selectTime[1]) {
        let three = 90 * 24 * 3600 * 1000;
        let date = new Date(selectTime[1]).getTime();
        let threeMonths = date - three;
        if (threeMonths > new Date(selectTime[0]).getTime()) {
          this.$bvToast.toast("Please select the time frame within 3 months");
          return;
        }
      }

      let d = {
        supplierId: localStorage.supplierId,
        status: status,
        startTime: selectTime[0]
          ? timeZoneConversion.queryTime(selectTime[0])
          : "",
        endTime: selectTime[1]
          ? timeZoneConversion.queryTime(
              selectTime[1].getTime() + 24 * 3600 * 1000 - 1
            )
          : "",
      };
      this.$api.Statistics.activeReportList(d).then((res) => {
        if (res.success) {
          this.activeUsersLineChart.data.labels = [];
          let series = [];
          this.newUsersLineChart.data.labels = [];
          let newSeries = [];
          res.data.activeReport.forEach((item) => {
            this.activeUsersLineChart.data.labels.push(item.timeGroup);
            series.push(item.count);
          });
          this.activeUsersLineChart.data.series = [series];
          res.data.newReport.forEach((item) => {
            this.newUsersLineChart.data.labels.push(item.timeGroup);
            newSeries.push(item.count);
          });
          this.newUsersLineChart.data.series = [newSeries];
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.ct-chart.wid {
  svg.ct-chart-line {
    // position: absolute !important;
    overflow: inherit !important;
    .ct-end {
      white-space: nowrap !important;
    }
  }
}
</style>
