var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "8" } },
                [
                  _c(
                    "b-form",
                    { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Voltage Curve Name:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "heating-name",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "heating-name",
                              placeholder: "Please enter 4 to 20 characters",
                              trim: "",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('');",
                              onblur:
                                "if(value.length<4||value.length>20)value=''"
                            },
                            model: {
                              value: _vm.form.modeName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "modeName", $$v)
                              },
                              expression: "form.modeName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticStyle: { display: "none" },
                          attrs: {
                            label: "Heating Mode Type:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "heatingType",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-radio-group", {
                            staticStyle: { "margin-top": "5px" },
                            attrs: {
                              id: "heatingType",
                              options: _vm.types.heatingTypes,
                              name: "heatingType",
                              required: ""
                            },
                            nativeOn: {
                              change: function($event) {
                                return _vm.changeHeatingType(
                                  _vm.form.heatingType
                                )
                              }
                            },
                            model: {
                              value: _vm.form.heatingType,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "heatingType", $$v)
                              },
                              expression: "form.heatingType"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Voltage Curve Description:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "path",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "application-name",
                              placeholder: "Please enter",
                              trim: "",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput:
                                "setCustomValidity('');if(value.length > 100)value = value.slice(0, 100)"
                            },
                            model: {
                              value: _vm.form.heatingRemarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "heatingRemarks", $$v)
                              },
                              expression: "form.heatingRemarks"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Devices Type:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "device-type",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "device-type",
                              options: _vm.types.deviceType,
                              "value-field": "id",
                              "text-field": "modelName",
                              required: "",
                              oninvalid: "setCustomValidity('Please select');",
                              oninput: "setCustomValidity('')"
                            },
                            model: {
                              value: _vm.form.deviceId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "deviceId", $$v)
                              },
                              expression: "form.deviceId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          ref: "groupNumberSetting",
                          attrs: {
                            label: "Position:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "position",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-wrap" },
                            _vm._l(_vm.types.gearSetting, function(
                              label,
                              index
                            ) {
                              return _c(
                                "b-form-checkbox",
                                {
                                  key: index,
                                  staticClass: "mr-2 mt-2",
                                  attrs: { required: true, value: label.value },
                                  model: {
                                    value: _vm.form.position,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "position", $$v)
                                    },
                                    expression: "form.position"
                                  }
                                },
                                [_vm._v(" " + _vm._s(label.text) + " ")]
                              )
                            }),
                            1
                          )
                        ]
                      ),
                      _vm.form.heatingType == 2
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Voltage:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "temperature",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "temperature" },
                                [
                                  _c("history-curve", {
                                    attrs: { power: _vm.power, width: "100%" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "voltage-one" },
                                _vm._l(_vm.temperatureList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "voltage-number"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            (
                                              (item.value * item.value) /
                                              1.2
                                            ).toFixed(2)
                                          ) +
                                          " w "
                                      )
                                    ]
                                  )
                                }),
                                0
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "voltage-two",
                                  staticStyle: { width: "100%" }
                                },
                                _vm._l(_vm.temperatureList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "voltage-list" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-plus voltage-plus",
                                        on: {
                                          click: function($event) {
                                            return _vm.onVoltagePlus(index)
                                          }
                                        }
                                      }),
                                      _c("el-slider", {
                                        attrs: {
                                          vertical: "",
                                          step: _vm.step,
                                          min: _vm.min,
                                          max: _vm.max,
                                          height: "150px"
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      }),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-minus voltage-reduce",
                                        on: {
                                          click: function($event) {
                                            return _vm.onVoltageReduce(index)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                              _c(
                                "div",
                                { staticClass: "voltage-three" },
                                _vm._l(8, function(index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "voltage-number"
                                    },
                                    [_vm._v(" " + _vm._s(index) + "s ")]
                                  )
                                }),
                                0
                              ),
                              _c("p", [
                                _vm._v(
                                  " The Voltage curve is between 0.4v-3.7v, after the 8th second, the same as the 8th second "
                                )
                              ])
                            ]
                          )
                        : _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Heating Temperature:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "temperature",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "temperature",
                                  placeholder: "Please enter(℉)",
                                  trim: "",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter')",
                                  oninput:
                                    "setCustomValidity('');value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, '$1$2.$3')"
                                },
                                model: {
                                  value: _vm.form.temperature,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "temperature", $$v)
                                  },
                                  expression: "form.temperature"
                                }
                              })
                            ],
                            1
                          ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Preheat Settings:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "preheat",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticStyle: { "margin-top": "5px" },
                              attrs: {
                                id: "preheat",
                                name: "preheatSetting",
                                value: 1,
                                "unchecked-value": 0,
                                switch: "",
                                size: "lg"
                              },
                              model: {
                                value: _vm.form.preheatSetting,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "preheatSetting", $$v)
                                },
                                expression: "form.preheatSetting"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.form.preheatSetting == 1 ? "Yes" : "No"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.form.preheatSetting == 1
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Time:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "preheat-time",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-spinbutton", {
                                staticStyle: { width: "12rem" },
                                attrs: {
                                  id: "preheat-time",
                                  name: "preheatTime",
                                  placeholder: "0",
                                  step: "0.5",
                                  min: "0.5",
                                  max: "20"
                                },
                                model: {
                                  value: _vm.preheatTime1,
                                  callback: function($$v) {
                                    _vm.preheatTime1 = $$v
                                  },
                                  expression: "preheatTime1"
                                }
                              }),
                              _c("p", [
                                _vm._v("The preheat time is between 0s-20s")
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.preheatSetting == 1
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Preheat Voltage:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "preheatVoltage",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-spinbutton", {
                                staticStyle: { width: "12rem" },
                                attrs: {
                                  id: "preheat-time",
                                  name: "preheatVoltage",
                                  placeholder: "0",
                                  step: "0.1",
                                  min: "0.4",
                                  max: "3.7"
                                },
                                model: {
                                  value: _vm.preheatVoltage1,
                                  callback: function($$v) {
                                    _vm.preheatVoltage1 = $$v
                                  },
                                  expression: "preheatVoltage1"
                                }
                              }),
                              _c("p", [
                                _vm._v(
                                  "The Preheat voltage is between 0.4v-3.7v"
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "NFC Settings:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "nfc-settings",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticStyle: { "margin-top": "5px" },
                              attrs: {
                                id: "nfc-settings",
                                name: "nfcSettings",
                                value: 1,
                                "unchecked-value": 0,
                                switch: "",
                                size: "lg"
                              },
                              model: {
                                value: _vm.form.nfcSettings,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "nfcSettings", $$v)
                                },
                                expression: "form.nfcSettings"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.form.nfcSettings == 1 ? "Yes" : "No"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Status:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "status",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-radio-group", {
                            staticStyle: { "margin-top": "5px" },
                            attrs: {
                              id: "radio-group-1",
                              options: _vm.types.statuss,
                              name: "radio-options",
                              required: ""
                            },
                            model: {
                              value: _vm.form.status,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Notes:",
                            "label-cols-lg": "3",
                            "label-align-sm": "right",
                            "label-for": "remark"
                          }
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              id: "remark",
                              placeholder: "Enter Notes",
                              rows: "3",
                              "max-rows": "6"
                            },
                            model: {
                              value: _vm.form.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        { attrs: { "label-cols-lg": "3" } },
                        [
                          _c(
                            "b-button",
                            { attrs: { type: "submit", variant: "primary" } },
                            [_vm._v("Confirm")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "m-l-5 ml-1",
                              attrs: { type: "reset", variant: "danger" }
                            },
                            [_vm._v("Cancel ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }