var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Basic Info")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { cols: "3" } },
                [
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Device NO.:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.deviceNumber))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Device Model:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.deviceModel))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Device Status:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.form.bindStatus == null
                              ? ""
                              : _vm.form.bindStatus == 0
                              ? "Unregistered"
                              : "Registered"
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Registered Email:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.email))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Repayment Period:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.repaymentPeriod))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Valid Period(day):")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.validPeriod))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Activated Time:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.getLocalTime(_vm.form.activationTime)))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Firmware Version:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.versionNo))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Renewal Mode:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(
                        _vm._s(
                          _vm.form.renewalType == null
                            ? ""
                            : _vm.form.renewalType == 0
                            ? "Automatic"
                            : "Manual"
                        )
                      )
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Payment Grace Period(day):")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.graceDays))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("br"),
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Operation Records")])
                ])
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-responsive mb-0" },
            [
              _c("b-table", {
                ref: "table",
                attrs: {
                  "show-empty": "",
                  "empty-text": "No records",
                  id: "table",
                  "head-variant": "light",
                  busy: _vm.isBusy,
                  striped: true,
                  items: _vm.provider,
                  fields: _vm.fields,
                  "per-page": _vm.search_condition.pageSize,
                  "current-page": _vm.search_condition.pageNum,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  filter: _vm.filter,
                  "filter-includedfields": _vm.filterOn
                },
                on: {
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.sortDesc = $event
                  },
                  filtered: _vm.onFiltered
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-busy",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center text-danger my-2" },
                          [
                            _c("b-spinner", { staticClass: "align-middle" }),
                            _c("strong", [_vm._v("Loading...")])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "cell(index)",
                    fn: function(data) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              data.index +
                                (_vm.search_condition.pageNum - 1) *
                                  _vm.search_condition.pageSize +
                                1
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "b-row",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "b-col",
                [
                  (_vm.form.whetherToPayOff == 0 &&
                    _vm.form.repaymentType == 0) ||
                  _vm.form.repaymentType == null
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "has",
                              rawName: "v-has",
                              value: "deviceLeasing::Stage Setting",
                              expression: "'deviceLeasing::Stage Setting'"
                            }
                          ],
                          staticStyle: { "margin-right": "10px" },
                          attrs: { variant: "primary" },
                          on: { click: _vm.openModal }
                        },
                        [_vm._v("Stage setting")]
                      )
                    : _vm._e(),
                  _vm.form.whetherToPayOff == 0 && _vm.form.repaymentType == 0
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "has",
                              rawName: "v-has",
                              value: "deviceLeasing::IncreasedValidityPeriod",
                              expression:
                                "'deviceLeasing::IncreasedValidityPeriod'"
                            }
                          ],
                          staticStyle: { "margin-right": "10px" },
                          attrs: { variant: "primary" },
                          on: {
                            click: function($event) {
                              _vm.increasedValidityPeriod = !_vm.increasedValidityPeriod
                            }
                          }
                        },
                        [_vm._v("Increased validity period")]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-secondary" },
                      on: { click: _vm.returnPage }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dataTables_paginate paging_simple_numbers float-right"
                    },
                    [
                      _vm.rows != 0
                        ? _c(
                            "ul",
                            { staticClass: "pagination mb-0" },
                            [
                              _c("b-pagination", {
                                attrs: {
                                  "per-page": 10,
                                  "total-rows": _vm.rows,
                                  "aria-controls": "table"
                                },
                                model: {
                                  value: _vm.search_condition.pageNum,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.search_condition,
                                      "pageNum",
                                      $$v
                                    )
                                  },
                                  expression: "search_condition.pageNum"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "stageSetting",
          attrs: {
            title: "Subscription Settings Status",
            "hide-footer": "",
            size: "lg",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: { hidden: _vm.reset },
          model: {
            value: _vm.stageSettingModal,
            callback: function($$v) {
              _vm.stageSettingModal = $$v
            },
            expression: "stageSettingModal"
          }
        },
        [
          _c(
            "form",
            { ref: "form", on: { submit: _vm.onSubmit, reset: _vm.onReset } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Validity Type:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "validityType"
                  }
                },
                [
                  _c("b-form-radio-group", {
                    staticStyle: { "margin-top": "5px" },
                    attrs: {
                      id: "radio-group-1",
                      options:
                        _vm.form.repaymentType == null
                          ? _vm.types.repaymentTypes
                          : [
                              {
                                text: "Payment by installment",
                                value: 0,
                                disabled: false
                              }
                            ],
                      required: ""
                    },
                    model: {
                      value: _vm.saveRenewal.repaymentType,
                      callback: function($$v) {
                        _vm.$set(_vm.saveRenewal, "repaymentType", $$v)
                      },
                      expression: "saveRenewal.repaymentType"
                    }
                  })
                ],
                1
              ),
              _vm.saveRenewal.repaymentType != 1
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Renewal Mode:",
                        "label-cols-lg": "3",
                        "label-align-lg": "right",
                        "label-for": "renewalMode"
                      }
                    },
                    [
                      _c("b-form-radio-group", {
                        staticStyle: { "margin-top": "5px" },
                        attrs: {
                          id: "radio-group-2",
                          options: _vm.types.renewalTypes,
                          required: ""
                        },
                        model: {
                          value: _vm.saveRenewal.renewalType,
                          callback: function($$v) {
                            _vm.$set(_vm.saveRenewal, "renewalType", $$v)
                          },
                          expression: "saveRenewal.renewalType"
                        }
                      }),
                      _c("div", [
                        _vm._v(
                          " Tip: Setting automatic will automatically renew the use time "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.saveRenewal.repaymentType != 1
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Months To Complete:",
                        "label-cols-lg": "3",
                        "label-align-lg": "right",
                        "label-for": "stages"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "stages",
                          trim: "",
                          disabled:
                            _vm.form.renewalType == 0 ||
                            _vm.form.renewalType == 1 ||
                            _vm.form.repaymentNumber,
                          type: "number",
                          min: "1",
                          max: "12",
                          required: "",
                          placeholder: "Please enter",
                          oninvalid:
                            "setCustomValidity('Please enter Numbers up to 12');",
                          oninput:
                            "setCustomValidity('');if(value > 12||value < 0)value =''"
                        },
                        model: {
                          value: _vm.saveRenewal.repaymentNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.saveRenewal, "repaymentNumber", $$v)
                          },
                          expression: "saveRenewal.repaymentNumber"
                        }
                      }),
                      _c("div", [
                        _vm._v(
                          " The number of stages required by the user, which can be used permanently after completion of the stages "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.saveRenewal.repaymentType != 1
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Payment Grace Period:",
                        "label-cols-lg": "3",
                        "label-align-lg": "right",
                        "label-for": "period"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "period",
                          trim: "",
                          type: "number",
                          min: "0",
                          max: "30",
                          required: _vm.saveRenewal.renewalType != 0,
                          placeholder: "Please enter",
                          oninvalid:
                            "setCustomValidity('Please enter Numbers up to 30');",
                          oninput:
                            "setCustomValidity('');if(value > 30||value < 0)value =''"
                        },
                        model: {
                          value: _vm.saveRenewal.graceDays,
                          callback: function($$v) {
                            _vm.$set(_vm.saveRenewal, "graceDays", $$v)
                          },
                          expression: "saveRenewal.graceDays"
                        }
                      }),
                      _c("div", [
                        _vm._v(
                          " After the specified repayment date, the device will be available during the grace period and will be automatically locked after the grace period "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Notes:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "remarks"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "remarks",
                      trim: "",
                      placeholder: "50 characters maximum",
                      oninput: "if(value.length > 50)value = value.slice(0, 50)"
                    },
                    model: {
                      value: _vm.saveRenewal.remarks,
                      callback: function($$v) {
                        _vm.$set(_vm.saveRenewal, "remarks", $$v)
                      },
                      expression: "saveRenewal.remarks"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    { staticClass: "m-l-5 ml-1", attrs: { type: "reset" } },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "increasedValidity",
          attrs: {
            title: "Increased Validity Period",
            "hide-footer": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: { hidden: _vm.resetIncreasedValidityPeriod },
          model: {
            value: _vm.increasedValidityPeriod,
            callback: function($$v) {
              _vm.increasedValidityPeriod = $$v
            },
            expression: "increasedValidityPeriod"
          }
        },
        [
          _c(
            "form",
            {
              ref: "form",
              on: {
                submit: _vm.onSubmitIncreasedValidityPeriod,
                reset: _vm.onResetIncreasedValidityPeriod
              }
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Increase:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "months"
                  }
                },
                [
                  _c("b-form-radio-group", {
                    staticStyle: { "margin-top": "5px" },
                    attrs: {
                      id: "radio-group-3",
                      options:
                        _vm.form.renewalType == 0
                          ? [
                              {
                                text: "Early settlement",
                                value: 2,
                                disabled: false
                              }
                            ]
                          : _vm.types.periodTypes
                    },
                    model: {
                      value: _vm.saveValidity.periodType,
                      callback: function($$v) {
                        _vm.$set(_vm.saveValidity, "periodType", $$v)
                      },
                      expression: "saveValidity.periodType"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Notes:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "remarks"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "remarks",
                      trim: "",
                      placeholder: "Within 50 characters",
                      oninput: "if(value.length > 50)value = value.slice(0, 50)"
                    },
                    model: {
                      value: _vm.saveValidity.remarks,
                      callback: function($$v) {
                        _vm.$set(_vm.saveValidity, "remarks", $$v)
                      },
                      expression: "saveValidity.remarks"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    { staticClass: "m-l-5 ml-1", attrs: { type: "reset" } },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }