<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <div class="vo-column" style="width: 100%" v-if="tagDetail.id">
        <div class="productDetailRow">
          <div class="productDetailColumn">
            <div class="productDetailRow1 productDetailBorder">
              <img :src="tagDetail.flavorPicture" style="object-fit: contain; width: 100%; height: 100%;" />
            </div>
          </div>

          <div class="productDetailColumn">
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Marketing Name#:</strong>
              </p>
              <p
                class="productDetailContent"
                style="font-size: 18px; color: #3eb1c8"
              >
                {{ tagDetail.deviceName }}
              </p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Product Model#:</strong>
              </p>
              <p class="productDetailContent">{{ tagDetail.modelName }}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Variant Code:</strong>
              </p>
              <p class="productDetailContent">
                {{ tagDetail.variantCode }}
              </p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Omni ID:</strong>
              </p>
              <p class="productDetailContent">
                {{ tagDetail.tagId }}
              </p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Business Type:</strong>
              </p>
              <p class="productDetailContent">
                {{ tagDetail.oilTypeName }}
              </p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Product Type:</strong>
              </p>
              <p class="productDetailContent">{{ tagDetail.productType }}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Compatibility:</strong>
              </p>
              <p class="productDetailContent">
                {{ tagDetail.availableCompatibility }}
              </p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Tank Size:</strong>
              </p>
              <p class="productDetailContent"></p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Resistance Value:</strong>
              </p>
              <p class="productDetailContent">{{ tagDetail.resistance }}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Max Output Voltage:</strong>
              </p>
              <p class="productDetailContent">
                {{ tagDetail.maxOutputVoltage }}
              </p>
            </div>
          </div>

          <div class="productDetailColumn">
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Client:</strong>
              </p>
              <p class="productDetailContent">{{ tagDetail.clientName }}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Brand:</strong>
              </p>
              <p class="productDetailContent">{{ tagDetail.brandName }}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Brand Product Model#:</strong>
              </p>
              <p class="productDetailContent">
                {{ tagDetail.brandProductModel }}
              </p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Brand Marketing Name:</strong>
              </p>
              <p class="productDetailContent">
                {{ tagDetail.brandMarketingName }}
              </p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Flavor:</strong>
              </p>
              <p class="productDetailContent">
                {{ tagDetail.flavorName }}
              </p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Batch Name:</strong>
              </p>
              <p class="productDetailContent">
                {{ tagDetail.orderName }}
              </p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Status:</strong>
              </p>
              <p class="productDetailContent">
                {{ tagDetail.statusName }}
              </p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Activated Time:</strong>
              </p>
              <p class="productDetailContent">
                {{getLocalTime(tagDetail.activatedTime)}}
              </p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Activated Address:</strong>
              </p>
              <p class="productDetailContent">
                {{ tagDetail.activatedAddress }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import { mapGetters } from "vuex";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import ImageComponets from "@/components/brand/ImageComponets.vue";

/**
 * Cartridge Info component
 */
export default {
  components: {
    Layout,
    PageHeader,
    timeZoneConversion,
    ImageComponets
  },
  data() {
    return {
      title: "Product Details",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Production Batch",
          active: true,
        },
        {
          text: "Omni ID List",
          active: true,
        },
        {
          text: "Details",
          active: true,
        },
      ],
      tagDetail: {},
    };
  },
  computed: {
    ...mapGetters(["isIkrusher", "isUnicoreus"]),
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.$api.emptyCartridges
          .selectTagById(this.$route.query)
          .then((res) => {
            if (res.success) {
              this.tagDetail = res.data;
            } else {
              if (res.message) {
                this.$toast(res);
              }
            }
          });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    onReset() {
      this.$router.push("productList");
    },
    getLocalTime(time) {
      return timeZoneConversion.getLocalTime(time);
    },
  },
};
</script>
<style scoped>
.productDetailRow1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.productDetailBorder {
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  width: 350px;
  height: 350px;
}
.productDetailBorder:hover{
  border: 2px dashed #02a7f0;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5); /* 添加阴影效果 */
}
.productDetailRow {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.productDetailColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.productDetailRowButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.modal-header {
  display: none;
}
.modal-footer {
  display: none;
}
.productDetailContent {
  text-align: left;
  flex: 1;
}
.productDetailContentColor {
  width: 200px;
  text-align: right;
  color: #333333;
}
</style>
