/*
 * @Author: Code-HHX
 * @Date: 2021-04-08 11:43:47
 * @LastEditors: Code-HHX
 * @LastEditTime: 2021-12-16 15:15:17
 * @Description: 
 */
import { format } from './time'
import axios from '../config/http.js'
import baseUrl from '../config/env.js'
import devModel from '../config/env.js'
import axios1 from '../config/http-json.js'

// 首页
function list(params, ctx) {
	return axios.fetchGet(baseUrl + '/home/list', params)
}
function selectProductionProgress(params, ctx) {
	return axios.fetchGet(baseUrl + '/home/selectProductionProgress', params)
}
function statisticalInventory(params, ctx) {
	return axios.fetchGet(baseUrl + '/home/statisticalInventory', params)
}
function findQuickEntry(params, ctx) {
	return axios.fetchGet(baseUrl + '/home/findQuickEntry', params)
}
function selectFastMenu(params, ctx) {
	return axios.fetchGet(baseUrl + '/home/selectFastMenu', params)
}
function saveOrUpdateQuickEntry(params, ctx) {
	return axios1.fetchPost(baseUrl + '/home/saveOrUpdateQuickEntry', params)
}
//测试版请求URL 'http://13.57.48.78:8080/supplement/supplier/business/selectActiveUser1'
// 商业数据BI
function selectActiveUser(params, ctx) {
	if (process.env.NODE_ENV == 'prod') {
		return axios.fetchGet(baseUrl + '/business/selectActiveUser', params)
	} else {
		return axios.fetchGet(
			baseUrl+'/business/selectActiveUser1',
			params
		)
	}
}
function selectModelConsumed(params, ctx) {
	return axios.fetchGet(baseUrl + '/business/selectModelConsumed', params)
}
function selectCartridgeUsageDetails(params, ctx) {
	return axios.fetchGet(
		baseUrl + '/business/selectCartridgeUsageDetails',
		params
	)
}
function selectBrandConsumed(params, ctx) {
	return axios.fetchGet(baseUrl + '/business/selectBrandConsumed', params)
}
function selectBatchConsumed(params, ctx) {
	return axios.fetchGet(baseUrl + '/business/selectBatchConsumed', params)
}
function selectUsaMapCartridgeDetails(params, ctx) {
	return axios.fetchGet(
		baseUrl + '/business/selectUsaMapCartridgeDetails',
		params
	)
}
function selectAgeConsumption(params, ctx) {
	return axios.fetchGet(baseUrl + '/business/selectAgeConsumption', params)
}

function selectBrandsLastDays(params, ctx) {
	return axios.fetchGet(baseUrl + '/business/selectBrandsLastDays', params)
}

function selectActiveUserRecords(params, ctx) {
	return axios.fetchGet(baseUrl + '/business/selectActiveUserRecords', params)
}

function selectUserRecord(params, ctx) {
	return axios.fetchGet(baseUrl + '/business/selectUserRecord', params)
}

function selectMapDetails(params, ctx) {
	return axios.fetchGet(
		baseUrl + '/business/selectMapDetails',
		params
	)
}
export default {
	list,
	selectProductionProgress,
	statisticalInventory,
	findQuickEntry,
	selectFastMenu,
	saveOrUpdateQuickEntry,
	selectActiveUser,
	selectModelConsumed,
	selectCartridgeUsageDetails,
	selectBrandConsumed,
	selectBatchConsumed,
	selectUsaMapCartridgeDetails,
	selectAgeConsumption,
	selectBrandsLastDays,
	selectActiveUserRecords,
	selectUserRecord,
	selectMapDetails,
}
