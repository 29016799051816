<template>
  <div :style="margin" style="text-align: center;"> {{len}}/{{ max }}</div>
</template>

<script>
export default {
  name: "CountText",
  props: {
    len: {
      type: [String,Number],
      default: 0
    },
    max: {
      type: [String,Number],
      default: 50
    },
    margin: {
      type:String,
      default: 'margin-bottom:1em'
    }
  }
}
</script>


<style scoped lang="scss">

</style>