<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="3">
              <b-form-group
                label="Mode name"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="Heating-name"
              >
                <b-form-input
                  id="Heating-name"
                  v-model="search_condition.modeName"
                  trim
                  placeholder="Please enter"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-form-group
                label="Mode Type"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="mode"
              >
                <b-form-select
                  v-model="search_condition.heatingType"
                  :options="types.heatingTypes"
                  value-field="value"
                  text-field="text"
                >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-form-group
                label="Status"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="status"
              >
                <b-form-select
                  v-model="search_condition.status"
                  :options="types.statuss"
                  value-field="value"
                  text-field="text"
                >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <b-button
            v-has="'heatingMode::Create'"
            @click="gotoAdd"
            variant="primary"
            >Create</b-button
          >

          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="No records"
              id="table"
              head-variant="light"
              ref="table"
              :busy="isBusy"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">
                {{
                  data.index +
                  (search_condition.pageNum - 1) * search_condition.pageSize +
                  1
                }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button-group>
                  <b-button
                    v-has="'heatingMode::Modify'"
                    v-if="row.item.supplierId != 0"
                    size="sm"
                    variant="primary"
                    @click="edit(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    Modify
                  </b-button>
                </b-button-group>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  >
                  </b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * FAQ component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
  },
  data() {
    return {
      page: {
        title: "Heating Mode",
        items: [
          {
            text: "UNI-CORE",
            // href: "/"
          },
          {
            text: "Data Dictionary",
            // href: "/"
          },
          {
            text: "Heating Mode",
            active: true,
          },
        ],
      },
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "modeName",
          label: "Mode name",
          class: "text-center",
        },
        {
          key: "heatingRemarks",
          label: "Mode Description",
          class: "text-center",
        },
        {
          key: "heatingType",
          label: "Mode Type",
          formatter: (value) => {
            return value == 1 ? "Consistent Temperature" : "High Wave";
          },
          class: "text-center",
        },
        // {
        //   key: "supplierId",
        //   label: "Affiliated Customer",
        //   formatter: (value) => {
        //     let a = ""
        //     this.types.allSupplier.forEach(item=>{
        //       if(item.id==value){
        //         a = item.brandName
        //       }
        //     })
        //     return a;
        //   },
        //   class: "text-center",
        // },
        {
          key: "status",
          label: "Status",
          formatter: (value) => {
            return value == 0 ? "Enabled" : "Disabled";
          },
          class: "text-center",
        },
        {
          key: "remarks",
          label: "Notes",
          class: "text-center",
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        modeName: "",
        heatingType: "",
        status: "",
        supplierId: localStorage.supplierId,
      },
      isBusy: false,
      has_data: false,
      types: {
        statuss: [
          { text: "Please select", value: "", disabled: false },
          { text: "Enabled", value: 0, disabled: false },
          { text: "Disabled", value: 1, disabled: false },
        ],
        heatingTypes: [
          { text: "Please select", value: "", disabled: false },
          { text: "Consistent Temperature", value: 1 },
          { text: "High Wave", value: 2 },
        ],
        // allSupplier: [],
      },
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  created() {
    // this.init();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    gotoAdd() {
      this.$router.push("heatingModeAdd");
    },
    provider(ctx) {
      this.isBusy = true;
      return this.$api.warmUpMode
        .heatingList(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    edit(item, index, button) {
      this.$router.push("heatingModeEdit?id=" + item.id);
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
  },
};
</script>
