var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-lg-6 col-xl-3" },
          [
            _c(
              "b-card",
              {
                attrs: {
                  "img-src": require("@/assets/images/small/img-1.jpg"),
                  "img-alt": "Card image",
                  "img-top": ""
                }
              },
              [
                _c("b-card-title", [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("Card title")
                  ])
                ]),
                _c("b-card-text", [
                  _vm._v(
                    " Some quick example text to build on the card title and make up the bulk of the card's content. "
                  )
                ]),
                _c(
                  "b-button",
                  {
                    attrs: { href: "javascript:void(0);", variant: "primary" }
                  },
                  [_vm._v("Button")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-lg-6 col-xl-3" },
          [
            _c(
              "b-card",
              {
                attrs: {
                  "no-body": "",
                  "img-src": require("@/assets/images/small/img-2.jpg"),
                  "img-alt": "Card image",
                  "img-top": ""
                }
              },
              [
                _c(
                  "b-card-body",
                  [
                    _c("b-card-title", [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v("Card title")
                      ])
                    ]),
                    _c("b-card-text", [
                      _vm._v(
                        "Some quick example text to build on the card title and make up the bulk of the card's content."
                      )
                    ])
                  ],
                  1
                ),
                _c("ul", { staticClass: "list-group list-group-flush" }, [
                  _c("li", { staticClass: "list-group-item" }, [
                    _vm._v("Cras justo odio")
                  ]),
                  _c("li", { staticClass: "list-group-item" }, [
                    _vm._v("Dapibus ac facilisis in")
                  ])
                ]),
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "a",
                    {
                      staticClass: "card-link text-custom",
                      attrs: { href: "javascript:void(0);" }
                    },
                    [_vm._v("Card link")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "card-link text-custom",
                      attrs: { href: "javascript:void(0);" }
                    },
                    [_vm._v("Another link")]
                  )
                ])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-lg-6 col-xl-3" },
          [
            _c(
              "b-card",
              {
                attrs: {
                  "img-src": require("@/assets/images/small/img-3.jpg"),
                  "img-alt": "Card image",
                  "img-top": ""
                }
              },
              [
                _c("b-card-text", [
                  _vm._v(
                    " Some quick example text to build on the card title and make up the bulk of the card's content. "
                  )
                ])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-lg-6 col-xl-3" },
          [
            _c(
              "b-card",
              { attrs: { "no-body": "" } },
              [
                _c(
                  "b-card-body",
                  [
                    _c("b-card-title", [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v("Card title")
                      ])
                    ]),
                    _c("b-card-sub-title", [
                      _c("h6", { staticClass: "card-subtitle text-muted" }, [
                        _vm._v("Support card subtitle")
                      ])
                    ])
                  ],
                  1
                ),
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: { src: require("@/assets/images/small/img-4.jpg") }
                }),
                _c(
                  "b-card-body",
                  [
                    _c("b-card-text", [
                      _vm._v(
                        " Some quick example text to build on the card title and make up the bulk of the card's content. "
                      )
                    ]),
                    _c(
                      "a",
                      {
                        staticClass: "card-link text-custom",
                        attrs: { href: "javascript:void(0);" }
                      },
                      [_vm._v("Card link")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "card-link text-custom",
                        attrs: { href: "javascript:void(0);" }
                      },
                      [_vm._v("Another link")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-lg-6" },
          [
            _c(
              "b-card",
              { attrs: { "no-body": "" } },
              [
                _c(
                  "b-card-body",
                  [
                    _c("b-card-title", [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v("Special title treatment")
                      ])
                    ]),
                    _c("b-card-text", [
                      _vm._v(
                        " With supporting text below as a natural lead-in to additional content. "
                      )
                    ]),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary btn-block",
                        attrs: { href: "javascript:void(0);" }
                      },
                      [_vm._v("Go somewhere")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-lg-6" },
          [
            _c(
              "b-card",
              { attrs: { "no-body": "" } },
              [
                _c(
                  "b-card-body",
                  [
                    _c("b-card-title", [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v("Special title treatment")
                      ])
                    ]),
                    _c("b-card-text", [
                      _vm._v(
                        " With supporting text below as a natural lead-in to additional content. "
                      )
                    ]),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary btn-block",
                        attrs: { href: "javascript:void(0);" }
                      },
                      [_vm._v("Go somewhere")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c(
              "b-card",
              [
                _c("b-card-title", [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("Special title treatment")
                  ])
                ]),
                _c(
                  "h4",
                  {
                    staticClass: "mb-0",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_vm._v("Featured")]
                ),
                _c("b-card-text", [
                  _vm._v(
                    "With supporting text below as a natural lead-in to additional content."
                  )
                ]),
                _c("b-button", { attrs: { href: "#", variant: "primary" } }, [
                  _vm._v("Go somewhere")
                ])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c(
              "b-card",
              { attrs: { header: "Quote" } },
              [
                _c("b-card-text", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
                  )
                ]),
                _c("footer", [
                  _vm._v(" Someone famous in "),
                  _c("cite", { attrs: { title: "Source Title" } }, [
                    _vm._v("Source Title")
                  ])
                ])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c(
              "b-card",
              { attrs: { header: "Featured", footer: "2 days ago" } },
              [
                _c("b-card-text", [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Special title treatment")
                  ]),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      " With supporting text below as a natural lead-in to additional content. "
                    )
                  ]),
                  _c(
                    "a",
                    { staticClass: "btn btn-primary", attrs: { href: "#" } },
                    [_vm._v("Go somewhere")]
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c(
              "b-card",
              {
                attrs: {
                  "img-src": require("@/assets/images/small/img-5.jpg"),
                  "img-alt": "Card image",
                  "img-top": ""
                }
              },
              [
                _c("b-card-title", [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("Card title")
                  ])
                ]),
                _c("b-card-text", [
                  _c("p", [
                    _vm._v(
                      "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                    )
                  ]),
                  _c("p", [
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Last updated 3 mins ago")
                    ])
                  ])
                ])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c(
              "b-card",
              {
                attrs: {
                  "img-src": require("@/assets/images/small/img-7.jpg"),
                  "img-alt": "Card image",
                  "img-bottom": ""
                }
              },
              [
                _c("b-card-title", [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("Card title")
                  ])
                ]),
                _c("b-card-text", [
                  _c("p", [
                    _vm._v(
                      "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                    )
                  ]),
                  _c("p", [
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Last updated 3 mins ago")
                    ])
                  ])
                ])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c(
              "b-card",
              {
                attrs: {
                  overlay: "",
                  "img-src": require("@/assets/images/small/img-6.jpg"),
                  "img-alt": "Card Image",
                  "text-variant": "white"
                }
              },
              [
                _c("b-card-title", [
                  _c("h5", { staticClass: "card-title text-white" }, [
                    _vm._v("Card title")
                  ])
                ]),
                _c("b-card-text", [
                  _c("p", [
                    _vm._v(
                      "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                    )
                  ]),
                  _c("p", [
                    _c("small", { staticClass: "text-white" }, [
                      _vm._v("Last updated 3 mins ago")
                    ])
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c(
              "b-card",
              { attrs: { "bg-variant": "secondary", "text-variant": "white" } },
              [
                _c(
                  "blockquote",
                  { staticClass: "card-bodyquote mb-0" },
                  [
                    _c("b-card-text", [
                      _c("p", [
                        _vm._v(
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
                        )
                      ]),
                      _c(
                        "footer",
                        { staticClass: "blockquote-footer text-white" },
                        [
                          _vm._v(" Someone famous in "),
                          _c("cite", { attrs: { title: "Source Title" } }, [
                            _vm._v("Source Title")
                          ])
                        ]
                      )
                    ])
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c(
              "b-card",
              { attrs: { "bg-variant": "primary", "text-variant": "white" } },
              [
                _c(
                  "blockquote",
                  { staticClass: "card-bodyquote mb-0" },
                  [
                    _c("b-card-text", [
                      _c("p", [
                        _vm._v(
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
                        )
                      ]),
                      _c(
                        "footer",
                        { staticClass: "blockquote-footer text-white" },
                        [
                          _vm._v(" Someone famous in "),
                          _c("cite", { attrs: { title: "Source Title" } }, [
                            _vm._v("Source Title")
                          ])
                        ]
                      )
                    ])
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c(
              "b-card",
              { attrs: { "bg-variant": "success", "text-variant": "white" } },
              [
                _c(
                  "blockquote",
                  { staticClass: "card-bodyquote mb-0" },
                  [
                    _c("b-card-text", [
                      _c("p", [
                        _vm._v(
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
                        )
                      ]),
                      _c(
                        "footer",
                        { staticClass: "blockquote-footer text-white" },
                        [
                          _vm._v(" Someone famous in "),
                          _c("cite", { attrs: { title: "Source Title" } }, [
                            _vm._v("Source Title")
                          ])
                        ]
                      )
                    ])
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c(
              "b-card",
              { attrs: { "bg-variant": "info", "text-variant": "white" } },
              [
                _c(
                  "blockquote",
                  { staticClass: "card-bodyquote mb-0" },
                  [
                    _c("b-card-text", [
                      _c("p", [
                        _vm._v(
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
                        )
                      ]),
                      _c(
                        "footer",
                        { staticClass: "blockquote-footer text-white" },
                        [
                          _vm._v(" Someone famous in "),
                          _c("cite", { attrs: { title: "Source Title" } }, [
                            _vm._v("Source Title")
                          ])
                        ]
                      )
                    ])
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c(
              "b-card",
              { attrs: { "bg-variant": "warning", "text-variant": "white" } },
              [
                _c(
                  "blockquote",
                  { staticClass: "card-bodyquote mb-0" },
                  [
                    _c("b-card-text", [
                      _c("p", [
                        _vm._v(
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
                        )
                      ]),
                      _c(
                        "footer",
                        { staticClass: "blockquote-footer text-white" },
                        [
                          _vm._v(" Someone famous in "),
                          _c("cite", { attrs: { title: "Source Title" } }, [
                            _vm._v("Source Title")
                          ])
                        ]
                      )
                    ])
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c(
              "b-card",
              { attrs: { "bg-variant": "danger", "text-variant": "white" } },
              [
                _c(
                  "blockquote",
                  { staticClass: "card-bodyquote mb-0" },
                  [
                    _c("b-card-text", [
                      _c("p", [
                        _vm._v(
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
                        )
                      ]),
                      _c(
                        "footer",
                        { staticClass: "blockquote-footer text-white" },
                        [
                          _vm._v(" Someone famous in "),
                          _c("cite", { attrs: { title: "Source Title" } }, [
                            _vm._v("Source Title")
                          ])
                        ]
                      )
                    ])
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row mb-3" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("h4", { staticClass: "my-4" }, [_vm._v("Decks")]),
            _c(
              "b-card-group",
              { attrs: { deck: "" } },
              [
                _c(
                  "b-card",
                  {
                    attrs: {
                      "img-src": require("@/assets/images/small/img-4.jpg"),
                      "img-alt": "Card image",
                      "img-top": ""
                    }
                  },
                  [
                    _c("b-card-title", [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v("Card title")
                      ])
                    ]),
                    _c("b-card-text", [
                      _c("p", [
                        _vm._v(
                          "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
                        )
                      ]),
                      _c("p", [
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v("Last updated 3 mins ago")
                        ])
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "b-card",
                  {
                    attrs: {
                      "img-src": require("@/assets/images/small/img-5.jpg"),
                      "img-alt": "Card image",
                      "img-top": ""
                    }
                  },
                  [
                    _c("b-card-title", [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v("Card title")
                      ])
                    ]),
                    _c("b-card-text", [
                      _c("p", [
                        _vm._v(
                          "This card has supporting text below as a natural lead-in to additional content."
                        )
                      ]),
                      _c("p", [
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v("Last updated 3 mins ago")
                        ])
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "b-card",
                  {
                    attrs: {
                      "img-src": require("@/assets/images/small/img-6.jpg"),
                      "img-alt": "Card image",
                      "img-top": ""
                    }
                  },
                  [
                    _c("b-card-title", [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v("Card title")
                      ])
                    ]),
                    _c("b-card-text", [
                      _c("p", [
                        _vm._v(
                          "This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action."
                        )
                      ]),
                      _c("p", [
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v("Last updated 3 mins ago")
                        ])
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }