var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vo-column align-items-center" },
    [
      _c("MaskView", { attrs: { "mask-background-color": "transparent" } }, [
        _c(
          "div",
          {
            staticClass: "position-relative vo-column align-items-center w-100"
          },
          [
            _c("el-image", {
              staticStyle: { height: "200px" },
              attrs: { fit: "contain", src: _vm.item.value }
            }),
            _c(
              "div",
              { staticClass: "cover position-absolute " },
              [
                _vm.item.status
                  ? _c(
                      "b-badge",
                      { staticClass: "cover-text", attrs: { variant: "dark" } },
                      [_vm._v("Cover")]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass:
            "vo-row align-items-center vo-space-around mt-2 belowButton w-100"
        },
        [
          _c(
            "div",
            {
              staticClass: "custom-color cursor",
              on: { click: _vm.deletePicture }
            },
            [_vm._v(" Delete ")]
          ),
          _c(
            "div",
            { staticClass: "custom-color cursor", on: { click: _vm.setCover } },
            [_vm._v(" Set as cove ")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }