<template>
	<Layout>
		<!-- <b-row>
      <b-col xl="12">
        <b-card title="Workbench">
          <img
            class="avatar-md rounded-circle mr-2"
            src="@/assets/images/users/avatar-4.jpg"
            alt="Header Avatar"
          />
          <span
            >Hi, {{ username }} , Wish you work happyness in your work.</span
          >
        </b-card>
      </b-col>
    </b-row> -->

		<b-row>
			<b-col xl="8">
				<!-- 快捷入口 -->
				<b-card>
					<div
						style="
              font-size: 16px;
              font-family: 'Sarabun', sans-serif;
              font-weight: 600;
              float: left;
            "
					>
						Shortcut Links
					</div>
					<div
						style="float: right; font-size: 24px"
						@click="openModal"
					>
						<i
							class="mdi mdi-settings-outline toggle-right"
							style="cursor: pointer"
						></i>
					</div>
					<div
						class="container"
						style="margin-top: 32px; display: flex; flex-wrap: wrap"
					>
						<div
							v-for="item in quickEntry"
							:key="item.id"
							style="display: inline-block; text-align: center; cursor: pointer"
							class="col-md-2"
							@click="$router.push(item.href)"
						>
							<i
								:class="item.quickIcon"
								style="font-size: 40px"
							></i>
							<p style="font-size: 14px; word-break: break-word">
								{{
									item.quickName.replace(/(\s|&nbsp;)+/g, ' ')
								}}
							</p>
						</div>
					</div>
				</b-card>

				<b-modal
					ref="modal"
					v-model="modalShow"
					title="Setting Quick Entry"
					@hidden="reset"
					hide-footer
					size="lg"
					centered
					no-close-on-esc
					no-close-on-backdrop
				>
					<form ref="form" @submit="onSubmit" @reset="onReset">
						<div class="container">
							<b-form-checkbox-group
								id="checkbox-group-2"
								v-model="selected"
								name="flavour-2"
							>
								<div
									v-for="ele in allQuickEntry"
									:key="ele.id"
									style="display: inline-block; text-align: center"
									class="col-md-2"
								>
									<b-form-checkbox :value="ele.id">
										<i
											:class="ele.quickIcon"
											style="font-size: 40px"
										></i>
										<p
											style="font-size: 14px; word-break: word-wrap"
										>
											{{
												ele.quickName.replace(
													/(\s|&nbsp;)+/g,
													' '
												)
											}}
										</p>
									</b-form-checkbox>
								</div>
							</b-form-checkbox-group>
						</div>

						<b-form-group style="text-align: center">
							<b-button type="submit" variant="primary"
								>Confirm</b-button
							>
							<b-button type="reset" class="m-l-5 ml-1"
								>Cancel</b-button
							>
						</b-form-group>
					</form>
				</b-modal>
			</b-col>

			<b-col xl="4">
				<!-- 烟弹库存统计 -->
				<b-card title="Product Inventory">
					<div style="display: flex">
						<div style="flex: 1; text-align: center">
							<p style="font-size: 30px">
								{{
									inventory.remainingTotal
										? inventory.remainingTotal
										: 0
								}}
							</p>
							<p>Remaining unfilled products</p>
						</div>
						<div style="flex: 1; text-align: center">
							<p style="font-size: 30px">
								{{
									inventory.toBeActivated_total
										? inventory.toBeActivated_total
										: 0
								}}
							</p>
							<p>Remaining To Be Activated</p>
						</div>
					</div>
				</b-card>
			</b-col>
		</b-row>

		<b-row>
			<b-col xl="8">
				<!-- 生产进度 -->
				<b-card title="Recent Activity">
					<div v-for="item in productionProgressItems" :key="item.id">
						<p
							style="margin-bottom: 10px; color: Rgba(56, 188, 255)"
						>
							{{ item.batchName }}
							<b-badge pill variant="danger">{{
								item.flavorName
							}}</b-badge>
							<span style="float: right">{{
								item.status == 0
									? 'To be confirmed'
									: item.status == 1
									? 'Have been confirmed'
									: item.status == 2
									? 'In production'
									: item.status == 3
									? 'Completed'
									: item.status == 4
									? 'Information to clear'
									: 'Production Completed on'
							}}</span>
						</p>
						<span>Quantity: {{ item.batchQuantity }}</span>
						<span style="margin-left: 30px">
							Product Model: {{ item.modelName }}</span
						>
						<span
							style="margin-left: 30px; color: Rgba(56, 164, 248)"
						>
							Creator: </span
						><span>{{ item.createUserName }}</span>
						<span
							style="margin-left: 30px; color: Rgba(2, 164, 153)"
						>
							Filling Manager: </span
						><span>{{ item.burnRecordUserName }}</span>
						<span style="float: right">{{
							getLocalTime(item.createTime)
						}}</span>
						<p
							style="height: 1px; background-color: #999; opacity: 0.5"
						></p>
					</div>
					<div
						style="float: right"
						v-if="productionProgressRows != 0"
					>
						<b-pagination
							v-model="productionProgressSearch.pageNum"
							:total-rows="productionProgressRows"
							:per-page="10"
							aria-controls="my-table"
							@change="changeProductionProgressPageNum"
						></b-pagination>
					</div>
				</b-card>
			</b-col>

			<b-col xl="4">
				<!-- 空烟弹申请 -->
				<b-card title="Blank Product Orders">
					<div v-for="item in emptyCartridgesItems" :key="item.id">
						<p class="text-muted" style="margin-bottom: 10px">
							{{ item.applicationInstructions }}
						</p>
						<span
							class="mb-0"
							v-for="i in item.model"
							:key="i.id"
							style="margin-right: 30px"
						>
							{{ i.modelName }}:{{ i.number }}
						</span>
						<p
							style="height: 1px; background-color: #999; opacity: 0.5"
						></p>
					</div>
					<div style="float: right" v-if="emptyCartridgesRows != 0">
						<b-pagination
							v-model="emptyCartridgesSearch.pageNum"
							:total-rows="emptyCartridgesRows"
							:per-page="10"
							aria-controls="my-tables"
							@change="changeEmptyCartridgesPageNum"
						></b-pagination>
					</div>
				</b-card>
			</b-col>
		</b-row>
	</Layout>
</template>
<script>
import Layout from '../layouts/main'
import timeZoneConversion from '../../components/widgets/timeZoneConversion'
import Swal from 'sweetalert2'

export default {
	components: {
		Layout,
		timeZoneConversion,
		Swal,
	},
	data() {
		return {
			productionProgressSearch: {
				supplierId: localStorage.supplierId,
				pageNum: 1,
				pageSize: 10,
			},
			productionProgressRows: 0,
			productionProgressItems: [],
			emptyCartridgesSearch: {
				supplierId: localStorage.supplierId,
				pageNum: 1,
				pageSize: 10,
			},
			emptyCartridgesRows: 0,
			emptyCartridgesItems: [],
			inventory: {},
			quickEntry: [],
			allQuickEntry: [],
			modalShow: false,
			selected: [],
			username: localStorage.getItem('auth-user'),
		}
	},
	created() {
		this.init()
		if (this.$route.params.isGuide == 0) {
			Swal.fire({
				title: 'New User Guide',
				text:
					"We see that you are using it for the first time, and we have prepared a beginner's guide!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#34c38f',
				cancelButtonColor: '#f46a6a',
				confirmButtonText: 'Okay',
				cancelButtonText: 'Skip',
			}).then((result) => {
				if (result.value) {
					this.$router.push('/newbieGuide')
				}
			})
		}
	},
	mounted() {
		this.findQuickEntry()
		this.statisticalInventory()
		this.changeProductionProgressPageNum()
		this.changeEmptyCartridgesPageNum()
	},
	methods: {
		init() {
			// this.$api.account.findUserMenu().then((res) => {
			// 	if (res.success && res.data) {
			// 		this.$store.commit('setMenu', res.data)
			// 	} else {
			// 		if (res.message) {
			// 			this.$toast(res)
			// 		}
			// 	}
			// })
			this.$api.account.selectAllSupplierButton().then((res) => {
				if (res.success && res.data) {
					sessionStorage['buttenpremissions'] = JSON.stringify(
						res.data
					)
				} else {
					if (res.message) {
						this.$toast(res)
					}
				}
			})
		},
		getLocalTime(date) {
			return timeZoneConversion.getLocalTime(date)
		},
		findQuickEntry() {
			this.$api.home.findQuickEntry().then((res) => {
				if (res.success) {
					this.quickEntry = res.data
					this.selected = res.data.map((item) => {
						return item.menuId
					})
				} else {
					if (res.message) {
						this.$toast(res)
					}
				}
			})
		},
		statisticalInventory() {
			this.$api.home
				.statisticalInventory({
					supplierId: localStorage.supplierId,
				})
				.then((res) => {
					if (res.success) {
						this.inventory = res.data
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
		},
		changeProductionProgressPageNum() {
			console.log("分页参数"+this.productionProgressSearch.pageNum)
			this.$api.home
				.selectProductionProgress(this.productionProgressSearch)
				.then((res) => {
					if (res.success) {
						this.productionProgressRows = res.total
						this.productionProgressItems = res.data
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
		},
		changeEmptyCartridgesPageNum() {
			this.$api.home.list(this.emptyCartridgesSearch).then((res) => {
				if (res.success) {
					this.emptyCartridgesRows = res.total
					this.emptyCartridgesItems = res.data.map((item) => {
						return {
							applicationInstructions:
								item.applicationInstructions,
							model: JSON.parse(item.model),
						}
					})
				} else {
					if (res.message) {
						this.$toast(res)
					}
				}
			})
		},
		openModal() {
			this.$api.home.selectFastMenu().then((res) => {
				if (res.success) {
					this.allQuickEntry = res.data
					this.modalShow = !this.modalShow
				} else {
					if (res.message) {
						this.$toast(res)
					}
				}
			})
		},
		reset() {},
		onReset(evt) {
			evt.preventDefault()
			this.modalShow = !this.modalShow
		},
		onSubmit(evt) {
			evt.preventDefault()
			this.$api.home
				.saveOrUpdateQuickEntry({ menuId: this.selected })
				.then((res) => {
					if (res.success) {
						this.modalShow = !this.modalShow
						this.findQuickEntry()
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.requiredRow {
	&:before {
		content: '*';
		color: red;
		padding-right: 2px;
	}
}
</style>
