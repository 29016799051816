<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />
    <b-form @submit="onSubmit" @reset="onReset">
      <b-card>
        <b-row>
          <b-col cols="10">
            <!-- 油类型 -->
            <form-row>
              <b-form-group
                label="Business Type:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="oilType"
                label-class="requiredRow"
              >
                <b-form-select
                  id="oilType"
                  v-model="form.oilType"
                  :options="types.oilType"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                ></b-form-select>
              </b-form-group>
            </form-row>

            <!-- 产品类型 -->
            <form-row>
              <b-form-group
                label="Product Type:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="productType"
                label-class="requiredRow"
              >
                <b-form-select
                  id="productType"
                  v-model="form.type"
                  :options="types.productType"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                ></b-form-select>
              </b-form-group>
            </form-row>

            <!-- 产品激活类型 -->
            <form-row>
              <b-form-group
                label="Activation Type:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="activationType"
                label-class="requiredRow"
              >
                <b-form-select
                  id="activationType"
                  v-model="form.activationType"
                  :options="types.activationType"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                ></b-form-select>
              </b-form-group>
            </form-row>

            <!-- 曲线类型 -->
            <form-row>
              <b-form-group
                label="Curve Type:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="curveType"
                label-class="requiredRow"
              >
                <b-form-select
                  id="curveType"
                  v-model="form.curveType"
                  :options="types.curveType"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                ></b-form-select>
              </b-form-group>
            </form-row>

            <!--产品系列名称 -->
            <form-row>
              <b-form-group
                label="Product Series Name:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="productSeriesName"
                label-class="requiredRow"
              >
                <b-form-input
                  id="productSeriesName"
                  v-model="form.productSeriesName"
                  trim
                  required
                  maxlength="50"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                  placeholder="Please enter"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <div style="text-align: center; margin-bottom: 1em">
                  {{ form.productSeriesName.length }}/50
                </div>
              </template>
            </form-row>

            <!--产品系列描述 -->
            <form-row>
              <b-form-group
                label="Product Series Introduction:"
                label-cols-lg="3"
                label-align-sm="right"
                label-for="productSeriesIntroductio"
                label-class="requiredRow"
              >
                <b-form-textarea
                  id="remark"
                  v-model="form.remarks"
                  placeholder="Please enter"
                  rows="6"
                  maxlength="1000"
                  max-rows="6"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                ></b-form-textarea>
              </b-form-group>
              <template #right>
                <div style="text-align: center; margin-bottom: 1em">
                  {{ form.remarks.length }}/1000
                </div>
              </template>
            </form-row>
          </b-col>
        </b-row>

        <b-row class="align-items-center">
          <b-col cols="12">
            <div class="blockquote">
              <h4>Specifications and Models:</h4>
            </div>
          </b-col>
        </b-row>

        <b-row v-for="(item, key) in form.specifications" :key="key">
          <b-col cols="10" class="specification">
            <div class="vo-column">
              <!-- 适配设备 -->
              <form-row>
                <b-form-group
                  label="Available Compatibility:"
                  label-cols-lg="3"
                  label-align-lg="right"
                  label-class="requiredRow"
                  label-for="availableCompatibilityId"
                >
                  <b-form-radio
                    class="custom-control-inline pt-2"
                    v-model="form.specifications[key].availableCompatibilityId"
                    value="1"
                    required
                    >OMNI NFC
                  </b-form-radio>
                  <b-form-radio
                    class="custom-control-inline pt-2"
                    v-model="form.specifications[key].availableCompatibilityId"
                    value="2"
                    required
                    >OMNI Hub
                  </b-form-radio>
                </b-form-group>
              </form-row>
              <!-- 容量 -->
              <form-row>
                <b-form-group
                  label="Available Tank Size:"
                  label-cols-lg="3"
                  label-align-lg="right"
                  label-class="requiredRow"
                  label-for="availableTankSize"
                >
                  <b-form-radio
                    v-for="(item, i) in types.availableTankSize"
                    :key="i"
                    class="custom-control-inline pt-2"
                    v-model="form.specifications[key].taskCapacityId"
                    :value="item.id"
                    required
                  >
                    {{ item.value }}
                  </b-form-radio>
                </b-form-group>
              </form-row>
              <!-- 尼古丁成分 -->
              <!-- <form-row v-if="form.oilType == 1">
                <b-form-group
                  label="Nic Ingredients:"
                  label-cols-lg="3"
                  label-align-lg="right"
                  label-for="nicIngredients"
                  label-class="requiredRow"
                >
                  <b-form-input
                    id="nicIngredients"
                    v-model="form.nicIngredients"
                    trim
                    required
                    oninvalid="setCustomValidity('Please enter only numbers and a decimal point.');"
                    oninput="setCustomValidity('');"
                    pattern="^\d*\.?\d*$"
                  ></b-form-input>
                </b-form-group>
                <template #right>
                  <div style="text-align: center; margin-bottom: 1em">%</div>
                </template>
              </form-row> -->

              <!-- 阻值 -->
              <form-row>
                <b-form-group
                  label="Resistance Value:"
                  label-cols-lg="3"
                  label-align-lg="right"
                  label-for="resistanceValue"
                  label-class="requiredRow"
                >
                  <b-form-input
                    id="productSeriesName"
                    v-model="form.specifications[key].resistance"
                    trim
                    required
                    oninvalid="setCustomValidity('Please enter only numbers and a decimal point.');"
                    oninput="setCustomValidity('');"
                    pattern="^\d*\.?\d*$"
                  ></b-form-input>
                </b-form-group>
                <template #right>
                  <div style="text-align: center; margin-bottom: 1em">Ω</div>
                </template>
              </form-row>
              <!-- 最大输出电压 -->
              <form-row>
                <b-form-group
                  label="Max Output Voltage:"
                  label-cols-lg="3"
                  label-align-lg="right"
                  label-for="maxOutputVoltage"
                  label-class="requiredRow"
                >
                  <b-form-input
                    id="maxOutputVoltage"
                    v-model="form.specifications[key].maxOutputVoltage"
                    trim
                    required
                    oninvalid="setCustomValidity('Please enter only numbers and a decimal point.');"
                    oninput="setCustomValidity('');"
                    pattern="^\d*\.?\d*$"
                  ></b-form-input>
                </b-form-group>
                <template #right>
                  <div style="text-align: center; margin-bottom: 1em">V</div>
                </template>
              </form-row>
              <!-- 其他选项-->
              <form-row>
                <b-form-group
                  label="Other Option:"
                  label-cols-lg="3"
                  label-align-lg="right"
                  label-for="otherOptionId"
                  label-class="requiredRow"
                >
                  <b-form-select
                    id="otherOptionId"
                    v-model="form.specifications[key].otherOptionId"
                    :options="types.otherOption"
                    required
                    oninvalid="setCustomValidity('Please enter');"
                  ></b-form-select>
                </b-form-group>
                <template #right>
                  <div style="text-align: center; margin-bottom: 1em">
                    Please go to the
                    <a href="#" class="custom-color" @click="clickPublicInfo"
                      >Public Info Mgmt</a
                    >
                    page to create new option
                  </div>
                </template>
              </form-row>
              <!-- 产品代号 -->
              <form-row>
                <b-form-group
                  label="Product Model #:"
                  label-cols-lg="3"
                  label-align-lg="right"
                  label-for="productSeriesName"
                  label-class="requiredRow"
                >
                  <b-form-input
                    id="productSeriesName"
                    v-model="form.specifications[key].modelName"
                    trim
                    required
                    maxlength="50"
                    oninvalid="setCustomValidity('Please enter');"
                    oninput="setCustomValidity('');"
                    placeholder="Please enter"
                  ></b-form-input>
                </b-form-group>
                <template #right>
                  <div style="text-align: center; margin-bottom: 1em">
                    {{ item.modelName.length }}/50
                  </div>
                </template>
              </form-row>
              <!-- 产品代码 -->
              <form-row>
                <b-form-group
                  label="Variant Code #:"
                  label-cols-lg="3"
                  label-align-lg="right"
                  label-for="variantCode"
                  label-class="requiredRow"
                >
                  <b-form-input
                    id="variantCode"
                    v-model="form.specifications[key].variantCode"
                    trim
                    required
                    maxlength="50"
                    oninvalid="setCustomValidity('Please enter');"
                    oninput="setCustomValidity('');"
                    placeholder="Please enter"
                  ></b-form-input>
                </b-form-group>
                <template #right>
                  <div style="text-align: center; margin-bottom: 1em">
                    {{ item.variantCode.length }}/50
                  </div>
                </template>
              </form-row>
              <!-- 营销名称 -->
              <form-row>
                <b-form-group
                  label="Marketing Name:"
                  label-cols-lg="3"
                  label-align-lg="right"
                  label-for="marketingName"
                  label-class="requiredRow"
                >
                  <b-form-input
                    id="marketingName"
                    v-model="form.specifications[key].deviceName"
                    trim
                    required
                    maxlength="50"
                    oninvalid="setCustomValidity('Please enter');"
                    oninput="setCustomValidity('');"
                    placeholder="Please enter"
                  ></b-form-input>
                </b-form-group>
                <template #right>
                  <div style="text-align: center; margin-bottom: 1em">
                    {{ item.deviceName.length }}/50
                  </div>
                </template>
              </form-row>
              <!-- 照片 -->
              <form-row>
                <b-form-group
                  label="Product Picture:"
                  label-cols-lg="3"
                  label-align-lg="right"
                  label-for="brand-logo"
                  label-class="requiredRow"
                >
                  <input
                    type="file"
                    class="filestyle"
                    data-input="false"
                    :id="'filestyle' + key"
                    style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
                    tabindex="-1"
                    @change="upload($event, key)"
                    oninvalid="setCustomValidity('Please upload');"
                    oninput="setCustomValidity('');"
                    accept="image/*"
                    multiple
                  />

                  <div class="bootstrap-filestyle input-group">
                    <div
                      name="filedrag"
                      style="
                        position: absolute;
                        width: 100%;
                        height: 35px;
                        z-index: -1;
                      "
                    ></div>
                    <span class="group-span-filestyle" tabindex="0">
                      <!-- <div v-if="form.deviceImage" style="margin-bottom: 10px">
                    <img
                      :src="form.deviceImage"
                      style="
                        width: 100px;
                        height: 100px;
                        border: 2px dashed #ccc;
                      "
                    />
                    <span
                      style="margin-left: 10px; vertical-align: bottom"
                      v-if="imageName"
                    >
                      <span style="font-weight: 500"> name : </span
                      >{{ imageName }}</span
                    >
                  </div> -->
                      <b-button
                        variant="primary"
                        class="custom-width"
                        @click="uploadFile(key)"
                        >upload picture</b-button
                      >
                      <span>
                        Recommended size of picture:490 pixels * 490 pixels,
                        supporting JPG and PNG formats
                      </span>
                    </span>
                  </div>
                  <b-row v-if="item.productPicture" class="mt-4">
                    <b-col
                      v-for="(pictureItem, pictureKey) in item.productPicture"
                      :key="pictureKey"
                      lg="3"
                    >
                      <product-picture
                        :item="pictureItem"
                        @deletePicture="deletePicture(pictureItem, key)"
                        @setCover="setCover(pictureItem, key)"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
              </form-row>
              <!-- 状态 -->
              <form-row>
                <b-form-group
                  label="Status:"
                  label-cols-lg="3"
                  label-align-lg="right"
                  label-class="requiredRow"
                >
                  <b-form-radio
                    class="custom-control-inline pt-2"
                    v-model="form.specifications[key].status"
                    value="0"
                    required
                    >Enable
                  </b-form-radio>
                  <b-form-radio
                    class="custom-control-inline pt-2"
                    v-model="form.specifications[key].status"
                    value="1"
                    required
                    >Disable
                  </b-form-radio>
                </b-form-group>
              </form-row>
            </div>
          </b-col>

          <b-col cols="2">
            <div
              class="vo-column align-items-center h-100 justify-content-center ml-4"
              style="flex: 1"
            >
              <b-button
                class="custom-width"
                variant="info"
                @click="addSpecification(key)"
                >+Add
              </b-button>
              <b-button
                v-if="
                  (editId > 0 &&
                    key > 0 &&
                    form.specifications[key].removeStatus) ||
                  (!editId && key > 0)
                "
                class="custom-width mt-1"
                variant="info"
                @click="deleteSpecification(key, item)"
                >-Delete
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-form-group label-cols-lg="3">
        <b-button type="submit" class="custom-width" variant="primary"
          >Save</b-button
        >
        <b-button type="reset" class="ml-5 custom-width">Cancel </b-button>
      </b-form-group>
    </b-form>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel"
      @hide="hide"
    >
      Saved successfully
    </Dialog>
    <!-- 错误提示框 -->
    <Dialog
      v-model="errorAlear"
      :imageSrc="require('@/assets/confirm.svg')"
      noBtn="OK"
      @cancel="cancel1"
      @hide="hide1"
    >
      {{ errorContent }}
    </Dialog>
  </Layout>
</template>
<style scoped>
.specification {
  flex: 3;
  margin-bottom: 10px;
  background-color: rgba(242, 242, 242, 1);
}
</style>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img, upload_multiple_file } from "@/api/upload";
import goback from "@/components/goback";
import FormRow from "@/components/FormRow.vue";
import ProductPicture from "@/components/product/ProductPicture.vue";
import Dialog from "@/components/Dialog";

/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    goback,
    FormRow,
    ProductPicture,
    Dialog,
  },
  data() {
    return {
      page: {
        title: "Create Omni Products",
        items: [
          {
            text: this.$store.getters.getTitle,
          },
          {
            text: "Production Mgmt",
          },
          {
            text: "Omni Products",
          },
          {
            text: this.editId ? "Modify" : "Create",
            active: true,
          },
        ],
      },
      form: {
        id: "",
        remarks: "",
        deviceImage: "",
        type: "",
        curveType: 1,
        oilType: "",
        activationType: "",
        productSeriesName: "",
        nicIngredients: "",
        curveType: "",
        specifications: [
          {
            id: 0,
            availableCompatibilityId: 1,
            taskCapacityId: 1,
            resistance: 0,
            maxOutputVoltage: 0,
            otherOptionId: "",
            variantCode: "",
            modelName: "",
            deviceName: "",
            status: 0,
            carouselImage: "",
            removeStatus: false,
            productPicture: [],
          },
        ],
        deleteProductId: [],
      },
      imageName: "",
      types: {
        curveType: [
          { text: "--Select Curve Type--", value: "", disabled: false },
          { text: "One curve", value: 2, disabled: false },
          { text: "Two curve", value: 1, disabled: false },
        ],
        oilType: [
          { text: "--Select Business Type--", value: "", disabled: false },
          { text: "CBD/THC Concentrates", value: 2, disabled: false },
          { text: "Nicotine", value: 1, disabled: false },
        ],
        productType: [
          { text: "--Select Product Type--", value: "", disabled: false },
          { text: "Disposable", value: 3, disabled: false },
          { text: "Batteries", value: 7, disabled: false },
        ],
        activationType: [
          { text: "--Select Activation Type--", value: "", disabled: false },
          { text: "Button Activated", value: 1, disabled: false },
          { text: "Inhate Activated", value: 2, disabled: false },
        ],
        otherOption: [],
        availableTankSize: [],
        objectURLs: [],
      },
      editId: window.location.search,
      editor: ClassicEditor,
      successAlear: false,
      errorAlear: false,
      errorContent: "",
    };
  },
  beforeCreate() {
    this.editId = window.location.search;
  },
  created() {
    this.init();
    if (this.editId) {
      let id = this.editId.split("=")[1];
      this.editId = id;
      this.formData(id);
    }
  },
  mounted() {},
  methods: {
    onReady: onReady,
    init() {
      this.selectAllSpecifications();
      this.selectOherList();
    },

    selectAllSpecifications() {
      this.$api.CartridgeModel.selectAllSpecifications({ type: 1 })
        .then((x) => {
          this.types.availableTankSize = x.data.map((item) => {
            return {
              id: item.id,
              value: item.value,
            };
          });
        })
        .catch((e) => {
          this.has_data = false;
        });
    },
    selectOherList() {
      this.$api.CartridgeModel.selectAllSpecifications({ type: 2 })
        .then((x) => {
          this.types.otherOption = [
            { text: "----Select On Option----", value: "", disabled: false },
          ].concat(
            x.data.map((item) => {
              return {
                text: item.value,
                value: item.id,
                disabled: false,
              };
            })
          );
        })
        .catch((e) => {
          this.has_data = false;
        });
    },

    clickPublicInfo() {
      this.$router.push("productMgmt");
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.form.specifications) {
        for (let i = 0; i < this.form.specifications.length; i++) {
          let specifications = this.form.specifications[i];
          if (specifications.productPicture.length == 0) {
            this.errorAlear = true;
            this.errorContent = "Product Picture cannot be empty.";
            return;
          }
        }
      }
      if (this.form.id) {
        this.$api.CartridgeModel.productUpdate(this.form).then((res) => {
          if (res.success) {
            this.successAlear = true;
          } else {
            if (res.message) {
              this.errorAlear = true;
              this.errorContent = res.message;
            }
          }
        });
      } else {
        this.$api.CartridgeModel.productAdd(this.form).then((res) => {
          if (res.success) {
            this.successAlear = true;
          } else {
            if (res.message) {
              this.errorAlear = true;
              this.errorContent = res.message;
            }
          }
        });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.$nextTick(() => {
        this.$router.go(-1);
      });
    },
    formData(id) {
      this.$api.CartridgeModel.selectProductById({
        id: id,
      }).then((res) => {
        if (res.success) {
          this.form = res.data;
          this.form.deleteProductId = [];
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    upload(e, key) {
      const specifications = this.form.specifications[key];
      let files = e.target.files;
      let size = 0;
      let currentId = 0;
      if (
        specifications.productPicture &&
        specifications.productPicture.length > 0
      ) {
        size = specifications.productPicture.length;
        currentId = specifications.productPicture[size - 1].id;
        console.log("currentId值" + currentId);
      }
      if (size + files.length > 4) {
        this.errorAlear = true;
        this.errorContent = "No more than 4 pictures.";
        return;
      }

      upload_multiple_file(files, "Carousel/").then((res) => {
        if (res.errno == 1) {
          this.$toast({ message: "Upload Error." });
          return;
        } else {
          for (let i = 0; i < res.data.length; i++) {
            console.log(res.data);
            console.log(specifications.productPicture);
            let status = 0;
            if (i == 0 && specifications.productPicture.length == 0) {
              status = 1;
            }
            specifications.productPicture.push({
              id: i + currentId + 1,
              value: res.data[i].url,
              status: status,
            });
          }
        }
      });
    },
    uploadFile(key) {
      const filestyle = document.getElementById("filestyle" + key);
      filestyle.click();
    },
    deletePicture(deleteItem, key) {
      const specifications = this.form.specifications[key];
      const list = specifications.productPicture.filter(
        (item) => item.id !== deleteItem.id
      );
      if (list) {
        specifications.productPicture = list;
        if (deleteItem.status == 1) {
          specifications.productPicture = specifications.productPicture.map(
            (item, i) => {
              if (i == 0) {
                item.status = 1;
              }
              return item;
            }
          );
        }
      } else {
        this.specifications[key].productPicture = [];
      }
    },
    setCover(setItem, key) {
      const specifications = this.form.specifications[key];
      console.log("状态" + setItem.id);
      if (specifications.productPicture && setItem.status == 0) {
        specifications.productPicture = specifications.productPicture.map(
          (item, i) => {
            console.log("id" + item.id);
            if (item.id == setItem.id) {
              item.status = 1;
            } else {
              item.status = 0;
            }
            return item;
          }
        );
      }
    },
    addSpecification() {
      this.form.specifications.push({
        id: 0,
        availableCompatibilityId: 1,
        taskCapacityId: 1,
        resistance: 0,
        maxOutputVoltage: 0,
        otherOptionId: "",
        variantCode: "",
        modelName: "",
        deviceName: "",
        status: 0,
        productPicture: [],
      });
    },
    deleteSpecification(key, item) {
      this.form.specifications.splice(key, 1);
      if (null != item.id && item.id > 0) {
        this.form.deleteProductId.push(item.id);
        console.log("Delete" + this.form.deleteProductId);
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    hide() {
      this.$router.go(-1);
    },
    cancel1() {
      this.errorAlear = false;
    },
    hide1() {
      this.errorAlear = false;
    },
  },
};
</script>
