<script>
  export default {
    components: {},
    data() {
      return {
        brand: "",
      };
    },
    created() {
    },
    mounted() {
      this.brand = this.$store.getters.getTitle;
    },
    methods: {
    },
  };
  </script>
<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          Copyright © {{ new Date().getFullYear() }} {{this.brand }}
          <span class="d-none d-sm-inline-block">
            . All rights reserved
          </span>
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>