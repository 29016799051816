<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />
    <!-- main -->

    <b-card>
      <b-row class="vo-items-center">
        <b-col lg="4">
          <div class="vo-row vo-items-center">
            <ReferenceBy label="REFINED BY" />
            <b-input
              style="flex: 1; margin-left: 10px"
              v-model="search_condition.deviceName"
              placeholder="Search Product"
            />
          </div>
        </b-col>
      </b-row>
      <b-row style="margin-top: 4px">
        <b-col style="margin-left: 120px">
          <b-form-select
            id="oil-type"
            v-model="search_condition.status"
            :options="types.statusOptions"
          ></b-form-select>
        </b-col>
        <b-col>
          <b-form-select
            id="oil-type"
            v-model="search_condition.brandId"
            :options="types.brandOpton"
          ></b-form-select>
        </b-col>
        <b-col>
          <b-form-select
            id="oil-type"
            v-model="search_condition.oilType"
            :options="types.oilType"
          ></b-form-select>
        </b-col>
        <b-col>
          <b-form-select
            id="product-type"
            v-model="search_condition.type"
            :options="types.productType"
          ></b-form-select>
        </b-col>
        <b-col>
          <b-form-select
            id="activation-type"
            v-model="search_condition.activationType"
            :options="types.activationType"
          ></b-form-select>
        </b-col>
        <b-col>
          <b-form-select
            id="available-tank-size"
            v-model="search_condition.taskCapacityId"
            :options="types.availableTankSize"
          ></b-form-select>
        </b-col>

        <b-col>
          <div class="vo-row vo-items-center vo-justify-between">
            <div class="custom-color cursor" @click="clearAll">Clear All</div>
            <b-button variant="info" @click="search">Search</b-button>
          </div>
        </b-col>
      </b-row>
      <div class="table-responsive mb-0 box-list">
        <div v-if="isBusy">
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </div>
        <div v-else>
          <b-row
            cols-lg="5"
            cols-sm="2"
            cols-xl="6"
            cols-md="4"
            style="margin: 0"
          >
            <b-col
              class="cursor"
              v-for="(item, index) in products"
              :key="index"
              style="margin: 20px 0 0 0"
            >
              <template v-if="item.viewType === 'add'">
                <CreateProduct @click="gotoAdd" name="Create SKU" />
              </template>

              <BrandProductItem
                v-else
                :item="item"
                @clickStatus="clickStatus(item)"
                @clickDetail="clickDetail(item)"
                @clickEdit="clickEdit(item)"
                @clickFlavor="clickFlavor(item)"
                @clickCurves="clickCurves(item)"
                @clickBatch="clickBatch(item)"
              />
            </b-col>
          </b-row>
        </div>
      </div>

      <b-row v-if="has_data" style="margin-top: 10px">
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="rows != 0">
              <li>
                <p
                  class="pagination mb-0"
                  style="margin-top: 7px; margin-right: 20px"
                >
                  {{ rows }} results
                </p>
              </li>
              <b-pagination
                v-model="search_condition.pageNum"
                :per-page="btnMenu('brandMgmt::Create') ? 11 : 12"
                :total-rows="rows"
                aria-controls="table"
              >
              </b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <Dialog
      v-model="showAlert"
      :imageSrc="require('@/assets/confirm.svg')"
      okBtn="Yes"
      noBtn="No"
      @ok="disabledOrEnbleProduct"
      @cancel="cancel"
    >
      <p>{{ showContent }}</p>
    </Dialog>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel1"
    >
      <p>Saved successfully</p>
    </Dialog>

    <ProductDetail
      v-model="showDetailAlear"
      noBtn="OK"
      @cancel="cancel2"
      :productDetail="productDetail"
    />
    <Dialog
      v-model="errorAlear"
      :imageSrc="require('@/assets/confirm.svg')"
      noBtn="OK"
      @cancel="cancel3"
    >
      {{ errorContent }}
    </Dialog>
  </Layout>
</template>

<style scoped>
.containerImage img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}
.productImage {
  display: flex; /* 使用 Flexbox 来居中图片和文字 */
  flex-direction: column;
  align-items: center; /* 水平居中 */
  text-align: center; /* 文字居中 */
}
.outerBox {
  border: 2px dashed #02a7f0;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5); /* 添加阴影效果 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex; /* 使用 Flexbox 来居中图片和文字 */
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 3;
  flex-direction: column;
  justify-content: space-between;
}

.disableBox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex; /* 使用 Flexbox 来居中图片和文字 */
  background-color: rgba(242, 242, 242, 0.7);
  z-index: 3;
  flex-direction: column;
}
.disableLable {
  margin-top: 0px;
}
.topButton {
  display: flex; /* 使用 Flexbox 来居中图片和文字 */
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 5px;
}

.belowButton {
  display: flex; /* 使用 Flexbox 来居中图片和文字 */
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 5px;
}
.topButton img {
  width: 27px;
  height: 27px;
  margin-right: 10px;
}
.productImage img {
  height: 190px;
}

.caption {
  font-size: 14px;
  color: #02a7f0;
  text-align: center;
  margin-bottom: -30px;
}

.productName {
  font-size: 14px;
  text-align: center;
  margin-bottom: -20px;
}
.icon-class img {
  width: 34px;
  height: 41px;
}
.icon-class {
  text-align: center;
}
</style>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Dialog from "@/components/Dialog";
import ProductDetail from "@/components/product/ProductDetail";
import BrandProductItem from "@/components/product/brandProductItem";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import ReferenceBy from "@/components/FindByLabel.vue";
import CreateProduct from "@/components/product/CreateProduct.vue";

/**
 * FAQ component
 */
export default {
  components: {
    CreateProduct,
    Layout,
    PageHeader,
    DatePicker,
    timeZoneConversion,
    Dialog,
    BrandProductItem,
    ReferenceBy,
    ProductDetail,
  },
  data() {
    return {
      page: {
        title: "Brand Product List",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "Client&Brand",
            // href: "/"
          },
          {
            text: "Brand Product List",
            active: true,
          },
        ],
      },
      totalRows: 0,
      search_condition: {
        pageNum: 1,
        pageSize: this.btnMenu("brandMgmt::Create") ? 11 : 12,
        deviceName: "",
        deviceModelId: "",
        oilType: "",
        type: "",
        activationType: "",
        taskCapacityId: "",
        status: "",
        brandId: this.$route.query.brandId ? this.$route.query.brandId : "",
        skuType: 2,
        seriesId: this.$route.query.productId
          ? this.$route.query.productId
          : "",
      },
      has_data: false,
      types: {
        statusOptions: [
          { text: "--All Status--", value: "", disabled: false },
          { text: "Enable", value: 0, disabled: false },
          { text: "Disable", value: 1, disabled: false },
        ],
        brandOpton: [{ text: "--All Brand--", value: "", disabled: false }],
        oilType: [
          { text: "--All Business Type--", value: "", disabled: false },
          { text: "CBD/THC Concentrates", value: 2, disabled: false },
          { text: "Nicotine", value: 1, disabled: false },
        ],
        productType: [
          { text: "--All Product Type--", value: "", disabled: false },
          { text: "Disposable", value: 3, disabled: false },
          { text: "Batteries", value: 7, disabled: false },
        ],
        activationType: [
          { text: "--All Activation Type--", value: "", disabled: false },
          { text: "Button Activated", value: 1, disabled: false },
          { text: "Inhate Activated", value: 2, disabled: false },
        ],
        availableTankSize: [
          { text: "--All Tank Capacity--", value: "", disabled: false },
        ],
        selectAllDeviceModel: [{ modelName: "Please select", id: "" }],
      },
      products: [],
      showAlert: false,
      successAlear: false,
      showContent: "",
      errorContent: "",
      postData: {},
      showDetailAlear: false,
      productDetail: {},
      isBusy: false,
      errorContent: "",
      errorAlear: false,
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.productList();
      this.selectAllSpecifications();
      this.selectAllBrand();
    },

    //按钮权限判断
    btnMenu(value) {
      return this.$_has(value);
    },
    productList() {
      this.isBusy = true;
      //this.$_has("productMgmt::Create")
      this.$api.CartridgeModel.productList(this.search_condition)
        .then((x) => {
          this.isBusy = false;
          this.has_data = true;
          this.totalRows = x.total;
          if (x.data) {
            this.products = x.data.map((item, i) => {
              item.showMenu = false;
              return item;
            });
          }
          if (this.btnMenu("brandMgmt::Create")) {
            this.products.unshift({
              deviceImage: require("@/assets/images/common/ic_add.svg"),
              viewType: "add",
            });
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
        });
    },

    selectAllSpecifications() {
      this.$api.CartridgeModel.selectAllSpecifications({ type: 1 })
        .then((x) => {
          this.types.availableTankSize = this.types.availableTankSize.concat(
            x.data.map((item) => {
              return {
                text: item.value,
                value: item.id,
                disabled: false,
              };
            })
          );
        })
        .catch((e) => {
          this.has_data = false;
        });
    },
    selectAllBrand() {
      this.$api.Dropdown.selectAllSkuBrand()
        .then((x) => {
          this.types.brandOpton = this.types.brandOpton.concat(
            x.data.map((item) => {
              return {
                text: item.brandName,
                value: item.id,
                disabled: false,
              };
            })
          );
        })
        .catch((e) => {
          this.has_data = false;
        });
    },
    clickEdit(item) {
      this.$router.push(
        "modifySku?brandId=" +
          this.$route.query.brandId +
          "&brandSeriesId=" +
          item.brandSeriesId +
          "&id=" +
          item.id
      );
    },
    clickFlavor(item) {
      this.$router.push(
        "flavorMgmt?brandId=" +
          this.$route.query.brandId +
          "&brandSeriesId=" +
          item.id
      );
    },
    clickCurves(item) {
      if (!this.btnMenu("curvesMgmt::View")) {
        this.errorAlear = true;
        this.errorContent = "This account does not have permission to list.";
      } else {
        this.$router.push(
          "curvesMgmt?brandId=" +
            this.$route.query.brandId +
            "&brandSeriesId=" +
            item.id
        );
      }
    },

    clickBatch(item) {
      if (this.isAccountLevel()) {
        this.$router.replace(
          "/productionMgmt/productionBatchMgmt?productId=" + item.id
        );
      } else {
        this.$router.push("brandProductionBatchMgmt?productId=" + item.id);
      }
    },

    search() {
      this.search_condition.pageNum = 1;
      this.productList();
    },
    clearAll() {
      this.search_condition = {
        pageNum: 1,
        pageSize: this.btnMenu("brandMgmt::Create") ? 11 : 12,
        deviceName: "",
        deviceModelId: "",
        oilType: "",
        type: "",
        activationType: "",
        taskCapacityId: "",
        status: "",
        brandId: this.$route.query.brandId ? this.$route.query.brandId : "",
        skuType: 2,
        seriesId: this.$route.query.productId
          ? this.$route.query.productId
          : "",
      };
      this.productList();
    },
    gotoAdd() {
      this.$router.push("choiceProduct?brandId=" + this.$route.query.brandId);
    },
    //确认是否禁用或者启用弹框
    clickStatus(item) {
      this.showAlert = true;
      let status = 0;
      if (item.status == 0) {
        status = 1;
        this.showContent = "Are sure to disable this product?";
      } else {
        this.showContent = "Are sure to enble this product?";
      }
      this.postData = {
        id: item.id,
        status: status,
      };
    },
    //点击详情
    clickDetail(item) {
      this.$router.push("brandProductMgmtInfo?id=" + item.id);
    },
    //确定禁用或者启用
    disabledOrEnbleProduct() {
      this.$api.CartridgeModel.enableOrDisableById(this.postData)
        .then((res) => {
          if (res.success) {
            this.showAlert = false;
            this.successAlear = true;
            this.productList();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        })
        .catch((error) => {});
    },
    cancel() {
      this.showAlert = false;
    },
    cancel1() {
      this.successAlear = false;
    },
    cancel2() {
      this.showDetailAlear = false;
    },
    cancel3() {
      this.errorAlear = false;
    },
    isAccountLevel() {
      return localStorage.accountLevel == 0;
    },
  },
  watch: {
    "search_condition.pageNum"() {
      this.productList();
    },
  },
};
</script>
