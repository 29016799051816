<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <b-form @submit="onSubmit" @reset="onReset">
      <b-card>
        <b-row>
          <b-col lg="10">
            <!-- 验证码 -->
            <FormRow>
              <b-form-group
                label="Invitation Code:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="invitation-code"
                label-class="requiredRow"
              >
                <b-form-input
                  id="invitation-code"
                  v-model="form.invitationCode"
                  placeholder="Please enter a 4 digits or letters"
                  required
                  type="number"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');if(value.length >4)value = value.slice(0, 4)"
                >
                </b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="
                    form.invitationCode
                      ? form.invitationCode.length > 4
                        ? 4
                        : form.invitationCode.length
                      : 0
                  "
                  max="4"
                />
              </template>
            </FormRow>

            <FormRow>
              <b-form-group
                label="Code type:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="code-type"
                label-class="requiredRow"
              >
                <b-form-radio-group
                  id="code-type-1"
                  v-model="form.codeType"
                  :options="codeType"
                  name="code-type"
                  style="padding-top: 7px"
                >
                </b-form-radio-group>
              </b-form-group>
            </FormRow>

            <FormRow v-if="form.codeType == 1">
              <b-form-group
                label="Role:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="roleId"
                label-class="requiredRow"
              >
                <b-form-select
                  id="roleId"
                  v-model="form.roleId"
                  :options="roleList"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                ></b-form-select>
              </b-form-group>
            </FormRow>

            <FormRow v-if="form.codeType == 2">
              <b-form-group
                label="Brand:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="brandId"
                label-class="requiredRow"
              >
                <b-form-select
                  id="brandId"
                  v-model="form.brandId"
                  :options="brandList"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                ></b-form-select>
              </b-form-group>
            </FormRow>

            <FormRow>
              <b-form-group
                label="Useful Time:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="use-type"
                label-class="requiredRow"
              >
                <b-form-radio-group
                  id="use-type-1"
                  v-model="form.useType"
                  :options="useType"
                  name="use-type"
                  style="padding-top: 7px"
                >
                </b-form-radio-group>
              </b-form-group>
            </FormRow>

            <FormRow>
              <b-form-group
                label="Code Notes:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="invitation-code"
                label-class="requiredRow"
              >
                <b-form-input
                  id="invitation-code"
                  v-model="form.remarks"
                  placeholder="Please enter a code notes"
                  required
                  maxlength="50"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                >
                </b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.remarks ? form.remarks.length : 0"
                  max="50"
                />
              </template>
            </FormRow>
          </b-col>
        </b-row>
      </b-card>
      <b-form-group label-cols-lg="3">
        <b-button type="submit" variant="primary" class="custom-width"
          >Confirm</b-button
        >
        <b-button type="reset" class="ml-4 custom-width">Cancel </b-button>
      </b-form-group>
    </b-form>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel"
      @hide="hide"
    >
      Saved successfully
    </Dialog>
    <!-- 错误提示框 -->
    <Dialog
      v-model="errorAlear"
      :imageSrc="require('@/assets/confirm.svg')"
      noBtn="OK"
      @cancel="cancel1"
      @hide="hide1"
    >
      {{ errorContent }}
    </Dialog>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img } from "@/api/upload";
import goback from "@/components/goback";
import FormRow from "@/components/FormRow.vue";
import CountText from "@/components/CountText.vue";
import Dialog from "@/components/Dialog";

/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    goback,
    FormRow,
    CountText,
    Dialog,
  },
  data() {
    return {
      page: {
        title: "Create Invitation Code",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "Client Mgmt",
            // href: "/"
          },
          {
            text: "Create Invitation Code",
            active: true,
          },
        ],
      },
      form: {
        invitationCode: "",
        codeType: 1,
        brandId: "",
        useType: 1,
        remarks: "",
        roleId: "",
      },
      codeType: [
        { text: "Operator Code", value: 1, disabled: false },
        { text: "User Code", value: 2, disabled: false },
      ],
      brandList: [{ text: "--All Brand--", value: "", disabled: false }],
      roleList: [{ text: "--All Role--", value: "", disabled: false }],
      useType: [
        { text: "OnceTime", value: 1, disabled: false },
        { text: "Unlimited", value: 2, disabled: false },
      ],
      editId: window.location.search,
      editor: ClassicEditor,
      successAlear: false,
      errorAlear: false,
      errorContent: "",
    };
  },
  beforeCreate() {},
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    onReady: onReady,
    init() {
      this.selectClientBrand();
      this.selectInvitationRole();
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$api.invitationCode.invitationCodeAdd(this.form).then((res) => {
        if (res.success) {
          this.successAlear = true;
        } else {
          if (res.message) {
            this.errorAlear = true;
            this.errorContent = res.message;
          }
        }
      });
    },
    selectClientBrand() {
      this.$api.invitationCode.selectClientBrand()
        .then((x) => {
          this.brandList = this.brandList.concat(
            x.data.map((item) => {
              return {
                text: item.brandName,
                value: item.id,
                disabled: false,
              };
            })
          );
        })
        .catch((e) => {
          this.has_data = false;
        });
    },

    selectInvitationRole() {
      this.$api.invitationCode
        .selectInvitationRole()
        .then((x) => {
          this.roleList = this.roleList.concat(
            x.data.map((item) => {
              return {
                text: item.roleName,
                value: item.id,
                disabled: false,
              };
            })
          );
        })
        .catch((e) => {
          this.has_data = false;
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.$nextTick(() => {
        this.$router.go(-1);
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    hide() {
      this.$router.go(-1);
    },
    cancel1() {
      this.errorAlear = false;
    },
    hide1() {
      this.errorAlear = false;
    },
  },
};
</script>
