<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <!-- 品牌名称 客户 -->
            <b-col lg="3">
              <b-form-group
                label="Brand Name"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="brandName"
              >
                <b-form-input
                  id="brandName"
                  trim
                  v-model="search_condition.brandName"
                  oninput="if(value.length > 20)value = value.slice(0, 20)"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <!-- 品牌名称 客户 -->
            <b-col lg="3">
              <b-form-group
                label="Flavor Name"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="flavorName"
              >
                <b-form-input
                  id="flavorName"
                  trim
                  v-model="search_condition.flavorName"
                  oninput="if(value.length > 20)value = value.slice(0, 20)"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <!-- Create Time 操作时间 -->
            <b-col lg="3">
              <b-form-group
                label="Create Time"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="operation-time"
              >
                <date-picker
                  v-model="selectTime"
                  format="MM/DD/YYYY"
                  range
                  append-to-body
                  lang="en"
                  placeholder="Select date"
                ></date-picker>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <!-- <b-form-group label label-cols-sm="4" label-align-sm="right"> -->
              <b-button variant="primary" @click="search()">Search</b-button>
              <!-- </b-form-group> -->
            </b-col>
          </b-row>

          <b-row class="text-center">
            <b-col lg="12"></b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="No records"
              id="device-table"
              head-variant="light"
              :striped="true"
              ref="table"
              :busy="isBusy"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">{{
                data.index +
                (search_condition.pageNum - 1) * search_condition.pageSize +
                1
              }}</template>
              <template v-slot:cell(flavorPicture)="data">
                <img
                  v-if="data.item.flavorPicture"
                  :src="`${data.item.flavorPicture}`"
                  alt
                  class="avatar-xs rounded-circle mr-2"
                />
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->

    <!-- <div class="text-center">
			<b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>-->
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

/**
 * User component
 */
export default {
  components: {
    DatePicker,
    Layout,
    PageHeader,
    timeZoneConversion,
  },
  data() {
    return {
      title: "Brand Records",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "All Histories",
          // href: "/"
        },
        {
          text: "Brand Records",
          active: true,
        },
      ],

      search_condition: {
        client: "", //客户名称
        deviceModelId: "", //设备型号
        startTime: "", //开始时间
        endTime: "", //结束时间
        pageNum: 1,
        pageSize: 10,
        supplierId: localStorage.supplierId,
        supplierType: 0,
      },
      selectTime: ["", ""],

      types: {
        deviceList: [{ modelName: "Please select", id: "" }], //适用设备列表
      },

      fields: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "brandName", //品牌名称
          label: "Brand Name",
          class: "text-center",
        },
        {
          key: "flavorName", //口味名称
          label: "Flavor Name",
          class: "text-center",
        },
        { key: "flavorPicture", label: "Flavor Picture", class: "text-center" },
        {
          key: "deviceTypeName", //设备类型
          label: "Device",
          class: "text-center",
        },
        {
          key: "scanType", //扫描类型
          label: "Operation",
          class: "text-center",
          formatter: (value) => {
            return value == 1 ? "QR CODE" : "NFC";
          },
        },
        {
          key: "time", //操作时间
          label: "Create Time",
          formatter: (value) => {
            return timeZoneConversion.getLocalTimes(value);
          },
          class: "text-center",
        },
      ],

      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      isBusy: false,
      has_data: false,
    };
  },
  watch: {
    selectTime: function (val) {
      this.search_condition.startTime = val[0]
        ? timeZoneConversion.queryTime(val[0])
        : val[0];
      if (val[1]) {
        this.search_condition.endTime = timeZoneConversion.queryTime(
          val[1].getTime() + 24 * 3600 * 1000 - 1
        );
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  created() {},
  methods: {
    provider(ctx) {
      this.isBusy = true;
      return this.$api.Client.selectScanRecord(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
