<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Steps :Steps="registSpets" :SetData="SetData" />
    <b-card>
      <b-col lg="12">
        <p class="title" style="font-size: 16px; font-weight: bolder">
          <span
            style="
              display: inline-block;
              width: 10px;
              height: 20px;
              vertical-align: text-top;
              background-color: #626ed4;
            "
          ></span>
          Batch Info
        </p>
      </b-col>
      <b-tabs pills style="margin: 20px 0">
        <b-tab title="Batch Details" active
          ><b-container>
            <b-row cols="3">
              <b-col>
                <label class="col-form-label">Batch Name：</label>
                <span class="p-3">{{ batchDetailsVo.batchName }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Batch ID：</label>
                <span class="p-3">{{ batchDetailsVo.number }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Devices Type：</label>
                <span class="p-3">{{ batchDetailsVo.smokeModel }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Oil Flavor</label>
                <span class="p-3">{{ batchDetailsVo.modelName }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Amount to Fill Per Product(mg/ml):</label>
                <span class="p-3">{{ batchDetailsVo.capacity }}</span>
              </b-col>
              <b-col v-if="ikruser">
                <label class="col-form-label">Vaporization Time Limit:</label>
                <span class="p-3">{{ batchDetailsVo.maxFrequency }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Batch Quantity:</label>
                <span class="p-3">{{ batchDetailsVo.batchQuantity }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Filling Factory:</label>
                <span class="p-3">{{ batchDetailsVo.manufacturerName }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Notes:</label>
                <span class="p-3">{{ batchDetailsVo.remarks }}</span>
              </b-col>
            </b-row>
          </b-container></b-tab
        >
        <b-tab title="Content Details"
          ><b-container>
            <b-row cols="3">
              <b-col>
                <label class="col-form-label">Content Type: </label>
                <span class="p-3">{{ smokeOilDetailsVo.type }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Brand：</label>
                <span class="p-3">{{ smokeOilDetailsVo.brandName }}</span>
              </b-col>
            </b-row>
          </b-container></b-tab
        >
        <b-tab title="Product Details" v-if="ikruser"
          ><b-container>
            <b-row cols="3">
              <b-col>
                <label class="col-form-label">Model Name: </label>
                <span class="p-3">{{ testSmokeBombModelVo.modelName }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Heating Wire Material: </label>
                <span class="p-3">{{
                  testSmokeBombModelVo.heatingWireMaterial
                }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Heating Wire Size: </label>
                <span class="p-3">{{
                  testSmokeBombModelVo.heatingWireSize
                }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Heating Wire Porosity: </label>
                <span class="p-3">{{
                  testSmokeBombModelVo.heatingWirePorosity
                }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Oil Hole: </label>
                <span class="p-3">{{ testSmokeBombModelVo.oilInlet }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Resistance: </label>
                <span class="p-3">{{
                  testSmokeBombModelVo.resistance
                    ? testSmokeBombModelVo.resistance + "Ω"
                    : ""
                }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Package Cotton: </label>
                <span class="p-3">{{
                  testSmokeBombModelVo.cottonBag == 0
                    ? "Yes"
                    : testSmokeBombModelVo.cottonBag == 1
                    ? "No"
                    : ""
                }}</span>
              </b-col>
            </b-row>
          </b-container></b-tab
        >
        <b-tab title="Heating&Preheating Mode" v-if="ikruser">
          <b-container>
            <span style="font-weight: 700">Heating Mode</span>
            <b-table
              id="heating-table"
              head-variant="light"
              show-empty
              empty-text="No records"
              ref="table"
              :busy="heatingIsBusy"
              :striped="true"
              :items="heatingModeDetailsVo"
              :fields="heatingModefields"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
            <span style="font-weight: 700">Preheating Mode</span>
            <b-table
              id="preheating-table"
              head-variant="light"
              show-empty
              empty-text="No records"
              ref="table"
              :busy="preheatingIsBusy"
              :striped="true"
              :items="warmUpModeDetailsVo"
              :fields="warmUpModefields"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-container></b-tab
        >
      </b-tabs>
    </b-card>
    <b-card v-if="produceStatus == 3">
      <b-col lg="12">
        <p class="title" style="font-size: 16px; font-weight: bolder">
          <span
            style="
              display: inline-block;
              width: 10px;
              height: 20px;
              vertical-align: text-top;
              background-color: #626ed4;
            "
          ></span>
          COA Info
        </p>
      </b-col>
      <b-container style="width: 80%">
        <b-row cols="1">
          <b-col>
            <label class="col-form-label">Sample ID: </label>
            <span class="p-3">{{ batchNumberCoaDetailsVo.sampleNumber }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Sample Name: </label>
            <span class="p-3">{{ batchNumberCoaDetailsVo.sampleName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Sample Batch ID: </label>
            <span class="p-3">{{
              batchNumberCoaDetailsVo.sampleBatchNumber
            }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Content Formula: </label>
            <span
              style="margin-left: 30px; display: flex"
              v-for="a in batchNumberCoaDetailsVo.formula"
              :key="a.id"
            >
              <span style="margin-right: 20px">{{ a.component }}</span>
              <span style="margin-right: 20px">{{ a.proportion + "%" }}</span>
              <span style="margin-right: 20px">{{
                a.status == 0 ? "Main ingredients" : "The secondary components"
              }}</span>
              <span style="margin-right: 20px">{{
                a.display == 0 ? "Show at Model X" : ""
              }}</span></span
            >
          </b-col>
          <b-col>
            <label class="col-form-label">Test Items: </label>
            <span
              style="margin-left: 30px; display: flex"
              v-for="item in batchNumberCoaDetailsVo.addReport"
              :key="item.id"
              >{{ item.reportName }}</span
            >
          </b-col>
          <b-col>
            <label class="col-form-label">Solvent: </label>
            <span
              style="margin-left: 30px; display: flex"
              v-for="b in batchNumberCoaDetailsVo.solvent"
              :key="b.id"
            >
              <span style="margin-right: 20px">{{ b.solventName }}</span>
              <span style="margin-right: 20px">{{ b.proportion + "%" }}</span>
            </span>
          </b-col>
          <b-col>
            <label class="col-form-label">Test Report: </label>
            <span class="p-3">{{ batchNumberCoaDetailsVo.reportLink }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Notes: </label>
            <span class="p-3">{{ batchNumberCoaDetailsVo.remarks }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-card>
      <b-button
        size="lg"
        type="reset"
        variant="outline-secondary"
        @click="onReset"
        >Go Back</b-button
      >
    </b-card>
  </Layout>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import Multiselect from "vue-multiselect";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Steps from "./disposable-filling-step.vue";
import { mapGetters } from 'vuex';

/**
 * Cartridge Batch Add component
 */

export default {
  components: {
    Layout,
    PageHeader,
    FormWizard,
    TabContent,
    Multiselect,
    Steps,
  },
  data() {
    return {
      title: "Disposable Filling Details",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "Production Manage",
          // href: "/"
        },
        {
          text: "Disposable Filling Details",
          active: true,
        },
      ],
      registSpets: this.produceStatus == 1 ? 1 : 3,
      SetData: [
        "Create Production Batch Order",
        "In Production",
        "Complete COA information",
        "Production Completed",
      ],
      isComplete: false,
      infoId: window.location.search,
      batchDetailsVo: {},
      smokeOilDetailsVo: {},
      testSmokeBombModelVo: {},
      heatingModeDetailsVo: [],
      warmUpModeDetailsVo: [],
      batchNumberCoaDetailsVo: {},
      produceStatus: "",
      heatingModefields: [
        { key: "modeName", label: "Mode Name", class: "text-center" },
        { key: "heatingType", label: "Mode Type", class: "text-center" },
        {
          key: "temperature",
          label: "Heating Temperature(℉)",
          class: "text-center",
        },
      ],
      warmUpModefields: [
        { key: "warmUpName", label: "Mode Name", class: "text-center" },
        {
          key: "warmUpTimes",
          label: "Preheating Time(S)",
          class: "text-center",
        },
        {
          key: "temperature",
          label: "Preheating Temperature(℉)",
          class: "text-center",
        },
      ],
      heatingIsBusy: false,
      preheatingIsBusy: false,
    };
  },
  computed: {
    ...mapGetters(["isIkrusher","isUnicoreus"]),
  },

  beforeCreate() {
    this.infoId = window.location.search;
  },
  created() {
    if (this.infoId) {
      let id = this.infoId.split("=")[1];
      this.getBatchInfo(id);
    }
  },
  watch: {
    produceStatus: function (val) {
      this.registSpets = val;
    },
  },
  methods: {
    getBatchInfo(id) {
      this.$api.CartridgeBatch.selectReportInformation({ id: id }).then(
        (res) => {
          if (res.success) {
            this.produceStatus = res.data.produceStatus;
            this.batchDetailsVo = res.data.batchDetailsVo;
            this.smokeOilDetailsVo = res.data.smokeOilDetailsVo;
            this.testSmokeBombModelVo = res.data.testSmokeBombModelVo;
            this.heatingModeDetailsVo = res.data.heatingModeDetailsVo;
            this.warmUpModeDetailsVo = res.data.warmUpModeDetailsVo;
            this.batchNumberCoaDetailsVo = res.data.batchNumberCoaDetailsVo;
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        }
      );
    },
    onReset() {
      this.$router.push("disposableFilling");
    },
  },
};
</script>
