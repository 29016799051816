<script>
import { authMethods } from "@/state/helpers";
import baseUrl from "@/config/env.js";
import Vue from "vue";
import { color } from "echarts/lib/export";

/**
 * Login component
 */
export default {
  components: {},
  data() {
    return {
      brand: "",
      imageUrl: "",
      height: 0,
      codeUrl: "",
      loginForm: {
        username: "",
        password: "",
        verificationCode: "",
      },
    };
  },
  created() {
    this.getCode();
    // let code = this.getUrlParam("code");
    // let code = this.$route.query.code;
    // if (code == 401) {
    //   Vue.swal("Your login has expired, Please login again.");
    // }
  },
  mounted() {
    if (this.$store.getters.isIkrusher) {
      this.brand = "Omni";
      this.imageUrl = require("@/assets/omniLogo.png");
      this.height = 100;
    } else {
      this.brand = "UNI-CORE";
      this.imageUrl = require("@/assets/unicoreusLogo.png");
      this.height = 50;
    }
    //绑定事件
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {
    ...authMethods,
    //获取url中的参数
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); //匹配目标参数
      if (r != null) return unescape(r[2]);
      return null; //返回参数值
    },
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode == 13) {
        this.tryToLogIn(e);
      }
    },
    tryToLogIn(evt) {
      if (evt) {
        evt.preventDefault();
      }
      this.logIn({
        username: this.loginForm.username,
        password: this.loginForm.password,
        verificationCode: this.loginForm.verificationCode,
      }).then(async (res) => {
        if (res.success) {
          const resp = await this.$api.account.findUserMenu();
          if (resp.success && resp.data) {
            this.$store.commit("setMenu", resp.data);
          } else {
            if (resp.message) {
              this.$toast(resp.message);
            }
            return
          }
          if (this.$store.getters.isIkrusher) {
            this.$router.push({ name: "Dashboard", params: res.data });
          } else {
            this.$router.push({ name: "home", params: res.data });
          }
          this.submitCurrentTimeZone();
          // this.$route.query.redirectFrom || { name: "home" };
        } else {
          if (res.message) {
            this.$toast(res);
          }
          this.getCode();
        }
      });
    },
    // 获取验证码
    getCode() {
      this.codeUrl = baseUrl + "/supplier/captcha?" + Math.random();
    },
    submitCurrentTimeZone() {
      var nowTimeZone = 0 - new Date().getTimezoneOffset() / 60;
      var time = String(nowTimeZone);
      if (nowTimeZone >= 0) {
        time = "+" + time;
      }
      var splitTime = time.split(".");
      if (null != splitTime[1]) {
        if (splitTime[1] > 0) {
          time = splitTime[0] + ":" + splitTime[1] * 6;
        } else {
          time = splitTime[0] + ":0" + splitTime[1] * 6;
        }
      } else {
        time += ":00";
      }
      if (time) {
        this.$api.account
          .submitCurrentTimeZone({ timeZone: time })
          .then((res) => {
            if (res.success) {
            }
          });
      }
    },
    //找回密码
    forgotPassword() {
      this.$router.push("forgotPassword?email=" + this.loginForm.username);
    },
  },
};
</script>

<template>
  <div class="login-box">
    <div style="margin-top: 120px">
      <div class="row justify-content-center">
        <div class="col-md-6 col-lg-4 col-xl-3">
          <div class="overflow-hidden">
            <div class="text-primary text-center p-4">
              <img :src="imageUrl" :height="height" alt="logo" />
            </div>
            <b-form @submit="tryToLogIn">
              <b-form-group>
                <b-form-input
                  v-model="loginForm.username"
                  placeholder="Enter UserName"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');if(value.length > 40)value = value.slice(0, 40)"
                >
                </b-form-input>
              </b-form-group>

              <b-form-group>
                <b-form-input
                  v-model="loginForm.password"
                  type="password"
                  placeholder="Enter password"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');if(value.length > 40)value = value.slice(0, 40)"
                >
                </b-form-input>
              </b-form-group>
              <b-row>
                <b-col md="7">
                  <b-form-group>
                    <b-form-input
                      v-model="loginForm.verificationCode"
                      placeholder="Enter code"
                      required
                      oninvalid="setCustomValidity('Please enter');"
                      oninput="setCustomValidity('');if(value.length > 4)value = value.slice(0, 4)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="5">
                  <img
                    style="padding-left: 30%"
                    title="Change One"
                    :src="codeUrl"
                    @click="getCode"
                    width="100%"
                  />
                </b-col>
              </b-row>
              <!-- <p @click="forgotPassword()">Forgot Password?</p>
              <b-form-group label-cols-lg="2">
                <b-button
                  type="submit"
                  variant="primary"
                  size="lg"
                  class="float-right"
                  >LOGIN</b-button>
              </b-form-group> -->

              <b-row>
                <b-col md="7">
                  <b-form-group>
                    <p
                      style="color: white; float: left"
                      @click="forgotPassword()"
                    >
                      Forgot Password?
                    </p>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group>
                <b-button
                  type="submit"
                  variant="primary"
                  class="float-inherit btn-lg"
                  style="width: 200px"
                  >LOGIN</b-button
                >
              </b-form-group>
            </b-form>
          </div>
          <!-- <b-alert
            :show="this.getUrlParam('code') == 401"
            class="bg-danger text-white mb-0 border-0"
            role="alert"
          >
            Your login has expired, Please login again.
          </b-alert> -->
        </div>
      </div>
    </div>
    <div class="text-center" style="margin-top: 120px">
      <p class="mb-0" style="color: #ffffff">
        Copyright © {{ new Date().getFullYear() }} {{ this.brand }}
        <span class="d-none d-sm-inline-block"> . All rights reserved </span>
      </p>
    </div>
  </div>
</template>

<style>
.login-box {
  width: 100%;
  height: 100%;
  background-color: #333547;
  position: absolute;
  text-align: center;
}
</style>
