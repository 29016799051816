<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form action="#" @submit.prevent="typeForm">
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Code:"
                    label-cols-sm="4"
                    label-align-sm="right"
                  >
                    <input
                      v-model="typeform.code"
                      type="text"
                      class="form-control"
                      placeholder="A combination of six letters and Numbers"
                      name="name"
                      :class="{
                        'is-invalid': typesubmit && $v.typeform.code.$error,
                      }"
                      disabled
                    />
                    <span> Automatically generate </span>
                    <!-- <span @click="get_code()"> create </span> -->
                    <!-- <div v-if="typesubmit && $v.typeform.code.$error" class="invalid-feedback">
                      <span v-if="!$v.typeform.code.required">This value is required.</span>
                    </div> -->
                  </b-form-group>
                </b-col>
              </div>

              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Effective time:"
                    label-cols-sm="4"
                    label-align-sm="right"
                  >
                    <date-picker
                      v-model="selectTime"
                      format="MM/DD/YYYY"
                      range
                      append-to-body
                      lang="en"
                      :disabled="typeform.failureState == 1"
                      placeholder="Select Effective time"
                    ></date-picker>
                    <b-form-checkbox
                      v-model="typeform.failureState"
                      value="1"
                      unchecked-value="0"
                      @input="changeFailureState(typeform.failureState)"
                      >Permanent</b-form-checkbox
                    >
                  </b-form-group>
                </b-col>
              </div>

              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Status:"
                    label-cols-sm="4"
                    label-align-sm="right"
                  >
                    <b-form-radio-group
                      id="radio-group-1"
                      v-model="typeform.status"
                      :options="types.statuss"
                      name="status"
                      style="padding-top: 5px"
                    >
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>
              </div>

              <div class="form-group mb-0">
                <div class="text-center">
                  <button type="submit" class="btn btn-primary">Sure</button>
                  <button type="reset" class="btn btn-secondary m-l-5 ml-1">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { dropzoneOptions } from "@/api/upload";
import vue2Dropzone from "vue2-dropzone";
import DatePicker from "vue2-datepicker";

import {
  alpha,
  alphaNum,
  numeric,
  between,
  email,
  ipAddress,
  macAddress,
  maxLength,
  minLength,
  required,
  requiredIf,
  requiredUnless,
  sameAs,
  url,
  or,
  and,
  not,
  minValue,
  maxValue,
  integer,
  decimal,
  helpers,
} from "vuelidate/lib/validators";

import { format } from "@/api/time";

export default {
  name: "RecommenderCode",
  page: {
    title: "RecommenderCode",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
    DatePicker,
  },

  data() {
    return {
      dropzoneOptions: dropzoneOptions,
      title: "Recommender Code",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Medical Manage",
          // href: "/"
        },
        {
          text: "Recommender Code",
          active: true,
        },
      ],
      selectTime: ["", ""],
      types: {
        statuss: [
          { text: "enable", value: 0, disabled: false },
          { text: "disable", value: 1, disabled: false },
        ],
      },
      range: {
        minlen: "",
        maxlength: "",
        between: "",
        minval: "",
        maxval: "",
        rangeval: "",
        expr: "",
      },
      typeform: {
        code: "",
        supplierId: localStorage.supplierId,
        startTime: "",
        endTime: "",
        failureState: 0,
        status: 0,
      },
      submit: false,
      typesubmit: false,
    };
  },
  watch: {
    selectTime: function (val) {
      this.typeform.startTime = this.dateFormatter(val[0]);
      this.typeform.endTime = this.dateFormatter(val[1]);
    },
  },
  validations: {
    range: {
      minlen: { required, minLength: minLength(6) },
      maxlength: { required, maxLength: maxLength(6) },
      between: { required, minLength: minLength(5), maxLength: maxLength(10) },
      minval: { required, minValue: minValue(6) },
      maxval: { required, maxValue: maxValue(6) },
      rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
      expr: { required },
    },
    typeform: {
      code: { required, minLength: minLength(6) },
      startTime: { required },
      endTime: { required },
      status: { required },
    },
  },
  computed: {
    is_add: function () {
      return /add/i.test(location.href);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    dateFormatter(val) {
      let dateTime = new Date(val);
      let year = dateTime.getFullYear();
      let month = dateTime.getMonth() + 1;
      let day = dateTime.getDate();
      return year + "-" + this.appendZero(month) + "-" + this.appendZero(day);
    },
    appendZero(obj) {
      if (obj < 10) return "0" + "" + obj;
      else return obj;
    },
    show1() {
      this.typeform = {
        id: this.$route.params.id,
        code: this.$route.params.code,
        startTime: this.$route.params.startTime,
        endTime: this.$route.params.endTime,
        failureState: this.$route.params.failureState,
        status: this.$route.params.status,
      };
      this.selectTime = [
        new Date(this.typeform.startTime),
        new Date(this.typeform.endTime),
      ];
    },
    init() {
      if (this.is_add) {
      } else {
        this.show1();
      }
    },
    add() {
      this.$api.medicalManage.addRecommendCode(this.typeform).then((res) => {
        if (res.success) {
          this.$toastBack(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    update() {
      this.$api.medicalManage.updateRecommendCode(this.typeform).then((res) => {
        if (res.success) {
          this.$toastBack(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    rangeform(e) {
      this.submit = true;
      this.$v.$touch();
    },
    typeForm(e) {
      if (this.is_add) {
        this.add();
      } else {
        this.update();
      }
    },
    changeFailureState(e) {
      if (e == 1) {
        this.selectTime = ["", ""];
      }
    },
  },
};
</script>

<style lang="scss"></style>
