var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Brand Info")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticStyle: { "margin-left": "20px" } },
                [
                  _c("b-col", { attrs: { lg: "8" } }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Brand Name:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.brandName))
                    ])
                  ]),
                  _vm.isUnicoreus
                    ? _c("b-col", { attrs: { lg: "8" } }, [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Brand Introduction:")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(_vm._s(_vm.info.brandIntroduction))
                        ])
                      ])
                    : _vm._e(),
                  _c("b-col", { attrs: { lg: "8" } }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Brand Ins:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.brandIns))
                    ])
                  ]),
                  _c("b-col", { attrs: { lg: "8" } }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Brand Url:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.brandUrl))
                    ])
                  ]),
                  _c("b-col", { attrs: { lg: "8" } }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Brand Logo:")
                    ]),
                    _vm.info.brandPicture
                      ? _c("img", {
                          staticStyle: {
                            "margin-left": "10px",
                            width: "120px",
                            height: "120px"
                          },
                          attrs: { src: "" + _vm.info.brandPicture, alt: "" }
                        })
                      : _vm._e()
                  ]),
                  _c("b-col", { attrs: { lg: "8" } }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Homepage Display Logo:")
                    ]),
                    _vm.info.homePicture
                      ? _c("img", {
                          staticStyle: {
                            "margin-left": "10px",
                            width: "120px",
                            height: "120px"
                          },
                          attrs: { src: "" + _vm.info.homePicture, alt: "" }
                        })
                      : _vm._e()
                  ]),
                  _vm.isUnicoreus
                    ? _c("b-col", { attrs: { lg: "8" } }, [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Brand Details Picture:")
                        ]),
                        _vm.info.brandDetailsPicture
                          ? _c("img", {
                              staticStyle: {
                                "margin-left": "10px",
                                width: "360px",
                                height: "88px"
                              },
                              attrs: {
                                src: "" + _vm.info.brandDetailsPicture,
                                alt: ""
                              }
                            })
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c("b-col", { attrs: { lg: "8" } }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Notes:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.remarks))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("br"),
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Review Opinion")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _vm.info.status != 0
                ? _c("b-col", { attrs: { lg: "8" } }, [
                    _c(
                      "span",
                      { staticClass: "p-3" },
                      [
                        _c("b-table", {
                          staticStyle: {
                            display: "inline",
                            "vertical-align": "text-top"
                          },
                          attrs: {
                            striped: "",
                            hover: "",
                            items: _vm.info.reviewRecordList,
                            fields: _vm.fields
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.show && _vm.info.status == 0
                ? _c(
                    "b-form",
                    { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
                    [
                      _c(
                        "b-row",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Review Opinion:",
                                "label-cols-lg": "4",
                                "label-align-lg": "right",
                                "label-for": "instructions"
                              }
                            },
                            [
                              _c("b-form-radio-group", {
                                staticStyle: { "margin-top": "5px" },
                                attrs: {
                                  id: "radio-group-1",
                                  options: _vm.types.statuss,
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "status", $$v)
                                  },
                                  expression: "form.status"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Review Instructions:",
                                "label-cols-lg": "4",
                                "label-align-lg": "right",
                                "label-for": "instructions"
                              }
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "instructions",
                                  placeholder: "Enter Review Instructions",
                                  rows: "4",
                                  "max-rows": "6"
                                },
                                model: {
                                  value: _vm.form.reviewInstructions,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "reviewInstructions",
                                      $$v
                                    )
                                  },
                                  expression: "form.reviewInstructions"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        { attrs: { "label-cols-lg": "2" } },
                        [
                          _c(
                            "b-button",
                            { attrs: { type: "submit", variant: "primary" } },
                            [_vm._v("Confirm")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "m-l-5 ml-1",
                              attrs: { type: "reset" }
                            },
                            [_vm._v("Go Back")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }