<!--
 * @Author: Code-HHX
 * @Date: 2022-04-26 14:02:06
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-05-09 17:13:18
 * @Description: 
-->
<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <b-card>
      <b-form @submit="onSubmit" @reset="onReset">
        <b-row>
          <b-col lg="8">
            <!-- 角色名称 -->
            <b-form-group
              label="Client Type Name:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="role"
              label-class="requiredRow"
            >
              <b-form-input
                id="role"
                v-model="form.roleName"
                placeholder="Please enter"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
              ></b-form-input>
            </b-form-group>

            <!-- 备注 -->
            <b-form-group
              label="Notes:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="remark"
            >
              <b-form-textarea
                id="remark"
                v-model="form.remarks"
                placeholder="Enter Notes"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- 权限设置 -->
        <b-tabs pills>
          <b-tab title="Function Permission Setting" active>
            <b-card-text>
              <b-table :items="form.navMenuList" :fields="fields" caption-top>
                <template v-slot:cell(module)="data">
                  <b-form-checkbox
                    v-model="data.item.allSelected"
                    :indeterminate="data.item.indeterminate"
                    aria-describedby="permissions"
                    aria-controls="permissions"
                    :disabled="
                      (data.item.title === 'Home' && isUnicoreus) ||
                      (data.item.title === 'Dashboard' && isIkrusher)
                        ? true
                        : false
                    "
                    @change="chooseAll(!data.item.allSelected, data.item)"
                  >
                    <div>{{ data.item.title }}</div>
                  </b-form-checkbox>
                </template>
                <template v-slot:cell(page)="data">
                  <div v-for="i in data.item.children" :key="i.id">
                    <!-- v-if="i.children" -->
                    <b-form-checkbox
                      v-model="i.allSelected"
                      :indeterminate="i.indeterminate"
                      aria-describedby="permissions"
                      aria-controls="permissions"
                      @change="toggleAll(!i.allSelected, i, data.item)"
                      @change.native="
                        disabledButton(i.selected, i.children, i, data.item)
                      "
                    >
                      <!-- {{ i.allSelected ? "Un-select All" : "Select All" }} -->
                      {{ i.title }}
                    </b-form-checkbox>
                  </div>
                </template>
                <template v-slot:cell(permissions)="data">
                  <div v-for="i in data.item.children" :key="i.id">
                    <div v-if="!i.children"><br /></div>
                    <b-form-checkbox-group
                      v-model="i.selected"
                      :options="i.children"
                      class="ml-4"
                      value-field="id"
                      text-field="title"
                      :disabled="i.disabled"
                      @change.native="
                        disabledButton(i.selected, i.children, i, data.item)
                      "
                    ></b-form-checkbox-group>
                  </div>
                </template>
              </b-table>
            </b-card-text>
          </b-tab>
          <b-tab title="App Function Permission Setting">
            <b-card-text>
              <b-table
                :items="form.appNavMenuList"
                :fields="fields"
                caption-top
              >
                <template v-slot:cell(module)="data">
                  <b-form-checkbox
                    v-model="data.item.allSelected"
                    :indeterminate="data.item.indeterminate"
                    aria-describedby="permissions"
                    aria-controls="permissions"
                    @change="chooseAll(!data.item.allSelected, data.item)"
                  >
                    <div>{{ data.item.title }}</div>
                  </b-form-checkbox>
                </template>
                <template v-slot:cell(page)="data">
                  <div v-for="i in data.item.children" :key="i.id">
                    <!-- v-if="i.children" -->
                    <b-form-checkbox
                      v-model="i.allSelected"
                      :indeterminate="i.indeterminate"
                      aria-describedby="permissions"
                      aria-controls="permissions"
                      @change="toggleAll(!i.allSelected, i, data.item)"
                      @change.native="
                        disabledButton(i.selected, i.children, i, data.item)
                      "
                    >
                      <!-- {{ i.allSelected ? "Un-select All" : "Select All" }} -->
                      {{ i.title }}
                    </b-form-checkbox>
                  </div>
                </template>
                <template v-slot:cell(permissions)="data">
                  <div v-for="i in data.item.children" :key="i.id">
                    <div v-if="!i.children"><br /></div>
                    <b-form-checkbox-group
                      v-model="i.selected"
                      :options="i.children"
                      class="ml-4"
                      value-field="id"
                      text-field="title"
                      :disabled="i.disabled"
                      @change.native="
                        disabledButton(i.selected, i.children, i, data.item)
                      "
                    >
                    </b-form-checkbox-group>
                  </div>
                </template>
              </b-table>
            </b-card-text>
          </b-tab>
        </b-tabs>
        <b-form-group label-cols-lg="3">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" variant="danger" class="m-l-5 ml-1"
            >Cancel</b-button
          >
        </b-form-group>
      </b-form>
    </b-card>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";
import { addDays } from "@fullcalendar/core";
import { mapGetters } from "vuex";
/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
  },
  computed: {
    ...mapGetters(["isIkrusher", "isUnicoreus"]),
  },
  data() {
    return {
      page: {
        title: this.editId ? "Modify Client Type" : "Create Client Type",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "Client Mgmt",
            // href: "/"
          },
          {
            text: this.editId ? "Modify Client Type" : "Create Client Type",
            active: true,
          },
        ],
      },
      fields: [
        { key: "module", label: "Module" },
        { key: "page", label: "Page" },
        {
          key: "permissions",
          label: "Permissions",
        },
      ],
      form: {
        roleName: "",
        remarks: "",
        navMenuList: [],
        menuId: [],
        appNavMenuList: [], //APP功能权限列表
        appMenuId: [],
      },
      editor: ClassicEditor,
      editId: window.location.search,
      photoName: "",
    };
  },
  beforeCreate() {
    this.editId = window.location.search;
  },
  created() {
    if (this.editId) {
      let id = this.editId.split("=")[1];
      this.formData(id);
    } else {
      this.$api.Role.findAllMenu().then((res) => {
        if (res.success) {
          res.data.forEach((element) => {
            if (
              (element.title === "Home" && this.$store.getters.isUnicoreus) ||
              (element.title === "Dashboard" && this.$store.getters.isIkrusher)
            ) {
              element.allSelected = true;
            }
          });
          this.form.navMenuList = res.data;
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
      this.$api.Role.selectDeveloperMenu()
        .then((res) => {
          if (res.success) {
            this.form.appNavMenuList = res.data;
          } else {
            this.$toast(ers);
          }
        })
        .catch((error) => {
          this.$toast(error);
        });
    }
  },
  mounted() {},
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.$set(this.form, "menuId", []);
      this.form.navMenuList.forEach((item) => {
        if (item.allSelected || item.indeterminate) {
          this.form.menuId.push(item.id);
        }
        if (item.children) {
          item.children.forEach((ele) => {
            if (ele.allSelected || ele.indeterminate) {
              this.form.menuId.push(ele.id);
            }
            if (ele.children && ele.selected && ele.selected.length > 0) {
              this.form.menuId = this.form.menuId.concat(ele.selected);
            }
          });
        }
      });
      this.$set(this.form, "appMenuId", []);
      this.form.appNavMenuList.forEach((item) => {
        if (item.allSelected || item.indeterminate) {
          this.form.appMenuId.push(item.id);
        }
        if (item.children) {
          item.children.forEach((ele) => {
            if (ele.allSelected || ele.indeterminate) {
              this.form.appMenuId.push(ele.id);
            }
            if (ele.children && ele.selected && ele.selected.length > 0) {
              this.form.appMenuId = this.form.appMenuId.concat(ele.selected);
            }
          });
        }
      });
      if (this.form.id) {
        let updateSupplierRoleVo = this.form;
        delete updateSupplierRoleVo.navMenuList;
        delete updateSupplierRoleVo.appNavMenuList;
        this.$api.Role.updateSupplierRole(updateSupplierRoleVo).then((res) => {
          if (res.success) {
            this.$toastBack(res);
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      } else {
        let addSupplierRoleVo = this.form;
        delete addSupplierRoleVo.navMenuList;
        delete addSupplierRoleVo.appNavMenuList;
        this.$api.Role.saveSupplierRole(addSupplierRoleVo).then((res) => {
          if (res.success) {
            this.$toastBack(res);
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        roleName: "",
        remarks: "",
        navMenuList: [],
        menuId: [],
        appNavMenuList: [],
        appMenuId: [],
      };
      this.$nextTick(() => {
        this.$router.go(-1);
      });
    },
    formData(id) {
      this.$api.Role.selectById({ id: id }).then((res) => {
        if (res.success) {
          this.form = res.data;
          this.form.navMenuList.forEach((item) => {
            if (item.isSelected == 1 && !item.children) {
              this.$set(item, "allSelected", true);
            }
            if (item.isSelected == 1 && item.children) {
              item.children.forEach((ele) => {
                if (ele.isSelected == 1 && !ele.children) {
                  this.$set(ele, "allSelected", true);
                  this.toggleAll(ele.allSelected, ele, item);
                } else if (ele.isSelected == 1 && ele.children) {
                  this.$set(ele, "selected", []);
                  ele.children.forEach((a) => {
                    if (a.isSelected == 1) {
                      ele.selected.push(a.id);
                      this.changeSelected(ele.selected, ele, item);
                    }
                  });
                }
              });
            }
          });
          this.form.appNavMenuList.forEach((item) => {
            if (item.isSelected == 1 && !item.children) {
              this.$set(item, "allSelected", true);
            }
            if (item.isSelected == 1 && item.children) {
              item.children.forEach((ele) => {
                if (ele.isSelected == 1 && !ele.children) {
                  this.$set(ele, "allSelected", true);
                  this.toggleAll(!ele.allSelected, ele, item);
                } else if (ele.isSelected == 1 && ele.children) {
                  this.$set(ele, "selected", []);
                  ele.children.forEach((a) => {
                    if (a.isSelected == 1) {
                      ele.selected.push(a.id);
                      this.changeSelected(ele.selected, ele, item);
                    }
                  });
                }
              });
            }
          });
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    chooseAll(checked, item) {
      if (checked && item.children) {
        item.children.forEach((i) => {
          this.$set(i, "allSelected", true);
          this.toggleAll(i.allSelected, i, item);
        });
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", true);
      } else if (checked && !item.children) {
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", true);
      } else {
        if (item.children) {
          item.children.forEach((i) => {
            this.$set(i, "allSelected", false);
            this.toggleAll(i.allSelected, i, item);
          });
        }
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", false);
      }
    },
    toggleAll(checked, item, par) {
      if (checked && item.children) {
        item.selected = item.children.map((item) => {
          return item.id;
        });
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", true);
      } else if (checked && !item.children) {
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", true);
      } else {
        item.selected = [];
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", false);
      }
      this.isAll(par);
    },
    isAll(par) {
      let a = 0;
      let b = 0;
      if (par) {
        par.children.forEach((i) => {
          if (i.allSelected) {
            a++;
          }
          if (i.indeterminate) {
            b++;
          }
        });
        if (a == par.children.length && b === 0) {
          this.$set(par, "indeterminate", false);
          this.$set(par, "allSelected", true);
        } else if (a === 0 && b === 0) {
          this.$set(par, "indeterminate", false);
          this.$set(par, "allSelected", false);
        } else {
          this.$set(par, "indeterminate", true);
          this.$set(par, "allSelected", false);
        }
      }
    },
    changeSelected(selected, item, par) {
      if (!selected || selected.length === 0) {
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", false);
      } else if (
        selected &&
        item.children &&
        item.children.length == selected.length
      ) {
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", true);
      } else {
        this.$set(item, "indeterminate", true);
        this.$set(item, "allSelected", false);
      }
      this.isAll(par);
    },
    disabledButton(selected, children, i, par) {
      console.log(selected, children, i, par);
      if (selected && children) {
        if (selected.indexOf(children[0].id) == -1) {
          //如果第一个不选中即没有查看权限后面的则不能选
          selected = [];
          this.$set(i, "selected", []);
        } else {
          this.$set(i, "disabled", false);
        }

        //if(i.menuAlias=='ModelView'){
        // if (i.title == 'Model Select') {
        // 	if (selected.length == i.children.length) {
        // 		selected = selected.splice(2, selected.length - 2)
        // 	} else if (selected.length > 2) {
        // 		selected = selected.pop()
        // 	}
        // }

        if (!selected || selected.length === 0) {
          this.$set(i, "indeterminate", false);
          this.$set(i, "allSelected", false);
          this.$set(i, "disabled", false);
        } else if (selected && i.children.length == selected.length) {
          this.$set(i, "indeterminate", false);
          this.$set(i, "allSelected", true);
          this.$set(i, "disabled", false);
        } else {
          this.$set(i, "indeterminate", true);
          this.$set(i, "allSelected", false);
          this.$set(i, "disabled", false);
        }
        this.isAll(par);
      }
    },
  },
};
</script>
