<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="3">
              <b-form-group
                label="Apply For"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="instructions"
              >
                <b-form-input
                  v-model="search_condition.applicationInstructions"
                  trim
                  placeholder="Please enter"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-form-group
                label="Status"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="status"
              >
                <b-form-select
                  v-model="search_condition.status"
                  :options="types.statuss"
                  value-field="value"
                  text-field="text"
                >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-form-group
                label="Create Time"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="create-time"
              >
                <date-picker
                  id="create-time"
                  v-model="activeTime"
                  :first-day-of-week="1"
                  format="MM/DD/YYYY"
                  range
                  append-to-body
                  lang="en"
                  placeholder="Select date"
                ></date-picker>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <b-button
            v-has="'orderStatus::Create'"
            @click="gotoAdd"
            variant="primary"
            >Create</b-button
          >

          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="No records"
              id="table"
              head-variant="light"
              ref="table"
              :busy="isBusy"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">
                {{
                  data.index +
                  (search_condition.pageNum - 1) * search_condition.pageSize +
                  1
                }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button-group>
                  <b-button
                    v-has="'orderStatus::Modify'"
                    size="sm"
                    variant="primary"
                    @click="edit(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    Modify
                  </b-button>
                  <b-button
                    v-has="'orderStatus::Details'"
                    size="sm"
                    variant="info"
                    @click="show(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    Details
                  </b-button>
                </b-button-group>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  >
                  </b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

/**
 * FAQ component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    timeZoneConversion,
  },
  data() {
    return {
      page: {
        title: "Order Status",
        items: [
          {
            text: "UNI-CORE",
            // href: "/"
          },
          {
            text: "Device Manage",
            // href: "/"
          },
          {
            text: "Order Status",
            active: true,
          },
        ],
      },
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "applicationInstructions",
          label: "Apply For",
          class: "text-center",
        },
        {
          key: "status",
          label: "Status",
          formatter: (value) => {
            return value == 0
              ? "To be verified"
              : value == 1
              ? "Passed"
              : "Rejected";
          },
        },
        {
          key: "createTime",
          label: "Creation Time",
          class: "text-center",
          formatter: (value) => {
            if (value) {
              return timeZoneConversion.getLocalTime(value);
            } else {
              return "";
            }
          },
        },
        {
          key: "reviewTime",
          label: "Review Time",
          class: "text-center",
          formatter: (value) => {
            if (value) {
              return timeZoneConversion.getLocalTime(value);
            } else {
              return "";
            }
          },
        },
        {
          key: "remarks",
          label: "Notes",
          class: "text-center",
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        applicationInstructions: "",
        status: "",
        createTime: "",
        endTime: "",
        supplierId: localStorage.supplierId,
      },
      activeTime: ["", ""],
      isBusy: false,
      has_data: false,
      types: {
        statuss: [
          { text: "Please select", value: "", disabled: false },
          { text: "To be verified", value: 0, disabled: false },
          { text: "Passed", value: 1, disabled: false },
          { text: "Rejected", value: 2, disabled: false },
        ],
      },
    };
  },
  watch: {
    activeTime: function (val) {
      this.search_condition.createTime = val[0]
        ? timeZoneConversion.queryTime(val[0])
        : val[0];
      if (val[1]) {
        this.search_condition.endTime = timeZoneConversion.queryTime(
          val[1].getTime() + 24 * 3600 * 1000 - 1
        );
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    gotoAdd() {
      this.$router.push("orderStatusAdd");
    },
    provider(ctx) {
      this.isBusy = true;
      return this.$api.emptyCartridges
        .smokeRecordApplicationList(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    edit(item, index, button) {
      this.$router.push("orderStatusEdit?id=" + item.id);
    },
    show(item, index, button) {
      this.$router.push("orderStatusInfo?id=" + item.id);
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
  },
};
</script>
