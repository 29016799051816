var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { lg: "8" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Client Type Name:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "role",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "role",
                              placeholder: "Please enter",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')"
                            },
                            model: {
                              value: _vm.form.roleName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "roleName", $$v)
                              },
                              expression: "form.roleName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Notes:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "remark"
                          }
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              id: "remark",
                              placeholder: "Enter Notes",
                              rows: "3",
                              "max-rows": "6"
                            },
                            model: {
                              value: _vm.form.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tabs",
                { attrs: { pills: "" } },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Function Permission Setting",
                        active: ""
                      }
                    },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c("b-table", {
                            attrs: {
                              items: _vm.form.navMenuList,
                              fields: _vm.fields,
                              "caption-top": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "cell(module)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          indeterminate:
                                            data.item.indeterminate,
                                          "aria-describedby": "permissions",
                                          "aria-controls": "permissions",
                                          disabled:
                                            (data.item.title === "Home" &&
                                              _vm.isUnicoreus) ||
                                            (data.item.title === "Dashboard" &&
                                              _vm.isIkrusher)
                                              ? true
                                              : false
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.chooseAll(
                                              !data.item.allSelected,
                                              data.item
                                            )
                                          }
                                        },
                                        model: {
                                          value: data.item.allSelected,
                                          callback: function($$v) {
                                            _vm.$set(
                                              data.item,
                                              "allSelected",
                                              $$v
                                            )
                                          },
                                          expression: "data.item.allSelected"
                                        }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(data.item.title))
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "cell(page)",
                                fn: function(data) {
                                  return _vm._l(data.item.children, function(
                                    i
                                  ) {
                                    return _c(
                                      "div",
                                      { key: i.id },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            attrs: {
                                              indeterminate: i.indeterminate,
                                              "aria-describedby": "permissions",
                                              "aria-controls": "permissions"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.toggleAll(
                                                  !i.allSelected,
                                                  i,
                                                  data.item
                                                )
                                              }
                                            },
                                            nativeOn: {
                                              change: function($event) {
                                                return _vm.disabledButton(
                                                  i.selected,
                                                  i.children,
                                                  i,
                                                  data.item
                                                )
                                              }
                                            },
                                            model: {
                                              value: i.allSelected,
                                              callback: function($$v) {
                                                _vm.$set(i, "allSelected", $$v)
                                              },
                                              expression: "i.allSelected"
                                            }
                                          },
                                          [_vm._v(" " + _vm._s(i.title) + " ")]
                                        )
                                      ],
                                      1
                                    )
                                  })
                                }
                              },
                              {
                                key: "cell(permissions)",
                                fn: function(data) {
                                  return _vm._l(data.item.children, function(
                                    i
                                  ) {
                                    return _c(
                                      "div",
                                      { key: i.id },
                                      [
                                        !i.children
                                          ? _c("div", [_c("br")])
                                          : _vm._e(),
                                        _c("b-form-checkbox-group", {
                                          staticClass: "ml-4",
                                          attrs: {
                                            options: i.children,
                                            "value-field": "id",
                                            "text-field": "title",
                                            disabled: i.disabled
                                          },
                                          nativeOn: {
                                            change: function($event) {
                                              return _vm.disabledButton(
                                                i.selected,
                                                i.children,
                                                i,
                                                data.item
                                              )
                                            }
                                          },
                                          model: {
                                            value: i.selected,
                                            callback: function($$v) {
                                              _vm.$set(i, "selected", $$v)
                                            },
                                            expression: "i.selected"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  })
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "App Function Permission Setting" } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c("b-table", {
                            attrs: {
                              items: _vm.form.appNavMenuList,
                              fields: _vm.fields,
                              "caption-top": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "cell(module)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          indeterminate:
                                            data.item.indeterminate,
                                          "aria-describedby": "permissions",
                                          "aria-controls": "permissions"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.chooseAll(
                                              !data.item.allSelected,
                                              data.item
                                            )
                                          }
                                        },
                                        model: {
                                          value: data.item.allSelected,
                                          callback: function($$v) {
                                            _vm.$set(
                                              data.item,
                                              "allSelected",
                                              $$v
                                            )
                                          },
                                          expression: "data.item.allSelected"
                                        }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(data.item.title))
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "cell(page)",
                                fn: function(data) {
                                  return _vm._l(data.item.children, function(
                                    i
                                  ) {
                                    return _c(
                                      "div",
                                      { key: i.id },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            attrs: {
                                              indeterminate: i.indeterminate,
                                              "aria-describedby": "permissions",
                                              "aria-controls": "permissions"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.toggleAll(
                                                  !i.allSelected,
                                                  i,
                                                  data.item
                                                )
                                              }
                                            },
                                            nativeOn: {
                                              change: function($event) {
                                                return _vm.disabledButton(
                                                  i.selected,
                                                  i.children,
                                                  i,
                                                  data.item
                                                )
                                              }
                                            },
                                            model: {
                                              value: i.allSelected,
                                              callback: function($$v) {
                                                _vm.$set(i, "allSelected", $$v)
                                              },
                                              expression: "i.allSelected"
                                            }
                                          },
                                          [_vm._v(" " + _vm._s(i.title) + " ")]
                                        )
                                      ],
                                      1
                                    )
                                  })
                                }
                              },
                              {
                                key: "cell(permissions)",
                                fn: function(data) {
                                  return _vm._l(data.item.children, function(
                                    i
                                  ) {
                                    return _c(
                                      "div",
                                      { key: i.id },
                                      [
                                        !i.children
                                          ? _c("div", [_c("br")])
                                          : _vm._e(),
                                        _c("b-form-checkbox-group", {
                                          staticClass: "ml-4",
                                          attrs: {
                                            options: i.children,
                                            "value-field": "id",
                                            "text-field": "title",
                                            disabled: i.disabled
                                          },
                                          nativeOn: {
                                            change: function($event) {
                                              return _vm.disabledButton(
                                                i.selected,
                                                i.children,
                                                i,
                                                data.item
                                              )
                                            }
                                          },
                                          model: {
                                            value: i.selected,
                                            callback: function($$v) {
                                              _vm.$set(i, "selected", $$v)
                                            },
                                            expression: "i.selected"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  })
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "3" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-l-5 ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }