import { format } from './time'
import qs from 'qs'
import axios from '../config/http.js'
import baseUrl from '../config/env.js'

function allCountry(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/allCountry', params)
}

function brandList(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/brandList', params)
}
function selectAllBrand(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllBrand', params)
}
function batchNumberList(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/batchNumberList', params)
}

function componentEncodingList(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/componentEncodingList', params)
}

function manufacturerEncodingList(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/manufacturerEncodingList',
		params
	)
}

function selectAllAtomizingWire(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllAtomizingWire', params)
}

function selectAllFlavor(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllFlavor', params)
}
function selectBatchFlavor(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectBatchFlavor', params)
}

function selectAllSolventEncoding(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/selectAllSolventEncoding',
		params
	)
}

function selectModelBySupplierId(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectModelBySupplierId', params)
}

function selectTypeEncoding(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectTypeEncoding', params)
}

function selectTypeByOil(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectTypeByOil', params)
}

function selectAllSupplier(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllSupplier', params)
}
function selectByWarmUpModeId(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectByWarmUpModeId', params)
}
function selectByHeatingModeId(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectByHeatingModeId', params)
}
function selectAllTestSmokeBombModel(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/selectAllTestSmokeBombModel',
		params
	)
}
function selectAllTestSmokeBombModelByType(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/selectAllTestSmokeBombModelByType',
		params
	)
}
function selectFlavorByDeviceId(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/selectFlavorByDeviceId',
		params
	)
}
function selectAllSubAccountRole(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllSubAccountRole', params)
}
function selectEnableRole(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectEnableRole', params)
}
function selectReportParameters(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectReportParameters', params)
}
function selectAreaById(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAreaById', params)
}
function selectAllDeviceModel(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllDeviceModel', params)
}
function selectCartridgeModelById(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/selectCartridgeModelById',
		params
	)
}

//查询默认的加热模式下拉框
function selectDefaultHeatingMode(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/selectDefaultHeatingMode',
		params
	)
}

//查询默认的预热模式下拉框
function selectDefaultWarmUpMode(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectDefaultWarmUpMode', params)
}

//查询所有烟弹类型
function selectAllCartridgeType(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllCartridgeType', params)
}

//查询所有膏类别下拉列表
function selectAllPasteType(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllPasteType', params)
}

//FAQ中的查询烟油型号下拉框
function selectModelAndPasteType(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectModelAndPasteType', params)
}

//查询所有的分享活动下拉框
function selectAllSharingSettings(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/selectAllSharingSettings',
		params
	)
}

//查询所有的优惠券奖励下拉框
function selectAllAwardCoupon(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllAwardCoupon', params)
}

//查询所有的优惠券奖励下拉框
function selectAllRegisterAwardCoupon(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllRegisterAwardCoupon', params)
}

//查询所有商品标签下拉框
function selectAllCommodityLabel(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllCommodityLabel', params)
}

//查询所有使用感受标签下拉框
function selectAllExperienceFeelingsLabel(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/selectAllExperienceFeelingsLabel',
		params
	)
}

//查询所有口感标签下拉框
function selectAllTasteLabel(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllTasteLabel', params)
}

//查询所有强度标签下拉框
function selectAllStrength(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllStrength', params)
}

//查询所有客户角色下拉框
function supplierRoleList(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/supplierRoleList', params)
}

//查询所有设备的加热模式下拉框
async function selectAllDeviceHeatingMode(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/selectAllDeviceHeatingMode',
		params
	)
}

async function selectReason(params = {}) {
	axios.fetchGet(baseUrl + '/dropDown/selectTypeEncoding', params)
	return {
		success: true,
		data: [
			{ id: 0, name: 'fda' },
			{ id: 1, name: 'recall' },
			{ id: 2, name: '...' },
		],
	}
}

//查询所有的主题颜色
function selectAllBrandSubject(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllBrandSubject', params)
}

//查询地址
function selectAddressById(params = {}){
	return axios.fetchGet(baseUrl + '/dropDown/selectAddressById', params)
}
//查询所有SKU品牌
function selectAllSkuBrand(params = {}){
	return axios.fetchGet(baseUrl + '/dropDown/selectAllSkuBrand', params)
}
//查询所有SKU
function selectAllSku(params = {}){
	return axios.fetchGet(baseUrl + '/dropDown/selectAllSku', params)
}

export default {
	allCountry,
	brandList,
	componentEncodingList,
	manufacturerEncodingList,
	selectAllAtomizingWire,
	selectAllFlavor,
	selectBatchFlavor,
	selectAllSolventEncoding,
	selectModelBySupplierId,
	selectTypeEncoding,
	selectTypeByOil,
	selectReason,
	batchNumberList,
	selectAllSupplier,
	selectByWarmUpModeId,
	selectByHeatingModeId,
	selectAllTestSmokeBombModel,
	selectAllSubAccountRole,
	selectEnableRole,
	selectReportParameters,
	selectAreaById,
	selectAllDeviceModel,
	selectCartridgeModelById,
	selectDefaultHeatingMode,
	selectDefaultWarmUpMode,
	selectAllCartridgeType,
	selectAllPasteType,
	selectModelAndPasteType,
	selectAllSharingSettings,
	selectAllAwardCoupon,
	selectAllRegisterAwardCoupon,
	selectAllCommodityLabel,
	selectAllExperienceFeelingsLabel,
	selectAllTasteLabel,
	selectAllStrength,
	supplierRoleList,
	selectAllDeviceHeatingMode,
	selectAllBrandSubject,
	selectAllTestSmokeBombModelByType,
	selectFlavorByDeviceId,
	selectAllBrand,
	selectAddressById,
	selectAllSkuBrand,
	selectAllSku,
}
