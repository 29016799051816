<template>
  <b-row align-content="center" class="vo-items-center">
    <b-col :cols="lg" :offset="offset" align-self="center">
      <slot/>
    </b-col>

    <!-- <b-col :cols="rightCol">
      <template v-if="$slots.right">
        <slot name="right"/>
      </template>
    </b-col> -->
    <template v-if="$slots.right">
        <slot name="right"/>
      </template>
  </b-row>
</template>

<script>
export default {
  props: {
    lg: {
      type: Number,
      default: 8
    },
    offset: {
      type: [Number, String],
      default: 0
    },
    rightCol: {
      type: [Number,String],
      default: 1
    }
  }
};

</script>

<style>
</style>