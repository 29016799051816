<!--
 * @Author: Code-HHX
 * @Date: 2022-03-28 18:11:42
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-01 18:20:42
 * @Description: 添加积分兑换商品
-->
<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<b-card>
			<b-form @submit="onSubmit" @reset="onReset" v-if="show">
				<!-- Commodity Name 商品名称-->
				<b-form-group
					label="Commodity Name:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="product-name"
					label-class="requiredRow"
				>
					<b-form-input
						id="product-name"
						v-model="form.productName"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
						placeholder="Please enter"
					>
					</b-form-input>
				</b-form-group>
				<!-- Commodity Picture 商品图片-->
				<b-form-group
					label="Commodity Picture:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="productPicture"
					label-class="requiredRow"
				>
					<input
						type="file"
						class="filestyle"
						data-input="false"
						id="filestyle-1"
						style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
						tabindex="-1"
						@change="upload($event)"
						:required="!form.productPicture"
						oninvalid="setCustomValidity('Please upload');"
						oninput="setCustomValidity('')"
					/>

					<div class="bootstrap-filestyle input-group">
						<div
							name="filedrag"
							style="position: absolute; width: 100%; height: 35px; z-index: -1"
						></div>
						<span class="group-span-filestyle" tabindex="0">
							<div
								v-if="form.productPicture"
								style="margin-bottom: 10px"
							>
								<img
									:src="form.productPicture"
									style="width: 100px; height: 100px; border: 2px dashed #ccc"
								/>
								<span
									style="margin-left: 10px; vertical-align: bottom"
									v-if="!editId"
								>
									<span style="font-weight: 500"
										>Picture name : </span
									>{{ photoName }}</span
								>
							</div>
							<label
								for="filestyle-1"
								style="margin-bottom: 0"
								class="btn btn-secondary"
							>
								<span class="buttonText"> upload picture </span>
							</label>
							<span>
								Recommended size of picture: 980 pixels * 500
								pixels, supporting JPG and PNG formats, within
								2m.
							</span>
						</span>
					</div>
				</b-form-group>
				<!-- Can be exchanged Time 上架时间 -->
				<b-form-group
					label="Can be exchanged Time:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="launch-time"
					label-class="requiredRow"
					description="Launch time cannot overlap"
				>
					<date-picker
						v-model="form.launchTime"
						format="MM/DD/YYYY HH:mm:ss"
						range
						append-to-body
						lang="en"
						placeholder="Select date"
						type="datetime"
						:disabled-date="notBeforeToday"
						@change="onChangeLaunchTime"
					></date-picker>
				</b-form-group>
				<!-- Commodity Type 兑换类型-->
				<b-form-group
					label="Commodity Type:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="exchange-type"
					label-class="requiredRow"
				>
					<b-form-select
						id="exchange-type"
						v-model="form.exchangeType"
						:options="types.exchangeTypeList"
						value-field="value"
						text-field="text"
						required
						oninvalid="setCustomValidity('Please select');"
						oninput="setCustomValidity('')"
						@change="onChangeExchangeType"
					></b-form-select>
				</b-form-group>
				<!-- Coupon 优惠券-->
				<b-form-group
					v-if="form.exchangeType == 1"
					label="Coupon:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="exchange-type"
					label-class="requiredRow"
				>
					<b-form-select
						id="exchange-type"
						v-model="form.couponId"
						:options="types.couponList"
						value-field="value"
						text-field="text"
						required
						oninvalid="setCustomValidity('Please select');"
						oninput="setCustomValidity('')"
					></b-form-select>
				</b-form-group>
				<!-- Consume Gems 兑换消耗积分数量 -->
				<b-form-group
					label="Consume Gems:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="integral-number"
					label-class="requiredRow"
				>
					<b-form-input
						id="integral-number"
						v-model="form.integral"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
						placeholder="Please enter"
					>
					</b-form-input>
				</b-form-group>
				<!-- Quantity 可兑换总数量 -->
				<b-form-group
					v-if="form.exchangeType != 1"
					label="Quantity:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="convertible-quantity"
					label-class="requiredRow"
				>
					<b-form-input
						id="convertible-quantity"
						v-model="form.convertibleQuantity"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
						placeholder="Please enter"
					>
					</b-form-input>
				</b-form-group>
				<!-- All limit Per day 单日可兑换数量限制 -->
				<b-form-group
					v-if="form.exchangeType != 1"
					label="All limit Per day:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="day-convertible-quantity"
					label-class="requiredRow"
				>
					<b-form-input
						id="day-convertible-quantity"
						v-model="form.dayConvertibleQuantity"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50);value=value.replace(/[^\d]/g,'')"
						placeholder="Please enter"
						:disabled="form.dayExchangeType == 1"
					></b-form-input>
					<b-form-checkbox
						id="checkbox-1"
						v-model="form.dayExchangeType"
						name="checkbox-1"
						unchecked-value="0"
						value="1"
						@change="onChangeDayExchangeType"
					>
						Unlimited
					</b-form-checkbox>
				</b-form-group>
				<!-- Exchange Time/Per user 每用户可兑换总次数 -->
				<b-form-group
					v-if="form.exchangeType != 1"
					label="Exchange Time/Per user:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="available-quantity"
					label-class="requiredRow"
					description="Maximum number of coupons per user"
				>
					<b-form-select
						id="limited-availableType"
						v-if="form.availableType == 0"
						v-model="form.limitedAvailableType"
						:options="types.limitedAvailableTypeList"
						value-field="value"
						text-field="text"
						required
						oninvalid="setCustomValidity('Please select');"
						oninput="setCustomValidity('')"
					></b-form-select>
					<b-form-input
						id="available-quantity"
						v-model="form.availableQuantity"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50);value=value.replace(/[^\d]/g,'')"
						placeholder="Please enter"
						:disabled="form.availableType == 1"
						:state="availableQuantityState"
						aria-describedby="input-available-feedback"
					></b-form-input>
					<b-form-invalid-feedback id="input-available-feedback">
						The available quantity cannot be greater than the issued
						quantity.
					</b-form-invalid-feedback>
					<b-form-checkbox
						id="checkbox-2"
						v-model="form.availableType"
						name="checkbox-2"
						unchecked-value="0"
						value="1"
						@change="onChangeAvailableQuantity"
					>
						Unlimited
					</b-form-checkbox>
				</b-form-group>
				<!-- Display at Exchange Center 是否在宝石商场展示 -->
				<b-form-group
					label="Display at Exchange Center:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="enable-status"
				>
					<b-form-checkbox
						id="enable-status"
						v-model="form.status"
						name="onSale"
						:value="1"
						:unchecked-value="0"
						switch
						size="lg"
						style="margin-top: 5px"
					>
						{{ form.status == 0 ? 'No' : 'Yes' }}
					</b-form-checkbox>
				</b-form-group>
				<!-- Commodity Details 产品详情 -->
				<b-form-group
					label="Commodity Details:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="product-details"
				>
					<div id="wangeEditor" style="max-width: 1310px"></div>
				</b-form-group>

				<b-form-group label-cols-lg="2">
					<b-button type="submit" variant="primary">Confirm</b-button>
					<b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
				</b-form-group>
			</b-form>
			<!-- <p>{{form}}</p> -->
		</b-card>
	</Layout>
</template>
<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Multiselect from 'vue-multiselect'

import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import DatePicker from 'vue2-datepicker'

import { onReady, upload_img } from '@/api/upload'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'

import baseUrl from '@/config/env.js'
import E from 'wangeditor'
const editor = new E('#wangeEditor')

/**
 * Cartridge Model Add
 */
export default {
	components: {
		ckeditor: CKEditor.component,
		Layout,
		PageHeader,
		Multiselect,
		DatePicker,
		timeZoneConversion,
	},
	data() {
		return {
			title: this.editId
				? 'Integral Commodity Modify'
				: 'Integral Commodity Create',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'Marketing Manage',
					// href: "/"
				},
				{
					text: this.editId
						? 'Integral Commodity Modify'
						: 'Integral Commodity Create',
					active: true,
				},
			],
			form: {
				productName: '', //商品名称
				productPicture: '', //商品图片
				exchangeType: '', //兑换类型 (1是优惠券 2是实物)
				couponId: '', //优惠券ID
				integral: '', //积分
				convertibleQuantity: '', //可兑换总数量
				dayExchangeType: '0', //单日兑换限制类型 0有限 1无限
				dayConvertibleQuantity: '', //单日可兑换数量限制
				availableQuantity: '', //可领取数量
				availableType: '0', //用户兑换类型(0是有限 1是无限)
				limitedAvailableType: '', //有限兑换的类型(1是日可领 2是周可领 3是月可领 4是没有时间限制)
				status: '1', //0禁用 1启用
				productDetails: '', //商品详情

				startTime: '', //上架时间
				endTime: '', //下架时间
				launchTime: [], //上架时间（显示用）

				supplierId: localStorage.supplierId,
			},
			types: {
				//兑换类型
				exchangeTypeList: [
					{
						text: 'Please select',
						value: '',
					},
					{
						text: 'Coupon', //优惠券
						value: 1,
					},
					{
						text: 'Object', //实物商品
						value: 2,
					},
				],
				//优惠券列表
				couponList: [{ text: 'Please select', value: '' }],
				//领取类型
				limitedAvailableTypeList: [
					{
						text: 'Please select',
						value: '',
					},
					{
						text: 'Every day', //每日
						value: 1,
					},
					{
						text: ' Every week', //每周
						value: 2,
					},
					{
						text: 'Every month', //每月
						value: 3,
					},
					{
						text: 'There is no time limit', //不限
						value: 4,
					},
				],
			},
			show: true, // 表单显示
			editor: ClassicEditor,
			editId: window.location.search,
		}
	},
	watch: {},
	beforeCreate() {
		this.editId = window.location.search
	},
	created() {},
	computed: {
		availableQuantityState() {
			if (
				this.form.availableQuantity == '' ||
				this.form.availableType == 1
			) {
				return null
			} else if (
				parseInt(this.form.availableQuantity) >
				parseInt(this.form.convertibleQuantity)
			) {
				return false
			} else {
				true
			}
		},
	},
	mounted() {
		if (/edit/i.test(window.location.href)) {
			this.selectInfo()
		}

		this.createEditor(editor)
	},
	methods: {
		selectInfo() {
			if (this.$route.query.id) {
				this.$api.Marketing.selectBySettingsId(this.$route.query).then(
					(res) => {
						if (res.success) {
							this.form = res.data
							if (this.form.dayExchangeType == 1) {
								this.form.dayConvertibleQuantity = ''
							}
							if (this.form.availableType == 1) {
								this.form.availableQuantity = ''
								this.form.limitedAvailableType = ''
							}
							editor.txt.html(res.data.productDetails)
							this.$set(this.form, 'launchTime', [
								new Date(res.data.startTime),
								new Date(res.data.endTime),
							])
							this.getCouponList()
						} else {
							if (res.message) {
								this.$toast(res)
							}
						}
					}
				)
			} else {
				this.$bvToast.toast('id?')
			}
		},
		//禁止选择早于今天的时间
		notBeforeToday(date) {
			return date < new Date(new Date().setHours(0, 0, 0, 0))
		},
		onReady: onReady,
		onSubmit(evt) {
			evt.preventDefault()
			if (this.availableQuantityState === false) return

			//时间类型组件非空验证
			if (this.form.launchTime.length <= 0) {
				this.$toastWarning('Please select Launch Time.')
				return
			}

			if (/edit/i.test(window.location.href)) {
				//修改
				this.form.productDetails = editor.txt.html()
				this.$api.Marketing.saveOrUpdateExchangeSettings(
					this.form
				).then((res) => {
					if (res.success) {
						this.$toastBack(res)
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
			} else {
				//添加
				this.form.productDetails = editor.txt.html()
				this.$api.Marketing.saveOrUpdateExchangeSettings(
					this.form
				).then((res) => {
					if (res.success) {
						this.$toastBack(res)
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
			}
		},
		onReset(evt) {
			evt.preventDefault()
			;(this.form = {
				supplierId: localStorage.supplierId,
			}),
				(this.show = false)
			this.$nextTick(() => {
				// this.show = true;
				this.$router.go(-1)
			})
		},
		//活动时间
		onChangeLaunchTime(val) {
			console.log(val)
			if (val[0] == null && val[1] == null) {
				this.form.startTime = ''
				this.form.endTime = ''
				this.form.launchTime = []
				this.form.couponId = ''
				this.types.couponList = [{ text: 'Please select', value: '' }]
				return
			}
			this.form.startTime = val[0]
				? timeZoneConversion.queryTime(val[0])
				: val[0]
			if (val[1]) {
				this.form.endTime = timeZoneConversion.queryTime(val[1])
			} else {
				this.form.endTime = val[1]
			}
			this.getCouponList()
		},
		onChangeExchangeType(value) {
			if (value == 1) {
				this.form.convertibleQuantity = ''
				this.form.dayConvertibleQuantity = ''
				this.form.dayExchangeType = 1
				this.form.availableType = 1
			} else {
				this.form.couponId = ''
				this.types.couponList = [{ text: 'Please select', value: '' }]
			}
			this.getCouponList()
		},
		onChangeAvailableQuantity(value) {
			if (value == 1) {
				this.form.availableQuantity = ''
				this.form.limitedAvailableType = ''
			}
		},
		onChangeDayExchangeType(value) {
			if (value == 1) {
				this.form.dayConvertibleQuantity = ''
			}
		},
		upload(e) {
			let file = e.target.files[0]
			this.photoName = file.name
			console.log(file)
			if (/image/.test(file.type)) {
				console.log('uploading', file)
				upload_img(file).then((res) => {
					if (res.message) {
						this.$toast(res)
					}
					if (res.success) {
						this.form.productPicture = res.data
						console.log('zzzzzzzz', this.form)
					}
				})
			} else {
				e.target.files = []
				this.$bvToast.toast('only support jpg/png file')
			}
		},
		//加载优惠券列表
		getCouponList() {
			let that = this
			if (
				this.form.exchangeType == 1 &&
				this.form.startTime != '' &&
				this.form.endTime != ''
			) {
				this.$api.Dropdown.selectAllAwardCoupon({
					startTime: this.form.startTime,
					endTime: this.form.endTime,
				})
					.then((res) => {
						if (res.success) {
							that.types.couponList = [
								{ text: 'Please select', value: '' },
							]
							for (let i = 0; i < res.data.length; i++) {
								this.types.couponList.push({
									text: res.data[i].couponName,
									value: res.data[i].id,
								})
							}
						} else {
							that.$toastWarning(res.message)
						}
					})
					.catch((error) => {
						that.$toastWarning(error.message)
					})
			} else {
				this.form.couponId = ''
				that.types.couponList = [{ text: 'Please select', value: '' }]
			}
		},
		createEditor(object) {
			object.customConfig.lang = {
				设置标题: 'title',
				正文: 'p',
				链接文字: 'link text',
				链接: 'link',
				插入: 'insert ',
				视频: 'video',
				创建: 'init',
				图片链接: 'pictures linking',
				网络: 'internet',
				上传: 'upload ',
				图片: 'picture',
				默认: 'default',
				代码: 'code',
				格式如: 'format',
				新浪: 'sina',
				字号: 'Font size',
				字体: 'Font',
				设置列表: 'Settings list',
				有序列表: 'Ordered list',
				无序列表: 'Unordered list',
				插入: 'insert ',
				插入表格: 'insert table',
				编辑: 'edit ',
				最大宽度: 'maximum width',
				删除: 'delete ',
				行: 'row',
				列: 'column',
				的: ' ',
				表格: 'table',
				上传图片: 'Upload picture',
				网络图片: 'Network picture',
				插入表格: 'Insert table',
				插入视频: 'Insert video',
				插入代码: 'Insert code',
				文字颜色: 'Text color',
				背景色: 'Background color',
				对齐方式: 'Alignment',
				靠左: 'left',
				居中: 'Center',
				靠右: 'right',
				// 还可自定添加更多
			}

			object.customConfig.uploadImgServer =
				baseUrl + '/supplier/uploadFile'
			object.customConfig.uploadImgHeaders = {
				'supplier-token': window.localStorage.getItem('auth-token'),
			}
			object.customConfig.uploadFileName = 'file'

			object.customConfig.uploadImgHooks = {
				// 上传图片之前
				before: function(xhr) {
					console.log(xhr)

					// 可阻止图片上传
					// return {
					//   prevent: true,
					//   msg: "需要提示给用户的错误信息",
					// };
				},
				// 图片上传并返回了结果，图片插入已成功
				success: function(xhr) {
					console.log('success', xhr)
				},
				// 图片上传并返回了结果，但图片插入时出错了
				fail: function(xhr, editor, resData) {
					console.log('fail', resData)
				},
				// 上传图片出错，一般为 http 请求的错误
				error: function(xhr, editor, resData) {
					console.log('error', xhr, resData)
				},
				// 上传图片超时
				timeout: function(xhr) {
					console.log('timeout')
				},
				// 图片上传并返回了结果，想要自己把图片插入到编辑器中
				// 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
				customInsert: function(insertImgFn, result) {
					// result 即服务端返回的接口
					console.log('customInsert', result)

					// insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
					result.data.forEach((element) => {
						insertImgFn(element.url)
					})
				},
			}

			object.create()
		},
	},
}
</script>

<style scoped>
.rewardSetting {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
}
.rewardSetting-title {
	font-weight: 500;
	margin: 0 8px;
}
.mx-datepicker-popup {
	z-index: 10001;
}
a:hover {
	cursor: pointer;
}
</style>

<style lang="scss" scoped>
::v-deep .w-e-menu {
	z-index: 2 !important;
}
::v-deep .w-e-text-container,
.w-e-toolbar {
	z-index: 1 !important;
}
::v-deep .custom-control-label {
	font-size: inherit !important;
	line-height: 2 !important;
}
</style>
