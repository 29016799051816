var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-box" },
    [
      _c("div", { staticStyle: { "margin-top": "120px" } }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-md-6 col-lg-4 col-xl-3" }, [
            _c(
              "div",
              { staticClass: "overflow-hidden" },
              [
                _c("div", { staticClass: "text-primary text-center p-4" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.imageUrl,
                      height: _vm.height,
                      alt: "logo"
                    }
                  })
                ]),
                _c(
                  "b-form",
                  { on: { submit: _vm.tryToLogIn } },
                  [
                    _c(
                      "b-form-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            placeholder: "UserName",
                            required: "",
                            disabled: "",
                            oninvalid: "setCustomValidity('Please enter');",
                            oninput:
                              "setCustomValidity('');if(value.length > 40)value = value.slice(0, 40)"
                          },
                          model: {
                            value: _vm.loginForm.username,
                            callback: function($$v) {
                              _vm.$set(_vm.loginForm, "username", $$v)
                            },
                            expression: "loginForm.username"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            type: "password",
                            placeholder: "New Password",
                            state:
                              _vm.loginForm.password.length >= 6 &&
                              _vm.loginForm.password.length <= 18,
                            required: "",
                            oninvalid: "setCustomValidity('Please enter');",
                            oninput:
                              "setCustomValidity('');if(value.length > 18)value = value.slice(0, 18)"
                          },
                          model: {
                            value: _vm.loginForm.password,
                            callback: function($$v) {
                              _vm.$set(_vm.loginForm, "password", $$v)
                            },
                            expression: "loginForm.password"
                          }
                        }),
                        _c("div", { staticClass: "input-message" }, [
                          _vm._v(
                            " 6-18 characters, including numbers, letters, symbols "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            type: "password",
                            placeholder: "Confirm Password",
                            required: "",
                            state:
                              _vm.loginForm.newPassword.length >= 6 &&
                              _vm.loginForm.newPassword.length <= 18 &&
                              _vm.loginForm.password ==
                                _vm.loginForm.newPassword,
                            oninvalid: "setCustomValidity('Please enter');",
                            oninput:
                              "setCustomValidity('');if(value.length > 18)value = value.slice(0, 18)"
                          },
                          model: {
                            value: _vm.loginForm.newPassword,
                            callback: function($$v) {
                              _vm.$set(_vm.loginForm, "newPassword", $$v)
                            },
                            expression: "loginForm.newPassword"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "7" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: "Email Verification Code",
                                    required: "",
                                    oninvalid:
                                      "setCustomValidity('Please enter');",
                                    oninput:
                                      "setCustomValidity('');if(value.length > 6)value = value.slice(0, 6)"
                                  },
                                  model: {
                                    value: _vm.loginForm.verificationCode,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.loginForm,
                                        "verificationCode",
                                        $$v
                                      )
                                    },
                                    expression: "loginForm.verificationCode"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("b-form-group", [
                      _c(
                        "p",
                        {
                          staticStyle: { color: "white", float: "left" },
                          on: {
                            click: function($event) {
                              return _vm.login()
                            }
                          }
                        },
                        [_vm._v("Login")]
                      )
                    ]),
                    _c(
                      "b-form-group",
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.modal-center",
                                modifiers: { "modal-center": true }
                              }
                            ],
                            staticClass: "float-inherit btn-lg",
                            staticStyle: { width: "300px" },
                            attrs: { type: "submit", variant: "primary" }
                          },
                          [_vm._v("CONFIRM")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "mt-5 text-center" }, [
              _c(
                "p",
                { staticClass: "mb-0", staticStyle: { color: "#ffffff" } },
                [
                  _vm._v(
                    " Copyright © " +
                      _vm._s(new Date().getFullYear()) +
                      " " +
                      _vm._s(this.brand) +
                      " "
                  ),
                  _c("span", { staticClass: "d-none d-sm-inline-block" }, [
                    _vm._v(" . All rights reserved ")
                  ])
                ]
              )
            ])
          ])
        ])
      ]),
      _vm.showAlert
        ? _c(
            "Dialog",
            {
              attrs: {
                customBtn: "Login",
                imageSrc: require("@/assets/success.png")
              },
              on: { customEvent: _vm.login }
            },
            [
              _c("p", [_vm._v("Password reset successful")]),
              _c("p", [_vm._v("Please use your new password to login.")])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }