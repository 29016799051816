var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c("b-card", [
        _c(
          "div",
          { staticClass: "vo-column", staticStyle: { width: "100%" } },
          [
            _c("div", { staticClass: "productDetailRow" }, [
              _c(
                "div",
                { staticClass: "productDetailColumn" },
                [
                  _c("div", { staticClass: "vo-row" }, [
                    _c("p", { staticClass: "productInfo" }, [
                      _c("strong", [_vm._v("Voltage Curves Name:")])
                    ]),
                    _c("p", { staticClass: "productInfoContent" }, [
                      _vm._v(" " + _vm._s(_vm.group.groupName) + " ")
                    ])
                  ]),
                  _c("div", { staticClass: "vo-row" }, [
                    _c("p", { staticClass: "productInfo" }, [
                      _c("strong", [_vm._v("Voltage Curves Description:")])
                    ]),
                    _c("p", { staticClass: "productInfoContent" }, [
                      _vm._v(" " + _vm._s(_vm.group.description) + " ")
                    ])
                  ]),
                  _c("div", { staticClass: "vo-row" }, [
                    _c("p", { staticClass: "productInfo" }, [
                      _c("strong", [_vm._v("Be Available For:")])
                    ]),
                    _c("p", { staticClass: "productInfoContent" }, [
                      _vm._v(" " + _vm._s(_vm.group.deviceName) + " ")
                    ])
                  ]),
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "8" } }, [
                        _c("div", { staticClass: "vo-row" }, [
                          _c("p", { staticClass: "productInfo" }, [
                            _c("strong", [_vm._v("Notes:")])
                          ]),
                          _c(
                            "div",
                            { staticClass: "productInfoContent" },
                            [
                              _c(
                                "b-row",
                                { staticStyle: { "margin-left": "-10px" } },
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c("b-form-textarea", {
                                        attrs: {
                                          id: "company-introduction",
                                          placeholder: _vm.group.remarks,
                                          disabled: true,
                                          rows: "10",
                                          "max-rows": "10",
                                          maxlength: "2000"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vo-row" }, [
                    _c("p", { staticClass: "productInfo" }, [
                      _c("strong", [_vm._v("Status:")])
                    ]),
                    _c("p", { staticClass: "productInfoContent" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.group.status == 0 ? "Eanable" : "Disable"
                          ) +
                          " "
                      )
                    ])
                  ])
                ],
                1
              )
            ])
          ]
        )
      ]),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Set Voltage Curves:")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "7" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-cols-lg": "3",
                        "label-align-lg": "right",
                        "label-for": "curveOne"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { "padding-bottom": "40px" } },
                        _vm._l(_vm.btnList, function(item, index) {
                          return _c(
                            "button",
                            {
                              key: index,
                              staticClass: "buttonStyle",
                              style: {
                                backgroundColor:
                                  _vm.activeIndex === index
                                    ? "rgba(255, 255, 255, 1)"
                                    : "",
                                color:
                                  _vm.activeIndex === index ? "#000000" : ""
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.clickBtn(index)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(item) + " ")]
                          )
                        }),
                        0
                      ),
                      _c("HistoryCurve", {
                        attrs: { width: "680px", power: _vm.types.power }
                      }),
                      _vm.activeIndex == 0
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "voltage-one" },
                              _vm._l(_vm.temperatureList, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "voltage-number" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          parseFloat(
                                            (
                                              Math.pow(item.value, 2) /
                                              parseFloat(_vm.resistance)
                                            ).toFixed(1)
                                          )
                                        ) +
                                        " w "
                                    )
                                  ]
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "voltage-two",
                                staticStyle: { width: "100%" }
                              },
                              _vm._l(_vm.temperatureList, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "voltage-list" },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-plus voltage-plus"
                                    }),
                                    _c("el-slider", {
                                      attrs: {
                                        vertical: "",
                                        step: _vm.step,
                                        min: _vm.min,
                                        max: _vm.max,
                                        disabled: "",
                                        height: "150px"
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value"
                                      }
                                    }),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-minus voltage-reduce"
                                    })
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              { staticClass: "voltage-three" },
                              _vm._l(8, function(index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "voltage-number" },
                                  [_vm._v(" " + _vm._s(index) + "s ")]
                                )
                              }),
                              0
                            )
                          ])
                        : _vm._e(),
                      _vm.activeIndex == 1
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "voltage-one" },
                              _vm._l(_vm.temperatureList1, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "voltage-number" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          parseFloat(
                                            (
                                              Math.pow(item.value, 2) /
                                              parseFloat(_vm.resistance)
                                            ).toFixed(1)
                                          )
                                        ) +
                                        " w "
                                    )
                                  ]
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "voltage-two",
                                staticStyle: { width: "100%" }
                              },
                              _vm._l(_vm.temperatureList1, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "voltage-list" },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-plus voltage-plus",
                                      on: {
                                        click: function($event) {
                                          return _vm.onVoltagePlus1(index)
                                        }
                                      }
                                    }),
                                    _c("el-slider", {
                                      attrs: {
                                        vertical: "",
                                        step: _vm.step,
                                        min: _vm.min,
                                        max: _vm.max,
                                        disabled: "",
                                        height: "150px"
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value"
                                      }
                                    }),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-minus voltage-reduce",
                                      on: {
                                        click: function($event) {
                                          return _vm.onVoltageReduce1(index)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              { staticClass: "voltage-three" },
                              _vm._l(8, function(index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "voltage-number" },
                                  [_vm._v(" " + _vm._s(index) + "s ")]
                                )
                              }),
                              0
                            )
                          ])
                        : _vm._e(),
                      _vm.activeIndex == 2
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "voltage-one" },
                              _vm._l(_vm.temperatureList2, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "voltage-number" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          parseFloat(
                                            (
                                              Math.pow(item.value, 2) /
                                              parseFloat(_vm.resistance)
                                            ).toFixed(1)
                                          )
                                        ) +
                                        " w "
                                    )
                                  ]
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "voltage-two",
                                staticStyle: { width: "100%" }
                              },
                              _vm._l(_vm.temperatureList2, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "voltage-list" },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-plus voltage-plus",
                                      on: {
                                        click: function($event) {
                                          return _vm.onVoltagePlus2(index)
                                        }
                                      }
                                    }),
                                    _c("el-slider", {
                                      attrs: {
                                        vertical: "",
                                        step: _vm.step,
                                        min: _vm.min,
                                        max: _vm.max,
                                        disabled: "",
                                        height: "150px"
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value"
                                      }
                                    }),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-minus voltage-reduce",
                                      on: {
                                        click: function($event) {
                                          return _vm.onVoltageReduce2(index)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              { staticClass: "voltage-three" },
                              _vm._l(8, function(index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "voltage-number" },
                                  [_vm._v(" " + _vm._s(index) + "s ")]
                                )
                              }),
                              0
                            )
                          ])
                        : _vm._e(),
                      _c("p", [
                        _vm._v(
                          " The Voltage curve is between 0.4v-3.7v, after the 8th second, the same as the 8th second "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { "label-cols-lg": "2" } },
        [
          _c(
            "b-button",
            {
              staticClass: "custom-width",
              attrs: { type: "reset" },
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_vm._v("Return ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }