<!--
 * @Author: Code-HHX
 * @Date: 2021-12-10 11:07:33
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-03-28 14:37:03
 * @Description: 
-->
<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  async created() {
    // window.addEventListener("load", () => {

    this.$api.account.selectAllSupplierButton().then((res) => {
      if (res.success && res.data) {
        sessionStorage["buttenpremissions"] = JSON.stringify(res.data);
      } else {
        if (res.message) {
          this.$toast(res);
        }
      }
    });
    // });
  },
  mounted() {
    // window.addEventListener("load", function() {
    // if (this.$route.path === "/") {
    //   this.$router.replace("/login");
    // }
    // });
  },
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      console.log(appConfig.title);
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
};
</script>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>

<style lang="scss">
//通用表单辅助样式
.b-row-point {
  font-size: inherit;
  line-height: 27px;
  display: flex;
  align-content: center;
  margin-left: 12px;

  .b-row-point-msg {
  }

  .b-row-poin-button {
    margin-left: 12px;
    color: #3a49c9;
    cursor: pointer;
    font-weight: 500;
    -moz-user-select: none; /* Firefox私有属性 */
    -webkit-user-select: none; /* WebKit内核私有属性 */
    -ms-user-select: none; /* IE私有属性(IE10及以后) */
    -khtml-user-select: none; /* KHTML内核私有属性 */
    -o-user-select: none; /* Opera私有属性 */
    user-select: none; /* CSS3属性 */
  }
}
.vo-items-center {
  align-items: center;
}
.vo-justify-between {
  justify-content: space-between;
}
.vo-column {
  display: flex;
  flex-direction: column;
}
.vo-row {
  display: flex;
  flex-direction: row;
}
.vo-gap-4 {
  gap: 4px;
}
.custom-width {
  width: 130px;
}
.custom-color {
  color: #38a4f8;
}
.cursor {
  cursor: pointer;
  &:hover {
    text-decoration: underline; /* 添加下划线 */
  }
}
.vo-flex-1 {
  flex: 1;
}
.vo-flex-2 {
  flex: 2;
}
.vo-flex-3 {
  flex: 3;
}
.box-list {
  border-top: 1px solid #999;
  padding-top: 15px;
  margin-top: 15px;
}
.customCol {
  margin-right: 12px;
}

.clientStyle {
  color: red;
}
.vo-space-around {
  justify-content: space-around;
}
.vo-object-fit {
  object-fit: contain;
}

.vo-front-style {
  font-family: "Arial Negreta", "Arial Normal", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  color: #333333;
}
</style>
