<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <b-card> -->
    <Steps :Steps="registSpets" :SetData="SetData" />
    <!-- </b-card> -->
    <b-card>
      <b-col lg="12">
        <p class="title" style="font-size: 16px; font-weight: bolder">
          <span
            style="
              display: inline-block;
              width: 10px;
              height: 20px;
              vertical-align: text-top;
              background-color: #626ed4;
            "
          ></span>
          Filling Batch Information
        </p>
      </b-col>
      <!-- <form-wizard
        @on-complete="onComplete"
        @on-change="handleChange"
        :start-index.sync="activeIndex"
        color="#626ed4"
        error-color="#a94442"
      > -->
      <!-- <tab-content
          icon="mdi mdi-account-circle"
          :before-change="validateFirstTab"
        > -->
      <b-row>
        <b-col lg="12">
          <b-form ref="firstTabForm" @submit="onSubmit" @reset="onReset">
            <!-- Batch Name 批次名称 -->
            <b-form-group
              label="Batch Name:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="batch-name"
              label-class="requiredRow"
            >
              <b-form-input
                id="batch-name"
                v-model="form.batchName"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please enter"
              ></b-form-input>
            </b-form-group>

            <!-- 烟弹型号 -->
            <b-form-group
              label="Cartridge Model:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="cartridge-model"
              label-class="requiredRow"
            >
              <b-form-select
                id="cartridge-model"
                v-model="form.testSmokeBombModeId"
                :options="types.selectAllTestSmokeBombModel"
                value-field="id"
                text-field="modelName"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
                @change="changeModelId(form.testSmokeBombModeId)"
              ></b-form-select>
            </b-form-group>

            <!-- 烟油型号 -->
            <b-form-group
              label="Distillate Flavor:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="content-model"
              label-class="requiredRow"
            >
              <b-form-select
                id="content-model"
                v-model="form.smokeBombModelId"
                :options="types.ModelBySupplierId"
                @change="
                  changeModel(form.smokeBombModelId, types.ModelBySupplierId)
                "
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
              <div class="b-row-point">
                <span class="b-row-point-msg">Not on the list? ？</span>
                <div class="b-row-poin-button" @click="onClickCreateFlavor">
                  Create New Flavor
                </div>
              </div>
            </b-form-group>

            <!-- 默认加热模式 -->
            <b-form-group
              label="Default Heating Setting:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="heating-model"
              label-class="requiredRow"
            >
              <b-form-select
                id="heating-model"
                v-model="form.defaultWarmHeatingId"
                :options="types.selectAllDefaultHeatingMode"
                value-field="id"
                text-field="modeName"
                @change="
                  changeModel(
                    form.smokeBombModelId,
                    types.selectAllDefaultHeatingMode
                  )
                "
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
              <div class="b-row-point">
                <i
                  class="mdi mdi-refresh mdi-18px"
                  @click="onClickRefreshHeatingList"
                ></i>
                <div
                  class="b-row-poin-button"
                  @click="onClickCreateHeatingModel"
                >
                  Create New Setting
                </div>
              </div>
            </b-form-group>

            <!-- 默认预热模式 -->
            <b-form-group
              label="Default Pre-Heat Setting:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="warmup-model"
            >
              <b-form-select
                id="warmup-model"
                v-model="form.defaultWarmUpId"
                :options="types.selectAllDefaultWarmUpMode"
                value-field="id"
                text-field="warmUpName"
                @change="
                  changeModel(
                    form.smokeBombModelId,
                    types.selectAllDefaultWarmUpMode
                  )
                "
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
              <div class="b-row-point">
                <i
                  class="mdi mdi-refresh mdi-18px"
                  @click="onClickRefreshPreHeatingList"
                ></i>
                <div
                  class="b-row-poin-button"
                  @click="onClickCreateWarmUpModel"
                >
                  Create New Setting
                </div>
              </div>
            </b-form-group>

            <!-- 烟弹口味 -->
            <b-form-group
              v-if="types.flavorOptions.length"
              :label="flavorLabel"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="cartridge-taste"
              label-class="requiredRow"
            >
              <b-form-radio-group
                class="pt-2"
                v-model="form.flavorId"
                :options="types.flavorOptions"
                required
              ></b-form-radio-group>
            </b-form-group>

            <!-- 烟油容量 -->
            <b-form-group
              label="Amount to Fill Per Cartridge(mg/ml):"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="capacity"
              label-class="requiredRow"
              description="Cartridge Status Will Be Calculated Base on this."
            >
              <b-form-input
                id="capacity"
                v-model="form.capacity"
                type="number"
                min="0"
                step="0.01"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <!-- 最大吸入上限 -->
            <b-form-group
              label="Upper Limit for Vaporization Time in Seconds:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="maxFrequency"
              label-class="requiredRow"
              description="To Prevent Counterfeit by Refill, Cartridge Will Stop Working After Time Reaches."
            >
              <b-form-input
                id="maxFrequency"
                v-model="form.maxFrequency"
                type="number"
                min="0"
                step="0.01"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <!-- Batch Quantity 批次数量 -->
            <b-form-group
              label="Numbers to Fill on This Batch:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="cartridge-quantity"
              label-class="requiredRow"
            >
              <b-form-input
                id="batch-quantity"
                v-model="form.batchQuantity"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');if(value<0 || value>100000)value = 0"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <!-- Manufacturer Name 生产厂家 -->
            <b-form-group
              label="Oil FIlling Plant:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="manufacturer"
            >
              <b-form-select
                id="manufacturer"
                v-model="form.manufacturerEncodingId"
                :options="types.manufacturerEncodingList"
                value-field="id"
                text-field="name"
              ></b-form-select>
              <div class="b-row-point">
                <span class="b-row-point-msg">Not on the List ？</span>
                <div class="b-row-poin-button" @click="onClickCreatePlant">
                  Add new Oil Filling Plant
                </div>
              </div>
            </b-form-group>

            <!-- 备注 -->
            <b-form-group
              label="Notes:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="remarks"
            >
              <b-form-textarea
                id="remarks"
                v-model="form.remarks"
                placeholder="Enter Notes"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-col lg="12" style="text-align: center">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" variant="danger" style="margin-left: 30px"
                >Cancel</b-button
              >
            </b-col>
          </b-form>
        </b-col>
        <!-- end col -->
      </b-row>

      <!-- end row -->
      <!-- </tab-content> -->
      <!-- </form-wizard> -->
    </b-card>
    <CreateFlavor
      :showCreateFlavor="showCreateFlavor"
      :deviceModelId="deviceModelId"
      @CancelEvent="cancelEvent"
      @AddFlavorComplete="addFlavorComplete"
    ></CreateFlavor>
    <CreatePlant
      :showCreatePlant="showCreatePlant"
      @CancelPlantEvent="cancelPlantEvent"
      @AddPlantComplete="addPlantComplete"
    ></CreatePlant>
  </Layout>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import Multiselect from "vue-multiselect";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Steps from "./cartridge-filling-step";
import CreateFlavor from "@/components/modal/create-new-flavor";
import CreatePlant from "@/components/modal/create-new-plant";

/**
 * Cartridge Batch Add component
 */

export default {
  components: {
    Layout,
    PageHeader,
    FormWizard,
    TabContent,
    Multiselect,
    Steps,
    CreateFlavor,
    CreatePlant,
  },
  computed: {
    is_add: function () {
      return /add/i.test(location.href);
    },
  },
  beforeCreate() {
    // this.init();
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      title: "Cartridge Filling Create",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Manage",
          // href: "/"
        },
        {
          text: "Cartridge Filling Create",
          active: true,
        },
      ],
      registSpets: 0,
      //SetData: ["Create Batch", "Import Production Details", "Complete"],
      SetData: [
        "Create Production Batch Order",
        "In Production",
        "Complete COA information",
        "Production Completed",
      ],
      types: {
        flavorOptions: [],
        ModelBySupplierId: [{ text: "Please select", value: "", flavor: "" }],
        manufacturerEncodingList: [{ name: "Please select", id: "" }],
        selectAllTestSmokeBombModel: [{ modelName: "Please select", id: "" }],
        selectAllDefaultHeatingMode: [{ modeName: "Please select", id: "" }],
        selectAllDefaultWarmUpMode: [{ warmUpName: "Please select", id: "" }],
      },
      form: {
        batchName: "",
        smokeBombModelId: "",
        flavorId: "",
        testSmokeBombModeId: "",
        capacity: 1,
        maxFrequency: 2000,
        batchQuantity: 100,
        manufacturerEncodingId: "",
        remarks: "",
        defaultWarmHeatingId: "",
        defaultWarmUpId: "",
        type: 1, //类型(1是油弹 2是膏弹 3是电池烟弹 4是电磁烟弹 5是NIC)
      },
      flavorLabel: "Flavor:",
      showCreateFlavor: false,
      showCreatePlant: false,
      deviceModelId: "", //关联设备ID
    };
  },
  methods: {
    init() {
      this.$api.Dropdown.manufacturerEncodingList().then((res) => {
        if (res.success && res.data) {
          this.types.manufacturerEncodingList =
            this.types.manufacturerEncodingList.concat(res.data);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
      this.$api.Dropdown.selectAllTestSmokeBombModel({ type: 1 }).then(
        (res) => {
          if (res.success && res.data) {
            this.types.selectAllTestSmokeBombModel =
              this.types.selectAllTestSmokeBombModel.concat(res.data);
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        }
      );
      //默认加热模式
      this.$api.Dropdown.selectDefaultHeatingMode().then((res) => {
        if (res.success && res.data) {
          this.types.selectAllDefaultHeatingMode =
            this.types.selectAllDefaultHeatingMode.concat(res.data);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
      //默认预热模式
      this.$api.Dropdown.selectDefaultWarmUpMode().then((res) => {
        if (res.success && res.data) {
          this.types.selectAllDefaultWarmUpMode =
            this.types.selectAllDefaultWarmUpMode.concat(res.data);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$api.CartridgeBatch.add(this.form).then((res) => {
        if (res.success) {
          this.$toastBack(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.$router.go(-1);
    },
    changeModel(a, b) {
      this.types.flavorOptions = [];
      if (a) {
        b.forEach((item) => {
          if (item.value == a) {
            if (item.flavor) {
              let o = JSON.parse(item.flavor);
              for (let i = 0; i < o.length; i++) {
                this.types.flavorOptions.push({
                  text: o[i].flavorName,
                  value: o[i].id,
                });
              }
            }
            let u = JSON.parse(item.type);
            if (u.id == 1) {
              this.flavorLabel = "Flavor:";
            } else if (u.id == 4) {
              this.flavorLabel = "Graphic Name:";
            } else {
              this.flavorLabel = "Flavor:";
            }
          }
        });
      }
    },
    changeModelId(modelId) {
      let entity = this.types.selectAllTestSmokeBombModel.find(
        (o) => o.id === modelId
      );
      if (entity != undefined) {
        this.deviceModelId = entity.deviceModelId;
      }
      if (modelId) {
        this.$api.Dropdown.selectCartridgeModelById({
          id: modelId,
        }).then((res) => {
          if (res.success) {
            // this.types.ModelBySupplierId = res.data;
            this.types.ModelBySupplierId = [];
            this.types.ModelBySupplierId.push({
              text: "Please select",
              value: "",
              flavor: "",
            });
            for (let i = 0; i < res.data.length; i++) {
              this.types.ModelBySupplierId.push({
                text: res.data[i].modelName,
                value: res.data[i].id,
                flavor: res.data[i].flavor,
                type: res.data[i].type,
              });
            }
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      }
    },
    //#region Create New Flavor Modal
    onClickCreateFlavor() {
      this.showCreateFlavor = true;
    },
    //#endregion

    //#region Refresh Heating List
    onClickRefreshHeatingList() {
      //默认加热模式
      this.$api.Dropdown.selectDefaultHeatingMode().then((res) => {
        if (res.success && res.data) {
          this.types.selectAllDefaultHeatingMode = [
            { modeName: "Please select", id: "" },
          ];
          this.types.selectAllDefaultHeatingMode =
            this.types.selectAllDefaultHeatingMode.concat(res.data);
          this.$toast(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    //#endregion
    //#region Create New Heating Link
    onClickCreateHeatingModel() {
      let routeUrl = this.$router.resolve({
        path: "/dataDictionary/heatingModeAdd",
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({ name: "heatingModeAdd" });
    },
    //#endregion
    //#region Refresh Pre-Heating List
    onClickRefreshPreHeatingList() {
      //默认预热模式
      this.$api.Dropdown.selectDefaultWarmUpMode().then((res) => {
        if (res.success && res.data) {
          this.types.selectAllDefaultWarmUpMode = [
            { warmUpName: "Please select", id: "" },
          ];
          this.types.selectAllDefaultWarmUpMode =
            this.types.selectAllDefaultWarmUpMode.concat(res.data);
          this.$toast(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    //#endregion
    //#region Create New Pre-Heating Link
    onClickCreateWarmUpModel() {
      let routeUrl = this.$router.resolve({
        path: "/dataDictionary/preheatModeAdd",
      });
      window.open(routeUrl.href, "_blank");
      //this.$router.push({ name: "preheatModeAdd" });
    },
    //#endregion
    //#region Accept child components Value
    cancelEvent(value) {
      this.showCreateFlavor = value;
    },
    //#endregion
    //#region Create New Flavor Complete
    addFlavorComplete(value) {
      this.changeModelId(this.form.testSmokeBombModeId);
    },
    //#endregion
    //#region Create New Plant
    onClickCreatePlant() {
      this.showCreatePlant = true;
    },
    //#endregion
    //#region Accept child components Value
    cancelPlantEvent(value) {
      this.showCreatePlant = value;
    },
    //#endregion
    //#region  Create New Plant Complete
    addPlantComplete(value) {
      this.$api.Dropdown.manufacturerEncodingList().then((res) => {
        if (res.success && res.data) {
          this.types.manufacturerEncodingList = [
            { name: "Please select", id: "" },
          ];
          this.types.manufacturerEncodingList =
            this.types.manufacturerEncodingList.concat(res.data);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    //#endregion
  },
};
</script>
