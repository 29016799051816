/**
 * 弹出页模块 模块接口
 */
// import base from "./base.js";
import axios from "../config/http.js"
import baseUrl from "../config/env.js"

const popupManage = {
	//查询弹出页详情
	selectPopupDetails() {
		return axios.fetchGet(baseUrl + '/brandPopup/selectPopupDetails');
	},

	//保存弹出页
	savePopup(params) {
		return axios.fetchPost(baseUrl + '/brandPopup/savePopup', params);
	},
}

export default popupManage;
