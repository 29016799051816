import { format } from "./time";
import axios from "../config/http.js";
import axios1 from "../config/http-json.js";
import baseUrl from "../config/env.js";

// faq
function list(params, ctx) {
  return axios.fetchGet(baseUrl + "/consultation/list", params);
}

function enableOrDisable(params = {}) {
  return axios.fetchPost(baseUrl + "/consultation/enableOrDisable", params);
}

function saveConsultation(params) {
  return axios1.fetchPost(baseUrl + "/consultation/saveConsultation", params);
}

function findById(params) {
  return axios.fetchPost(baseUrl + "/consultation/findById", params);
}

function updateById(params) {
  return axios1.fetchPost(baseUrl + "/consultation/updateById", params);
}

// user-guide
function useGuideList(params, ctx) {
  return axios.fetchGet(baseUrl + "/useGuide/list", params);
}
function saveUseGuide(params) {
  return axios.fetchPost(baseUrl + "/useGuide/saveUseGuide", params);
}
function updateUseGuide(params) {
  return axios.fetchPost(baseUrl + "/useGuide/updateUseGuide", params);
}
function useGuideEnableOrDisable(params = {}) {
  return axios.fetchPost(baseUrl + "/useGuide/enableOrDisable", params);
}

// research-questions
async function selectAllProblemGroup(params, ctx) {
  return axios.fetchGet(
    baseUrl + "/problemGroup/selectAllProblemGroup",
    params
  );
}

function selectAllProblemType(params) {
  return axios.fetchGet(baseUrl + "/problemGroup/selectAllProblemType", params);
}

function saveProblemGroup(params) {
  return axios1.fetchPost(baseUrl + "/problemGroup/saveProblemGroup", params);
}

function selectProblemGroupDetails(params) {
  return axios.fetchGet(
    baseUrl + "/problemGroup/selectProblemGroupDetails",
    params
  );
}

function modifiedProblemGroup(params) {
  return axios1.fetchPost(
    baseUrl + "/problemGroup/modifiedProblemGroup",
    params
  );
}

function enableOrDisableProblemGroup(params) {
  return axios.fetchPost(
    baseUrl + "/problemGroup/enableOrDisableProblemGroup",
    params
  );
}

// recommender-code
async function recommendCodeList(params, ctx) {
  return axios.fetchGet(baseUrl + "/recommendCode/list", params);
}
function codeEnableOrDisable(params = {}) {
  return axios.fetchPost(baseUrl + "/recommendCode/enableOrDisable", params);
}
function addRecommendCode(params) {
  return axios.fetchPost(baseUrl + "/recommendCode/addRecommendCode", params);
}
function updateRecommendCode(params) {
  return axios.fetchPost(
    baseUrl + "/recommendCode/updateRecommendCode",
    params
  );
}

export default {
  list,
  enableOrDisable,
  saveConsultation,
  findById,
  updateById,

  useGuideList,
  saveUseGuide,
  updateUseGuide,
  useGuideEnableOrDisable,

  selectAllProblemGroup,
  selectAllProblemType,
  saveProblemGroup,
  selectProblemGroupDetails,
  modifiedProblemGroup,
  enableOrDisableProblemGroup,

  recommendCodeList,
  codeEnableOrDisable,
  addRecommendCode,
  updateRecommendCode,
};
