<template>
  <Layout>
    <div role="tablist" style="margin-top:1rem;">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            href="#"
            v-b-toggle.accordion-1
            variant="outline-primary"
            style="text-align: left;line-height: 3;font-size: 1rem;"
            >Step 1: Basic Data</b-button
          >
        </b-card-header>
        <b-collapse
          id="accordion-1"
          :visible="basicDataVisible"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body style="padding-top: 0;padding-bottom: 0;">
            <div v-for="(basicData, index) in basicDataList" :key="index">
              <b-button
                block
                :variant="
                  basicData.indeterminate ? 'outline-dark' : 'outline-success'
                "
                style="text-align: left;line-height: 2;margin:5px;"
                @click="goPage(basicData, index, 'setBasicDataList')"
              >
                <b-form-checkbox
                  v-model="basicData.indeterminate"
                  :disabled="basicData.indeterminate"
                >
                  <div
                    v-if="basicData.indeterminate"
                    class="haveToClick"
                    style="cursor:pointer;"
                  >
                    <p style="margin-bottom: 0;">{{ basicData.text }}</p>
                    <span>{{ basicData.explain }}</span>
                  </div>
                  <div v-else style="cursor:pointer;">
                    <p style="margin-bottom: 0;">{{ basicData.text }}</p>
                    <span>{{ basicData.explain }}</span>
                  </div>
                </b-form-checkbox>
              </b-button>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            href="#"
            v-b-toggle.accordion-2
            variant="outline-primary"
            style="text-align: left;line-height: 3;font-size: 1rem;"
            >Step 2: Flavor Info</b-button
          >
        </b-card-header>
        <b-collapse
          id="accordion-2"
          accordion="my-accordion"
          role="tabpanel"
          :visible="flavorInfoVisible"
        >
          <b-card-body style="padding-top: 0;padding-bottom: 0;">
            <div v-for="(flavorInfo, index) in flavorInfoList" :key="index">
              <b-button
                block
                :variant="
                  flavorInfo.indeterminate ? 'outline-dark' : 'outline-success'
                "
                style="text-align: left;line-height: 2;margin:5px;"
                @click="goPage(flavorInfo, index, 'setFlavorInfoList')"
              >
                <b-form-checkbox
                  v-model="flavorInfo.indeterminate"
                  :disabled="flavorInfo.indeterminate"
                >
                  <div
                    v-if="flavorInfo.indeterminate"
                    class="haveToClick"
                    style="cursor:pointer;"
                  >
                    <p style="margin-bottom: 0;">{{ flavorInfo.text }}</p>
                    <span>{{ flavorInfo.explain }}</span>
                  </div>
                  <div v-else style="cursor:pointer;">
                    <p style="margin-bottom: 0;">{{ flavorInfo.text }}</p>
                    <span>{{ flavorInfo.explain }}</span>
                  </div>
                </b-form-checkbox>
              </b-button>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            href="#"
            v-b-toggle.accordion-3
            variant="outline-primary"
            style="text-align: left;line-height: 3;font-size: 1rem;"
            >Step 3: Production Process</b-button
          >
        </b-card-header>
        <b-collapse
          id="accordion-3"
          accordion="my-accordion"
          role="tabpanel"
          :visible="productionProcessVisible"
        >
          <b-card-body style="padding-top: 0;padding-bottom: 0;">
            <div
              v-for="(productionProcess, index) in productionProcessList"
              :key="index"
            >
              <b-button
                block
                :variant="
                  productionProcess.indeterminate
                    ? 'outline-dark'
                    : 'outline-success'
                "
                style="text-align: left;line-height: 2;margin:5px;"
                @click="
                  goPage(productionProcess, index, 'setProductionProcessList')
                "
              >
                <b-form-checkbox
                  v-model="productionProcess.indeterminate"
                  :disabled="productionProcess.indeterminate"
                >
                  <div
                    v-if="productionProcess.indeterminate"
                    class="haveToClick"
                    style="cursor:pointer;"
                  >
                    <p style="margin-bottom: 0;">
                      {{ productionProcess.text }}
                    </p>
                    <span>{{ productionProcess.explain }}</span>
                  </div>
                  <div v-else style="cursor:pointer;">
                    <p style="margin-bottom: 0;">
                      {{ productionProcess.text }}
                    </p>
                    <span>{{ productionProcess.explain }}</span>
                  </div>
                </b-form-checkbox>
              </b-button>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            href="#"
            v-b-toggle.accordion-4
            variant="outline-primary"
            style="text-align: left;line-height: 3;font-size: 1rem;"
            >Step 4: View The Data</b-button
          >
        </b-card-header>
        <b-collapse
          id="accordion-4"
          accordion="my-accordion"
          role="tabpanel"
          :visible="viewVisible"
        >
          <b-card-body style="padding-top: 0;padding-bottom: 0;">
            <div v-for="(view, index) in viewList" :key="index">
              <b-button
                block
                :variant="
                  view.indeterminate ? 'outline-dark' : 'outline-success'
                "
                style="text-align: left;line-height: 2;margin:5px;"
                @click="goPage(view, index, 'setViewList')"
              >
                <b-form-checkbox
                  v-model="view.indeterminate"
                  :disabled="view.indeterminate"
                >
                  <div
                    v-if="view.indeterminate"
                    class="haveToClick"
                    style="cursor:pointer;"
                  >
                    <p style="margin-bottom: 0;">{{ view.text }}</p>
                    <span>{{ view.explain }}</span>
                  </div>
                  <div v-else style="cursor:pointer;">
                    <p style="margin-bottom: 0;">{{ view.text }}</p>
                    <span>{{ view.explain }}</span>
                  </div>
                </b-form-checkbox>
              </b-button>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import { mapState } from "vuex";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      basicDataVisible: true,
      flavorInfoVisible: false,
      productionProcessVisible: false,
      viewVisible: false,
    };
  },
  computed: mapState([
    // 映射 this.count 为 store.state.count
    "basicDataList",
    "flavorInfoList",
    "productionProcessList",
    "viewList",
    "isGuide",
  ]),
  created() {
    var resultA = this.basicDataList.some((item) => {
      if (!item.indeterminate) {
        return true;
      }
    });
    var resultB = this.flavorInfoList.some((item) => {
      if (!item.indeterminate) {
        return true;
      }
    });
    var resultC = this.productionProcessList.some((item) => {
      if (!item.indeterminate) {
        return true;
      }
    });
    var resultD = this.viewList.some((item) => {
      if (!item.indeterminate) {
        return true;
      }
    });
    if (resultA) {
      this.basicDataVisible = true;
    } else if (!resultA && resultB) {
      this.basicDataVisible = false;
      this.flavorInfoVisible = true;
    } else if (!resultA && !resultB && resultC) {
      this.basicDataVisible = false;
      this.flavorInfoVisible = false;
      this.productionProcessVisible = true;
    } else if (!resultA && !resultB && !resultC && resultD) {
      this.basicDataVisible = false;
      this.flavorInfoVisible = false;
      this.productionProcessVisible = false;
      this.viewVisible = true;
    } else {
      this.basicDataVisible = false;
      this.flavorInfoVisible = false;
      this.productionProcessVisible = false;
      this.viewVisible = false;
    }
  },
  mounted() {},
  methods: {
    goPage(ele, index, name) {
      this.$router.push(ele.value);
      this.$store.commit(name, index);
      if (this.isGuide == 0) {
        this.$api.account
          .supplierGuide({ supplierId: localStorage.supplierId })
          .then((res) => {
            this.$store.commit("setIsGuide", 1);
          });
      }
    },
  },
};
</script>

<style lang="scss">
.haveToClick {
  text-decoration: line-through !important;
}
</style>
