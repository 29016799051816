const echarts = require('echarts/lib/echarts');
require('echarts/lib/component/grid');
require('echarts/lib/chart/line');

const graphic = require('echarts/lib/util/graphic');
const voltageData = {
  color: ["#86A1A9"],
  xAxis: {
    show: false,
    type: "category",
    boundaryGap: false,
    data: ["1", "2", "3", "4", "5", "6", "7", "8"]
  },
  yAxis: {
    show: false,
    type: "value"
  },
  grid: { bottom: 4, left: 22, right: 22, top: 22 },
  series: [
    {
      data: [1.3, 1.7, 3.3, 4, 2.0, 2.9, 3.6, 2],
      type: "line",
      smooth: true,
      symbol: "circle",
      symbolSize: 8,
      label: {
        show: true,
        position: "top",
        formatter: function(a) {
          // console.log(`c = ${c}`);
          return `${a.value.toFixed(1)}W`;
        },
        color: "#86A1A9",
        fontSize: 10,
        distance: 4
      },
      areaStyle: {
        color: graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgba(192, 222, 226, 1)" //c0dee2
          },
          {
            offset: 1,
            color: "rgba(246, 250, 250, 1)"//f6fafa
          }
        ])
      }
    }
  ]
};

export function chartConfig() {
  return {
    color: ["#86A1A9"],
    xAxis: {
      show: false,
      type: "category",
      boundaryGap: false,
      data: ["1", "2", "3", "4", "5", "6", "7", "8"]
    },
    yAxis: {
      show: false,
      type: "value"
    },
    grid: { bottom: 4, left: 22, right: 22, top: 22 },
    series: [
      {
        data: [1.3, 1.7, 3.3, 4, 2.0, 2.9, 3.6, 2],
        type: "line",
        smooth: true,
        symbol: "circle",
        symbolSize: 8,
        label: {
          show: true,
          position: "top",
          formatter: function(a) {
            // console.log(`c = ${c}`);
            let value = a.value;
            return `${a.value.toFixed(1)}W`;
          },
          color: "#86A1A9",
          fontSize: 10,
          distance: 4
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgba(192, 222, 226, 1)"
            },
            {
              offset: 1,
              color: "rgba(246, 250, 250, 1)"
            }
          ])
        }
      }
    ]
  };
}

export { voltageData };
