var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal",
        size: "lg",
        centered: "",
        title: "Create New Plant",
        "ok-title": "Confirm",
        "no-close-on-esc": "",
        "no-close-on-backdrop": ""
      },
      on: { ok: _vm.onConfirm, cancel: _vm.onCancel, close: _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function() {
            return [_c("div", { staticClass: "w-100" })]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.showCreatePlant,
        callback: function($$v) {
          _vm.showCreatePlant = $$v
        },
        expression: "showCreatePlant"
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  attrs: { action: "#" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onConfirm($event)
                    },
                    reset: _vm.onCancel
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Manufacturer Name",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "manufacturer-name",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "manufacturer-name",
                                  placeholder: "Please enter",
                                  trim: "",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                                },
                                model: {
                                  value: _vm.typeform.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "name", $$v)
                                  },
                                  expression: "typeform.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Contacts",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "contacts"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "contacts",
                                  placeholder: "Please enter",
                                  trim: "",
                                  oninput:
                                    "if(value.length > 50)value = value.slice(0, 50)"
                                },
                                model: {
                                  value: _vm.typeform.contacts,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "contacts", $$v)
                                  },
                                  expression: "typeform.contacts"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Email",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "email"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "email",
                                  placeholder: "Please enter",
                                  type: "email",
                                  trim: "",
                                  oninput:
                                    "if(value.length > 50)value = value.slice(0, 50)"
                                },
                                model: {
                                  value: _vm.typeform.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "email", $$v)
                                  },
                                  expression: "typeform.email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Contact Number",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "contact-number"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "contact-number",
                                  placeholder: "Please enter",
                                  trim: "",
                                  oninput:
                                    "if(value.length > 50)value = value.slice(0, 50)"
                                },
                                model: {
                                  value: _vm.typeform.phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "phone", $$v)
                                  },
                                  expression: "typeform.phone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Contact Address",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "contact-address"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "contact-address",
                                  placeholder: "Please enter",
                                  trim: "",
                                  oninput:
                                    "if(value.length > 50)value = value.slice(0, 50)"
                                },
                                model: {
                                  value: _vm.typeform.address,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "address", $$v)
                                  },
                                  expression: "typeform.address"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Notes",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "flavor-remark"
                              }
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "remark",
                                  placeholder: "Enter Notes",
                                  rows: "3",
                                  "max-rows": "6"
                                },
                                model: {
                                  value: _vm.typeform.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "remarks", $$v)
                                  },
                                  expression: "typeform.remarks"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "form-group mb-0" }, [
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "b-button",
                          { attrs: { type: "submit", variant: "primary" } },
                          [_vm._v("Confirm")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "m-l-5 ml-1",
                            attrs: { type: "reset", variant: "danger" }
                          },
                          [_vm._v("Cancel")]
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }