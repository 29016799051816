var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "8" } },
                [
                  _c(
                    "b-form",
                    {
                      attrs: { action: "#" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit_form($event)
                        },
                        reset: _vm.reset_form
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Manufacturer Name",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "manufacturer-name",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "manufacturer-name",
                              placeholder: "Please enter",
                              trim: "",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput:
                                "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                            },
                            model: {
                              value: _vm.typeform.name,
                              callback: function($$v) {
                                _vm.$set(_vm.typeform, "name", $$v)
                              },
                              expression: "typeform.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Contacts",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "contacts"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "contacts",
                              placeholder: "Please enter",
                              trim: "",
                              oninput:
                                "if(value.length > 50)value = value.slice(0, 50)"
                            },
                            model: {
                              value: _vm.typeform.contacts,
                              callback: function($$v) {
                                _vm.$set(_vm.typeform, "contacts", $$v)
                              },
                              expression: "typeform.contacts"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Email",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "email"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "email",
                              placeholder: "Please enter",
                              type: "email",
                              trim: "",
                              oninput:
                                "if(value.length > 50)value = value.slice(0, 50)"
                            },
                            model: {
                              value: _vm.typeform.email,
                              callback: function($$v) {
                                _vm.$set(_vm.typeform, "email", $$v)
                              },
                              expression: "typeform.email"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Contact Number",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "contact-number"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "contact-number",
                              placeholder: "Please enter",
                              trim: "",
                              oninput:
                                "if(value.length > 50)value = value.slice(0, 50)"
                            },
                            model: {
                              value: _vm.typeform.phone,
                              callback: function($$v) {
                                _vm.$set(_vm.typeform, "phone", $$v)
                              },
                              expression: "typeform.phone"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Contact Address",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "contact-address"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "contact-address",
                              placeholder: "Please enter",
                              trim: "",
                              oninput:
                                "if(value.length > 50)value = value.slice(0, 50)"
                            },
                            model: {
                              value: _vm.typeform.address,
                              callback: function($$v) {
                                _vm.$set(_vm.typeform, "address", $$v)
                              },
                              expression: "typeform.address"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Notes",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "flavor-remark"
                          }
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              id: "remark",
                              placeholder: "Enter Notes",
                              rows: "3",
                              "max-rows": "6"
                            },
                            model: {
                              value: _vm.typeform.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.typeform, "remarks", $$v)
                              },
                              expression: "typeform.remarks"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        { attrs: { "label-cols-lg": "3" } },
                        [
                          _c(
                            "b-button",
                            { attrs: { type: "submit", variant: "primary" } },
                            [_vm._v("Confirm")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "m-l-5 ml-1",
                              attrs: { type: "reset" }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }