import { render, staticRenderFns } from "./share-add.vue?vue&type=template&id=5a620644&scoped=true&"
import script from "./share-add.vue?vue&type=script&lang=js&"
export * from "./share-add.vue?vue&type=script&lang=js&"
import style0 from "./share-add.vue?vue&type=style&index=0&id=5a620644&scoped=true&lang=css&"
import style1 from "./share-add.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a620644",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\公司文件\\web\\omni\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a620644')) {
      api.createRecord('5a620644', component.options)
    } else {
      api.reload('5a620644', component.options)
    }
    module.hot.accept("./share-add.vue?vue&type=template&id=5a620644&scoped=true&", function () {
      api.rerender('5a620644', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/marketing-manage/share/share-add.vue"
export default component.exports