var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  attrs: { action: "#" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit_form($event)
                    },
                    reset: _vm.reset_form
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Country",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "country-name",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "country-name",
                                  placeholder: "Please enter",
                                  trim: "",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                                },
                                model: {
                                  value: _vm.typeform.countryName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "countryName", $$v)
                                  },
                                  expression: "typeform.countryName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "City",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "city-name",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "city-name",
                                  placeholder: "Please enter",
                                  trim: "",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                                },
                                model: {
                                  value: _vm.typeform.cityName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "cityName", $$v)
                                  },
                                  expression: "typeform.cityName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Notes",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "remark"
                              }
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "remark",
                                  placeholder: "Enter Notes",
                                  rows: "3",
                                  "max-rows": "6"
                                },
                                model: {
                                  value: _vm.typeform.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "remarks", $$v)
                                  },
                                  expression: "typeform.remarks"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "form-group mb-0" }, [
                    _c("div", { staticClass: "text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit" }
                        },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary m-l-5 ml-1",
                          attrs: { type: "reset" }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }