var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productInfo" }, [
                    _c("strong", [_vm._v("Flavor ID:")])
                  ]),
                  _c("p", { staticClass: "productInfoContent" }, [
                    _vm._v(" " + _vm._s(_vm.info.id) + " ")
                  ])
                ]),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productInfo" }, [
                    _c("strong", [_vm._v("Flavor Name:")])
                  ]),
                  _c("p", { staticClass: "productInfoContent" }, [
                    _vm._v(" " + _vm._s(_vm.info.modelName) + " ")
                  ])
                ]),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productInfo" }, [
                    _c("strong", [_vm._v("Ingredients:")])
                  ]),
                  _c(
                    "p",
                    { staticClass: "productInfoContent" },
                    _vm._l(_vm.info.ingredientsVoList, function(item, i) {
                      return _c(
                        "b-row",
                        { key: i, staticStyle: { "margin-bottom": "10px" } },
                        [
                          _c("b-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          _c("b-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(item.proportion) + "%")
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ]),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productInfo" }, [
                    _c("strong", [_vm._v("Terpenes:")])
                  ]),
                  _c(
                    "p",
                    { staticClass: "productInfoContent" },
                    _vm._l(_vm.info.terpenesList, function(item, i) {
                      return _c(
                        "b-row",
                        { key: i, staticStyle: { "margin-bottom": "10px" } },
                        [
                          _c("b-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          _c("b-col", { attrs: { cols: "4" } }, [
                            _vm._v(_vm._s(item.proportion) + "%")
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ]),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productInfo" }, [
                    _c("strong", [_vm._v("Usage Effects Tags:")])
                  ]),
                  _c("p", { staticClass: "productInfoContent" }, [
                    _vm._v(" " + _vm._s(_vm.info.commodityLabelName) + " ")
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "vo-row" },
                  [
                    _c("p", { staticClass: "productInfo" }, [
                      _c("strong", [_vm._v(" Flavor Picture:")])
                    ]),
                    _c(
                      "b-col",
                      {
                        staticClass: "pd cursor",
                        staticStyle: { "margin-left": "-10px" },
                        attrs: { cols: "5" }
                      },
                      [
                        _c("ImageComponets", {
                          attrs: { image: _vm.info.flavorPicture }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productInfo" }, [
                    _c("strong", [_vm._v("Status:")])
                  ]),
                  _c("p", { staticClass: "productInfoContent" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.info.status == 0 ? "Eanable" : "Disable") +
                        " "
                    )
                  ])
                ])
              ]),
              _c(
                "b-col",
                { ref: "container" },
                [
                  _vm.pieChartWidth !== 0
                    ? _c("FlavorPieChart", {
                        attrs: {
                          legend: _vm.legend,
                          series: _vm.series,
                          height: "300px",
                          width: _vm.pieChartWidth
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              staticStyle: { "margin-top": "40px" },
              attrs: { "label-cols-lg": "2" }
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "custom-width",
                  attrs: { type: "reset" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [_vm._v("Return ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.productDetail.id
        ? _c(
            "b-card",
            [
              _c(
                "b-row",
                { staticClass: "align-items-center" },
                [
                  _c("b-col", { attrs: { lg: "12" } }, [
                    _c("div", { staticClass: "blockquote" }, [
                      _c("h4", [_vm._v("Product Info")])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "productDetailRow",
                  staticStyle: { "margin-left": "40px" }
                },
                [
                  _c("div", [
                    _c("div", { staticClass: "productDetailBorder" }, [
                      _c("img", {
                        attrs: { src: _vm.productDetail.deviceImage }
                      })
                    ]),
                    _c(
                      "div",
                      { staticClass: "productDetailImageRow" },
                      _vm._l(_vm.productDetail.productPicture, function(
                        item,
                        key
                      ) {
                        return _c("img", {
                          key: key,
                          attrs: { src: item.value }
                        })
                      }),
                      0
                    )
                  ]),
                  _c("div", { staticClass: "productDetailContent" }, [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          color: "#3eb1c8",
                          "font-weight": "700"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.productDetail.productSeriesName) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "13px",
                          color: "#aaaaaa",
                          "font-family": "'Arial Normal', 'Arial', sans-serif"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.productDetail.remarks) + " ")]
                    ),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Product Model #:")]
                      ),
                      _vm._v(" " + _vm._s(_vm.productDetail.modelName) + " ")
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Variant Code #:")]
                      ),
                      _vm._v(" " + _vm._s(_vm.productDetail.variantCode) + " ")
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Marketing Name:")]
                      ),
                      _vm._v(" " + _vm._s(_vm.productDetail.deviceName) + " ")
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Business Type:")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.productDetail.oilType == 2
                              ? "CBD/THC Concentrates"
                              : "Nicotine"
                          ) +
                          " "
                      )
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Product Type:")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.productDetail.type == 3
                              ? "Disposable"
                              : "Batteries"
                          ) +
                          " "
                      )
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Available Compatibility:")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.productDetail.availableCompatibilityId == 1
                              ? "OMNI NFC"
                              : "OMNI Hub"
                          ) +
                          " "
                      )
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Available Tank Size:")]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.productDetail.taskCapacityValue) + " "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "productDetailContent" }, [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          color: "#3eb1c8",
                          "font-weight": "700",
                          visibility: "hidden"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.productDetail.productSeriesName) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "13px",
                          color: "#aaaaaa",
                          "font-family": "'Arial Normal', 'Arial', sans-serif",
                          visibility: "hidden"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.productDetail.remarks) + " ")]
                    ),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Brand:")]
                      ),
                      _vm._v(" " + _vm._s(_vm.productDetail.brandName) + " ")
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Brand Product Model #:")]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.productDetail.brandProductName) + " "
                      )
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Brand Marketing Name:")]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.productDetail.brandMarketingName) + " "
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }