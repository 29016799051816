var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MaskView",
    {
      attrs: {
        borderStyle: "border: 1px solid #d7d7d7",
        outerBorderStyle: "1px dashed #02a7f0",
        "background-color":
          _vm.item.status === 1 ? "rgba(242, 242, 242, 1)" : ""
      },
      scopedSlots: _vm._u([
        {
          key: "top",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "vo-row align-items-center vo-gap-4 justify-content-end p-2"
                },
                [
                  _vm.item.status == 0
                    ? _c("img", {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "brandMgmt::Create",
                            expression: "'brandMgmt::Create'"
                          }
                        ],
                        staticClass: "cursor",
                        attrs: { src: require("@/assets/enable.svg") },
                        on: {
                          click: function($event) {
                            return _vm.$emit("clickStatus")
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.item.status == 1
                    ? _c("img", {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "brandMgmt::Create",
                            expression: "'brandMgmt::Create'"
                          }
                        ],
                        staticClass: "cursor",
                        attrs: { src: require("@/assets/disable.svg") },
                        on: {
                          click: function($event) {
                            return _vm.$emit("clickStatus")
                          }
                        }
                      })
                    : _vm._e(),
                  _c("img", {
                    directives: [
                      {
                        name: "has",
                        rawName: "v-has",
                        value: "brandMgmt::Create",
                        expression: "'brandMgmt::Create'"
                      }
                    ],
                    staticClass: "cursor",
                    attrs: { src: require("@/assets/edit.svg") },
                    on: {
                      click: function($event) {
                        return _vm.$emit("clickEdit")
                      }
                    }
                  }),
                  _c("img", {
                    staticClass: "cursor",
                    attrs: { src: require("@/assets/view.svg") },
                    on: {
                      click: function($event) {
                        return _vm.$emit("clickDetail")
                      }
                    }
                  })
                ]
              )
            ]
          },
          proxy: true
        },
        {
          key: "bottom",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "vo-row align-items-center justify-content-around flex-wrap mb-1 ml-1 mr-1",
                  staticStyle: { gap: "10px" }
                },
                [
                  _vm.btnMenu("brandProductMgmt::FlavorBtn") ||
                  (!_vm.isAccountLevel() && _vm.btnMenu("brandMgmt::Create"))
                    ? _c(
                        "b-button",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { variant: "info" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("clickFlavor")
                            }
                          }
                        },
                        [_vm._v("Flavor ")]
                      )
                    : _vm._e(),
                  _vm.btnMenu("brandProductMgmt::CurvesBtn") ||
                  (!_vm.isAccountLevel() && _vm.btnMenu("brandMgmt::Create"))
                    ? _c(
                        "b-button",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { variant: "info" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("clickCurves")
                            }
                          }
                        },
                        [_vm._v("Curves ")]
                      )
                    : _vm._e(),
                  _vm.btnMenu("brandProductMgmt::BatchBtn") ||
                  (!_vm.isAccountLevel() && _vm.btnMenu("brandMgmt::Create"))
                    ? _c(
                        "b-button",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { variant: "info" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("clickBatch")
                            }
                          }
                        },
                        [_vm._v("Batch ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "position-relative w-100 h-100" }, [
        _c("div", { staticClass: "position-absolute img-wrap" }, [
          _c("img", {
            staticStyle: {
              width: "90%",
              height: "90%",
              "object-fit": "contain"
            },
            attrs: { src: _vm.item.deviceImage }
          }),
          _c("p", { staticClass: "productName" }, [
            _vm._v(_vm._s(_vm.item.modelName))
          ])
        ]),
        _c(
          "div",
          { staticClass: "status position-absolute" },
          [
            _vm.item.status == 1
              ? _c(
                  "b-badge",
                  {
                    staticClass: "custom-badge",
                    attrs: { href: "#", variant: _vm.statusVariant }
                  },
                  [_vm._v(_vm._s(_vm.statusText))]
                )
              : _vm._e(),
            _c("el-image", {
              staticStyle: {
                width: "60px",
                "object-fit": "contain",
                "padding-top": "10px"
              },
              attrs: { fit: "contain", href: "#", src: _vm.item.brandImage }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }