/*
 * @Author: Code-HHX
 * @Date: 2021-12-10 11:07:33
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-27 15:53:42
 * @Description:
 */
import { format } from './time'
import axios from '../config/http.js'
import axios1 from '../config/http-json'
import baseUrl from '../config/env.js'

// status
// 0是未使用1是使用中、2是未激活
async function list(params, ctx) {
	// let activeTime=format(params.activeTime)
	params.startTime = format(params.startTime)
	params.endTime = format(params.endTime)
	return axios.fetchGet(baseUrl + '/device/list', params)
}

async function find_usage(params, ctx) {
	return axios.fetchGet(
		baseUrl + '/device/deviceUsageInformationList',
		params
	)
}

async function find_record_usage(params, ctx) {
	return axios.fetchGet(
		baseUrl + '/device/selectDeviceOperationRecordById',
		params
	)
}

function add(params) {
	return axios.fetchPost(baseUrl + '/device/add', params)
}

function addBatch(params) {
	return axios.fileUpload(baseUrl + '/device/addBatch', params)
}

function find(params) {
	return axios.fetchGet(baseUrl + '/device/findById', params)
}

function update(params) {
	return axios.fetchPost(baseUrl + '/device/update', params)
}

function del(params) {
	return axios.fetchPost(baseUrl + '/device/updateById', params)
}

function deviceStagingList(params) {
	return axios.fetchGet(baseUrl + '/deviceStaging/list', params)
}
function selectByDeviceId(params) {
	return axios.fetchGet(baseUrl + '/deviceStaging/selectByDeviceId', params)
}
function selectDeviceLogData(params, ctx) {
	return axios.fetchGet(
		baseUrl + '/deviceStaging/selectDeviceLogData',
		params
	)
}
function selectRenewalMethodDetails(params) {
	return axios.fetchPost(
		baseUrl + '/deviceStaging/selectRenewalMethodDetails',
		params
	)
}
function saveRenewalMethod(params) {
	return axios.fetchPost(baseUrl + '/deviceStaging/saveRenewalMethod', params)
}
function saveValiditySetting(params) {
	return axios.fetchPost(
		baseUrl + '/deviceStaging/saveValiditySetting',
		params
	)
}

//查询设备型号列表
function selectDeviceModelList(params = {}) {
	return axios1.fetchGet(baseUrl + '/deviceModel/list', params)
}

//设备型号关联加热模式
function associatedHeatingMode(params = {}) {
	return axios1.fetchPost(
		baseUrl + '/deviceModel/associatedHeatingMode',
		params
	)
}

export default {
	list,
	find_usage,
	find_record_usage,
	find,
	add,
	addBatch,
	update,
	del,
	deviceStagingList,
	selectByDeviceId,
	selectDeviceLogData,
	selectRenewalMethodDetails,
	saveRenewalMethod,
	saveValiditySetting,
	selectDeviceModelList,
	associatedHeatingMode,
}
