/*
 * @Author: Code-HHX
 * @Date: 2022-04-25 14:59:35
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-27 11:06:58
 * @Description:
 */
import { format } from './time'
import axios from '../config/http.js'
import axios1 from '../config/http-json.js'
import baseUrl from '../config/env.js'

//查询客户账户列表
async function selectClientList(params = {}) {
	return axios.fetchGet(baseUrl + '/clientAccount/list', params)
}

//审核账户
async function auditClientAccount(params = {}) {
	return axios1.fetchPost(
		baseUrl + '/clientAccount/auditClientAccount',
		params
	)
}

//启用/禁用账户
async function enableOrProhibit(params = {}) {
	return axios.fetchPost(baseUrl + '/clientAccount/enableOrProhibit', params)
}

//添加账户
async function addClientAccount(params = {}) {
	return axios1.fetchPost(baseUrl + '/clientAccount/addClientAccount', params)
}

//修改账户
async function updateClientAccount(params = {}) {
	return axios1.fetchPost(
		baseUrl + '/clientAccount/updateClientAccount',
		params
	)
}

//查询详情
async function findById(params = {}) {
	return axios.fetchGet(baseUrl + '/clientAccount/findById', params)
}

//修改客户账号密码
async function changePasswordById(params = {}) {
	return axios.fetchPost(
		baseUrl + '/clientAccount/changePasswordById',
		params
	)
}

//查询客户操作记录
async function selectClientOperationRecord(params = {}) {
	return axios1.fetchGet(
		baseUrl + '/clientAccount/selectClientOperationRecord',
		params
	)
}

//查询固件设置记录
async function selectFirmwareSettingRecordList(params = {}) {
	return axios1.fetchGet(
		baseUrl + '/firmwareSettingRecord/selectFirmwareSettingRecordList',
		params
	)
}

//查询固件设置记录
async function selectScanRecord(params = {}) {
	return axios1.fetchGet(
		baseUrl + '/firmwareSettingRecord/selectScanRecord',
		params
	)
}
function getClientDetails(params) {
	return axios.fetchGet(baseUrl + '/clientAccount/getClientDetails', params)
}
//收藏曲线记录
function favoriteCurveHistory(params) {
	return axios.fetchPost(baseUrl + '/firmwareSettingRecord/favoriteCurveHistory', params)
}
//查询曲线组
function selectCurveGroupList(params) {
	return axios.fetchGet(baseUrl + '/heatingMode/selectCurveGroupList', params)
}
//添加曲线组
async function saveCurveGroup(params = {}) {
	return axios1.fetchPost(
		baseUrl + '/heatingMode/saveCurveGroup',
		params
	)
}
//修改曲线组
async function updateCurveGroup(params = {}) {
	return axios1.fetchPost(
		baseUrl + '/heatingMode/updateCurveGroup',
		params
	)
}
//查询曲线组详情
function selectCurveGroupById(params) {
	return axios.fetchGet(baseUrl + '/heatingMode/selectCurveGroupById', params)
}




export default {
	selectClientList,
	auditClientAccount,
	enableOrProhibit,
	addClientAccount,
	findById,
	changePasswordById,
	updateClientAccount,
	selectClientOperationRecord,
	selectFirmwareSettingRecordList,
	selectScanRecord,
	getClientDetails,
	favoriteCurveHistory,
	selectCurveGroupList,
	saveCurveGroup,
	updateCurveGroup,
	selectCurveGroupById
}
