import {format,now} from "./time"
import qs from "qs"
import axios from "../config/http.js"
import axios1 from "../config/http-json.js"
import baseUrl from "../config/env.js"


async function list(params,ctx){
    params.startTime=format(params.startTime)
    params.endTime=format(params.endTime)
    return axios.fetchGet(baseUrl+"/batchNumber/list",params)
}

function find(params){
   return axios.fetchGet(baseUrl + '/batchNumber/selectByNumberId',params)
}
// 查询批次检测报告信息以及烟油信息1.5版本
function selectReportInformation(params){
   return axios.fetchGet(baseUrl + '/batchNumber/selectReportInformation',params)
}
// 完善批次检测报告信息1.5版本
function perfectBatchInformation(params){
   return axios1.fetchPost(baseUrl + '/batchNumber/perfectBatchInformation',params)
}

function add(params){
    return axios.fetchPost(baseUrl + '/batchNumber/add', params);
}

function addBatch(params){
    return axios.fileUpload(baseUrl + '/batchNumber/addBatch', params);
}

function update(params){
    return axios.fetchPost(baseUrl + '/batchNumber/updateById', params);
}

function del(params){
    return axios.fetchPost(baseUrl + '/batchNumber/deleteById', params);
}

function export_excel(params){
    let data={
          'supplier-token':localStorage.getItem("auth-token"),
           id:params.id,
    }
    let url=baseUrl+"/batchNumber/exportSmokeRecordModel"+"?"+qs.stringify(data)
    location.href=url
}

async function getBatchNumber(params={}){
    axios.fetchGet(baseUrl + '/batchNumber/getBatchNumber',params)
    return {
        success:true,
        data:now(),
    }

}

//清除烟油批次
function selectAllClearOrder(params){
    return axios.fetchGet(baseUrl + '/clearOrder/selectAllClearOrder', params);
}
function clearOrderSave(params){
    return axios.fetchPost(baseUrl + '/clearOrder/save', params);
}
function clearOrderInfo(params){
    return axios.fetchGet(baseUrl + '/clearOrder/selectById', params);
}

// 添加认证类型
function addReportParameters(params){
    return axios.fetchPost(baseUrl + '/reportParameters/add', params);
}

export default {
    addBatch,
    list,
    find,
    add,
    update,
    del,
    export_excel,
    getBatchNumber,
    selectAllClearOrder,
    clearOrderSave,
    clearOrderInfo,
    selectReportInformation,
    perfectBatchInformation,
    addReportParameters
}
