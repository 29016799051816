var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MaskView",
    {
      attrs: {
        borderStyle: "border: 1px solid #d7d7d7",
        outerBorderStyle: "1px dashed #02a7f0",
        "background-color":
          _vm.item.status !== 1 || _vm.item.enableStatus == 1 ? "#EEE" : ""
      },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "top",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "vo-row align-items-center vo-gap-4 justify-content-end p-2"
                },
                [
                  _vm.item.enableStatus === 0 && _vm.item.status == 1
                    ? _c("el-image", {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "brandMgmt::Disable",
                            expression: "'brandMgmt::Disable'"
                          }
                        ],
                        staticClass: "cursor",
                        attrs: { src: require("@/assets/enable.svg") },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("onEnableOrDisableClick")
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.item.enableStatus === 1 && _vm.item.status == 1
                    ? _c("el-image", {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "brandMgmt::Disable",
                            expression: "'brandMgmt::Disable'"
                          }
                        ],
                        staticClass: "cursor",
                        attrs: { src: require("@/assets/disable.svg") },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("onEnableOrDisableClick")
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.item.status === 0
                    ? _c("el-image", {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "brandMgmt::Review",
                            expression: "'brandMgmt::Review'"
                          }
                        ],
                        staticClass: "cursor",
                        attrs: { src: require("@/assets/review.svg") },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("onClickReview")
                          }
                        }
                      })
                    : _vm._e(),
                  _c("el-image", {
                    directives: [
                      {
                        name: "has",
                        rawName: "v-has",
                        value: "brandMgmt::Modify",
                        expression: "'brandMgmt::Modify'"
                      }
                    ],
                    staticClass: "cursor",
                    attrs: { src: require("@/assets/edit.svg") },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("onEditClick")
                      }
                    }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "bottom",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "vo-row align-items-center justify-content-around flex-wrap mb-1 ml-1 mr-1",
                  staticStyle: { gap: "10px" }
                },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "brandMgmt::Sku",
                          expression: "'brandMgmt::Sku'"
                        }
                      ],
                      staticClass: "operate-btn",
                      staticStyle: { flex: "1" },
                      attrs: { variant: "primary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("onSkuClick")
                        }
                      }
                    },
                    [_vm._v("Sku ")]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "brandMgmt::User",
                          expression: "'brandMgmt::User'"
                        }
                      ],
                      staticClass: "operate-btn",
                      staticStyle: { flex: "1" },
                      attrs: { variant: "primary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("onUserClick")
                        }
                      }
                    },
                    [_vm._v("User ")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        {
          staticClass:
            "vo-column align-items-center position-relative w-100 h-100"
        },
        [
          _c("div", { staticClass: "position-absolute img-wrap" }, [
            _c("img", {
              staticStyle: {
                width: "90%",
                height: "90%",
                "object-fit": "contain"
              },
              attrs: { src: _vm.item.brandPicture }
            })
          ]),
          _c(
            "div",
            { staticClass: "status position-absolute" },
            [
              _vm.item.status !== 1 || _vm.item.enableStatus == 1
                ? _c(
                    "b-badge",
                    {
                      staticClass: "custom-badge",
                      attrs: { href: "#", variant: _vm.statusVariant }
                    },
                    [_vm._v(_vm._s(_vm.statusText) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }