var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Layout", [
    _c(
      "div",
      { staticClass: "page", attrs: { id: "fullScreenpage" } },
      [
        _c("div", { staticClass: "title" }, [
          _c("p", { staticClass: "headline" }, [_vm._v("OMNI DATA CENTER")]),
          !_vm.btnText
            ? _c(
                "i",
                {
                  staticClass: "mdi mdi-fullscreen fullscreen",
                  on: { click: _vm.requestFullScreen }
                },
                [_vm._v("Full Screen")]
              )
            : _c(
                "i",
                {
                  staticClass: "mdi mdi-fullscreen fullscreen",
                  on: { click: _vm.exitFullscreen }
                },
                [_vm._v("Exit Full Screen")]
              )
        ]),
        _c(
          "b-row",
          { staticStyle: { padding: "0 1vh" } },
          [
            _c(
              "b-col",
              { staticStyle: { height: "100%" }, attrs: { xl: "3" } },
              [
                _c("div", {
                  staticClass: "bodyCard",
                  staticStyle: {
                    width: "100%",
                    height: "calc(26vh)",
                    "margin-bottom": "1.2vh"
                  },
                  attrs: { id: "frequencyStatisticsToCurve" }
                }),
                _c("div", {
                  staticClass: "bodyCard",
                  staticStyle: { width: "100%", height: "calc(26vh)" },
                  attrs: { id: "brandStatistics" }
                })
              ]
            ),
            _c("b-col", { attrs: { xl: "6" } }, [
              _c(
                "div",
                { staticClass: "bodyCard", staticStyle: { height: "100%" } },
                [
                  _c("div", {
                    staticStyle: { width: "100%", height: "calc(40vh)" },
                    attrs: { id: "usaMap" }
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "calc(0.9vh)",
                        "border-top": "1px solid rgba(44, 50, 77, 1)"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "col-md-4",
                          staticStyle: {
                            display: "inline-block",
                            "text-align": "center",
                            "border-right": "1px solid rgba(44, 50, 77, 1)"
                          }
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "3.4vh",
                                color: "#fff",
                                "margin-bottom": "0"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.total) + " ")]
                          ),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "1.6vh",
                                color: "#999"
                              }
                            },
                            [_vm._v("Total Product")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-md-4",
                          staticStyle: {
                            display: "inline-block",
                            "text-align": "center",
                            "border-right": "1px solid rgba(44, 50, 77, 1)"
                          }
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "3.4vh",
                                color: "#fff",
                                "margin-bottom": "0"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.activationTotal) + " ")]
                          ),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "1.6vh",
                                color: "#999"
                              }
                            },
                            [_vm._v("Activated Total")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-md-4",
                          staticStyle: {
                            display: "inline-block",
                            "text-align": "center"
                          }
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "3.4vh",
                                color: "#fff",
                                "margin-bottom": "0"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.activationToday) + " ")]
                          ),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "1.6vh",
                                color: "#999"
                              }
                            },
                            [_vm._v("Activated Today")]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]),
            _c("b-col", { attrs: { xl: "3" } }, [
              _c(
                "div",
                { staticClass: "bodyCard", staticStyle: { height: "100%" } },
                [
                  _c("div", {
                    staticStyle: { width: "100%", height: "calc(32vh)" },
                    attrs: { id: "consumptionOne" }
                  }),
                  _c("span", {
                    staticStyle: {
                      display: "inline-block",
                      width: "50%",
                      height: "calc(18vh)"
                    },
                    attrs: { id: "consumptionTwo" }
                  }),
                  _c("span", {
                    staticStyle: {
                      display: "inline-block",
                      width: "50%",
                      height: "calc(18vh)"
                    },
                    attrs: { id: "consumptionThree" }
                  })
                ]
              )
            ])
          ],
          1
        ),
        _c(
          "b-row",
          { staticStyle: { padding: "1vh" } },
          [
            _c("b-col", { attrs: { xl: "3" } }, [
              _c("div", {
                staticClass: "bodyCard",
                staticStyle: {
                  width: "100%",
                  height: "calc(34vh)",
                  "margin-bottom": "1.2vh"
                },
                attrs: { id: "flavorStatistics" }
              })
            ]),
            _c("b-col", { attrs: { xl: "6" } }, [
              _c(
                "div",
                {
                  staticClass: "bodyCard",
                  staticStyle: {
                    width: "100%",
                    height: "calc(34vh)",
                    overflow: "hidden"
                  }
                },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        color: "#fff",
                        margin: "10px 0 0 5px",
                        "font-size": "14px",
                        "font-family": "sans-serif"
                      }
                    },
                    [_vm._v(" User's Activities ")]
                  ),
                  _vm.spinnerShow
                    ? _c(
                        "div",
                        {
                          staticClass: "text-center",
                          staticStyle: { height: "100%" }
                        },
                        [
                          _c("b-spinner", {
                            staticStyle: { margin: "10% auto" },
                            attrs: {
                              variant: "primary",
                              label: "Text Centered"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.userList, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.userId,
                        style:
                          "margin:0.8vh;line-height: 5vh;" +
                          { marginTop: _vm.marginTop + "vh" }
                      },
                      [
                        _c("span", { staticStyle: { color: "#fff" } }, [
                          item.userPicture
                            ? _c("img", {
                                staticClass: "avatar-xs rounded-circle mr-2",
                                attrs: { src: "" + item.userPicture, alt: "" }
                              })
                            : _c("img", {
                                staticClass: "avatar-xs rounded-circle mr-2",
                                attrs: {
                                  src: require("../../assets/face.jpg"),
                                  alt: ""
                                }
                              }),
                          _vm._v(_vm._s(item.nickName))
                        ]),
                        _c(
                          "b-badge",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: { pill: "", variant: "" + item.color }
                          },
                          [_vm._v(_vm._s(item.flavorName))]
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              float: "right",
                              "line-height": "38px",
                              "margin-right": "20%",
                              color: "#999"
                            }
                          },
                          [_vm._v(_vm._s(item.activeTime))]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ]),
            _c("b-col", { attrs: { xl: "3" } }, [
              _c("div", {
                staticClass: "bodyCard",
                staticStyle: { width: "100%", height: "calc(34vh)" },
                attrs: { id: "curvesStatistics" }
              })
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }