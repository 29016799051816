<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />
    <b-form action="#" @submit.prevent="typeForm" @reset="reset_form">
      <b-card>
        <b-row>
          <b-col cols="10">
            <!-- 标题 -->
            <FormRow>
              <b-form-group
                label="Blog Title:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="blog-title"
                label-class="requiredRow"
              >
                <b-form-input
                  id="blog-title"
                  v-model="typeform.title"
                  placeholder="Please enter"
                  trim
                  required
                  maxlength="100"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                >
                </b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="typeform.title ? typeform.title.length : 0"
                  :max="100"
                />
              </template>
            </FormRow>
            <!-- 关键词 -->
            <FormRow>
              <b-form-group
                label="Blog Keywords:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="blog-keywords"
                label-class="requiredRow"
              >
                <b-form-input
                  id="blog-keywords"
                  v-model="typeform.keywords"
                  placeholder="Please enter"
                  trim
                  required
                  maxlength="100"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                >
                </b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="typeform.keywords ? typeform.keywords.length : 0"
                  :max="100"
                />
              </template>
            </FormRow>

            <!-- 描述 -->
            <FormRow>
              <b-form-group
                label="Blog Description:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="blog-description"
                label-class="requiredRow"
              >
                <b-form-input
                  id="blog-description"
                  v-model="typeform.description"
                  placeholder="Please enter"
                  trim
                  required
                  maxlength="2000"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                >
                </b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="typeform.description ? typeform.description.length : 0"
                  :max="2000"
                />
              </template>
            </FormRow>
            <!-- 关联类型 -->
            <FormRow>
              <b-form-group
                label="Associated Types:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="Associated Types"
                label-class="requiredRow"
              >
                <b-form-radio
                  class="custom-control-inline pt-2"
                  v-model="typeform.relevanceBrandType"
                  value="1"
                  required
                  >All Brand
                </b-form-radio>
                <b-form-radio
                  class="custom-control-inline pt-2"
                  v-model="typeform.relevanceBrandType"
                  value="2"
                  required
                  >Designated Brand
                </b-form-radio>
              </b-form-group>
            </FormRow>

            <!-- 品牌 -->
            <FormRow v-if="typeform.relevanceBrandType == 2">
              <b-form-group
                label="Belong To Brand:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="blog-description"
                label-class="requiredRow"
              >
                <v-select
                  v-model="typeform.brandId"
                  :options="types.brandOptions"
                  label="text"
                  :reduce="(option) => option.value"
                  placeholder="--All Brand--"
                  multiple
                  class="custom-placeholder"
                >
                </v-select>
              </b-form-group>
            </FormRow>

            <FormRow>
              <b-form-group
                label="Business Type:"
                ref="businessType"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="company-business"
                label-class="requiredRow"
              >
                <div class="d-flex flex-wrap">
                  <b-form-checkbox
                    v-for="(label, index) in types.companyBusiness"
                    :required="true"
                    :key="index"
                    v-model="typeform.businessTypeId"
                    :value="label.value"
                    class="mr-2 mt-2"
                  >
                    {{ label.text }}
                  </b-form-checkbox>
                </div>
              </b-form-group>
            </FormRow>

            <!-- 富文本 -->
            <b-form-group
              label="Blog text:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="pushContent"
              label-class="requiredRow"
            >
              <div
                id="wangeEditor"
                style="max-width: 1260px; position: relative; z-index: 1"
              ></div>
            </b-form-group>

            <FormRow>
              <!-- 图片 -->
              <b-form-group
                label="Cover Picture:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="brand-logo"
                label-class="requiredRow"
              >
                <input
                  type="file"
                  class="filestyle"
                  data-input="false"
                  id="filestyle-1"
                  style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
                  tabindex="-1"
                  @change="upload($event)"
                  :required="!typeform.coverImage"
                  oninvalid="setCustomValidity('Please upload');"
                  accept="image/*"
                  oninput="setCustomValidity('');"
                />

                <div class="bootstrap-filestyle input-group">
                  <span class="group-span-filestyle" tabindex="0">
                    <div v-if="typeform.coverImage">
                      <div class="upload-img-wrap">
                        <img :src="typeform.coverImage" class="upload-img" />
                        <img
                          @click="onRemoveBrandLogo"
                          :src="require('@/assets/images/brand/ic_close.png')"
                          class="close-img"
                        />
                      </div>
                    </div>
                    <b-button
                      variant="primary"
                      class="custom-width"
                      @click="uploadFile()"
                      >upload picture</b-button
                    >
                    <span>
                      Recommended size of picture: 120 pixels * 120 pixels,
                      supporting JPG and PNG formats.
                    </span>
                  </span>
                </div>
              </b-form-group>
            </FormRow>
            <!-- 状态 -->
            <FormRow>
              <b-form-group
                label="Blog Status:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="Blog Status"
                label-class="requiredRow"
              >
                <b-form-radio
                  class="custom-control-inline pt-2"
                  v-model="typeform.startStatus"
                  value="1"
                  required
                  >Enable
                </b-form-radio>
                <b-form-radio
                  class="custom-control-inline pt-2"
                  v-model="typeform.startStatus"
                  value="0"
                  required
                  >Disable
                </b-form-radio>
              </b-form-group>
            </FormRow>
          </b-col>
        </b-row>
      </b-card>
      <b-form-group label-cols-lg="3">
        <b-button type="submit" variant="primary" class="custom-width"
          >Save</b-button
        >
        <b-button type="reset" class="ml-4 custom-width">Cancel </b-button>
      </b-form-group>
    </b-form>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel"
      @hide="hide"
    >
      Saved successfully
    </Dialog>
    <!-- 错误提示框 -->
    <Dialog
      v-model="errorAlear"
      :imageSrc="require('@/assets/confirm.svg')"
      noBtn="OK"
      @cancel="cancel1"
      @hide="hide1"
    >
      {{ errorContent }}
    </Dialog>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import baseUrl from "@/config/env.js";
import { upload_img } from "@/api/upload";
import vue2Dropzone from "vue2-dropzone";
import { mapGetters } from "vuex";

import { minLength, required } from "vuelidate/lib/validators";
import FormRow from "@/components/FormRow.vue";
import CountText from "@/components/CountText.vue";
import OperationLog from "@/components/operation/OperationLog.vue";
import Dialog from "@/components/Dialog";
import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import E from "wangeditor";
const editor = new E("#wangeEditor");

export default {
  components: {
    OperationLog,
    CountText,
    FormRow,
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,
    Dialog,
    VSelect,
    ckeditor: CKEditor.component,
  },

  data() {
    return {
      page: {
        title: this.$route.query.id ? "Modify Blog" : "Create Blog",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "App Settings",
            // href: "/"
          },
          {
            text: "Blog Mgmt",
            // href: "/"
          },
          {
            text: "Blog Mgmt",
            active: true,
          },
        ],
      },
      typeform: {
        title: "",
        keywords: "",
        description: "",
        brandId: [],
        businessTypeId: [],
        blogText: "",
        coverImage: "",
        startStatus: 1,
        relevanceBrandType: 1,
      },
      submit: false,
      typesubmit: false,
      types: {
        companyBusiness: [
          { text: "CBD/THC Concentrates", value: 2 },
          { text: "Nicotine", value: 1 },
        ],
        brandOptions: [],
      },
      successAlear: false,
      errorAlear: false,
      errorContent: "",
      editor: ClassicEditor,
    };
  },
  validations: {
    typeform: {
      coverImage: { required, minLength: minLength(1) },
    },
  },
  computed: {
    ...mapGetters(["isIkrusher", "isUnicoreus"]),
  },
  mounted() {
    this.init();
    // 选择语言
    editor.customConfig.lang = {
      设置标题: "title",
      正文: "p",
      链接文字: "link text",
      链接: "link",
      插入: "insert ",
      视频: "video",
      创建: "init",
      图片链接: "pictures linking",
      网络: "internet",
      上传: "upload ",
      图片: "picture",
      默认: "default",
      代码: "code",
      格式如: "format",
      新浪: "sina",
      字号: "Font size",
      字体: "Font",
      设置列表: "Settings list",
      有序列表: "Ordered list",
      无序列表: "Unordered list",
      插入: "insert ",
      插入表格: "insert table",
      编辑: "edit ",
      最大宽度: "maximum width",
      删除: "delete ",
      行: "row",
      列: "column",
      的: " ",
      表格: "table",
      上传图片: "Upload picture",
      网络图片: "Network picture",
      插入表格: "Insert table",
      插入视频: "Insert video",
      插入代码: "Insert code",
      文字颜色: "Text color",
      背景色: "Background color",
      对齐方式: "Alignment",
      靠左: "left",
      居中: "Center",
      靠右: "right",
      // 还可自定添加更多
    };

    editor.customConfig.uploadImgServer = baseUrl + "/supplier/uploadFile";
    editor.customConfig.uploadImgHeaders = {
      "supplier-token": window.localStorage.getItem("auth-token"),
    };
    editor.customConfig.uploadFileName = "file";

    editor.customConfig.uploadImgHooks = {
      // 上传图片之前
      before: function (xhr) {
        console.log(xhr);

        // 可阻止图片上传
        // return {
        //   prevent: true,
        //   msg: "需要提示给用户的错误信息",
        // };
      },
      // 图片上传并返回了结果，图片插入已成功
      success: function (xhr) {
        console.log("success", xhr);
      },
      // 图片上传并返回了结果，但图片插入时出错了
      fail: function (xhr, editor, resData) {
        console.log("fail", resData);
      },
      // 上传图片出错，一般为 http 请求的错误
      error: function (xhr, editor, resData) {
        console.log("error", xhr, resData);
      },
      // 上传图片超时
      timeout: function (xhr) {
        console.log("timeout");
      },
      // 图片上传并返回了结果，想要自己把图片插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
      customInsert: function (insertImgFn, result) {
        // result 即服务端返回的接口
        console.log("customInsert", result);

        // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
        result.data.forEach((element) => {
          insertImgFn(element.url);
        });
      },
    };

    editor.create();
  },
  methods: {
    onRemoveBrandLogo() {
      this.typeform.coverImage = "";
    },
    init() {
      if (this.$route.query.id) {
        this.selectBrandBlogById();
      }
      this.grtBrandList();
    },

    //查询所有品牌
    grtBrandList() {
      this.$api.Dropdown.brandList().then((x) => {
        if (x.success) {
          this.types.brandOptions = this.types.brandOptions.concat(
            x.data.map((item, i) => {
              return {
                text: item.brandName,
                value: item.id,
                disabled: false,
              };
            })
          );
        } else {
          if (x.message) {
            this.$toast(x);
          }
        }
      });
    },
    // 上传图片
    upload(e) {
      let file = e.target.files[0];
      this.photoName = file.name;
      console.log(file);
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          // if (res.message) {
          //   this.$toast(res);
          // }
          if (res.success) {
            this.typeform.coverImage = res.data;
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    // 添加博客
    add() {
      this.typeform.blogText = editor.txt.html();
      if (this.typeform.businessTypeId.length == 0) {
        this.errorAlear = true;
        this.errorContent = "The business type is required";
      } else {
        this.$api.brandBlog.saveBrandBlog(this.typeform).then((res) => {
          if (res.success) {
            this.successAlear = true;
          } else {
            if (res.message) {
              this.errorAlear = true;
              this.errorContent = res.message;
            }
          }
        });
      }
    },
    // 修改博客
    update() {
      if (this.typeform.businessTypeId.length == 0) {
        this.errorAlear = true;
        this.errorContent = "The business type is required";
      } else {
        this.$api.brandBlog.updateBrandBlog(this.typeform).then((res) => {
          if (res.success) {
            this.successAlear = true;
          } else {
            if (res.message) {
              this.errorAlear = true;
              this.errorContent = res.message;
            }
          }
        });
      }
    },
    // 查询博客详情
    selectBrandBlogById() {
      this.$api.brandBlog
        .selectBrandBlogById({ id: this.$route.query.id })
        .then((res) => {
          if (res.success) {
            this.typeform = {
              id: res.data.id,
              title: res.data.title,
              keywords: res.data.keywords,
              description: res.data.description,
              brandId: res.data.brandId,
              businessTypeId: res.data.businessTypeId,
              blogText: res.data.blogText,
              coverImage: res.data.coverImage,
              startStatus: res.data.startStatus,
              relevanceBrandType: res.data.relevanceBrandType,
            };
            editor.txt.html(res.data.blogText);
          }
        });
    },
    cancel() {
      this.$router.go(-1);
    },
    hide() {
      this.$router.go(-1);
    },
    cancel1() {
      this.errorAlear = false;
    },
    hide1() {
      this.errorAlear = false;
    },
    rangeform(e) {
      this.submit = true;
      this.$v.$touch();
    },
    submit_form() {
      this.typesubmit = true;
      this.$v.$touch();
      if (this.$route.query.id) {
        this.update();
      } else {
        this.add();
      }
    },
    typeForm(e) {
      if (this.$v.typeform.$invalid) {
        this.submitStatus = "ERROR";
        this.$bvToast.toast("Please upload pictures");
      } else {
        this.submit_form();
      }
    },
    reset_form() {
      this.$router.go(-1);
    },
    uploadFile() {
      const filestyle = document.getElementById("filestyle-1");
      filestyle.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.close-img {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  padding: 4px;
  background: #02a7f0;
  border-radius: 50%;
  object-fit: contain;
}

.upload-img-wrap {
  margin-bottom: 1em;
  width: 200px;
  height: 200px;
  position: relative;
  border: 2px dashed #02a7f0;
}
.custom-placeholder ::placeholder {
  font-size: 0.875rem;
  color: #495057;
}
</style>
