<template>
	<b-row class="align-items-center">
		<b-col lg="12">
			<div class="blockquote">
				<h4 class="font-size-18">{{ title }}</h4>
			</div>
		</b-col>
	</b-row>
</template>

<script>
	/**
	 *  blockquote component
	 */
	export default {
		components: {},
		props: {
			title: {
				type: String,
				default: ""
			}
		}
	}
</script>

<style>
	.blockquote {
		padding: 10px 10px;
		border-left: 5px solid #38a4f8;
		color: #333333;
	}
</style>
