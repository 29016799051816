var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vertical-menu" },
    [
      _c("simplebar", { staticClass: "h-100" }, [
        _c("div", { attrs: { id: "sidebar-menu" } }, [
          _vm.menu
            ? _c(
                "ul",
                {
                  ref: "sideMenu",
                  staticClass: "metismenu list-unstyled",
                  attrs: { id: "side-menu" }
                },
                _vm._l(_vm.menu, function(item) {
                  return _c(
                    "li",
                    { key: item.id },
                    [
                      !item.children
                        ? _c(
                            "router-link",
                            {
                              staticClass: "side-nav-link",
                              attrs: { tag: "a", to: item.href }
                            },
                            [
                              _c("i", { class: item.icon }),
                              _c("span", [_vm._v(_vm._s(item.title))])
                            ]
                          )
                        : _vm._e(),
                      item.children
                        ? _c(
                            "a",
                            {
                              staticClass: "has-arrow",
                              attrs: { href: "javascript: void(0);" }
                            },
                            [
                              _c("i", { class: item.icon }),
                              _c("span", [_vm._v(_vm._s(item.title))])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "ul",
                        {
                          staticClass: "sub-menu",
                          attrs: { "aria-expanded": "false" }
                        },
                        _vm._l(item.children, function(child) {
                          return _c(
                            "li",
                            { key: child.id },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "side-nav-link",
                                  attrs: { to: child.href }
                                },
                                [_vm._v(_vm._s(child.title) + " ")]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }