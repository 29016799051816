<!--
 * @Author: Code-HHX
 * @Date: 2022-03-29 13:55:48
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-01 16:31:29
 * @Description: 积分活动设置（宝石活动设置）
-->
<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<!-- main -->
		<b-row>
			<b-col lg="12">
				<!-- 积分总体详情 -->
				<b-card>
					<div class="integral">
						<!-- 总有效宝石 -->
						<div class="integral-item">
							Total effective integral<br />{{
								numberToCurrencyNo(totalData.totalGems)
							}}
						</div>
						<!-- 今日新增 -->
						<div class="integral-item">
							Increase Today<br />{{
								numberToCurrencyNo(totalData.addGemsToday)
							}}
						</div>
						<!-- 邀请新增 -->
						<div class="integral-item">
							Invitation Increase<br />{{
								numberToCurrencyNo(totalData.inviteAddGemsToday)
							}}
						</div>
						<!-- 激活新增 -->
						<div class="integral-item">
							Activation increase<br />{{
								numberToCurrencyNo(
									totalData.activateAddGemsToday
								)
							}}
						</div>
						<!-- 今日消耗 -->
						<div class="integral-item">
							Consumption Today<br />{{
								numberToCurrencyNo(totalData.consumeGemsToday)
							}}
						</div>
					</div>
				</b-card>
				<!-- 查询条件 -->
				<b-card>
					<b-row>
						<!-- Activity Name 活动名称 -->
						<b-col lg="3">
							<b-form-group
								label="Activity Name"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="activity-name"
							>
								<b-form-input
									id="activity-name"
									trim
									v-model="search_condition.activityName"
									oninput="if(value.length > 20)value = value.slice(0, 20)"
								></b-form-input>
							</b-form-group>
						</b-col>
						<!-- Activity Time 活动时间 -->
						<b-col lg="4">
							<b-form-group
								label="Activity Time"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="activity-time"
							>
								<date-picker
									v-model="selectTime"
									format="MM/DD/YYYY HH:mm:ss"
									range
									append-to-body
									lang="en"
									type="datetime"
									placeholder="Select date"
								></date-picker>
							</b-form-group>
						</b-col>

						<b-col lg="3">
							<!-- <b-form-group label label-cols-sm="4" label-align-sm="right"> -->
							<b-button variant="primary" @click="search()"
								>Search</b-button
							>
							<b-button
								style="margin-left:24px"
								v-has="'integralActivity::Create'"
								@click="create"
								variant="primary"
								>Create</b-button
							>
							<!-- </b-form-group> -->
						</b-col>
					</b-row>

					<b-row class="text-center">
						<b-col lg="12"></b-col>
					</b-row>
				</b-card>
				<!-- 查询内容 -->
				<b-card>
					<!-- table -->
					<div class="table-responsive mb-0">
						<b-table
							show-empty
							empty-text="No records"
							id="device-table"
							head-variant="light"
							:striped="true"
							ref="table"
							:busy="isBusy"
							:items="provider"
							:fields="fields"
							:per-page="search_condition.pageSize"
							:current-page="search_condition.pageNum"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:filter="filter"
							:filter-includedfields="filterOn"
							@filtered="onFiltered"
						>
							<template v-slot:table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>

							<template v-slot:cell(index)="data">{{
								data.index +
									(search_condition.pageNum - 1) *
										search_condition.pageSize +
									1
							}}</template>

							<template v-slot:cell(actions)="row">
								<b-button-group>
									<b-button
										v-has="'exchangeRecord::Details'"
										size="sm"
										variant="info"
										@click="
											details(row.item, $event.target)
										"
										class="mr-1"
									>
										Details
									</b-button>
								</b-button-group>
							</template>
						</b-table>
					</div>
					<!-- end table -->
					<!-- 卡片式表格 -->
					<div class="card-table">
						<div
							class="table-item"
							v-for="item in tableData"
							:key="item.id"
						>
							<div class="title">{{ item.activityName }}</div>
							<div class="number">
								Gem Received：{{ item.issuanceGemsQuantity }}
							</div>
							<div class="state">
								Status：{{ item.activityStatus }}
							</div>
							<div class="date">
								Activity Time：{{ item.startTime }} ~
								{{ item.endTime }}
							</div>
							<div
								v-has="'integralActivity::Details'"
								class="button-detail"
								@click="details(item, $event.target)"
							>
								Details
							</div>
						</div>
					</div>
					<!-- pagination -->
					<b-row v-if="has_data">
						<b-col>
							<div
								class="dataTables_paginate paging_simple_numbers float-right"
							>
								<ul class="pagination mb-0" v-if="rows != 0">
									<b-pagination
										v-model="search_condition.pageNum"
										:per-page="6"
										:total-rows="rows"
										aria-controls="table"
									></b-pagination>
								</ul>
							</div>
						</b-col>
					</b-row>
					<!-- end pagination -->
				</b-card>
			</b-col>
		</b-row>
		<!-- end main -->

		<!-- <div class="text-center">
			<b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>-->
	</Layout>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'
import { elementMatches } from '@fullcalendar/core'

/**
 * User component
 */
export default {
	components: {
		DatePicker,
		Layout,
		PageHeader,
		timeZoneConversion,
	},
	data() {
		return {
			title: 'Integral Activity',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'Marketing Mgmt',
					// href: "/"
				},
				{
					text: 'Integral Activity',
					active: true,
				},
			],

			search_condition: {
				pageNum: 1,
				pageSize: 6,
				activityName: '', //活动名称
				startTime: '', //开始时间
				endTime: '', //结束时间

				supplierId: localStorage.supplierId,
			},
			selectTime: ['', ''],

			types: {},

			fields: [
				{ key: 'index', label: '#', class: 'text-center' },
				{
					key: 'orderNumber', //订单号
					label: 'Order Number',
					class: 'text-center',
				},
				{
					key: 'exchangeType', //兑换方式
					label: 'Exchange Type',
					class: 'text-center',
				},
				{
					key: 'exchangeProduct', //兑换商品
					label: 'Exchange Product',
					class: 'text-center',
				},
				{
					key: 'consumptionIntegralNumber', //消耗积分数量
					label: 'Consumption Integral Number',
					class: 'text-center',
				},

				{
					key: 'createTime', //兑换时间
					label: 'Exchange Time',
					formatter: (value) => {
						return timeZoneConversion.getLocalTimes(value)
					},
					class: 'text-center',
				},
				{ key: 'actions', label: 'Opreration', class: 'text-center' },
			],

			totalData: {
				activateAddGemsToday: 0, //今日激活新增宝石
				addGemsToday: 0, //今日新增宝石
				consumeGemsToday: 0, //今日消耗宝石
				inviteAddGemsToday: 0, //今日邀请新增宝石
				totalGems: 0, //总宝石数
			},
			tableData: [],
			totalRows: 0,
			currentPage: 1,
			perPage: 6,
			pageOptions: [10, 25, 50, 100],
			filter: null,
			filterOn: [],
			sortBy: 'id',
			sortDesc: false,
			isBusy: false,
			has_data: false,
		}
	},
	watch: {
		selectTime: function(val) {
			this.search_condition.startTime = val[0]
				? timeZoneConversion.queryTime(val[0])
				: val[0]
			if (val[1]) {
				this.search_condition.endTime = timeZoneConversion.queryTime(
					val[1].getTime() + 24 * 3600 * 1000 - 1
				)
			} else {
				this.search_condition.endTime = val[1]
			}
		},
	},
	computed: {
		rows() {
			return this.totalRows
		},
	},
	created() {},
	mounted() {
		this.$api.Marketing.selectGemsDetails()
			.then((res) => {
				this.totalData = res.data
			})
			.catch((error) => {})
	},
	methods: {
		provider(ctx) {
			this.isBusy = true
			return this.$api.Marketing.selectGemsActivityList(
				this.search_condition,
				ctx
			)
				.then((x) => {
					this.isBusy = false
					if (x.total) {
						this.has_data = true
						this.totalRows = x.total
						this.tableData = x.data
						this.tableData.forEach((element) => {
							if (element.startTime) {
								element.startTime = timeZoneConversion.getLocalTime(
									element.startTime
								)
							}
							if (element.endTime) {
								element.endTime = timeZoneConversion.getLocalTime(
									element.endTime
								)
							}
						})
						return x.data
					} else {
						this.has_data = false
						this.tableData = []
						return []
					}
				})
				.catch((e) => {
					this.isBusy = false
					this.has_data = false
					this.tableData = []
					return []
				})
		},
		details(item, button) {
			this.$router.push('integralActivityInfo?id=' + item.id)
		},
		create(item, index, button) {
			this.$router.push('integralActivityAdd')
		},
		search() {
			this.search_condition.pageNum = 1
			this.$refs.table.refresh()
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		//千位分隔符格式
		numberToCurrencyNo(value) {
			if (!value) return 0
			// 获取整数部分
			const intPart = Math.trunc(value)
			// 整数部分处理，增加,
			const intPartFormat = intPart
				.toString()
				.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
			// 预定义小数部分
			let floatPart = ''
			// 将数值截取为小数部分和整数部分
			const valueArray = value.toString().split('.')
			if (valueArray.length === 2) {
				// 有小数部分
				floatPart = valueArray[1].toString() // 取得小数部分
				return intPartFormat + '.' + floatPart
			}
			return intPartFormat + floatPart
		},
	},
}
</script>
<style lang="scss" scoped>
.integral {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100px;
	border: 1px solid #ced4da;
	padding: 20px 0;
	border-radius: 0.25rem;

	.integral-item {
		width: 20%;
		text-align: center;
		line-height: 29px;
	}

	div:nth-child(-n + 4) {
		border-right: 1px solid #ced4da;
	}
}

.table-responsive {
	display: none;
}
.card-table {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.table-item {
		border: 1px solid #ced4da;
		width: 32%;
		height: 180px;
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;
		border-radius: 0.25rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		div:nth-child(-n + 4) {
			padding: 0 16px;
		}

		.title {
			font-size: 18px;
			font-weight: bold;
			margin-top: 10px;
			margin-bottom: 10px;
			white-space: nowrap;
		}

		.number {
		}

		.state {
			margin-top: 8px;
		}

		.date {
			margin-top: 8px;
		}
		.button-detail {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 8px;
			border-top: 1px solid #ced4cd;
			color: #626ed4;
		}
		.button-detail:hover {
			background-color: #626ed4;
			color: white;
			cursor: pointer;
		}
	}

	&:after {
		content: '';
		width: 32%;
	}
}
</style>
