import { format } from './time'
import axios from '../config/http-json'
import baseUrl from '../config/env.js'
import qs from 'qs'

//查询优惠券包列表
function selectCouponCode(params) {
	return axios.fetchGet(baseUrl + '/coupon/selectCouponCode', params)
}

//保存或修改优惠券
function saveOrUpdateCoupon(params, ctx) {
	return axios.fetchPost(baseUrl + '/coupon/saveOrUpdateCoupon', params)
}

//启用或禁用优惠券
function enableOrDisableById(params, ctx) {
	return axios.fetchPost(baseUrl + '/coupon/enableOrDisableById', params)
}

//查询优惠券详情
function selectCouponCodeById(params) {
	return axios.fetchGet(baseUrl + '/coupon/selectCouponCodeById', params)
}

//查询优惠券码列表
function selectCouponCodeList(params) {
	return axios.fetchGet(baseUrl + '/coupon/selectCouponCodeList', params)
}

//下载优惠券码列表
function downCouponCodeList(params) {
	let d = JSON.parse(JSON.stringify(params))
	d.id = params.id
	d['supplier-token'] = localStorage['auth-token']
	location.href =
		baseUrl + '/coupon/downCouponCodeList' + '?' + qs.stringify(d)
	// return axios.fetchGet(baseUrl + "/coupon/downCouponCodeList", params);
}

//查询分享设置列表
function selectSharingSettings(parmas) {
	return axios.fetchGet(
		baseUrl + '/sharingSettings/selectSharingSettings',
		parmas
	)
}

//启用或禁用分享设置
function shareEnableOrDisableById(params) {
	return axios.fetchPost(
		baseUrl + '/sharingSettings/enableOrDisableById',
		params
	)
}

//添加或修改分享设置
function saveOrUpdateSharingSettings(params) {
	return axios.fetchPost(
		baseUrl + '/sharingSettings/saveOrUpdateSharingSettings',
		params
	)
}

//查询分享设置详情
function selectSharingSettingsById(params) {
	return axios.fetchGet(
		baseUrl + '/sharingSettings/selectSharingSettingsById',
		params
	)
}

//查询分享奖励设置列表
function selectUserSharingRewardsList(params) {
	return axios.fetchGet(
		baseUrl + '/sharingSettings/selectUserSharingRewardsList',
		params
	)
}

//查询分享列表
function selectUserShareRecordsList(params) {
	return axios.fetchGet(
		baseUrl + '/sharingSettings/selectUserShareRecordsList',
		params
	)
}

//下载分享奖励设置列表
function downUserSharingRewardsList(params) {
	let d = JSON.parse(JSON.stringify(params))
	d.id = params.id
	d['supplier-token'] = localStorage['auth-token']
	location.href =
		baseUrl +
		'/sharingSettings/downUserSharingRewardsList' +
		'?' +
		qs.stringify(d)
	// return axios.fetchGet(baseUrl + "/coupon/downCouponCodeList", params);
}

//#region 积分兑换商品设置
//查询兑换商品列表
function selectExchangeSettingsList(params) {
	return axios.fetchGet(
		baseUrl + '/exchangeSettings/selectExchangeSettingsList',
		params
	)
}

//启动或禁用积分兑换设置
function integralEnableOrDisableById(params) {
	return axios.fetchPost(
		baseUrl + '/exchangeSettings/enableOrDisableById',
		params
	)
}

//查询兑换商品设置详情
function selectBySettingsId(params) {
	return axios.fetchGet(
		baseUrl + '/exchangeSettings/selectBySettingsId',
		params
	)
}

//添加或修改兑换商品设置
function saveOrUpdateExchangeSettings(params) {
	return axios.fetchPost(
		baseUrl + '/exchangeSettings/saveOrUpdateExchangeSettings',
		params
	)
}
//#endregion

//#region 积分（宝石）记录
//查询积分记录（宝石记录）
function selectUserIntegralRecordList(params) {
	return axios.fetchGet(
		baseUrl + '/userIntegral/selectUserIntegralRecordList',
		params
	)
}

//导出积分记录（宝石记录）
function exportUserIntegralRecordList(params) {
	let d = JSON.parse(JSON.stringify(params))
	d['supplier-token'] = localStorage['auth-token']
	location.href =
		baseUrl +
		'/userIntegral/exportUserIntegralRecordList?' +
		qs.stringify(d)
}
//#endregion

//#region 积分（宝石）兑换记录
//查询积分兑换记录（兑换宝石记录）
function selectUserExchangeRecord(params) {
	return axios.fetchGet(
		baseUrl + '/userIntegral/selectUserExchangeRecord',
		params
	)
}

//查询积分兑换详情
function selectUserExchangeRecordById(params) {
	return axios.fetchGet(
		baseUrl + '/userIntegral/selectUserExchangeRecordById',
		params
	)
}

//导出积分兑换记录（宝石兑换记录）
function exportUserExchangeRecord(params) {
	let d = JSON.parse(JSON.stringify(params))
	d['supplier-token'] = localStorage['auth-token']
	location.href =
		baseUrl + '/userIntegral/exportUserExchangeRecord?' + qs.stringify(d)
}
//#endregion

//#region 积分（宝石）或者设置
//查询积分（宝石）活动数据统计
function selectGemsDetails(params = {}) {
	return axios.fetchGet(baseUrl + '/gemActivity/selectGemsDetails', params)
}

//查询积分（宝石）活动设置列表
function selectGemsActivityList(params = {}) {
	return axios.fetchGet(
		baseUrl + '/gemActivity/selectGemsActivityList',
		params
	)
}

//查询积分（宝石）活动详情
function selectGemsActivityDetailsById(params = {}) {
	return axios.fetchGet(
		baseUrl + '/gemActivity/selectGemsActivityDetailsById',
		params
	)
}

//添加或修改积分（宝石）活动设置
function saveOrUpdateGemActivityVo(params = {}) {
	return axios.fetchPost(
		baseUrl + '/gemActivity/saveOrUpdateGemActivityVo',
		params
	)
}

//查询优惠券核销记录
function selectWriteOffRecords(params = {}) {
	return axios.fetchGet(
		baseUrl + '/writeOffRecords/selectWriteOffRecords',
		params
	)
}


//查询注册设置列表
function selectRegisterSettings(parmas) {
	return axios.fetchGet(
		baseUrl + '/registerSettings/selectRegisterSettings',
		parmas
	)
}

//启用或禁用注册设置
function registerEnableOrDisableById(params) {
	return axios.fetchPost(
		baseUrl + '/registerSettings/enableOrDisableById',
		params
	)
}

//添加或修改注册设置
function saveOrUpdateRegisterSettings(params) {
	return axios.fetchPost(
		baseUrl + '/registerSettings/saveOrUpdateRegisterSettings',
		params
	)
}

//查询注册设置详情
function selectRegisterSettingsById(params) {
	return axios.fetchGet(
		baseUrl + '/registerSettings/selectRegisterSettingsById',
		params
	)
}
//#endregion

export default {
	selectCouponCode,
	saveOrUpdateCoupon,
	enableOrDisableById,
	selectCouponCodeById,
	selectCouponCodeList,
	downCouponCodeList,
	selectSharingSettings,
	shareEnableOrDisableById,
	saveOrUpdateSharingSettings,
	selectSharingSettingsById,
	selectUserSharingRewardsList,
	selectUserShareRecordsList,
	downUserSharingRewardsList,
	selectExchangeSettingsList,
	integralEnableOrDisableById,
	selectBySettingsId,
	saveOrUpdateExchangeSettings,
	selectUserIntegralRecordList,
	exportUserIntegralRecordList,
	selectUserExchangeRecord,
	selectUserExchangeRecordById,
	exportUserExchangeRecord,
	selectGemsDetails,
	selectGemsActivityList,
	selectGemsActivityDetailsById,
	saveOrUpdateGemActivityVo,
	selectWriteOffRecords,
	selectRegisterSettings,
	registerEnableOrDisableById,
	saveOrUpdateRegisterSettings,
	selectRegisterSettingsById,
}
