var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { lg: "10" } },
                    [
                      _c(
                        "form-row",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-bottom": "1em"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.form.roleName.length) +
                                          "/20 "
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Role:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "role",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "role",
                                  placeholder: "Please enter",
                                  maxlength: "20",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                                },
                                model: {
                                  value: _vm.form.roleName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "roleName", $$v)
                                  },
                                  expression: "form.roleName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Role Status:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "deviceType",
                                "label-class": "requiredRow"
                              }
                            },
                            _vm._l(_vm.types.status, function(item, key) {
                              return _c(
                                "b-form-radio",
                                {
                                  key: key,
                                  staticClass: "custom-control-inline pt-2",
                                  attrs: {
                                    name: "deviceType-options" + key,
                                    value: item.value
                                  },
                                  model: {
                                    value: _vm.form.isDelete,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "isDelete", $$v)
                                    },
                                    expression: "form.isDelete"
                                  }
                                },
                                [_vm._v(_vm._s(item.text) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tabs",
                { attrs: { pills: "" } },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Function Permission Setting",
                        active: ""
                      }
                    },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c("b-table", {
                            attrs: {
                              items: _vm.form.navMenuList,
                              fields: _vm.fields,
                              "caption-top": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "cell(module)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          indeterminate:
                                            data.item.indeterminate,
                                          "aria-describedby": "permissions",
                                          "aria-controls": "permissions",
                                          disabled:
                                            (data.item.title === "Home" &&
                                              _vm.isUnicoreus) ||
                                            (data.item.title === "Dashboard" &&
                                              _vm.isIkrusher)
                                              ? true
                                              : false
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.chooseAll(
                                              !data.item.allSelected,
                                              data.item
                                            )
                                          }
                                        },
                                        model: {
                                          value: data.item.allSelected,
                                          callback: function($$v) {
                                            _vm.$set(
                                              data.item,
                                              "allSelected",
                                              $$v
                                            )
                                          },
                                          expression: "data.item.allSelected"
                                        }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(data.item.title))
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "cell(page)",
                                fn: function(data) {
                                  return _vm._l(data.item.children, function(
                                    i
                                  ) {
                                    return _c(
                                      "div",
                                      { key: i.id },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            attrs: {
                                              indeterminate: i.indeterminate,
                                              "aria-describedby": "permissions",
                                              "aria-controls": "permissions"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.toggleAll(
                                                  !i.allSelected,
                                                  i,
                                                  data.item
                                                )
                                              }
                                            },
                                            nativeOn: {
                                              change: function($event) {
                                                return _vm.disabledButton(
                                                  i.selected,
                                                  i.children,
                                                  i,
                                                  data.item
                                                )
                                              }
                                            },
                                            model: {
                                              value: i.allSelected,
                                              callback: function($$v) {
                                                _vm.$set(i, "allSelected", $$v)
                                              },
                                              expression: "i.allSelected"
                                            }
                                          },
                                          [_vm._v(" " + _vm._s(i.title) + " ")]
                                        )
                                      ],
                                      1
                                    )
                                  })
                                }
                              },
                              {
                                key: "cell(permissions)",
                                fn: function(data) {
                                  return _vm._l(data.item.children, function(
                                    i
                                  ) {
                                    return _c(
                                      "div",
                                      { key: i.id },
                                      [
                                        !i.children
                                          ? _c("div", [_c("br")])
                                          : _vm._e(),
                                        _c("b-form-checkbox-group", {
                                          staticClass: "ml-4",
                                          attrs: {
                                            options: i.children,
                                            "value-field": "id",
                                            "text-field": "title",
                                            disabled: i.disabled
                                          },
                                          nativeOn: {
                                            change: function($event) {
                                              return _vm.disabledButton(
                                                i.selected,
                                                i.children,
                                                i,
                                                data.item
                                              )
                                            }
                                          },
                                          model: {
                                            value: i.selected,
                                            callback: function($$v) {
                                              _vm.$set(i, "selected", $$v)
                                            },
                                            expression: "i.selected"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  })
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "App Function Permission Setting" } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c("b-table", {
                            attrs: {
                              items: _vm.form.appNavMenuList,
                              fields: _vm.fields,
                              "caption-top": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "cell(module)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          indeterminate:
                                            data.item.indeterminate,
                                          "aria-describedby": "permissions",
                                          "aria-controls": "permissions"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.chooseAll(
                                              !data.item.allSelected,
                                              data.item
                                            )
                                          }
                                        },
                                        model: {
                                          value: data.item.allSelected,
                                          callback: function($$v) {
                                            _vm.$set(
                                              data.item,
                                              "allSelected",
                                              $$v
                                            )
                                          },
                                          expression: "data.item.allSelected"
                                        }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(data.item.title))
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "cell(page)",
                                fn: function(data) {
                                  return _vm._l(data.item.children, function(
                                    i
                                  ) {
                                    return _c(
                                      "div",
                                      { key: i.id },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            attrs: {
                                              indeterminate: i.indeterminate,
                                              "aria-describedby": "permissions",
                                              "aria-controls": "permissions"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.toggleAll(
                                                  !i.allSelected,
                                                  i,
                                                  data.item
                                                )
                                              }
                                            },
                                            nativeOn: {
                                              change: function($event) {
                                                return _vm.disabledButton(
                                                  i.selected,
                                                  i.children,
                                                  i,
                                                  data.item
                                                )
                                              }
                                            },
                                            model: {
                                              value: i.allSelected,
                                              callback: function($$v) {
                                                _vm.$set(i, "allSelected", $$v)
                                              },
                                              expression: "i.allSelected"
                                            }
                                          },
                                          [_vm._v(" " + _vm._s(i.title) + " ")]
                                        )
                                      ],
                                      1
                                    )
                                  })
                                }
                              },
                              {
                                key: "cell(permissions)",
                                fn: function(data) {
                                  return _vm._l(data.item.children, function(
                                    i
                                  ) {
                                    return _c(
                                      "div",
                                      { key: i.id },
                                      [
                                        !i.children
                                          ? _c("div", [_c("br")])
                                          : _vm._e(),
                                        _c("b-form-checkbox-group", {
                                          staticClass: "ml-4",
                                          attrs: {
                                            options: i.children,
                                            "value-field": "id",
                                            "text-field": "title",
                                            disabled: i.disabled
                                          },
                                          nativeOn: {
                                            change: function($event) {
                                              return _vm.disabledButton(
                                                i.selected,
                                                i.children,
                                                i,
                                                data.item
                                              )
                                            }
                                          },
                                          model: {
                                            value: i.selected,
                                            callback: function($$v) {
                                              _vm.$set(i, "selected", $$v)
                                            },
                                            expression: "i.selected"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  })
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { "label-cols-lg": "3" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "custom-width",
                  attrs: { type: "submit", variant: "primary" }
                },
                [_vm._v("Save")]
              ),
              _c(
                "b-button",
                { staticClass: "ml-4 custom-width", attrs: { type: "reset" } },
                [_vm._v("Cancel")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/success.png"), noBtn: "OK" },
          on: { cancel: _vm.cancel, hide: _vm.hide },
          model: {
            value: _vm.successAlear,
            callback: function($$v) {
              _vm.successAlear = $$v
            },
            expression: "successAlear"
          }
        },
        [_vm._v(" Saved successfully ")]
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/confirm.svg"), noBtn: "OK" },
          on: { cancel: _vm.cancel1, hide: _vm.hide1 },
          model: {
            value: _vm.errorAlear,
            callback: function($$v) {
              _vm.errorAlear = $$v
            },
            expression: "errorAlear"
          }
        },
        [_vm._v(" " + _vm._s(_vm.errorContent) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }