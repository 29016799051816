var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container",
      style: _vm.containerStyles,
      on: {
        mouseenter: function($event) {
          _vm.showMenu = true
        },
        mouseleave: function($event) {
          _vm.showMenu = false
        }
      }
    },
    [
      _vm._t("default"),
      _vm.showMenu
        ? _c(
            "div",
            {
              staticClass: "outerBox",
              style: _vm.outerBoxStyles,
              on: { click: _vm.click }
            },
            [
              _c("div", { staticClass: "topButton" }, [_vm._t("top")], 2),
              _c("div", { staticClass: "belowButton" }, [_vm._t("bottom")], 2)
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }