/*
 * @Author: Code-HHX
 * @Date: 2021-12-10 11:07:33
 * @LastEditors: Code-HHX
 * @LastEditTime: 2021-12-30 15:48:22
 * @Description: 
 */
import { format } from './time'
import axios from '../config/http.js'
import axios1 from '../config/http-json.js'
import baseUrl from '../config/env.js'

// account manage
async function list(params, ctx) {
	return axios.fetchGet(baseUrl + '/subAccount/list', params)
}

function enableOrProhibit(params = {}) {
	return axios.fetchPost(baseUrl + '/subAccount/enableOrProhibit', params)
}

function add(params) {
	return axios.fetchPost(baseUrl + '/subAccount/addSubAccount', params)
}

function findById(params) {
	return axios.fetchGet(baseUrl + '/subAccount/selectById', params)
}

function updateById(params) {
	return axios.fetchPost(baseUrl + '/subAccount/updateSubAccountById', params)
}

function changePasswordById(params) {
	return axios.fetchPost(baseUrl + '/subAccount/changePasswordById', params)
}

// role manage
async function roleList(params, ctx) {
	return axios.fetchGet(baseUrl + '/subAccountRole/list', params)
}

function roleAdd(params) {
	return axios1.fetchPost(baseUrl + '/subAccountRole/add', params)
}

function roleFindById(params) {
	return axios.fetchGet(baseUrl + '/subAccountRole/findById', params)
}

function roleUpdateById(params) {
	return axios1.fetchPost(baseUrl + '/subAccountRole/update', params)
}

function findAllMenu(params) {
	return axios.fetchGet(baseUrl + '/subAccountRole/findAllMenu', params)
}
function findUserMenu(params) {
	return axios.fetchGet(baseUrl + '/role/findUserMenu', params)
}
function selectAllPlatformButton(params) {
	return axios.fetchGet(baseUrl + '/role/selectAllPlatformButton', params)
}

function disableOrEnableRole(params){
	return axios.fetchPost(baseUrl + '/subAccountRole/deleteById', params)
}


//查看客户的所有子账户Developer角色权限
function findDeveloperAllMenu(params = {}) {
	return axios.fetchGet(
		baseUrl + '/subAccountRole/findDeveloperAllMenu',
		params
	)
}

export default {
	list,
	enableOrProhibit,
	add,
	findById,
	updateById,
	changePasswordById,
	roleList,
	roleAdd,
	roleFindById,
	roleUpdateById,
	findAllMenu,
	findUserMenu,
	selectAllPlatformButton,
	findDeveloperAllMenu,
	disableOrEnableRole,
}
