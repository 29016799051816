<!--
 * @Author: Code-HHX
 * @Date: 2022-04-24 10:30:03
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-29 12:01:48
 * @Description: 
-->
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- 查询条件 -->
    <b-card>
      <b-row class="vo-items-center">
        <b-col lg="4">
          <div class="vo-row vo-items-center">
            <ReferenceBy label="REFINED BY" />
            <b-input
              style="flex: 1; margin-left: 10px"
              v-model="search_condition.clientInfo"
              placeholder="Search Client info"
            />
          </div>
        </b-col>
        <b-col>
          <b-form-select
            id="oil-type"
            v-model="search_condition.companyBusinessId"
            :options="types.oilType"
          ></b-form-select>
        </b-col>

        <b-col>
          <b-form-select
            id="client-status"
            v-model="search_condition.status"
            :options="types.status"
          ></b-form-select>
        </b-col>

        <b-col>
          <b-form-select
            id="country-id"
            v-model="search_condition.countryId"
            :options="types.countryList"
            value-field="id"
            text-field="name"
            @change="selectAddress(search_condition.countryId)"
          ></b-form-select>
        </b-col>

        <b-col>
          <b-form-select
            id="state-id"
            v-model="search_condition.stateId"
            :options="types.stateList"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-col>

        <b-col>
          <div class="vo-row vo-items-center vo-justify-between">
            <div class="custom-color cursor" @click="clearAll">Clear All</div>
            <b-button variant="info" @click="search">Search</b-button>
          </div>
        </b-col>
      </b-row>

      <div class="box-list">
        <b-button-group>
          <b-button
            v-has="'clientMgmt::Create'"
            @click="create()"
            variant="primary"
            >+Create Client</b-button
          >
        </b-button-group>
        <!-- table -->
        <div class="table-responsive mb-0">
          <b-table
            show-empty
            empty-text="No records"
            id="table"
            head-variant="light"
            ref="table"
            :busy="isBusy"
            :striped="true"
            :items="provider"
            :fields="fields"
            :per-page="search_condition.pageSize"
            :current-page="search_condition.pageNum"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-includedfields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template v-slot:cell(index)="data">
              {{
                data.index +
                (search_condition.pageNum - 1) * search_condition.pageSize +
                1
              }}
            </template>

            <template v-slot:cell(picture)="data">
              <img
                :src="`${data.item.picture}`"
                alt
                class="avatar-xs rounded-circle mr-2"
              />
            </template>

            <template v-slot:cell(actions)="row">
              <b-button-group>
                <b-button
                  v-has="'clientMgmt::Details'"
                  size="sm"
                  @click="onClickDetails(row.item, row.index, $event.target)"
                  class="mr-1"
                  >View</b-button
                >
                <b-button
                  v-show="row.item.approvalStatus == 0"
                  v-has="'clientMgmt::Verify'"
                  size="sm"
                  variant="success"
                  @click="onClickVerify(row.item, row.index, $event.target)"
                  class="mr-1"
                  >Verify</b-button
                >
                <b-button
                  v-has="'clientMgmt::Modify'"
                  size="sm"
                  variant="primary"
                  @click="show(row.item, row.index, $event.target)"
                  class="mr-1"
                  >Modify</b-button
                >
                <b-button
                  v-show="row.item.approvalStatus == 1 && row.item.status == 0"
                  v-has="'clientMgmt::Disable'"
                  size="sm"
                  variant="secondary"
                  @click="clickStatus(row.item)"
                  class="mr-1"
                  >Disable</b-button
                >
                <b-button
                  v-show="row.item.approvalStatus == 1 && row.item.status == 1"
                  v-has="'clientMgmt::Disable'"
                  size="sm"
                  variant="success"
                  @click="clickStatus(row.item)"
                  class="mr-1"
                  >Enable</b-button
                >
              </b-button-group>
            </template>
            <template v-slot:cell(showStatus)="row">
              <p v-if="row.item.status == 1" class="clientStyle">Disable</p>
              <p v-if="row.item.status == 0">Enable</p>
            </template>
          </b-table>
        </div>
      </div>
      <!-- end table -->
      <!-- pagination -->
      <b-row>
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="rows != 0">
              <li>
                <p
                  class="pagination mb-0"
                  style="margin-top: 7px; margin-right: 20px"
                >
                  {{ rows }} results
                </p>
              </li>
              <b-pagination
                v-model="search_condition.pageNum"
                :per-page="10"
                :total-rows="rows"
                aria-controls="table"
              ></b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
      <!-- end pagination -->
    </b-card>
    <!-- 修改密码弹框 -->
    <b-modal
      ref="modal"
      v-model="verifyModal"
      title="Verify"
      @hidden="resetModal"
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
    >
      <form ref="form" @submit="onSure" @reset="onClose">
        <!-- 审核状态 -->
        <b-form-group
          label="Verify Status:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="verify-status"
          label-class="requiredRow"
        >
          <b-form-checkbox
            id="verify-status"
            v-model="modalData.approvalStatus"
            name="status"
            :value="1"
            :unchecked-value="2"
            switch
            size="lg"
            style="margin-top: 5px; user-select: none"
          >
            {{ modalData.approvalStatus == 1 ? "Pass" : "Not Pass" }}
          </b-form-checkbox>
        </b-form-group>
        <!-- 品牌名称 -->
        <b-form-group
          v-if="modalData.approvalStatus == 1"
          label="Brand Name:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="brand-name"
          label-class="requiredRow"
        >
          <b-form-input
            id="brand-name"
            v-model="modalData.brandName"
            trim
            required
            placeholder="Please enter"
            type="text"
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 20)value = value.slice(0, 20)"
          ></b-form-input>
        </b-form-group>
        <!-- 角色类型 -->
        <b-form-group
          v-if="modalData.approvalStatus == 1"
          label="Client Type:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="client-type"
          label-class="requiredRow"
        >
          <b-form-select
            id="client-type"
            :options="types.clientTypeList"
            v-model="modalData.roleId"
            value-field="value"
            text-field="text"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
          ></b-form-select>
        </b-form-group>
        <!-- 备注 -->
        <b-form-group
          label="Notes:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="remarks"
        >
          <b-form-textarea
            id="remarks"
            v-model="modalData.approvalRemarks"
            placeholder="Enter Notes"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group label-cols-lg="4">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" variant="danger" class="m-l-5 ml-1"
            >Cancel</b-button
          >
        </b-form-group>
      </form>
    </b-modal>
    <!-- end main -->
    <Dialog
      v-model="showAlert"
      :imageSrc="require('@/assets/confirm.svg')"
      okBtn="Yes"
      noBtn="No"
      @ok="disabledOrEnbleClient"
      @cancel="cancel"
    >
      <p>{{ showContent }}</p>
    </Dialog>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel1"
    >
      <p>Saved successfully</p>
    </Dialog>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import ReferenceBy from "@/components/FindByLabel.vue";
import Dialog from "@/components/Dialog";

/**
 * Device component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    timeZoneConversion,
    ReferenceBy,
    Dialog,
  },
  data() {
    return {
      title: "Client List",
      items: [
        {
          text: this.$store.getters.getTitle,
        },
        {
          text: "Client&Brand",
        },
        {
          text: "Client Mgmt",
        },
        {
          text: "Client List",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [
        "deviceNumber",
        "deviceCoding",
        "deviceLocation",
        "activationTime",
      ],
      sortBy: "id",
      sortDesc: false,
      verifyModal: false,
      modalData: {
        id: "",
        approvalStatus: "1", //审核状态
        brandName: "",
        roleId: "", //角色类型
        approvalRemarks: "", //备注
      }, 
      fields: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "id",
          label: "Client ID",
          class: "text-center",
        },
        {
          key: "username",
          label: "Account Email:",
          class: "text-center",
        },
        {
          key: "companyName",
          label: "Client Company Name",
          class: "text-center",
        },
        {
          key: "companyBusinessId",
          label: "Company Business",
          class: "text-center",
          formatter: (value) => {
            if (value) {
              const business = value.split(",");
              const newBusiness = business.map((value) => {
                if (value == 1) {
                  return "Nicotine";
                } else if (value == 2) {
                  return "CBD/THC Concentrates";
                }
              });
              const result = newBusiness.join(",");
              return result;
            }
          },
        },
        {
          key: "address",
          label: "Company Address",
          class: "text-center",
        },
        {
          key: "showStatus", //待审核状态显示待审核 审核通过显示启用/禁用状态
          label: "Status",
          class: "text-center",
        },
        {
          key: "createTime",
          label: "Register Time",
          class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.getLocalTime(value);
          },
        },
        // {
        // 	key: 'operatorName', //操作人
        // 	label: 'Operator',
        // 	class: 'text-center',
        // },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        status: "", //状态(0是停用 1是启用)
        supplierId: localStorage.supplierId,
        companyBusinessId: "",
        countryId: "",
        stateId: "",
        clientInfo: "",
      },
      isBusy: false,
      has_data: false,
      types: {
        approvalList: [
          { text: "Please select", value: "" },
          { text: "To be verify", value: "0" },
          { text: "Enable", value: "1" }, //审核通过
          { text: "Disable", value: "2" }, //审核不通过
        ],
        clientTypeList: [{ text: "Please select", value: "" }],
        status: [
          { text: "--All Status--", value: "", disabled: false },
          { text: "Enable", value: 0, disabled: false },
          { text: "Disable", value: 1, disabled: false },
        ],
        oilType: [
          { text: "--All Business Type--", value: "", disabled: false },
          { text: "CBD/THC Concentrates", value: 2, disabled: false },
          { text: "Nicotine", value: 1, disabled: false },
        ],
        countryList: [],
        stateList: [{ id: "", name: "--All States--" }],
      },
      postData: {},
      showAlert: false,
      successAlear: false,
      showContent: "",
    };
  },
  watch: {},
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  created() {
    //获取用户角色类型下拉框数据
    this.$api.Dropdown.supplierRoleList()
      .then((res) => {
        if (res.success) {
          for (let i = 0; i < res.data.length; i++) {
            this.types.clientTypeList.push({
              text: res.data[i].roleName,
              value: res.data[i].id,
            });
          }
        } else {
          if (res.message) {
            that.$bvToast.toast(res.message);
          }
        }
      })
      .catch((res) => {
        if (res.message) this.$bvToast.toast(res.message);
      });
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.selectAddress(null);
    },
    provider(ctx) {
      this.isBusy = true;
      if (this.search_condition.approvalStatus == 0)
        this.search_condition.status = "";
      if (this.search_condition.approvalStatus == 1) {
        this.search_condition.approvalStatus = "";
        this.search_condition.status = 0;
      }
      if (this.search_condition.approvalStatus == 2) {
        this.search_condition.approvalStatus = 1;
        this.search_condition.status = 1;
      }

      return this.$api.Client.selectClientList(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            x.data.forEach((element) => {
              if (!element.picture) {
                element.picture =
                  "https://app.unicoreus.com/supplement/static/files/tp20200917113058822.jpg";
              }
              if (element.approvalStatus == 0)
                element.showStatus = "To be verify";
              else if (element.status == 0) element.showStatus = "Enable";
              else if (element.status == 1) element.showStatus = "Disable";
            });

            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    show(item, index, button) {
      this.$router.push("clientMgmtEdit?id=" + item.id);
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },

    clearAll() {
      this.search_condition = {
        pageNum: 1,
        pageSize: 10,
        status: "", //状态(0是停用 1是启用)
        supplierId: localStorage.supplierId,
        companyBusinessId: "",
        countryId: "",
        stateId: "",
        clientInfo: "",
      };
      this.$refs.table.refresh();
    },

    selectAddress(pid) {
      this.$api.Dropdown.selectAddressById({ pid: pid }).then((res) => {
        if (res.success) {
          if (pid) {
            this.types.stateList = [{ id: "", name: "--All States--" }].concat(
              res.data
            );
          } else {
            this.types.countryList = [
              { id: "", name: "--All Country--" },
            ].concat(res.data);
          }
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    //审核操作
    onClickVerify(item, index, row) {
      console.log(item);
      this.modalData.id = item.id;
      this.modalData.brandName = item.brandName;
      this.modalData.roleId =
        item.supplierRoleId == null ? "" : item.supplierRoleId;
      this.modalData.approvalRemarks = item.approvalRemarks;
      this.verifyModal = true;
    },

    clickStatus(item) {
      let status = 0;
      if (item.status == 0) {
        status = 1;
        this.showContent = "Are sure to disable this client?";
      } else {
        this.showContent = "Are sure to enble this client?";
      }
      this.showAlert = true;
      this.postData = {
        id: item.id,
        status: status,
      };
    },

    disabledOrEnbleClient() {
      this.$api.Client.enableOrProhibit(this.postData)
        .then((res) => {
          if (res.success) {
            this.showAlert = false;
            this.successAlear = true;
            this.$refs.table.refresh();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel() {
      this.showAlert = false;
    },
    cancel1() {
      this.successAlear = false;
    },
    onClickDetails(item, index, row) {
      this.$router.push("clientMgmtInfo?id=" + item.id);
    },
    create() {
      this.$router.push({
        name: "clientMgmtAdd",
      });
    },
    onSure(evt) {
      evt.preventDefault();
      let postData = null;
      if (this.modalData.approvalStatus == 1) {
        postData = this.modalData;
      } else {
        postData = {
          id: this.modalData.id,
          approvalStatus: this.modalData.approvalStatus,
          approvalRemarks: this.modalData.approvalRemarks,
        };
      }
      this.$api.Client.auditClientAccount(postData)
        .then((res) => {
          this.$refs.table.refresh();

          if (res.success) {
            this.verifyModal = !this.verifyModal;
          }
          if (res.message) {
            this.$toast(res);
          }
        })
        .catch((error) => {});
    },
    onClose(evt) {
      evt.preventDefault();
      this.modalData = {
        id: "",
        approvalStatus: "1",
        brandName: "",
        roleId: "",
        approvalRemarks: "",
      };
      this.verifyModal = !this.verifyModal;
    },
    resetModal() {
      this.modalData = {
        id: "",
        approvalStatus: "1",
        brandName: "",
        roleId: "",
        approvalRemarks: "",
      };
    },
  },
};
</script>
