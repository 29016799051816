<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<b-card>
			<b-row class="align-items-center">
				<b-col lg="12">
					<div class="blockquote">
						<h4>Basic Info</h4>
					</div>
				</b-col>
			</b-row>
			<b-container>
				<b-row cols="3">
					<b-col>
						<label class="col-form-label">Device NO.:</label>
						<span class="p-3">{{ form.deviceNumber }}</span>
					</b-col>

					<b-col>
						<label class="col-form-label">Device Model:</label>
						<span class="p-3">{{ form.deviceModel }}</span>
					</b-col>

					<b-col>
						<label class="col-form-label">Device Status:</label>
						<span class="p-3">
							{{
								form.bindStatus == null
									? ''
									: form.bindStatus == 0
									? 'Unregistered'
									: 'Registered'
							}}
						</span>
					</b-col>

					<b-col>
						<label class="col-form-label">Registered Email:</label>
						<span class="p-3">{{ form.email }}</span>
					</b-col>

					<b-col>
						<label class="col-form-label">Repayment Period:</label>
						<span class="p-3">{{ form.repaymentPeriod }}</span>
					</b-col>

					<b-col>
						<label class="col-form-label">Valid Period(day):</label>
						<span class="p-3">{{ form.validPeriod }}</span>
					</b-col>

					<b-col>
						<label class="col-form-label">Activated Time:</label>
						<span class="p-3">{{
							getLocalTime(form.activationTime)
						}}</span>
					</b-col>

					<b-col>
						<label class="col-form-label">Firmware Version:</label>
						<span class="p-3">{{ form.versionNo }}</span>
					</b-col>

					<b-col>
						<label class="col-form-label">Renewal Mode:</label>
						<span class="p-3">{{
							form.renewalType == null
								? ''
								: form.renewalType == 0
								? 'Automatic'
								: 'Manual'
						}}</span>
					</b-col>

					<b-col>
						<label class="col-form-label"
							>Payment Grace Period(day):</label
						>
						<span class="p-3">{{ form.graceDays }}</span>
					</b-col>
				</b-row>
			</b-container>
			<br />
			<b-row class="align-items-center">
				<b-col lg="12">
					<div class="blockquote">
						<h4>Operation Records</h4>
					</div>
				</b-col>
			</b-row>

			<!-- table -->
			<div class="table-responsive mb-0">
				<b-table
					show-empty
					empty-text="No records"
					id="table"
					head-variant="light"
					ref="table"
					:busy="isBusy"
					:striped="true"
					:items="provider"
					:fields="fields"
					:per-page="search_condition.pageSize"
					:current-page="search_condition.pageNum"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:filter="filter"
					:filter-includedfields="filterOn"
					@filtered="onFiltered"
				>
					<template v-slot:table-busy>
						<div class="text-center text-danger my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Loading...</strong>
						</div>
					</template>

					<template v-slot:cell(index)="data">
						{{
							data.index +
								(search_condition.pageNum - 1) *
									search_condition.pageSize +
								1
						}}
					</template>
				</b-table>
			</div>
			<!-- end table -->
			<!-- pagination -->
			<b-row style="margin-top: 10px">
				<b-col>
					<b-button
						v-has="'deviceLeasing::Stage Setting'"
						v-if="
							(form.whetherToPayOff == 0 &&
								form.repaymentType == 0) ||
								form.repaymentType == null
						"
						@click="openModal"
						variant="primary"
						style="margin-right: 10px"
						>Stage setting</b-button
					>
					<b-button
						v-has="'deviceLeasing::IncreasedValidityPeriod'"
						v-if="
							form.whetherToPayOff == 0 && form.repaymentType == 0
						"
						@click="
							increasedValidityPeriod = !increasedValidityPeriod
						"
						variant="primary"
						style="margin-right: 10px"
						>Increased validity period</b-button
					>
					<b-button variant="outline-secondary" @click="returnPage"
						>Cancel</b-button
					>
					<div
						class="dataTables_paginate paging_simple_numbers float-right"
					>
						<ul class="pagination mb-0" v-if="rows != 0">
							<b-pagination
								v-model="search_condition.pageNum"
								:per-page="10"
								:total-rows="rows"
								aria-controls="table"
							></b-pagination>
						</ul>
					</div>
				</b-col>
			</b-row>
			<!-- end pagination -->
		</b-card>
		<!-- 分期设置 -->
		<b-modal
			ref="stageSetting"
			v-model="stageSettingModal"
			title="Subscription Settings Status"
			@hidden="reset"
			hide-footer
			size="lg"
			centered
			no-close-on-esc
			no-close-on-backdrop
		>
			<form ref="form" @submit="onSubmit" @reset="onReset">
				<b-form-group
					label="Validity Type:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="validityType"
				>
					<b-form-radio-group
						id="radio-group-1"
						v-model="saveRenewal.repaymentType"
						:options="
							form.repaymentType == null
								? types.repaymentTypes
								: [
										{
											text: 'Payment by installment',
											value: 0,
											disabled: false,
										},
								  ]
						"
						required
						style="margin-top: 5px"
					></b-form-radio-group>
				</b-form-group>
				<b-form-group
					label="Renewal Mode:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="renewalMode"
					v-if="saveRenewal.repaymentType != 1"
				>
					<b-form-radio-group
						id="radio-group-2"
						v-model="saveRenewal.renewalType"
						:options="types.renewalTypes"
						required
						style="margin-top: 5px"
					></b-form-radio-group>
					<div>
						Tip: Setting automatic will automatically renew the use
						time
					</div>
				</b-form-group>
				<b-form-group
					label="Months To Complete:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="stages"
					v-if="saveRenewal.repaymentType != 1"
				>
					<b-form-input
						id="stages"
						trim
						v-model="saveRenewal.repaymentNumber"
						:disabled="
							form.renewalType == 0 ||
								form.renewalType == 1 ||
								form.repaymentNumber
						"
						type="number"
						min="1"
						max="12"
						required
						placeholder="Please enter"
						oninvalid="setCustomValidity('Please enter Numbers up to 12');"
						oninput="setCustomValidity('');if(value > 12||value < 0)value =''"
					></b-form-input>
					<div>
						The number of stages required by the user, which can be
						used permanently after completion of the stages
					</div>
				</b-form-group>
				<b-form-group
					label="Payment Grace Period:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="period"
					v-if="saveRenewal.repaymentType != 1"
				>
					<b-form-input
						id="period"
						trim
						v-model="saveRenewal.graceDays"
						type="number"
						min="0"
						max="30"
						:required="saveRenewal.renewalType != 0"
						placeholder="Please enter"
						oninvalid="setCustomValidity('Please enter Numbers up to 30');"
						oninput="setCustomValidity('');if(value > 30||value < 0)value =''"
					></b-form-input>
					<div>
						After the specified repayment date, the device will be
						available during the grace period and will be
						automatically locked after the grace period
					</div>
				</b-form-group>
				<b-form-group
					label="Notes:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="remarks"
				>
					<b-form-input
						id="remarks"
						trim
						v-model="saveRenewal.remarks"
						placeholder="50 characters maximum"
						oninput="if(value.length > 50)value = value.slice(0, 50)"
					></b-form-input>
				</b-form-group>

				<b-form-group style="text-align: center">
					<b-button type="submit" variant="primary">Confirm</b-button>
					<b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
				</b-form-group>
			</form>
		</b-modal>
		<!-- 增加有效期 -->
		<b-modal
			ref="increasedValidity"
			v-model="increasedValidityPeriod"
			title="Increased Validity Period"
			@hidden="resetIncreasedValidityPeriod"
			hide-footer
			centered
			no-close-on-esc
			no-close-on-backdrop
		>
			<form
				ref="form"
				@submit="onSubmitIncreasedValidityPeriod"
				@reset="onResetIncreasedValidityPeriod"
			>
				<b-form-group
					label="Increase:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="months"
				>
					<b-form-radio-group
						id="radio-group-3"
						v-model="saveValidity.periodType"
						:options="
							form.renewalType == 0
								? [
										{
											text: 'Early settlement',
											value: 2,
											disabled: false,
										},
								  ]
								: types.periodTypes
						"
						style="margin-top: 5px"
					></b-form-radio-group>
				</b-form-group>
				<b-form-group
					label="Notes:"
					label-cols-lg="3"
					label-align-lg="right"
					label-for="remarks"
				>
					<b-form-input
						id="remarks"
						trim
						v-model="saveValidity.remarks"
						placeholder="Within 50 characters"
						oninput="if(value.length > 50)value = value.slice(0, 50)"
					></b-form-input>
				</b-form-group>

				<b-form-group style="text-align: center">
					<b-button type="submit" variant="primary">Confirm</b-button>
					<b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
				</b-form-group>
			</form>
		</b-modal>
	</Layout>
</template>
<script>
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'

export default {
	components: {
		Layout,
		PageHeader,
		timeZoneConversion,
	},
	data() {
		return {
			title: 'Device Leasing Details',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'Device Manage',
					// href: "/"
				},
				{
					text: 'Device Leasing Details',
					active: true,
				},
			],
			fields: [
				{ key: 'index', label: '#', class: 'text-center' },
				{
					key: 'operationContent',
					label: 'Action',
					class: 'text-center',
				},
				{ key: 'nickName', label: 'Username', class: 'text-center' },
				{
					key: 'createTime',
					label: 'Operating Time',
					class: 'text-center',
					formatter: (value) => {
						return timeZoneConversion.getLocalTime(value)
					},
				},
				{ key: 'remarks', label: 'Notes', class: 'text-center' },
			],
			search_condition: {
				pageNum: 1,
				pageSize: 10,
				id: '',
			},
			totalRows: 0,
			currentPage: 1,
			filter: null,
			filterOn: [],
			sortBy: 'id',
			sortDesc: false,
			isBusy: false,
			form: {},
			stageSettingModal: false,
			increasedValidityPeriod: false,
			types: {
				renewalTypes: [
					{ text: 'Automatic', value: 0, disabled: false },
					{ text: 'Manual', value: 1, disabled: false },
				],
				repaymentTypes: [
					{
						text: 'Payment by installment',
						value: 0,
						disabled: false,
					},
					{ text: 'Permanent effective', value: 1, disabled: false },
				],
				periodTypes: [
					{ text: 'A month', value: 1, disabled: false },
					{ text: 'Early settlement', value: 2, disabled: false },
				],
			},
			saveRenewal: {
				id: '',
				repaymentType: '',
				renewalType: '',
				repaymentNumber: '',
				graceDays: '',
				remarks: '',
			},
			saveValidity: {
				id: '',
				periodType: '',
				remarks: '',
			},
		}
	},
	computed: {
		/**
		 * 计算rows
		 */
		rows() {
			return this.totalRows
		},
	},

	created() {},
	mounted() {
		this.init()
	},
	methods: {
		getLocalTime(date) {
			return timeZoneConversion.getLocalTime(date)
		},
		provider(ctx) {
			this.isBusy = true
			this.search_condition.id = this.$route.query.id
			return this.$api.Device.selectDeviceLogData(
				this.search_condition,
				ctx
			)
				.then((x) => {
					this.isBusy = false
					if (x.total) {
						this.totalRows = x.total
						return x.data
					} else {
						return []
					}
				})
				.catch((e) => {
					this.isBusy = false
					return []
				})
		},
		init() {
			if (this.$route.query.id) {
				this.$api.Device.selectByDeviceId(this.$route.query).then(
					(res) => {
						if (res.success && res.data) {
							this.form = res.data
						} else {
							if (res.message) {
								this.$toast(res)
							}
						}
					}
				)
			} else {
				this.$bvToast.toast('id?')
			}
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		returnPage() {
			this.$router.go(-1)
		},
		openModal() {
			this.stageSettingModal = !this.stageSettingModal
			if (this.$route.query.id) {
				this.$api.Device.selectRenewalMethodDetails(
					this.$route.query
				).then((res) => {
					if (res.success) {
						if (res.data) {
							this.saveRenewal = res.data
						} else {
							this.saveRenewal = {
								id: '',
								repaymentType: '',
								renewalType: null,
								repaymentNumber: null,
								graceDays: '',
								remarks: '',
							}
						}
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
			}
		},
		reset() {
			this.saveRenewal = {
				id: '',
				repaymentType: '',
				renewalType: null,
				repaymentNumber: null,
				graceDays: '',
				remarks: '',
			}
		},
		onReset(evt) {
			evt.preventDefault()
			this.saveRenewal = {
				id: '',
				repaymentType: '',
				renewalType: null,
				repaymentNumber: null,
				graceDays: '',
				remarks: '',
			}
			this.stageSettingModal = !this.stageSettingModal
		},
		onSubmit(evt) {
			evt.preventDefault()
			this.saveRenewal.id = this.$route.query.id
			this.$api.Device.saveRenewalMethod(this.saveRenewal).then((res) => {
				if (res.success) {
					this.stageSettingModal = !this.stageSettingModal
					this.init()
					this.$refs.table.refresh()
				} else {
					if (res.message) {
						this.$toast(res)
					}
				}
			})
		},
		resetIncreasedValidityPeriod() {
			this.saveValidity = {
				id: '',
				periodType: '',
				remarks: '',
			}
		},
		onResetIncreasedValidityPeriod(evt) {
			evt.preventDefault()
			this.saveValidity = {
				id: '',
				periodType: '',
				remarks: '',
			}
			this.increasedValidityPeriod = !this.increasedValidityPeriod
		},
		onSubmitIncreasedValidityPeriod(evt) {
			evt.preventDefault()
			this.saveValidity.id = this.$route.query.id
			this.$api.Device.saveValiditySetting(this.saveValidity).then(
				(res) => {
					if (res.success) {
						this.increasedValidityPeriod = !this
							.increasedValidityPeriod
						this.init()
						this.$refs.table.refresh()
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				}
			)
		},
	},
}
</script>
<style>
.blockquote {
	padding: 10px 10px;
	border-left: 5px solid #aaaaff;
}
</style>
