var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MaskView",
    {
      attrs: {
        borderStyle: "border: 1px solid #d7d7d7",
        outerBorderStyle: "1px dashed #02a7f0",
        "mask-background-color": "transparent"
      },
      on: { click: _vm.click }
    },
    [
      _c("div", { staticClass: "position-absolute create-prod-wrap" }, [
        _c(
          "div",
          {
            staticClass:
              "vo-column align-items-center justify-content-center h-100 w-100"
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/images/common/ic_add.svg"),
                alt: ""
              }
            }),
            _c("div", { staticClass: "caption" }, [_vm._v(_vm._s(_vm.name))])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }