<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row>
        <b-col lg="8">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <!-- Tags Name 标签名称-->
        <b-form-group
          label="Tags Name:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="tags-name"
          label-class="requiredRow"
        >
          <b-form-input
            id="tags-name"
            v-model="form.labelName"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
            placeholder="Please enter"
          ></b-form-input>
        </b-form-group>
        <!-- remarks 备注 -->
        <b-form-group
          label="Notes:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="remarks"
        >
          <b-form-textarea
            id="remarks"
            v-model="form.remarks"
            placeholder="Enter Notes"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-cols-lg="3">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
        </b-form-group>
      </b-form>
      <!-- <p>{{form}}</p> -->
        </b-col>
      </b-row>
    </b-card>
  </Layout>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Cartridge Model Add
 */
export default {
  components: {
    ckeditor: CKEditor.component,
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: this.editId ? "Intensity Modify" : "Intensity Create",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "Settings",
          // href: "/"
        },
        {
          text: this.editId ? "Intensity Modify" : "Intensity Create",
          active: true,
        },
      ],
      form: {
        supplierId: localStorage.supplierId,
        labelName: "",
        labelColor: "",
        remarks: "",
      },
      types: {},
      show: true, // 表单显示
      editor: ClassicEditor,
      editId: this.$route.params.id,
    };
  },
  beforeCreate() {
    this.editId = this.$route.params.id;
  },
  created() {},
  mounted() {
    this.init();
    if (/edit/i.test(window.location.href)) {
      this.show1();
    }
  },
  methods: {
    init() {},
    show1() {
      if (this.$route.params.id) {
        this.form = this.$route.params.item;
      } else {
        this.$bvToast.toast("id?");
      }
    },
    onSubmit(evt) {
      evt.preventDefault();

      if (/edit/i.test(window.location.href)) {
        this.$api.Label.intensitySaveOrUpdateLabel(this.form).then((res) => {
          if (res.success) {
            this.$toastBack(res);
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      } else {
        this.$api.Label.intensitySaveOrUpdateLabel(this.form).then((res) => {
          if (res.success) {
            this.$toastBack(res);
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      (this.form = {
        supplierId: localStorage.supplierId,
        labelName: "",
        labelColor: "",
        remarks: "",
      }),
        (this.show = false);
      this.$nextTick(() => {
        // this.show = true;
        this.$router.go(-1);
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-color-picker__trigger {
  width: 100px;
}
</style>