<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row>
        <b-col lg="8">
          <b-form ref="firstTabForm" @submit="onSubmit" @reset="onReset">
            <!-- 批次名称 -->
            <b-form-group
              label="Batch Name:"
              label-cols-lg="4"
              label-align-lg="right"
              label-for="batch-name"
              label-class="requiredRow"
            >
              <b-form-input
                id="batch-name"
                v-model="form.orderName"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please enter"
              ></b-form-input>
            </b-form-group>

            <!-- 烟油生产批次 -->
            <b-form-group
              label="Content Production Batch:"
              label-cols-lg="4"
              label-align-lg="right"
              label-for="content-batch"
              label-class="requiredRow"
            >
              <b-form-select
                id="content-batch"
                v-model="form.batchNumberId"
                :options="types.batchNumberList"
                value-field="id"
                text-field="number"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
            </b-form-group>

            <!-- 清除数量 -->
            <b-form-group
              label="Planned Clearance Quantity:"
              label-cols-lg="4"
              label-align-lg="right"
              label-for="production-quantity"
              label-class="requiredRow"
            >
              <b-form-input
                id="production-quantity"
                v-model="form.batchQuantity"
                type="number"
                min="0"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <!-- 备注 -->
            <b-form-group
              label="Notes:"
              label-cols-lg="4"
              label-align-lg="right"
              label-for="remarks"
            >
              <b-form-textarea
                id="remarks"
                v-model="form.remarks"
                placeholder="Enter Notes"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-col lg="12" style="text-align: center">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" style="margin-left: 30px">Return</b-button>
            </b-col>
          </b-form>
        </b-col>
        <!-- end col -->
      </b-row>
    </b-card>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
  },
  computed: {
    is_add: function () {
      return /add/i.test(location.href);
    },
  },
  created() {
    this.init();
  },
  data() {
    return {
      title: "Remove Content Batch Create",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Manage",
          // href: "/"
        },
        {
          text: "Remove Content Batch Create",
          active: true,
        },
      ],
      types: {
        batchNumberList: [{ number: "Please select", id: "" }],
      },
      form: {
        orderName: "",
        batchNumberId: "",
        batchQuantity: "",
        remarks: "",
      },
    };
  },
  methods: {
    init() {
      this.$api.Dropdown.batchNumberList({
        supplierId: localStorage.supplierId,
      }).then((res) => {
        if (res.success && res.data) {
          this.types.batchNumberList = this.types.batchNumberList.concat(
            res.data
          );
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$api.CartridgeBatch.clearOrderSave(this.form).then((res) => {
        if (res.success) {
          this.$toastBack(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.$router.go(-1);
    },
  },
};
</script>
