/*
 * @Author: Code-HHX
 * @Date: 2022-04-26 14:42:56
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-26 16:00:13
 * @Description:
 */
import { format } from './time'
import axios from '../config/http.js'
import axios1 from '../config/http-json.js'
import baseUrl from '../config/env.js'

//查询所有的客户角色列表
async function selectSupplierRoleList(params = {}) {
	return axios1.fetchGet(baseUrl + '/role/selectSupplierRoleList', params)
}

//查询客户所有的菜单权限
async function findAllMenu(params = {}) {
	return axios1.fetchGet(baseUrl + '/role/findAllMenu', params)
}

//查询客户的APP菜单权限
async function selectDeveloperMenu(params = {}) {
	return axios1.fetchGet(baseUrl + '/role/selectDeveloperMenu', params)
}

//添加客户角色
async function saveSupplierRole(params = {}) {
	return axios1.fetchPost(baseUrl + '/role/saveSupplierRole', params)
}

//修改客户角色
async function updateSupplierRole(params = {}) {
	return axios1.fetchPost(baseUrl + '/role/updateSupplierRole', params)
}

//查询客户角色详情
async function selectById(params = {}) {
	return axios1.fetchGet(baseUrl + '/role/selectById', params)
}

export default {
	selectSupplierRoleList,
	findAllMenu,
	selectDeveloperMenu,
	saveSupplierRole,
	updateSupplierRole,
	selectById,
}
