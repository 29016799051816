var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "12" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Brand Name",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "brandName"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "brandName",
                                  trim: "",
                                  oninput:
                                    "if(value.length > 20)value = value.slice(0, 20)"
                                },
                                model: {
                                  value: _vm.search_condition.brandName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.search_condition,
                                      "brandName",
                                      $$v
                                    )
                                  },
                                  expression: "search_condition.brandName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { lg: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Flavor Name",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "flavorName"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "flavorName",
                                  trim: "",
                                  oninput:
                                    "if(value.length > 20)value = value.slice(0, 20)"
                                },
                                model: {
                                  value: _vm.search_condition.flavorName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.search_condition,
                                      "flavorName",
                                      $$v
                                    )
                                  },
                                  expression: "search_condition.flavorName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { lg: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Create Time",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "operation-time"
                              }
                            },
                            [
                              _c("date-picker", {
                                attrs: {
                                  format: "MM/DD/YYYY",
                                  range: "",
                                  "append-to-body": "",
                                  lang: "en",
                                  placeholder: "Select date"
                                },
                                model: {
                                  value: _vm.selectTime,
                                  callback: function($$v) {
                                    _vm.selectTime = $$v
                                  },
                                  expression: "selectTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { lg: "3" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.search()
                                }
                              }
                            },
                            [_vm._v("Search")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "text-center" },
                    [_c("b-col", { attrs: { lg: "12" } })],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-card",
                [
                  _c(
                    "div",
                    { staticClass: "table-responsive mb-0" },
                    [
                      _c("b-table", {
                        ref: "table",
                        attrs: {
                          "show-empty": "",
                          "empty-text": "No records",
                          id: "device-table",
                          "head-variant": "light",
                          striped: true,
                          busy: _vm.isBusy,
                          items: _vm.provider,
                          fields: _vm.fields,
                          "per-page": _vm.search_condition.pageSize,
                          "current-page": _vm.search_condition.pageNum,
                          "sort-by": _vm.sortBy,
                          "sort-desc": _vm.sortDesc,
                          filter: _vm.filter,
                          "filter-includedfields": _vm.filterOn
                        },
                        on: {
                          "update:sortBy": function($event) {
                            _vm.sortBy = $event
                          },
                          "update:sort-by": function($event) {
                            _vm.sortBy = $event
                          },
                          "update:sortDesc": function($event) {
                            _vm.sortDesc = $event
                          },
                          "update:sort-desc": function($event) {
                            _vm.sortDesc = $event
                          },
                          filtered: _vm.onFiltered
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-busy",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-center text-danger my-2"
                                  },
                                  [
                                    _c("b-spinner", {
                                      staticClass: "align-middle"
                                    }),
                                    _c("strong", [_vm._v("Loading...")])
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "cell(index)",
                            fn: function(data) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    data.index +
                                      (_vm.search_condition.pageNum - 1) *
                                        _vm.search_condition.pageSize +
                                      1
                                  )
                                )
                              ]
                            }
                          },
                          {
                            key: "cell(flavorPicture)",
                            fn: function(data) {
                              return [
                                data.item.flavorPicture
                                  ? _c("img", {
                                      staticClass:
                                        "avatar-xs rounded-circle mr-2",
                                      attrs: {
                                        src: "" + data.item.flavorPicture,
                                        alt: ""
                                      }
                                    })
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm.has_data
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dataTables_paginate paging_simple_numbers float-right"
                              },
                              [
                                _vm.rows != 0
                                  ? _c(
                                      "ul",
                                      { staticClass: "pagination mb-0" },
                                      [
                                        _c("b-pagination", {
                                          attrs: {
                                            "per-page": 10,
                                            "total-rows": _vm.rows,
                                            "aria-controls": "table"
                                          },
                                          model: {
                                            value: _vm.search_condition.pageNum,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.search_condition,
                                                "pageNum",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "search_condition.pageNum"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }