var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm._v(
            " Copyright © " +
              _vm._s(new Date().getFullYear()) +
              " " +
              _vm._s(this.brand) +
              " "
          ),
          _c("span", { staticClass: "d-none d-sm-inline-block" }, [
            _vm._v(" . All rights reserved ")
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }