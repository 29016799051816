import axios from 'axios'
import qs from 'qs'
import router from '../router/index'
import store from '../state/store.js'
import { BModal } from 'bootstrap-vue'

// 封装 axios
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'

// 创建axios 实例
const service = axios.create({
  //url = base url + request url
	//baseURL: process.env.VUE_APP_BASE_API,
	timeout: 130000, // 请求超时
	withCredentials: false,
})

/**
 * 封装 请求
 * 请求拦截、 响应拦截，异常统一处理
 */

//http request 拦截器
service.interceptors.request.use(
	(config) => {
		// 获取token
		const token = window.localStorage.getItem('auth-token')
		function getTimeZone(){
			// 添加时区
			var nowTimeZone = 0 - new Date().getTimezoneOffset() / 60;
		  var time = String(nowTimeZone);
		  if (nowTimeZone >= 0) {
			time = "+" + time;
		  }
		  var splitTime = time.split(".");
		  if (null != splitTime[1]) {
			if (splitTime[1] > 0) {
			  time = splitTime[0] + ":" + splitTime[1] * 6;
			} else {
			  time = splitTime[0] + ":0" + splitTime[1] * 6;
			}
		  } else {
			time += ":00";
		  }
		  return time;
		}
		// 设置参数格式
		if (!config.headers['Content-Type']) {
			config.headers = {
				'Content-Type': 'application/x-www-form-urlencoded',
			}
		}
		config.headers['supplier-token'] = token
		config.headers['timeZone'] = getTimeZone()
		//window.console.log(config);
		return config
	},
	(err) => {
		return Promise.reject(err)
	}
)

//http response 拦截器
service.interceptors.response.use(
	(response) => {
		const code = response.data.code
		if (code == 401) {
			// 登录验证
			//授权令牌无效，请重新登录
			store.commit('auth/SET_CURRENT_USER', '')
			router.push({
				name: 'login', //从哪个页面跳转
				query: {
					retUrl: window.location.href.split('#')[1] || '',
					is_new_user_url: 1,
					// code: 401,
				},
			})
			response.data.message = ''
		} else if (code == 500) {
			// this.$bvToast.toast(response.data.msg);
		} else if (code == 404) {
			// 404
		}
		return response
	},
	(error) => {
		return Promise.reject(error)
	}
)

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function fetchGet(url, params = {}) {
	return new Promise((resolve, reject) => {
		service({
			url: url,
			method: 'get',
			params: params,
		})
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function fetchPost(url, params = {}) {
	return new Promise((resolve, reject) => {
		service({
			url: url,
			method: 'post',
			data: qs.stringify(params),
		})
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

/*
 *  文件上传
 *  url:请求地址
 *  params:参数
 * */
export function fileUpload(url, params = {}) {
	return new Promise((resolve, reject) => {
		service({
			url: url,
			method: 'post',
			data: params,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export default {
	fetchGet,
	fetchPost,
	fileUpload,
}
