<!--
 * @Author: Code-HHX
 * @Date: 2021-12-10 11:07:33
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-03-28 14:15:58
 * @Description: 
-->
<script>
import { layoutComputed } from '@/state/helpers'

import Vertical from '@/router/layouts/vertical'
import Horizontal from '@/router/layouts/horizontal'

export default {
	components: {
		Vertical,
		Horizontal,
	},
	computed: {
		...layoutComputed,
	},
}
</script>

<template>
	<div>
		<vertical v-if="layoutType === 'vertical'" :layout="layoutType">
			<slot />
		</vertical>

		<Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
			<slot />
		</Horizontal>
	</div>
</template>
