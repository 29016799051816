<!--
 * @Author: Code-HHX
 * @Date: 2022-04-26 14:01:55
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-26 16:21:11
 * @Description: 
-->
<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />
    <b-card v-if="btnMenu('invitationCode::Create')">
      <div style="padding-top: 23px">
        <b-button
          v-has="'invitationCode::Create'"
          @click="gotoAdd"
          variant="primary"
          >+Create Invitation Code</b-button
        >
      </div>
      <div style="padding-top: 30px">
        Please invite your users or partner to use Omni together.
      </div>
    </b-card>
    <b-card>
      <b-row class="vo-items-center" v-if="!btnMenu('invitationCode::Create')">
        <b-col lg="4">
          <div class="vo-row vo-items-center">
            <ReferenceBy label="REFINED BY" />
            <b-input
              style="flex: 1; margin-left: 10px"
              v-model="search_condition.invitationCode"
              placeholder="Search Invitation Code"
            />
          </div>
        </b-col>
        <b-col>
          <b-form-select
            id="supplierId"
            v-model="search_condition.supplierId"
            :options="types.supplierList"
          ></b-form-select>
        </b-col>

        <b-col>
          <b-form-select
            id="brandId"
            v-model="search_condition.brandId"
            :options="types.brandList"
          ></b-form-select>
        </b-col>

        <b-col>
          <b-form-select
            id="code-type"
            v-model="search_condition.codeType"
            :options="types.codeTypeList"
          ></b-form-select>
        </b-col>

        <b-col>
          <b-form-select
            id="status-1"
            v-model="search_condition.status"
            :options="types.statusList"
          ></b-form-select>
        </b-col>

        <b-col>
          <div class="vo-row vo-items-center vo-justify-between">
            <div class="custom-color cursor" @click="clearAll">Clear All</div>
            <b-button variant="info" @click="search">Search</b-button>
          </div>
        </b-col>
      </b-row>

      <b-row
        class="align-items-center"
        v-if="btnMenu('invitationCode::Create')"
      >
        <b-col cols="12">
          <div class="blockquote">
            <h4>Invitation Code list</h4>
          </div>
        </b-col>
      </b-row>
      <div class="box-list" v-if="!btnMenu('invitationCode::Create')"></div>
      <div
        class="table-responsive mb-0"
        v-if="btnMenu('invitationCode::Create')"
      >
        <b-table
          show-empty
          empty-text="No records"
          id="table"
          head-variant="light"
          ref="table"
          :busy="isBusy"
          :striped="true"
          :items="provider"
          :fields="fields"
          :per-page="search_condition.pageSize"
          :current-page="search_condition.pageNum"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-includedfields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template v-slot:cell(index)="data">
            {{
              data.index +
              (search_condition.pageNum - 1) * search_condition.pageSize +
              1
            }}
          </template>
          <template v-slot:cell(actions)="row">
            <b-button-group>
              <b-button
                v-has="'invitationCode::Create'"
                size="sm"
                variant="primary"
                @click="codyCode(row.item, row.index, $event.target)"
                class="mr-1"
              >
                Copy Code
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </div>
      <!-- end table -->
      <!-- pagination -->
      <b-row v-if="has_data">
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="rows != 0">
              <li>
                <p
                  class="pagination mb-0"
                  style="margin-top: 7px; margin-right: 20px"
                >
                  {{ rows }} results
                </p>
              </li>
              <b-pagination
                v-model="search_condition.pageNum"
                :per-page="10"
                :total-rows="rows"
                aria-controls="table"
              >
              </b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
      <!-- end pagination -->
    </b-card>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel"
    >
      <p>
        Copy success! Please send the invitation code to your users or partner
        to use Omni together.
      </p>
    </Dialog>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import ReferenceBy from "@/components/FindByLabel.vue";
import Dialog from "@/components/Dialog";

/**
 * FAQ component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    timeZoneConversion,
    ReferenceBy,
    Dialog,
  },
  data() {
    return {
      page: {
        title: "Invitation Code",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "Client Mgmt",
            // href: "/"
          },
          {
            text: "Invitation Code",
            active: true,
          },
        ],
      },
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "invitationCode",
          label: "Invitation Code",
          class: "text-center",
        },
        {
          key: "codeType",
          label: "Code Type",
          class: "text-center",
          formatter: (value) => {
            return value == 1 ? "Operator Code" : "User Code";
          },
        },
        {
          key: "useType",
          label: "Useful Time",
          class: "text-center",
          formatter: (value) => {
            return value == 1 ? "Once Time" : "Unlimited";
          },
        },
        { key: "remarks", label: "Code Notes", class: "text-center" },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          formatter: (value) => {
            return value == 1 ? "Enabled" : "Disabled";
          },
        },
        {
          key: "createTime",
          label: "Create Time",
          class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.getLocalTime(value);
          },
        },
        {
          key: "createName",
          label: "Createor",
          class: "text-center",
        },
        {
          key: "brandName",
          label: "Brand",
          class: "text-center",
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        invitationCode: "",
        supplierId: "",
        brandId: "",
        codeType: "",
        status: "",
      },
      types: {
        supplierList: [{ text: "--All Client--", value: "", disabled: false }],
        brandList: [{ text: "--All Brand--", value: "", disabled: false }],
        codeTypeList: [
          { text: "--All Code type--", value: "", disabled: false },
          { text: "Operator Code", value: 1, disabled: false },
          { text: "User Code", value: 2, disabled: false },
        ],
        statusList: [
          { text: "--All Status--", value: "", disabled: false },
          { text: "Unused", value: 1, disabled: false },
          { text: "Used", value: 2, disabled: false },
        ],
      },
      isBusy: false,
      has_data: false,
      successAlear: false,
    };
  },
  watch: {},
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.selectInvitationBrand();
      this.selectInvitationClient();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    gotoAdd() {
      this.$router.push("invitationCodeAdd");
    },
    provider(ctx) {
      this.isBusy = true;
      return this.$api.invitationCode
        .selectInvitationCodeList(this.search_condition, ctx)
        .then((x) => {
          // x.data.forEach(function (value) {
          //   if (value.status == 1) {
          //     value._rowVariant = "info";
          //   }
          // });
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    selectInvitationBrand() {
      this.$api.invitationCode
        .selectInvitationBrand()
        .then((x) => {
          this.types.brandList = [
            { text: "--All Brand--", value: "", disabled: false },
          ].concat(
            x.data.map((item) => {
              return {
                text: item.brandName,
                value: item.id,
                disabled: false,
              };
            })
          );
        })
        .catch((e) => {
          this.has_data = false;
        });
    },

    selectInvitationClient() {
      this.$api.invitationCode
        .selectInvitationClient()
        .then((x) => {
          this.types.supplierList = [
            { text: "--All Client--", value: "", disabled: false },
          ].concat(
            x.data.map((item) => {
              return {
                text: item.companyName,
                value: item.id,
                disabled: false,
              };
            })
          );
        })
        .catch((e) => {
          this.has_data = false;
        });
    },

    //按钮权限判断
    btnMenu(value) {
      return this.$_has(value);
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    codyCode(item, index, button) {
      const textarea = document.createElement("textarea");
      // 设置要复制的文本
      textarea.value = item.invitationCode;
      // 将 textarea 元素添加到文档中
      document.body.appendChild(textarea);
      // 选择 textarea 的文本
      textarea.select();
      // 执行复制命令
      document.execCommand("copy");
      // 移除 textarea 元素
      document.body.removeChild(textarea);
      this.successAlear = true;
    },
    cancel() {
      this.successAlear = false;
    },
    clearAll() {
      this.search_condition = {
        pageNum: 1,
        pageSize: 10,
        invitationCode: "",
        supplierId: "",
        brandId: "",
        codeType: "",
        status: "",
      };
      this.$refs.table.refresh();
    },
  },
};
</script>
