import {format} from "./time"
import axios from "../config/http.js"
import baseUrl from "../config/env.js"

// system log
async function systemList(params,ctx){
    // axios.fetchGet(baseUrl + '/supplier/list',params)
    return {
        success:true,
        data:[
                {
                    'content': 'xiangzi877@163.com',
                    'operator': '管理员',
                    'time': '2012-12-23 10:05:54',
                    'remarks': 'Pick_Pick'
                },
                {
                    'content': 'DG.Cloud',
                    'operator': '丁二牛',
                    'time': '2012-12-23 10:05:54',
                    'remarks': 'Pick_Pick'
                }
        ],
        total:2
    }
}
//server log
async function serverList(params,ctx){
    // axios.fetchGet(baseUrl + '/supplier/list',params)
    return {
        success:true,
        data:[
                {
                    'content': 'xiangzi877@163.com',
                    'operator': '管理员',
                    'time': '2012-12-23 10:05:54'
                },
                {
                    'content': 'DG.Cloud',
                    'operator': '丁二牛',
                    'time': '2012-12-23 10:05:54'
                }
        ],
        total:2
    }
}

export default {
    systemList,
    serverList
}
