/*
 * @Author: Code-HHX
 * @Date: 2021-12-10 11:07:33
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-03-28 15:12:52
 * @Description:
 */
exports.install = function(Vue, options) {
	Vue.prototype.$toast = function(res) {
		//全局函数 (根据接口返回状态提示)
		if (res.code == 200) {
			this.$bvToast.toast(res.message, {
				title: `Tips`,
				variant: 'success',
				solid: true,
				toaster: 'b-toaster-bottom-right',
			})
		} else if (res.code == 400) {
			this.$bvToast.toast(res.message, {
				title: `Tips`,
				variant: 'warning',
				solid: true,
				toaster: 'b-toaster-bottom-right',
			})
		} else {
			this.$bvToast.toast(res.message, {
				title: `Tips`,
				variant: 'danger',
				solid: true,
				toaster: 'b-toaster-bottom-right',
			})
		}
	}

	Vue.prototype.$toastBack = function(res) {
		//全局函数2 (返回上一页后根据接口返回状态提示)
		this.$router.go(-1)
		setTimeout(() => {
			this.$toast(res)
		}, 500)
	}

	Vue.prototype.$toastWarning = function(message) {
		//全局函数3 （警告状态提示）
		this.$bvToast.toast(message, {
			title: 'Tips',
			variant: 'warning',
			solid: true,
			toaster: 'b-toaster-bottom-right',
		})
	}
}
