import axios from "../config/http.js"
import baseUrl from "../config/env.js"


function listPage(params) {
    return axios.fetchGet(baseUrl + '/brandAdvertise/listPage', params)
}

function saveBrandAdvertise(params) {
    return axios.fetchPost(baseUrl + '/brandAdvertise/saveBrandAdvertise', params);
}

export default {
    listPage,
    saveBrandAdvertise,
}