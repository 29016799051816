var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Question Title:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "title"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "title", trim: "" },
                    model: {
                      value: _vm.form.title,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Question category:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "categoryId"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "categoryId",
                      options: _vm.types.questionCategorys,
                      "value-field": "id",
                      "text-field": "name"
                    },
                    model: {
                      value: _vm.form.categoryId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "categoryId", $$v)
                      },
                      expression: "form.categoryId"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Model:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "modelId"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "modelId",
                      options: _vm.types.modelIds,
                      "value-field": "id",
                      "text-field": "name"
                    },
                    model: {
                      value: _vm.form.modelId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "modelId", $$v)
                      },
                      expression: "form.modelId"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "frontCover:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "frontCover"
                  }
                },
                [
                  _c("input", {
                    staticClass: "filestyle",
                    staticStyle: {
                      position: "absolute",
                      clip: "rect(0px, 0px, 0px, 0px)"
                    },
                    attrs: {
                      type: "file",
                      "data-input": "false",
                      id: "filestyle-1",
                      tabindex: "-1"
                    },
                    on: {
                      change: function($event) {
                        return _vm.upload($event)
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "bootstrap-filestyle input-group" },
                    [
                      _c("div", {
                        staticStyle: {
                          position: "absolute",
                          width: "100%",
                          height: "35px",
                          "z-index": "-1"
                        },
                        attrs: { name: "filedrag" }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "group-span-filestyle",
                          attrs: { tabindex: "0" }
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "btn btn-secondary",
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { for: "filestyle-1" }
                            },
                            [
                              _c("span", { staticClass: "buttonText" }, [
                                _vm._v(" upload picture ")
                              ])
                            ]
                          ),
                          _c("span", [
                            _vm._v(
                              " Recommended size of picture: 512 pixels * 512 pixels, supporting JPG and PNG formats, within 2m. "
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Answer:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right"
                  }
                },
                [
                  _c("ckeditor", {
                    attrs: { editor: _vm.editor },
                    on: { ready: _vm.onReady },
                    model: {
                      value: _vm.form.content,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "2" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-l-5 ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("goback")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }