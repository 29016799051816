<!-- 客服弹窗组件 -->
<template>
  <b-modal
    id="modal"
    v-model="showAssistant"
    centered
    title="Assistant"
    ok-title="Confirm"
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="chat-conversation">
            <ul
              class="conversation-list"
              data-simplebar="init"
              style="max-height: 367px"
            >
              <div class="simplebar-wrapper" style="margin: 0px -10px">
                <div class="simplebar-height-auto-observer-wrapper">
                  <div class="simplebar-height-auto-observer"></div>
                </div>
                <div class="simplebar-mask">
                  <div
                    class="simplebar-offset"
                    style="right: -17px; bottom: 0px"
                  >
                    <div
                      class="simplebar-content-wrapper"
                      style="height: auto; overflow: hidden scroll"
                    >
                      <div class="simplebar-content" style="padding: 0px 10px">
                        <li class="clearfix">
                          <div class="chat-avatar">
                            <img
                              src="https://themesbrand.com/veltrix/layouts/assets/images/users/user-2.jpg"
                              class="avatar-xs rounded-circle"
                              alt="男性"
                            />
                            <span class="time"
                              ><font style="vertical-align: inherit"
                                ><font style="vertical-align: inherit"
                                  >10:00</font
                                ></font
                              ></span
                            >
                          </div>
                          <div class="conversation-text">
                            <div class="ctext-wrap">
                              <span class="user-name"
                                ><font style="vertical-align: inherit"
                                  ><font style="vertical-align: inherit"
                                    >约翰·迪奥</font
                                  ></font
                                ></span
                              >
                              <p>
                                <font style="vertical-align: inherit"
                                  ><font style="vertical-align: inherit">
                                    你好！
                                  </font></font
                                >
                              </p>
                            </div>
                          </div>
                        </li>
                        <li class="clearfix odd">
                          <div class="chat-avatar">
                            <img
                              src="https://themesbrand.com/veltrix/layouts/assets/images/users/user-3.jpg"
                              class="avatar-xs rounded-circle"
                              alt="女性"
                            />
                            <span class="time"
                              ><font style="vertical-align: inherit"
                                ><font style="vertical-align: inherit"
                                  >10:01</font
                                ></font
                              ></span
                            >
                          </div>
                          <div class="conversation-text">
                            <div class="ctext-wrap">
                              <span class="user-name"
                                ><font style="vertical-align: inherit"
                                  ><font style="vertical-align: inherit"
                                    >史密斯</font
                                  ></font
                                ></span
                              >
                              <p>
                                <font style="vertical-align: inherit"
                                  ><font style="vertical-align: inherit">
                                    你好你好吗？我们下次见面怎么样？
                                  </font></font
                                >
                              </p>
                            </div>
                          </div>
                        </li>
                        <li class="clearfix">
                          <div class="chat-avatar       ">
                            <img
                              src="https://themesbrand.com/veltrix/layouts/assets/images/users/user-2.jpg"
                              class="avatar-xs rounded-circle"
                              alt="男性"
                            />
                            <span class="time"
                              ><font style="vertical-align: inherit"
                                ><font style="vertical-align: inherit"
                                  >10:04</font
                                ></font
                              ></span
                            >
                          </div>
                          <div class="conversation-text">
                            <div class="ctext-wrap">
                              <span class="user-name"
                                ><font style="vertical-align: inherit"
                                  ><font style="vertical-align: inherit"
                                    >约翰·迪奥</font
                                  ></font
                                ></span
                              >
                              <p>
                                <font style="vertical-align: inherit"
                                  ><font style="vertical-align: inherit">
                                    是的，一切都很好  
                                  </font></font
                                >
                              </p>
                            </div>
                          </div>
                        </li>
                        <li class="clearfix odd">
                          <div class="chat-avatar">
                            <img
                              src="https://themesbrand.com/veltrix/layouts/assets/images/users/user-3.jpg"
                              class="avatar-xs rounded-circle"
                              alt="男性"
                            />
                            <span class="time"
                              ><font style="vertical-align: inherit"
                                ><font style="vertical-align: inherit"
                                  >10:05</font
                                ></font
                              ></span
                            >
                          </div>
                          <div class="conversation-text">
                            <div class="ctext-wrap">
                              <span class="user-name"
                                ><font style="vertical-align: inherit"
                                  ><font style="vertical-align: inherit"
                                    >史密斯</font
                                  ></font
                                ></span
                              >
                              <p>
                                <font style="vertical-align: inherit"
                                  ><font style="vertical-align: inherit">
                                    哇，太棒了
                                  </font></font
                                >
                              </p>
                            </div>
                          </div>
                        </li>
                        <li class="clearfix odd">
                          <div class="chat-avatar">
                            <img
                              src="https://themesbrand.com/veltrix/layouts/assets/images/users/user-3.jpg"
                              class="avatar-xs rounded-circle"
                              alt="男性"
                            />
                            <span class="time"
                              ><font style="vertical-align: inherit"
                                ><font style="vertical-align: inherit"
                                  >10:08</font
                                ></font
                              ></span
                            >
                          </div>
                          <div class="conversation-text">
                            <div class="ctext-wrap">
                              <span class="user-name mb-2"
                                ><font style="vertical-align: inherit"
                                  ><font style="vertical-align: inherit"
                                    >史密斯</font
                                  ></font
                                ></span
                              >

                              <img
                                src="https://themesbrand.com/veltrix/layouts/assets/images/small/img-1.jpg"
                                alt=""
                                height="48"
                                class="rounded me-2"
                              />
                              <img
                                src="https://themesbrand.com/veltrix/assets/images/small/img-2.jpg"
                                alt=""
                                height="48"
                                class="rounded"
                              />
                            </div>
                          </div>
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="simplebar-placeholder"
                  style="width: auto; height: 480px"
                ></div>
              </div>
              <div
                class="simplebar-track simplebar-horizontal"
                style="visibility: hidden"
              >
                <div
                  class="simplebar-scrollbar"
                  style="transform: translate3d(0px, 0px, 0px); display: none"
                ></div>
              </div>
              <div
                class="simplebar-track simplebar-vertical"
                style="visibility: visible"
              >
                <div
                  class="simplebar-scrollbar"
                  style="
                    height: 280px;
                    transform: translate3d(0px, 87px, 0px);
                    display: block;
                  "
                ></div>
              </div>
            </ul>
            <div class="row">
              <div class="col-sm-9 col-8 chat-inputbar">
                <input
                  type="text"
                  class="form-control chat-input"
                  placeholder="输入您的文字"
                />
              </div>
              <div class="col-sm-3 col-4 chat-send">
                <div class="d-grid">
                  <button type="submit" class="btn btn-success">
                    <font style="vertical-align: inherit"
                      ><font style="vertical-align: inherit">发送</font></font
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <template v-slot:modal-footer>
      <div class="w-100"></div>
    </template>
  </b-modal>
</template>
<script>
import { dropzoneOptions } from "@/api/upload";

export default {
  name: "CreateNewPlant",
  data() {
    return {
      dropzoneOptions: dropzoneOptions,
      submit: false,
      typesubmit: false,
    };
  },
  props: {
    showAssistant: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
     
    },
  },
};
</script>