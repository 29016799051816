<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />
    <b-card v-if="productDetail.id">
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Public Info</h4>
          </div>
        </b-col>
      </b-row>
      <div class="productDetailRow" style="margin-left: 40px">
        <!-- 左边 -->
        <div>
          <div class="productDetailBorder">
            <img :src="productDetail.deviceImage" />
          </div>
          <div class="productDetailImageRow">
            <img
              :src="item.value"
              v-for="(item, key) in productDetail.productPicture"
              :key="key"
            />
          </div>
        </div>
        <!-- 右边 -->
        <div class="productDetailContent">
          <p style="font-size: 18px; color: #3eb1c8; font-weight: 700">
            {{ productDetail.productSeriesName }}
          </p>
          <p
            style="
              font-size: 13px;
              color: #aaaaaa;
              font-family: 'Arial Normal', 'Arial', sans-serif;
            "
          >
            {{ productDetail.remarks }}
          </p>
          <p>
            <strong class="productDetailContentColor">Product Model #:</strong>
            {{ productDetail.modelName }}
          </p>
          <p>
            <strong class="productDetailContentColor">Variant Code #:</strong>
            {{ productDetail.variantCode }}
          </p>
          <p>
            <strong class="productDetailContentColor">Marketing Name:</strong>
            {{ productDetail.deviceName }}
          </p>
          <p>
            <strong class="productDetailContentColor">Business Type:</strong>
            {{
              productDetail.oilType == 2 ? "CBD/THC Concentrates" : "Nicotine"
            }}
          </p>
          <p>
            <strong class="productDetailContentColor">Product Type:</strong>
            {{ productDetail.type == 3 ? "Disposable" : "Batteries" }}
          </p>
          <p>
            <strong class="productDetailContentColor"
              >Available Compatibility:</strong
            >
            {{
              productDetail.availableCompatibilityId == 1
                ? "OMNI NFC"
                : "OMNI Hub"
            }}
          </p>
          <p>
            <strong class="productDetailContentColor"
              >Available Tank Size:</strong
            >
            {{ productDetail.taskCapacityValue }}
          </p>
        </div>
      </div>
    </b-card>

    <b-form @submit="submitBrand" @reset="onReset">
      <b-card>
        <b-row class="align-items-center">
          <b-col lg="12">
            <div class="blockquote">
              <h4>Your Business Info</h4>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="10">
            <!-- 品牌 -->
            <form-row>
              <b-form-group
                label="Your Brand:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="brand"
                label-class="requiredRow"
                class="color"
              >
                <b-form-select
                  id="brand"
                  v-model="form.brandId"
                  :options="types.brandList"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                ></b-form-select>
              </b-form-group>
            </form-row>

            <!-- 产品代号 -->
            <form-row>
              <b-form-group
                label="Your Product Model #:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="productSeriesName"
                label-class="requiredRow"
                class="color"
              >
                <b-form-input
                  id="productSeriesName"
                  v-model="form.modelName"
                  trim
                  required
                  maxlength="50"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                  placeholder="Please enter"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.modelName ? form.modelName.length : 0"
                  max="50"
                />
              </template>
            </form-row>
            <!-- 营销名称 -->
            <form-row>
              <b-form-group
                label="Marketing Name:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="marketingName"
                label-class="requiredRow"
                class="color"
              >
                <b-form-input
                  id="marketingName"
                  v-model="form.deviceName"
                  trim
                  required
                  maxlength="50"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                  placeholder="Please enter"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.deviceName ? form.deviceName.length : 0"
                  max="50"
                />
              </template>
            </form-row>
            <!-- 描述 -->
            <form-row>
              <b-form-group
                label="Your Product Introduction:"
                label-cols-lg="3"
                label-align-sm="right"
                label-for="productSeriesIntroductio"
                label-class="requiredRow"
                class="color"
              >
                <b-form-textarea
                  id="remarks"
                  v-model="form.remarks"
                  placeholder="Please enter"
                  rows="6"
                  maxlength="1000"
                  max-rows="6"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                ></b-form-textarea>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.remarks ? form.remarks.length : 0"
                  max="1000"
                />
              </template>
            </form-row>
            <!-- 状态 -->
            <form-row>
              <b-form-group
                label="Your Product Status:"
                label-cols-lg="3"
                label-align-lg="right"
                label-class="requiredRow"
                label-for="productStatus"
                class="color"
              >
                <b-form-radio
                  class="custom-control-inline pt-2"
                  v-model="form.status"
                  value="0"
                  required
                  >Enable
                </b-form-radio>
                <b-form-radio
                  class="custom-control-inline pt-2"
                  v-model="form.status"
                  value="1"
                  required
                  >Disable
                </b-form-radio>
              </b-form-group>
            </form-row>
          </b-col>
        </b-row>
      </b-card>
      <b-form-group label-cols-lg="2">
        <b-button
          type="submit"
          class="custom-width"
          variant="primary"
          @click="addBrandProduct(1)"
          >Save
        </b-button>
        <b-button
          type="submit"
          class="ml-5"
          variant="primary"
          @click="addBrandProduct(2)"
          >Save & Add Flavor
        </b-button>
        <b-button type="reset" class="ml-5 custom-width" @click="$router.back()"
          >Cancel
        </b-button>
      </b-form-group>
    </b-form>

    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel"
      @hide="hide"
    >
      Saved successfully
    </Dialog>
    <!-- 错误提示框 -->
    <Dialog
      v-model="errorAlear"
      :imageSrc="require('@/assets/confirm.svg')"
      noBtn="OK"
      @cancel="cancel1"
      @hide="hide1"
    >
      {{ errorContent }}
    </Dialog>
  </Layout>
</template>
<style scoped>
.productDetailBorder {
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.productDetailRow {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}
.productDetailImageRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5px;
  height: 125px;
}

.modal-header {
  display: none;
}
.modal-footer {
  display: none;
}
.productDetailContent {
  margin-left: 40px;
  width: 400px;
}
.productDetailContentColor {
  color: #333333;
}
.productDetailColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.productInfo {
  width: 200px;
  text-align: right;
  color: #333333;
}

.productInfoContent {
  text-align: left;
  flex: 1;
  margin-left: 5px;
}
.pd {
  padding-bottom: 10px;
}

.overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.color {
  color: #333333;
}
</style>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img, upload_multiple_file } from "@/api/upload";
import goback from "@/components/goback";
import FormRow from "@/components/FormRow.vue";
import ImageComponets from "@/components/brand/ImageComponets.vue";
import CountText from "@/components/CountText.vue";
import Dialog from "@/components/Dialog";
/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    goback,
    FormRow,
    ImageComponets,
    CountText,
    Dialog,
  },
  data() {
    return {
      page: {
        title: "Modify SKU",
        items: [
          {
            text: this.$store.getters.getTitle,
          },
          {
            text: "Client&Brand",
          },
          {
            text: "Brand Product Mgmt",
          },
          {
            text: "Modify SKU",
            active: true,
          },
        ],
      },
      form: {
        id: this.$route.query.id,
        modeName: "",
        deviceName: "",
        remarks: "",
        status: 0,
        brandId: this.$route.query.brandId,
        brandSeriesId: this.$route.query.brandSeriesId,
      },
      types: {
        brandList: [],
      },
      productDetail: {},
      successAlear: false,
      errorAlear: false,
      errorContent: "",
      alertType: 1,
      addReturnId: 0,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    onReady: onReady,
    init() {
      if (this.$route.query.id) {
        this.selectBrandProductById();
      } else {
        this.selectOmniProductById();
      }
      this.selectBrnadList();
    },
    selectBrandProductById() {
      this.$api.CartridgeModel.selectBrandProductById({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.success) {
          this.productDetail = res.data;
          var brandProduct = res.data.brandProduct;
          this.form = {
            id: this.$route.query.id,
            modelName: brandProduct.modelName,
            deviceName: brandProduct.deviceName,
            remarks: brandProduct.remarks,
            status: brandProduct.status,
            brandId: this.$route.query.brandId,
            brandSeriesId: this.$route.query.brandSeriesId,
          };
          console.log(this.form);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    selectOmniProductById() {
      this.$api.CartridgeModel.selectOmniProductById({
        id: this.$route.query.brandSeriesId,
      }).then((res) => {
        if (res.success) {
          this.productDetail = res.data;
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    selectBrnadList() {
      this.$api.Dropdown.selectAllBrand({}).then((res) => {
        if (res.success) {
          this.types.brandList = res.data.map((item) => {
            return {
              text: item.brandName,
              value: item.id,
              disabled: false,
            };
          });
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    submitBrand(evt) {
      evt.preventDefault();
      if (this.$route.query.id) {
        //修改品牌产品
        this.$api.CartridgeModel.updateBrandProduct(this.form).then((res) => {
          if (res.success) {
            this.successAlear = true;
          } else {
            if (res.message) {
              this.errorAlear = true;
              this.errorContent = res.message;
            }
          }
        });
      } else {
        //添加品牌产品
        this.$api.CartridgeModel.saveBrandProduct(this.form).then((res) => {
          if (res.success) {
            this.addReturnId = res.data;
            this.successAlear = true;
          } else {
            if (res.message) {
              this.errorAlear = true;
              this.errorContent = res.message;
            }
          }
        });
      }
    },
    addBrandProduct(type) {
      this.alertType = type;
    },
    onReset(evt) {
      evt.preventDefault();
      this.$nextTick(() => {
        this.$router.go(-1);
      });
    },
    cancel() {
      if (this.alertType == 1) {
        this.$router.go(-1);
      } else {
        var brandSeriesId = this.$route.query.id;
        if (!this.$route.query.id) {
          brandSeriesId = this.addReturnId;
        }
        this.$router.push(
          "flavorMgmtAdd?brandId=" +
            this.$route.query.brandId +
            "&brandSeriesId=" +
            brandSeriesId
        );
      }
    },
    hide() {
      if (this.alertType == 1) {
        this.$router.go(-1);
      } else {
        var brandSeriesId = this.$route.query.id;
        if (!this.$route.query.id) {
          brandSeriesId = this.addReturnId;
        }
        this.$router.push(
          "flavorMgmtAdd?brandId=" +
            this.$route.query.brandId +
            "&brandSeriesId=" +
            brandSeriesId
        );
      }
    },
    cancel1() {
      this.errorAlear = false;
    },
    hide1() {
      this.errorAlear = false;
    },
  },
};
</script>
