import axios from "../config/http.js"
import axios1 from '../config/http-json.js'
import baseUrl from "../config/env.js"


function selectBrandBlogList(params) {
    return axios.fetchGet(baseUrl + '/brandBlog/selectBrandBlogList', params)
}

function saveBrandBlog(params) {
    return axios1.fetchPost(baseUrl + '/brandBlog/saveBrandBlog', params);
}

function updateBrandBlog(params) {
    return axios1.fetchPost(baseUrl + '/brandBlog/updateBrandBlog', params);
}

function selectBrandBlogById(params) {
    return axios.fetchGet(baseUrl + '/brandBlog/selectBrandBlogById', params)
}

function enableOrDisableById(params) {
    return axios1.fetchPost(baseUrl + '/brandBlog/enableOrDisableById', params);
}
export default {
    selectBrandBlogList,
    saveBrandBlog,
    updateBrandBlog,
    selectBrandBlogById,
    enableOrDisableById
}