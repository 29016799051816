import Vue from "vue";
import Vuex from "vuex";

import modules from "./modules";

Vue.use(Vuex);

/**
 * 状态管理
 */
const store = new Vuex.Store({
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
  state: {
    // 存放状态
    menu: [],
    basicDataList: [
      // {
      //   text: "Set Region",
      //   value: "/dataDictionary/regionAdd",
      //   explain:
      //     "Regional setting of filling manufacturers for production of cartridges",
      //   indeterminate: false,
      // },
      {
        text: "Set Manufacturer",
        value: "/dataDictionary/manufacturerAdd",
        explain: "Setting up your cartridge content filling manufacturer",
        indeterminate: false,
      },
      {
        text: "Set Solvent",
        value: "/dataDictionary/solventAdd",
        explain: "Create the cartridge content solvent",
        indeterminate: false,
      },
      {
        text: "Set Ingredient",
        value: "/dataDictionary/ingredientAdd",
        explain: "Create the cartridge content ingredient information",
        indeterminate: false,
      },
      {
        text: "Set Brand",
        value: "/dataDictionary/consumableBrandsAdd",
        explain:
          "Create the brand of cartridge content, set the brand logo and other information",
        indeterminate: false,
      },
    ],
    flavorInfoList: [
      {
        text: "Set Order Status",
        value: "/deviceMgmt/orderStatusAdd",
        explain:
          "Apply for purchase of empty Cartridges, wait for review and delivery after application for purchase",
        indeterminate: false,
      },
      {
        text: "Set Preheat Mode",
        value: "/dataDictionary/preheatModeAdd",
        explain:
          "When your cartridge content is thick, you need to preheat it. Create the temperature in the pre-heating mode",
        indeterminate: false,
      },
      {
        text: "Set Heating Mode",
        value: "/dataDictionary/heatingModeAdd",
        explain:
          "Create a mode for heating the cartridge content. Set up 6 temperature curve modes",
        indeterminate: false,
      },
      {
        text: "Set Flavor",
        value: "/productionMgmt/flavorAdd",
        explain: "Create your Flavor",
        indeterminate: false,
      },
      {
        text: "Flavor List",
        value: "/productionMgmt/flavor",
        explain: "Set the preheating and heating modes for the created flavor",
        indeterminate: false,
      },
    ],
    productionProcessList: [
      {
        text: "Set Cartridge Filling",
        value: "/productionMgmt/cartridgeFillingCreate",
        explain: "Create a production batch for the cartridge content",
        indeterminate: false,
      },
      {
        text: "Cartridge Filling",
        value: "/productionMgmt/cartridgeFilling",
        explain:
          "Please complete the production process through the Dev App+ Production Kit",
        indeterminate: false,
      },
      {
        text: "Perfect Flavor Details",
        value: "/productionMgmt/cartridgeFilling",
        explain:
          "After the completion of the production, improve the composition of the cartridge content and certification report and other information",
        indeterminate: false,
      },
    ],
    viewList: [
      {
        text: "User",
        value: "/userMgmt/userAccounts",
        explain:
          "Find your users in the user list, click the details to view the user basic information, equipment information, smoke ammunition information, use records, and so on",
        indeterminate: false,
      },
      {
        text: "Device",
        value: "/deviceMgmt/deviceList",
        explain: "View the device and user bindings under the brand",
        indeterminate: false,
      },
      {
        text: "Cartridge Usage",
        value: "/deviceMgmt/usageRecord",
        explain:
          "View the brand Cartridge information and use data, status and so on",
        indeterminate: false,
      },
      {
        text: "User statistic",
        value: "/consumptionReport/userAnalysis",
        explain: "View user statistics, batch usage status report",
        indeterminate: false,
      },
    ],
    isGuide: 0,
  },
  getters: {
    // state的计算属性
    isIkrusher() {
      if (window.location.host == "portal.ikrusher.com" || window.location.host == "portal.omniapp.us" || window.location.host == "testadmin.unicoreus.com" || window.location.host == "192.168.254.151:8084") {
        return true;
      } else {
        return false;
      }
    },
    isUnicoreus() {
      if (window.location.host == "admin.unicoreus.com" || window.location.host == "localhost:8084") {
        return true;
      } else {
        return false;
      }
    },
    getTitle(){
      if (window.location.host == "portal.ikrusher.com" || window.location.host == "portal.omniapp.us" || window.location.host == "testadmin.unicoreus.com" || window.location.host == "192.168.254.151:8084") {
        return "Omni"
      }else{
        return "UNI-CORE";
      }
    }
  },
  mutations: {
    // 更改state中状态的逻辑，同步操作
    setMenu(state, value) {
      state.menu = value;
    },
    setBasicDataList(state, index) {
      state.basicDataList[index].indeterminate = true;
    },
    setFlavorInfoList(state, index) {
      state.flavorInfoList[index].indeterminate = true;
    },
    setProductionProcessList(state, index) {
      state.productionProcessList[index].indeterminate = true;
    },
    setViewList(state, index) {
      state.viewList[index].indeterminate = true;
    },
    setIsGuide(state, value) {
      state.isGuide = value;
    },
  },
  actions: {
    // 提交mutation，异步操作
  },
  // 如果将store分成一个个的模块的话，则需要用到modules。
  //然后在每一个module中写state, getters, mutations, actions等。
  modules,
});

export default store;
