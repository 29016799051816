/*
 * @Author: Code-HHX
 * @Date: 2021-12-10 11:07:33
 * @LastEditors: Code-HHX
 * @LastEditTime: 2021-12-14 17:39:34
 * @Description:
 */
import { format } from './time'
import axios from '../config/http.js'
import baseUrl from '../config/env.js'

async function list(params, ctx) {
	console.log(params, ctx)
	// params.createTime=format(params.createTime)
	return axios.fetchGet(
		baseUrl + '/smokeRecord/smokeUsageInformationList',
		params
	)
}

async function find_usage(params, ctx) {
	axios.fetchGet(baseUrl + '/device_usage/list', params)
	return {
		success: true,
		data: [
			{
				deviceNumber: '20201565',
				deviceCoding: '21-25-25',
				deviceLocation: 'USA',
				activationTime: '2012-12-23 10:05:54',
				duration: 10,
			},
		],
		total: 1,
	}
}

function add(params) {
	return axios.fetchPost(baseUrl + '/device/add', params)
}

function find(params) {
	return axios.fetchGet(baseUrl + '/device/findById', params)
}

function update(params) {
	return axios.fetchPost(baseUrl + '/device/updateById', params)
}

function del(params) {
	return axios.fetchPost(baseUrl + '/device/deleteById', params)
}

export default {
	list,
	find_usage,
	find,
	add,
	update,
	del,
}
