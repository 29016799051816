var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "8" } },
                [
                  _vm.show
                    ? _c(
                        "b-form",
                        { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Tags Name:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "tags-name",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "tags-name",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                                  placeholder: "Please enter"
                                },
                                model: {
                                  value: _vm.form.labelName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "labelName", $$v)
                                  },
                                  expression: "form.labelName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Notes:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "remarks"
                              }
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "remarks",
                                  placeholder: "Enter Notes",
                                  rows: "3",
                                  "max-rows": "6"
                                },
                                model: {
                                  value: _vm.form.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "remarks", $$v)
                                  },
                                  expression: "form.remarks"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            { attrs: { "label-cols-lg": "3" } },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { type: "submit", variant: "primary" }
                                },
                                [_vm._v("Confirm")]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "m-l-5 ml-1",
                                  attrs: { type: "reset" }
                                },
                                [_vm._v("Cancel")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }