var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "4" } },
                [
                  _c(
                    "b-form",
                    {
                      attrs: { action: "#" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.typeForm($event)
                        },
                        reset: _vm.reset_form
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Subject Name",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "subject-name",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              id: "subject-name",
                              placeholder: "Please enter",
                              trim: "",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput:
                                "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                            },
                            model: {
                              value: _vm.typeform.subjectName,
                              callback: function($$v) {
                                _vm.$set(_vm.typeform, "subjectName", $$v)
                              },
                              expression: "typeform.subjectName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Brand Notes",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "brand-remark"
                          }
                        },
                        [
                          _c("b-form-textarea", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              id: "remark",
                              placeholder: "Enter Notes",
                              rows: "3",
                              "max-rows": "6"
                            },
                            model: {
                              value: _vm.typeform.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.typeform, "remarks", $$v)
                              },
                              expression: "typeform.remarks"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Theme Main Color:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "tags-color",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value: _vm.typeform.subjectContent.themeMainColor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent,
                                  "themeMainColor",
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.themeMainColor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Drop Down Active Color:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "tags-color",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.typeform.subjectContent.dropDownActiveColor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent,
                                  "dropDownActiveColor",
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.dropDownActiveColor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Sync Button Color:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "tags-color",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.typeform.subjectContent.syncButtonColor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent,
                                  "syncButtonColor",
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.syncButtonColor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Curve Color:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "tags-color",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value: _vm.typeform.subjectContent.curveColor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent,
                                  "curveColor",
                                  $$v
                                )
                              },
                              expression: "typeform.subjectContent.curveColor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "CurveLine Color:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "tags-color",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.typeform.subjectContent.curveLineColor[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent.curveLineColor,
                                  0,
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.curveLineColor[0]"
                            }
                          }),
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.typeform.subjectContent.curveLineColor[1],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent.curveLineColor,
                                  1,
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.curveLineColor[1]"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Mode Normal Background:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "tags-color",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.typeform.subjectContent
                                  .modeNormalBackground,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent,
                                  "modeNormalBackground",
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.modeNormalBackground"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Mode Normal Text Color:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "tags-color",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.typeform.subjectContent.modeNormalTextColor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent,
                                  "modeNormalTextColor",
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.modeNormalTextColor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Mode Active Background:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "tags-color",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.typeform.subjectContent
                                  .modeActiveBackground,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent,
                                  "modeActiveBackground",
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.modeActiveBackground"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Mode Active Text Color:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "tags-color",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.typeform.subjectContent.modeActiveTextColor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent,
                                  "modeActiveTextColor",
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.modeActiveTextColor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Percent Ring Unused Color:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "tags-color",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.typeform.subjectContent
                                  .percentRingUnusedColor[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent
                                    .percentRingUnusedColor,
                                  0,
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.percentRingUnusedColor[0]"
                            }
                          }),
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.typeform.subjectContent
                                  .percentRingUnusedColor[1],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent
                                    .percentRingUnusedColor,
                                  1,
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.percentRingUnusedColor[1]"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Percent Ring Use Color:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "tags-color",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.typeform.subjectContent.percentRingUseColor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent,
                                  "percentRingUseColor",
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.percentRingUseColor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Percent Ring Text Color:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "tags-color",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.typeform.subjectContent
                                  .percentRingTextColor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent,
                                  "percentRingTextColor",
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.percentRingTextColor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Cancel Button Color:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "tags-color",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.typeform.subjectContent.cancelButtonColor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent,
                                  "cancelButtonColor",
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.cancelButtonColor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "TabButton Active Color:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "tags-color",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.typeform.subjectContent
                                  .tabButtonActiveColor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent,
                                  "tabButtonActiveColor",
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.tabButtonActiveColor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Tab Button Inactive Color:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "tags-color",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("el-color-picker", {
                            model: {
                              value:
                                _vm.typeform.subjectContent
                                  .tabButtonInactiveColor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.typeform.subjectContent,
                                  "tabButtonInactiveColor",
                                  $$v
                                )
                              },
                              expression:
                                "typeform.subjectContent.tabButtonInactiveColor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        { attrs: { "label-cols-lg": "3" } },
                        [
                          _c(
                            "b-button",
                            { attrs: { type: "submit", variant: "primary" } },
                            [_vm._v("Confirm")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "m-l-5 ml-1",
                              attrs: { type: "reset" }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                {
                  style: Object.assign({}, _vm.themeColor),
                  attrs: { lg: "20" }
                },
                [
                  _c("div", { attrs: { width: "360px" } }, [
                    _c("div", { staticClass: "text-primary text-center" }, [
                      _c("img", {
                        attrs: {
                          src:
                            "http://192.168.254.151:8038/static/files/1702868805456.png",
                          alt: "",
                          height: "100%",
                          width: "100%"
                        }
                      })
                    ]),
                    _c(
                      "div",
                      { on: { click: _vm.onCurveClick } },
                      [
                        _c("HistoryCurve", {
                          class: _vm.elementItemClick["curve"] ? "select" : "",
                          attrs: {
                            power: _vm.power,
                            "max-power": _vm.maxPower,
                            resistance: _vm.resistance,
                            "curve-line-color":
                              _vm.typeform.subjectContent.curveLineColor,
                            "curve-color":
                              _vm.typeform.subjectContent.curveColor,
                            width: "360px",
                            height: "200px"
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { on: { click: _vm.onSliderClick } }, [
                      _c(
                        "div",
                        { staticClass: "voltage-one" },
                        _vm._l(_vm.diyVoltage, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "voltage-number" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (
                                      (item.value * item.value) /
                                      1000000 /
                                      1.2
                                    ).toFixed(2)
                                  ) +
                                  " w "
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "voltage-two",
                          class: _vm.elementItemClick["slider"] ? "select" : "",
                          staticStyle: { width: "100%" }
                        },
                        _vm._l(_vm.diyVoltage, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "voltage-list" },
                            [
                              _c("i", {
                                staticClass: "el-icon-plus voltage-plus"
                              }),
                              _c("el-slider", {
                                attrs: {
                                  vertical: "",
                                  step: 100,
                                  disabled: "",
                                  min: _vm.limitMinVoltage,
                                  max: _vm.limitMaxVoltage,
                                  height: "80px"
                                },
                                model: {
                                  value: item.value,
                                  callback: function($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value"
                                }
                              }),
                              _c("i", {
                                staticClass: "el-icon-minus voltage-reduce"
                              })
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "voltage-three" },
                        _vm._l(8, function(index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "voltage-number" },
                            [_vm._v(" " + _vm._s(index) + "s ")]
                          )
                        }),
                        0
                      )
                    ]),
                    _c("div", { staticStyle: { "margin-top": "16px" } }, [
                      _c(
                        "div",
                        { staticClass: "custom-curve" },
                        _vm._l(_vm.topModeList, function(mode, index) {
                          return _c(
                            "el-button",
                            {
                              key: mode.id,
                              staticClass: "custom-item",
                              class: [
                                index === 0 ? "recommended-button-active" : "",
                                index === 0
                                  ? _vm.elementItemClick["activeButton"]
                                    ? "select"
                                    : ""
                                  : _vm.elementItemClick["inactiveButton"]
                                  ? "select"
                                  : ""
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.onRecommendButtonClick(index)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(mode.modeName) + " ")]
                          )
                        }),
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "custom-curve" },
                        _vm._l(_vm.bottomModeList, function(mode, index) {
                          return _c(
                            "el-button",
                            {
                              key: mode.id,
                              staticClass: "custom-item",
                              class: _vm.elementItemClick["inactiveButton"]
                                ? "select"
                                : ""
                            },
                            [_vm._v(" " + _vm._s(mode.modeName) + " ")]
                          )
                        }),
                        1
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "percent-ring",
                        class: _vm.elementItemClick["percentRing"]
                          ? "select"
                          : "",
                        on: { click: _vm.onPercentRingClick }
                      },
                      [
                        _c("PercentRing", {
                          attrs: {
                            "unused-color":
                              _vm.typeform.subjectContent
                                .percentRingUnusedColor,
                            "use-color":
                              _vm.typeform.subjectContent.percentRingUseColor,
                            "text-color":
                              _vm.typeform.subjectContent.percentRingTextColor
                          }
                        }),
                        _c("PercentRing", {
                          attrs: {
                            "unused-color":
                              _vm.typeform.subjectContent
                                .percentRingUnusedColor,
                            "use-color":
                              _vm.typeform.subjectContent.percentRingUseColor,
                            "text-color":
                              _vm.typeform.subjectContent.percentRingTextColor
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class: _vm.elementItemClick["cancelButton"]
                          ? "select"
                          : "",
                        on: { click: _vm.onCancelButtonClick }
                      },
                      [
                        _c("el-button", { staticClass: "cancel-button" }, [
                          _vm._v("Cancel")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class: _vm.elementItemClick["syncButton"]
                          ? "select"
                          : "",
                        on: { click: _vm.onSyncButtonClick }
                      },
                      [
                        _c("el-button", { staticClass: "sync-button" }, [
                          _vm._v("Sync to device")
                        ])
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }