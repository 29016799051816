import { format } from "./time"
import axios from "../config/http.js"
import axios1 from "../config/http-json.js"
import baseUrl from "../config/env.js"

//查询规格
function getTankSize(params) {
    return axios.fetchGet(baseUrl + '/specifications/getTankSize', params)
}
//启用或禁用产品规则
function disableOrEnableById(params) {
    return axios.fetchPost(baseUrl + '/specifications/disableOrEnableById', params)
}
//添加产品
function saveSpecifications(params) {
    return axios.fetchPost(baseUrl + '/specifications/saveSpecifications', params)
}
//查询所有成分
function getComponentEncoding(params) {
    return axios.fetchGet(baseUrl + '/specifications/getComponentEncoding', params)
}
//启用或禁用成分
function disableOrEnableByComponentId(params) {
    return axios.fetchPost(baseUrl + '/specifications/disableOrEnableByComponentId', params)
}
//添加成分
function saveComponentEncoding(params) {
    return axios.fetchPost(baseUrl + '/specifications/saveComponentEncoding', params)
}

//查询所有萜烯
function getTerpenes(params) {
    return axios.fetchGet(baseUrl + '/specifications/getTerpenes', params)
}
//启用或禁用萜烯
function disableOrEnableByTerpenesId(params) {
    return axios.fetchPost(baseUrl + '/specifications/disableOrEnableByTerpenesId', params)
}
//添加萜烯
function saveTerpenes(params) {
    return axios.fetchPost(baseUrl + '/specifications/saveTerpenes', params)
}

//查询所有标签
function getCommodityLabel(params) {
    return axios.fetchGet(baseUrl + '/specifications/getCommodityLabel', params)
}
//启用或禁用萜烯
function disableOrEnableByCommodityLabelId(params) {
    return axios.fetchPost(baseUrl + '/specifications/disableOrEnableByCommodityLabelId', params)
}
//添加萜烯
function saveCommodityLabel(params) {
    return axios.fetchPost(baseUrl + '/specifications/saveCommodityLabel', params)
}


export default {
    getTankSize,
    disableOrEnableById,
    saveSpecifications,
    getComponentEncoding,
    disableOrEnableByComponentId,
    saveComponentEncoding,
    getTerpenes,
    disableOrEnableByTerpenesId,
    saveTerpenes,
    getCommodityLabel,
    disableOrEnableByCommodityLabelId,
    saveCommodityLabel
}
