<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />
    <div class="poupol">
      <!-- 类型1为品牌弹出框 -->
      <div class="flavor">
        <b-card
          header="Brand Popup"
          header-text-variant="#333547"
          header-tag="header"
          header-bg-variant="#333547"
          title=""
          style="max-width: 23rem; height: 780px"
          footer="Brand Popup, the interface displays brand information, flavors and other information."
          footer-tag="footer"
          footer-bg-variant="#333547"
          footer-border-variant="#333547"
        >
          <template v-slot:header>
            <div class="header-div-flavor">
              <div class="header-div-div">Brand Popup</div>
              <b-button
                v-if="popupType != 1"
                class="header-div-button"
                @click="clickPopup(1)"
                >Unused</b-button
              >
              <b-button
                v-if="popupType == 1"
                class="header-div-button1"
                disabled
                >Using</b-button
              >
            </div>
          </template>

          <b-card-img
            src="https://app.unicoreus.com/supplement/static/files/popup-flavor.png"
            alt="Image"
            height="100%"
            bottom
          ></b-card-img>
        </b-card>
      </div>
      <!-- 类型4为品牌跳转URL -->
      <div class="flavor">
        <b-card
          header="Brand URL Popup"
          header-text-variant="#333547"
          header-tag="header"
          header-bg-variant="#333547"
          title=""
          style="max-width: 23rem; height: 780px"
          footer="Brand URL Popup,Jump to the URL corresponding to the brand."
          footer-tag="footer"
          footer-bg-variant="#333547"
          footer-border-variant="#333547"
        >
          <template v-slot:header>
            <div class="header-div-flavor">
              <div class="header-div-div">Brand URL Popup</div>
              <b-button
                v-if="popupType != 4"
                class="header-div-button"
                @click="clickPopup(4)"
                >Unused</b-button
              >
              <b-button
                v-if="popupType == 4"
                class="header-div-button1"
                disabled
                >Using</b-button
              >
            </div>
          </template>

          <b-card-img
            src="https://app.unicoreus.com/supplement/static/files/popup-brandUrl.jpg"
            alt="Image"
            bottom
            height="100%"
          ></b-card-img>
        </b-card>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * FAQ component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      page: {
        title: "Manage Your Subjtct",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "My Brands",
            // href: "/"
          },
          {
            text: "Manage Your Popup",
            active: true,
          },
        ],
      },
      form: {
        popupType: 0,
      },
      popupType: 1,
      supplierId: 84,
    };
  },
  computed: {},
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$api.popup.selectPopupDetails().then((res) => {
        if (res.success && null != res.data) {
          this.supplierId = res.data.supplierId;
          this.popupType = res.data.popupType;
        }
      });
    },
    clickPopup(type) {
      this.form.popupType = type;
      this.popupType = type;
      this.$api.popup.savePopup(this.form).then((res) => {
        if (!res.success) {
          this.$bvToast.toast(res.message);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.flavor {
  padding-top: 10px;
  display: inline-block;
  width: 24%;
}
.header-div-flavor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-div-div {
  font-weight: bold;
  font-size: 18px;
  color: black;
}
.header-div-button1 {
  background-color: #01bb02;
}
</style>
