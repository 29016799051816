var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { size: _vm.size, centered: "" },
      on: { hide: _vm.hide },
      model: {
        value: _vm.modalVisible,
        callback: function($$v) {
          _vm.modalVisible = $$v
        },
        expression: "modalVisible"
      }
    },
    [
      [
        _vm.showLogo
          ? _c("div", { staticClass: "imageRow" }, [
              _c("img", { attrs: { src: _vm.imageSrc, alt: "" } })
            ])
          : _vm._e(),
        _c("div", { staticClass: "contentRow" }, [_vm._t("default")], 2),
        _vm.customBtn
          ? _c(
              "div",
              { staticClass: "buttonRow" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "modal-btn",
                    attrs: { variant: "outline-secondary" },
                    on: { click: _vm.customEvent }
                  },
                  [_vm._v(" " + _vm._s(_vm.customBtn) + " ")]
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "buttonRow" },
          [
            _vm.okBtn
              ? _c(
                  "b-button",
                  {
                    staticClass: "modal-btn",
                    attrs: { variant: "info" },
                    on: { click: _vm.ok }
                  },
                  [_vm._v(" " + _vm._s(_vm.okBtn) + " ")]
                )
              : _vm._e(),
            _vm.noBtn
              ? _c(
                  "b-button",
                  {
                    staticClass: "modal-btn",
                    attrs: { variant: "outline-secondary" },
                    on: { click: _vm.customCancel }
                  },
                  [_vm._v(" " + _vm._s(_vm.noBtn) + " ")]
                )
              : _vm._e()
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }