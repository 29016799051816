<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <b-card>
          <b-row class="vo-items-center">
            <b-col lg="4">
              <div class="vo-row vo-items-center">
                <ReferenceBy label="REFINED BY" />
                <b-input
                  style="flex: 1; margin-left: 10px"
                  v-model="search_condition.productName"
                  placeholder="Search Flavor"
                />
              </div>
            </b-col>
          </b-row>
          <b-row style="margin-top: 4px">
            <b-col style="margin-left: 120px">
              <b-select
                v-model="search_condition.status"
                :options="statusOptions"
              />
            </b-col>
            <b-col>
              <b-select
                v-model="search_condition.brandName"
                :options="brandOptions"
              />
            </b-col>
            <b-col>
              <b-select
                v-model="search_condition.businessType"
                :options="oilType"
              />
            </b-col>
            <b-col>
              <b-select
                v-model="search_condition.productType"
                :options="productType"
              />
            </b-col>
            <b-col>
              <b-select
                v-model="search_condition.tankCapacity"
                :options="tankCapacityOptios"
              />
            </b-col>
            <b-col>
              <b-select
                v-model="search_condition.usageEffect"
                :options="usageEffectOptions"
              />
            </b-col>
            <b-col>
              <div class="vo-row vo-items-center vo-justify-between">
                <a href="#" @click="clearAll">Clear All</a>
                <b-button variant="primary" @click="search">Search</b-button>
              </div>
            </b-col>
          </b-row>
          <!-- table -->
          <div class="table-responsive mb-0 cursor">
            <div v-if="isBusy">
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </div>
            <b-row
              cols-sm="1"
              cols-md="2"
              cols-lg="3"
              cols-xl="4"
              style="margin: 0"
            >
              <b-col v-for="item in flavorList" :key="item.id" class="mt-4">
                <CreateProduct
                  v-if="item.viewType === 'add'"
                  @click="gotoAdd"
                  name="Create Flavor"
                />
                <FlavorItem
                  v-else
                  @clickView="clickView(item)"
                  @clickStatus="clickStatus(item)"
                  @clickEdit="clickEdit(item)"
                  :item="item"
                />
              </b-col>
            </b-row>

            <!--
                        <b-table
                            show-empty
                            empty-text="No records"
                            id="device-table"
                            head-variant="light"
                            ref="table"
                            :busy="isBusy"
                            :striped="true"
                            :items="provider"
                            :fields="fields"
                            :per-page="search_condition.pageSize"
                            :current-page="search_condition.pageNum"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            :filter-includedfields="filterOn"
                            @filtered="onFiltered"
                        >
                          <template v-slot:table-busy>
                            <div class="text-center text-danger my-2">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong>Loading...</strong>
                            </div>
                          </template>

                          <template v-slot:cell(index)="data">{{
                data.index +
                (search_condition.pageNum - 1) * search_condition.pageSize +
                1
            }}
          </template>

          <template v-slot:cell(brandPicture)="data">
            <img
                v-if="data.item.brandPicture"
                :src="`${data.item.brandPicture}`"
                alt
                class="avatar-xs rounded-circle mr-2"
            />
          </template>

          <template v-slot:cell(actions)="row">
            <b-button
                v-has="'brandManage::Modify'"
                v-if="row.item.supplierId > 0"
                size="sm"
                variant="primary"
                @click="edit(row.item, row.index, $event.target)"
                class="mr-1"
            >Modify
            </b-button>

            <b-button
                v-has="'brandManage::Details'"
                v-if="row.item.supplierId > 0"
                size="sm"
                variant="info"
                @click="onClickDetails(row.item, row.index, $event.target)"
                class="mr-1"
            >Details
            </b-button>

            <b-button
                v-if="row.item.supplierId > 0 && row.item.status == 0"
                v-has="'brandManage::Review'"
                size="sm"
                variant="warning"
                @click="onClickReview(row.item, row.index, $event.target)"
                class="mr-1"
            >
              Review
            </b-button>

            <b-button
                v-has="'brandManage::Delete'"
                v-if="row.item.supplierId > 0"
                size="sm"
                variant="danger"
                @click="onClickDelete(row.item, row.index, $event.target)"
                class="mr-1"
            >
              Delete
            </b-button>

            <b-button
                v-has="'brandManage::Down'"
                v-if="row.item.supplierId != 0"
                size="sm"
                variant="success"
                :disabled="dowmDisable"
                @click="down(row.item, row.index, $event.target)"
                class="mr-1"
            >Down link
            </b-button
            >
          </template>
        </b-table>

         -->
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data" class="mt-4">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <li>
                    <p
                      class="pagination mb-0"
                      style="margin-top: 7px; margin-right: 20px"
                    >
                      {{ rows }} results
                    </p>
                  </li>
                  <b-pagination
                    ref="page"
                    v-model="search_condition.pageNum"
                    :per-page="
                      btnMenu('flavorMgmt::Create') ||
                      (!isAccountLevel() && btnMenu('brandMgmt::Create'))
                        ? 7
                        : 8
                    "
                    :total-rows="rows"
                    @change="onPageChange"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->
    <Dialog
      v-model="showAlert"
      :imageSrc="require('@/assets/confirm.svg')"
      okBtn="Yes"
      noBtn="No"
      @ok="enableOrDisableFlavor"
      @cancel="cancel"
    >
      <p>{{ showContent }}</p>
    </Dialog>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel1"
    >
      <p>Saved successfully</p>
    </Dialog>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import FlavorItem from "@/components/flavor/FlavorItem.vue";
import ReferenceBy from "@/components/FindByLabel.vue";
import productDetail from "@/components/product/ProductDetail.vue";
import CreateProduct from "@/components/product/CreateProduct.vue";
import Dialog from "@/components/Dialog";

export default {
  components: {
    ReferenceBy,
    FlavorItem,
    Layout,
    PageHeader,
    Multiselect,
    timeZoneConversion,
    CreateProduct,
    Dialog,
  },
  data() {
    return {
      title: "Flavor Mgmt",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "Client&Brand",
          // href: "/"
        },
        {
          text: "Flavor Mgmt",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage:
        this.btnMenu("flavorMgmt::Create") ||
        (!this.isAccountLevel() && this.btnMenu("brandMgmt::Create"))
          ? 7
          : 8,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#" },
        { key: "modelName", label: "Flavor Name", class: "text-center" },
        // {
        //   key: "type",
        //   label: "Flavor Type",
        //   formatter: (value) => {
        //     return JSON.parse(value).typeName;
        //   },
        //   class: "text-center",
        // },
        // { key: "flavor", label: "Flavor", class: "text-center" },
        { key: "brandName", label: "Brand Name", class: "text-center" },
        { key: "flavorPicture", label: "Flavor Picture", class: "text-center" },
        { key: "remarks", label: "Notes", class: "text-center" },
        {
          key: "createTime",
          label: "Create Time",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return timeZoneConversion.getLocalTime(value);
            }
          },
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize:
          this.btnMenu("flavorMgmt::Create") ||
          (!this.isAccountLevel() && this.btnMenu("brandMgmt::Create"))
            ? 7
            : 8,
        modelName: "",
        type: "",
        brandName: "",
        status: "",
        businessType: "",
        productName: "",
        productType: "",
        tankCapacity: "",
        usageEffect: "",
        brand: "",
        supplierId: localStorage.supplierId,
        deviceType: this.$route.query.brandSeriesId,
      },
      isBusy: false,
      has_data: false,
      types: {
        selectByWarmUpModeId: [],
        selectByHeatingModeId: [],
        selectByHeatingModeIds: [{ modeName: "Please select", id: 0 }],
        singleHeatingModeId: [{ modeName: "Please select", id: 0 }],
        heatingTypes: [
          { text: "Fixed Temperature", value: 1 },
          { text: "High Wave", value: 2 },
          // { text: "Single temperature heating", value: 1 },
          // { text: "Curve heating", value: 2 },
        ],
        TypeEncoding: [{ typeName: "Please select", id: "" }],
      },
      warmUp: {
        id: "",
        warmUpModeId: [],
      },
      warmUpModeId: [],
      heatingType: "",
      heatingModeId: [],
      heating: {
        id: "",
        heatingType: "2",
        heatingModeId: [],
        defaultHeatingModeId: 0,
        singleTemperatureId: 0,
      },
      warmUpModeShow: false,
      heatingModeShow: false,
      dowmDisable: false,
      showAlert: false,
      successAlear: false,
      showContent: "",
      statusOptions: [
        { text: "--All Status--", value: "", disabled: false },
        { text: "Enable", value: 0, disabled: false },
        { text: "Disable", value: 1, disabled: false },
      ],
      brandOptions: [{ text: "--All Brand--", value: "", disabled: false }],
      oilType: [
        { text: "--All Business Type--", value: "", disabled: false },
        { text: "CBD/THC Concentrates", value: 2, disabled: false },
        { text: "Nicotine", value: 1, disabled: false },
      ],
      productType: [
        { text: "--All Product Type--", value: "", disabled: false },
        { text: "Disposable", value: 3, disabled: false },
        { text: "Batteries", value: 7, disabled: false },
      ],
      tankCapacityOptios: [
        { text: "--All Tank Capactiry--", value: "", disabled: false },
      ],
      usageEffectOptions: [
        { text: "--All Usage Effects--", value: "", disabled: false },
      ],
      flavorList: [],
      isBusy: false,
    };
  },
  computed: {
    productDetail() {
      return productDetail;
    },
    ...mapGetters(["isIkrusher", "isUnicoreus"]),
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTankSize();
      this.getCommodityLabel();
      this.loadData();
      this.grtBrandList();
    },

    //查询所有容量
    getTankSize() {
      this.$api.specifications
        .getTankSize({ type: 1 })
        .then((x) => {
          this.tankCapacityOptios = this.tankCapacityOptios.concat(
            x.data.map((item, i) => {
              return {
                text: item.value,
                value: item.id,
                disabled: false,
              };
            })
          );
        })
        .catch((e) => {
          this.has_data = false;
        });
    },
    //查询所有品牌
    grtBrandList() {
      this.$api.Dropdown.brandList().then((x) => {
        if (x.success) {
          this.brandOptions = this.brandOptions.concat(
            x.data.map((item, i) => {
              return {
                text: item.brandName,
                value: item.id,
                disabled: false,
              };
            })
          );
        } else {
          if (x.message) {
            this.$toast(x);
          }
        }
      });
    },

    //查询所有标签
    getCommodityLabel() {
      this.$api.specifications
        .getCommodityLabel()
        .then((x) => {
          this.usageEffectOptions = this.usageEffectOptions.concat(
            x.data.map((item, i) => {
              return {
                text: item.labelName,
                value: item.id,
                disabled: false,
              };
            })
          );
        })
        .catch((e) => {
          this.has_data = false;
        });
    },

    onPageChange(page) {
      this.search_condition.pageNum = page;
      this.loadData();
    },
    provider(ctx) {
      this.isBusy = true;
      return this.$api.CartridgeModel.list(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    loadData() {
      this.isBusy = true;
      this.$api.CartridgeModel.list(this.search_condition)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.isBusy = false;
            this.has_data = true;
            this.totalRows = x.total;
            this.flavorList = x.data.map((item, i) => {
              let list = [];
              if (item.ingredientsVoList && item.ingredientsVoList.length > 0) {
                item.ingredientsVoList.map((ingredients, i) => {
                  list.push({
                    id: ingredients.id,
                    name: ingredients.name,
                    value: ingredients.proportion,
                  });
                });
              }
              if (item.terpenesList && item.terpenesList.length > 0) {
                item.terpenesList.map((terpenes, i) => {
                  list.push({
                    id: terpenes.id,
                    name: terpenes.name,
                    value: terpenes.proportion,
                  });
                });
              }
              list = list.sort((item1, item2) => {
                return item1.value - item2.value >= 0 ? -1 : 0;
              });
              item.ingredients = list;
              item.showMenu = false;
              return item;
            });
          } else {
            this.isBusy = false;
            this.has_data = false;
            this.flavorList = [];
          }
          if (
            this.btnMenu("flavorMgmt::Create") ||
            (!this.isAccountLevel() && this.btnMenu("brandMgmt::Create"))
          ) {
            this.flavorList.unshift({
              flavorPicture: require("@/assets/images/common/ic_add.svg"),
              viewType: "add",
            });
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          this.flavorList = [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.loadData();
    },
    clearAll() {
      this.search_condition = {
        pageNum: 1,
        pageSize:
          this.btnMenu("flavorMgmt::Create") ||
          (!this.isAccountLevel() && this.btnMenu("brandMgmt::Create"))
            ? 7
            : 8,
        modelName: "",
        type: "",
        brandName: "",
        status: "",
        businessType: "",
        productName: "",
        productType: "",
        tankCapacity: "",
        usageEffect: "",
        brand: "",
        supplierId: localStorage.supplierId,
        deviceType: this.$route.query.deviceType,
      };
      this.loadData();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    gotoAdd() {
      this.$router.push("flavorMgmtAdd?brandId="+this.$route.query.brandId +"&brandSeriesId="+this.$route.query.brandSeriesId);
    },
    clickView(item) {
      this.$router.push("flavorMgmtInfo?id=" + item.id);
    },
    clickEdit(item) {
      this.$router.push("flavorMgmtAdd?id=" + item.id+"&brandId="+this.$route.query.brandId +"&brandSeriesId="+this.$route.query.brandSeriesId);
    },
    clickStatus(item) {
      this.showAlert = true;
      let status = 0;
      if (item.status == 0) {
        status = 1;
        this.showContent = "Are sure to disable this flavor?";
      } else {
        this.showContent = "Are sure to enble this flavor?";
      }
      this.postData = {
        id: item.id,
        status: status,
      };
    },
    //确定禁用或者启用
    enableOrDisableFlavor() {
      this.$api.CartridgeModel.enableOrDisableFlavor(this.postData)
        .then((res) => {
          if (res.success) {
            this.showAlert = false;
            this.successAlear = true;
            this.loadData();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        })
        .catch((error) => {});
    },
    cancel() {
      this.showAlert = false;
    },
    cancel1() {
      this.successAlear = false;
    },
    edit(item, index, button) {
      this.$router.push("flavorMgmtEdit?id=" + item.id);
    },
    //按钮权限判断
    btnMenu(value) {
      return this.$_has(value);
    },
    //账户层级
    isAccountLevel() {
      return localStorage.accountLevel == 0;
    },
    warmUpMode(a, b, c) {
      this.warmUpModeShow = !this.warmUpModeShow;
      this.types.selectByWarmUpModeId = [];
      // this.types.selectByWarmUpModeId = [
      //   { warmUpName: "Please select", id: "" },
      // ];
      this.$api.Dropdown.selectByWarmUpModeId({ id: a.id }).then((res) => {
        if (res.success && res.data) {
          this.types.selectByWarmUpModeId =
            this.types.selectByWarmUpModeId.concat(res.data);
          if (a.warmUpModeId.length > 0) {
            this.warmUpModeId = [];
            this.types.selectByWarmUpModeId.forEach((item) => {
              if (a.warmUpModeId.indexOf(item.id) > -1) {
                this.warmUpModeId.push(item);
              }
            });
          }
          // this.warmUpModeId = a.warmUpModeId;
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
      this.warmUp.id = a.id;
      this.warmUp.warmUpModeId = a.warmUpModeId;
    },
    changeWarmUpModeId(warmUpModeId) {
      let arr = [];
      warmUpModeId.forEach((item) => {
        arr.push(item.id);
      });
      this.warmUp.warmUpModeId = arr;
    },
    resetWarmUpMode() {
      this.warmUp = {
        id: "",
        warmUpModeId: [],
      };
      this.warmUpModeId = [];
    },
    onResetWarmUpMode(evt) {
      evt.preventDefault();
      this.warmUp = {
        id: "",
        warmUpModeId: [],
      };
      this.warmUpModeId = [];
      this.warmUpModeShow = !this.warmUpModeShow;
    },
    onSubmitWarmUpMode(evt) {
      evt.preventDefault();
      this.$api.CartridgeModel.associatedWarmUp(this.warmUp).then((res) => {
        if (res.success) {
          this.warmUpModeShow = !this.warmUpModeShow;
          this.$refs.table.refresh();
        }
        if (res.message) {
          this.$toast(res);
        }
      });
    },
    heatingMode(a, b, c) {
      this.heatingModeShow = !this.heatingModeShow;
      // this.heatingType = a.singleTemperatureId ? 1 : a.heatingModeId ? 2 : "";
      this.heating = {
        id: a.id,
        heatingType: 2, //a.singleTemperatureId ? 1 : a.heatingModeId ? 2 : "",
        heatingModeId: a.heatingModeId ? a.heatingModeId : [],
        defaultHeatingModeId: a.defaultHeatingModeId,
        singleTemperatureId: a.singleTemperatureId ? a.singleTemperatureId : 0,
      };
      //if (this.heating.heatingType) {
      this.selectByHeatingModeId(a.heatingModeId);
      //}
    },
    selectByHeatingModeId(ele) {
      this.types.selectByHeatingModeId = [];
      this.types.selectByHeatingModeIds = [
        { modeName: "Please select", id: 0 },
      ];
      this.types.singleHeatingModeId = [{ modeName: "Please select", id: 0 }];
      this.$api.Dropdown.selectByHeatingModeId({
        id: this.heating.id,
        heatingType: 2, //this.heating.heatingType,
      }).then((res) => {
        if (res.success && res.data) {
          if (this.heating.heatingType == 1) {
            this.types.singleHeatingModeId =
              this.types.singleHeatingModeId.concat(res.data);
          }
          if (this.heating.heatingType == 2) {
            this.types.selectByHeatingModeId =
              this.types.selectByHeatingModeId.concat(res.data);
            this.types.selectByHeatingModeIds =
              this.types.selectByHeatingModeIds.concat(res.data);
            if (ele.length > 0) {
              this.heatingModeId = [];
              this.types.selectByHeatingModeId.forEach((item) => {
                if (ele.indexOf(item.id) > -1) {
                  this.heatingModeId.push(item);
                }
              });
            }
          }
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    changeHeatingModeId(heatingModeId) {
      let arr = [];
      heatingModeId.forEach((item) => {
        arr.push(item.id);
      });
      this.heating.heatingModeId = arr;
    },
    resetHeatingMode() {
      this.heating = {
        id: "",
        heatingType: "2",
        heatingModeId: [],
        singleTemperatureId: 0,
      };
      // this.heatingType = "";
      this.heatingModeId = [];
    },
    onResetHeatingMode(evt) {
      evt.preventDefault();
      this.heating = {
        id: "",
        heatingType: "2",
        heatingModeId: [],
        singleTemperatureId: 0,
      };
      // this.heatingType = "";
      this.heatingModeId = [];
      this.heatingModeShow = !this.heatingModeShow;
    },
    onSubmitHeatingMode(evt) {
      evt.preventDefault();
      this.$api.CartridgeModel.associatedHeatingMode(this.heating).then(
        (res) => {
          if (res.success) {
            this.heatingModeShow = !this.heatingModeShow;
            this.$refs.table.refresh();
          }
          if (res.message) {
            this.$toast(res);
          }
        }
      );
    },

    selectFlavorById() {},
    down(item, index, event) {
      this.dowmDisable = true;
      let data = {
        id: item.id,
      };
      this.$bvToast.toast("Creating...");
      try {
        this.$api.CartridgeModel.downModelQrCode(data);
        setTimeout(() => {
          this.dowmDisable = false;
        }, 6000);
      } catch (error) {}
    },
  },
};
</script>
