import { render, staticRenderFns } from "./article-add.vue?vue&type=template&id=40eb9472&"
import script from "./article-add.vue?vue&type=script&lang=js&"
export * from "./article-add.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\公司文件\\web\\omni\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('40eb9472')) {
      api.createRecord('40eb9472', component.options)
    } else {
      api.reload('40eb9472', component.options)
    }
    module.hot.accept("./article-add.vue?vue&type=template&id=40eb9472&", function () {
      api.rerender('40eb9472', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/content-manage/article-add.vue"
export default component.exports