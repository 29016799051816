<template>
  <div class="card-body">
    <b-col lg="12">
      <p class="title" style="font-size: 16px; font-weight: bolder">
        <span
          style="
            display: inline-block;
            width: 10px;
            height: 20px;
            vertical-align: text-top;
            background-color: #626ed4;
          "
        ></span>
        Production Stage
      </p>
    </b-col>
    <ul class="steps">
      <li
        v-for="(item, index) in SetData"
        :key="item + index"
        :class="{ active: Steps === index }"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  //   @Prop({default:0}) private Steps!: number;
  //   @Prop() private SetData!: string[];
  props: {
    SetData: {
      type: Array,
      required: true,
    },
    Steps: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style>
.steps {
  position: relative;
  display: flex;
  counter-reset: step; /*创建步骤数字计数器*/
}
/*步骤描述*/
.steps li {
  list-style-type: none;
  font-size: 16px;
  text-align: center;
  width: 15%;
  position: relative;
  float: left;
  flex: 1;
}

/*步骤数字*/
.steps li:before {
  display: block;
  content: counter(step); /*设定计数器内容*/
  counter-increment: step; /*计数器值递增*/
  width: 32px;
  height: 32px;
  background-color: #626ed4;
  line-height: 32px;
  border-radius: 32px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  font-weight: 700;
  margin: 0 auto 8px auto;
}

/*连接线*/
.steps li ~ li:after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #626ed4;
  position: absolute;
  left: -50%;
  top: 15px;
  z-index: -1; /*放置在数字后面*/
}

/*将当前/完成步骤之前的数字及连接线变绿*/
.steps li.active:before,
.steps li.active:after {
  background-color: #626ed4;
}

/*将当前/完成步骤之后的数字及连接线变灰*/
.steps li.active ~ li:before,
.steps li.active ~ li:after {
  background-color: #777;
}
</style>
