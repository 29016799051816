<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- 用户资料 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>User Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Avatar:</label>
            <span class="p-3"
              ><img
                v-if="info.picture"
                :src="info.picture"
                alt
                class="avatar-xs rounded-circle mr-2"
            /></span>
          </b-col>
          <b-col>
            <label class="col-form-label">User ID:</label>
            <span class="p-3">{{ info.id }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Sex:</label>
            <span class="p-3">{{ sex }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Registration Time:</label>
            <span class="p-3">{{ info.createTime }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Name:</label>
            <span class="p-3">{{ info.nickName }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Birth day:</label>
            <span class="p-3">{{ info.dateOfBirth }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Registration Area:</label>
            <span class="p-3">{{ info.registrationArea }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Email:</label>
            <span class="p-3">{{ info.email }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Status:</label>
            <span class="p-3">{{
              info.status == 1 ? "Enabled" : "Disabled"
            }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Invitation Code:</label>
            <span class="p-3">{{ info.invitationCode }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <!-- end card -->

    <!-- 产品记录 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>History Products</h4>
          </div>
        </b-col>
      </b-row>
      <br />
      <b-table
        id="tag_table"
        head-variant="light"
        striped
        :fields="tagFields"
        ref="tag_table"
        :items="tag_provider"
        :per-page="device.pageSize"
        :current-page="device.pageNum"
        :busy="device_isBusy"
        show-empty
        empty-text="No records"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:cell(index)="data">{{
          data.index + (device.pageNum - 1) * device.pageSize + 1
        }}</template>
        <template v-slot:cell(actions)="row">
          <b-button-group>
            <b-button
              v-has="'userAccounts::Details'"
              size="sm"
              variant="info"
              @click="show(row.item, row.index, $event.target)"
              class="mr-1"
              >Details</b-button
            >
          </b-button-group>
        </template>
        <template v-slot:cell(status)="row">
            <p v-if="row.item.status == 2" class="clientStyle">Unactivated</p>
            <p v-if="row.item.status == 1">Activated</p>
          </template>
      </b-table>

      <!-- pagination -->
      <b-row>
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="device_totalRows">
              <li>
                <p
                  class="pagination mb-0"
                  style="margin-top: 7px; margin-right: 20px"
                >
                  {{ device_totalRows }} results
                </p>
              </li>
              <b-pagination
                v-model="device.pageNum"
                :per-page="10"
                :total-rows="device_totalRows"
                aria-controls="device_table"
              >
              </b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
      <!-- end pagination -->
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import { mapGetters } from "vuex";

/**
 * User Info component
 */
export default {
  components: {
    Layout,
    PageHeader,
    timeZoneConversion,
  },
  data() {
    return {
      title: "User Accounts Details",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "User Mgmt",
          // href: "/"
        },
        {
          text: "UserDetails",
          active: true,
        },
      ],
      // 绑定的设备信息
      deviceFields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "deviceNumber", label: "Device No.", class: "text-center" },
        {
          key: "deviceLocation",
          label: "Active Location",
          class: "text-center",
        },
        {
          key: "address",
          label: "Lastest Loaction",
          class: "text-center",
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          formatter: (value) => {
            return value == 0 ? "Active" : "Activate";
          },
        },
        { key: "versionNo", label: "Firmware version", class: "text-center" },
        {
          key: "activationTime",
          label: "Activation time",
          class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.getLocalTime(value);
          },
        },
        {
          key: "actions",
          label: "Opreration",
          class: "text-center",
        },
      ],
      // 绑定的烟弹信息
      cartridgeFields: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "smokeRecordNumber",
          label: "Product ID",
          class: "text-center",
        },
        {
          key: "smokeBombModelName",
          label: "Product model",
          class: "text-center",
        },
        {
          key: "smokeOilModelName",
          label: "Content model",
          class: "text-center",
        },
        // { key: "flavor", label: "Content model", class: "text-center" },
        { key: "remaining", label: "Remaining", class: "text-center" },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          formatter: (value) => {
            return value == 1
              ? "Activated"
              : value == 2
              ? "Unactivated"
              : value == 3
              ? "Depleted"
              : value == 4
              ? "Abnormal"
              : value == 9
              ? "Empty"
              : "";
          },
        },
        {
          key: "activationTime",
          label: "Activation time",
          class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.getLocalTime(value);
          },
        },
        {
          key: "lastUseTime",
          label: "Last use time",
          class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.getLocalTime(value);
          },
        },
      ],
      // 使用记录
      usageRecordFields: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "smokeRecordNumber",
          label: "Product ID",
          class: "text-center",
        },
        { key: "deviceNumber", label: "Device ID", class: "text-center" },
        { key: "flavor", label: "Flavor", class: "text-center" },
        {
          key: "createTime",
          label: "Use Time",
          class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.foramtTime(value);
          },
        },
        {
          key: "deviceLocation",
          label: "Active Location",
          class: "text-center",
        },
        { key: "address", label: "Lastest Loaction", class: "text-center" },
        { key: "totalDuration", label: "Duration(s)", class: "text-center" },
      ],
      //产品记录
      tagFields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "productId", label: "Product ID", class: "text-center" },
        {
          key: "productName",
          label: "Product Models#/Marketing Name",
          class: "text-center",
          formatter: (value) => {
            return value == null ? "-" : value;
          },
        },
        { key: "brandName", label: "Brand Name", class: "text-center" },
        { key: "batchName", label: "Batch Name", class: "text-center" },
        {
          key: "status",
          label: "Status",
          class: "text-center",
        },
        {
          key: "activationTime",
          label: "Activated Time",
          class: "text-center",
          formatter: (value) => {
            return value == null ? "-" : value;
          },
        },
        {
          key: "actions",
          label: "Opreration",
          class: "text-center",
        },
      ],
      info: {
        binding: 0,
        createTime: "",
        dateOfBirth: "",
        email: "",
        frequency: 0,
        gender: 0,
        id: 0,
        isElectricSmoke: 0,
        isSmoke: 0,
        nickName: "",
        picture: "",
        source: "",
      },
      device: {
        pageNum: 1,
        pageSize: 10,
        id: this.$route.query.id,
      },
      cartridge: {
        pageNum: 1,
        pageSize: 10,
        id: this.$route.query.id,
      },
      device_isBusy: false,
      cartridge_isBusy: false,
      device_totalRows: 0,
      cartridge_totalRows: 0,
      SmokeBomb: {
        pageNum: 1,
        pageSize: 10,
        id: this.$route.query.id,
        supplierId: localStorage.supplierId,
      },
      SmokeBom_isBusy: false,
      SmokeBomb_totalRows: 0,
    };
  },
  computed: {
    ...mapGetters(["isIkrusher", "isUnicoreus"]),
    sex() {
      if (this.info.gender == 0) {
        return "Male";
      } else if (this.info.gender == 1) {
        return "Female";
      } else if (this.info.gender == 2) {
        return "Others";
      } else {
        return "Not to disclose";
      }
    },
    createTime() {
      return timeZoneConversion.getLocalTimes(this.info.createTime);
    },
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    device_provider(ctx) {
      this.device_isBusy = true;
      return this.$api.userManage.getUserDevice(this.device, ctx).then((x) => {
        this.device_totalRows = x.total;
        this.device_isBusy = false;
        return x.data;
      });
    },
    tag_provider(ctx) {
      this.device_isBusy = true;
      return this.$api.userManage
        .selectHistoryProductsList(this.device, ctx)
        .then((x) => {
          this.device_totalRows = x.total;
          this.device_isBusy = false;
          return x.data;
        });
    },
    cartridge_provider(ctx) {
      this.cartridge_isBusy = true;
      return this.$api.userManage
        .selectBindSmokeRecord(this.cartridge, ctx)
        .then((x) => {
          this.cartridge_totalRows = x.total;
          this.cartridge_isBusy = false;
          return x.data;
        });
    },
    SmokeBomb_provider(ctx) {
      this.SmokeBom_isBusy = true;
      return this.$api.userManage
        .findSmokeBombList(this.SmokeBomb, ctx)
        .then((x) => {
          this.SmokeBomb_totalRows = x.total;
          this.SmokeBom_isBusy = false;
          return x.data;
        });
    },
    init() {
      if (this.$route.query.id) {
        this.$api.userManage.getUser(this.$route.query).then((res) => {
          if (res.success) {
            if (!res.data.dateOfBirth) {
              res.data.dateOfBirth = "Not to disclose";
            }
            if (!res.data.registrationArea) {
              res.data.registrationArea = "Not to disclose";
            }
            this.info = res.data;
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    returnPage() {
      this.$router.go(-1);
    },
    // 解绑用户设备
    unbundleDevice(item, index, row) {
      let postData = {
        id: item.id,
      };
      this.$api.userManage
        .untieDeviceById(postData)
        .then((res) => {
          console.log(res);
          if (res.success && res.message == "Unbind success") {
            this.$toast(res);
            //this.device_provider();
            this.$refs.device_table.refresh();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        })
        .catch((error) => {});
    },

    show(item, index, button) {
      this.$router.replace("/productionMgmt/productionBatchMgmtOmniIDInfo?id=" + item.id);
    },
  },
};
</script>
