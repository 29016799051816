var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      attrs: { size: "lg", centered: "", showLogo: false },
      model: {
        value: _vm.modalVisible,
        callback: function($$v) {
          _vm.modalVisible = $$v
        },
        expression: "modalVisible"
      }
    },
    [
      [
        _vm.batchDetail.id
          ? _c(
              "div",
              { staticClass: "vo-column", staticStyle: { width: "100%" } },
              [
                _c("div", { staticClass: "productDetailRow" }, [
                  _c("div", { staticClass: "productDetailColumn" }, [
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Batch#:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.batchDetail.number))
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Production Batch Name:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.batchDetail.orderName))
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Ceate time:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getLocalTime(_vm.batchDetail.createTime)
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Batch Qty:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.batchDetail.batchQuantity) + " pcs")
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Produced Qty:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.batchDetail.normalQuantity) + " pcs")
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Status:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.batchDetail.statusName))
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Completed Time:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v("-")
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Notes:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.batchDetail.remarks))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "productDetailColumn" }, [
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Product Model#:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v("AD2601")
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Variant Code:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.batchDetail.variantCode))
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Marketing Name:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.batchDetail.deviceName))
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Client:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.batchDetail.clientName))
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Brand:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.batchDetail.brandName))
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Brand ProductModel#:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.batchDetail.brandProductModel))
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Brand Marketing Name:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.batchDetail.brandMarketingName))
                      ])
                    ])
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "productDetailRowButton" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-secondary" },
                        on: { click: _vm.cancel }
                      },
                      [_vm._v(" Close ")]
                    )
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }