var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("b-card", [
        _vm.tagDetail.id
          ? _c(
              "div",
              { staticClass: "vo-column", staticStyle: { width: "100%" } },
              [
                _c("div", { staticClass: "productDetailRow" }, [
                  _c("div", { staticClass: "productDetailColumn" }, [
                    _c(
                      "div",
                      { staticClass: "productDetailRow1 productDetailBorder" },
                      [
                        _c("img", {
                          staticStyle: {
                            "object-fit": "contain",
                            width: "100%",
                            height: "100%"
                          },
                          attrs: { src: _vm.tagDetail.flavorPicture }
                        })
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "productDetailColumn" }, [
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Marketing Name#:")])
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "productDetailContent",
                          staticStyle: { "font-size": "18px", color: "#3eb1c8" }
                        },
                        [_vm._v(" " + _vm._s(_vm.tagDetail.deviceName) + " ")]
                      )
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Product Model#:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.tagDetail.modelName))
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Variant Code:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(" " + _vm._s(_vm.tagDetail.variantCode) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Omni ID:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(" " + _vm._s(_vm.tagDetail.tagId) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Business Type:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(" " + _vm._s(_vm.tagDetail.oilTypeName) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Product Type:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.tagDetail.productType))
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Compatibility:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.tagDetail.availableCompatibility) +
                            " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Tank Size:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" })
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Resistance Value:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.tagDetail.resistance))
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Max Output Voltage:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(
                          " " + _vm._s(_vm.tagDetail.maxOutputVoltage) + " "
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "productDetailColumn" }, [
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Client:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.tagDetail.clientName))
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Brand:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(_vm._s(_vm.tagDetail.brandName))
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Brand Product Model#:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(
                          " " + _vm._s(_vm.tagDetail.brandProductModel) + " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Brand Marketing Name:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(
                          " " + _vm._s(_vm.tagDetail.brandMarketingName) + " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Flavor:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(" " + _vm._s(_vm.tagDetail.flavorName) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Batch Name:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(" " + _vm._s(_vm.tagDetail.orderName) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Status:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(" " + _vm._s(_vm.tagDetail.statusName) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Activated Time:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getLocalTime(_vm.tagDetail.activatedTime)
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "vo-row" }, [
                      _c("p", { staticClass: "productDetailContentColor" }, [
                        _c("strong", [_vm._v("Activated Address:")])
                      ]),
                      _c("p", { staticClass: "productDetailContent" }, [
                        _vm._v(
                          " " + _vm._s(_vm.tagDetail.activatedAddress) + " "
                        )
                      ])
                    ])
                  ])
                ])
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }