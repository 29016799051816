<template>
  <div class="login-box">
    <div style="margin-top: 120px">
      <div class="row justify-content-center">
        <div class="col-md-6 col-lg-4 col-xl-3">
          <div class="overflow-hidden">
            <div class="text-primary text-center p-4">
              <img :src="imageUrl" :height="height" alt="logo" />
            </div>
            <b-form @submit="tryToLogIn">
              <b-form-group>
                <b-form-input
                  v-model="loginForm.username"
                  placeholder="UserName"
                  required
                  disabled
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');if(value.length > 40)value = value.slice(0, 40)"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-input
                  v-model="loginForm.password"
                  type="password"
                  placeholder="New Password"
                  :state="
                    loginForm.password.length >= 6 &&
                    loginForm.password.length <= 18
                  "
                  required
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');if(value.length > 18)value = value.slice(0, 18)"
                >
                </b-form-input>
                <div class="input-message">
                  6-18 characters, including numbers, letters, symbols
                </div>
              </b-form-group>
              <b-form-group>
                <b-form-input
                  v-model="loginForm.newPassword"
                  type="password"
                  placeholder="Confirm Password"
                  required
                  :state="
                    loginForm.newPassword.length >= 6 &&
                    loginForm.newPassword.length <= 18 &&
                    loginForm.password == loginForm.newPassword
                  "
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');if(value.length > 18)value = value.slice(0, 18)"
                >
                </b-form-input>
              </b-form-group>
              <b-row>
                <b-col md="7">
                  <b-form-group>
                    <b-form-input
                      v-model="loginForm.verificationCode"
                      placeholder="Email Verification Code"
                      required
                      oninvalid="setCustomValidity('Please enter');"
                      oninput="setCustomValidity('');if(value.length > 6)value = value.slice(0, 6)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group>
                <p style="color: white; float: left" @click="login()">Login</p>
              </b-form-group>
              <b-form-group>
                <b-button
                  type="submit"
                  variant="primary"
                  class="float-inherit btn-lg"
                  style="width: 300px"
                  v-b-modal.modal-center
                  >CONFIRM</b-button
                >
              </b-form-group>
            </b-form>
          </div>
          <div class="mt-5 text-center">
            <p class="mb-0" style="color: #ffffff">
              Copyright © {{ new Date().getFullYear() }} {{ this.brand }}
              <span class="d-none d-sm-inline-block">
                . All rights reserved
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <Dialog v-if="showAlert" customBtn="Login" :imageSrc="require('@/assets/success.png')"   @customEvent="login">
      <p>Password reset successful</p>
      <p>Please use your new password to login.</p>
    </Dialog>
    <!-- <alert message1 = "Please use your new password to log in." loginButtonName = "Login" v-on:clickLogin="login"/> -->
  </div>
</template>

<style>
.login-box {
  width: 100%;
  height: 100%;
  background-color: #333547;
  position: absolute;
  text-align: center;
}
.input-message {
  display: flex;
  align-items: center;
  color: white;
}
</style>

<script>
import { authMethods } from "@/state/helpers";
import Dialog from "@/components/Dialog";

/**
 * Login component
 */
export default {
  components: {Dialog},
  data() {
    return {
      showButton: true,
      countdown: 60, // 初始倒计时时间
      timer: null, // 定时器
      isDisabled: 0, //默认不显示按钮
      brand: "",
      imageUrl: "",
      height: 0,
      loginForm: {
        username: "",
        password: "",
        newPassword: "",
        verificationCode: "",
      },
      showAlert:false
    };
  },
  created() {
    let value = window.location.search.split("=");
    if (value.length > 1) {
      this.loginForm.username = decodeURIComponent(value[1]);
    }
  },
  mounted() {
    if (this.$store.getters.isIkrusher) {
      this.brand = "Omni";
      this.imageUrl = require("@/assets/omniLogo.png");
      this.height = 100;
    } else {
      this.brand = "UNI-CORE";
      this.imageUrl = require("@/assets/unicoreusLogo.png");
      this.height = 50;
    }
    //绑定事件
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
    clearInterval(this.timer);
  },
  methods: {
    ...authMethods,
    login() {
      this.$router.push("login");
    },
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode == 13) {
        this.tryToLogIn(e);
      }
    },
    tryToLogIn(evt) {
      if (evt) {
        evt.preventDefault();
      }
      //执行找回密码接口
      this.$api.account
        .retrievePassword({
          email: this.loginForm.username,
          password: this.loginForm.password,
          code: this.loginForm.verificationCode,
        })
        .then((res) => {
          if (res.code == 200) {
            this.showAlert = true;
          } else {
            this.$toast(res);
            return;
          }
        });
    },
  },
};
</script>
