<!-- 添加新的生产厂商弹窗组件 -->
<template>
  <b-modal
    id="modal"
    size="lg"
    v-model="showCreatePlant"
    centered
    title="Create New Plant"
    ok-title="Confirm"
    @ok="onConfirm"
    @cancel="onCancel"
    @close="onClose"
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form action="#" @submit.prevent="onConfirm" @reset="onCancel">
              <!-- 厂家名称 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Manufacturer Name"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="manufacturer-name"
                    label-class="requiredRow"
                  >
                    <b-form-input
                      id="manufacturer-name"
                      v-model="typeform.name"
                      placeholder="Please enter"
                      trim
                      required
                      oninvalid="setCustomValidity('Please enter');"
                      oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div>
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Contacts"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="contacts"
                  >
                    <b-form-input
                      id="contacts"
                      v-model="typeform.contacts"
                      placeholder="Please enter"
                      trim
                      oninput="if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div>
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Email"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="typeform.email"
                      placeholder="Please enter"
                      type="email"
                      trim
                      oninput="if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div>
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Contact Number"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="contact-number"
                  >
                    <b-form-input
                      id="contact-number"
                      v-model="typeform.phone"
                      placeholder="Please enter"
                      trim
                      oninput="if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div>
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Contact Address"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="contact-address"
                  >
                    <b-form-input
                      id="contact-address"
                      v-model="typeform.address"
                      placeholder="Please enter"
                      trim
                      oninput="if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div>
              <!-- 备注 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Notes"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="flavor-remark"
                  >
                    <b-form-textarea
                      id="remark"
                      v-model="typeform.remarks"
                      placeholder="Enter Notes"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </div>

              <div class="form-group mb-0">
                <div class="text-center">
                  <b-button type="submit" variant="primary">Confirm</b-button>
                  <b-button type="reset" variant="danger" class="m-l-5 ml-1"
                    >Cancel</b-button
                  >
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
    <template v-slot:modal-footer>
      <div class="w-100"></div>
    </template>
  </b-modal>
</template>
<script>
import { dropzoneOptions } from "@/api/upload";

export default {
  name: "CreateNewPlant",
  data() {
    return {
      dropzoneOptions: dropzoneOptions,
      typeform: {
        //"id":3,
        name: "",
        //areaEncodingId: "",
        contacts: "",
        phone: "",
        email: "",
        address: "",
        remarks: "",
        supplierId: localStorage.supplierId,
      },
      types: {
        areaEncodingList: [{ cityName: "Please select", id: "" }],
      },
      submit: false,
      typesubmit: false,
    };
  },
  props: {
    showCreatePlant: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$api.Dropdown.allCountry().then((res) => {
        if (res.success) {
          this.types.areaEncodingList = this.types.areaEncodingList.concat(
            res.data
          );
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onReset(evt) {
      this.typeform = {
        name: "",
        contacts: "",
        phone: "",
        email: "",
        address: "",
        remarks: "",
        supplierId: localStorage.supplierId,
      };
    },
    onConfirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$api.Flavor.manufacturerAdd(this.typeform).then((res) => {
        if (res.success) {
          //添加完成
          this.onReset();
          this.$emit("AddPlantComplete", false);
          this.$emit("CancelPlantEvent", false);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onCancel(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.onReset();
      this.$emit("CancelPlantEvent", false);
    },
    onClose(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.onReset();
      this.$emit("CancelPlantEvent", false);
    },
  },
};
</script>