<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-form @submit="onSubmit" @reset="onReset">
      <b-card>
        <b-row>
          <b-col cols="10">
            <!-- 批次名称 -->
            <form-row>
              <b-form-group
                label="Batch Name:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="order-name"
                label-class="requiredRow"
              >
                <b-form-input
                  id="order-name"
                  v-model="form.orderName"
                  required
                  maxlength="100"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                  placeholder="Please enter"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <div style="text-align: center; margin-bottom: 1em">
                  {{ form.orderName.length }}/100
                </div>
              </template>
            </form-row>
            <!-- 产品类型 -->
            <form-row>
              <b-form-group
                label="Product Type:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="deviceType"
                label-class="requiredRow"
              >
                <b-form-radio
                  v-for="(item, key) in types.deviceType"
                  :key="key"
                  class="custom-control-inline pt-2"
                  @change="deviceTypeIdChange"
                  v-model="form.type"
                  :name="'deviceType-options' + key"
                  :value="item.value"
                  >{{ item.text }}
                </b-form-radio>
              </b-form-group>
            </form-row>

            <!-- SKU类型 -->
            <form-row>
              <b-form-group
                label="SKU Type:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="skuType"
                label-class="requiredRow"
              >
                <b-form-radio
                  v-for="(item, key) in types.skuType"
                  :key="key"
                  class="custom-control-inline pt-2"
                  v-model="form.skuType"
                  @change="skuTypeIdChange"
                  :name="'skuType-options' + key"
                  :value="item.value"
                  >{{ item.text }}
                </b-form-radio>
              </b-form-group>
            </form-row>
            <!-- Brand Name 所属品牌-->
            <form-row>
              <b-form-group
                v-if="showBrand"
                label="Brand Name:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="brand-name"
                label-class="requiredRow"
              >
                <b-form-select
                  v-model="form.brandId"
                  @change="brandIdChange"
                  :options="types.brandNames"
                  value-field="id"
                  text-field="brandName"
                  required
                  oninvalid="setCustomValidity('Please select');"
                  oninput="setCustomValidity('')"
                ></b-form-select>
              </b-form-group>
            </form-row>
            <!-- 产品 -->
            <form-row>
              <b-form-group
                v-if="showProduct"
                label="Product:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="cartridge-model"
                label-class="requiredRow"
              >
                <b-form-select
                  id="cartridge-model"
                  v-model="form.testSmokeBombModeId"
                  @change="testSmokeBombModeIdChange"
                  :options="types.selectAllTestSmokeBombModel"
                  value-field="id"
                  text-field="modelName"
                  required
                  oninvalid="setCustomValidity('Please select');"
                  oninput="setCustomValidity('')"
                ></b-form-select>
              </b-form-group>
            </form-row>
            <!-- 口味 一次性尼古丁时选择口味 -->
            <form-row>
              <b-form-group
                v-if="showFlavor"
                label="Flavor:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="flavor"
                label-class="requiredRow"
              >
                <b-form-select
                  v-model="form.smokeBombModelId"
                  :options="types.flavorList"
                  value-field="id"
                  text-field="modelName"
                  required
                  oninvalid="setCustomValidity('Please select');"
                  oninput="setCustomValidity('')"
                ></b-form-select>
              </b-form-group>
            </form-row>

            <!-- 生产数量 -->
            <form-row :rightCol = "3">
              <b-form-group
                label="Production Quantity:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="production-quantity"
                label-class="requiredRow"
              >
                <b-form-input
                  id="production-quantity"
                  v-model="form.batchQuantity"
                  type="number"
                  min="0"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('')"
                  placeholder="Please Enter"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <div style="text-align: center; margin-bottom: 1em">
                  <span style="margin-right: 10px">PCS</span> Default 
                  packaging：1 box contains 50pcs
                </div>
              </template>
            </form-row>
            <!--检测网站  -->
            <form-row>
              <b-form-group
                label="Check Custom Url:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="check-custom-url"
                label-class="requiredRow"
              >
                <b-form-checkbox
                  id="check-custom-url"
                  v-model="checkCustomUrl"
                  name="checkCustomUrl"
                  :value="1"
                  :unchecked-value="0"
                  switch
                  size="lg"
                  style="margin-top: 5px"
                >
                  {{ checkCustomUrl == 1 ? "Yes" : "No" }}
                </b-form-checkbox>
              </b-form-group>
            </form-row>
            <form-row>
              <b-form-group
                v-if="checkCustomUrl == 1"
                label="URL:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="check-url"
                label-class="requiredRow"
              >
                <b-form-input
                  id="check-url"
                  v-model="form.checkUrl"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('')"
                  placeholder="Please enter"
                ></b-form-input>
              </b-form-group>
            </form-row>
            <form-row>
              <b-form-group
                label="Batch Popup Type:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="batchPopupType"
                label-class="requiredRow"
              >
                <b-form-radio
                  v-for="(item, key) in types.batchPopupType"
                  :key="key"
                  class="custom-control-inline pt-2"
                  v-model="batchPopupTypeId"
                  :name="'batchPopupType-options' + key"
                  :value="item.value"
                  >{{ item.text }}
                </b-form-radio>
              </b-form-group>
            </form-row>

            <!-- 默认弹出url -->
            <form-row>
              <b-form-group
                v-if="batchPopupTypeId == 2"
                label="URL"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="url-popup"
                label-class="requiredRow"
              >
                <b-form-input
                  id="url-popup"
                  v-model="form.defaultUrlPopup"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('')"
                  placeholder="Please enter"
                >
                </b-form-input>
              </b-form-group>
            </form-row>
            <!-- PCBA供应商 -->
            <form-row>
              <b-form-group
                v-if="isUnicoreus"
                label="PBCA Supplier:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="pbca-supplier"
                label-class="requiredRow"
              >
                <b-form-input
                  id="pbca-supplier"
                  v-model="form.pcbaSupplier"
                  type="text"
                  min="0"
                  maxlength="250"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                  placeholder="Please Enter"
                ></b-form-input>
              </b-form-group>
            </form-row>
            <!-- 本次PCBA负责人 -->
            <form-row>
              <b-form-group
                v-if="isUnicoreus"
                label="Charger of PCBA:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="charger-of-pcba"
                label-class="requiredRow"
              >
                <b-form-input
                  id="charger-of-pcba"
                  v-model="form.chargerOfPcba"
                  type="text"
                  min="0"
                  maxlength="250"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('')"
                  placeholder="Please Enter"
                ></b-form-input>
              </b-form-group>
            </form-row>
            <!-- 组装供应商 -->
            <form-row>
              <b-form-group
                label="Assembly Supplier:"
                v-if="isUnicoreus"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="assembly-supplier"
                label-class="requiredRow"
              >
                <b-form-input
                  id="assembly-supplier"
                  v-model="form.assemblySupplier"
                  type="text"
                  min="0"
                  maxlength="250"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('')"
                  placeholder="Please Enter"
                ></b-form-input>
              </b-form-group>
            </form-row>
            <!-- 本次组装责任人 -->
            <form-row>
              <b-form-group
                v-if="isUnicoreus"
                label="Charger of Assembly:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="charger-of-assembly"
                label-class="requiredRow"
              >
                <b-form-input
                  id="charger-of-assembly"
                  v-model="form.chargerOfAssembly"
                  type="text"
                  min="0"
                  maxlength="250"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('')"
                  placeholder="Please Enter"
                ></b-form-input>
              </b-form-group>
            </form-row>
            <!-- 生产厂家 -->
            <form-row>
              <b-form-group
                v-if="isUnicoreus"
                label="Manufacturer:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="manufacturer"
              >
                <b-form-input
                  id="manufacturer"
                  v-model="form.manufacturerEncodingName"
                  placeholder="Please Enter"
                ></b-form-input>
              </b-form-group>
            </form-row>
            <!-- 备注 -->
            <form-row>
              <b-form-group
                label="Batch Notes:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="remarks"
              >
                <b-form-textarea
                  id="remarks"
                  v-model="form.remarks"
                  placeholder="Please enter"
                  rows="6"
                  max-rows="6"
                  maxlength="200"
                ></b-form-textarea>
              </b-form-group>
              <template #right>
                <div style="text-align: center; margin-bottom: 1em">
                  {{ form.remarks && form.remarks.length
                  }}<span v-if="!form.remarks">0</span>/200
                </div>
              </template>
            </form-row>
            <!-- 状态 -->
            <form-row>
              <b-form-group
                label="Status:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="status"
                label-class="requiredRow"
              >
                <b-form-select
                  v-model="form.status"
                  :options="types.status"
                  required
                ></b-form-select>
              </b-form-group>
            </form-row>
          </b-col>
          <!-- end col -->
        </b-row>
      </b-card>
      <!-- <b-row class="align-items-center">
        <b-col cols="12">
          <div class="blockquote">
            <h4>Specifications and Models:</h4>
          </div>
        </b-col>
      </b-row>
      <b-card> </b-card> -->
      <b-form-group label-cols-lg="3">
        <b-button type="submit" class="custom-width" variant="primary"
          >Save</b-button
        >
        <b-button type="reset" class="ml-4 custom-width">Cancel</b-button>
      </b-form-group>
    </b-form>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel"
      @hide="hide"
    >
      Saved successfully
    </Dialog>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import FormRow from "@/components/FormRow.vue";
import { mapGetters } from "vuex";
import Dialog from "@/components/Dialog";
import CountText from "@/components/CountText.vue";

/**
 * Cartridge Batch Add component
 */

export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    FormRow,
    Dialog,
    CountText
  },
  data() {
    return {
      title: "Empty Carts Orders Create",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Empty Carts Orders Create",
          active: true,
        },
      ],
      types: {
        // manufacturerEncodingList: [{ name: "Please select", id: "" }],
        selectAllTestSmokeBombModel: [],
        selectAllDefaultHeatingMode: [{ modeName: "Please select", id: "" }],
        selectAllDefaultWarmUpMode: [{ warmUpName: "Please select", id: "" }],
        deviceType: [
          { text: "Disposable", value: 3 },
          { text: "Batteries", value: 7 },
        ],
        batchPopupType: [
          { text: "Popup template", value: 1 },
          { text: "URL", value: 2 },
        ],
        status: [
          { text: "To be confirmed", value: 11, disabled: false },
          { text: "IN production", value: 13, disabled: false },
          { text: "Done", value: 14, disabled: false },
        ],
        skuType: [
          { text: "Omni SKU", value: 1 },
          { text: "Brand SKU", value: 2 },
        ],
        brandNames: [],
        flavorList: [],
      },
      form: {
        orderName: "",
        testSmokeBombModeId: "",
        batchQuantity: "",
        pcbaSupplier: "", // PCBA供应商
        chargerOfPcba: "", // 本次PCBA负责人
        assemblySupplier: "", // 组装供应商
        chargerOfAssembly: "", // 本次组装责任人
        output: "", // 六点输出
        manufacturerEncodingName: "",
        productionTime: "",
        remarks: "",
        defaultWarmHeatingId: "",
        defaultWarmUpId: "",
        type: 3,
        maxFrequency: "2000",
        defaultUrlPopup: "",
        checkUrl: "",
        brandId: "",
        smokeBombModelId: "",
        status: 11,
        skuType: 1,
      },
      productionTime: "",
      checkCustomUrl: 0,
      oilType: 2,
      outputList: [
        { value: 3.3 },
        { value: 3.3 },
        { value: 3.3 },
        { value: 3.3 },
        { value: 3.3 },
        { value: 3.3 },
        { value: 3.3 },
        { value: 3.3 },
      ],
      min: 0.1,
      max: 4.2,
      step: 0.1,
      batchPopupTypeId: 1,
      skuTypeId: 1,
      deviceTypeId: 3,
      testSmokeBombModeId: "",
      brandId: "",
      editId: window.location.search,
      successAlear: false,
    };
  },
  computed: {
    ...mapGetters(["isIkrusher", "isUnicoreus"]),
    is_add: function () {
      return /add/i.test(location.href);
    },
    showBrand() {
      return this.form.type == 7 || this.form.skuType == 2;
    },
    showProduct() {
      return (
        (this.form.type == 3 && this.form.skuType == 1) ||
        (this.form.type == 3 && this.form.skuType == 2 && this.form.brandId) ||
        (this.form.type == 7 && this.form.skuType && this.form.brandId)
      );
    },
    showFlavor() {
      return (
        this.form.type == 3 &&
        this.oilType == 1 &&
        this.form.testSmokeBombModeId
      );
    },
  },
  beforeCreate() {
    this.editId = window.location.search;
  },

  async created() {
    this.init();
  },
  watch: {
    productionTime(val) {
      this.form.productionTime = this.dateFormatter(val);
      console.log(this.form.productionTime);
    },
  },
  methods: {
    async init() {
      if (this.editId) {
        let id = this.editId.split("=")[1];
        this.editId = id;
        await this.selectBatchDetail(id);
        if (this.showBrand) {
          await this.selectBrnadList();
        }
        if (this.showProduct) {
          await this.selectProductList();
        }
        this.oilType = this.types.selectAllTestSmokeBombModel.find(
          (option) => option.id === this.form.testSmokeBombModeId
        ).oilType;

        if (this.showFlavor) {
          await this.selectflavorList();
        }
      } else {
        this.selectProductList();
      }
    },
    async deviceTypeIdChange(e) {
      console.log("deviceTypeId变动" + e);
      this.form.type = e;
      this.form.testSmokeBombModeId = "";
      this.types.selectAllTestSmokeBombModel = [];
      this.types.flavorList = [];
      if (this.showBrand) {
        await this.selectBrnadList();
      } else {
        this.types.brandName = [];
      }
      if (this.showProduct) {
        await this.selectProductList();
      } else {
        this.types.selectAllTestSmokeBombModel = [];
      }
    },

    async skuTypeIdChange(e) {
      console.log("skuTypeId变动" + e);
      this.form.skuType = e;
      this.form.testSmokeBombModeId = "";
      this.types.selectAllTestSmokeBombModel = [];
      this.form.smokeBombModelId = "";
      this.types.flavorList = [];
      this.form.brandId = "";
      this.types.brandNames = [];
      if (this.showProduct) {
        console.log("type"+this.form.type);
        await this.selectProductList();
      } else {
        this.types.selectAllTestSmokeBombModel = [];
      }
      if (this.showBrand) {
        console.log("type"+this.form.type);
        await this.selectBrnadList();
      } else {
        this.types.brandName = [];
      }
    },

    async brandIdChange(e) {
      console.log("brandId变动" + e);
      this.form.testSmokeBombModeId = "";
      this.types.selectAllTestSmokeBombModel = [];
      this.types.flavorList = [];
      await this.selectProductList();
    },

    async testSmokeBombModeIdChange(e) {
      console.log("testSmokeBombModeId变动" + e);
      this.form.testSmokeBombModelId = e;
      console.log("testSmokeBombModeId变动" + this.form.testSmokeBombModelId);
      console.log(this.form.type);
      console.log(this.oilType);
      console.log(this.form.testSmokeBombModeId);
      const selectedOption = this.types.selectAllTestSmokeBombModel.find(
        (option) => option.id === this.form.testSmokeBombModeId
      );
      this.oilType = selectedOption.oilType;
      if (this.showFlavor) {
        await this.selectflavorList();
      } else {
        this.types.flavorList = [];
      }
    },

    async selectBatchDetail(id) {
      try {
        const res = await this.$api.emptyCartridges.selectByOrderId({ id: id });
        if (res.success && res.data) {
          if (res.data.checkUrl) {
            this.checkCustomUrl = 1;
          }
          if (res.data.defaultUrlPopup) {
            this.batchPopupTypeId = 2;
          }
          if (res.data.brandId == null) {
            res.data.brandId = "";
          }
          if (res.data.smokeBombModelId == null) {
            res.data.smokeBombModelId = "";
          }
          this.form = res.data;
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    changeoutputList(data) {
      let arr = [];
      data.forEach((item) => {
        if (item.value) {
          arr.push(item.value);
        }
      });
      this.form.output = arr.join(",");
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (!this.form.type) {
        this.form.type = 3;
      }
      if (this.checkCustomUrl != 1) {
        this.form.checkUrl = "";
      }
      if (this.batchPopupTypeId == 1) {
        this.form.defaultUrlPopup = "";
      }
      if (!this.showFlavor) {
        this.form.smokeBombModelId = "";
      }
      if (!this.showBrand) {
        this.form.brandId = "";
      }
      this.form.output = this.outputList
        .map((item) => item.value * 1000)
        .join(",");

      if (this.editId) {
        //编辑
        this.$api.emptyCartridges
          .smokeRecordOrderUpdate(this.form)
          .then((res) => {
            if (res.success) {
              this.successAlear = true;
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          });
      } else {
        this.$api.emptyCartridges
          .smokeRecordOrderSave(this.form)
          .then((res) => {
            if (res.success) {
              this.successAlear = true;
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          });
      }
    },
    async selectProductList() {
      try {
        const res = await this.$api.Dropdown.selectAllTestSmokeBombModelByType({
          type: this.form.type,
          skuType: this.form.skuType,
          brandId: this.form.brandId,
        });
        if (res.success && res.data) {
          this.types.selectAllTestSmokeBombModel = [
            { modelName: "Please select", id: "", oilType: "" },
          ].concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },

    async selectBrnadList() {
      try {
        this.types.brandNames = [{ id: "", brandName: "Please select" }];
        const res = await this.$api.Dropdown.selectAllBrand({
          skuType: this.form.skuType,
          type: this.form.type,
        });
        if (res.success) {
          for (let i = 0; i < res.data.length; i++) {
            this.types.brandNames.push({
              id: res.data[i].id,
              brandName: res.data[i].brandName,
            });
          }
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async selectflavorList() {
      if (this.oilType == 1 && this.form.type == 3) {
        this.types.flavorList = [];
        try {
          const res = await this.$api.Dropdown.selectFlavorByDeviceId({
            deviceType: this.form.testSmokeBombModeId,
          });

          if (res.success && res.data) {
            this.types.flavorList = [{ id: "", modelName: "Please select" }];
            for (let i = 0; i < res.data.length; i++) {
              this.types.flavorList.push({
                modelName: res.data[i].modelName,
                id: res.data[i].id,
              });
            }
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        } catch (e) {
        } finally {
        }
      } else {
        this.types.flavorList = [];
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.$router.go(-1);
    },
    dateFormatter(val) {
      let dateTime = new Date(val);
      let year = dateTime.getFullYear();
      let month = dateTime.getMonth() + 1;
      let day = dateTime.getDate();
      // let h = dateTime.getHours();
      // let mm = dateTime.getMinutes();
      // let s = dateTime.getSeconds();
      return (
        year + "-" + this.appendZero(month) + "-" + this.appendZero(day)
        // + " " +
        // this.appendZero(h) +
        // ":" +
        // this.appendZero(mm) +
        // ":" +
        // this.appendZero(s)
      );
    },
    appendZero(obj) {
      if (obj < 10) return "0" + "" + obj;
      else return obj;
    },

    onVoltagePlus(index) {
      this.outputList[index].value = parseFloat(
        (this.outputList[index].value + 0.1).toFixed(1)
      );
    },
    onVoltageReduce(index) {
      this.outputList[index].value = parseFloat(
        (this.outputList[index].value - 0.1).toFixed(1)
      );
    },
    cancel() {
      this.$router.go(-1);
    },
    hide() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
.voltage-one {
  margin-top: 10px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
  }
}
.voltage-two {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .voltage-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;

    .voltage-plus {
      margin-bottom: 20px;
    }

    .voltage-reduce {
      margin-top: 16px;
    }
  }
}
.voltage-three {
  margin-top: 66px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
  }
}
</style>