<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row>
        <b-col lg="4">
          <b-form action="#" @submit.prevent="typeForm" @reset="reset_form">
            <!-- 主题名称 -->
            <b-form-group
              label="Subject Name"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="subject-name"
              label-class="requiredRow"
            >
              <b-form-input
                style="width: 200px"
                id="subject-name"
                v-model="typeform.subjectName"
                placeholder="Please enter"
                trim
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
              >
              </b-form-input>
            </b-form-group>
            <!-- 主题备注 -->
            <b-form-group
              label="Brand Notes"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="brand-remark"
            >
              <b-form-textarea
                style="width: 200px"
                id="remark"
                v-model="typeform.remarks"
                placeholder="Enter Notes"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <!--主颜色-->
            <b-form-group
              label="Theme Main Color:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="tags-color"
              label-class="requiredRow"
            >
              <el-color-picker
                v-model="typeform.subjectContent.themeMainColor"
              ></el-color-picker>
            </b-form-group>

            <!--下拉框选中颜色-->
            <b-form-group
              label="Drop Down Active Color:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="tags-color"
              label-class="requiredRow"
            >
              <el-color-picker
                v-model="typeform.subjectContent.dropDownActiveColor"
              ></el-color-picker>
            </b-form-group>

            <!-- 同步按钮颜色-->
            <b-form-group
              label="Sync Button Color:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="tags-color"
              label-class="requiredRow"
            >
              <el-color-picker
                v-model="typeform.subjectContent.syncButtonColor"
              ></el-color-picker>
            </b-form-group>

            <!-- 曲线颜色-->
            <b-form-group
              label="Curve Color:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="tags-color"
              label-class="requiredRow"
            >
              <el-color-picker
                v-model="typeform.subjectContent.curveColor"
              ></el-color-picker>
            </b-form-group>

            <!-- 曲线范围颜色-->
            <b-form-group
              label="CurveLine Color:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="tags-color"
              label-class="requiredRow"
            >
              <el-color-picker
                v-model="typeform.subjectContent.curveLineColor[0]"
              ></el-color-picker>
              <el-color-picker
                v-model="typeform.subjectContent.curveLineColor[1]"
              ></el-color-picker>
            </b-form-group>

            <!--推荐按钮背景颜色-->
            <b-form-group
              label="Mode Normal Background:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="tags-color"
              label-class="requiredRow"
            >
              <el-color-picker
                v-model="typeform.subjectContent.modeNormalBackground"
              ></el-color-picker>
            </b-form-group>

            <!--推荐按钮字体颜色-->
            <b-form-group
              label="Mode Normal Text Color:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="tags-color"
              label-class="requiredRow"
            >
              <el-color-picker
                v-model="typeform.subjectContent.modeNormalTextColor"
              ></el-color-picker>
            </b-form-group>

            <!--推荐按钮选中背景色-->
            <b-form-group
              label="Mode Active Background:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="tags-color"
              label-class="requiredRow"
            >
              <el-color-picker
                v-model="typeform.subjectContent.modeActiveBackground"
              ></el-color-picker>
            </b-form-group>

            <!--推荐按钮选中背景色-->
            <b-form-group
              label="Mode Active Text Color:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="tags-color"
              label-class="requiredRow"
            >
              <el-color-picker
                v-model="typeform.subjectContent.modeActiveTextColor"
              ></el-color-picker>
            </b-form-group>

            <!--Color读取设备圆环的渐变色-->
            <b-form-group
              label="Percent Ring Unused Color:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="tags-color"
              label-class="requiredRow"
            >
              <el-color-picker
                v-model="typeform.subjectContent.percentRingUnusedColor[0]"
              ></el-color-picker>
              <el-color-picker
                v-model="typeform.subjectContent.percentRingUnusedColor[1]"
              ></el-color-picker>
            </b-form-group>

            <!--读取设备圆环的已使用的背景色-->
            <b-form-group
              label="Percent Ring Use Color:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="tags-color"
              label-class="requiredRow"
            >
              <el-color-picker
                v-model="typeform.subjectContent.percentRingUseColor"
              ></el-color-picker>
            </b-form-group>

            <!--圆环文字颜色-->
            <b-form-group
              label="Percent Ring Text Color:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="tags-color"
              label-class="requiredRow"
            >
              <el-color-picker
                v-model="typeform.subjectContent.percentRingTextColor"
              ></el-color-picker>
            </b-form-group>

            <!--取消按钮颜色-->
            <b-form-group
              label="Cancel Button Color:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="tags-color"
              label-class="requiredRow"
            >
              <el-color-picker
                v-model="typeform.subjectContent.cancelButtonColor"
              ></el-color-picker>
            </b-form-group>

            <!--选中tab-->
            <b-form-group
              label="TabButton Active Color:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="tags-color"
              label-class="requiredRow"
            >
              <el-color-picker
                v-model="typeform.subjectContent.tabButtonActiveColor"
              ></el-color-picker>
            </b-form-group>

            <!--没选中tab-->
            <b-form-group
              label="Tab Button Inactive Color:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="tags-color"
              label-class="requiredRow"
            >
              <el-color-picker
                v-model="typeform.subjectContent.tabButtonInactiveColor"
              ></el-color-picker>
            </b-form-group>

            <b-form-group label-cols-lg="3">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
            </b-form-group>
          </b-form>
        </b-col>
        <b-col lg="20" :style="{ ...themeColor }">
          <div width="360px">
            <div class="text-primary text-center">
             <img
                src="http://192.168.254.151:8038/static/files/1702868805456.png"
                alt=""
                height="100%"
                width="100%"
              />
            </div>
            <div @click="onCurveClick">
              <HistoryCurve
                :power="power"
                :max-power="maxPower"
                :resistance="resistance"
                :curve-line-color="typeform.subjectContent.curveLineColor"
                :curve-color="typeform.subjectContent.curveColor"
                :class="elementItemClick[`curve`] ? 'select' : ''"
                width="360px"
                height="200px"
              />
            </div>
            <div @click="onSliderClick">
              <div class="voltage-one">
                <div
                  class="voltage-number"
                  v-for="(item, index) in diyVoltage"
                  :key="index"
                >
                  {{ ((item.value * item.value) / 1000000 / 1.2).toFixed(2) }} w
                </div>
              </div>

              <div
                class="voltage-two"
                style="width: 100%"
                :class="elementItemClick[`slider`] ? 'select' : ''"
              >
                <div
                  class="voltage-list"
                  v-for="(item, index) in diyVoltage"
                  :key="index"
                >
                  <i class="el-icon-plus voltage-plus"></i>

                  <el-slider
                    v-model="item.value"
                    vertical
                    :step="100"
                    disabled
                    :min="limitMinVoltage"
                    :max="limitMaxVoltage"
                    height="80px"
                  >
                  </el-slider>

                  <i class="el-icon-minus voltage-reduce"></i>
                </div>
              </div>

              <div class="voltage-three">
                <div class="voltage-number" v-for="index of 8" :key="index">
                  {{ index }}s
                </div>
              </div>
            </div>
            <div style="margin-top: 16px">
              <div class="custom-curve">
                <el-button
                  @click="onRecommendButtonClick(index)"
                  v-for="(mode, index) in topModeList"
                  :key="mode.id"
                  :class="[
                    index === 0 ? 'recommended-button-active' : '',
                    index === 0
                      ? elementItemClick[`activeButton`]
                        ? 'select'
                        : ''
                      : elementItemClick[`inactiveButton`]
                      ? 'select'
                      : '',
                  ]"
                  class="custom-item"
                >
                  {{ mode.modeName }}
                </el-button>
              </div>
              <div class="custom-curve">
                <el-button
                  v-for="(mode, index) in bottomModeList"
                  :key="mode.id"
                  class="custom-item"
                  :class="elementItemClick[`inactiveButton`] ? 'select' : ''"
                >
                  {{ mode.modeName }}
                </el-button>
              </div>
            </div>

            <div
              class="percent-ring"
              @click="onPercentRingClick"
              :class="elementItemClick[`percentRing`] ? 'select' : ''"
            >
              <PercentRing
                :unused-color="typeform.subjectContent.percentRingUnusedColor"
                :use-color="typeform.subjectContent.percentRingUseColor"
                :text-color="typeform.subjectContent.percentRingTextColor"
              />
              <PercentRing
                :unused-color="typeform.subjectContent.percentRingUnusedColor"
                :use-color="typeform.subjectContent.percentRingUseColor"
                :text-color="typeform.subjectContent.percentRingTextColor"
              />
            </div>

            <div
              @click="onCancelButtonClick"
              :class="elementItemClick[`cancelButton`] ? 'select' : ''"
            >
              <el-button class="cancel-button">Cancel</el-button>
            </div>
            <div
              @click="onSyncButtonClick"
              :class="elementItemClick[`syncButton`] ? 'select' : ''"
            >
              <el-button class="sync-button">Sync to device</el-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { dropzoneOptions, upload_img } from "@/api/upload";
import vue2Dropzone from "vue2-dropzone";
import HistoryCurve from "@/components/HistoryCurve.vue";
import PercentRing from "@/components/PercentRing.vue";
import Images from "../../../../../组件/Admin/src/router/views/ui/images.vue";

export default {
  name: "brand",
  page: {
    title: "brand",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    PercentRing,
    HistoryCurve,
    vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },

  data() {
    return {
      dropzoneOptions: dropzoneOptions,
      title: "Manage Your Subject",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "My Brands",
          // href: "/"
        },
        {
          text: this.$route.params.id ? "Subject Modify" : "Subject Create",
        },
      ],
      typeform: {
        subjectName: "",
        remarks: "",
        subjectContent: {
          themeMainColor: "#3fa7bc",
          dropDownActiveColor: "#3fa7bc",
          syncButtonColor: "#3fa7bc",
          curveColor: "#86A1A9",
          curveLineColor: ["rgba(192, 222, 226, 1)", "rgba(246, 250, 250, 1)"],
          modeNormalBackground: "#3290a0",
          modeNormalTextColor: "#ffffff",
          modeActiveBackground: "#d5efee",
          modeActiveTextColor: "#3290a0",
          percentRingUnusedColor: ["#3290A0", "#9DCCD0"],
          percentRingUseColor: "#F1F2F2",
          percentRingTextColor: "#3290A0",
          cancelButtonColor: "#e8eff2",
          tabButtonActiveColor: "#3fa7bc",
          tabButtonInactiveColor: "#e8eff2",
          preheatActiveColor: "#b9c4c6",
          preheatInactiveColor: "#a7a9ac",
          // themeMainColor: "#85A587", //主色
          // dropDownActiveColor: "#85A587", //下拉框选中的颜色
          // syncButtonColor: "#85A587", //同步按钮颜色
          // curveColor: "#85A587", //曲线颜色
          // curveLineColor: ["#94CF77", "#FFFFFF"], //曲线范围的渐变色
          // modeNormalBackground: "#549559", //推荐按钮背景颜色
          // modeNormalTextColor: "#ffffff", //推荐按钮字体颜色
          // modeActiveBackground: "#E5F6DD", //推荐按钮选中背景色
          // modeActiveTextColor: "#549559", //推荐按钮选中文字颜色
          // percentRingUnusedColor: ["#549559", "#E5F6DD"], //读取设备圆环的渐变色
          // percentRingUseColor: "#F1F2F2", //读取设备圆环的已使用的背景色
          // percentRingTextColor: "#549559", //圆环文字颜色
          // cancelButtonColor: "#EBF2E8", //取消按钮颜色
          // tabButtonActiveColor: "#549559", //激活按钮颜色
          // tabButtonInactiveColor: "#e8eff2", //取消激活按钮颜色
          // preheatActiveColor: "#549559", //预热按钮颜色
          // preheatInactiveColor: "#a7a9ac", //取消预热颜色,
        },
      },
      submit: false,
      typesubmit: false,
      limitMaxVoltage: 3500,
      limitMinVoltage: 350,

      power: [9.63, 9.63, 9.63, 9.63, 9.63, 9.63, 9.63, 9.63],
      diyVoltage: [
        {
          value: 3400,
        },
        {
          value: 3400,
        },
        {
          value: 3400,
        },
        {
          value: 3400,
        },
        {
          value: 3400,
        },
        {
          value: 3400,
        },
        {
          value: 3400,
        },
        {
          value: 3400,
        },
      ],
      maxPower: 12,
      resistance: 1.2,
      value2: 50,
      topModeList: [
        {
          id: 1,
          modeName: "THC-A",
        },
        {
          id: 2,
          modeName: "THC-B",
        },
      ],

      bottomModeList: [
        {
          id: 1,
          modeName: "THC-A",
        },
        {
          id: 2,
          modeName: "THC-B",
        },
        {
          id: 3,
          modeName: "THC-C",
        },
        {
          id: 4,
          modeName: "THC-D",
        },
      ],

      curveChart: null,

      elementItemClick: {
        curve: false,
        slider: false,
        activeButton: false,
        inactiveButton: false,
        percentRing: false,
        cancelButton: false,
        syncButton: false,
      },
    };
  },
  computed: {
    is_add: function () {
      return /add/i.test(location.href);
    },
    themeColor() {
      return {
        "--themeMainColor": this.typeform.subjectContent.themeMainColor,
        "--buttonColor": this.typeform.subjectContent.buttonColor,
        "--curveColor": this.typeform.subjectContent.curveColor,
        "--syncButtonColor": this.typeform.subjectContent.syncButtonColor,
        "--preheatActiveColor": this.typeform.subjectContent.preheatActiveColor,
        "--preheatInactiveColor":
          this.typeform.subjectContent.preheatInactiveColor,
        "--modeNormalBackground":
          this.typeform.subjectContent.modeNormalBackground,
        "--modeNormalTextColor":
          this.typeform.subjectContent.modeNormalTextColor,
        "--modeActiveBackground":
          this.typeform.subjectContent.modeActiveBackground,
        "--modeActiveTextColor":
          this.typeform.subjectContent.modeActiveTextColor,
        "--dropDownActiveColor":
          this.typeform.subjectContent.dropDownActiveColor,
        "--cancelButtonColor": this.typeform.subjectContent.cancelButtonColor,
        "--tabButtonActiveColor":
          this.typeform.subjectContent.tabButtonActiveColor,
        "--tabButtonInactiveColor":
          this.typeform.subjectContent.tabButtonInactiveColor,
      };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    show1() {
      this.typeform = {
        id: this.$route.params.id,
        subjectName: this.$route.params.subjectName,
        subjectContent: JSON.parse(this.$route.params.subjectContent),
        remarks: this.$route.params.remarks,
      };
    },
    init() {
      if (this.is_add) {
      } else {
        this.show1();
      }
    },
    add() {
      this.$api.subject.saveSubject(this.typeform).then((res) => {
        if (res.success) {
          this.$toastBack(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    update() {
      const typeform = { ...this.typeform };
      typeform.subjectContent = JSON.stringify(this.typeform.subjectContent);
      this.$api.subject.saveSubject(typeform).then((res) => {
        if (res.success) {
          this.$toastBack(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    rangeform(e) {
      this.submit = true;
      this.$v.$touch();
    },
    typeForm(e) {
      this.typesubmit = true;
      if (this.is_add) {
        this.add();
      } else {
        this.update();
      }
    },
    reset_form() {
      this.$router.go(-1);
    },
    resetClickItem() {
      for (const elementItemClickKey in this.elementItemClick) {
        this.elementItemClick[elementItemClickKey] = false;
      }
    },
    //曲线点击
    onCurveClick() {
      this.resetClickItem();
      this.elementItemClick["curve"] = true;
    },
    onSliderClick() {
      this.resetClickItem();
      this.elementItemClick["slider"] = true;
    },
    onRecommendButtonClick(index) {
      if (index === 0) {
        this.onActiveButtonClick();
      } else {
        this.onInActiveButtonClick();
      }
    },
    onActiveButtonClick() {
      this.resetClickItem();
      this.elementItemClick["activeButton"] = true;
    },
    onInActiveButtonClick() {
      this.resetClickItem();
      this.elementItemClick["inactiveButton"] = true;
    },
    onPercentRingClick() {
      this.resetClickItem();
      this.elementItemClick["percentRing"] = true;
    },
    onCancelButtonClick() {
      this.resetClickItem();
      this.elementItemClick["cancelButton"] = true;
    },
    onSyncButtonClick() {
      this.resetClickItem();
      this.elementItemClick["syncButton"] = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropzone {
  width: 150px;
  height: 150px;
  border: 1px dashed #ced4da !important;
}

.dropzone .dz-message {
  margin: 1em 0;
}

.dropzone .dz-message {
  font-size: 16px !important;
}

.dropzone .dz-message {
  color: #ced4da;
  margin: 27px 0 !important;
}

.dropzone .dz-preview {
  margin: -6px;
}

.voltage-two {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .voltage-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;

    .voltage-plus {
      margin-bottom: 20px;
    }

    .voltage-reduce {
      margin-top: 16px;
    }

    ::v-deep .el-slider__button {
      border: 2px solid var(--curveColor) !important;
    }

    ::v-deep .el-slider__bar {
      background: var(--curveColor) !important;
    }
  }
}

.voltage-one {
  margin-top: 10px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
  }
}

.voltage-three {
  margin-top: 10px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
  }
}

.temperature {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-curve {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin-top: 8px;

  .custom-item {
    flex: 1;
    height: 44px;
    line-height: 44px;
    border-radius: 6px;
    font-size: 16px;
    text-align: center;
    padding: 0;
    word-break: break-all;
    line-height: 13px;
    background: var(--modeNormalBackground);
    color: var(--modeNormalTextColor);
  }

  .recommended-button-active {
    background: var(--modeActiveBackground) !important;
    color: var(--modeActiveTextColor) !important;
  }
}
.percent-ring {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}
.cancel-button {
  background: var(--cancelButtonColor);
  color: #939598;
}

.sync-button {
  background: var(--syncButtonColor);
  color: white;
}

.select {
  //border: 2px solid red;
}
</style>
