<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />
    <b-form @submit="onSubmit" @reset="onReset">
      <b-card>
        <b-row>
          <b-col cols="10">
            <!-- 曲线名称 -->
            <FormRow>
              <b-form-group
                label="Voltage Curves Name:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="curves-name"
                label-class="requiredRow"
              >
                <b-form-input
                  id="curves-name"
                  v-model="form.groupName"
                  placeholder="Please enter"
                  required
                  maxlength="50"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.groupName ? form.groupName.length : 0"
                  max="50"
                />
              </template>
            </FormRow>
            <!-- 曲线描述 -->
            <FormRow>
              <b-form-group
                label="Voltage Curves Description:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="curves-description"
                label-class="requiredRow"
              >
                <b-form-input
                  id="curves-description"
                  v-model="form.description"
                  placeholder="Please enter"
                  required
                  maxlength="50"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.description ? form.description.length : 0"
                  max="50"
                />
              </template>
            </FormRow>
            <!-- SKU -->
            <FormRow v-if="deviceId == 0">
              <b-form-group
                label="Be Available For:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="productId"
                label-class="requiredRow"
              >
                <b-form-select
                  v-model="form.deviceId"
                  required
                  :options="types.skuList"
                  @change="changeDevice()"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                ></b-form-select>
              </b-form-group>
            </FormRow>
            <FormRow>
              <b-form-group
                label="Notes:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="notes"
                label-class="requiredRow"
              >
                <b-form-textarea
                  id="notes"
                  v-model="form.remarks"
                  placeholder="Please enter"
                  required
                  rows="10"
                  max-rows="10"
                  maxlength="100"
                ></b-form-textarea>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.remarks ? form.remarks.length : 0"
                  max="100"
                />
              </template>
            </FormRow>
            <!-- 状态 -->
            <FormRow>
              <b-form-group
                label="Status:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="status"
                label-class="requiredRow"
              >
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="form.status"
                  :options="types.status"
                  name="status"
                  style="padding-top: 7px"
                >
                </b-form-radio-group>
              </b-form-group>
            </FormRow>
          </b-col>
        </b-row>
      </b-card>

      <b-card v-if="form.deviceId">
        <b-row class="align-items-center">
          <b-col cols="12">
            <div class="blockquote">
              <h4>Set Voltage Curves:</h4>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="7">
            <b-form-group
              label-cols-lg="3"
              label-align-lg="right"
              label-for="curveOne"
            >
              <div style="padding-bottom: 40px">
                <button
                  type="button"
                  v-for="(item, index) in btnList"
                  :key="index"
                  :style="{
                    backgroundColor:
                      activeIndex === index ? 'rgba(255, 255, 255, 1)' : '',
                    color: activeIndex === index ? '#000000' : '',
                  }"
                  class="buttonStyle"
                  @click="clickBtn(index)"
                >
                  {{ item }}
                </button>
              </div>
              <HistoryCurve :width="'680px'" :power="types.power" />

              <div v-if="activeIndex == 0">
                <div class="voltage-one">
                  <div
                    class="voltage-number"
                    v-for="(item, index) in temperatureList"
                    :key="index"
                  >
                    {{
                      parseFloat(
                        (
                          Math.pow(item.value, 2) / parseFloat(resistance)
                        ).toFixed(1)
                      )
                    }}
                    w
                    <!-- {{ item.value }} v -->
                  </div>
                </div>

                <div class="voltage-two" style="width: 100%">
                  <div
                    class="voltage-list"
                    v-for="(item, index) in temperatureList"
                    :key="index"
                  >
                    <i
                      class="el-icon-plus voltage-plus"
                      @click="onVoltagePlus(index)"
                    ></i>

                    <el-slider
                      v-model="item.value"
                      vertical
                      :step="step"
                      :min="min"
                      :max="max"
                      @change="power()"
                      height="150px"
                    >
                    </el-slider>

                    <i
                      class="el-icon-minus voltage-reduce"
                      @click="onVoltageReduce(index)"
                    ></i>
                  </div>
                </div>

                <div class="voltage-three">
                  <div class="voltage-number" v-for="index of 8" :key="index">
                    {{ index }}s
                  </div>
                </div>
              </div>

              <div v-if="activeIndex == 1">
                <div class="voltage-one">
                  <div
                    class="voltage-number"
                    v-for="(item, index) in temperatureList1"
                    :key="index"
                  >
                    {{
                      parseFloat(
                        (
                          Math.pow(item.value, 2) / parseFloat(resistance)
                        ).toFixed(1)
                      )
                    }}
                    w
                    <!-- {{ item.value }} v -->
                  </div>
                </div>

                <div class="voltage-two" style="width: 100%">
                  <div
                    class="voltage-list"
                    v-for="(item, index) in temperatureList1"
                    :key="index"
                  >
                    <i
                      class="el-icon-plus voltage-plus"
                      @click="onVoltagePlus1(index)"
                    ></i>

                    <el-slider
                      v-model="item.value"
                      vertical
                      :step="step"
                      :min="min"
                      :max="max"
                      @change="power()"
                      height="150px"
                    >
                    </el-slider>

                    <i
                      class="el-icon-minus voltage-reduce"
                      @click="onVoltageReduce1(index)"
                    ></i>
                  </div>
                </div>

                <div class="voltage-three">
                  <div class="voltage-number" v-for="index of 8" :key="index">
                    {{ index }}s
                  </div>
                </div>
              </div>
              <div v-if="activeIndex == 2">
                <div class="voltage-one">
                  <div
                    class="voltage-number"
                    v-for="(item, index) in temperatureList2"
                    :key="index"
                  >
                    {{
                      parseFloat(
                        (
                          Math.pow(item.value, 2) / parseFloat(resistance)
                        ).toFixed(1)
                      )
                    }}
                    w
                    <!-- {{ item.value }} v -->
                  </div>
                </div>

                <div class="voltage-two" style="width: 100%">
                  <div
                    class="voltage-list"
                    v-for="(item, index) in temperatureList2"
                    :key="index"
                  >
                    <i
                      class="el-icon-plus voltage-plus"
                      @click="onVoltagePlus2(index)"
                    ></i>

                    <el-slider
                      v-model="item.value"
                      vertical
                      :step="step"
                      :min="min"
                      :max="max"
                      @change="power()"
                      height="150px"
                    >
                    </el-slider>

                    <i
                      class="el-icon-minus voltage-reduce"
                      @click="onVoltageReduce2(index)"
                    ></i>
                  </div>
                </div>

                <div class="voltage-three">
                  <div class="voltage-number" v-for="index of 8" :key="index">
                    {{ index }}s
                  </div>
                </div>
              </div>
              <p>
                The Voltage curve is between 0.4v-3.7v, after the 8th second,
                the same as the 8th second
              </p>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-form-group label-cols-lg="3">
        <b-button type="submit" variant="primary" class="custom-width"
          >Save</b-button
        >
        <b-button type="reset" class="ml-4 custom-width">Cancel </b-button>
      </b-form-group>
    </b-form>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel"
      @hide="hide"
    >
      Saved successfully
    </Dialog>
    <!-- 错误提示框 -->
    <Dialog
      v-model="errorAlear"
      :imageSrc="require('@/assets/confirm.svg')"
      noBtn="OK"
      @cancel="cancel1"
      @hide="hide1"
    >
      {{ errorContent }}
    </Dialog>
  </Layout>
</template>

<style scoped>
.pd {
  padding-bottom: 10px;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img } from "@/api/upload";
import FormRow from "@/components/FormRow.vue";
import CountText from "@/components/CountText.vue";
import Dialog from "@/components/Dialog";
import CreateProduct from "@/components/product/CreateProduct.vue";
import ImageComponets from "@/components/brand/ImageComponets.vue";
import HistoryCurve from "@/components/HistoryCurve.vue";

/**
 * FAQ Add component
 */
export default {
  components: {
    CountText,
    FormRow,
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    Dialog,
    CreateProduct,
    ImageComponets,
    HistoryCurve,
  },
  data() {
    return {
      page: {
        title: this.editId
          ? "Modify Recommend Curves"
          : "Create Recommend Curves",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "Client&Brand",
          },
          {
            text: "Curves Mgmt",
          },
          {
            text: this.$route.query.id
              ? "Modify Recommend Curves"
              : "Create Recommend Curves",
            active: true,
          },
        ],
      },
      form: {
        id: "",
        groupName: "",
        brandId: this.$route.query.brandId,
        deviceId:
          this.$route.query.brandSeriesId == 0
            ? ""
            : this.$route.query.brandSeriesId,
        description: "",
        status: 0,
        addCurveVos: [],
        remarks: "",
      },
      btnList: ["Light", "Smooth", "Strong"],
      activeIndex: 0,
      temperatureList: [
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
      ],
      temperatureList1: [
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
      ],
      temperatureList2: [
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
      ],
      min: 0.4,
      max: 3.7,
      step: 0.1,
      resistance: "1.2",
      deviceId: this.$route.query.brandSeriesId,
      types: {
        status: [
          { text: "Enabled", value: 0, disabled: false },
          { text: "Disabled", value: 1, disabled: false },
        ],
        brandOpton: [{ text: "--All Brand--", value: "", disabled: false }],
        skuList: [{ text: "All Sku", value: "", disabled: false }],
        power: [],
      },
      successAlear: false,
      errorAlear: false,
      errorContent: "",
    };
  },
  mounted() {
    this.init();
  },
  computed: {},
  methods: {
    onReady: onReady,
    async init() {
      if (this.$route.query.id) {
        await this.selectCurveGroupById();
      }
      await this.selectAllSku();
    },
    selectAllBrand() {
      this.$api.Dropdown.selectAllSkuBrand()
        .then((x) => {
          this.types.brandOpton = this.types.brandOpton.concat(
            x.data.map((item) => {
              return {
                text: item.brandName,
                value: item.id,
                disabled: false,
              };
            })
          );
        })
        .catch((e) => {
          this.has_data = false;
        });
    },
    async selectAllSku() {
      try {
        var id = this.$route.query.id;
        const x = await this.$api.Dropdown.selectAllSku();
        if (x.success && x.data) {
          this.types.skuList = this.types.skuList.concat(
            x.data.map((item) => {
              if (id && item.id == this.form.deviceId) {
                this.resistance = item.resistance;
                this.power();
              }
              return {
                text: item.deviceName,
                value: item.id,
                resistance: item.resistance,
                disabled: false,
              };
            })
          );
        }
      } catch (e) {
        this.has_data = false;
        console.log(e);
      }
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.pushCurve();
      if (this.$route.query.id) {
        this.updateCurveGroup();
      } else {
        this.pushCurve();
        this.saveCurveGroup();
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        id: "",
        groupName: "",
        brandId: "",
        device_id: "",
        description: "",
        status: "",
        remarks: "",
      };
      this.$nextTick(() => {
        this.$router.go(-1);
      });
    },

    changeDevice() {
      this.resistance = this.types.skuList.find(
        (item) => item.value === this.form.deviceId
      ).resistance;
      this.power();
    },
    onVoltagePlus(index) {
      this.temperatureList[index].value = parseFloat(
        (this.temperatureList[index].value + 0.1).toFixed(1)
      );
      this.power();
    },
    onVoltageReduce(index) {
      this.temperatureList[index].value = parseFloat(
        (this.temperatureList[index].value - 0.1).toFixed(1)
      );
      this.power();
    },
    onVoltagePlus1(index) {
      this.temperatureList1[index].value = parseFloat(
        (this.temperatureList1[index].value + 0.1).toFixed(1)
      );
      this.power();
    },
    onVoltageReduce1(index) {
      this.temperatureList1[index].value = parseFloat(
        (this.temperatureList1[index].value - 0.1).toFixed(1)
      );
      this.power();
    },
    onVoltagePlus2(index) {
      this.temperatureList2[index].value = parseFloat(
        (this.temperatureList2[index].value + 0.1).toFixed(1)
      );
      this.power();
    },
    onVoltageReduce2(index) {
      this.temperatureList2[index].value = parseFloat(
        (this.temperatureList2[index].value - 0.1).toFixed(1)
      );
      this.power();
    },
    async selectCurveGroupById() {
      try {
        const res = await this.$api.Client.selectCurveGroupById({
          id: this.$route.query.id,
        });
        if (res.success) {
          this.form = {
            id: res.data.id,
            groupName: res.data.groupName,
            brandId: res.data.brandId,
            deviceId: res.data.deviceId,
            description: res.data.description,
            status: res.data.status,
            addCurveVos: res.data.addCurveVos,
            remarks: res.data.remarks,
          };
          var temperatureList = [];
          var temperatureList1 = [];
          var temperatureList2 = [];
          res.data.addCurveVos[0].temperature.split(",").map((item) => {
            temperatureList.push({ value: item / 1000 });
          });
          res.data.addCurveVos[1].temperature.split(",").map((item) => {
            temperatureList1.push({ value: item / 1000 });
          });
          res.data.addCurveVos[2].temperature.split(",").map((item) => {
            temperatureList2.push({ value: item / 1000 });
          });
          this.temperatureList = temperatureList;
          this.temperatureList1 = temperatureList1;
          this.temperatureList2 = temperatureList2;
          this.power();
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      } catch (e) {
        console.log(e);
        this.$bvToast.toast(res.message);
      }
    },
    pushCurve() {
      if (this.$route.query.id) {
        var addCurveVosList = this.form.addCurveVos;
        addCurveVosList[0].temperature = this.temperatureList
          .map((item) => item.value * 1000)
          .join(",");

        addCurveVosList[1].temperature = this.temperatureList1
          .map((item) => item.value * 1000)
          .join(",");

        addCurveVosList[2].temperature = this.temperatureList2
          .map((item) => item.value * 1000)
          .join(",");
        this.form.addCurveVos = addCurveVosList;
      } else {
        var addCurveVosList = [];
        addCurveVosList.push({
          curveName: "Light",
          temperature: this.temperatureList
            .map((item) => item.value * 1000)
            .join(","),
        });
        addCurveVosList.push({
          curveName: "Smooth",
          temperature: this.temperatureList1
            .map((item) => item.value * 1000)
            .join(","),
        });
        addCurveVosList.push({
          curveName: "Strong",
          temperature: this.temperatureList2
            .map((item) => item.value * 1000)
            .join(","),
        });
        this.form.addCurveVos = addCurveVosList;
      }
    },
    saveCurveGroup() {
      this.$api.Client.saveCurveGroup(this.form).then((res) => {
        if (res.success) {
          this.successAlear = true;
        } else {
          if (res.message) {
            this.errorAlear = true;
            this.errorContent = res.message;
          }
        }
      });
    },
    updateCurveGroup() {
      this.$api.Client.updateCurveGroup(this.form).then((res) => {
        if (res.success) {
          this.successAlear = true;
        } else {
          if (res.message) {
            this.errorAlear = true;
            this.errorContent = res.message;
          }
        }
      });
    },
    power() {
      if (this.activeIndex == 0) {
        this.types.power = this.temperatureList.map((item) => {
          return parseFloat(
            (Math.pow(item.value, 2) / parseFloat(this.resistance)).toFixed(1)
          );
        });
      } else if (this.activeIndex == 1) {
        this.types.power = this.temperatureList1.map((item) => {
          return parseFloat(
            (Math.pow(item.value, 2) / parseFloat(this.resistance)).toFixed(1)
          );
        });
      } else {
        this.types.power = this.temperatureList2.map((item) => {
          return parseFloat(
            (Math.pow(item.value, 2) / parseFloat(this.resistance)).toFixed(1)
          );
        });
      }
    },
    clickBtn(index) {
      this.activeIndex = index;
      this.power();
    },
    cancel() {
      this.$router.go(-1);
    },
    hide() {
      this.$router.go(-1);
    },
    cancel1() {
      this.errorAlear = false;
    },
    hide1() {
      this.errorAlear = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.required-icon {
  ::before {
    content: "*";
  }
}

::v-deep .van-slider {
  margin: 10px auto;
}

.slider-button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ffffff;
}

.voltage-two {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .voltage-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;

    .voltage-plus {
      margin-bottom: 20px;
    }

    .voltage-reduce {
      margin-top: 16px;
    }
  }
}
.voltage-one {
  margin-top: 10px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
  }
}
.voltage-three {
  margin-top: 66px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: hsla(0, 19%, 58%, 0);
  }
}
.temperature {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-slider__button {
  border: 2px solid #86a1a9;
}
::v-deep .el-slider__bar {
  background: #86a1a9;
}
.buttonStyle {
  width: 173px;
  height: 51px;
  border: 1px solid #d3d3d3;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  font-family: "Arial Negreta", "Arial Normal", "Arial", sans-serif;
  background-color: rgba(2, 167, 240, 1);
  margin-left: 2px;
}
</style>
