<!--
 * @Author: Code-HHX
 * @Date: 2022-04-26 09:38:36
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-26 16:28:41
 * @Description: 
-->
<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />
    <b-form @submit="onSubmit" @reset="onReset">
      <b-card>
        <b-row>
          <b-col cols="10">
            <!-- 公司名称 -->
            <FormRow>
              <b-form-group
                label="Company Name:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="company-name"
                label-class="requiredRow"
              >
                <b-form-input
                  id="company-name"
                  v-model="form.companyName"
                  placeholder="Please enter"
                  required
                  maxlength="100"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.companyName ? form.companyName.length : 0"
                  max="100"
                />
              </template>
            </FormRow>

            <FormRow>
              <b-form-group
                label="Company Address:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="company-address"
                label-class="requiredRow"
              >
                <b-form-select
                  id="company-address"
                  v-model="form.countryId"
                  :options="types.countryList"
                  value-field="id"
                  text-field="name"
                  required
                  @change="selectAddress(form.countryId, 1)"
                  oninvalid="setCustomValidity('Please select');"
                  oninput="setCustomValidity('')"
                  class="col-md-3"
                ></b-form-select>
                <b-form-select
                  id="company-state"
                  v-model="form.stateId"
                  :options="types.stateList"
                  value-field="id"
                  text-field="name"
                  required
                  oninvalid="setCustomValidity('Please select');"
                  oninput="setCustomValidity('')"
                  class="ml-4 col-md-3"
                ></b-form-select>
              </b-form-group>
            </FormRow>

            <FormRow>
              <b-form-group
                label="City Name:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="city-name"
                label-class="requiredRow"
              >
                <b-form-input
                  id="city-name"
                  v-model="form.cityName"
                  placeholder="Please enter"
                  required
                  maxlength="50"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.cityName ? form.cityName.length : 0"
                  max="50"
                />
              </template>
            </FormRow>

            <FormRow>
              <b-form-group
                label="Zip Code:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="zip-code"
                label-class="requiredRow"
              >
                <b-form-input
                  id="zip-code"
                  v-model="form.zipCode"
                  placeholder="Please enter"
                  required
                  maxlength="10"
                  type="number"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.zipCode ? form.zipCode.length : 0"
                  max="10"
                />
              </template>
            </FormRow>

            <FormRow>
              <b-form-group
                label="Specific Address:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="company-name"
                label-class="requiredRow"
              >
                <b-form-input
                  id="company-name"
                  v-model="form.address"
                  placeholder="Please enter"
                  required
                  maxlength="200"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.address ? form.address.length : 0"
                  max="200"
                />
              </template>
            </FormRow>
            <!-- 公司业务-->
            <FormRow>
              <b-form-group
                label="Company Business:"
                ref="companyBusiness"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="company-business"
                label-class="requiredRow"
              >
                <div class="d-flex flex-wrap">
                  <b-form-checkbox
                    v-for="(label, index) in types.companyBusiness"
                    :required="true"
                    :key="index"
                    v-model="form.companyBusinessId"
                    :value="label.value"
                    class="mr-2 mt-2"
                  >
                    {{ label.text }}
                  </b-form-checkbox>
                </div>
              </b-form-group>
            </FormRow>
            <!-- 登录账号 -->
            <FormRow>
              <b-form-group
                label="Company Email:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="email-address"
                label-class="requiredRow"
              >
                <b-form-input
                  id="email-address"
                  v-model="form.email"
                  placeholder="Please enter"
                  required
                  maxlength="100"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.email ? form.email.length : 0"
                  max="100"
                />
              </template>
            </FormRow>
            <FormRow>
              <b-form-group
                label="Company phone:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="company-phone"
                label-class="requiredRow"
              >
                <b-form-input
                  id="company-phone"
                  v-model="form.phoneNumber"
                  placeholder="Please enter"
                  required
                  maxlength="20"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                  type="number"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.phoneNumber ? form.phoneNumber.length : 0"
                  max="20"
                />
              </template>
            </FormRow>
            <!-- 公司联系人 -->
            <FormRow>
              <b-form-group
                label="Company Contacts:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="company-contacts"
                label-class="requiredRow"
              >
                <b-form-input
                  id="company-contacts"
                  v-model="form.name"
                  placeholder="Please enter"
                  required
                  maxlength="50"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText :len="form.name ? form.name.length : 0" max="50" />
              </template>
            </FormRow>
            <!-- 公司简介 -->
            <FormRow>
              <b-form-group
                label="Company Introduction:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="company-introduction"
                label-class="requiredRow"
              >
                <b-form-textarea
                  id="company-introduction"
                  v-model="form.companyIntroduction"
                  placeholder="Please enter"
                  rows="10"
                  max-rows="10"
                  maxlength="2000"
                ></b-form-textarea>
              </b-form-group>
              <template #right>
                <CountText
                  :len="
                    form.companyIntroduction
                      ? form.companyIntroduction.length
                      : 0
                  "
                  max="2000"
                />
              </template>
            </FormRow>
            <!-- 选择角色 -->
            <!-- <FormRow>
              <b-form-group
                label="Client Type:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="client-type"
                label-class="requiredRow"
              >
                <b-form-select
                  id="client-type"
                  v-model="form.supplierRoleId"
                  :options="types.clientTypeList"
                  value-field="value"
                  text-field="text"
                  required
                  oninvalid="setCustomValidity('Please select');"
                  oninput="setCustomValidity('');"
                >
                </b-form-select>
              </b-form-group>
            </FormRow> -->
            <!-- 状态 -->
            <FormRow>
              <b-form-group
                label="Status:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="status"
                label-class="requiredRow"
              >
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="form.status"
                  :options="types.status"
                  name="status"
                  style="padding-top: 7px"
                >
                </b-form-radio-group>
              </b-form-group>
            </FormRow>
            <FormRow v-if="editType">
              <b-form-group
                label="Brand:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="company-introduction"
              >
                <b-row cols-xl="3" style="margin-left: -10px">
                  <b-col
                    class="pd cursor"
                    v-for="(item, i) in brandList"
                    :key="i"
                  >
                    <ImageComponets :image="item.brandPicture" />
                  </b-col>
                  <b-col class="pd cursor">
                    <CreateProduct @click="gotoAdd(item)" name="Create Brand" />
                  </b-col>
                </b-row>
              </b-form-group>
            </FormRow>
            <!-- 修改密码弹框 -->
            <b-modal
              ref="modal"
              v-model="modalShow"
              title="Edit Password"
              @hidden="resetModal"
              hide-header
              hide-footer
              centered
            >
              <form ref="form" @submit="onSure" @reset="onClose">
                <b-form-group
                  label="Password:"
                  label-cols-lg="4"
                  label-align-lg="right"
                  label-for="password"
                  label-class="requiredRow"
                >
                  <b-form-input
                    id="password"
                    v-model="modalData.password"
                    trim
                    required
                    placeholder="Enter password"
                    type="password"
                    :state="
                      modalData.password &&
                      modalData.password.length >= 6 &&
                      modalData.password.length <= 60
                    "
                    oninvalid="setCustomValidity('Please enter');"
                    oninput="setCustomValidity('')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Confirm password:"
                  label-cols-lg="4"
                  label-align-lg="right"
                  label-for="password"
                  label-class="requiredRow"
                >
                  <b-form-input
                    id="password"
                    v-model="modalData.confirmPassword"
                    trim
                    required
                    placeholder="Enter password"
                    type="password"
                    :state="
                      modalData.confirmPassword &&
                      modalData.confirmPassword.length >= 6 &&
                      modalData.confirmPassword.length <= 60 &&
                      modalData.password == modalData.confirmPassword
                    "
                    oninvalid="setCustomValidity('Please enter');"
                    oninput="setCustomValidity('');"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-cols-lg="4">
                  <b-button type="submit" variant="primary" class="custom-width"
                    >Save</b-button
                  >
                  <b-button type="reset" class="ml-4 custom-width"
                    >Cancel
                  </b-button>
                </b-form-group>
              </form>
            </b-modal>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <b-row class="align-items-center">
          <b-col cols="12">
            <div class="blockquote">
              <h4>
                Create an admin account to log in the Omni management system:
              </h4>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="10">
            <FormRow>
              <b-form-group
                label="Account Email:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="account-email"
                label-class="requiredRow"
              >
                <b-form-input
                  id="account-email"
                  v-model="form.username"
                  trim
                  required
                  maxlength="100"
                  placeholder="Please enter"
                  :state="
                    form.username &&
                    form.username.length >= 6 &&
                    form.username.length <= 100
                  "
                  :disabled="editType"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.username ? form.username.length : 0"
                  max="100"
                />
              </template>
            </FormRow>
            <FormRow>
              <b-form-group
                label="First Name:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="first-name"
                label-class="requiredRow"
              >
                <b-form-input
                  id="first-name"
                  v-model="form.firstName"
                  trim
                  required
                  placeholder="Please enter"
                  maxlength="60"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.firstName ? form.firstName.length : 0"
                  max="60"
                />
              </template>
            </FormRow>

            <FormRow>
              <b-form-group
                label="Last Name:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="last-name"
                label-class="requiredRow"
              >
                <b-form-input
                  id="last-name"
                  v-model="form.lastName"
                  trim
                  required
                  placeholder="Please enter"
                  maxlength="60"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="form.lastName ? form.lastName.length : 0"
                  max="60"
                />
              </template>
            </FormRow>

            <!-- 密码 -->
            <FormRow>
              <b-form-group
                label="Password:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="password"
                label-class="requiredRow"
              >
                <b-form-input
                  id="password"
                  v-model="form.password"
                  trim
                  required
                  placeholder="Please enter"
                  type="password"
                  :disabled="editType"
                  maxlength="60"
                  :state="
                    form.password &&
                    form.password.length >= 6 &&
                    form.password.length <= 60
                  "
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                ></b-form-input>
                <b-button
                  v-if="editType"
                  variant="outline-secondary"
                  @click="modalShow = !modalShow"
                  >Reset Passwords
                </b-button>
              </b-form-group>
              <template #right v-if="!editType">
                <CountText
                  :len="form.password ? form.password.length : 0"
                  max="60"
                />
              </template>
            </FormRow>

            <!-- 确认密码 -->
            <FormRow v-if="!editType">
              <b-form-group
                label="Confirm Password:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="password"
                label-class="requiredRow"
              >
                <b-form-input
                  id="password"
                  v-model="confirmPassword"
                  trim
                  required
                  placeholder="Please enter"
                  type="password"
                  maxlength="60"
                  :state="
                    confirmPassword &&
                    confirmPassword.length >= 6 &&
                    confirmPassword.length <= 60 &&
                    form.password == confirmPassword
                  "
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="confirmPassword ? confirmPassword.length : 0"
                  max="60"
                />
              </template>
            </FormRow>
          </b-col>
        </b-row>
      </b-card>
      <b-form-group label-cols-lg="3">
        <b-button type="submit" variant="primary" class="custom-width"
          >Save</b-button
        >
        <b-button type="reset" class="ml-4 custom-width">Cancel </b-button>
      </b-form-group>
    </b-form>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel"
      @hide="hide"
    >
      Saved successfully
    </Dialog>
    <!-- 错误提示框 -->
    <Dialog
      v-model="errorAlear"
      :imageSrc="require('@/assets/confirm.svg')"
      noBtn="OK"
      @cancel="cancel1"
      @hide="hide1"
    >
      {{ errorContent }}
    </Dialog>
  </Layout>
</template>

<style scoped>
.pd {
  padding-bottom: 10px;
}
</style>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img } from "@/api/upload";
import FormRow from "@/components/FormRow.vue";
import CountText from "@/components/CountText.vue";
import Dialog from "@/components/Dialog";
import CreateProduct from "@/components/product/CreateProduct.vue";
import ImageComponets from "@/components/brand/ImageComponets.vue";

/**
 * FAQ Add component
 */
export default {
  components: {
    CountText,
    FormRow,
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    Dialog,
    CreateProduct,
    ImageComponets,
  },
  data() {
    return {
      page: {
        title: this.editId ? "Modify Client" : "Create Client",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "Client&Brand",
          },
          {
            text: "Client Mgmt",
          },
          {
            text: "Client List",
            active: true,
          },
          {
            text: this.editId ? "Modify Client" : "Create Client",
            active: true,
          },
        ],
      },
      form: {
        brandName: "",
        companyName: "",
        email: "",
        name: "",
        phoneNumber: "",
        password: "",
        picture: "",
        picture: "",
        supplierRoleId: "", //客户角色
        status: 0,
        countryId: "",
        stateId: "",
        cityName: "",
        address: "",
        companyBusinessId: [],
        companyIntroduction: "",
        username: "",
        firstName: "",
        lastName: "",
        zipCode: "",
      },
      types: {
        clientTypeList: [{ text: "Please select", value: "" }],
        status: [
          { text: "Enabled", value: 0, disabled: false },
          { text: "Disabled", value: 1, disabled: false },
        ],
        companyBusiness: [
          { text: "CBD/THC Concentrates", value: 2 },
          { text: "Nicotine", value: 1 },
        ],
        countryList: [],
        stateList: [{ id: "", name: "Please select" }],
      },
      modalShow: false,
      modalData: {
        password: "",
        confirmPassword: "",
      },
      editor: ClassicEditor,
      editId: window.location.search,
      editType: false,
      photoName: "",
      confirmPassword: "",
      successAlear: false,
      errorAlear: false,
      errorContent: "",
      brandList: [],
      supplierId: "",
    };
  },
  beforeCreate() {
    this.editId = this.$route.query.id;
  },
  created() {
    if (this.editId) {
      this.editId = this.$route.query.id;
      this.editType = true;
      this.formData(this.editId);
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    onReady: onReady,
    init() {
      this.selectAddress(null, 2);
      // this.$api.Dropdown.supplierRoleList()
      //   .then((res) => {
      //     if (res.success) {
      //       for (let i = 0; i < res.data.length; i++) {
      //         this.types.clientTypeList.push({
      //           text: res.data[i].roleName,
      //           value: res.data[i].id,
      //         });
      //       }
      //     } else {
      //       if (res.message) {
      //         this.$bvToast.toast(res.message);
      //       }
      //     }
      //   })
      //   .catch((res) => {
      //     if (res.message) this.$bvToast.toast(res.message);
      //   });
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.editId) {
        if (this.form.companyBusinessId.length == 0) {
          this.errorAlear = true;
          this.errorContent = "The company business  is required";
        } else {
          this.$api.Client.updateClientAccount(this.form).then((res) => {
            if (res.success) {
              this.successAlear = true;
            } else {
              if (res.message) {
                this.errorAlear = true;
                this.errorContent = res.message;
              }
            }
          });
        }
      } else {
        if (this.form.password != this.confirmPassword) {
          this.errorAlear = true;
          this.errorContent = "The two passwords are inconsistent.";
        } else if (this.form.companyBusinessId.length == 0) {
          this.errorAlear = true;
          this.errorContent = "The company business  is required";
        } else {
          this.$api.Client.addClientAccount(this.form).then((res) => {
            if (res.success) {
              this.successAlear = true;
            } else {
              if (res.message) {
                this.errorAlear = true;
                this.errorContent = res.message;
              }
            }
          });
        }
      }
    },
    selectAddress(pid, type) {
      if (type == 1) {
        this.form.stateId = "";
      }
      this.$api.Dropdown.selectAddressById({ pid: pid }).then((res) => {
        if (res.success) {
          if (pid) {
            this.types.stateList = [{ id: "", name: "Please select" }].concat(
              res.data
            );
          } else {
            this.types.countryList = [{ id: "", name: "Please select" }].concat(
              res.data
            );
          }
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        brandName: "",
        companyName: "",
        email: "",
        name: "",
        password: "",
        picture: "",
        status: 0,
        supplierRoleId: "", //客户角色
      };
      this.$nextTick(() => {
        // this.show = true;
        this.$router.go(-1);
      });
    },
    upload(e) {
      let file = e.target.files[0];
      this.photoName = file.name;
      console.log(file);
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          if (res.message) {
            this.$toast(res);
          }
          if (res.success) {
            this.form.picture = res.data;
            console.log("zzzzzzzz", this.form);
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    formData(id) {
      this.$api.Client.getClientDetails({ id: id }).then((res) => {
        if (res.success) {
          if (res.data.companyBusinessId == null) {
            res.data.companyBusinessId = [];
          }
          this.form = res.data;
          this.supplierId = res.data.id;
          this.brandList = res.data.brandList;
          if (this.form.supplierRoleId == null) this.form.supplierRoleId = "";
          this.form.password = "123456";
          if (this.editId) {
            this.selectAddress(res.data.countryId);
          }
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onSure(evt) {
      evt.preventDefault();
      if (this.modalData.password == this.modalData.confirmPassword) {
        this.$api.Client.changePasswordById({
          id: this.editId,
          password: this.modalData.password,
        }).then((res) => {
          if (res.success) {
            this.modalShow = !this.modalShow;
            this.successAlear = true;
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      }
    },
    onClose(evt) {
      evt.preventDefault();
      this.modalData = {
        password: "",
        confirmPassword: "",
      };
      this.modalShow = !this.modalShow;
    },
    resetModal() {
      this.modalData = {
        password: "",
        confirmPassword: "",
      };
    },
    gotoAdd() {
      this.$router.push("brandMgmtAdd?supplierId=" + this.supplierId);
    },
    cancel() {
      this.$router.go(-1);
    },
    hide() {
      this.$router.go(-1);
    },
    cancel1() {
      this.errorAlear = false;
    },
    hide1() {
      this.errorAlear = false;
    },
  },
};
</script>
