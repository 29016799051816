var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _vm.productDetail.id
        ? _c(
            "b-card",
            [
              _c(
                "b-row",
                { staticClass: "align-items-center" },
                [
                  _c("b-col", { attrs: { lg: "12" } }, [
                    _c("div", { staticClass: "blockquote" }, [
                      _c("h4", [_vm._v("Public Info")])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "productDetailRow",
                  staticStyle: { "margin-left": "40px" }
                },
                [
                  _c("div", [
                    _c("div", { staticClass: "productDetailBorder" }, [
                      _c("img", {
                        attrs: { src: _vm.productDetail.deviceImage }
                      })
                    ]),
                    _c(
                      "div",
                      { staticClass: "productDetailImageRow" },
                      _vm._l(_vm.productDetail.productPicture, function(
                        item,
                        key
                      ) {
                        return _c("img", {
                          key: key,
                          attrs: { src: item.value }
                        })
                      }),
                      0
                    )
                  ]),
                  _c("div", { staticClass: "productDetailContent" }, [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          color: "#3eb1c8",
                          "font-weight": "700"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.productDetail.productSeriesName) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "13px",
                          color: "#aaaaaa",
                          "font-family": "'Arial Normal', 'Arial', sans-serif"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.productDetail.remarks) + " ")]
                    ),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Product Model #:")]
                      ),
                      _vm._v(" " + _vm._s(_vm.productDetail.modelName) + " ")
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Variant Code #:")]
                      ),
                      _vm._v(" " + _vm._s(_vm.productDetail.variantCode) + " ")
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Marketing Name:")]
                      ),
                      _vm._v(" " + _vm._s(_vm.productDetail.deviceName) + " ")
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Business Type:")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.productDetail.oilType == 2
                              ? "CBD/THC Concentrates"
                              : "Nicotine"
                          ) +
                          " "
                      )
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Product Type:")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.productDetail.type == 3
                              ? "Disposable"
                              : "Batteries"
                          ) +
                          " "
                      )
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Available Compatibility:")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.productDetail.availableCompatibilityId == 1
                              ? "OMNI NFC"
                              : "OMNI Hub"
                          ) +
                          " "
                      )
                    ]),
                    _c("p", [
                      _c(
                        "strong",
                        { staticClass: "productDetailContentColor" },
                        [_vm._v("Available Tank Size:")]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.productDetail.taskCapacityValue) + " "
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-form",
        { on: { submit: _vm.submitBrand, reset: _vm.onReset } },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                { staticClass: "align-items-center" },
                [
                  _c("b-col", { attrs: { lg: "12" } }, [
                    _c("div", { staticClass: "blockquote" }, [
                      _c("h4", [_vm._v("Your Business Info")])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "color",
                              attrs: {
                                label: "Your Brand:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "brand",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "brand",
                                  options: _vm.types.brandList,
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');"
                                },
                                model: {
                                  value: _vm.form.brandId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "brandId", $$v)
                                  },
                                  expression: "form.brandId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.modelName
                                        ? _vm.form.modelName.length
                                        : 0,
                                      max: "50"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "color",
                              attrs: {
                                label: "Your Product Model #:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "productSeriesName",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "productSeriesName",
                                  trim: "",
                                  required: "",
                                  maxlength: "50",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');",
                                  placeholder: "Please enter"
                                },
                                model: {
                                  value: _vm.form.modelName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "modelName", $$v)
                                  },
                                  expression: "form.modelName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.deviceName
                                        ? _vm.form.deviceName.length
                                        : 0,
                                      max: "50"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "color",
                              attrs: {
                                label: "Marketing Name:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "marketingName",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "marketingName",
                                  trim: "",
                                  required: "",
                                  maxlength: "50",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');",
                                  placeholder: "Please enter"
                                },
                                model: {
                                  value: _vm.form.deviceName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "deviceName", $$v)
                                  },
                                  expression: "form.deviceName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.remarks
                                        ? _vm.form.remarks.length
                                        : 0,
                                      max: "1000"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "color",
                              attrs: {
                                label: "Your Product Introduction:",
                                "label-cols-lg": "3",
                                "label-align-sm": "right",
                                "label-for": "productSeriesIntroductio",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "remarks",
                                  placeholder: "Please enter",
                                  rows: "6",
                                  maxlength: "1000",
                                  "max-rows": "6",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');"
                                },
                                model: {
                                  value: _vm.form.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "remarks", $$v)
                                  },
                                  expression: "form.remarks"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "color",
                              attrs: {
                                label: "Your Product Status:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-class": "requiredRow",
                                "label-for": "productStatus"
                              }
                            },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "custom-control-inline pt-2",
                                  attrs: { value: "0", required: "" },
                                  model: {
                                    value: _vm.form.status,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "status", $$v)
                                    },
                                    expression: "form.status"
                                  }
                                },
                                [_vm._v("Enable ")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "custom-control-inline pt-2",
                                  attrs: { value: "1", required: "" },
                                  model: {
                                    value: _vm.form.status,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "status", $$v)
                                    },
                                    expression: "form.status"
                                  }
                                },
                                [_vm._v("Disable ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { "label-cols-lg": "2" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "custom-width",
                  attrs: { type: "submit", variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addBrandProduct(1)
                    }
                  }
                },
                [_vm._v("Save ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "ml-5",
                  attrs: { type: "submit", variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addBrandProduct(2)
                    }
                  }
                },
                [_vm._v("Save & Add Flavor ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "ml-5 custom-width",
                  attrs: { type: "reset" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [_vm._v("Cancel ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/success.png"), noBtn: "OK" },
          on: { cancel: _vm.cancel, hide: _vm.hide },
          model: {
            value: _vm.successAlear,
            callback: function($$v) {
              _vm.successAlear = $$v
            },
            expression: "successAlear"
          }
        },
        [_vm._v(" Saved successfully ")]
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/confirm.svg"), noBtn: "OK" },
          on: { cancel: _vm.cancel1, hide: _vm.hide1 },
          model: {
            value: _vm.errorAlear,
            callback: function($$v) {
              _vm.errorAlear = $$v
            },
            expression: "errorAlear"
          }
        },
        [_vm._v(" " + _vm._s(_vm.errorContent) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }