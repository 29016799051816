<!--
 * @Author: Code-HHX
 * @Date: 2022-03-29 10:39:59
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-03-31 15:01:38
 * @Description: 积分记录（宝石记录）
-->
<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<!-- main -->
		<b-row>
			<b-col lg="12">
				<!-- 查询条件 -->
				<b-card>
					<b-row>
						<!-- Nickname 用户 -->
						<b-col lg="3">
							<b-form-group
								label="Nickname"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="nick-name"
							>
								<b-form-input
									id="nick-name"
									trim
									v-model="search_condition.nickName"
									oninput="if(value.length > 20)value = value.slice(0, 20)"
								></b-form-input>
							</b-form-group>
						</b-col>
						<!-- Acquisition Mode 获得方式 -->
						<b-col lg="3">
							<b-form-group
								label="Acquisition Mode"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="acquisition-mode"
							>
								<b-form-select
									id="acquisition-mode"
									:options="types.modeList"
									v-model="search_condition.operationMethod"
								></b-form-select>
							</b-form-group>
						</b-col>
						<!-- Acquisition Time 获得时间 -->
						<b-col lg="3">
							<b-form-group
								label="Acquisition Time"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="acquisition-time"
							>
								<date-picker
									v-model="selectTime"
									format="MM/DD/YYYY"
									range
									append-to-body
									lang="en"
									placeholder="Select date"
								></date-picker>
							</b-form-group>
						</b-col>

						<b-col lg="3">
							<!-- <b-form-group label label-cols-sm="4" label-align-sm="right"> -->
							<b-button variant="primary" @click="search()"
								>Search</b-button
							>
							<!-- </b-form-group> -->
						</b-col>
					</b-row>

					<b-row class="text-center">
						<b-col lg="12"></b-col>
					</b-row>
				</b-card>

				<b-card>
					<b-button-group>
						<b-button
							v-has="'integralRecord::Export'"
							@click="export_checkout"
							variant="primary"
							>Export</b-button
						>
					</b-button-group>
					<!-- table -->
					<div class="table-responsive mb-0">
						<b-table
							show-empty
							empty-text="No records"
							id="device-table"
							head-variant="light"
							:striped="true"
							ref="table"
							:busy="isBusy"
							:items="provider"
							:fields="fields"
							:per-page="search_condition.pageSize"
							:current-page="search_condition.pageNum"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:filter="filter"
							:filter-includedfields="filterOn"
							@filtered="onFiltered"
						>
							<template v-slot:table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>

							<template v-slot:cell(index)="data">{{
								data.index +
									(search_condition.pageNum - 1) *
										search_condition.pageSize +
									1
							}}</template>
						</b-table>
					</div>
					<!-- end table -->
					<!-- pagination -->
					<b-row v-if="has_data">
						<b-col>
							<div
								class="dataTables_paginate paging_simple_numbers float-right"
							>
								<ul class="pagination mb-0" v-if="rows != 0">
									<b-pagination
										v-model="search_condition.pageNum"
										:per-page="10"
										:total-rows="rows"
										aria-controls="table"
									></b-pagination>
								</ul>
							</div>
						</b-col>
					</b-row>
					<!-- end pagination -->
				</b-card>
			</b-col>
		</b-row>
		<!-- end main -->

		<!-- <div class="text-center">
			<b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>-->
	</Layout>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'

/**
 * User component
 */
export default {
	components: {
		DatePicker,
		Layout,
		PageHeader,
		timeZoneConversion,
	},
	data() {
		return {
			title: 'Integral Record',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'Marketing Mgmt',
					// href: "/"
				},
				{
					text: 'Integral Record',
					active: true,
				},
			],

			search_condition: {
				nickName: '', //用户名
				operationMethod: '', //获得方式 1是激活设备,2是兑换消耗 3是邀请好友
				startTime: '', //开始时间
				endTime: '', //结束时间
				pageNum: 1,
				pageSize: 10,
				supplierId: localStorage.supplierId,
			},
			selectTime: ['', ''],

			types: {
				//获得方式
				modeList: [
					{
						text: 'Please select',
						value: '',
					},
					{
						text: 'Via Activated Product', //激活设备
						value: 1,
					},
					{
						text: 'Exchanged to perk', //兑换消耗
						value: 2,
					},
					{
						text: 'Via invite friend', //邀请好友
						value: 3,
					},
				],
			},

			fields: [
				{ key: 'index', label: '#', class: 'text-center' },
				{
					key: 'nickName', //获得用户
					label: 'Nickname',
					class: 'text-center',
				},
				{
					key: 'integral', //获得数量
					label: 'Integral Number',
					class: 'text-center',
				},
				{
					key: 'operationContent', //获得方式
					label: 'Acquisition Mode',
					class: 'text-center',
				},
				{
					key: 'createTime', //获得时间
					label: 'Acquisition Time',
					formatter: (value) => {
						return timeZoneConversion.getLocalTimes(value)
					},
					class: 'text-center',
				},
			],

			tableData: [],
			totalRows: 0,
			currentPage: 1,
			perPage: 10,
			pageOptions: [10, 25, 50, 100],
			filter: null,
			filterOn: [],
			sortBy: 'id',
			sortDesc: false,
			isBusy: false,
			has_data: false,
		}
	},
	watch: {
		selectTime: function(val) {
			this.search_condition.startTime = val[0]
				? timeZoneConversion.queryTime(val[0])
				: val[0]
			if (val[1]) {
				this.search_condition.endTime = timeZoneConversion.queryTime(
					val[1].getTime() + 24 * 3600 * 1000 - 1
				)
			} else {
				this.search_condition.endTime = val[1]
			}
		},
	},
	computed: {
		rows() {
			return this.totalRows
		},
	},
	mounted() {},
	created() {},
	methods: {
		provider(ctx) {
			this.isBusy = true
			return this.$api.Marketing.selectUserIntegralRecordList(
				this.search_condition,
				ctx
			)
				.then((x) => {
					this.isBusy = false
					if (x.total) {
						this.has_data = true
						this.totalRows = x.total
						return x.data
					} else {
						this.has_data = false
						return []
					}
				})
				.catch((e) => {
					this.isBusy = false
					this.has_data = false
					return []
				})
		},

		export_excel() {
			this.$api.Marketing.exportUserIntegralRecordList(
				this.search_condition
			)
		},
		export_checkout() {
			//校验是否存在搜索条件
			if (
				// !this.search_condition.nickName ||
				this.search_condition.startTime == '' ||
				this.search_condition.endTime == ''
			) {
				this.$toastWarning('Please select the search criteria first')
				return
			}
			//时间范围三个月内
			if (
				this.search_condition.startTime &&
				this.search_condition.endTime
			) {
				let three = 90 * 24 * 3600 * 1000
				let date = new Date(this.search_condition.endTime).getTime()
				let threeMonths = date - three
				if (
					threeMonths >
					new Date(this.search_condition.startTime).getTime()
				) {
					this.$toastWarning(
						'Please select the time frame within 3 months'
					)
					return
				}
			}
			this.export_excel()
		},
		details(item, index, button) {
			this.$router.push('usageRecordInfo?id=' + item.id)
		},
		search() {
			this.search_condition.pageNum = 1
			this.$refs.table.refresh()
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
	},
}
</script>
