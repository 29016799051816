var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xl: "8" } },
            [
              _c("b-card", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "16px",
                      "font-family": "'Sarabun', sans-serif",
                      "font-weight": "600",
                      float: "left"
                    }
                  },
                  [_vm._v(" Shortcut Links ")]
                ),
                _c(
                  "div",
                  {
                    staticStyle: { float: "right", "font-size": "24px" },
                    on: { click: _vm.openModal }
                  },
                  [
                    _c("i", {
                      staticClass: "mdi mdi-settings-outline toggle-right",
                      staticStyle: { cursor: "pointer" }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "container",
                    staticStyle: {
                      "margin-top": "32px",
                      display: "flex",
                      "flex-wrap": "wrap"
                    }
                  },
                  _vm._l(_vm.quickEntry, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "col-md-2",
                        staticStyle: {
                          display: "inline-block",
                          "text-align": "center",
                          cursor: "pointer"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$router.push(item.href)
                          }
                        }
                      },
                      [
                        _c("i", {
                          class: item.quickIcon,
                          staticStyle: { "font-size": "40px" }
                        }),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "word-break": "break-word"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.quickName.replace(/(\s| )+/g, " ")
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]),
              _c(
                "b-modal",
                {
                  ref: "modal",
                  attrs: {
                    title: "Setting Quick Entry",
                    "hide-footer": "",
                    size: "lg",
                    centered: "",
                    "no-close-on-esc": "",
                    "no-close-on-backdrop": ""
                  },
                  on: { hidden: _vm.reset },
                  model: {
                    value: _vm.modalShow,
                    callback: function($$v) {
                      _vm.modalShow = $$v
                    },
                    expression: "modalShow"
                  }
                },
                [
                  _c(
                    "form",
                    {
                      ref: "form",
                      on: { submit: _vm.onSubmit, reset: _vm.onReset }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "container" },
                        [
                          _c(
                            "b-form-checkbox-group",
                            {
                              attrs: {
                                id: "checkbox-group-2",
                                name: "flavour-2"
                              },
                              model: {
                                value: _vm.selected,
                                callback: function($$v) {
                                  _vm.selected = $$v
                                },
                                expression: "selected"
                              }
                            },
                            _vm._l(_vm.allQuickEntry, function(ele) {
                              return _c(
                                "div",
                                {
                                  key: ele.id,
                                  staticClass: "col-md-2",
                                  staticStyle: {
                                    display: "inline-block",
                                    "text-align": "center"
                                  }
                                },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    { attrs: { value: ele.id } },
                                    [
                                      _c("i", {
                                        class: ele.quickIcon,
                                        staticStyle: { "font-size": "40px" }
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "font-size": "14px",
                                            "word-break": "word-wrap"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                ele.quickName.replace(
                                                  /(\s| )+/g,
                                                  " "
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "b-button",
                            { attrs: { type: "submit", variant: "primary" } },
                            [_vm._v("Confirm")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "m-l-5 ml-1",
                              attrs: { type: "reset" }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xl: "4" } },
            [
              _c("b-card", { attrs: { title: "Product Inventory" } }, [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c(
                    "div",
                    { staticStyle: { flex: "1", "text-align": "center" } },
                    [
                      _c("p", { staticStyle: { "font-size": "30px" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.inventory.remainingTotal
                                ? _vm.inventory.remainingTotal
                                : 0
                            ) +
                            " "
                        )
                      ]),
                      _c("p", [_vm._v("Remaining unfilled products")])
                    ]
                  ),
                  _c(
                    "div",
                    { staticStyle: { flex: "1", "text-align": "center" } },
                    [
                      _c("p", { staticStyle: { "font-size": "30px" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.inventory.toBeActivated_total
                                ? _vm.inventory.toBeActivated_total
                                : 0
                            ) +
                            " "
                        )
                      ]),
                      _c("p", [_vm._v("Remaining To Be Activated")])
                    ]
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xl: "8" } },
            [
              _c(
                "b-card",
                { attrs: { title: "Recent Activity" } },
                [
                  _vm._l(_vm.productionProgressItems, function(item) {
                    return _c("div", { key: item.id }, [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "margin-bottom": "10px",
                            color: "Rgba(56, 188, 255)"
                          }
                        },
                        [
                          _vm._v(" " + _vm._s(item.batchName) + " "),
                          _c(
                            "b-badge",
                            { attrs: { pill: "", variant: "danger" } },
                            [_vm._v(_vm._s(item.flavorName))]
                          ),
                          _c("span", { staticStyle: { float: "right" } }, [
                            _vm._v(
                              _vm._s(
                                item.status == 0
                                  ? "To be confirmed"
                                  : item.status == 1
                                  ? "Have been confirmed"
                                  : item.status == 2
                                  ? "In production"
                                  : item.status == 3
                                  ? "Completed"
                                  : item.status == 4
                                  ? "Information to clear"
                                  : "Production Completed on"
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _c("span", [
                        _vm._v("Quantity: " + _vm._s(item.batchQuantity))
                      ]),
                      _c("span", { staticStyle: { "margin-left": "30px" } }, [
                        _vm._v(" Product Model: " + _vm._s(item.modelName))
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-left": "30px",
                            color: "Rgba(56, 164, 248)"
                          }
                        },
                        [_vm._v(" Creator: ")]
                      ),
                      _c("span", [_vm._v(_vm._s(item.createUserName))]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-left": "30px",
                            color: "Rgba(2, 164, 153)"
                          }
                        },
                        [_vm._v(" Filling Manager: ")]
                      ),
                      _c("span", [_vm._v(_vm._s(item.burnRecordUserName))]),
                      _c("span", { staticStyle: { float: "right" } }, [
                        _vm._v(_vm._s(_vm.getLocalTime(item.createTime)))
                      ]),
                      _c("p", {
                        staticStyle: {
                          height: "1px",
                          "background-color": "#999",
                          opacity: "0.5"
                        }
                      })
                    ])
                  }),
                  _vm.productionProgressRows != 0
                    ? _c(
                        "div",
                        { staticStyle: { float: "right" } },
                        [
                          _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.productionProgressRows,
                              "per-page": 10,
                              "aria-controls": "my-table"
                            },
                            on: { change: _vm.changeProductionProgressPageNum },
                            model: {
                              value: _vm.productionProgressSearch.pageNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.productionProgressSearch,
                                  "pageNum",
                                  $$v
                                )
                              },
                              expression: "productionProgressSearch.pageNum"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xl: "4" } },
            [
              _c(
                "b-card",
                { attrs: { title: "Blank Product Orders" } },
                [
                  _vm._l(_vm.emptyCartridgesItems, function(item) {
                    return _c(
                      "div",
                      { key: item.id },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "text-muted",
                            staticStyle: { "margin-bottom": "10px" }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(item.applicationInstructions) + " "
                            )
                          ]
                        ),
                        _vm._l(item.model, function(i) {
                          return _c(
                            "span",
                            {
                              key: i.id,
                              staticClass: "mb-0",
                              staticStyle: { "margin-right": "30px" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(i.modelName) +
                                  ":" +
                                  _vm._s(i.number) +
                                  " "
                              )
                            ]
                          )
                        }),
                        _c("p", {
                          staticStyle: {
                            height: "1px",
                            "background-color": "#999",
                            opacity: "0.5"
                          }
                        })
                      ],
                      2
                    )
                  }),
                  _vm.emptyCartridgesRows != 0
                    ? _c(
                        "div",
                        { staticStyle: { float: "right" } },
                        [
                          _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.emptyCartridgesRows,
                              "per-page": 10,
                              "aria-controls": "my-tables"
                            },
                            on: { change: _vm.changeEmptyCartridgesPageNum },
                            model: {
                              value: _vm.emptyCartridgesSearch.pageNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.emptyCartridgesSearch,
                                  "pageNum",
                                  $$v
                                )
                              },
                              expression: "emptyCartridgesSearch.pageNum"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }