import {format} from "./time"
import axios from "../config/http.js"
import axios1 from "../config/http-json.js"
import baseUrl from "../config/env.js"

async function smokeRecordApplicationList(params,ctx){
    return axios.fetchGet(baseUrl + '/smokeRecordApplication/list',params)
}

function smokeRecordApplicationAdd(params){
    return axios1.fetchPost(baseUrl + '/smokeRecordApplication/add', params);
}

function smokeRecordApplicationInfo(params){
   return axios.fetchGet(baseUrl + '/smokeRecordApplication/selectById',params)
}

function smokeRecordApplicationUpdate(params){
    return axios1.fetchPost(baseUrl + '/smokeRecordApplication/update', params);
}

function inStockStatistics(params){
    return axios.fetchGet(baseUrl + '/smokeRecordApplication/inStockStatistics',params)
 }
 //空烟弹生产订单
function selectAllOrder(params, ctx) {
    return axios.fetchGet(baseUrl + "/smokeRecordOrder/selectAllOrder", params);
}
//添加空烟弹订单
function smokeRecordOrderSave(params, ctx) {
    return axios.fetchPost(baseUrl + "/smokeRecordOrder/save", params);
}

//修改空烟弹
function smokeRecordOrderUpdate(params,ctx){
    return axios.fetchPost(baseUrl + "/smokeRecordOrder/updateById", params);
}
//控烟单详情
function selectByOrderId(params, ctx) {
    return axios.fetchGet(baseUrl + "/smokeRecordOrder/selectByOrderId", params);
}
//查询烟弹详情
function selectTagById(params){
    return axios.fetchGet(baseUrl + '/smokeRecordOrder/selectTagById',params)
 }
 

export default {
    smokeRecordApplicationList,
    smokeRecordApplicationAdd,
    smokeRecordApplicationInfo,
    smokeRecordApplicationUpdate,
    inStockStatistics,
    selectAllOrder,
    smokeRecordOrderSave,
    selectByOrderId,
    smokeRecordOrderUpdate,
    selectTagById,
}
