<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<!-- main -->

		<b-modal
			ref="modal"
			v-model="modalShow"
			title="import devices"
			@show="resetModal"
			@hidden="resetModal"
			@ok="handleOk"
			centered
			no-close-on-esc
			no-close-on-backdrop
		>
			<form ref="form" @submit.stop.prevent="handleSubmit">
				<!-- <b-row> -->
				<b-form-group
					label="Select brand:"
					label-cols-sm="4"
					label-align-sm="right"
					label-for="device-brand"
				>
					<b-form-select
						id="device-brand"
						:options="types.brand"
						v-model="form.brandId"
					></b-form-select>
				</b-form-group>
				<!-- </b-row> -->
				<!-- <b-row> -->
				<div class="form-group mb-0">
					<!-- <label>import devices</label> -->

					<input
						type="file"
						class="filestyle"
						data-input="false"
						id="filestyle-1"
						style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
						tabindex="-1"
						@change="upload($event)"
					/>

					<!-- <div class="bootstrap-filestyle input-group"> -->
					<!-- <div
              name="filedrag"
              style="position: absolute; width: 100%; height: 35px; z-index: -1;"
          ></div>-->
					<b-form-group
						label="Upload file:"
						label-cols-sm="4"
						label-align-sm="right"
						label-for="device-file"
					>
						<span
							class="group-span-filestyle"
							tabindex="0"
							b-col="8"
						>
							<label
								for="filestyle-1"
								style="margin-bottom: 0"
								class="btn btn-secondary"
							>
								<span class="buttonText"
									>upload Excel file</span
								>
							</label>
							<p>only support xls、xlxs file</p>
						</span>
					</b-form-group>
				</div>
				<!-- </div> -->
				<!-- </b-row> -->
				<b-form-group
					label="Notes:"
					label-cols-sm="4"
					label-align-sm="right"
					label-for="remarks"
				>
					<b-form-input
						id="remarks"
						v-model="form.remarks"
						placeholder="Maximum 20 characters"
						oninput="if(value.length > 20)value = value.slice(0, 20)"
						trim
					></b-form-input>
				</b-form-group>

				<!-- end row -->
			</form>
			<template v-slot:modal-ok="{}">Sure</template>
			<template v-slot:modal-cancel="{}">Close</template>
			<!-- <b-row>
        <b-col lg="12">
          <p>
            <a href="http://www.baidu.com/" target="_blank"
              >download template</a
            >
          </p>
        </b-col>
      </b-row>-->
		</b-modal>

		<b-row>
			<b-col lg="12">
				<!-- 查询条件 -->
				<b-card>
					<b-row>
						<b-col lg="4">
							<b-form-group
								label="Device NO."
								label-cols-sm="4"
								label-align-sm="right"
								label-for="device-number"
							>
								<b-form-input
									id="device-number"
									v-model="search_condition.deviceNumber"
									trim
								></b-form-input>
							</b-form-group>
						</b-col>

						<b-col lg="4">
							<b-form-group
								label="Device Brand"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="device-brand"
							>
								<b-form-select
									id="device-brand"
									:options="types.brand"
									v-model="search_condition.brandId"
								></b-form-select>
							</b-form-group>
						</b-col>

						<b-col lg="4">
							<b-form-group
								label="Activated Time"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="active-time"
							>
								<date-picker
									v-model="activeTime"
									format="MM/DD/YYYY"
									range
									append-to-body
									lang="en"
									placeholder="Select date"
								></date-picker>
							</b-form-group>
						</b-col>

						<b-col lg="4">
							<b-form-group
								label="Device Model"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="device-model"
							>
								<b-form-select
									id="device-model"
									:options="types.deviceList"
									v-model="search_condition.deviceModelId"
								></b-form-select>
							</b-form-group>
						</b-col>

						<b-col lg="4">
							<b-form-group
								label="Device Status"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="device-status"
							>
								<b-form-select
									id="device-status"
									:options="types.statuss"
									v-model="search_condition.status"
								></b-form-select>
							</b-form-group>
						</b-col>

						<b-col lg="4">
							<b-button variant="primary" @click="search()"
								>Search</b-button
							>
						</b-col>
					</b-row>
				</b-card>

				<b-card>
					<b-button-group>
						<b-button
							v-has="'deviceList::Import'"
							@click="modalShow = !modalShow"
							variant="info"
							>Import</b-button
						>
					</b-button-group>
					<!-- table -->
					<div class="table-responsive mb-0">
						<b-table
							show-empty
							empty-text="No records"
							id="table"
							head-variant="light"
							ref="table"
							:busy="isBusy"
							:striped="true"
							:items="provider"
							:fields="fields"
							:per-page="search_condition.pageSize"
							:current-page="search_condition.pageNum"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:filter="filter"
							:filter-includedfields="filterOn"
							@filtered="onFiltered"
						>
							<template v-slot:table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>

							<template v-slot:cell(index)="data">
								{{
									data.index +
										(search_condition.pageNum - 1) *
											search_condition.pageSize +
										1
								}}
							</template>

							<template v-slot:cell(actions)="row">
								<b-button-group>
									<b-button
										v-has="'deviceList::Details'"
										size="sm"
										variant="info"
										@click="
											show(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
										>Details</b-button
									>
									<b-button
										v-has="'deviceList::Release Binding'"
										size="sm"
										variant="danger"
										:disabled="row.item.bindStatus == 0"
										@click="
											unbundleDevice(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
										>Release Binding</b-button
									>
								</b-button-group>
							</template>
						</b-table>
					</div>
					<!-- end table -->
					<br />
					<!-- pagination -->
					<b-row>
						<b-col>
							<div
								class="dataTables_paginate paging_simple_numbers float-right"
							>
								<ul class="pagination mb-0" v-if="rows != 0">
									<b-pagination
										v-model="search_condition.pageNum"
										:per-page="10"
										:total-rows="rows"
										aria-controls="table"
									></b-pagination>
								</ul>
							</div>
						</b-col>
					</b-row>
					<!-- end pagination -->
				</b-card>
			</b-col>
		</b-row>
		<!-- end main -->
	</Layout>
</template>
<script>
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import DatePicker from 'vue2-datepicker'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'

/**
 * Device component
 */
export default {
	components: {
		Layout,
		PageHeader,
		DatePicker,
		timeZoneConversion,
	},
	data() {
		return {
			title: 'Device List',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'Device Manage',
					// href: "/"
				},
				{
					text: 'Device List',
					active: true,
				},
			],
			tableData: [],
			totalRows: 0,
			currentPage: 1,
			perPage: 10,
			pageOptions: [10, 25, 50, 100],
			filter: null,
			filterOn: [
				'deviceNumber',
				'deviceCoding',
				'deviceLocation',
				'activationTime',
			],
			sortBy: 'id',
			sortDesc: false,
			fields: [
				{ key: 'index', label: '#', class: 'text-center' },
				{
					key: 'deviceNumber',
					label: 'Device NO.',
					class: 'text-center',
				},
				{
					key: 'status',
					label: 'Device Status',
					formatter: (value) => {
						return value == 1
							? 'Activated'
							: 2
							? 'Unactivated'
							: 'Disabled'
					},
					class: 'text-center',
				},
				{
					key: 'deviceModel',
					label: 'Device Model',
					class: 'text-center',
				},

				{
					key: 'activationTime',
					label: 'Activated Time',
					formatter: (value) => {
						return timeZoneConversion.getLocalTime(value)
					},
					class: 'text-center',
				},
				{
					key: 'brandName',
					label: 'Device Brand',
					class: 'text-center',
				},
				{
					key: 'lastUseTime',
					label: 'Last Used Time',
					formatter: (value) => {
						return timeZoneConversion.getLocalTime(value)
					},
					class: 'text-center',
				},
				{ key: 'bindName', label: 'Bind Name', class: 'text-center' },
				{ key: 'actions', label: 'Opreration', class: 'text-center' },
			],
			search_condition: {
				pageNum: 1,
				pageSize: 10,
				deviceNumber: '',
				brandId: '',
				status: '',
				startTime: '',
				endTime: '',
				supplierId: localStorage.supplierId,
				deviceModelId: '',
			},
			activeTime: ['', ''],
			isBusy: false,
			has_data: false,
			modalShow: false,
			form: { brandId: '', file: '', remarks: '', id: '' },
			types: {
				statuss: [
					{ text: 'Please select', value: '', disabled: false },
					{ text: 'Activated', value: 1, disabled: false },
					{ text: 'Unactivated', value: 2, disabled: false },
					{ text: 'Disabled', value: 3, disabled: false },
				],
				brand: [{ text: 'Please select', value: '' }],
				deviceList: [
					{ text: 'Please select', value: '', disabled: false },
				],
			},
		}
	},
	watch: {
		activeTime: function(val) {
			this.search_condition.startTime = val[0]
				? timeZoneConversion.queryTime(val[0])
				: val[0]
			if (val[1]) {
				this.search_condition.endTime = timeZoneConversion.queryTime(
					val[1].getTime() + 24 * 3600 * 1000 - 1
				)
			} else {
				this.search_condition.endTime = val[1]
			}
		},
	},
	computed: {
		/**
		 * 计算rows
		 */
		rows() {
			return this.totalRows
		},
	},
	created() {
		this.getBrandOptions()
	},
	mounted() {
		this.resetModal()
	},
	methods: {
		provider(ctx) {
			this.isBusy = true
			return this.$api.Device.list(this.search_condition, ctx)
				.then((x) => {
					this.isBusy = false
					if (x.total) {
						this.has_data = true
						this.totalRows = x.total
						return x.data
					} else {
						this.has_data = false
						return []
					}
				})
				.catch((e) => {
					this.isBusy = false
					this.has_data = false
					return []
				})
		},
		show(item, index, button) {
			this.$router.push('deviceInfo?id=' + item.id)
		},
		search() {
			this.search_condition.pageNum = 1
			this.$refs.table.refresh()
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		resetModal() {
			this.form = { brandId: '', file: '', remarks: '', id: '' }
			this.form = new FormData()
		},
		handleOk(bvModalEvt) {
			bvModalEvt.preventDefault()
			let formData = new FormData()
			formData.append('id', localStorage.supplierId)
			formData.append('brandId', this.form.brandId)
			formData.append('file', this.form.file)
			formData.append('remarks', this.form.remarks)
			let that = this
			that.$api.Device.addBatch(formData).then((res) => {
				that.$bvToast.toast(res.data.message)
				if (res.data.success) {
					that.$refs.table.refresh()
					// that.modalShow = false;
				}
			})
			this.handleSubmit()
		},
		upload(e) {
			let that = this
			let file = e.target.files[0]
			console.log(e)
			if (/sheet/.test(file.type)) {
				this.form.file = file
				console.log('uploading', this.form.file)
			} else {
				e.target.files = []
				this.$bvToast.toast('only support xls、xlxs file')
			}
		},
		handleSubmit() {
			this.resetModal()
			this.modalShow = false
		},
		getBrandOptions() {
			this.$api.Dropdown.brandList().then((res) => {
				if (res.success) {
					for (let i = 0; i < res.data.length; i++) {
						this.types.brand.push({
							text: res.data[i].brandName,
							value: res.data[i].id,
						})
					}
				} else {
					if (res.message) {
						this.$toast(res)
					}
				}
			})
			this.$api.Dropdown.selectAllDeviceModel().then((res) => {
				if (res.success) {
					for (let i = 0; i < res.data.length; i++) {
						this.types.deviceList.push({
							text: res.data[i].modelName,
							value: res.data[i].id,
						})
					}
				} else {
					if (res.message) {
						this.$toast(res)
					}
				}
			})
		},
		// 解绑用户设备
		unbundleDevice(item, index, row) {
			let postData = {
				id: item.id,
			}
			this.$api.userManage
				.untieDeviceById(postData)
				.then((res) => {
					console.log(res)
					if (res.success && res.message == 'Unbind success') {
						this.$toast(res)
						//this.device_provider();
						this.$refs.table.refresh()
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
				.catch((error) => {})
		},
	},
}
</script>
