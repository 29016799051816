var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("Steps", { attrs: { Steps: _vm.registSpets, SetData: _vm.SetData } }),
      _c(
        "b-card",
        [
          _c("b-col", { attrs: { lg: "12" } }, [
            _c(
              "p",
              {
                staticClass: "title",
                staticStyle: { "font-size": "16px", "font-weight": "bolder" }
              },
              [
                _c("span", {
                  staticStyle: {
                    display: "inline-block",
                    width: "10px",
                    height: "20px",
                    "vertical-align": "text-top",
                    "background-color": "#626ed4"
                  }
                }),
                _vm._v(" Batch Info ")
              ]
            )
          ]),
          _c(
            "b-tabs",
            { staticStyle: { margin: "20px 0" }, attrs: { pills: "" } },
            [
              _c(
                "b-tab",
                { attrs: { title: "Batch Details", active: "" } },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        { attrs: { cols: "3" } },
                        [
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Batch Name：")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.batchName))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Batch ID：")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.number))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Product Model：")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.smokeModel))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Flavor：")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.modelName))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Content Capacity:")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.capacity))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Max Frequency:")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.maxFrequency))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Batch Quantity:")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.batchQuantity))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Production Factory:")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(_vm.batchDetailsVo.manufacturerName)
                              )
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Notes:")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.remarks))
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Content Details" } },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        { attrs: { cols: "3" } },
                        [
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Content Type: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.smokeOilDetailsVo.type))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Brand：")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.smokeOilDetailsVo.brandName))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Flavor：")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.smokeOilDetailsVo.modelName))
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Cartridge Details" } },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        { attrs: { cols: "3" } },
                        [
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Model Name: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.testSmokeBombModelVo.modelName))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Heating Wire Material: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.testSmokeBombModelVo.heatingWireMaterial
                                )
                              )
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Heating Wire Size: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(_vm.testSmokeBombModelVo.heatingWireSize)
                              )
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Heating Wire Porosity: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.testSmokeBombModelVo.heatingWirePorosity
                                )
                              )
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Oil Hole: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.testSmokeBombModelVo.oilInlet))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Resistance: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(_vm.testSmokeBombModelVo.resistance)
                              )
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Package Cotton: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.testSmokeBombModelVo.cottonBag == 0
                                    ? "Yes"
                                    : _vm.testSmokeBombModelVo.cottonBag == 1
                                    ? "No"
                                    : ""
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Heating&Preheating Mode" } },
                [
                  _c(
                    "b-container",
                    [
                      _c("span", { staticStyle: { "font-weight": "700" } }, [
                        _vm._v("Heating Mode")
                      ]),
                      _c("b-table", {
                        ref: "table",
                        attrs: {
                          id: "heating-table",
                          "head-variant": "light",
                          "show-empty": "",
                          "empty-text": "No records",
                          busy: _vm.heatingIsBusy,
                          striped: true,
                          items: _vm.heatingModeDetailsVo,
                          fields: _vm.heatingModefields
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-busy",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-center text-danger my-2"
                                  },
                                  [
                                    _c("b-spinner", {
                                      staticClass: "align-middle"
                                    }),
                                    _c("strong", [_vm._v("Loading...")])
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      }),
                      _c("span", { staticStyle: { "font-weight": "700" } }, [
                        _vm._v("Preheating Mode")
                      ]),
                      _c("b-table", {
                        ref: "table",
                        attrs: {
                          id: "preheating-table",
                          "head-variant": "light",
                          "show-empty": "",
                          "empty-text": "No records",
                          busy: _vm.preheatingIsBusy,
                          striped: true,
                          items: _vm.warmUpModeDetailsVo,
                          fields: _vm.warmUpModefields
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-busy",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-center text-danger my-2"
                                  },
                                  [
                                    _c("b-spinner", {
                                      staticClass: "align-middle"
                                    }),
                                    _c("strong", [_vm._v("Loading...")])
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("b-col", { attrs: { lg: "12" } }, [
            _c(
              "p",
              {
                staticClass: "title",
                staticStyle: { "font-size": "16px", "font-weight": "bolder" }
              },
              [
                _c("span", {
                  staticStyle: {
                    display: "inline-block",
                    width: "10px",
                    height: "20px",
                    "vertical-align": "text-top",
                    "background-color": "#626ed4"
                  }
                }),
                _vm._v(" COA Info ")
              ]
            )
          ]),
          _c(
            "b-container",
            [
              _c(
                "b-form",
                {
                  ref: "firstTabForm",
                  on: { submit: _vm.onSubmit, reset: _vm.onReset }
                },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Sample ID:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "sample-id"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "sample-id",
                          oninput:
                            "if(value.length > 50)value = value.slice(0, 50)",
                          placeholder: "Please enter"
                        },
                        model: {
                          value: _vm.form.sampleNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sampleNumber", $$v)
                          },
                          expression: "form.sampleNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Sample Name:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "sample-name"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "sample-name",
                          oninput:
                            "if(value.length > 50)value = value.slice(0, 50)",
                          placeholder: "Please enter"
                        },
                        model: {
                          value: _vm.form.sampleName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sampleName", $$v)
                          },
                          expression: "form.sampleName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Sample Batch ID:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "sample-batch"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "sample-batch",
                          oninput:
                            "if(value.length > 50)value = value.slice(0, 50)",
                          placeholder: "Please enter"
                        },
                        model: {
                          value: _vm.form.sampleBatchNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sampleBatchNumber", $$v)
                          },
                          expression: "form.sampleBatchNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "inner",
                      attrs: {
                        label: "Content Formula:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "cartridge-formula",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _vm._l(_vm.form.formula, function(data, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "inner mb-3 row" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-md-3 col-4" },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    id: "cartridge-type",
                                    options: _vm.types.cartridgeComponentData,
                                    "value-field": "id",
                                    "text-field": "component",
                                    required: "",
                                    oninvalid:
                                      "setCustomValidity('Please select');",
                                    oninput: "setCustomValidity('')"
                                  },
                                  model: {
                                    value: data.id,
                                    callback: function($$v) {
                                      _vm.$set(data, "id", $$v)
                                    },
                                    expression: "data.id"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-3 col-4" },
                              [
                                _c("b-form-input", {
                                  staticClass: "inner form-control",
                                  attrs: {
                                    required: "",
                                    type: "number",
                                    min: "0",
                                    max: "100",
                                    step: "0.0001",
                                    placeholder: "Please enter proportion(%)",
                                    oninvalid:
                                      "setCustomValidity('Please enter')",
                                    oninput:
                                      "setCustomValidity('');value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d\\d\\d).*$/, '$1$2.$3');"
                                  },
                                  model: {
                                    value: data.proportion,
                                    callback: function($$v) {
                                      _vm.$set(data, "proportion", $$v)
                                    },
                                    expression: "data.proportion"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col-md-2 col-4",
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: {
                                      value: "0",
                                      "unchecked-value": "1",
                                      disabled:
                                        _vm.statusDisabled && data.status == 1
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.change_status(
                                          $event,
                                          data,
                                          index
                                        )
                                      }
                                    },
                                    model: {
                                      value: data.status,
                                      callback: function($$v) {
                                        _vm.$set(data, "status", $$v)
                                      },
                                      expression: "data.status"
                                    }
                                  },
                                  [_vm._v("Main")]
                                ),
                                _c("img", {
                                  staticStyle: { "margin-left": "5px" },
                                  attrs: {
                                    src: require("@/assets/images/question.png"),
                                    width: "22",
                                    height: "22",
                                    title:
                                      "The main ingredients of product, Display at  app cartridge information page."
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.form.formula.length > 1
                              ? _c(
                                  "div",
                                  { staticClass: "col-md-2 col-2" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "danger" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteFormula(index)
                                          }
                                        }
                                      },
                                      [_vm._v("Delete")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: _vm.addFormula }
                        },
                        [_vm._v("Add")]
                      )
                    ],
                    2
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "inner",
                      attrs: {
                        label: "Test Items:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "certification"
                      }
                    },
                    [
                      _vm._l(_vm.form.addReport, function(ele, e) {
                        return _c(
                          "div",
                          { key: e, staticClass: "inner mb-3 row" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-md-3 col-4" },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    id: "certification",
                                    options: _vm.types.selectReportParameters,
                                    "value-field": "id",
                                    "text-field": "reportName"
                                  },
                                  model: {
                                    value: ele.id,
                                    callback: function($$v) {
                                      _vm.$set(ele, "id", $$v)
                                    },
                                    expression: "ele.id"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-3 col-4" },
                              [
                                _c("b-form-select", {
                                  attrs: { options: _vm.types.passOrNots },
                                  model: {
                                    value: ele.passOrNot,
                                    callback: function($$v) {
                                      _vm.$set(ele, "passOrNot", $$v)
                                    },
                                    expression: "ele.passOrNot"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col-md-3 col-2",
                                staticStyle: { "padding-top": "7px" }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "Rgba(68, 82, 204)"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.modalShow = !_vm.modalShow
                                      }
                                    }
                                  },
                                  [_vm._v("Create Custom Options")]
                                )
                              ]
                            ),
                            _vm.form.addReport.length > 1
                              ? _c(
                                  "div",
                                  { staticClass: "col-md-2 col-2" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "danger" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteCertification(e)
                                          }
                                        }
                                      },
                                      [_vm._v("Delete")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: _vm.addCertification }
                        },
                        [_vm._v("Add")]
                      )
                    ],
                    2
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "inner",
                      attrs: {
                        label: "Solvent:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "Solvent"
                      }
                    },
                    [
                      _vm._l(_vm.form.solvent, function(item, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "inner mb-3 row" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-md-4 col-4" },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    id: "cartridge-type",
                                    options: _vm.types.SolventEncoding,
                                    "value-field": "id",
                                    "text-field": "solventName"
                                  },
                                  model: {
                                    value: item.id,
                                    callback: function($$v) {
                                      _vm.$set(item, "id", $$v)
                                    },
                                    expression: "item.id"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4 col-4" },
                              [
                                _c("b-form-input", {
                                  staticClass: "inner form-control",
                                  attrs: {
                                    type: "number",
                                    min: "0",
                                    max: "100",
                                    step: "0.0001",
                                    placeholder: "Please Enter proportion(%)",
                                    oninput:
                                      "value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d\\d\\d).*$/, '$1$2.$3');"
                                  },
                                  model: {
                                    value: item.proportion,
                                    callback: function($$v) {
                                      _vm.$set(item, "proportion", $$v)
                                    },
                                    expression: "item.proportion"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.form.solvent.length > 1
                              ? _c(
                                  "div",
                                  { staticClass: "col-md-2 col-2" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "danger" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteSolvent(i)
                                          }
                                        }
                                      },
                                      [_vm._v("Delete")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: _vm.addSolvent }
                        },
                        [_vm._v("Add")]
                      )
                    ],
                    2
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Report Type:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "report"
                      }
                    },
                    [
                      _c(
                        "b-form-radio-group",
                        {
                          staticClass: "pt-2",
                          attrs: { id: "reportType" },
                          model: {
                            value: _vm.form.reportType,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "reportType", $$v)
                            },
                            expression: "form.reportType"
                          }
                        },
                        [
                          _c(
                            "b-form-radio",
                            {
                              attrs: { value: "1" },
                              on: { change: _vm.changeLink }
                            },
                            [_vm._v("Link")]
                          ),
                          _c(
                            "b-form-radio",
                            {
                              attrs: { value: "2" },
                              on: { change: _vm.changeEnclosure }
                            },
                            [_vm._v("Enclosure")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showReportLink
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Report Link:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "reportLink"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "reportLink",
                              placeholder: "Enter Report Link"
                            },
                            model: {
                              value: _vm.form.reportLink,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "reportLink", $$v)
                              },
                              expression: "form.reportLink"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showReportEnclosure
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Report File:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "reportEnclosure"
                          }
                        },
                        [
                          _c("input", {
                            staticClass: "filestyle",
                            staticStyle: {
                              position: "absolute",
                              clip: "rect(0px, 0px, 0px, 0px)"
                            },
                            attrs: {
                              type: "file",
                              "data-input": "false",
                              id: "filestyle-1",
                              tabindex: "-1"
                            },
                            on: {
                              change: function($event) {
                                return _vm.upload($event)
                              }
                            }
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "group-span-filestyle",
                              attrs: { tabindex: "0", "b-col": "8" }
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "btn btn-secondary",
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { for: "filestyle-1" }
                                },
                                [
                                  _c("span", { staticClass: "buttonText" }, [
                                    _vm._v("Upload PDF file")
                                  ])
                                ]
                              ),
                              _vm.fileName
                                ? _c("span", [_vm._v(_vm._s(_vm.fileName))])
                                : _c("span", [_vm._v("only support PDF file")])
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Notes",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "remarks"
                      }
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "remarks",
                          placeholder: "Enter Notes",
                          rows: "3",
                          "max-rows": "6"
                        },
                        model: {
                          value: _vm.form.remarks,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "remarks", $$v)
                          },
                          expression: "form.remarks"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticStyle: { "margin-top": "50px" },
                      attrs: { "label-cols-lg": "2", "label-align-lg": "right" }
                    },
                    [
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "primary" } },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticStyle: { "margin-left": "30px" },
                          attrs: { type: "reset" }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            title: "Create Custom Options",
            "hide-footer": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: { hidden: _vm.resetModal },
          model: {
            value: _vm.modalShow,
            callback: function($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow"
          }
        },
        [
          _c(
            "form",
            {
              ref: "modelform",
              on: { submit: _vm.onSubmitModal, reset: _vm.onResetModal }
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Certification Type:",
                    "label-cols-lg": "4",
                    "label-align-lg": "right",
                    "label-for": "certification-type",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "certification-type",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput:
                        "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                      placeholder: "Please enter"
                    },
                    model: {
                      value: _vm.reportName,
                      callback: function($$v) {
                        _vm.reportName = $$v
                      },
                      expression: "reportName"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "4" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    { staticClass: "m-l-5 ml-1", attrs: { type: "reset" } },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }