import { format } from "./time"
import qs from "qs";
import axios from "../config/http.js"
import baseUrl from "../config/env.js"


async function list(params, ctx) {
    return axios.fetchGet(baseUrl + '/flavor/list', params)
}

function add(params) {
    return axios.fetchPost(baseUrl + '/flavor/add', params);
}

function find(params) {
    return axios.fetchGet(baseUrl + '/flavor/findById', params)
}

function update(params) {
    return axios.fetchPost(baseUrl + '/flavor/update', params);
}

function del(params) {
    return axios.fetchPost(baseUrl + '/flavor/deleteById', params);
}

// ingredient 成分接口
async function ingredientList(params, ctx) {
    return axios.fetchGet(baseUrl + '/componentEncoding/list', params)
}

function ingredientAdd(params) {
    return axios.fetchPost(baseUrl + '/componentEncoding/add', params);
}

function ingredientUpdate(params) {
    return axios.fetchPost(baseUrl + '/componentEncoding/update', params);
}

function ingredientEnableOrDisable(params) {
    return axios.fetchPost(baseUrl + '/componentEncoding/deleteById', params);
}
// 厂家接口
async function manufacturerList(params, ctx) {
    return axios.fetchGet(baseUrl + '/manufacturerEncoding/list', params)
}

function manufacturerAdd(params) {
    return axios.fetchPost(baseUrl + '/manufacturerEncoding/add', params);
}

function manufacturerFind(params) {
    return axios.fetchGet(baseUrl + '/manufacturerEncoding/findById', params)
}

function manufacturerUpdate(params) {
    return axios.fetchPost(baseUrl + '/manufacturerEncoding/update', params);
}

// brand 品牌接口
async function brandList(params, ctx) {
    return axios.fetchGet(baseUrl + '/brand/list', params)
}

function brandAdd(params) {
    return axios.fetchPost(baseUrl + '/brand/add', params);
}

function brandUpdate(params) {
    return axios.fetchPost(baseUrl + '/brand/update', params);
}
function brandDelete(params) {
    return axios.fetchPost(baseUrl + '/brand/deleteBrandById', params);
}

// 溶剂类型
async function solventList(params, ctx) {
    return axios.fetchGet(baseUrl + '/solventEncoding/list', params)
}

function solventAdd(params) {
    return axios.fetchPost(baseUrl + '/solventEncoding/add', params);
}

function solventUpdate(params) {
    return axios.fetchPost(baseUrl + '/solventEncoding/updateById', params);
}
function solventEnableOrDisable(params) {
    return axios.fetchPost(baseUrl + '/solventEncoding/deleteById', params);
}
// 地区管理
async function areaList(params, ctx) {
    return axios.fetchGet(baseUrl + '/areaEncoding/list', params)
}

function areaAdd(params) {
    return axios.fetchPost(baseUrl + '/areaEncoding/add', params);
}

function areaUpdate(params) {
    return axios.fetchPost(baseUrl + '/areaEncoding/update', params);
}
function areaEnableOrDisable(params) {
    return axios.fetchPost(baseUrl + '/areaEncoding/deleteById', params);
}
function findBrandById(params) {
    return axios.fetchGet(baseUrl + '/brand/findBrandById', params)
}
function reviewBrandById(params) {
    return axios.fetchPost(baseUrl + '/brand/reviewBrandById', params);
}

// terpenes 萜烯接口
async function terpenesList(params, ctx) {
    return axios.fetchGet(baseUrl + '/terpenes/list', params);
}

function terpenesAdd(params) {
    return axios.fetchPost(baseUrl + '/terpenes/add', params);
}

function terpenesUpdate(params) {
    return axios.fetchPost(baseUrl + '/terpenes/update', params);
}

function terpenesEnableOrDisable(params) {
    return axios.fetchPost(baseUrl + '/terpenes/deleteById', params);
}

//下载品牌链接二维码
function downBrandLink(params = {}) {
    let d = JSON.parse(JSON.stringify(params));
    d.id = params.id;
    d["supplier-token"] = localStorage["auth-token"];
    location.href =
        baseUrl + "/brand/downBrandLink" + "?" + qs.stringify(d);
}
//禁用或者启用品牌
function enableOrDisableBrand(params) {
    return axios.fetchPost(baseUrl + '/brand/enableOrDisableBrand', params);
}
export default {
    list,
    find,
    add,
    update,
    del,
    ingredientList,
    ingredientAdd,
    ingredientUpdate,
    ingredientEnableOrDisable,
    brandList,
    brandAdd,
    brandDelete,
    brandUpdate,
    solventList,
    solventAdd,
    solventUpdate,
    solventEnableOrDisable,
    areaList,
    areaAdd,
    areaUpdate,
    areaEnableOrDisable,
    manufacturerList,
    manufacturerAdd,
    manufacturerFind,
    manufacturerUpdate,
    findBrandById,
    reviewBrandById,
    downBrandLink,
    terpenesList,
    terpenesAdd,
    terpenesUpdate,
    terpenesEnableOrDisable,
    enableOrDisableBrand,
}