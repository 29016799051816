var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vo-column" },
    [
      _c("h4", { staticClass: "font-size-20 mt-2" }, [_vm._v("Operation Log")]),
      _c("b-card", [
        _c(
          "div",
          { staticClass: "table-responsive mb-0" },
          [
            _c("b-table", {
              ref: "table",
              attrs: {
                striped: "",
                borderless: "",
                "show-empty": "",
                small: "",
                "empty-text": "No records",
                id: "device-table",
                "head-variant": "none",
                striped: true,
                items: _vm.provider,
                fields: _vm.fields,
                "per-page": _vm.filterParams.pageSize,
                "current-page": _vm.filterParams.pageNum,
                "sort-by": _vm.filterParams.sortBy,
                "sort-desc": _vm.filterParams.sortDesc,
                filter: _vm.filter,
                "filter-includedfields": _vm.filterOn
              },
              on: {
                "update:sortBy": function($event) {
                  return _vm.$set(_vm.filterParams, "sortBy", $event)
                },
                "update:sort-by": function($event) {
                  return _vm.$set(_vm.filterParams, "sortBy", $event)
                },
                "update:sortDesc": function($event) {
                  return _vm.$set(_vm.filterParams, "sortDesc", $event)
                },
                "update:sort-desc": function($event) {
                  return _vm.$set(_vm.filterParams, "sortDesc", $event)
                },
                filtered: _vm.onFiltered
              },
              scopedSlots: _vm._u([
                {
                  key: "table-busy",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center text-danger my-2" },
                        [
                          _c("b-spinner", { staticClass: "align-middle" }),
                          _c("strong", [_vm._v("Loading...")])
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "cell(index)",
                  fn: function(data) {
                    return [
                      _vm._v(
                        _vm._s(
                          data.index +
                            (_vm.filterParams.pageNum - 1) *
                              _vm.filterParams.pageSize +
                            1
                        ) + " "
                      )
                    ]
                  }
                },
                {
                  key: "cell(operationContent)",
                  fn: function(row) {
                    return [_c("span", [_vm._v("Modify Status")])]
                  }
                },
                {
                  key: "cell(OperationTime)",
                  fn: function(row) {
                    return [_c("span", [_vm._v("April 8th 2024 19:04:46")])]
                  }
                },
                {
                  key: "cell(OperationAccount)",
                  fn: function(row) {
                    return [_c("span", [_vm._v("mark@ikrusher.cn")])]
                  }
                },
                {
                  key: "cell(Operator)",
                  fn: function(row) {
                    return [_c("span", [_vm._v("mark")])]
                  }
                }
              ])
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }