<template>
  <MaskView
    borderStyle="border: 1px solid #d7d7d7"
    outerBorderStyle="1px dashed #02a7f0"
    :background-color="item.startStatus === 0 ? `rgba(242, 242, 242, 1)` : ''"
  >
    <div class="position-relative w-100 h-100">
      <div class="position-absolute img-wrap">
        <img
          style="width: 90%; height: 81%; object-fit: contain"
          :src="item.coverImage"
        />
        <div class="flex-container">
          <span class="productName">{{ item.title }}</span>
        </div>
        <div class="flex-container" style="margin-top: 4px">
          <span class="nickName">{{ item.nickName }}</span>
          <span class="createTime">{{ item.createTime }}</span>
        </div>
      </div>
      <div class="status position-absolute" v-if="item.startStatus == 0">
        <b-badge class="custom-badge" href="#" variant="danger"
          >Disable</b-badge
        >
      </div>
    </div>
    <template #top>
      <div class="vo-row align-items-center vo-gap-4 justify-content-end p-2">
        <img
          v-has="'blogMgmt::Disable'"
          v-if="item.startStatus == 1"
          src="@/assets/enable.svg"
          class="cursor"
          @click="$emit('clickStatus')"
        />
        <img
          v-has="'blogMgmt::Disable'"
          v-if="item.startStatus == 0"
          src="@/assets/disable.svg"
          class="cursor"
          @click="$emit('clickStatus')"
        />
        <img
          src="@/assets/view.svg"
          class="cursor"
          @click="$emit('clickDetail')"
        />
        <img
          v-has="'blogMgmt::Modify'"
          src="@/assets/edit.svg"
          class="cursor"
          @click="$emit('clickEdit')"
        />
      </div>
    </template>
  </MaskView>
</template>

<script>
import MaskView from "@/components/MaskView.vue";

export default {
  name: "BrandItem",
  components: { MaskView },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.status {
  top: 0px;
  left: 0;
  font-size: 20px;
}

.img-wrap {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}
.flex-container {
  display: flex;
  justify-content: space-around; /* 分散对齐 */
  width: 100%; /* 确保宽度适应父容器 */
}
.nickName {
  font-family: "Arial Normal", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  color: #333333;
}

.createTime {
  font-size: 12px;
  color: #AAAAAA;
  font-family: "Arial Normal", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
}

::v-deep .badge {
  border-radius: 0;
}

.operate-btn {
  width: 80px;
}
.productName {
  color: #000000;
  font-weight: 700;
  font-family: "Arial Negreta", "Arial Normal", "Arial", sans-serif;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出的部分 */
  text-overflow: ellipsis; /* 省略号 */
  width: 100%; /* 确保宽度适应父容器 */
}

.disableBox {
  background-color: rgba(242, 242, 242, 1);
}

.custom-badge {
  height: 30px; /* 设置高度 */
  line-height: 30px; /* 行高与高度相同，使文本垂直居中 */
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  text-align: center; /* 确保文本居中对齐 */
  margin-top: 12px;
}
</style>