var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "form-row",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-bottom": "1em"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.form.orderName.length) +
                                          "/100 "
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Batch Name:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "order-name",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "order-name",
                                  required: "",
                                  maxlength: "100",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');",
                                  placeholder: "Please enter"
                                },
                                model: {
                                  value: _vm.form.orderName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "orderName", $$v)
                                  },
                                  expression: "form.orderName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Product Type:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "deviceType",
                                "label-class": "requiredRow"
                              }
                            },
                            _vm._l(_vm.types.deviceType, function(item, key) {
                              return _c(
                                "b-form-radio",
                                {
                                  key: key,
                                  staticClass: "custom-control-inline pt-2",
                                  attrs: {
                                    name: "deviceType-options" + key,
                                    value: item.value
                                  },
                                  on: { change: _vm.deviceTypeIdChange },
                                  model: {
                                    value: _vm.form.type,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "type", $$v)
                                    },
                                    expression: "form.type"
                                  }
                                },
                                [_vm._v(_vm._s(item.text) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "SKU Type:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "skuType",
                                "label-class": "requiredRow"
                              }
                            },
                            _vm._l(_vm.types.skuType, function(item, key) {
                              return _c(
                                "b-form-radio",
                                {
                                  key: key,
                                  staticClass: "custom-control-inline pt-2",
                                  attrs: {
                                    name: "skuType-options" + key,
                                    value: item.value
                                  },
                                  on: { change: _vm.skuTypeIdChange },
                                  model: {
                                    value: _vm.form.skuType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "skuType", $$v)
                                    },
                                    expression: "form.skuType"
                                  }
                                },
                                [_vm._v(_vm._s(item.text) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _vm.showBrand
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Brand Name:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "brand-name",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.types.brandNames,
                                      "value-field": "id",
                                      "text-field": "brandName",
                                      required: "",
                                      oninvalid:
                                        "setCustomValidity('Please select');",
                                      oninput: "setCustomValidity('')"
                                    },
                                    on: { change: _vm.brandIdChange },
                                    model: {
                                      value: _vm.form.brandId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "brandId", $$v)
                                      },
                                      expression: "form.brandId"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _vm.showProduct
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Product:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "cartridge-model",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "cartridge-model",
                                      options:
                                        _vm.types.selectAllTestSmokeBombModel,
                                      "value-field": "id",
                                      "text-field": "modelName",
                                      required: "",
                                      oninvalid:
                                        "setCustomValidity('Please select');",
                                      oninput: "setCustomValidity('')"
                                    },
                                    on: {
                                      change: _vm.testSmokeBombModeIdChange
                                    },
                                    model: {
                                      value: _vm.form.testSmokeBombModeId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "testSmokeBombModeId",
                                          $$v
                                        )
                                      },
                                      expression: "form.testSmokeBombModeId"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _vm.showFlavor
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Flavor:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "flavor",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.types.flavorList,
                                      "value-field": "id",
                                      "text-field": "modelName",
                                      required: "",
                                      oninvalid:
                                        "setCustomValidity('Please select');",
                                      oninput: "setCustomValidity('')"
                                    },
                                    model: {
                                      value: _vm.form.smokeBombModelId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "smokeBombModelId",
                                          $$v
                                        )
                                      },
                                      expression: "form.smokeBombModelId"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        {
                          attrs: { rightCol: 3 },
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-bottom": "1em"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "10px"
                                          }
                                        },
                                        [_vm._v("PCS")]
                                      ),
                                      _vm._v(
                                        " Default packaging：1 box contains 50pcs "
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Production Quantity:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "production-quantity",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "production-quantity",
                                  type: "number",
                                  min: "0",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('')",
                                  placeholder: "Please Enter"
                                },
                                model: {
                                  value: _vm.form.batchQuantity,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "batchQuantity", $$v)
                                  },
                                  expression: "form.batchQuantity"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Check Custom Url:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "check-custom-url",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticStyle: { "margin-top": "5px" },
                                  attrs: {
                                    id: "check-custom-url",
                                    name: "checkCustomUrl",
                                    value: 1,
                                    "unchecked-value": 0,
                                    switch: "",
                                    size: "lg"
                                  },
                                  model: {
                                    value: _vm.checkCustomUrl,
                                    callback: function($$v) {
                                      _vm.checkCustomUrl = $$v
                                    },
                                    expression: "checkCustomUrl"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.checkCustomUrl == 1 ? "Yes" : "No"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _vm.checkCustomUrl == 1
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "URL:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "check-url",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "check-url",
                                      required: "",
                                      oninvalid:
                                        "setCustomValidity('Please enter');",
                                      oninput: "setCustomValidity('')",
                                      placeholder: "Please enter"
                                    },
                                    model: {
                                      value: _vm.form.checkUrl,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "checkUrl", $$v)
                                      },
                                      expression: "form.checkUrl"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Batch Popup Type:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "batchPopupType",
                                "label-class": "requiredRow"
                              }
                            },
                            _vm._l(_vm.types.batchPopupType, function(
                              item,
                              key
                            ) {
                              return _c(
                                "b-form-radio",
                                {
                                  key: key,
                                  staticClass: "custom-control-inline pt-2",
                                  attrs: {
                                    name: "batchPopupType-options" + key,
                                    value: item.value
                                  },
                                  model: {
                                    value: _vm.batchPopupTypeId,
                                    callback: function($$v) {
                                      _vm.batchPopupTypeId = $$v
                                    },
                                    expression: "batchPopupTypeId"
                                  }
                                },
                                [_vm._v(_vm._s(item.text) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _vm.batchPopupTypeId == 2
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "URL",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "url-popup",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "url-popup",
                                      required: "",
                                      oninvalid:
                                        "setCustomValidity('Please enter');",
                                      oninput: "setCustomValidity('')",
                                      placeholder: "Please enter"
                                    },
                                    model: {
                                      value: _vm.form.defaultUrlPopup,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "defaultUrlPopup",
                                          $$v
                                        )
                                      },
                                      expression: "form.defaultUrlPopup"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _vm.isUnicoreus
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "PBCA Supplier:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "pbca-supplier",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "pbca-supplier",
                                      type: "text",
                                      min: "0",
                                      maxlength: "250",
                                      required: "",
                                      oninvalid:
                                        "setCustomValidity('Please enter');",
                                      oninput: "setCustomValidity('');",
                                      placeholder: "Please Enter"
                                    },
                                    model: {
                                      value: _vm.form.pcbaSupplier,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "pcbaSupplier", $$v)
                                      },
                                      expression: "form.pcbaSupplier"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _vm.isUnicoreus
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Charger of PCBA:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "charger-of-pcba",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "charger-of-pcba",
                                      type: "text",
                                      min: "0",
                                      maxlength: "250",
                                      required: "",
                                      oninvalid:
                                        "setCustomValidity('Please enter');",
                                      oninput: "setCustomValidity('')",
                                      placeholder: "Please Enter"
                                    },
                                    model: {
                                      value: _vm.form.chargerOfPcba,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "chargerOfPcba", $$v)
                                      },
                                      expression: "form.chargerOfPcba"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _vm.isUnicoreus
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Assembly Supplier:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "assembly-supplier",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "assembly-supplier",
                                      type: "text",
                                      min: "0",
                                      maxlength: "250",
                                      required: "",
                                      oninvalid:
                                        "setCustomValidity('Please enter');",
                                      oninput: "setCustomValidity('')",
                                      placeholder: "Please Enter"
                                    },
                                    model: {
                                      value: _vm.form.assemblySupplier,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "assemblySupplier",
                                          $$v
                                        )
                                      },
                                      expression: "form.assemblySupplier"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _vm.isUnicoreus
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Charger of Assembly:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "charger-of-assembly",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "charger-of-assembly",
                                      type: "text",
                                      min: "0",
                                      maxlength: "250",
                                      required: "",
                                      oninvalid:
                                        "setCustomValidity('Please enter');",
                                      oninput: "setCustomValidity('')",
                                      placeholder: "Please Enter"
                                    },
                                    model: {
                                      value: _vm.form.chargerOfAssembly,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "chargerOfAssembly",
                                          $$v
                                        )
                                      },
                                      expression: "form.chargerOfAssembly"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _vm.isUnicoreus
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Manufacturer:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "manufacturer"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "manufacturer",
                                      placeholder: "Please Enter"
                                    },
                                    model: {
                                      value: _vm.form.manufacturerEncodingName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "manufacturerEncodingName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.manufacturerEncodingName"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-bottom": "1em"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.remarks &&
                                              _vm.form.remarks.length
                                          )
                                      ),
                                      !_vm.form.remarks
                                        ? _c("span", [_vm._v("0")])
                                        : _vm._e(),
                                      _vm._v("/200 ")
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Batch Notes:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "remarks"
                              }
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "remarks",
                                  placeholder: "Please enter",
                                  rows: "6",
                                  "max-rows": "6",
                                  maxlength: "200"
                                },
                                model: {
                                  value: _vm.form.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "remarks", $$v)
                                  },
                                  expression: "form.remarks"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Status:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "status",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  options: _vm.types.status,
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "status", $$v)
                                  },
                                  expression: "form.status"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { "label-cols-lg": "3" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "custom-width",
                  attrs: { type: "submit", variant: "primary" }
                },
                [_vm._v("Save")]
              ),
              _c(
                "b-button",
                { staticClass: "ml-4 custom-width", attrs: { type: "reset" } },
                [_vm._v("Cancel")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/success.png"), noBtn: "OK" },
          on: { cancel: _vm.cancel, hide: _vm.hide },
          model: {
            value: _vm.successAlear,
            callback: function($$v) {
              _vm.successAlear = $$v
            },
            expression: "successAlear"
          }
        },
        [_vm._v(" Saved successfully ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }