<template>
  <b-modal v-model="modalVisible" :size="size"  centered @hide="hide">
    <template>
      <div class="imageRow" v-if="showLogo">
        <img :src="imageSrc" alt="" />
      </div>
      <div class="contentRow">
        <slot />
      </div>

      <div class="buttonRow" v-if="customBtn">
        <b-button class="modal-btn" variant="outline-secondary" @click="customEvent">
          {{ customBtn }}
        </b-button>
      </div>
      <div class="buttonRow">
        <b-button v-if="okBtn" class="modal-btn" variant="info" @click="ok">
          {{ okBtn }}
        </b-button>
        <b-button
          v-if="noBtn"
          class="modal-btn"
          variant="outline-secondary"
          @click="customCancel"
        >
          {{ noBtn }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<style>
.modal-btn{
  width: 87px;
}
.imageRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.imageRow img {
  width: 50px;
  height: 50px;
}
.contentRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  text-align: center;
}
.contentRow p {
  font-size: 15px;
}
.buttonRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 5px;
}
.modal-header {
  display: none;
}
.modal-footer {
  display: none;
}
</style>

<script>
export default {
  name:"Dialog",
  props: {
    showLogo: {
      type:Boolean,
      default:true,
    },
    imageSrc: {
      type: String,
      default:
        "https://files.axshare.com/gsc/ZUM0H1/39/80/bf/3980bf3dcc5f4a7cb2849575c09f4160/images/production_mgmt-product_mgmt/u195.svg?pageId=b64b71ba-c6ec-45d7-b5f9-70018923374e",
    },
    content: {
      type: String,
      default:
        "Password reset successful.<br>Please use your new password to login.",
    },
    okBtn: {
      type: String,
      default: "",
    },
    noBtn: {
      type: String,
      default: "",
    },
    customBtn: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
    size:{
      type: String,
      default: "sm",
    },
  },
  computed: {
    modalVisible: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    customEvent() {
      this.$emit("customEvent");
    },
    ok() {
      this.$emit("ok");
    },
    customCancel() {
      this.$emit("cancel");
    },
    hide() {
      this.$emit("hide");
    },
  },
};
</script>
