<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="4">
              <!-- 分享用户 -->
              <b-form-group
                label="Share User"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="share-user"
              >
                <b-form-input
                  id="share-user"
                  v-model="search_condition.shareUser"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- 邀请用户 -->
            <b-col lg="4">
              <b-form-group
                label="Invite User"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="invite-user"
              >
                <b-form-input
                  id="invite-user"
                  v-model="search_condition.beInvitedUser"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- 邀请时间 -->
            <b-col lg="4">
              <b-form-group
                label="Invitation Time"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="invitation-time"
              >
                <date-picker
                  v-model="invitationTime"
                  format="MM/DD/YYYY HH:mm:ss"
                  type="datetime"
                  range
                  append-to-body
                  lang="en"
                  placeholder="Select date"
                ></date-picker>
              </b-form-group>
            </b-col>
            <!-- 分享者奖品 -->
            <b-col lg="4">
              <b-form-group
                label="Sharer Prizes"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="sharer-prizes"
              >
                <b-form-select
                  id="sharer-prizes"
                  :options="types.rewardList"
                  v-model="search_condition.inviterCoupon"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <!-- 受邀者奖品 -->
            <b-col lg="4">
              <b-form-group
                label="Invited Prizes"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="invited-prizes"
              >
                <b-form-select
                  id="invited-prizes"
                  :options="types.rewardList"
                  v-model="search_condition.shareInstructions"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <b-button-group>
            <b-button
              v-has="'shareRewardList::Download'"
              @click="download()"
              variant="primary"
              >Download</b-button
            >
          </b-button-group>
          <!-- table -->
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="No records"
              id="table"
              head-variant="light"
              ref="table"
              :busy="isBusy"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">
                {{
                  data.index +
                  (search_condition.pageNum - 1) * search_condition.pageSize +
                  1
                }}
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <br />
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

/**
 * Device component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    timeZoneConversion,
  },
  data() {
    return {
      title: "Share Reward List",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Marketing Manage",
          // href: "/"
        },
        {
          text: "Share Reward List",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [
        "deviceNumber",
        "deviceCoding",
        "deviceLocation",
        "activationTime",
      ],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        //活动名称
        { key: "activityName", label: "Activity Name", class: "text-center" },
        //分享者
        { key: "shareUser", label: "Share User", class: "text-center" },
        //邀请用户
        { key: "beInvitedUser", label: "Invite User", class: "text-center" },
        //邀请时间
        { key: "inviteTime", label: "Invitation Time", class: "text-center" },
        //分享者奖品
        {
          key: "inviterCoupon",
          label: "Sharer Prizes",
          class: "text-center",
        },
        //受邀请奖品
        {
          key: "shareInstructions",
          label: "Invited Prizes",
          class: "text-center",
        },
      ],
      invitationTime: [], //邀请时间（显示用）
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        shareUser: "", //分享用户
        beInvitedUser: "", //邀请用户
        startTime: "", //邀请开始时间
        endTime: "", //邀请结束时间
        inviterCoupon: "", //分享者奖品
        shareInstructions: "", //受邀者奖品
      },
      isBusy: false,
      has_data: false,
      form: { brandId: "", file: "", remarks: "", id: "" },
      types: {
        //奖励类型
        rewardList: [{ text: "Please select", value: "" }],
        status: [
          { text: "Please select", value: "", disabled: false },
          { text: "Disabled", value: 0, disabled: false },
          { text: "Enable", value: 1, disabled: false },
        ],
      },
    };
  },
  watch: {
    invitationTime: function (val) {
      this.search_condition.startTime = val[0]
        ? timeZoneConversion.queryTime(val[0])
        : val[0];
      if (val[1]) {
        this.search_condition.endTime = timeZoneConversion.queryTime(val[1]);
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  created() {
    this.getAllRewardList();
  },
  mounted() {
    this.resetModal();
  },
  methods: {
    provider(ctx) {
      this.isBusy = true;
      return this.$api.Marketing.selectUserSharingRewardsList(
        this.search_condition,
        ctx
      )
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            x.data.forEach((element) => {
              element.invitationTime = "";
              let startTime = timeZoneConversion.getLocalTime(
                element.startTime
              );
              let endTime = timeZoneConversion.getLocalTime(element.endTime);
              element.invitationTime = startTime + " ~ " + endTime;
              element.inviteTime = timeZoneConversion.getLocalTime(
                element.inviteTime
              );
            });
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    resetModal() {
      this.form = { brandId: "", file: "", remarks: "", id: "" };
      this.form = new FormData();
    },
    handleSubmit() {
      this.resetModal();
    },
    getAllRewardList() {
      this.$api.Dropdown.selectAllAwardCoupon()
        .then((res) => {
          if (res.success) {
            this.types.rewardList = [{ text: "Please select", value: "" }];
            for (let i = 0; i < res.data.length; i++) {
              this.types.rewardList.push({
                text: res.data[i].couponName,
                value: res.data[i].id,
              });
            }
          } else {
            if (res.message) {
              that.$bvToast.toast(res.message);
            }
          }
        })
        .catch((res) => {
          if (res.message) this.$bvToast.toast(res.message);
        });
    },
    download() {
      if (
        this.search_condition.startTime == "" ||
        this.search_condition.endTime == ""
      ) {
        this.$bvToast.toast("Please select Invitation Time.", {
          title: "Tips",
          variant: "warning",
          solid: true,
        });
        return;
      } else if (this.totalRows <= 0) {
        this.$bvToast.toast("No data for this time period.", {
          title: "Tips",
          variant: "warning",
          solid: true,
        });
        return;
      }
      this.$api.Marketing.downUserSharingRewardsList(this.search_condition);
    },
  },
};
</script>
