<!--
 * @Author: Code-HHX
 * @Date: 2022-03-29 16:10:38
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-02 15:43:05
 * @Description: 添加积分活动
-->
<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<b-card>
			<b-form @submit="onSubmit" @reset="onReset" v-if="show">
				<!-- Activity Name 活动名称-->
				<b-form-group
					label="Activity Name:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="activity-name"
					label-class="requiredRow"
				>
					<b-form-input
						id="activity-name"
						v-model="form.activityName"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
						placeholder="Please enter"
					></b-form-input>
				</b-form-group>
				<!-- Activity Time 上架时间 -->
				<b-form-group
					label="Activity Time:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="activity-time"
					label-class="requiredRow"
					description="Activity time cannot overlap"
				>
					<date-picker
						v-model="form.activityTime"
						format="MM/DD/YYYY HH:mm:ss"
						range
						append-to-body
						lang="en"
						placeholder="Select date"
						type="datetime"
						:disabled-date="notBeforeToday"
						@change="onChangeActivityTime"
					></date-picker>
				</b-form-group>
				<!-- Reward 奖励行为 -->
				<b-form-group
					label="Reward:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="reward"
					label-class="requiredRow"
				>
					<div class="reward">
						<!-- 激活设备奖励 -->
						<div class="activation">
							<span class="activation-title">
								Activate the device to get
							</span>
							<b-form-input
								id="reward"
								v-model="form.activatedAwardIntegral"
								required
								type="number"
								oninvalid="setCustomValidity('Please enter');"
								oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
								placeholder="Please enter"
								style="width: 10%"
							>
							</b-form-input>
							<span class="activation-title">
								integral
							</span>
						</div>
						<!-- 邀请好友奖励 -->
						<div class="invitation">
							<span class="invitation-title">
								Invite friends to get
							</span>
							<b-form-input
								id="reward"
								v-model="form.inviteAwardIntegral"
								required
								type="number"
								oninvalid="setCustomValidity('Please enter');"
								oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
								placeholder="Please enter"
								style="width: 10%"
							>
							</b-form-input>
							<span class="invitation-title">
								integral，Invitees get
							</span>
							<b-form-input
								id="reward"
								v-model="form.beInvitedAwardIntegral"
								required
								type="number"
								oninvalid="setCustomValidity('Please enter');"
								oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
								placeholder="Please enter"
								style="width: 10%"
							>
							</b-form-input>
							<span class="invitation-title">
								integral
							</span>
						</div>
					</div>
				</b-form-group>

				<!-- remarks 备注 -->
				<b-form-group
					label="Notes:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="remarks"
				>
					<b-form-textarea
						id="remarks"
						v-model="form.remarks"
						placeholder="Enter Notes"
						rows="3"
						max-rows="6"
					></b-form-textarea>
				</b-form-group>

				<b-form-group label-cols-lg="2">
					<b-button type="submit" variant="primary">Confirm</b-button>
					<b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
				</b-form-group>
			</b-form>
			<!-- <p>{{form}}</p> -->
		</b-card>
	</Layout>
</template>
<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import DatePicker from 'vue2-datepicker'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'

import { onReady, upload_img } from '@/api/upload'

/**
 * Cartridge Model Add
 */
export default {
	components: {
		ckeditor: CKEditor.component,
		Layout,
		PageHeader,
		DatePicker,
		timeZoneConversion,
	},
	data() {
		return {
			title: /edit/i.test(window.location.href)
				? 'Integral Activity Modify'
				: 'Integral Activity Create',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'Production Mgmt',
					// href: "/"
				},
				{
					text: /edit/i.test(window.location.href)
						? 'Integral Activity Modify'
						: 'Integral Activity Create',
					active: true,
				},
			],
			form: {
				supplierId: localStorage.supplierId,
				activityName: '', //活动名称
				activityTime: [], //活动时间(显示用)
				startTime: '', //活动开始时间
				endTime: '', //活动结束时间
				activatedAwardIntegral: '', //激活设备奖励
				inviteAwardIntegral: '', //邀请者奖励
				beInvitedAwardIntegral: '', //受邀者奖励
				remarks: '', //备注
			},

			show: true, // 表单显示
			editor: ClassicEditor,
			editId: window.location.search,
		}
	},
	beforeCreate() {
		this.editId = window.location.search
	},
	mounted() {
		if (/edit/i.test(window.location.href)) {
			this.form = {
				id: this.$route.query.id,
				supplierId: localStorage.supplierId,
			}
			this.$api.Marketing.selectGemsActivityDetailsById(this.form)
				.then((res) => {
					this.form = res.data
					this.$set(this.form, 'activityTime', [
						new Date(res.data.startTime),
						new Date(res.data.endTime),
					])
				})
				.catch((error) => {})
		}
	},
	methods: {
		onReady: onReady,
		onSubmit(evt) {
			evt.preventDefault()
			if (/edit/i.test(window.location.href)) {
				this.$api.Marketing.saveOrUpdateGemActivityVo(this.form).then(
					(res) => {
						if (res.success) {
							this.$toastBack(res)
						} else {
							if (res.message) {
								this.$toast(res)
							}
						}
					}
				)
			} else {
				this.$api.Marketing.saveOrUpdateGemActivityVo(this.form).then(
					(res) => {
						if (res.success) {
							this.$toastBack(res)
						} else {
							if (res.message) {
								this.$toast(res)
							}
						}
					}
				)
			}
		},
		onReset(evt) {
			evt.preventDefault()
			this.form = {
				supplierId: localStorage.supplierId,
				remarks: '',
			}
			this.show = false
			this.$nextTick(() => {
				// this.show = true;
				this.$router.go(-1)
			})
		},
		//禁止选择早于今天的时间
		notBeforeToday(date) {
			return date < new Date(new Date().setHours(0, 0, 0, 0))
		},
		//活动时间
		onChangeActivityTime(val) {
			console.log(val)
			if (val[0] == null && val[1] == null) {
				this.form.startTime = ''
				this.form.endTime = ''
				this.form.activityTime = []
				return
			}
			this.form.startTime = val[0]
				? timeZoneConversion.queryTime(val[0])
				: val[0]
			if (val[1]) {
				this.form.endTime = timeZoneConversion.queryTime(val[1])
			} else {
				this.form.endTime = val[1]
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.reward {
	display: flex;
	flex-direction: column;

	.activation {
		display: flex;
		align-items: center;
		margin-bottom: 8px;

		.activation-title {
			font-weight: 500;
			margin: 0 8px;
		}
	}
	.invitation {
		display: flex;
		align-items: center;
		margin-bottom: 8px;

		.invitation-title {
			font-weight: 500;
			margin: 0 8px;
		}
	}
}
</style>
