import {format} from "./time"
import axios from "../config/http.js"
import baseUrl from "../config/env.js"


function find(params){
   return axios.fetchGet(baseUrl + '/messages/findById',params)
}

function del(params){
    return axios.fetchPost(baseUrl + '/messages/deleteById', params);
}

function selectUserList(){
   return axios.fetchGet(baseUrl + '/messages/selectUserList',params)
}

export default {
    find,
    del,
    selectUserList,
}
