var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "8" } },
                [
                  _c(
                    "b-form",
                    {
                      attrs: { action: "#" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit_form($event)
                        },
                        reset: _vm.reset_form
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Terpenes Name",
                            "label-cols-lg": "3",
                            "label-align-sm": "right",
                            "label-for": "terpenes-name",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "terpenes-name",
                              placeholder: "Please enter",
                              trim: "",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput:
                                "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                            },
                            model: {
                              value: _vm.typeform.terpenesName,
                              callback: function($$v) {
                                _vm.$set(_vm.typeform, "terpenesName", $$v)
                              },
                              expression: "typeform.terpenesName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Terpenes Explains",
                            "label-cols-lg": "3",
                            "label-align-sm": "right",
                            "label-for": "terpenes-statement"
                          }
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              id: "explains",
                              placeholder: "Please enter",
                              rows: "3",
                              "max-rows": "6"
                            },
                            model: {
                              value: _vm.typeform.explains,
                              callback: function($$v) {
                                _vm.$set(_vm.typeform, "explains", $$v)
                              },
                              expression: "typeform.explains"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Terpenes Notes",
                            "label-cols-lg": "3",
                            "label-align-sm": "right",
                            "label-for": "terpenes-remark"
                          }
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              id: "remark",
                              placeholder: "Please enter",
                              rows: "3",
                              "max-rows": "6"
                            },
                            model: {
                              value: _vm.typeform.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.typeform, "remarks", $$v)
                              },
                              expression: "typeform.remarks"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "form-group mb-0" }, [
                        _c("div", { staticClass: "text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "submit" }
                            },
                            [_vm._v("Confirm")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary m-l-5 ml-1",
                              attrs: { type: "reset" }
                            },
                            [_vm._v(" Cancel ")]
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }