<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <b-card>
      <b-form @submit="onSubmit" @reset="onReset">
        <!-- 问题 -->
        <b-form-group
          label="Question Title:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="title"
        >
          <b-form-input id="title" v-model="form.title" trim></b-form-input>
        </b-form-group>

        <!-- 问题分类 -->
        <b-form-group
          label="Question category:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="categoryId"
        >
          <b-form-select
            id="categoryId"
            v-model="form.categoryId"
            :options="types.questionCategorys"
            value-field="id"
            text-field="name"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Model:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="modelId"
        >
          <b-form-select
            id="modelId"
            v-model="form.modelId"
            :options="types.modelIds"
            value-field="id"
            text-field="name"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="frontCover:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="frontCover"
        >
          <input
            type="file"
            class="filestyle"
            data-input="false"
            id="filestyle-1"
            style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
            tabindex="-1"
            @change="upload($event)"
          />

          <div class="bootstrap-filestyle input-group">
            <div
              name="filedrag"
              style="position: absolute; width: 100%; height: 35px; z-index: -1"
            ></div>
            <span class="group-span-filestyle" tabindex="0">
              <label
                for="filestyle-1"
                style="margin-bottom: 0"
                class="btn btn-secondary"
              >
                <span class="buttonText"> upload picture </span>
              </label>
              <span>
                Recommended size of picture: 512 pixels * 512 pixels, supporting
                JPG and PNG formats, within 2m.
              </span>
            </span>
          </div>
        </b-form-group>

        <!-- 问题答案  -->
        <b-form-group label="Answer:" label-cols-lg="2" label-align-lg="right">
          <ckeditor
            v-model="form.content"
            :editor="editor"
            @ready="onReady"
          ></ckeditor>
        </b-form-group>

        <b-form-group label-cols-lg="2">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" variant="danger" class="m-l-5 ml-1"
            >Cancel</b-button
          >
        </b-form-group>
      </b-form>
      <goback />
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img } from "@/api/upload";
import goback from "@/components/goback";

/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    goback,
  },
  data() {
    return {
      page: {
        title: "FAQ Create",
        items: [
          {
            text: "UNI-CORE",
            // href: "/"
          },
          {
            text: "App Manage",
            // href: "/"
          },
          {
            text: "FAQ Create",
            active: true,
          },
        ],
      },
      form: {
        modelId: "",
        createTime: "",
        categoryId: "",
        content: "",
        frontCover: "",
        id: "",
        isDelete: "",
        nickName: "",
        supplierId: "",
        title: "",
        userId: "",
        file: "",
      },
      types: {
        questionCategorys: [{ id: "", name: "" }],
        modelIds: [],
      },
      editor: ClassicEditor,
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    onReady: onReady,
    init() {
      this.$api.Category.list().then((res) => {
        if (res.success) {
          this.types.questionCategorys = this.types.questionCategorys.concat(
            res.data
          );
        }
      });
      this.types.modelIds = [
        { id: 0, name: "all" },
        { id: 1, name: "d" },
      ];
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$api.Consultation.add(this.form).then((res) => {
        if (res.success) {
          this.$toastBack(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        createTime: "",
        categoryId: "",
        content: "",
        frontCover: "",
        id: "",
        isDelete: "",
        nickName: "",
        supplierId: "",
        title: "",
        userId: "",
        file: "",
      };
      this.$nextTick(() => {
        this.show = true;
      });
    },
    upload(e) {
      let file = e.target.files[0];
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          if (res.success) {
            this.form.frontCover = res.data;
            console.log("zzzzzzzz", this.form);
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
  },
};
</script>
