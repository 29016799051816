<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<b-card>
			<b-form @submit="onSubmit" @reset="onReset" v-if="show">
				<!-- Activity Name 活动名称-->
				<b-form-group
					label="Activity Name:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="activity-name"
					label-class="requiredRow"
				>
					<b-form-input
						id="activity-name"
						v-model="form.activityName"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
						placeholder="Please enter"
					>
					</b-form-input>
				</b-form-group>
				<!-- Activity Time 活动时间-->
				<b-form-group
					label="Activity Time:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="activity-time"
					label-class="requiredRow"
					description="Activity time cannot overlap"
				>
					<date-picker
						v-model="form.activityTime"
						format="MM/DD/YYYY HH:mm:ss"
						range
						append-to-body
						lang="en"
						placeholder="Select date"
						type="datetime"
						:disabled-date="notBeforeToday"
						@change="onChangeActivityTime"
					></date-picker>
				</b-form-group>
				<!-- Invitees 奖品  -->
				<b-form-group
					label="Invitees:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="prizes-for-invitees"
					label-class="requiredRow"
				>
					<b-form-select
						id="prizes-for-invitees"
						v-model="form.couponId"
						:options="types.rewardList"
						value-field="value"
						text-field="text"
						required
						oninvalid="setCustomValidity('Please select');"
						oninput="setCustomValidity('')"
						:disabled="form.activityTime.length <= 0"
					></b-form-select>
				</b-form-group>
				<!-- Page description copy 页面说明文案 -->
				<b-form-group
					label="Page Description:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="page-description"
				>
					<div id="wangeEditor" style="max-width: 1310px"></div>
				</b-form-group>

				<b-form-group label-cols-lg="2">
					<b-button type="submit" variant="primary">Confirm</b-button>
					<b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
				</b-form-group>
			</b-form>
			<!-- <p>{{form}}</p> -->
		</b-card>
	</Layout>
</template>
<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Multiselect from 'vue-multiselect'

import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import DatePicker from 'vue2-datepicker'

import { onReady, upload_img } from '@/api/upload'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'

import baseUrl from '@/config/env.js'
import E from 'wangeditor'
const editor = new E('#wangeEditor')

/**
 * Cartridge Model Add
 */
export default {
	components: {
		ckeditor: CKEditor.component,
		Layout,
		PageHeader,
		Multiselect,
		DatePicker,
		timeZoneConversion,
	},
	data() {
		return {
			title: this.editId ? 'Register Modify' : 'Register Create',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'Marketing Manage',
					// href: "/"
				},
				{
					text: this.editId ? 'Register Modify' : 'Register Create',
					active: true,
				},
			],
			form: {
				activityName: '', //活动名称
				startTime: '', //活动开始时间
				endTime: '', //活动结束时间
				activityTime: [], //活动时间（显示用）
				couponId: '', //注册奖品
				instructions: '', //分析页面说明
				supplierId: localStorage.supplierId,
			},
			types: {
				statuss: [
					{ text: 'true', value: 1, disabled: false },
					{ text: 'false', value: 0, disabled: false },
				],
				//奖励优惠券列表
				rewardList: [{ text: 'Please select', value: '' }],
			},
			show: true, // 表单显示
			editor: ClassicEditor,
			showReportLink: false,
			showReportEnclosure: false,
			editId: window.location.search,
		}
	},
	watch: {},
	beforeCreate() {
		this.editId = window.location.search
	},
	created() {
		this.getAllRewardList()
	},
	mounted() {
		this.init()
		if (/edit/i.test(window.location.href)) {
			this.selectInfo()
		}

		this.createEditor(editor)
	},
	methods: {
		init() {},
		selectInfo() {
			if (this.$route.query.id) {
				this.$api.Marketing.selectRegisterSettingsById(
					this.$route.query
				).then((res) => {
					if (res.success) {
						this.form = res.data
						editor.txt.html(res.data.instructions)
						this.$set(this.form, 'activityTime', [
							new Date(res.data.startTime),
							new Date(res.data.endTime),
						])
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
			} else {
				this.$bvToast.toast('id?')
			}
		},
		//禁止选择早于今天的时间
		notBeforeToday(date) {
			return date < new Date(new Date().setHours(0, 0, 0, 0))
		},
		onReady: onReady,
		onSubmit(evt) {
			evt.preventDefault()

			if (/edit/i.test(window.location.href)) {
				//修改
				this.form.instructions = editor.txt.html()
				if (this.form.rewardSettings == 1)
					this.form.addTargetNumber = []
				this.$api.Marketing.saveOrUpdateRegisterSettings(this.form).then(
					(res) => {
						if (res.success) {
							this.$toastBack(res)
						} else {
							if (res.message) {
								this.$toast(res)
							}
						}
					}
				)
			} else {
				//添加
				this.form.instructions = editor.txt.html()
				if (this.form.rewardSettings == 1)
					this.form.addTargetNumber = []
				this.$api.Marketing.saveOrUpdateRegisterSettings(this.form).then(
					(res) => {
						if (res.success) {
							this.$toastBack(res)
						} else {
							if (res.message) {
								this.$toast(res)
							}
						}
					}
				)
			}
		},
		onReset(evt) {
			evt.preventDefault()
			;(this.form = {
				supplierId: localStorage.supplierId,
			}),
				(this.show = false)
			this.$nextTick(() => {
				// this.show = true;
				this.$router.go(-1)
			})
		},
		//活动时间
		onChangeActivityTime(val) {
			if (val[0] == null && val[1] == null) {
				this.form.startTime = ''
				this.form.endTime = ''
				this.form.activityTime = []
				this.form.couponId = ''
				this.rewardList = [{ text: 'Please select', value: '' }]
				return
			}
			this.form.startTime = val[0]
				? timeZoneConversion.queryTime(val[0])
				: val[0]
			if (val[1]) {
				this.form.endTime = timeZoneConversion.queryTime(val[1])
			} else {
				this.form.endTime = val[1]
			}
			this.getAllRewardList()
		},
		getAllRewardList() {
			const {id,startTime, endTime} = this.$route.query
			let params = {
				id: id ? id : '',
				startTime: startTime ?startTime: this.form.startTime,
				endTime: endTime ? endTime:this.form.endTime,
			}
			this.$api.Dropdown.selectAllRegisterAwardCoupon(params)
				.then((res) => {
					if (res.success) {
						this.types.rewardList = [
							{ text: 'Please select', value: '' },
						]
						for (let i = 0; i < res.data.length; i++) {
							this.types.rewardList.push({
								text: res.data[i].couponName,
								value: res.data[i].id,
							})
						}
					} else {
						if (res.message) {
							that.$bvToast.toast(res.message)
						}
					}
				})
				.catch((res) => {
					if (res.message) this.$bvToast.toast(res.message)
				})
		},
		createEditor(object) {
			object.customConfig.lang = {
				设置标题: 'title',
				正文: 'p',
				链接文字: 'link text',
				链接: 'link',
				插入: 'insert ',
				视频: 'video',
				创建: 'init',
				图片链接: 'pictures linking',
				网络: 'internet',
				上传: 'upload ',
				图片: 'picture',
				默认: 'default',
				代码: 'code',
				格式如: 'format',
				新浪: 'sina',
				字号: 'Font size',
				字体: 'Font',
				设置列表: 'Settings list',
				有序列表: 'Ordered list',
				无序列表: 'Unordered list',
				插入: 'insert ',
				插入表格: 'insert table',
				编辑: 'edit ',
				最大宽度: 'maximum width',
				删除: 'delete ',
				行: 'row',
				列: 'column',
				的: ' ',
				表格: 'table',
				上传图片: 'Upload picture',
				网络图片: 'Network picture',
				插入表格: 'Insert table',
				插入视频: 'Insert video',
				插入代码: 'Insert code',
				文字颜色: 'Text color',
				背景色: 'Background color',
				对齐方式: 'Alignment',
				靠左: 'left',
				居中: 'Center',
				靠右: 'right',
				// 还可自定添加更多
			}

			object.customConfig.uploadImgServer =
				baseUrl + '/supplier/uploadFile'
			object.customConfig.uploadImgHeaders = {
				'supplier-token': window.localStorage.getItem('auth-token'),
			}
			object.customConfig.uploadFileName = 'file'

			object.customConfig.uploadImgHooks = {
				// 上传图片之前
				before: function(xhr) {
					console.log(xhr)

					// 可阻止图片上传
					// return {
					//   prevent: true,
					//   msg: "需要提示给用户的错误信息",
					// };
				},
				// 图片上传并返回了结果，图片插入已成功
				success: function(xhr) {
					console.log('success', xhr)
				},
				// 图片上传并返回了结果，但图片插入时出错了
				fail: function(xhr, editor, resData) {
					console.log('fail', resData)
				},
				// 上传图片出错，一般为 http 请求的错误
				error: function(xhr, editor, resData) {
					console.log('error', xhr, resData)
				},
				// 上传图片超时
				timeout: function(xhr) {
					console.log('timeout')
				},
				// 图片上传并返回了结果，想要自己把图片插入到编辑器中
				// 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
				customInsert: function(insertImgFn, result) {
					// result 即服务端返回的接口
					console.log('customInsert', result)

					// insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
					result.data.forEach((element) => {
						insertImgFn(element.url)
					})
				},
			}

			object.create()
		},
	},
}
</script>

<style scoped>
.rewardSetting {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
}
.rewardSetting-title {
	font-weight: 500;
	margin: 0 8px;
}
.mx-datepicker-popup {
	z-index: 10001;
}
a:hover {
	cursor: pointer;
}
</style>

<style>
.w-e-menu {
	z-index: 2 !important;
}
.w-e-text-container,
.w-e-toolbar {
	z-index: 1 !important;
}
</style>
