<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />
    <b-card>
      <b-row>
        <b-col cols="8">
          <b-form @submit="onSubmit">
            <!-- <span class="vo-front-style">Pop-up ad Info</span> -->
            <!-- 广告名 -->
            <FormRow>
              <b-form-group
                label="Choose Brand:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="choose-brand"
                label-class="requiredRow"
              >
                <v-select
                  v-model="form.brandId"
                  :options="types.brandOptions"
                  label="text"
                  :reduce="(option) => option.value"
                  placeholder="--All Brand--"
                  multiple
                  class="custom-placeholder"
                >
                </v-select>
              </b-form-group>
            </FormRow>

            <FormRow>
              <b-form-group
                label="Popups URL:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="url"
                label-class="requiredRow"
              >
                <b-form-input
                  v-model="form.url"
                  required
                  maxlength="250"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                  placeholder="Plase enter"
                >
                </b-form-input>
              </b-form-group>
              <template #right>
                <CountText :len="form.url ? form.url.length : 0" max="250" />
              </template>
            </FormRow>

            <FormRow style="margin-top: 100px">
              <b-form-group label-cols-lg="3" v-has="'popUp::Create'">
                <b-button type="submit" variant="primary" class="custom-width"
                  >Save Pop-up-ad</b-button
                >
              </b-form-group>
            </FormRow>
          </b-form>
        </b-col>

        <b-col class="popImage">
          <img
            :src="require('@/assets/popup-flavor.png')"
            class="vo-object-fit"
            style="
              height: 488px;
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
            "
          />
        </b-col>
      </b-row>
    </b-card>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel"
      @hide="hide"
    >
      Saved successfully
    </Dialog>
    <!-- 错误提示框 -->
    <Dialog
      v-model="errorAlear"
      :imageSrc="require('@/assets/confirm.svg')"
      noBtn="OK"
      @cancel="cancel1"
      @hide="hide1"
    >
      {{ errorContent }}
    </Dialog>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import ReferenceBy from "@/components/FindByLabel.vue";
import Dialog from "@/components/Dialog";
import FormRow from "@/components/FormRow.vue";
import CountText from "@/components/CountText.vue";
import { dropzoneOptions, upload_img } from "@/api/upload";
import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";

/**
 * FAQ component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    timeZoneConversion,
    ReferenceBy,
    Dialog,
    FormRow,
    CountText,
    dropzoneOptions,
    upload_img,
    VSelect,
  },
  data() {
    return {
      page: {
        title: "Pop-up Mgmt",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "APP Settings",
            // href: "/"
          },
          {
            text: "Pop-up Mgmt",
            active: true,
          },
        ],
      },
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "id",
          label: "Ad ID",
          class: "text-center",
        },
        {
          key: "advertiseName",
          label: "Pop-up ad Name",
          class: "text-center",
        },
        {
          key: "startTime",
          label: "Effective Time",
          class: "text-center",
        },
        {
          key: "url",
          label: "Learn More URL",
          class: "text-center",
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          formatter: (value) => {
            return value == 1 ? "Enable" : "Disable";
          },
        },
        {
          key: "createTime",
          label: "Create Time",
          class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.getLocalTime(value);
          },
        },
        {
          key: "createName",
          label: "Createor",
          class: "text-center",
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      form: {
        id: "",
        advertiseName: "",
        startTime: "",
        endTime: "",
        url: "",
        status: 1,
        coverImage: "",
      },
      selectTime: [],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
      },
      types: {
        statusList: [
          { text: "Enable", value: 1, disabled: false },
          { text: "Disable", value: 0, disabled: false },
        ],
      },
      isBusy: false,
      has_data: false,
      successAlear: false,
      errorAlear: false,
      errorContent: "",
    };
  },
  watch: {
    selectTime: function (val) {
      this.form.startTime = timeZoneConversion.formatTime(val[0], 1);
      this.form.endTime = timeZoneConversion.formatTime(val[1], 1);
    },
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    onRemoveLogo() {
      this.form.coverImage = "";
    },
    init() {},
    onSubmit(evt) {
      evt.preventDefault();
      this.$api.brandAdvertise.saveBrandAdvertise(this.form).then((res) => {
        if (res.success) {
          this.successAlear = true;
          this.$refs.table.refresh();
        } else {
          if (res.message) {
            this.errorAlear = true;
            this.errorContent = res.message;
          }
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    provider(ctx) {
      this.isBusy = true;
      return this.$api.brandAdvertise
        .listPage(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    modifyPop(item) {
      this.form = {
        id: item.id,
        advertiseName: item.advertiseName,
        startTime: item.startTime,
        endTime: item.endTime,
        url: item.url,
        status: item.status,
        coverImage: item.coverImage,
      };
      this.selectTime = [new Date(item.startTime), new Date(item.endTime)];
      this.$nextTick();
    },
    cancel() {
      this.successAlear = false;
    },
    hide() {
      this.successAlear = false;
    },
    cancel1() {
      this.errorAlear = false;
    },
    hide1() {
      this.errorAlear = false;
    },
    upload(e) {
      let file = e.target.files[0];
      this.photoName = file.name;
      console.log(file);
      if (/image/.test(file.type)) {
        upload_img(file).then((res) => {
          if (res.success) {
            this.form.coverImage = res.data;
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    uploadFile() {
      const filestyle = document.getElementById("filestyle-1");
      filestyle.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.close-img {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  padding: 4px;
  background: #02a7f0;
  border-radius: 50%;
  object-fit: contain;
}

.upload-img-wrap {
  margin-bottom: 1em;
  width: 200px;
  height: 200px;
  position: relative;
  border: 2px dashed #02a7f0;
}

.popImage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 488px;
}

.custom-placeholder ::placeholder {
  font-size: 0.875rem;
  color: #495057;
}
</style>
