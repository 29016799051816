/**
 * account 接口模块
 */

import axios from "../config/http.js";
import axios1 from "axios";
import qs from "qs";

import baseUrl from "../config/env.js";

const account = {
  // 登录 post
  login(params) {
    return axios1
      .post(baseUrl + "/supplier/doLogin", qs.stringify(params), {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((x) => x.data);
  },
  // 退出 post
  logout() {
    return axios.fetchPost(baseUrl + '/supplier/logout');
  },
  // 修改密码 post
  resetPassword(params) {
    return axios.fetchPost(baseUrl + '/supplier/changePassword', params);
  },
  // 菜单
  findUserMenu(params = {}) {
    return axios.fetchGet(baseUrl+"/supplier/findUserMenu",params)
  },
  // 按钮
  selectAllSupplierButton(params = {}) {
    return axios.fetchGet(baseUrl+"/supplier/selectAllSupplierButton",params)
  },

  submitCurrentTimeZone(params = {}) {
    return axios.fetchPost(baseUrl + "/supplier/submitCurrentTimeZone", params);
  },
  // 完成新手指引
  supplierGuide(params = {}) {
    return axios.fetchPost(baseUrl + "/supplier/supplierGuide", params);
  },
  //发送验证码方法
  sendCode(params = {}){
    return axios.fetchPost(baseUrl + "/supplier/sendEmail", params);
  },
   //找回密码
   retrievePassword(params = {}){
    return axios.fetchPost(baseUrl + "/supplier/retrievePassword", params);
  }
};

export default account;
