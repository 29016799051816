var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { lg: "10" } },
                    [
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.invitationCode
                                        ? _vm.form.invitationCode.length > 4
                                          ? 4
                                          : _vm.form.invitationCode.length
                                        : 0,
                                      max: "4"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Invitation Code:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "invitation-code",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "invitation-code",
                                  placeholder:
                                    "Please enter a 4 digits or letters",
                                  required: "",
                                  type: "number",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');if(value.length >4)value = value.slice(0, 4)"
                                },
                                model: {
                                  value: _vm.form.invitationCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "invitationCode", $$v)
                                  },
                                  expression: "form.invitationCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Code type:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "code-type",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-radio-group", {
                                staticStyle: { "padding-top": "7px" },
                                attrs: {
                                  id: "code-type-1",
                                  options: _vm.codeType,
                                  name: "code-type"
                                },
                                model: {
                                  value: _vm.form.codeType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "codeType", $$v)
                                  },
                                  expression: "form.codeType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.form.codeType == 1
                        ? _c(
                            "FormRow",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Role:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "roleId",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "roleId",
                                      options: _vm.roleList,
                                      required: "",
                                      oninvalid:
                                        "setCustomValidity('Please enter');"
                                    },
                                    model: {
                                      value: _vm.form.roleId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "roleId", $$v)
                                      },
                                      expression: "form.roleId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.codeType == 2
                        ? _c(
                            "FormRow",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Brand:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "brandId",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "brandId",
                                      options: _vm.brandList,
                                      required: "",
                                      oninvalid:
                                        "setCustomValidity('Please enter');"
                                    },
                                    model: {
                                      value: _vm.form.brandId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "brandId", $$v)
                                      },
                                      expression: "form.brandId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "FormRow",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Useful Time:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "use-type",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-radio-group", {
                                staticStyle: { "padding-top": "7px" },
                                attrs: {
                                  id: "use-type-1",
                                  options: _vm.useType,
                                  name: "use-type"
                                },
                                model: {
                                  value: _vm.form.useType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "useType", $$v)
                                  },
                                  expression: "form.useType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.remarks
                                        ? _vm.form.remarks.length
                                        : 0,
                                      max: "50"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Code Notes:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "invitation-code",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "invitation-code",
                                  placeholder: "Please enter a code notes",
                                  required: "",
                                  maxlength: "50",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.form.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "remarks", $$v)
                                  },
                                  expression: "form.remarks"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { "label-cols-lg": "3" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "custom-width",
                  attrs: { type: "submit", variant: "primary" }
                },
                [_vm._v("Confirm")]
              ),
              _c(
                "b-button",
                { staticClass: "ml-4 custom-width", attrs: { type: "reset" } },
                [_vm._v("Cancel ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/success.png"), noBtn: "OK" },
          on: { cancel: _vm.cancel, hide: _vm.hide },
          model: {
            value: _vm.successAlear,
            callback: function($$v) {
              _vm.successAlear = $$v
            },
            expression: "successAlear"
          }
        },
        [_vm._v(" Saved successfully ")]
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/confirm.svg"), noBtn: "OK" },
          on: { cancel: _vm.cancel1, hide: _vm.hide1 },
          model: {
            value: _vm.errorAlear,
            callback: function($$v) {
              _vm.errorAlear = $$v
            },
            expression: "errorAlear"
          }
        },
        [_vm._v(" " + _vm._s(_vm.errorContent) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }