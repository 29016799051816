var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.groupName
                                        ? _vm.form.groupName.length
                                        : 0,
                                      max: "50"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Voltage Curves Name:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "curves-name",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "curves-name",
                                  placeholder: "Please enter",
                                  required: "",
                                  maxlength: "50",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.form.groupName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "groupName", $$v)
                                  },
                                  expression: "form.groupName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.description
                                        ? _vm.form.description.length
                                        : 0,
                                      max: "50"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Voltage Curves Description:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "curves-description",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "curves-description",
                                  placeholder: "Please enter",
                                  required: "",
                                  maxlength: "50",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.form.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "description", $$v)
                                  },
                                  expression: "form.description"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.deviceId == 0
                        ? _c(
                            "FormRow",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Be Available For:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "productId",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      required: "",
                                      options: _vm.types.skuList,
                                      oninvalid:
                                        "setCustomValidity('Please enter');",
                                      oninput: "setCustomValidity('');"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.changeDevice()
                                      }
                                    },
                                    model: {
                                      value: _vm.form.deviceId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "deviceId", $$v)
                                      },
                                      expression: "form.deviceId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.remarks
                                        ? _vm.form.remarks.length
                                        : 0,
                                      max: "100"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Notes:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "notes",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "notes",
                                  placeholder: "Please enter",
                                  required: "",
                                  rows: "10",
                                  "max-rows": "10",
                                  maxlength: "100"
                                },
                                model: {
                                  value: _vm.form.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "remarks", $$v)
                                  },
                                  expression: "form.remarks"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Status:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "status",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-radio-group", {
                                staticStyle: { "padding-top": "7px" },
                                attrs: {
                                  id: "radio-group-1",
                                  options: _vm.types.status,
                                  name: "status"
                                },
                                model: {
                                  value: _vm.form.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "status", $$v)
                                  },
                                  expression: "form.status"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.form.deviceId
            ? _c(
                "b-card",
                [
                  _c(
                    "b-row",
                    { staticClass: "align-items-center" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("div", { staticClass: "blockquote" }, [
                          _c("h4", [_vm._v("Set Voltage Curves:")])
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "7" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "curveOne"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "padding-bottom": "40px" } },
                                _vm._l(_vm.btnList, function(item, index) {
                                  return _c(
                                    "button",
                                    {
                                      key: index,
                                      staticClass: "buttonStyle",
                                      style: {
                                        backgroundColor:
                                          _vm.activeIndex === index
                                            ? "rgba(255, 255, 255, 1)"
                                            : "",
                                        color:
                                          _vm.activeIndex === index
                                            ? "#000000"
                                            : ""
                                      },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.clickBtn(index)
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(item) + " ")]
                                  )
                                }),
                                0
                              ),
                              _c("HistoryCurve", {
                                attrs: {
                                  width: "680px",
                                  power: _vm.types.power
                                }
                              }),
                              _vm.activeIndex == 0
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "voltage-one" },
                                      _vm._l(_vm.temperatureList, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "voltage-number"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  parseFloat(
                                                    (
                                                      Math.pow(item.value, 2) /
                                                      parseFloat(_vm.resistance)
                                                    ).toFixed(1)
                                                  )
                                                ) +
                                                " w "
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "voltage-two",
                                        staticStyle: { width: "100%" }
                                      },
                                      _vm._l(_vm.temperatureList, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "voltage-list"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-plus voltage-plus",
                                              on: {
                                                click: function($event) {
                                                  return _vm.onVoltagePlus(
                                                    index
                                                  )
                                                }
                                              }
                                            }),
                                            _c("el-slider", {
                                              attrs: {
                                                vertical: "",
                                                step: _vm.step,
                                                min: _vm.min,
                                                max: _vm.max,
                                                height: "150px"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.power()
                                                }
                                              },
                                              model: {
                                                value: item.value,
                                                callback: function($$v) {
                                                  _vm.$set(item, "value", $$v)
                                                },
                                                expression: "item.value"
                                              }
                                            }),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-minus voltage-reduce",
                                              on: {
                                                click: function($event) {
                                                  return _vm.onVoltageReduce(
                                                    index
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "voltage-three" },
                                      _vm._l(8, function(index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "voltage-number"
                                          },
                                          [_vm._v(" " + _vm._s(index) + "s ")]
                                        )
                                      }),
                                      0
                                    )
                                  ])
                                : _vm._e(),
                              _vm.activeIndex == 1
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "voltage-one" },
                                      _vm._l(_vm.temperatureList1, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "voltage-number"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  parseFloat(
                                                    (
                                                      Math.pow(item.value, 2) /
                                                      parseFloat(_vm.resistance)
                                                    ).toFixed(1)
                                                  )
                                                ) +
                                                " w "
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "voltage-two",
                                        staticStyle: { width: "100%" }
                                      },
                                      _vm._l(_vm.temperatureList1, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "voltage-list"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-plus voltage-plus",
                                              on: {
                                                click: function($event) {
                                                  return _vm.onVoltagePlus1(
                                                    index
                                                  )
                                                }
                                              }
                                            }),
                                            _c("el-slider", {
                                              attrs: {
                                                vertical: "",
                                                step: _vm.step,
                                                min: _vm.min,
                                                max: _vm.max,
                                                height: "150px"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.power()
                                                }
                                              },
                                              model: {
                                                value: item.value,
                                                callback: function($$v) {
                                                  _vm.$set(item, "value", $$v)
                                                },
                                                expression: "item.value"
                                              }
                                            }),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-minus voltage-reduce",
                                              on: {
                                                click: function($event) {
                                                  return _vm.onVoltageReduce1(
                                                    index
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "voltage-three" },
                                      _vm._l(8, function(index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "voltage-number"
                                          },
                                          [_vm._v(" " + _vm._s(index) + "s ")]
                                        )
                                      }),
                                      0
                                    )
                                  ])
                                : _vm._e(),
                              _vm.activeIndex == 2
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "voltage-one" },
                                      _vm._l(_vm.temperatureList2, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "voltage-number"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  parseFloat(
                                                    (
                                                      Math.pow(item.value, 2) /
                                                      parseFloat(_vm.resistance)
                                                    ).toFixed(1)
                                                  )
                                                ) +
                                                " w "
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "voltage-two",
                                        staticStyle: { width: "100%" }
                                      },
                                      _vm._l(_vm.temperatureList2, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "voltage-list"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-plus voltage-plus",
                                              on: {
                                                click: function($event) {
                                                  return _vm.onVoltagePlus2(
                                                    index
                                                  )
                                                }
                                              }
                                            }),
                                            _c("el-slider", {
                                              attrs: {
                                                vertical: "",
                                                step: _vm.step,
                                                min: _vm.min,
                                                max: _vm.max,
                                                height: "150px"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.power()
                                                }
                                              },
                                              model: {
                                                value: item.value,
                                                callback: function($$v) {
                                                  _vm.$set(item, "value", $$v)
                                                },
                                                expression: "item.value"
                                              }
                                            }),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-minus voltage-reduce",
                                              on: {
                                                click: function($event) {
                                                  return _vm.onVoltageReduce2(
                                                    index
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "voltage-three" },
                                      _vm._l(8, function(index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "voltage-number"
                                          },
                                          [_vm._v(" " + _vm._s(index) + "s ")]
                                        )
                                      }),
                                      0
                                    )
                                  ])
                                : _vm._e(),
                              _c("p", [
                                _vm._v(
                                  " The Voltage curve is between 0.4v-3.7v, after the 8th second, the same as the 8th second "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-form-group",
            { attrs: { "label-cols-lg": "3" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "custom-width",
                  attrs: { type: "submit", variant: "primary" }
                },
                [_vm._v("Save")]
              ),
              _c(
                "b-button",
                { staticClass: "ml-4 custom-width", attrs: { type: "reset" } },
                [_vm._v("Cancel ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/success.png"), noBtn: "OK" },
          on: { cancel: _vm.cancel, hide: _vm.hide },
          model: {
            value: _vm.successAlear,
            callback: function($$v) {
              _vm.successAlear = $$v
            },
            expression: "successAlear"
          }
        },
        [_vm._v(" Saved successfully ")]
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/confirm.svg"), noBtn: "OK" },
          on: { cancel: _vm.cancel1, hide: _vm.hide1 },
          model: {
            value: _vm.errorAlear,
            callback: function($$v) {
              _vm.errorAlear = $$v
            },
            expression: "errorAlear"
          }
        },
        [_vm._v(" " + _vm._s(_vm.errorContent) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }