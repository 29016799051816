<template>
  <MaskView
    borderStyle="border: 1px solid #d7d7d7"
    outerBorderStyle="1px dashed #02a7f0"
    mask-background-color="transparent"
    @click="click"
  >
    <div class="position-relative w-100 h-100">
      <div class="position-absolute img-wrap">
        <img
          style="width: 90%; height: 90%; object-fit: contain"
          :src="image"
        />
      </div>
    </div>
  </MaskView>
</template>

<script>
import MaskView from "@/components/MaskView.vue";

export default {
  name: "CreateProduct",
  components: { MaskView },
  props: {
    image: {
      type: String,
      default: "",
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
<style scoped>
.img-wrap {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}
</style>
