/*
 * @Author: Code-HHX
 * @Date: 2021-04-08 11:43:47
 * @LastEditors: Code-HHX
 * @LastEditTime: 2021-12-17 18:04:23
 * @Description:
 */
//根据不同的环境更改不同的baseUrl

let baseUrl = ''
baseUrl = process.env.VUE_APP_BASE_API
export default baseUrl
