import {format} from "./time"
import axios from "../config/http.js"
import axios1 from "../config/http-json.js"
import baseUrl from "../config/env.js"

// 膏类型
async function pasteTypeList(params,ctx){
    return axios.fetchGet(baseUrl + '/pasteType/listPage',params)
}
function associatedWarmUp(params){
    return axios1.fetchPost(baseUrl + '/pasteType/associatedWarmUp', params);
}
function associatedHeatingMode(params){
    return axios1.fetchPost(baseUrl + '/pasteType/associatedHeatingMode', params);
}
function pasteTypeSelectByWarmUpModeId(params){
    return axios.fetchGet(baseUrl + '/pasteType/selectByWarmUpModeId',params)
 }
function pasteTypeSelectByHeatingModeId(params){
    return axios.fetchGet(baseUrl + '/pasteType/selectByHeatingModeId',params)
 }
function savePasteType(params){
    return axios.fetchPost(baseUrl + '/pasteType/savePasteType',params)
 }
function updatePasteTypeById(params){
    return axios.fetchPost(baseUrl + '/pasteType/updatePasteTypeById',params)
 }

// 预热模式
async function list(params,ctx){
    return axios.fetchGet(baseUrl + '/warmUpMode/list',params)
}

function addWarmUpMode(params){
    return axios.fetchPost(baseUrl + '/warmUpMode/addWarmUpMode', params);
}

function selectByWarmUpModeId(params){
   return axios.fetchGet(baseUrl + '/warmUpMode/selectByWarmUpModeId',params)
}

function updateByWarmUpModeId(params){
    return axios.fetchPost(baseUrl + '/warmUpMode/updateByWarmUpModeId', params);
}
// 加热模式
async function heatingList(params,ctx){
    return axios.fetchGet(baseUrl + '/heatingMode/list',params)
}

function addHeatingMode(params){
    return axios.fetchPost(baseUrl + '/heatingMode/addHeatingMode', params);
}

function selectByHeatingModeId(params){
   return axios.fetchGet(baseUrl + '/heatingMode/selectByHeatingModeId',params)
}

function updateByHeatingModeId(params){
    return axios.fetchPost(baseUrl + '/heatingMode/updateByHeatingModeId', params);
}

// 加热模式
async function selectRecommendList(params,ctx){
    return axios.fetchGet(baseUrl + '/heatingMode/selectRecommendList',params)
}

export default {
    list,
    addWarmUpMode,
    selectByWarmUpModeId,
    updateByWarmUpModeId,
    heatingList,
    addHeatingMode,
    selectByHeatingModeId,
    updateByHeatingModeId,
    pasteTypeList,
    associatedWarmUp,
    associatedHeatingMode,
    pasteTypeSelectByWarmUpModeId,
    pasteTypeSelectByHeatingModeId,
    savePasteType,
    updatePasteTypeById,
    selectRecommendList
}
