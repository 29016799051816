var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticStyle: { "margin-left": "20px" } },
                [
                  _c("b-col", { attrs: { lg: "8" } }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Apply For:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.applicationInstructions))
                    ])
                  ]),
                  _c("b-col", { attrs: { lg: "8" } }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Model:")
                    ]),
                    _c(
                      "span",
                      { staticClass: "p-3" },
                      [
                        _c("b-table", {
                          staticStyle: {
                            display: "inline",
                            "vertical-align": "text-top"
                          },
                          attrs: {
                            striped: "",
                            hover: "",
                            items: _vm.info.modelNumber,
                            fields: _vm.fields
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("b-col", { attrs: { lg: "8" } }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Notes:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.remarks))
                    ])
                  ]),
                  _c("b-col", { attrs: { lg: "8" } }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Status:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(
                        _vm._s(
                          _vm.info.status == 0
                            ? "To be verified"
                            : _vm.info.status == 1
                            ? "Passed"
                            : "Rejected"
                        )
                      )
                    ])
                  ]),
                  _c(
                    "b-col",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { lg: "8" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            type: "reset",
                            variant: "outline-secondary"
                          },
                          on: { click: _vm.returnPage }
                        },
                        [_vm._v("Go Back")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }