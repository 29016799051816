var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _vm.show
            ? _c(
                "b-form",
                { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Coupon Name:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "coupon-name",
                        "label-class": "requiredRow",
                        description: "Coupon name, displayed on the coupon name"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "coupon-name",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                          placeholder: "Please enter"
                        },
                        model: {
                          value: _vm.form.couponName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "couponName", $$v)
                          },
                          expression: "form.couponName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Coupon Type:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "coupon-type",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "coupon-type",
                          options: _vm.types.couponType,
                          "value-field": "value",
                          "text-field": "text",
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('')"
                        },
                        model: {
                          value: _vm.form.couponType,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "couponType", $$v)
                          },
                          expression: "form.couponType"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.form.couponType == 0
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Available Amount:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "available-amount",
                            "label-class": "requiredRow",
                            description: "Threshold amount"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "available-amount",
                              required: "",
                              type: "number",
                              min: "0",
                              step: "0.01",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput:
                                "setCustomValidity('');if(parseFloat(value) > 100000)value = 100000;value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, '$1$2.$3')",
                              placeholder: "Please enter",
                              state: _vm.thresholdPriceState,
                              "aria-describedby": "input-threshold-feedback"
                            },
                            model: {
                              value: _vm.form.thresholdPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "thresholdPrice", $$v)
                              },
                              expression: "form.thresholdPrice"
                            }
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-threshold-feedback" } },
                            [
                              _vm._v(
                                " The available amount cannot be lower than the discounted price. "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.couponType == 0 || _vm.form.couponType == 2
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Discounted Price:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "discounted-Price",
                            "label-class": "requiredRow",
                            description: "Coupon discount amount"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "available-amount",
                              required: "",
                              type: "number",
                              min: "0",
                              step: "0.01",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput:
                                "setCustomValidity('');if(parseFloat(value) > 100000)value = 100000;value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, '$1$2.$3')",
                              placeholder: "Please enter"
                            },
                            model: {
                              value: _vm.form.discountedPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "discountedPrice", $$v)
                              },
                              expression: "form.discountedPrice"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Scope Of Use:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "scope-of-use",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "scope-of-use",
                          options: _vm.types.useRange,
                          "value-field": "value",
                          "text-field": "text",
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('')"
                        },
                        model: {
                          value: _vm.form.scopeOfUse,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "scopeOfUse", $$v)
                          },
                          expression: "form.scopeOfUse"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.form.scopeOfUse === 2
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Use Goods:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "use-goods",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "model-choose" },
                            [
                              _c("b-form-select", {
                                staticStyle: { width: "30%" },
                                attrs: {
                                  id: "scope-goods",
                                  options: _vm.types.modelList,
                                  "value-field": "id",
                                  "text-field": "modelName",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please select');",
                                  oninput: "setCustomValidity('')"
                                },
                                model: {
                                  value: _vm.selectModel,
                                  callback: function($$v) {
                                    _vm.selectModel = $$v
                                  },
                                  expression: "selectModel"
                                }
                              }),
                              _c(
                                "b-button",
                                {
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: {
                                    variant: "primary",
                                    disabled: _vm.selectModel == ""
                                  },
                                  on: { click: _vm.onClickAddModel }
                                },
                                [_vm._v(" Add ")]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "20px" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "margin-right": "4px"
                                      }
                                    },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "Once the coupon is issued, it cannot be modified "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("b-table", {
                            ref: "table",
                            staticStyle: { width: "61%" },
                            attrs: {
                              "show-empty": "",
                              "empty-text": "No records",
                              id: "table",
                              "head-variant": "light",
                              striped: true,
                              items: _vm.selectModelList,
                              fields: _vm.fields
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(actions)",
                                  fn: function(row) {
                                    return [
                                      _c(
                                        "b-button-group",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                size: "sm",
                                                variant: "danger"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onClickDelete(
                                                    row.item,
                                                    row.index,
                                                    $event.target
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Delete")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              163615721
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Acquisition Method:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "get-mode",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "get-mode",
                          options: _vm.types.getModeList,
                          "value-field": "value",
                          "text-field": "text",
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('')"
                        },
                        model: {
                          value: _vm.form.methodOfObtaining,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "methodOfObtaining", $$v)
                          },
                          expression: "form.methodOfObtaining"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Issuance Quantity:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "issuance-quantity",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "issuance-quantity",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50);value=value.replace(/[^\\d]/g,'')",
                          placeholder: "Please enter",
                          disabled: _vm.form.issuanceType == 1
                        },
                        model: {
                          value: _vm.form.issuanceQuantity,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "issuanceQuantity", $$v)
                          },
                          expression: "form.issuanceQuantity"
                        }
                      }),
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "checkbox-1",
                            name: "checkbox-1",
                            "unchecked-value": "0",
                            value: "1"
                          },
                          on: { change: _vm.onChangeIssuanceQuantity },
                          model: {
                            value: _vm.form.issuanceType,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "issuanceType", $$v)
                            },
                            expression: "form.issuanceType"
                          }
                        },
                        [_vm._v(" Unlimited ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Available Quantity:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "available-quantity",
                        "label-class": "requiredRow",
                        description: "Maximum number of coupons per user"
                      }
                    },
                    [
                      _vm.form.availableType == 0
                        ? _c("b-form-select", {
                            attrs: {
                              id: "limited-availableType",
                              options: _vm.types.limitedAvailableTypeList,
                              "value-field": "value",
                              "text-field": "text",
                              required: "",
                              oninvalid: "setCustomValidity('Please select');",
                              oninput: "setCustomValidity('')"
                            },
                            model: {
                              value: _vm.form.limitedAvailableType,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "limitedAvailableType", $$v)
                              },
                              expression: "form.limitedAvailableType"
                            }
                          })
                        : _vm._e(),
                      _c("b-form-input", {
                        attrs: {
                          id: "available-quantity",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50);value=value.replace(/[^\\d]/g,'')",
                          placeholder: "Please enter",
                          disabled: _vm.form.availableType == 1,
                          state: _vm.availableQuantityState,
                          "aria-describedby": "input-available-feedback"
                        },
                        model: {
                          value: _vm.form.availableQuantity,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "availableQuantity", $$v)
                          },
                          expression: "form.availableQuantity"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-available-feedback" } },
                        [
                          _vm._v(
                            " The available quantity cannot be greater than the issued quantity. "
                          )
                        ]
                      ),
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "checkbox-2",
                            name: "checkbox-2",
                            "unchecked-value": "0",
                            value: "1"
                          },
                          on: { change: _vm.onChangeAvailableQuantity },
                          model: {
                            value: _vm.form.availableType,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "availableType", $$v)
                            },
                            expression: "form.availableType"
                          }
                        },
                        [_vm._v(" Unlimited ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "All limit Per day:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "day-issuance-quantity",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "day-issuance-quantity",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50);value=value.replace(/[^\\d]/g,'')",
                          placeholder: "Please enter",
                          disabled: _vm.form.dayIssuanceType == 1
                        },
                        model: {
                          value: _vm.form.dayIssuanceQuantity,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "dayIssuanceQuantity", $$v)
                          },
                          expression: "form.dayIssuanceQuantity"
                        }
                      }),
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "checkbox-3",
                            name: "checkbox-3",
                            "unchecked-value": "0",
                            value: "1"
                          },
                          on: { change: _vm.onChangeDayIssuanceQuantity },
                          model: {
                            value: _vm.form.dayIssuanceType,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "dayIssuanceType", $$v)
                            },
                            expression: "form.dayIssuanceType"
                          }
                        },
                        [_vm._v(" Unlimited ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Release Time:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "release-time",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("date-picker", {
                        attrs: {
                          format: "MM/DD/YYYY HH:mm:ss",
                          range: "",
                          "append-to-body": "",
                          lang: "en",
                          placeholder: "Select date",
                          type: "datetime",
                          "disabled-date": _vm.notBeforeToday
                        },
                        on: { change: _vm.onChangeReleaseTime },
                        model: {
                          value: _vm.form.releaseTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "releaseTime", $$v)
                          },
                          expression: "form.releaseTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Time Type:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "time-type",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c(
                        "b-form-radio",
                        {
                          staticClass: "custom-control-inline pt-2",
                          attrs: { name: "absoluteTime", value: "0" },
                          model: {
                            value: _vm.form.typeOfUse,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "typeOfUse", $$v)
                            },
                            expression: "form.typeOfUse"
                          }
                        },
                        [_vm._v("Absolute Time")]
                      ),
                      _c(
                        "b-form-radio",
                        {
                          staticClass: "custom-control-inline pt-2",
                          attrs: { name: "relativeTime", value: "1" },
                          model: {
                            value: _vm.form.typeOfUse,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "typeOfUse", $$v)
                            },
                            expression: "form.typeOfUse"
                          }
                        },
                        [_vm._v("Relative Time")]
                      )
                    ],
                    1
                  ),
                  _vm.form.typeOfUse == 0
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Usage Start Time:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "usage-time",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("date-picker", {
                            attrs: {
                              format: "MM/DD/YYYY HH:mm:ss",
                              "append-to-body": "",
                              lang: "en",
                              placeholder: "Select date",
                              type: "datetime",
                              "disabled-date": _vm.notBeforeUsageStartToday,
                              "disabled-time": _vm.notBeforeUsageStartTime
                            },
                            model: {
                              value: _vm.form.startTime,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "startTime", $$v)
                              },
                              expression: "form.startTime"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.typeOfUse == 0
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Usage End Time:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "usage-time",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("date-picker", {
                            attrs: {
                              format: "MM/DD/YYYY HH:mm:ss",
                              "append-to-body": "",
                              lang: "en",
                              placeholder: "Select date",
                              type: "datetime",
                              "disabled-date": _vm.notBeforeUsageEndToday,
                              "disabled-time": _vm.notBeforeUsageEndTime
                            },
                            model: {
                              value: _vm.form.endTime,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "endTime", $$v)
                              },
                              expression: "form.endTime"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.typeOfUse == 1
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Usage Time:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "usage-time",
                            "label-class": "requiredRow",
                            description:
                              "Effective days after receipt, not more than 90 days"
                          }
                        },
                        [
                          _vm.form.typeOfUse == 1
                            ? _c("b-form-input", {
                                attrs: {
                                  id: "days",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');if(value > 90)value = 90;value=value.replace(/[^\\d]/g,'')",
                                  placeholder: "Please enter"
                                },
                                model: {
                                  value: _vm.form.days,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "days", $$v)
                                  },
                                  expression: "form.days"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Illustrate:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "illustrate"
                      }
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "illustrate",
                          placeholder: "Enter illustrate",
                          rows: "3",
                          "max-rows": "6"
                        },
                        model: {
                          value: _vm.form.illustrate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "illustrate", $$v)
                          },
                          expression: "form.illustrate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { "label-cols-lg": "2" } },
                    [
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "primary" } },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "b-button",
                        { staticClass: "m-l-5 ml-1", attrs: { type: "reset" } },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }