var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "vo-items-center" },
            [
              _c("b-col", { attrs: { lg: "4" } }, [
                _c(
                  "div",
                  { staticClass: "vo-row vo-items-center" },
                  [
                    _c("ReferenceBy", { attrs: { label: "REFINED BY" } }),
                    _c("b-input", {
                      staticStyle: { flex: "1", "margin-left": "10px" },
                      attrs: { placeholder: "Omni ID" },
                      model: {
                        value: _vm.search_condition.smokeRecordNumber,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.search_condition,
                            "smokeRecordNumber",
                            $$v
                          )
                        },
                        expression: "search_condition.smokeRecordNumber"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "b-col",
                { staticClass: "customCol", attrs: { lg: "3" } },
                [
                  _c("b-form-select", {
                    attrs: { id: "oil-type", options: _vm.types.status },
                    model: {
                      value: _vm.search_condition.status,
                      callback: function($$v) {
                        _vm.$set(_vm.search_condition, "status", $$v)
                      },
                      expression: "search_condition.status"
                    }
                  })
                ],
                1
              ),
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "vo-row vo-items-center vo-justify-between" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "custom-color cursor",
                        on: { click: _vm.clearAll }
                      },
                      [_vm._v("Clear All")]
                    ),
                    _c(
                      "b-button",
                      { attrs: { variant: "info" }, on: { click: _vm.search } },
                      [_vm._v("Search")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-responsive mb-0" },
            [
              _c("b-table", {
                ref: "table",
                attrs: {
                  id: "table",
                  "show-empty": "",
                  "empty-text": "No records",
                  "head-variant": "light",
                  busy: _vm.isBusy,
                  striped: true,
                  items: _vm.provider,
                  fields: this.$store.getters.isUnicoreus
                    ? _vm.fields
                    : _vm.fieldsIkrusher,
                  "per-page": _vm.search_condition.pageSize,
                  "current-page": _vm.search_condition.pageNum,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  filter: _vm.filter,
                  "filter-includedfields": _vm.filterOn
                },
                on: {
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.sortDesc = $event
                  },
                  filtered: _vm.onFiltered
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-busy",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center text-danger my-2" },
                          [
                            _c("b-spinner", { staticClass: "align-middle" }),
                            _c("strong", [_vm._v("Loading...")])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "cell(index)",
                    fn: function(data) {
                      return [
                        _vm._v(
                          _vm._s(
                            data.index +
                              (_vm.search_condition.pageNum - 1) *
                                _vm.search_condition.pageSize +
                              1
                          )
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(actions)",
                    fn: function(row) {
                      return [
                        _c(
                          "b-button-group",
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-1",
                                attrs: { size: "sm", variant: "info" },
                                on: {
                                  click: function($event) {
                                    return _vm.show(
                                      row.item,
                                      row.index,
                                      $event.target
                                    )
                                  }
                                }
                              },
                              [_vm._v("Details")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(status)",
                    fn: function(row) {
                      return [
                        row.item.status == 2
                          ? _c("p", { staticClass: "clientStyle" }, [
                              _vm._v("Unactivated")
                            ])
                          : _vm._e(),
                        row.item.status == 1
                          ? _c("p", [_vm._v("Activated")])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("br"),
          _vm.has_data
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "dataTables_paginate paging_simple_numbers float-right"
                      },
                      [
                        _vm.rows != 0
                          ? _c(
                              "ul",
                              { staticClass: "pagination mb-0" },
                              [
                                _c("b-pagination", {
                                  attrs: {
                                    "per-page": 10,
                                    "total-rows": _vm.rows,
                                    "aria-controls": "table"
                                  },
                                  model: {
                                    value: _vm.search_condition.pageNum,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.search_condition,
                                        "pageNum",
                                        $$v
                                      )
                                    },
                                    expression: "search_condition.pageNum"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }