<!--
 * @Author: Code-HHX
 * @Date: 2022-03-29 16:08:39
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-03-31 15:27:04
 * @Description: 积分商品详情
-->
<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<!-- 积分活动详情 -->
		<b-card>
			<b-row class="align-items-center">
				<b-col lg="12">
					<div class="blockquote">
						<h4>Integral Commodity Details</h4>
					</div>
				</b-col>
			</b-row>
			<b-container>
				<b-row cols="3">
					<!-- 商品名称 -->
					<b-col>
						<label class="col-form-label">Commodity Name:</label>
						<span class="p-3">{{ info.productName }}</span>
					</b-col>
					<!-- 商品编号 -->
					<b-col>
						<label class="col-form-label">Commodity No:</label>
						<span class="p-3">{{ info.productNumber }}</span>
					</b-col>
					<!-- 商品图片 -->
					<b-col>
						<label class="col-form-label">Commodity Picture:</label>
						<span class="p-3"
							><img
								v-if="info.productPicture"
								:src="info.productPicture"
								alt
								class="avatar-xs rounded-circle mr-2"
						/></span>
					</b-col>
					<!-- 兑换类型 -->
					<b-col>
						<label class="col-form-label">Commodity Type:</label>
						<span class="p-3">{{
							info.exchangeType == 1
								? 'Coupon'
								: info.exchangeType == 2
								? 'Object'
								: ''
						}}</span>
					</b-col>
					<!-- 消耗宝石数 -->
					<b-col>
						<label class="col-form-label"
							>Consume Gems:</label
						>
						<span class="p-3">{{ info.integral }}</span>
					</b-col>
					<!-- 可兑换总数量 -->
					<b-col>
						<label class="col-form-label"
							>Quantity:</label
						>
						<span class="p-3">{{ info.convertibleQuantity }}</span>
					</b-col>
					<!-- 单日兑换限制 -->
					<b-col>
						<label class="col-form-label"
							>All limit Per day:</label
						>
						<span class="p-3">{{
							info.dayExchangeType == 1
								? 'Unlimited'
								: info.dayConvertibleQuantity
						}}</span>
					</b-col>
					<!-- 每用户可兑次数 -->
					<b-col>
						<label class="col-form-label"
							>Exchange Time/Per user:</label
						>
						<span class="p-3">{{ userExchange }}</span>
					</b-col>
					<!-- 上架时间 -->
					<b-col>
						<label class="col-form-label">Can be exchanged Time:</label>
						<span class="p-3">{{ launchTime }}</span>
					</b-col>
					<!-- 是否在宝石商场展示 -->
					<b-col>
						<label class="col-form-label">Display at Exchange Center:</label>
						<span class="p-3">{{
							info.status == 0 ? 'No' : 'Yes'
						}}</span>
					</b-col>
				</b-row>
			</b-container>
		</b-card>
		<!-- end card -->
		<b-card>
			<b-button
				size="lg"
				type="reset"
				variant="outline-secondary"
				@click="returnPage"
				>Go Back</b-button
			>
		</b-card>
		<!-- end card -->
	</Layout>
</template>
<script>
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'

/**
 * User Info component
 */
export default {
	components: {
		Layout,
		PageHeader,
		timeZoneConversion,
	},
	data() {
		return {
			title: 'Integral Products Details',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'Marketing Mgmt',
					// href: "/"
				},
				{
					text: 'Integral Products Details',
					active: true,
				},
			],
			info: {},
		}
	},
	created() {},
	mounted() {
		this.init()
	},
	computed: {
		userExchange() {
			//无限
			if (this.info) {
				if (this.info.availableType == '1') {
					return 'Unlimited'
				} else {
					switch (this.info.limitedAvailableType) {
						case 1:
							return 'Every day ' + this.info.availableQuantity
						case 2:
							return 'Every week ' + this.info.availableQuantity
						case 3:
							return 'Every month ' + this.info.availableQuantity
						case 4:
							return 'There is no time limit'
						default:
							return ''
					}
				}
			} else {
				return ''
			}
		},
		launchTime() {
			if (this.info) {
				let startTime = timeZoneConversion.getLocalTime(
					this.info.startTime
				)
				let endTime = timeZoneConversion.getLocalTime(this.info.endTime)
				return startTime + ' ~ ' + endTime
			} else {
				return ''
			}
		},
	},
	methods: {
		init() {
			if (this.$route.query.id) {
				this.$api.Marketing.selectBySettingsId(this.$route.query).then(
					(res) => {
						if (res.success) {
							this.info = res.data
						} else {
							if (res.message) {
								this.$toast(res)
							}
						}
					}
				)
			} else {
				this.$bvToast.toast('id?')
			}
		},
		returnPage() {
			this.$router.go(-1)
		},
	},
}
</script>
