<template>
	<Layout>
		<div class="page" id="fullScreenpage">
			<!-- <b-card class="title">
        <img src="@/assets/title.jpg" alt="" style="width:100%" />
        <p class="headline">CONSUMPTION STATISTICAL ANALYSIS</p>
      </b-card> -->
			<div class="title">
				<p class="headline">CONSUMPTION STATISTICAL ANALYSIS</p>
				<i
					v-if="!btnText"
					class="mdi mdi-fullscreen fullscreen"
					@click="requestFullScreen"
					>Full Screen</i
				>
				<i
					v-else
					class="mdi mdi-fullscreen fullscreen"
					@click="exitFullscreen"
					>Exit Full Screen</i
				>
			</div>
			<b-row style="padding: 0 1vh">
				<b-col xl="3" style="height: 100%">
					<div
						class="bodyCard"
						id="ageConsumption"
						style="
							width: 100%;
							height: calc(26vh);
							margin-bottom: 1.2vh;
						"
					></div>
					<div
						class="bodyCard"
						id="consumeOfBrand"
						style="width: 100%; height: calc(26vh)"
					></div>
				</b-col>
				<b-col xl="6">
					<div class="bodyCard" style="height: 100%">
						<div
							id="usaMap"
							style="width: 100%; height: calc(40vh)"
						></div>
						<div
							style="
								padding: calc(0.9vh);
								border-top: 1px solid rgba(44, 50, 77, 1);
							"
						>
							<div
								style="
									display: inline-block;
									text-align: center;
									border-right: 1px solid rgba(44, 50, 77, 1);
								"
								class="col-md-4"
							>
								<p
									style="
										font-size: 3.4vh;
										color: #fff;
										margin-bottom: 0;
									"
								>
									{{ consumedTotal }}
								</p>
								<p style="font-size: 1.6vh; color: #999">
									Total Consumed
								</p>
							</div>
							<div
								style="
									display: inline-block;
									text-align: center;
									border-right: 1px solid rgba(44, 50, 77, 1);
								"
								class="col-md-4"
							>
								<p
									style="
										font-size: 3.4vh;
										color: #fff;
										margin-bottom: 0;
									"
								>
									{{ consumedToday }}
								</p>
								<p style="font-size: 1.6vh; color: #999">
									Consumed Today
								</p>
							</div>
							<div
								style="
									display: inline-block;
									text-align: center;
								"
								class="col-md-4"
							>
								<p
									style="
										font-size: 3.4vh;
										color: #fff;
										margin-bottom: 0;
									"
								>
									{{ activedToday }}
								</p>
								<p style="font-size: 1.6vh; color: #999">
									Activated Today
								</p>
							</div>
						</div>
					</div>
				</b-col>
				<b-col xl="3">
					<div class="bodyCard" style="height: 100%">
						<div
							id="consumptionOne"
							style="width: 100%; height: calc(32vh)"
						></div>
						<!-- <div style="padding:0 10%;"> -->
						<span
							id="consumptionTwo"
							style="
								display: inline-block;
								width: 50%;
								height: calc(18vh);
							"
						></span>
						<span
							id="consumptionThree"
							style="
								display: inline-block;
								width: 50%;
								height: calc(18vh);
							"
						></span>
						<!-- </div> -->
					</div>
				</b-col>
			</b-row>
			<b-row style="padding: 1vh">
				<b-col xl="3">
					<div
						class="bodyCard"
						style="width: 100%; height: calc(34vh)"
					>
						<p
							style="
								color: #fff;
								margin: 10px 0 0 5px;
								font-size: 14px;
								font-family: sans-serif;
							"
						>
							Most Popular Flavor Top5
						</p>
						<div
							class="container"
							style="
								margin-top: 10px;
								display: flex;
								flex-wrap: wrap;
							"
						>
							<div
								v-for="(item, i) in flavorList"
								:key="i"
								style="
									display: inline-block;
									text-align: center;
									padding: 0.6vh;
								"
								class="col-md-4"
							>
								<p
									style="
										font-size: 12px;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
										cursor: pointer;
										color: #999;
										margin-bottom: 5px;
									"
									:title="`${item.modelName}`"
								>
									{{ item.modelName }}
								</p>
								<img
									v-if="item.flavorPicture"
									:src="`${item.flavorPicture}`"
									alt
									class="avatar-sm rounded-circle mr-2"
									:style="`border: 6px solid ${item.color};margin-right: 0 !important;`"
								/>
								<img
									v-else
									src="../../assets/face.jpg"
									alt
									class="avatar-sm rounded-circle mr-2"
									:style="`border: 6px solid ${item.color};margin-right: 0 !important;`"
								/>
								<p
									style="
										font-size: 12px;
										color: #fff;
										margin-bottom: 0;
									"
								>
									{{ item.count }}
								</p>
							</div>
						</div>
					</div>
				</b-col>
				<b-col xl="6">
					<div
						class="bodyCard"
						style="
							width: 100%;
							height: calc(34vh);
							overflow: hidden;
						"
					>
						<p
							style="
								color: #fff;
								margin: 10px 0 0 5px;
								font-size: 14px;
								font-family: sans-serif;
							"
						>
							User's Activities
						</p>
						<div
							class="text-center"
							style="height: 100%"
							v-if="spinnerShow"
						>
							<b-spinner
								variant="primary"
								label="Text Centered"
								style="margin: 10% auto"
							></b-spinner>
						</div>
						<div
							v-for="item in userList"
							:key="item.userId"
							:style="
								'margin:0.8vh;line-height: 5vh;' +
								{ marginTop: marginTop + 'vh' }
							"
						>
							<span style="color: #fff">
								<img
									v-if="item.userPicture"
									:src="`${item.userPicture}`"
									alt
									class="avatar-xs rounded-circle mr-2"
								/>
								<img
									v-else
									src="../../assets/face.jpg"
									alt
									class="avatar-xs rounded-circle mr-2"
								/>{{ item.nickName }}</span
							>
							<b-badge
								pill
								:variant="`${item.color}`"
								style="margin-left: 20px"
								>{{ item.flavorName }}</b-badge
							>
							<span
								style="
									float: right;
									line-height: 38px;
									margin-right: 20%;
									color: #999;
								"
								>{{ item.activeTime }}</span
							>
						</div>
					</div>
				</b-col>
				<b-col xl="3">
					<div
						class="bodyCard"
						id="batchConsume"
						style="width: 100%; height: calc(34vh)"
					></div>
				</b-col>
			</b-row>
		</div>
	</Layout>
</template>
<script>
import Layout from '../layouts/main'
import * as echarts from 'echarts'
import USA from './../../api/json/USA.json'

import {
	ageConsumptionData,
	consumeOfBrandData,
	consumptionOneData,
	consumptionTwoData,
	consumptionThreeData,
	batchConsumeData,
	usaMapData,
} from './data'

export default {
	components: {
		Layout,
	},
	data() {
		return {
			userList: [],
			flavorList: [],
			consumedTotal: '',
			consumedToday: '',
			activedToday: '',
			btnText: false,
			spinnerShow: false,
			marginTop: 0, // 数据的margin-top
			timer: '', // 定时器
			refresh: '',
		}
	},
	created() {},
	mounted() {
		this.getAgeConsumption()
		this.getActiveUser()
		this.getBrandConsumed()
		this.getConsumptionStatistical()
		this.getConsumeOfBrand()
		this.getBatchConsume()
		this.getUsaMap()
		this.timer = setInterval(this.showNotice, 300)
		this.refresh = setInterval(this.getActiveUser, 10000)
	},
	beforeDestroy() {
		clearInterval(this.timer)
		clearInterval(this.refresh)
	},
	methods: {
		// 数据循环滚动
		showNotice() {
			this.marginTop -= 1
			// this.getActiveUser();
			if (this.marginTop < -5) {
				// 滚上去后把上一条数据拉下来
				this.userList.push(this.userList[0])
				this.userList.shift()
				this.marginTop = 0
			}
		},
		showAgeConsumption() {
			if (document.getElementById('ageConsumption')) {
				let myChartAge = echarts.init(
					document.getElementById('ageConsumption')
				)
				myChartAge.setOption(ageConsumptionData)
				// myChartAge.resize();
				window.addEventListener('resize', () => {
					// 自动渲染echarts
					myChartAge.resize()
				})
			}
		},
		showConsumeOfBrand() {
			if (document.getElementById('consumeOfBrand')) {
				let myChartBrand = echarts.init(
					document.getElementById('consumeOfBrand')
				)
				myChartBrand.setOption(consumeOfBrandData)
				window.addEventListener('resize', () => {
					// 自动渲染echarts
					myChartBrand.resize()
				})
			}
		},
		showConsumptionStatistical() {
			if (document.getElementById('consumptionOne')) {
				let myChartConsumptionOne = echarts.init(
					document.getElementById('consumptionOne')
				)
				myChartConsumptionOne.setOption(consumptionOneData)
				window.addEventListener('resize', () => {
					// 自动渲染echarts
					myChartConsumptionOne.resize()
				})
			}

			if (document.getElementById('consumptionTwo')) {
				let myChartConsumptionTwo = echarts.init(
					document.getElementById('consumptionTwo')
				)
				myChartConsumptionTwo.setOption(consumptionTwoData)
				window.addEventListener('resize', () => {
					// 自动渲染echarts
					myChartConsumptionTwo.resize()
				})
			}

			if (document.getElementById('consumptionThree')) {
				{
					let myChartConsumptionThree = echarts.init(
						document.getElementById('consumptionThree')
					)
					myChartConsumptionThree.setOption(consumptionThreeData)
					window.addEventListener('resize', () => {
						// 自动渲染echarts
						myChartConsumptionThree.resize()
					})
				}
			}
		},
		showBatchConsume() {
			if (document.getElementById('batchConsume')) {
				let myChartBatch = echarts.init(
					document.getElementById('batchConsume')
				)
				myChartBatch.setOption(batchConsumeData)
				window.addEventListener('resize', () => {
					// 自动渲染echarts
					myChartBatch.resize()
				})
			}
		},
		showUsaMap() {
			if (document.getElementById('usaMap')) {
				let myChartMap = echarts.init(document.getElementById('usaMap'))

				echarts.registerMap('USA', USA, {
					Alaska: {
						// 把阿拉斯加移到美国主大陆左下方
						left: -131,
						top: 25,
						width: 15,
					},
					Hawaii: {
						left: -110, // 夏威夷
						top: 28,
						width: 5,
					},
					'Puerto Rico': {
						// 波多黎各
						left: -76,
						top: 26,
						width: 2,
					},
				})
				myChartMap.setOption(usaMapData)
				myChartMap.on('click', function (data) {
					console.log(data)
				})
				// window.addEventListener("resize", () => {
				//   // 自动渲染echarts
				//   myChartMap.resize();
				// });
				window.onresize = myChartMap.resize
			}
		},
		getActiveUser() {
			this.spinnerShow = true
			// 添加时区
			var nowTimeZone = 0 - new Date().getTimezoneOffset() / 60
			var time = String(nowTimeZone)
			if (nowTimeZone >= 0) {
				time = '+' + time
			}
			var splitTime = time.split('.')
			if (null != splitTime[1]) {
				if (splitTime[1] > 0) {
					time = splitTime[0] + ':' + splitTime[1] * 6
				} else {
					time = splitTime[0] + ':0' + splitTime[1] * 6
				}
			} else {
				time += ':00'
			}
			let params = {}
			if (process.env.NODE_ENV == 'prod') {
				params = { supplierId: localStorage.supplierId }
			} else {
				params = { timeZone: time }
			}
			this.$api.home.selectActiveUser(params).then((res) => {
				if (res.success) {
					let badgeList = [
						{ color: 'primary' },
						{ color: 'success' },
						{ color: 'info' },
						{ color: 'warning' },
						{ color: 'danger' },
						{ color: 'primary' },
						{ color: 'success' },
						{ color: 'info' },
						{ color: 'warning' },
						{ color: 'danger' },
					]
					res.data.forEach((item, i) => {
						badgeList.forEach((ele, e) => {
							if (i == e) {
								item.color = ele.color
							}
						})
					})
					this.userList = res.data
					this.spinnerShow = false
				} else {
					if (res.message) {
						this.$toast(res)
					}
				}
			})
		},
		getBrandConsumed() {
			this.$api.home
				.selectModelConsumed({
					supplierId: localStorage.supplierId,
				})
				.then((res) => {
					if (res.success) {
						let colorList = [
							{ color: 'rgba(196, 64, 89,1)' },
							{ color: 'rgba(206, 153, 41,1)' },
							{ color: 'rgba(86, 97, 181,1)' },
							{ color: 'rgba(52, 140, 210,1)' },
							{ color: 'rgba(9, 140, 134,1)' },
						]
						res.data.forEach((item, i) => {
							colorList.forEach((ele, e) => {
								if (i == e) {
									item.color = ele.color
								}
							})
						})
						this.flavorList = res.data
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
		},
		getConsumptionStatistical() {
			consumptionOneData.series[0].data = []
			consumptionOneData.series[0].axisLine.lineStyle.color = []
			consumptionTwoData.series[0].data = []
			consumptionTwoData.series[0].axisLine.lineStyle.color = []
			consumptionThreeData.series[0].data = []
			this.$api.home
				.selectCartridgeUsageDetails({
					supplierId: localStorage.supplierId,
				})
				.then((res) => {
					if (res.success) {
						consumptionOneData.series[0].data.push(res.data[0])
						if (
							res.data[0].ratio &&
							res.data[0].ratio > 0 &&
							res.data[0].ratio <= 0.3
						) {
							consumptionOneData.series[0].axisLine.lineStyle.color =
								[
									[res.data[0].ratio, 'rgba(239, 99, 106,1)'],
									[1, '#999'],
								]
						} else if (
							res.data[0].ratio &&
							res.data[0].ratio > 0.3 &&
							res.data[0].ratio <= 0.7
						) {
							consumptionOneData.series[0].axisLine.lineStyle.color =
								[
									[0.3, 'rgba(239, 99, 106,1)'],
									[res.data[0].ratio, 'rgba(98, 110, 212,1)'],
									[1, '#999'],
								]
						} else if (
							res.data[0].ratio &&
							res.data[0].ratio > 0.7 &&
							res.data[0].ratio < 1
						) {
							consumptionOneData.series[0].axisLine.lineStyle.color =
								[
									[0.3, 'rgba(239, 99, 106,1)'],
									[0.7, 'rgba(98, 110, 212,1)'],
									[res.data[0].ratio, 'rgba(2, 164, 153,1)'],
									[1, '#999'],
								]
						} else if (
							res.data[0].ratio &&
							res.data[0].ratio == 1
						) {
							consumptionOneData.series[0].axisLine.lineStyle.color =
								[
									[0.3, 'rgba(239, 99, 106,1)'],
									[0.7, 'rgba(98, 110, 212,1)'],
									[1, 'rgba(2, 164, 153,1)'],
								]
						} else {
							consumptionOneData.series[0].axisLine.lineStyle.color =
								[[1, '#999']]
						}
						consumptionTwoData.series[0].data.push(res.data[1])
						if (
							res.data[1].ratio &&
							res.data[1].ratio > 0 &&
							res.data[1].ratio <= 0.3
						) {
							consumptionTwoData.series[0].axisLine.lineStyle.color =
								[
									[res.data[1].ratio, 'rgba(239, 99, 106,1)'],
									[1, '#999'],
								]
						} else if (
							res.data[1].ratio &&
							res.data[1].ratio > 0.3 &&
							res.data[1].ratio <= 0.7
						) {
							consumptionTwoData.series[0].axisLine.lineStyle.color =
								[
									[0.3, 'rgba(239, 99, 106,1)'],
									[res.data[1].ratio, 'rgba(98, 110, 212,1)'],
									[1, '#999'],
								]
						} else if (
							res.data[1].ratio &&
							res.data[1].ratio > 0.7 &&
							res.data[1].ratio < 1
						) {
							consumptionTwoData.series[0].axisLine.lineStyle.color =
								[
									[0.3, 'rgba(239, 99, 106,1)'],
									[0.7, 'rgba(98, 110, 212,1)'],
									[res.data[1].ratio, 'rgba(2, 164, 153,1)'],
									[1, '#999'],
								]
						} else if (
							res.data[1].ratio &&
							res.data[1].ratio == 1
						) {
							consumptionTwoData.series[0].axisLine.lineStyle.color =
								[
									[0.3, 'rgba(239, 99, 106,1)'],
									[0.7, 'rgba(98, 110, 212,1)'],
									[1, 'rgba(2, 164, 153,1)'],
								]
						} else {
							consumptionTwoData.series[0].axisLine.lineStyle.color =
								[[1, '#999']]
						}
						consumptionThreeData.series[0].data.push(res.data[2])
						this.showConsumptionStatistical()
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
		},
		getConsumeOfBrand() {
			consumeOfBrandData.yAxis.data = []
			consumeOfBrandData.series[0].data = []
			this.$api.home
				.selectBrandConsumed({
					supplierId: localStorage.supplierId,
				})
				.then((res) => {
					if (res.success) {
						res.data.forEach((item) => {
							consumeOfBrandData.yAxis.data.push(item.brandName)
							consumeOfBrandData.series[0].data.push(item.count)
						})
						this.showConsumeOfBrand()
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
		},
		getBatchConsume() {
			batchConsumeData.xAxis.data = []
			batchConsumeData.series = []
			this.$api.home
				.selectBatchConsumed({
					supplierId: localStorage.supplierId,
				})
				.then((res) => {
					if (res.success) {
						batchConsumeData.xAxis.data = res.data.xaxis
						res.data.series.forEach((item) => {
							item.type = 'bar'
							item.barWidth = 14
							item.stack = 'batch'
							if (item.name == 'Remaining') {
								item.itemStyle = {
									emphasis: {
										barBorderRadius: [10, 10, 10, 10],
									},
									normal: {
										//柱形图圆角，初始化效果
										barBorderRadius: [10, 10, 1, 1],
										color: 'rgba(86, 97, 181,1)',
									},
								}
							} else if (item.name == 'Activated') {
								item.itemStyle = {
									normal: {
										color: 'rgba(9, 140, 134,1)',
									},
								}
							} else if (item.name == 'Consumed') {
								item.itemStyle = {
									normal: {
										color: 'rgba(198, 88, 96,1)',
									},
								}
							}
						})
						batchConsumeData.series = res.data.series.reverse()
						this.showBatchConsume()
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
		},
		getAgeConsumption() {
			ageConsumptionData.xAxis.data = []
			ageConsumptionData.series[0].data = []
			this.$api.home
				.selectAgeConsumption({
					supplierId: localStorage.supplierId,
				})
				.then((res) => {
					if (res.success) {
						ageConsumptionData.xAxis.data = res.data.ageSection
						ageConsumptionData.series[0].data = res.data.count
						this.showAgeConsumption()
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
		},
		getUsaMap() {
			// if ("WebSocket" in window) {
			//   alert("您的浏览器支持 WebSocket!");

			//   // 打开一个 web socket
			//   // var ws = new WebSocket("ws://localhost:9998/echo");
			//   let url = baseUrl.replace("http://", "ws://");
			//   var ws = new WebSocket(
			//     url +
			//       "/business/selectUsaMapCartridgeDetails?" +
			//       "supplierId=" +
			//       localStorage.supplierId
			//   );

			//   ws.onopen = function() {
			//     // Web Socket 已连接上，使用 send() 方法发送数据
			//     ws.send();
			//     alert("数据发送中...");
			//   };

			//   ws.onmessage = function(evt) {
			//     // var received_msg = evt.data;
			//     console.log(evt.data);
			//     alert("数据已接收...");
			//   };

			//   ws.onclose = function() {
			//     // 关闭 websocket
			//     alert("连接已关闭...");
			//   };
			// } else {
			//   // 浏览器不支持 WebSocket
			//   alert("您的浏览器不支持 WebSocket!");
			// }
			usaMapData.series[0].data = []
			this.$api.home
				.selectUsaMapCartridgeDetails({
					supplierId: localStorage.supplierId,
				})
				.then((res) => {
					if (res.success) {
						this.consumedTotal = res.data.consumedTotal
						this.consumedToday = res.data.consumedToday
						this.activedToday = res.data.activedToday
						usaMapData.series[0].data = res.data.usaMapVo
						usaMapData.visualMap.max = res.data.valueInterval
						this.showUsaMap()
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
		},
		requestFullScreen() {
			var element = document.getElementById('fullScreenpage')
			var requestMethod =
				element.requestFullScreen ||
				element.webkitRequestFullScreen ||
				element.mozRequestFullScreen ||
				element.msRequestFullScreen
			if (requestMethod) {
				requestMethod.call(element)
			} else if (typeof window.ActiveXObject !== 'undefined') {
				var wscript = new ActiveXObject('WScript.Shell')
				if (wscript !== null) {
					wscript.SendKeys('{F11}')
				}
			}
			this.btnText = true
		},
		exitFullscreen() {
			if (document.exitFullscreen) {
				document.exitFullscreen()
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen()
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen()
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen()
			}
			this.btnText = false
		},
	},
}
</script>

<style lang="scss" scoped>
.page {
	background-color: rgba(33, 39, 53, 1);
	height: calc(100vh);
	margin: 0 -24px;
	//   .title {
	//     .card-body {
	//       padding: 0;
	//     }
	//     .headline {
	//       position: absolute;
	//       margin-bottom: 0;
	//       font-size: 28px;
	//       line-height: 50px;
	//       top: 10px;
	//       width: 100%;
	//       text-align: center;
	//       background: linear-gradient(to bottom, white, rgba(55, 135, 250));
	//       background-clip: text;
	//       -webkit-text-fill-color: transparent;
	//     }
	//   }
	.title {
		text-align: center;
		width: 100%;
		background: url('../../assets/title.jpg');
		background-size: 100% auto;
		background-position: 0 0;
		background-repeat: no-repeat;
		position: relative;
		.headline {
			font-size: 3.6vh;
			line-height: 8vh;
			background: linear-gradient(
				to bottom,
				white,
				rgba(55, 135, 250, 1)
			);
			background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		.fullscreen {
			color: #fff;
			font-style: normal;
			font-size: 2.2vh;
			cursor: pointer;
			position: absolute;
			right: 1vh;
			top: 1vh;
		}
	}
	.bodyCard {
		// background-color: rgba(37, 43, 59, 1);
		border: 1px solid rgba(44, 50, 77, 1);
		width: 100%;
		// height: 100%;
		margin: 0 auto;
		padding: 2px;
		background: linear-gradient(
					to left,
					rgba(98, 110, 212, 1),
					rgba(98, 110, 212, 1)
				)
				no-repeat left top,
			linear-gradient(
					to left,
					rgba(98, 110, 212, 1),
					rgba(98, 110, 212, 1)
				)
				no-repeat right top,
			linear-gradient(
					to left,
					rgba(98, 110, 212, 1),
					rgba(98, 110, 212, 1)
				)
				no-repeat left bottom,
			linear-gradient(
					to left,
					rgba(98, 110, 212, 1),
					rgba(98, 110, 212, 1)
				)
				no-repeat left bottom,
			linear-gradient(
					to left,
					rgba(98, 110, 212, 1),
					rgba(98, 110, 212, 1)
				)
				no-repeat right bottom,
			linear-gradient(
					to left,
					rgba(98, 110, 212, 1),
					rgba(98, 110, 212, 1)
				)
				no-repeat right bottom,
			rgba(37, 43, 59, 1);
		background-size: 4vh 3px, 4vh 3px, 2vh 2px, 2px 2vh, 2vh 2px, 2px 2vh;
		// &:before {
		//   content: "";
		//   position: absolute;
		//   height: 3px;
		//   width: 40px;
		//   background-color: rgba(98, 110, 212,1);
		// }
	}
}
</style>
