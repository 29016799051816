<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <b-card>
      <b-row>
        <b-col lg="8">
          <b-form @submit="onSubmit" @reset="onReset">
            <!-- 加热模式名称 -->
            <b-form-group
              label="Voltage Curve Name:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="heating-name"
              label-class="requiredRow"
            >
              <b-form-input
                id="heating-name"
                v-model="form.modeName"
                placeholder="Please enter 4 to 20 characters"
                trim
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');"
                onblur="if(value.length<4||value.length>20)value=''"
              ></b-form-input>
            </b-form-group>

            <!-- 所属客户 -->
            <!-- <b-form-group
          label="Affiliated Customer:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="customer"
        >
          <b-form-select
            id="customer"
            v-model="form.supplierId"
            :options="types.allSupplier"
            value-field="id"
            text-field="brandName"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
          >
          </b-form-select>
        </b-form-group> -->

            <!-- 加热模式 -->
            <b-form-group
              label="Heating Mode Type:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="heatingType"
              label-class="requiredRow"
              style="display: none"
            >
              <b-form-radio-group
                id="heatingType"
                v-model="form.heatingType"
                :options="types.heatingTypes"
                name="heatingType"
                required
                @change.native="changeHeatingType(form.heatingType)"
                style="margin-top: 5px"
              ></b-form-radio-group>
            </b-form-group>

            <!-- 模式描述 -->
            <b-form-group
              label="Voltage Curve Description:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="path"
              label-class="requiredRow"
            >
              <b-form-input
                id="application-name"
                v-model="form.heatingRemarks"
                placeholder="Please enter"
                trim
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');if(value.length > 100)value = value.slice(0, 100)"
              ></b-form-input>
            </b-form-group>

            <!-- 设备类型 -->
            <b-form-group
              label="Devices Type:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="device-type"
              label-class="requiredRow"
            >
              <b-form-select
                id="device-type"
                v-model="form.deviceId"
                :options="types.deviceType"
                value-field="id"
                text-field="modelName"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
            </b-form-group>
                  <!-- 档数 高档或者低档 -->
                <b-form-group
              label="Position:"
              ref="groupNumberSetting"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="position"
              label-class="requiredRow"
            >
              <div class="d-flex flex-wrap">
                <b-form-checkbox
                  v-for="(label, index) in types.gearSetting"
                  :required="true"
                  :key="index"
                  v-model="form.position"
                  :value="label.value"
                  class="mr-2 mt-2"
                >
                  {{ label.text }}
                </b-form-checkbox>
              </div>
            </b-form-group>

            <!-- 曲线模式--加热温度设置 -->
            <b-form-group
              v-if="form.heatingType == 2"
              label="Voltage:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="temperature"
              label-class="requiredRow"
            >
            <div class="temperature">
                  <history-curve :power="power" width="100%" />
                </div>
              <div class="voltage-one">
                <div
                  class="voltage-number"
                  v-for="(item, index) in temperatureList"
                  :key="index"
                >
                  {{ ((item.value * item.value) / 1.2).toFixed(2) }} w
                </div>
              </div>

              <div class="voltage-two" style="width: 100%">
                <!--               @touchmove.prevent="(e)=>{}" -->

                <div
                  class="voltage-list"
                  v-for="(item, index) in temperatureList"
                  :key="index"
                >
                  <i
                    class="el-icon-plus voltage-plus"
                    @click="onVoltagePlus(index)"
                  ></i>

                  <el-slider
                    v-model="item.value"
                    vertical
                    :step="step"
                    :min="min"
                    :max="max"
                    height="150px"
                  >
                  </el-slider>

                  <i
                    class="el-icon-minus voltage-reduce"
                    @click="onVoltageReduce(index)"
                  ></i>
                </div>
              </div>

              <div class="voltage-three">
                <div class="voltage-number" v-for="index of 8" :key="index">
                  {{ index }}s
                </div>
              </div>
              <p>
                The Voltage curve is between 0.4v-3.7v, after the 8th second,
                the same as the 8th second
              </p>
            </b-form-group>

            <!-- 单热模式--加热温度 -->
            <b-form-group
              v-else
              label="Heating Temperature:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="temperature"
              label-class="requiredRow"
            >
              <b-form-input
                id="temperature"
                v-model="form.temperature"
                placeholder="Please enter(℉)"
                trim
                required
                oninvalid="setCustomValidity('Please enter')"
                oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
              ></b-form-input>
            </b-form-group>
            <!-- Preheat 是否打开预热 -->
            <b-form-group
              label="Preheat Settings:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="preheat"
              label-class="requiredRow"
            >
              <b-form-checkbox
                id="preheat"
                v-model="form.preheatSetting"
                name="preheatSetting"
                :value="1"
                :unchecked-value="0"
                switch
                size="lg"
                style="margin-top: 5px"
              >
                {{ form.preheatSetting == 1 ? "Yes" : "No" }}
              </b-form-checkbox>
            </b-form-group>
            <!-- 预热时间 -->
            <b-form-group
              v-if="form.preheatSetting == 1"
              label="Time:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="preheat-time"
              label-class="requiredRow"
            >
              <b-form-spinbutton
                id="preheat-time"
                name="preheatTime"
                style="width: 12rem"
                placeholder="0"
                step="0.5"
                v-model="preheatTime1"
                min="0.5"
                max="20"
              ></b-form-spinbutton>
              <p>The preheat time is between 0s-20s</p>
            </b-form-group>

            <!-- 预热电压 -->
            <b-form-group
              v-if="form.preheatSetting == 1"
              label="Preheat Voltage:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="preheatVoltage"
              label-class="requiredRow"
            >
              <!-- <b-form-input
              style="width:12rem"
              v-model="form.preheatVoltage"
              min="0"
              step="0.1"
              max="4.2"
              placeholder="Please enter"
              title=""
              required
              oninvalid="setCustomValidity('Please enter')"
              oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3')"
              onblur="if(value<0||value>4.2)value='';if(value)value = parseFloat(value)"
          ></b-form-input> -->
              <b-form-spinbutton
                id="preheat-time"
                name="preheatVoltage"
                style="width: 12rem"
                placeholder="0"
                step="0.1"
                v-model="preheatVoltage1"
                min="0.4"
                max="3.7"
              ></b-form-spinbutton>
              <p>The Preheat voltage is between 0.4v-3.7v</p>
            </b-form-group>

            <!-- Preheat 是否打开Nfc设置 -->
            <b-form-group
              label="NFC Settings:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="nfc-settings"
              label-class="requiredRow"
            >
              <b-form-checkbox
                id="nfc-settings"
                v-model="form.nfcSettings"
                name="nfcSettings"
                :value="1"
                :unchecked-value="0"
                switch
                size="lg"
                style="margin-top: 5px"
              >
                {{ form.nfcSettings == 1 ? "Yes" : "No" }}
              </b-form-checkbox>
            </b-form-group>
            <!-- 状态 -->
            <b-form-group
              label="Status:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="status"
              label-class="requiredRow"
            >
              <b-form-radio-group
                id="radio-group-1"
                v-model="form.status"
                :options="types.statuss"
                name="radio-options"
                required
                style="margin-top: 5px"
              ></b-form-radio-group>
            </b-form-group>

            <!-- 备注 -->
            <b-form-group
              label="Notes:"
              label-cols-lg="3"
              label-align-sm="right"
              label-for="remark"
            >
              <b-form-textarea
                id="remark"
                v-model="form.remarks"
                placeholder="Enter Notes"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group label-cols-lg="3">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" variant="danger" class="m-l-5 ml-1"
                >Cancel
              </b-button>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady } from "@/api/upload";
import HistoryCurve from "../../../../components/HistoryCurve.vue";
/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    HistoryCurve,
  },
  data() {
    return {
      page: {
        title: this.editId ? "Modify Voltage Curve" : "Create Voltage Curve",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "My Curves",
            // href: "/"
          },
          {
            text: this.editId ? "Modify Voltage Curve" : "Create Voltage Curve",
            active: true,
          },
        ],
      },
      form: {
        id: "",
        modeName: "",
        heatingType: "2",
        heatingRemarks: "",
        supplierId: localStorage.supplierId,
        temperature: "",
        remarks: "",
        status: 0,
        scopeOfUse: 1, // 0-原加热模式 1-omni新加热模式
        preheatSetting: 0,
        preheatVoltage: 0,
        preheatTime: 0,
        nfcSettings: 0,
        deviceId: 0,
        position: 2,
      },
      preheatVoltage1: 2.4,
      preheatTime1: 3,
      temperatureList: [
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
      ],
      min: 0.4,
      max: 3.7,
      step: 0.1,
      types: {
        // allSupplier: [{ brandName: "Please select", id: "" }],
        deviceType: [{ modelName: "Please select", id: 0 }],
        gearSetting: [
          { text: "High position", value: 2 },
          { text: "Low position", value: 1 },
        ],
        heatingTypes: [
          { text: "Consistent Temperature", value: 1 },
          { text: "High Wave", value: 2 },
        ],
        statuss: [
          { text: "Enabled", value: 0 },
          { text: "Disabled", value: 1 },
        ],
      },
      editId: window.location.search,
      editor: ClassicEditor,
      curveColor: "#86A1A9",
    };
  },
  beforeCreate() {
    this.editId = window.location.search;
  },
  created() {
    this.init();
    if (this.editId) {
      let id = this.editId.split("=")[1];
      this.formData(id);
    }
  },
  mounted() {},
  computed: {
    power() {
      return this.temperatureList.map((item) =>
        parseFloat((Math.pow(item.value, 2) / 1.2).toFixed(2))
      );
    },
  },
  methods: {
    init(){
      this.$api.Dropdown.selectAllTestSmokeBombModel({
        type: this.selectItem ? this.selectItem : 3,
      }).then((res) => {
        if (res.success && res.data) {
          this.types.deviceType =
            this.types.deviceType.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onReady: onReady,
    onSubmit(evt) {
      evt.preventDefault();
      if (!this.form.deviceId || this.form.deviceId == 0) {
        this.$bvToast.toast("Please select Devices type.");
        return;
      }
      if (!this.form.position) {
          this.$bvToast.toast("Please select Position.");
          return;
        }
      // let arr = this.form.temperature.split(',')
      // let newArr = []
      // for (let index = 0; index < arr.length; index++) {
      //   const element = arr[index] * 1000
      //   newArr.push(element)
      // }
      // if (newArr.length < 8) {
      //   this.$toastWarning('Minimum 8 voltage parameters required')
      //   return
      // }
      this.form.temperature = this.temperatureList
        .map((item) => item.value * 1000)
        .join(",");
      this.form.preheatTime = this.preheatTime1 * 1000;
      this.form.preheatVoltage = this.preheatVoltage1 * 1000;
      if (this.form.id) {
        this.$api.warmUpMode.updateByHeatingModeId(this.form).then((res) => {
          if (res.message) {
            this.$toast(res);
          }
          if (res.success) {
            this.$toastBack(res);
          }
        });
      } else {
        this.$api.warmUpMode.addHeatingMode(this.form).then((res) => {
          if (res.message) {
            this.$toast(res);
          }
          if (res.success) {
            this.$toastBack(res);
          }
        });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        id: "",
        modeName: "",
        heatingType: "2",
        heatingRemarks: "",
        supplierId: localStorage.supplierId,
        temperature: "",
        remarks: "",
        status: "",
        scopeOfUse: 1, // 0-原加热模式 1-omni新加热模式
        preheatSetting: "",
        preheatVoltage: "",
        preheatTime: "",
        nfcSettings: "",
      };
      this.$nextTick(() => {
        this.$router.go(-1);
      });
    },
    formData(id) {
      this.$api.warmUpMode
        .selectByHeatingModeId({ id: id, scopeOfUse: 1 })
        .then((res) => {
          if (res.success) {
            if (res.data.temperature) {
              let arr = res.data.temperature.split(",");
              let newArr = [];
              arr.forEach((element) => {
                let item = element / 1000;
                newArr.push(item);
              });
              this.temperatureList = [];
              let newTemperature = "";
              newArr.forEach((i) => {
                this.temperatureList.push({ value: i });
              });
              newTemperature = newArr.join(",");
              res.data.temperature = newTemperature;
            }
            res.data.preheatTime = res.data.preheatTime / 1000;
            res.data.preheatVoltage = res.data.preheatVoltage / 1000;
            this.preheatTime1 = res.data.preheatTime;
            this.preheatVoltage1 = res.data.preheatVoltage;
            this.form = res.data;
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
    },
    changeTemperatureList(data) {
      let arr = [];
      data.forEach((item) => {
        if (item.value) {
          arr.push(item.value);
        }
      });
      this.form.temperature = arr.join(",");
    },
    changeHeatingType(value) {
      if (value == 2) {
        this.temperatureList = [
          { value: "" },
          { value: "" },
          { value: "" },
          { value: "" },
          { value: "" },
          { value: "" },
          { value: "" },
          { value: "" },
        ];
      } else {
        this.form.temperature = "";
      }
    },
    onVoltagePlus(index) {
      this.temperatureList[index].value = parseFloat(
        (this.temperatureList[index].value + 0.1).toFixed(1)
      );
    },
    onVoltageReduce(index) {
      this.temperatureList[index].value = parseFloat(
        (this.temperatureList[index].value - 0.1).toFixed(1)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.required-icon {
  ::before {
    content: "*";
  }
}

::v-deep .van-slider {
  margin: 10px auto;
}

.slider-button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ffffff;
}

.voltage-two {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .voltage-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;

    .voltage-plus {
      margin-bottom: 20px;
    }

    .voltage-reduce {
      margin-top: 16px;
    }
  }
}
.voltage-one {
  margin-top: 10px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
  }
}
.voltage-three {
  margin-top: 66px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
  }
}
.temperature {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-slider__button {
  border:  2px solid #86A1A9;
}
::v-deep .el-slider__bar {
  background: #86A1A9;
}
</style>
