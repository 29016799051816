<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />
    <!-- main -->

    <b-card>
      <b-row class="vo-items-center">
        <b-col lg="4">
          <div class="vo-row vo-items-center">
            <ReferenceBy label="REFINED BY" />
            <b-input
              style="flex: 1; margin-left: 10px"
              v-model="search_condition.title"
              placeholder="Search title and text"
            />
          </div>
        </b-col>

        <b-col>
          <v-select
            v-model="search_condition.brandId"
            :options="types.brandOptions"
            label="text"
            :reduce="(option) => option.value"
            placeholder="--All Brand--"
            class="custom-placeholder"
          >
          </v-select>
        </b-col>

        <!-- <b-col>
          <b-select
            v-model="search_condition.brandId"
            :options="types.brandOptions"
          />
        </b-col> -->
        <b-col>
          <b-select
            v-model="search_condition.startStatus"
            :options="types.statusOptions"
          />
        </b-col>
        <b-col>
          <b-form-select
            v-model="search_condition.businessTypeId"
            :options="types.oilType"
          ></b-form-select>
        </b-col>
        <b-col>
          <div class="vo-row vo-items-center vo-justify-between">
            <div class="custom-color cursor" @click="clearAll">Clear All</div>
            <b-button variant="info" @click="search">Search</b-button>
          </div>
        </b-col>
      </b-row>
      <div class="table-responsive mb-0 box-list">
        <div v-if="isBusy">
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </div>
        <div v-else>
          <b-row
            cols-lg="5"
            cols-sm="2"
            cols-xl="6"
            cols-md="4"
            style="margin: 0"
          >
            <b-col
              class="cursor"
              v-for="(item, index) in brandBlog"
              :key="index"
              style="margin: 20px 0 0 0"
            >
              <template v-if="item.viewType === 'add'">
                <CreateProduct @click="gotoAdd" name="Create Blog" />
              </template>

              <blog
                v-else
                :item="item"
                @clickStatus="clickStatus(item)"
                @clickDetail="clickDetail(item)"
                @clickEdit="clickEdit(item)"
              />
            </b-col>
          </b-row>
        </div>
      </div>

      <b-row v-if="has_data" style="margin-top: 10px">
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="rows != 0">
              <li>
                <p
                  class="pagination mb-0"
                  style="margin-top: 7px; margin-right: 20px"
                >
                  {{ rows }} results
                </p>
              </li>
              <b-pagination
                v-model="search_condition.pageNum"
                :per-page="btnMenu('blogMgmt::Create') ? 11 : 12"
                :total-rows="rows"
                @change="onPageChange"
              >
              </b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <Dialog
      v-model="showAlert"
      :imageSrc="require('@/assets/confirm.svg')"
      okBtn="Yes"
      noBtn="No"
      @ok="enableOrDisableById"
      @cancel="cancel"
    >
      <p>{{ showContent }}</p>
    </Dialog>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel1"
    >
      <p>Saved successfully</p>
    </Dialog>
  </Layout>
</template>

<style scoped>
.containerImage img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}
.productImage {
  display: flex; /* 使用 Flexbox 来居中图片和文字 */
  flex-direction: column;
  align-items: center; /* 水平居中 */
  text-align: center; /* 文字居中 */
}
.outerBox {
  border: 2px dashed #02a7f0;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5); /* 添加阴影效果 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex; /* 使用 Flexbox 来居中图片和文字 */
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 3;
  flex-direction: column;
  justify-content: space-between;
}

.disableBox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex; /* 使用 Flexbox 来居中图片和文字 */
  background-color: rgba(242, 242, 242, 0.7);
  z-index: 3;
  flex-direction: column;
}
.disableLable {
  margin-top: 0px;
}
.topButton {
  display: flex; /* 使用 Flexbox 来居中图片和文字 */
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 5px;
}

.belowButton {
  display: flex; /* 使用 Flexbox 来居中图片和文字 */
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 5px;
}
.topButton img {
  width: 27px;
  height: 27px;
  margin-right: 10px;
}
.productImage img {
  height: 190px;
}

.caption {
  font-size: 14px;
  color: #02a7f0;
  text-align: center;
  margin-bottom: -30px;
}

.productName {
  font-size: 14px;
  text-align: center;
  margin-bottom: -20px;
}
.icon-class img {
  width: 34px;
  height: 41px;
}
.icon-class {
  text-align: center;
}

.custom-placeholder ::placeholder {
  font-size: 0.875rem;
  color: #495057;
}
</style>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Dialog from "@/components/Dialog";
import ProductDetail from "@/components/product/ProductDetail";
import blog from "@/components/blog/blog";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import ReferenceBy from "@/components/FindByLabel.vue";
import CreateProduct from "@/components/product/CreateProduct.vue";
import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";

/**
 * FAQ component
 */
export default {
  components: {
    CreateProduct,
    Layout,
    PageHeader,
    DatePicker,
    timeZoneConversion,
    Dialog,
    blog,
    ReferenceBy,
    ProductDetail,
    VSelect,
  },
  data() {
    return {
      page: {
        title: "Blog Mgmt",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "App Settings",
            // href: "/"
          },
          {
            text: "Blog Mgmt",
            active: true,
          },
        ],
      },
      totalRows: 0,
      search_condition: {
        pageNum: 1,
        pageSize: this.btnMenu("blogMgmt::Create") ? 11 : 12,
        title: "",
        startStatus: "",
        businessTypeId: "",
        brandId: "",
      },
      has_data: false,
      types: {
        oilType: [
          { text: "--All Business Type--", value: "", disabled: false },
          { text: "CBD/THC Concentrates", value: 2, disabled: false },
          { text: "Nicotine", value: 1, disabled: false },
        ],
        statusOptions: [
          { text: "--All Status--", value: "", disabled: false },
          { text: "Enabled", value: 1, disabled: false },
          { text: "Disabled", value: 0, disabled: false },
        ],
        brandOptions: [],
      },
      brandBlog: [],
      showAlert: false,
      successAlear: false,
      showContent: "",
      postData: {},
      showDetailAlear: false,
      productDetail: {},
      isBusy: false,
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.selectBrandBlogList();
      this.grtBrandList();
    },
    //按钮权限判断
    btnMenu(value) {
      return this.$_has(value);
    },
    selectBrandBlogList() {
      this.isBusy = true;
      //this.$_has("productMgmt::Create")
      this.$api.brandBlog
        .selectBrandBlogList(this.search_condition)
        .then((x) => {
          this.isBusy = false;
          this.has_data = true;
          this.has_data = true;
          this.totalRows = x.total;
          this.brandBlog = x.data.map((item, i) => {
            item.showMenu = false;
            return item;
          });
          if (this.btnMenu("blogMgmt::Create")) {
            this.brandBlog.unshift({
              deviceImage: require("@/assets/images/common/ic_add.svg"),
              viewType: "add",
            });
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
        });
    },
    clickEdit(item) {
      this.$router.push("blogMgmtAdd?id=" + item.id);
    },
    search() {
      this.search_condition.pageNum = 1;
      this.selectBrandBlogList();
    },
    clearAll() {
      this.search_condition = {
        pageNum: 1,
        pageSize: this.btnMenu("blogMgmt::Create") ? 11 : 12,
        title: "",
        startStatus: "",
        businessTypeId: "",
        brandId: "",
      };
      this.selectBrandBlogList();
    },
    gotoAdd() {
      this.$router.push("blogMgmtAdd");
    },
    //确认是否禁用或者启用弹框
    clickStatus(item) {
      this.showAlert = true;
      let status = 0;
      if (item.startStatus == 0) {
        status = 1;
        this.showContent = "Are sure to enble this blog?";
      } else {
        this.showContent = "Are sure to disable this blog?";
      }
      this.postData = {
        id: item.id,
        status: status,
      };
    },

    //查询所有品牌
    grtBrandList() {
      this.$api.Dropdown.brandList().then((x) => {
        if (x.success) {
          this.types.brandOptions = this.types.brandOptions.concat(
            x.data.map((item, i) => {
              return {
                text: item.brandName,
                value: item.id,
                disabled: false,
              };
            })
          );
        } else {
          if (x.message) {
            this.$toast(x);
          }
        }
      });
    },
    //点击详情
    clickDetail(item) {
      window.open(
        "https://www.ikrusher.com/blogs/blog/how-to-unclog-a-510-cartridge",
        "_blank"
      );
    },
    //确定禁用或者启用
    enableOrDisableById() {
      this.$api.brandBlog
        .enableOrDisableById(this.postData)
        .then((res) => {
          if (res.success) {
            this.showAlert = false;
            this.successAlear = true;
            this.selectBrandBlogList();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        })
        .catch((error) => {});
    },
    onPageChange(page) {
      this.search_condition.pageNum = page;
      this.selectBrandBlogList();
    },
    cancel() {
      this.showAlert = false;
    },
    cancel1() {
      this.successAlear = false;
    },
    cancel2() {
      this.showDetailAlear = false;
    },
  },
};
</script>
