<template>
  <MaskView
    borderStyle="border: 1px solid #d7d7d7"
    outerBorderStyle="1px dashed #02a7f0"
    :background-color="item.status === 1 ? `rgba(242, 242, 242, 1)` : ''"
  >
    <div class="position-relative w-100 h-100">
      <div class="position-absolute img-wrap">
        <img
          style="width: 90%; height: 90%; object-fit: contain"
          :src="item.deviceImage"
        />
        <p class="productName">{{ item.modelName }}</p>
      </div>

      <div class="status position-absolute">
        <b-badge
          v-if="item.status == 1"
          class="custom-badge"
          href="#"
          :variant="statusVariant"
          >{{ statusText }}</b-badge
        >
        <el-image
          fit="contain"
          href="#"
          style="width: 60px; object-fit: contain; padding-top: 10px"
          :src="item.brandImage"
        />
      </div>
    </div>
    <template #top>
      <div class="vo-row align-items-center vo-gap-4 justify-content-end p-2">
        <img
          v-has="'brandMgmt::Create'"
          v-if="item.status == 0"
          src="@/assets/enable.svg"
          class="cursor"
          @click="$emit('clickStatus')"
        />
        <img
          v-has="'brandMgmt::Create'"
          v-if="item.status == 1"
          src="@/assets/disable.svg"
          class="cursor"
          @click="$emit('clickStatus')"
        />
        <img
          v-has="'brandMgmt::Create'"
          src="@/assets/edit.svg"
          class="cursor"
          @click="$emit('clickEdit')"
        />
        <img
          src="@/assets/view.svg"
          class="cursor"
          @click="$emit('clickDetail')"
        />
        <!-- <img src="@/assets/edit.svg" class="cursor" @click="clickEdit" /> -->
      </div>
    </template>
    <template #bottom>
      <div
        class="vo-row align-items-center justify-content-around flex-wrap mb-1 ml-1 mr-1"
        style="gap: 10px"
      >
        <b-button
          v-if="
            btnMenu('brandProductMgmt::FlavorBtn') ||
            (!isAccountLevel() && btnMenu('brandMgmt::Create'))
          "
          style="flex: 1"
          variant="info"
          @click="$emit('clickFlavor')"
          >Flavor
        </b-button>
        <b-button
          v-if="
            btnMenu('brandProductMgmt::CurvesBtn') ||
            (!isAccountLevel() && btnMenu('brandMgmt::Create'))
          "
          style="flex: 1"
          variant="info"
          @click="$emit('clickCurves')"
          >Curves
        </b-button>
        <b-button
          v-if="
            btnMenu('brandProductMgmt::BatchBtn') ||
            (!isAccountLevel() && btnMenu('brandMgmt::Create'))
          "
          style="flex: 1"
          variant="info"
          @click="$emit('clickBatch')"
          >Batch
        </b-button>
      </div>
    </template>
  </MaskView>
</template>

<script>
import MaskView from "@/components/MaskView.vue";

export default {
  name: "BrandItem",
  components: { MaskView },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    statusVariant() {
      switch (this.item.status) {
        case 1: //禁用
          return "danger";
      }
    },
    statusText() {
      switch (this.item.status) {
        case 1: //禁用
          return "Disabled";
      }
    },
  },
  methods: {
    //按钮权限判断
    btnMenu(value) {
      return this.$_has(value);
    },
    //账户层级
    isAccountLevel() {
      return localStorage.accountLevel == 0;
    },
  },
};
</script>

<style scoped lang="scss">
.status {
  top: 0px;
  left: 0;
  font-size: 20px;
}

.img-wrap {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}

::v-deep .badge {
  border-radius: 0;
}

.operate-btn {
  width: 80px;
}
.productName {
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出的部分 */
  text-overflow: ellipsis; /* 省略号 */
  width: 100%; /* 确保宽度适应父容器 */
}

.disableBox {
  background-color: rgba(242, 242, 242, 1);
}

.custom-badge {
  height: 30px; /* 设置高度 */
  line-height: 30px; /* 行高与高度相同，使文本垂直居中 */
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  text-align: center; /* 确保文本居中对齐 */
  margin-top: 12px;
}
</style>