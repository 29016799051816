<!--
 * @Author: Code-HHX
 * @Date: 2022-03-29 15:32:44
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-03-31 18:40:44
 * @Description: 积分活动详情（宝石活动详情）
-->
<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<!-- 积分活动详情 -->
		<b-card>
			<b-row class="align-items-center">
				<b-col lg="12">
					<div class="blockquote">
						<h4>Integral Activity Details</h4>
					</div>
				</b-col>
			</b-row>
			<b-container>
				<b-row cols="3">
					<!-- 活动名称 -->
					<b-col>
						<label class="col-form-label">Activity Name:</label>
						<span class="p-3">{{ info.activityName }}</span>
					</b-col>
					<!-- 活动时间 -->
					<b-col>
						<label class="col-form-label">Activity Time:</label>
						<span class="p-3">{{ info.activityTime }}</span>
					</b-col>
					<!-- 激活设备获得宝石数 -->
					<b-col>
						<label class="col-form-label"
							>Activate device to get gems:</label
						>
						<span class="p-3">{{
							info.activatedAwardIntegral
						}}</span>
					</b-col>
					<!-- 邀请获得宝石 -->
					<b-col>
						<label class="col-form-label"
							>Invite friends to get integral:</label
						>
						<span class="p-3">{{ info.inviteAwardIntegral }}</span>
					</b-col>
					<!-- 被邀请获得宝石 -->
					<b-col>
						<label class="col-form-label"
							>Invitees get integral:</label
						>
						<span class="p-3">{{
							info.beInvitedAwardIntegral
						}}</span>
					</b-col>
					<!-- 状态 -->
					<b-col>
						<label class="col-form-label">Status:</label>
						<span class="p-3">{{ info.activityStatus }}</span>
					</b-col>
					<!-- 已发放宝石 -->
					<b-col>
						<label class="col-form-label">Gem Received:</label>
						<span class="p-3">{{ info.issuanceGemsQuantity }}</span>
					</b-col>
					<!-- 备注 -->
					<b-col>
						<label class="col-form-label">Remarks:</label>
						<span class="p-3">{{ info.remarks }}</span>
					</b-col>
				</b-row>
			</b-container>
		</b-card>
		<!-- end card -->
		<b-card>
			<b-button
				size="lg"
				:v-has="'integralActivity::Modify'"
				variant="primary"
				@click="modify"
				style="margin-right:10px;"
				>Modify</b-button
			>
			<b-button
				size="lg"
				type="reset"
				variant="outline-secondary"
				@click="returnPage"
				>Go Back</b-button
			>
		</b-card>
		<!-- end card -->
	</Layout>
</template>
<script>
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'

/**
 * User Info component
 */
export default {
	components: {
		Layout,
		PageHeader,
		timeZoneConversion,
	},
	data() {
		return {
			title: 'Integral Activity Details',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'Marketing Mgmt',
					// href: "/"
				},
				{
					text: 'Integral Activity Details',
					active: true,
				},
			],
			info: {},
		}
	},
	created() {},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			if (this.$route.query.id) {
				this.$api.Marketing.selectGemsActivityDetailsById(
					this.$route.query
				).then((res) => {
					if (res.success) {
						this.info = res.data
						if (this.info.startTime && this.info.endTime) {
							this.info.activityTime =
								timeZoneConversion.getLocalTime(
									this.info.startTime
								) +
								' ~ ' +
								timeZoneConversion.getLocalTime(
									this.info.endTime
								)
						}
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
			} else {
				this.$bvToast.toast('id?')
			}
		},
		modify() {
			this.$router.replace(
				'integralActivityEdit?id=' + this.$route.query.id
			)
		},
		returnPage() {
			this.$router.go(-1)
		},
	},
}
</script>
