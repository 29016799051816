import { format } from "./time";
import axios from "../config/http.js";
import axios1 from "../config/http-json.js";
import baseUrl from "../config/env.js";

//查询商品口味标签列表
function selectLabelList(params) {
  return axios1.fetchGet(baseUrl + "/commodityLabel/selectLabelList", params);
}

//添加或修改商品口味标签
function saveOrUpdateLabel(params) {
  return axios1.fetchPost(
    baseUrl + "/commodityLabel/saveOrUpdateLabel",
    params
  );
}

//启动或禁用商品口味标签
function enableOrDisableById(params) {
  return axios1.fetchPost(
    baseUrl + "/commodityLabel/enableOrDisableById",
    params
  );
}

//查询使用感受标签列表
function effectsSelectLabelList(params) {
  return axios1.fetchGet(
    baseUrl + "/experienceFeelingsLabel/selectLabelList",
    params
  );
}

//添加或修改使用感受标签
function effectsSaveOrUpdateLabel(params) {
  return axios1.fetchPost(
    baseUrl + "/experienceFeelingsLabel/saveOrUpdateLabel",
    params
  );
}

//启动或禁用使用感受标签
function effectsEnableOrDisableById(params) {
  return axios1.fetchPost(
    baseUrl + "/experienceFeelingsLabel/enableOrDisableById",
    params
  );
}

//查询口感描述标签列表
function flavorSelectLabelList(params) {
  return axios1.fetchGet(baseUrl + "/tasteLabel/selectLabelList", params);
}

//添加或修改使用感受标签
function flavorSaveOrUpdateLabel(params) {
  return axios1.fetchPost(baseUrl + "/tasteLabel/saveOrUpdateLabel", params);
}

//启动或禁用使用感受标签
function flavorEnableOrDisableById(params) {
  return axios1.fetchPost(baseUrl + "/tasteLabel/enableOrDisableById", params);
}

//查询强度标签列表
function intensitySelectLabelList(params) {
  return axios1.fetchGet(baseUrl + "/strength/selectLabelList", params);
}

//添加或修改强度标签
function intensitySaveOrUpdateLabel(params) {
  return axios1.fetchPost(baseUrl + "/strength/saveOrUpdateLabel", params);
}

//启动或禁用强度标签
function intensityEnableOrDisableById(params) {
  return axios1.fetchPost(baseUrl + "/strength/enableOrDisableById", params);
}

export default {
  selectLabelList,
  saveOrUpdateLabel,
  enableOrDisableById,
  effectsSelectLabelList,
  effectsSaveOrUpdateLabel,
  effectsEnableOrDisableById,
  flavorSelectLabelList,
  flavorSaveOrUpdateLabel,
  flavorEnableOrDisableById,
  intensitySelectLabelList,
  intensitySaveOrUpdateLabel,
  intensityEnableOrDisableById,
};
