/*
 * @Author: Code-HHX
 * @Date: 2021-12-22 14:32:40
 * @LastEditors: Code-HHX
 * @LastEditTime: 2021-12-22 14:42:26
 * @Description: 防止按钮重复点击提交指令
 */
export default {
	install(Vue) {
		// 防止重复点击

		Vue.directive('preventReClick', {
			inserted(el, binding) {
				console.log('binding-7', binding)

				el.addEventListener('click', () => {
					if (!el.disabled) {
						el.disabled = true

						setTimeout(() => {
							el.disabled = false
						}, binding.value || 1000)
					}
				})
			},
		})
	},
}
