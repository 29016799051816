<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <b-card>
      <!-- <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4 v-if="isAccountLevel()">Client Business Info</h4>
          </div>
        </b-col>
      </b-row> -->
      <div class="vo-column" style="width: 100%">
        <div class="productDetailRow">
          <div class="productDetailColumn">
            <div class="vo-row">
              <p class="productInfo">
                <strong>Voltage Curves Name:</strong>
              </p>
              <p class="productInfoContent">
                {{ group.groupName }}
              </p>
            </div>

            <div class="vo-row">
              <p class="productInfo">
                <strong>Voltage Curves Description:</strong>
              </p>
              <p class="productInfoContent">
                {{ group.description }}
              </p>
            </div>

            <div class="vo-row">
              <p class="productInfo">
                <strong>Be Available For:</strong>
              </p>
              <p class="productInfoContent">
                {{ group.deviceName }}
              </p>
            </div>

            <b-row>
              <b-col cols="8">
                <div class="vo-row">
                  <p class="productInfo">
                    <strong>Notes:</strong>
                  </p>

                  <div class="productInfoContent">
                    <b-row style="margin-left: -10px">
                      <b-col>
                        <b-form-textarea
                          id="company-introduction"
                          :placeholder="group.remarks"
                          :disabled="true"
                          rows="10"
                          max-rows="10"
                          maxlength="2000"
                        ></b-form-textarea>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>

            <div class="vo-row">
              <p class="productInfo">
                <strong>Status:</strong>
              </p>
              <p class="productInfoContent">
                {{ group.status == 0 ? "Eanable" : "Disable" }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <b-card>
      <b-row class="align-items-center">
        <b-col cols="12">
          <div class="blockquote">
            <h4>Set Voltage Curves:</h4>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="7">
          <b-form-group
            label-cols-lg="3"
            label-align-lg="right"
            label-for="curveOne"
          >
            <div style="padding-bottom: 40px">
              <button
                type="button"
                v-for="(item, index) in btnList"
                :key="index"
                :style="{
                  backgroundColor:
                    activeIndex === index ? 'rgba(255, 255, 255, 1)' : '',
                  color: activeIndex === index ? '#000000' : '',
                }"
                class="buttonStyle"
                @click="clickBtn(index)"
              >
                {{ item }}
              </button>
            </div>
            <HistoryCurve :width="'680px'" :power="types.power" />
            <div v-if="activeIndex == 0">
              <div class="voltage-one">
                <div
                  class="voltage-number"
                  v-for="(item, index) in temperatureList"
                  :key="index"
                >
                  {{
                    parseFloat(
                      (
                        Math.pow(item.value, 2) / parseFloat(resistance)
                      ).toFixed(1)
                    )
                  }}
                  w
                  <!-- {{ item.value }} v -->
                </div>
              </div>

              <div class="voltage-two" style="width: 100%">
                <div
                  class="voltage-list"
                  v-for="(item, index) in temperatureList"
                  :key="index"
                >
                  <i
                    class="el-icon-plus voltage-plus"
                  ></i>

                  <el-slider
                    v-model="item.value"
                    vertical
                    :step="step"
                    :min="min"
                    :max="max"
                    disabled
                    height="150px"
                  >
                  </el-slider>

                  <i
                    class="el-icon-minus voltage-reduce"
                  ></i>
                </div>
              </div>

              <div class="voltage-three">
                <div class="voltage-number" v-for="index of 8" :key="index">
                  {{ index }}s
                </div>
              </div>
            </div>

            <div v-if="activeIndex == 1">
              <div class="voltage-one">
                <div
                  class="voltage-number"
                  v-for="(item, index) in temperatureList1"
                  :key="index"
                >
                  {{
                    parseFloat(
                      (
                        Math.pow(item.value, 2) / parseFloat(resistance)
                      ).toFixed(1)
                    )
                  }}
                  w
                  <!-- {{ item.value }} v -->
                </div>
              </div>

              <div class="voltage-two" style="width: 100%">
                <div
                  class="voltage-list"
                  v-for="(item, index) in temperatureList1"
                  :key="index"
                >
                  <i
                    class="el-icon-plus voltage-plus"
                    @click="onVoltagePlus1(index)"
                  ></i>

                  <el-slider
                    v-model="item.value"
                    vertical
                    :step="step"
                    :min="min"
                    :max="max"
                    disabled
                    height="150px"
                  >
                  </el-slider>

                  <i
                    class="el-icon-minus voltage-reduce"
                    @click="onVoltageReduce1(index)"
                  ></i>
                </div>
              </div>

              <div class="voltage-three">
                <div class="voltage-number" v-for="index of 8" :key="index">
                  {{ index }}s
                </div>
              </div>
            </div>
            <div v-if="activeIndex == 2">
              <div class="voltage-one">
                <div
                  class="voltage-number"
                  v-for="(item, index) in temperatureList2"
                  :key="index"
                >
                  {{
                    parseFloat(
                      (
                        Math.pow(item.value, 2) / parseFloat(resistance)
                      ).toFixed(1)
                    )
                  }}
                  w
                  <!-- {{ item.value }} v -->
                </div>
              </div>

              <div class="voltage-two" style="width: 100%">
                <div
                  class="voltage-list"
                  v-for="(item, index) in temperatureList2"
                  :key="index"
                >
                  <i
                    class="el-icon-plus voltage-plus"
                    @click="onVoltagePlus2(index)"
                  ></i>

                  <el-slider
                    v-model="item.value"
                    vertical
                    :step="step"
                    :min="min"
                    :max="max"
                    disabled
                    height="150px"
                  >
                  </el-slider>

                  <i
                    class="el-icon-minus voltage-reduce"
                    @click="onVoltageReduce2(index)"
                  ></i>
                </div>
              </div>

              <div class="voltage-three">
                <div class="voltage-number" v-for="index of 8" :key="index">
                  {{ index }}s
                </div>
              </div>
            </div>
            <p>
              The Voltage curve is between 0.4v-3.7v, after the 8th second, the
              same as the 8th second
            </p>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-form-group label-cols-lg="2">
      <b-button type="reset" class="custom-width" @click="$router.back()"
        >Return
      </b-button>
    </b-form-group>
  </Layout>
</template>
<style lang="scss" scoped>
.productDetailBorder {
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.productDetailRow {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}
.productDetailImageRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5px;
  height: 125px;
}

.modal-header {
  display: none;
}
.modal-footer {
  display: none;
}
.productDetailContent {
  margin-left: 40px;
  width: 400px;
}
.productDetailContentColor {
  color: #333333;
}
.productDetailColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.productInfo {
  width: 200px;
  text-align: right;
  color: #333333;
}

.productInfoContent {
  text-align: left;
  flex: 1;
  margin-left: 5px;
}
.pd {
  padding-bottom: 10px;
}

.overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  text-align: center;
  justify-content: center;
  align-items: center;
}

::v-deep .van-slider {
  margin: 10px auto;
}

.slider-button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ffffff;
}

.voltage-two {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .voltage-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;

    .voltage-plus {
      margin-bottom: 20px;
    }

    .voltage-reduce {
      margin-top: 16px;
    }
  }
}
.voltage-one {
  margin-top: 10px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
  }
}
.voltage-three {
  margin-top: 66px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: hsla(0, 19%, 58%, 0);
  }
}
.temperature {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-slider__button {
  border: 2px solid #86a1a9;
}
::v-deep .el-slider__bar {
  background: #86a1a9;
}
.buttonStyle {
  width: 173px;
  height: 51px;
  border: 1px solid #d3d3d3;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  font-family: "Arial Negreta", "Arial Normal", "Arial", sans-serif;
  background-color: rgba(2, 167, 240, 1);
  margin-left: 2px;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img, upload_multiple_file } from "@/api/upload";
import goback from "@/components/goback";
import FormRow from "@/components/FormRow.vue";
import ImageComponets from "@/components/brand/ImageComponets.vue";
import HistoryCurve from "@/components/HistoryCurve.vue";

/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    goback,
    FormRow,
    ImageComponets,
    HistoryCurve,
  },
  data() {
    return {
      page: {
        title: "Recommend Curves Details",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "Client&Brand",
          },
          {
            text: "Curves Mgmt",
          },
          {
            text: "Recommend Curves Details",
            active: true,
          },
        ],
      },
      btnList: ["Light", "Smooth", "Strong"],
      group: {},
      temperatureList: [],
      temperatureList1: [],
      temperatureList2: [],
      activeIndex: 0,
      min: 0.4,
      max: 3.7,
      step: 0.1,
      resistance: "1.2",
      types:{
        power:[]
      }
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    onReady: onReady,
    init() {
      if (this.$route.query.id) {
        this.selectCurveGroupById();
      }
    },
    selectCurveGroupById() {
      this.$api.Client.selectCurveGroupById({ id: this.$route.query.id })
        .then((res) => {
          if (res.success) {
            this.group = res.data;
            this.resistance = parseFloat(res.data.resistance);
            var temperatureList = [];
            var temperatureList1 = [];
            var temperatureList2 = [];
            res.data.addCurveVos[0].temperature.split(",").map((item) => {
              temperatureList.push({ value: item / 1000 });
            });
            res.data.addCurveVos[1].temperature.split(",").map((item) => {
              temperatureList1.push({ value: item / 1000 });
            });
            res.data.addCurveVos[2].temperature.split(",").map((item) => {
              temperatureList2.push({ value: item / 1000 });
            });
            this.temperatureList = temperatureList;
            this.temperatureList1 = temperatureList1;
            this.temperatureList2 = temperatureList2;
            this.power();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        })
        .catch((res) => {
          this.$bvToast.toast(res.message);
        });
    },
    clickBtn(index) {
      this.activeIndex = index;
      this.power();
    },
    power() {
      if (this.activeIndex == 0) {
        this.types.power = this.temperatureList.map((item) => {
          return parseFloat(
            (Math.pow(item.value, 2) / parseFloat(this.resistance)).toFixed(1)
          );
        });
      } else if (this.activeIndex == 1) {
        this.types.power = this.temperatureList1.map((item) => {
          return parseFloat(
            (Math.pow(item.value, 2) / parseFloat(this.resistance)).toFixed(1)
          );
        });
      } else {
        this.types.power = this.temperatureList2.map((item) => {
          return parseFloat(
            (Math.pow(item.value, 2) / parseFloat(this.resistance)).toFixed(1)
          );
        });
      }
    },
  },
};
</script>
