var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "vo-items-center" },
            [
              _c("b-col", { attrs: { lg: "4" } }, [
                _c(
                  "div",
                  { staticClass: "vo-row vo-items-center" },
                  [
                    _c("ReferenceBy", { attrs: { label: "REFINED BY" } }),
                    _c("b-input", {
                      staticStyle: { flex: "1", "margin-left": "10px" },
                      attrs: { placeholder: "Search Client info" },
                      model: {
                        value: _vm.search_condition.clientInfo,
                        callback: function($$v) {
                          _vm.$set(_vm.search_condition, "clientInfo", $$v)
                        },
                        expression: "search_condition.clientInfo"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "b-col",
                [
                  _c("b-form-select", {
                    attrs: { id: "oil-type", options: _vm.types.oilType },
                    model: {
                      value: _vm.search_condition.companyBusinessId,
                      callback: function($$v) {
                        _vm.$set(_vm.search_condition, "companyBusinessId", $$v)
                      },
                      expression: "search_condition.companyBusinessId"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("b-form-select", {
                    attrs: { id: "client-status", options: _vm.types.status },
                    model: {
                      value: _vm.search_condition.status,
                      callback: function($$v) {
                        _vm.$set(_vm.search_condition, "status", $$v)
                      },
                      expression: "search_condition.status"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "country-id",
                      options: _vm.types.countryList,
                      "value-field": "id",
                      "text-field": "name"
                    },
                    on: {
                      change: function($event) {
                        return _vm.selectAddress(_vm.search_condition.countryId)
                      }
                    },
                    model: {
                      value: _vm.search_condition.countryId,
                      callback: function($$v) {
                        _vm.$set(_vm.search_condition, "countryId", $$v)
                      },
                      expression: "search_condition.countryId"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "state-id",
                      options: _vm.types.stateList,
                      "value-field": "id",
                      "text-field": "name"
                    },
                    model: {
                      value: _vm.search_condition.stateId,
                      callback: function($$v) {
                        _vm.$set(_vm.search_condition, "stateId", $$v)
                      },
                      expression: "search_condition.stateId"
                    }
                  })
                ],
                1
              ),
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "vo-row vo-items-center vo-justify-between" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "custom-color cursor",
                        on: { click: _vm.clearAll }
                      },
                      [_vm._v("Clear All")]
                    ),
                    _c(
                      "b-button",
                      { attrs: { variant: "info" }, on: { click: _vm.search } },
                      [_vm._v("Search")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box-list" },
            [
              _c(
                "b-button-group",
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "clientMgmt::Create",
                          expression: "'clientMgmt::Create'"
                        }
                      ],
                      attrs: { variant: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.create()
                        }
                      }
                    },
                    [_vm._v("+Create Client")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "table-responsive mb-0" },
                [
                  _c("b-table", {
                    ref: "table",
                    attrs: {
                      "show-empty": "",
                      "empty-text": "No records",
                      id: "table",
                      "head-variant": "light",
                      busy: _vm.isBusy,
                      striped: true,
                      items: _vm.provider,
                      fields: _vm.fields,
                      "per-page": _vm.search_condition.pageSize,
                      "current-page": _vm.search_condition.pageNum,
                      "sort-by": _vm.sortBy,
                      "sort-desc": _vm.sortDesc,
                      filter: _vm.filter,
                      "filter-includedfields": _vm.filterOn
                    },
                    on: {
                      "update:sortBy": function($event) {
                        _vm.sortBy = $event
                      },
                      "update:sort-by": function($event) {
                        _vm.sortBy = $event
                      },
                      "update:sortDesc": function($event) {
                        _vm.sortDesc = $event
                      },
                      "update:sort-desc": function($event) {
                        _vm.sortDesc = $event
                      },
                      filtered: _vm.onFiltered
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "table-busy",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-center text-danger my-2" },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle"
                                }),
                                _c("strong", [_vm._v("Loading...")])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "cell(index)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.index +
                                    (_vm.search_condition.pageNum - 1) *
                                      _vm.search_condition.pageSize +
                                    1
                                ) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(picture)",
                        fn: function(data) {
                          return [
                            _c("img", {
                              staticClass: "avatar-xs rounded-circle mr-2",
                              attrs: { src: "" + data.item.picture, alt: "" }
                            })
                          ]
                        }
                      },
                      {
                        key: "cell(actions)",
                        fn: function(row) {
                          return [
                            _c(
                              "b-button-group",
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "has",
                                        rawName: "v-has",
                                        value: "clientMgmt::Details",
                                        expression: "'clientMgmt::Details'"
                                      }
                                    ],
                                    staticClass: "mr-1",
                                    attrs: { size: "sm" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onClickDetails(
                                          row.item,
                                          row.index,
                                          $event.target
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("View")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.item.approvalStatus == 0,
                                        expression:
                                          "row.item.approvalStatus == 0"
                                      },
                                      {
                                        name: "has",
                                        rawName: "v-has",
                                        value: "clientMgmt::Verify",
                                        expression: "'clientMgmt::Verify'"
                                      }
                                    ],
                                    staticClass: "mr-1",
                                    attrs: { size: "sm", variant: "success" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onClickVerify(
                                          row.item,
                                          row.index,
                                          $event.target
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Verify")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "has",
                                        rawName: "v-has",
                                        value: "clientMgmt::Modify",
                                        expression: "'clientMgmt::Modify'"
                                      }
                                    ],
                                    staticClass: "mr-1",
                                    attrs: { size: "sm", variant: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.show(
                                          row.item,
                                          row.index,
                                          $event.target
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Modify")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          row.item.approvalStatus == 1 &&
                                          row.item.status == 0,
                                        expression:
                                          "row.item.approvalStatus == 1 && row.item.status == 0"
                                      },
                                      {
                                        name: "has",
                                        rawName: "v-has",
                                        value: "clientMgmt::Disable",
                                        expression: "'clientMgmt::Disable'"
                                      }
                                    ],
                                    staticClass: "mr-1",
                                    attrs: { size: "sm", variant: "secondary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickStatus(row.item)
                                      }
                                    }
                                  },
                                  [_vm._v("Disable")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          row.item.approvalStatus == 1 &&
                                          row.item.status == 1,
                                        expression:
                                          "row.item.approvalStatus == 1 && row.item.status == 1"
                                      },
                                      {
                                        name: "has",
                                        rawName: "v-has",
                                        value: "clientMgmt::Disable",
                                        expression: "'clientMgmt::Disable'"
                                      }
                                    ],
                                    staticClass: "mr-1",
                                    attrs: { size: "sm", variant: "success" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickStatus(row.item)
                                      }
                                    }
                                  },
                                  [_vm._v("Enable")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(showStatus)",
                        fn: function(row) {
                          return [
                            row.item.status == 1
                              ? _c("p", { staticClass: "clientStyle" }, [
                                  _vm._v("Disable")
                                ])
                              : _vm._e(),
                            row.item.status == 0
                              ? _c("p", [_vm._v("Enable")])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  {
                    staticClass:
                      "dataTables_paginate paging_simple_numbers float-right"
                  },
                  [
                    _vm.rows != 0
                      ? _c(
                          "ul",
                          { staticClass: "pagination mb-0" },
                          [
                            _c("li", [
                              _c(
                                "p",
                                {
                                  staticClass: "pagination mb-0",
                                  staticStyle: {
                                    "margin-top": "7px",
                                    "margin-right": "20px"
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.rows) + " results ")]
                              )
                            ]),
                            _c("b-pagination", {
                              attrs: {
                                "per-page": 10,
                                "total-rows": _vm.rows,
                                "aria-controls": "table"
                              },
                              model: {
                                value: _vm.search_condition.pageNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.search_condition, "pageNum", $$v)
                                },
                                expression: "search_condition.pageNum"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            title: "Verify",
            "hide-footer": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: { hidden: _vm.resetModal },
          model: {
            value: _vm.verifyModal,
            callback: function($$v) {
              _vm.verifyModal = $$v
            },
            expression: "verifyModal"
          }
        },
        [
          _c(
            "form",
            { ref: "form", on: { submit: _vm.onSure, reset: _vm.onClose } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Verify Status:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "verify-status",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticStyle: {
                        "margin-top": "5px",
                        "user-select": "none"
                      },
                      attrs: {
                        id: "verify-status",
                        name: "status",
                        value: 1,
                        "unchecked-value": 2,
                        switch: "",
                        size: "lg"
                      },
                      model: {
                        value: _vm.modalData.approvalStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.modalData, "approvalStatus", $$v)
                        },
                        expression: "modalData.approvalStatus"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.modalData.approvalStatus == 1
                              ? "Pass"
                              : "Not Pass"
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.modalData.approvalStatus == 1
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Brand Name:",
                        "label-cols-lg": "3",
                        "label-align-lg": "right",
                        "label-for": "brand-name",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "brand-name",
                          trim: "",
                          required: "",
                          placeholder: "Please enter",
                          type: "text",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 20)value = value.slice(0, 20)"
                        },
                        model: {
                          value: _vm.modalData.brandName,
                          callback: function($$v) {
                            _vm.$set(_vm.modalData, "brandName", $$v)
                          },
                          expression: "modalData.brandName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.modalData.approvalStatus == 1
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Client Type:",
                        "label-cols-lg": "3",
                        "label-align-lg": "right",
                        "label-for": "client-type",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "client-type",
                          options: _vm.types.clientTypeList,
                          "value-field": "value",
                          "text-field": "text",
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('')"
                        },
                        model: {
                          value: _vm.modalData.roleId,
                          callback: function($$v) {
                            _vm.$set(_vm.modalData, "roleId", $$v)
                          },
                          expression: "modalData.roleId"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Notes:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "remarks"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "remarks",
                      placeholder: "Enter Notes",
                      rows: "3",
                      "max-rows": "6"
                    },
                    model: {
                      value: _vm.modalData.approvalRemarks,
                      callback: function($$v) {
                        _vm.$set(_vm.modalData, "approvalRemarks", $$v)
                      },
                      expression: "modalData.approvalRemarks"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "4" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-l-5 ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "Dialog",
        {
          attrs: {
            imageSrc: require("@/assets/confirm.svg"),
            okBtn: "Yes",
            noBtn: "No"
          },
          on: { ok: _vm.disabledOrEnbleClient, cancel: _vm.cancel },
          model: {
            value: _vm.showAlert,
            callback: function($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert"
          }
        },
        [_c("p", [_vm._v(_vm._s(_vm.showContent))])]
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/success.png"), noBtn: "OK" },
          on: { cancel: _vm.cancel1 },
          model: {
            value: _vm.successAlear,
            callback: function($$v) {
              _vm.successAlear = $$v
            },
            expression: "successAlear"
          }
        },
        [_c("p", [_vm._v("Saved successfully")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }