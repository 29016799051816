<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row class="vo-items-center">
        <b-col lg="4">
          <div class="vo-row vo-items-center">
            <ReferenceBy label="REFINED BY" />
            <b-input
              style="flex: 1; margin-left: 10px"
              v-model="search_condition.smokeRecordNumber"
              placeholder="Omni ID"
            />
          </div>
        </b-col>
        <b-col lg="3" class="customCol">
          <b-form-select
            id="oil-type"
            v-model="search_condition.status"
            :options="types.status"
          ></b-form-select>
        </b-col>

        <b-col>
          <div class="vo-row vo-items-center vo-justify-between">
            <div class="custom-color cursor" @click="clearAll">Clear All</div>
            <b-button variant="info" @click="search">Search</b-button>
          </div>
        </b-col>
      </b-row>
      <div class="table-responsive mb-0">
        <b-table
          id="table"
          show-empty
          empty-text="No records"
          head-variant="light"
          ref="table"
          :busy="isBusy"
          :striped="true"
          :items="provider"
          :fields="this.$store.getters.isUnicoreus ? fields : fieldsIkrusher"
          :per-page="search_condition.pageSize"
          :current-page="search_condition.pageNum"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-includedfields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template v-slot:cell(index)="data">{{
            data.index +
            (search_condition.pageNum - 1) * search_condition.pageSize +
            1
          }}</template>

          <template v-slot:cell(actions)="row">
            <b-button-group>
              <b-button
                size="sm"
                variant="info"
                @click="show(row.item, row.index, $event.target)"
                class="mr-1"
                >Details</b-button
              >
            </b-button-group>
          </template>

          <template v-slot:cell(status)="row">
            <p v-if="row.item.status == 2" class="clientStyle">Unactivated</p>
            <p v-if="row.item.status == 1">Activated</p>
          </template>
        </b-table>
      </div>
      <!-- end table -->
      <br />
      <!-- pagination -->
      <b-row v-if="has_data">
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="rows != 0">
              <b-pagination
                v-model="search_condition.pageNum"
                :per-page="10"
                :total-rows="rows"
                aria-controls="table"
              ></b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import { mapGetters } from "vuex";
import ReferenceBy from "@/components/FindByLabel.vue";

/**
 * cartridge component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    timeZoneConversion,
    ReferenceBy,
  },
  data() {
    return {
      tableData: [],
      title: "Omni ID List",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Production Batch",
          active: true,
        },
        {
          text: "Omni ID List",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "smokeRecordNumber",
      sortDesc: false,
      fields: [
        { key: "index", label: "#" },
        { key: "smokeRecordNumber", label: "Product Id" },
        { key: "batchName", label: "Batch Name" },
        { key: "type", label: "Flavor Type" },
        {
          key: "status",
          label: "Product Status",
          formatter: (value) => {
            let a = "";
            this.types.status.filter((item) => {
              if (value === item.value) {
                a = item.text;
              }
            });
            return a;
          },
        },
        {
          key: "createTime",
          label: "Create Time",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return timeZoneConversion.getLocalTime(value);
            }
          },
        },
        {
          key: "activationTime",
          label: "Activated Time",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return timeZoneConversion.getLocalTime(value);
            }
          },
        },
        { key: "brandName", label: "Brand Owner" },
        { key: "dose", label: "Capacity(ml)" },
        { key: "flavor", label: "Flavor" },
        { key: "placeOfOrigin", label: "Place Of Origin" },
        { key: "actions", label: "Opreration" },
      ],
      fieldsIkrusher: [
        { key: "index", label: "#" },
        { key: "smokeRecordNumber", label: "Omni ID" },
        { key: "deviceName", label: "iK Product Models#/iK Marketing Name" },
        { key: "number", label: "Batch#" },
        { key: "batchName", label: "Batch Name" },
        {
          key: "status",
          label: "Status",
          formatter: (value) => {
            let a = "";
            this.types.status.filter((item) => {
              if (value === item.value) {
                a = item.text;
              }
            });
            return a;
          },
        },
        {
          key: "activationTime",
          label: "Activated Time",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return timeZoneConversion.getLocalTime(value);
            }
          },
        },
        { key: "actions", label: "Opreration" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        supplierId: localStorage.supplierId,
        smokeRecordNumber: "",
        orderId: this.$route.query.id,
        status: "",
      },
      selectTime: ["", ""],
      isBusy: false,
      modalShow: false,
      types: {
        // 使用状态(0是未连接 1是连接中 2是未激活)
        status: [
          { text: "All Status", value: "", disabled: false },
          { text: "Activated", value: 1, disabled: false },
          { text: "Unactivated", value: 2, disabled: false },
        ],
      },
      has_data: false,
    };
  },
  created() {
    this.init();
  },
  watch: {
    selectTime: function (val) {
      this.search_condition.startTime = val[0]
        ? timeZoneConversion.queryTime(val[0])
        : val[0];
      if (val[1]) {
        this.search_condition.endTime = timeZoneConversion.queryTime(
          val[1].getTime() + 24 * 3600 * 1000 - 1
        );
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {
    ...mapGetters(["isIkrusher", "isUnicoreus"]),
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  methods: {
    init() {
      let id = this.$route.query.id;
      this.search_condition.orderId = id;
    },
    provider(ctx) {
      this.isBusy = true;
      return this.$api.Cartridge.list(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.search_condition.pageNum = 1;
    },
    show(item, index, button) {
      if(this.isAccountLevel()){
        this.$router.push("productionBatchMgmtOmniIDInfo?id=" + item.id);
      }else{
        this.$router.push("brandProductionBatchMgmtOmniIDInfo?id=" + item.id);
      }
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    clearAll() {
      (this.search_condition = {
        pageNum: 1,
        pageSize: 10,
        supplierId: localStorage.supplierId,
        smokeRecordNumber: "",
        orderId: this.$route.query.id,
        status: "",
      }),
        this.$refs.table.refresh();
    },
    //账户层级
    isAccountLevel() {
      return localStorage.accountLevel == 0;
    },
  },
};
</script>
