<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<b-card>
			<b-form @submit="onSubmit" @reset="onReset" v-if="show">
				<!-- Coupon name 优惠卷名称-->
				<b-form-group
					label="Coupon Name:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="coupon-name"
					label-class="requiredRow"
					description="Coupon name, displayed on the coupon name"
				>
					<b-form-input
						id="coupon-name"
						v-model="form.couponName"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
						placeholder="Please enter"
					>
					</b-form-input>
				</b-form-group>
				<!-- Coupon Type 优惠券类型 -->
				<b-form-group
					label="Coupon Type:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="coupon-type"
					label-class="requiredRow"
				>
					<b-form-select
						id="coupon-type"
						v-model="form.couponType"
						:options="types.couponType"
						value-field="value"
						text-field="text"
						required
						oninvalid="setCustomValidity('Please select');"
						oninput="setCustomValidity('')"
					></b-form-select>
				</b-form-group>

				<!-- Available Amount 可使用金额-->
				<b-form-group
					v-if="form.couponType == 0"
					label="Available Amount:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="available-amount"
					label-class="requiredRow"
					description="Threshold amount"
				>
					<b-form-input
						id="available-amount"
						v-model="form.thresholdPrice"
						required
						type="number"
						min="0"
						step="0.01"
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(parseFloat(value) > 100000)value = 100000;value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
						placeholder="Please enter"
						:state="thresholdPriceState"
						aria-describedby="input-threshold-feedback"
					></b-form-input>
					<b-form-invalid-feedback id="input-threshold-feedback">
						The available amount cannot be lower than the discounted
						price.
					</b-form-invalid-feedback>
				</b-form-group>

				<!-- Discounted Price 优惠金额 -->
				<b-form-group
					v-if="form.couponType == 0 || form.couponType == 2"
					label="Discounted Price:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="discounted-Price"
					label-class="requiredRow"
					description="Coupon discount amount"
				>
					<b-form-input
						id="available-amount"
						v-model="form.discountedPrice"
						required
						type="number"
						min="0"
						step="0.01"
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(parseFloat(value) > 100000)value = 100000;value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
						placeholder="Please enter"
					></b-form-input>
				</b-form-group>

				<!-- Scope Of Use 可使用范围 -->
				<b-form-group
					label="Scope Of Use:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="scope-of-use"
					label-class="requiredRow"
				>
					<b-form-select
						id="scope-of-use"
						v-model="form.scopeOfUse"
						:options="types.useRange"
						value-field="value"
						text-field="text"
						required
						oninvalid="setCustomValidity('Please select');"
						oninput="setCustomValidity('')"
					></b-form-select>
				</b-form-group>
				<!-- 指定商品 -->
				<b-form-group
					v-if="form.scopeOfUse === 2"
					label="Use Goods:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="use-goods"
					label-class="requiredRow"
				>
					<div class="model-choose">
						<b-form-select
							id="scope-goods"
							v-model="selectModel"
							:options="types.modelList"
							value-field="id"
							text-field="modelName"
							required
							oninvalid="setCustomValidity('Please select');"
							oninput="setCustomValidity('')"
							style="width:30%;"
						></b-form-select>
						<b-button
							style="margin-left:20px;"
							variant="primary"
							:disabled="selectModel == ''"
							@click="onClickAddModel"
						>
							Add
						</b-button>
						<div style="margin-left:20px;">
							<span style="color:red;margin-right:4px;">*</span
							>Once the coupon is issued, it cannot be modified
						</div>
					</div>
					<b-table
						show-empty
						empty-text="No records"
						id="table"
						head-variant="light"
						ref="table"
						:striped="true"
						:items="selectModelList"
						:fields="fields"
						style="width:61%;"
					>
						<template v-slot:cell(actions)="row">
							<b-button-group>
								<b-button
									size="sm"
									variant="danger"
									@click="
										onClickDelete(
											row.item,
											row.index,
											$event.target
										)
									"
									class="mr-1"
									>Delete</b-button
								>
							</b-button-group>
						</template>
					</b-table>
				</b-form-group>
				<!-- 显示指定商品列表 -->
				<!-- 可删除 -->

				<!-- Receiving Source 获取方式 -->
				<b-form-group
					label="Acquisition Method:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="get-mode"
					label-class="requiredRow"
				>
					<b-form-select
						id="get-mode"
						v-model="form.methodOfObtaining"
						:options="types.getModeList"
						value-field="value"
						text-field="text"
						required
						oninvalid="setCustomValidity('Please select');"
						oninput="setCustomValidity('')"
					></b-form-select>
				</b-form-group>

				<!-- Issuance Quantity 发放数 -->
				<b-form-group
					label="Issuance Quantity:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="issuance-quantity"
					label-class="requiredRow"
				>
					<b-form-input
						id="issuance-quantity"
						v-model="form.issuanceQuantity"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50);value=value.replace(/[^\d]/g,'')"
						placeholder="Please enter"
						:disabled="form.issuanceType == 1"
					></b-form-input>
					<b-form-checkbox
						id="checkbox-1"
						v-model="form.issuanceType"
						name="checkbox-1"
						unchecked-value="0"
						value="1"
						@change="onChangeIssuanceQuantity"
					>
						Unlimited
					</b-form-checkbox>
				</b-form-group>

				<!-- Available Quantity 可领取数 -->
				<b-form-group
					label="Available Quantity:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="available-quantity"
					label-class="requiredRow"
					description="Maximum number of coupons per user"
				>
					<b-form-select
						id="limited-availableType"
						v-if="form.availableType == 0"
						v-model="form.limitedAvailableType"
						:options="types.limitedAvailableTypeList"
						value-field="value"
						text-field="text"
						required
						oninvalid="setCustomValidity('Please select');"
						oninput="setCustomValidity('')"
					></b-form-select>
					<b-form-input
						id="available-quantity"
						v-model="form.availableQuantity"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50);value=value.replace(/[^\d]/g,'')"
						placeholder="Please enter"
						:disabled="form.availableType == 1"
						:state="availableQuantityState"
						aria-describedby="input-available-feedback"
					></b-form-input>
					<b-form-invalid-feedback id="input-available-feedback">
						The available quantity cannot be greater than the issued
						quantity.
					</b-form-invalid-feedback>
					<b-form-checkbox
						id="checkbox-2"
						v-model="form.availableType"
						name="checkbox-2"
						unchecked-value="0"
						value="1"
						@change="onChangeAvailableQuantity"
					>
						Unlimited
					</b-form-checkbox>
				</b-form-group>

				<!-- All limit Per day 单日发放设置 -->
				<b-form-group
					label="All limit Per day:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="day-issuance-quantity"
					label-class="requiredRow"
				>
					<b-form-input
						id="day-issuance-quantity"
						v-model="form.dayIssuanceQuantity"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50);value=value.replace(/[^\d]/g,'')"
						placeholder="Please enter"
						:disabled="form.dayIssuanceType == 1"
					></b-form-input>
					<b-form-checkbox
						id="checkbox-3"
						v-model="form.dayIssuanceType"
						name="checkbox-3"
						unchecked-value="0"
						value="1"
						@change="onChangeDayIssuanceQuantity"
					>
						Unlimited
					</b-form-checkbox>
				</b-form-group>

				<!-- Release Time 发放时间 -->
				<b-form-group
					label="Release Time:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="release-time"
					label-class="requiredRow"
				>
					<date-picker
						v-model="form.releaseTime"
						format="MM/DD/YYYY HH:mm:ss"
						range
						append-to-body
						lang="en"
						placeholder="Select date"
						type="datetime"
						:disabled-date="notBeforeToday"
						@change="onChangeReleaseTime"
					></date-picker>
				</b-form-group>

				<!-- typeOfUse 使用类型 -->
				<b-form-group
					label="Time Type:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="time-type"
					label-class="requiredRow"
				>
					<b-form-radio
						class="custom-control-inline pt-2"
						v-model="form.typeOfUse"
						name="absoluteTime"
						value="0"
						>Absolute Time</b-form-radio
					>
					<b-form-radio
						class="custom-control-inline pt-2"
						v-model="form.typeOfUse"
						name="relativeTime"
						value="1"
						>Relative Time</b-form-radio
					>
				</b-form-group>

				<!-- Usage Time 使用时间 -->
				<b-form-group
					v-if="form.typeOfUse == 0"
					label="Usage Start Time:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="usage-time"
					label-class="requiredRow"
				>
					<date-picker
						v-model="form.startTime"
						format="MM/DD/YYYY HH:mm:ss"
						append-to-body
						lang="en"
						placeholder="Select date"
						type="datetime"
						:disabled-date="notBeforeUsageStartToday"
						:disabled-time="notBeforeUsageStartTime"
					></date-picker>
				</b-form-group>
				<b-form-group
					v-if="form.typeOfUse == 0"
					label="Usage End Time:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="usage-time"
					label-class="requiredRow"
				>
					<date-picker
						v-model="form.endTime"
						format="MM/DD/YYYY HH:mm:ss"
						append-to-body
						lang="en"
						placeholder="Select date"
						type="datetime"
						:disabled-date="notBeforeUsageEndToday"
						:disabled-time="notBeforeUsageEndTime"
					></date-picker>
				</b-form-group>
				<b-form-group
					v-if="form.typeOfUse == 1"
					label="Usage Time:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="usage-time"
					label-class="requiredRow"
					description="Effective days after receipt, not more than 90 days"
				>
					<b-form-input
						v-if="form.typeOfUse == 1"
						id="days"
						v-model="form.days"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value > 90)value = 90;value=value.replace(/[^\d]/g,'')"
						placeholder="Please enter"
					></b-form-input>
				</b-form-group>

				<!-- Illustrate 使用说明 -->
				<b-form-group
					label="Illustrate:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="illustrate"
				>
					<b-form-textarea
						id="illustrate"
						v-model="form.illustrate"
						placeholder="Enter illustrate"
						rows="3"
						max-rows="6"
					></b-form-textarea>
				</b-form-group>

				<b-form-group label-cols-lg="2">
					<b-button type="submit" variant="primary">Confirm</b-button>
					<b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
				</b-form-group>
			</b-form>
			<!-- <p>{{form}}</p> -->
		</b-card>
	</Layout>
</template>
<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Multiselect from 'vue-multiselect'

import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import DatePicker from 'vue2-datepicker'

import { onReady, upload_img } from '@/api/upload'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'

/**
 * Cartridge Model Add
 */
export default {
	components: {
		ckeditor: CKEditor.component,
		Layout,
		PageHeader,
		Multiselect,
		DatePicker,
		timeZoneConversion,
	},
	data() {
		return {
			title: this.editId ? 'Coupon Modify' : 'Coupon Create',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'Marketing Manage',
					// href: "/"
				},
				{
					text: this.editId ? 'Coupon Modify' : 'Coupon Create',
					active: true,
				},
			],
			form: {
				couponName: '',
				couponType: '', //优惠券类型(0是满减 1是折扣 2无门槛 3是兑换卷)
				thresholdPrice: '', //可使用金额
				discountedPrice: '', //优惠金额
				scopeOfUse: '', //可使用范围(0是全部商品 1是指定分类 2是指定商品)
				scopeOfUseId: '', //可使用范围ID （分类/商品ID）
				methodOfObtaining: '', //获取方式 0自动发放 1链接获取
				issuanceQuantity: '', //发放数量
				issuanceType: 0, //发送类型 0有限 1无限
				availableType: 0, //领取类型 0有限 1无限
				limitedAvailableType: '', //1是日可领 2是周可领 3是月可领 4是没有时间限制
				availableQuantity: '', //可领取数量
				dayIssuanceType: '', //单日发放限制 0有限 1无限
				dayIssuanceQuantity: '', //单日发放限制数量
				releaseTime: [], //发放时间
				issuanceStartTime: '', //发放开始时间
				issuanceEndTime: '', //发放结束时间
				typeOfUse: 0, //0绝对时间 1相对时间
				usageTime: [], //使用时间(显示用)
				startTime: '', //使用开始时间：相对时间
				endTime: '', //使用结束时间：相对时间
				days: '', //使用天数：绝对天数
				illustrate: '', //优惠券使用说明

				supplierId: localStorage.supplierId,
			},
			types: {
				statuss: [
					{ text: 'true', value: 1, disabled: false },
					{ text: 'false', value: 0, disabled: false },
				],
				//优惠券使用范围
				useRange: [
					{
						text: 'Please select',
						value: '',
					},
					{
						text: 'All Products',
						value: 0,
					},
					// {
					// 	text: 'Specify classification', //指定分类
					// 	value: 1,
					// },
					{
						text: 'Designated Goods', //指定商品
						value: 2,
					},
					// {
					// 	text: 'Activity distribution', //活动发放
					// 	value: 3,
					// },
				],
				couponType: [
					{
						text: 'Please select',
						value: '',
					},
					{
						text: 'Rebate coupon', //满减券
						value: 0,
					},
					// {
					//   text: 'Discount voucher', //折扣券
					//   value: 1,
					// },
					// {
					//   text: 'Unconditional coupon', //无门槛券
					//   value: 2,
					// },
					{
						text: 'Exchange coupon', //兑换券
						value: 3,
					},
				],
				//获取方式
				getModeList: [
					{
						text: 'Please select',
						value: '',
					},
					{
						text: 'Automatic distribution', //自动发送
						value: 0,
					},
					{
						text: 'Link acquisition', //链接领取
						value: 1,
					},
					{
						text: 'Activity collection', //活动领取
						value: 2,
					},
				],
				//领取类型
				limitedAvailableTypeList: [
					{
						text: 'Please select',
						value: '',
					},
					{
						text: 'Every day', //每日
						value: 1,
					},
					{
						text: ' Every week', //每周
						value: 2,
					},
					{
						text: 'Every month', //每月
						value: 3,
					},
					{
						text: 'There is no time limit', //不限
						value: 4,
					},
				],
				//商品列表
				modelList: [
					{
						modelName: 'Please select',
						id: '',
					},
				],
			},

			selectModel: '', //选中的商品
			selectModelList: [], //选中的商品集合
			fields: [
				{
					key: 'modelName', //商品名称
					label: 'Goods Name',
					class: 'text-left',
				},

				{
					key: 'retailPrice', //销售价格
					label: 'Selling Price',
					class: 'text-right',
					formatter: (value) => {
						return '$' + value
					},
				},

				{ key: 'actions', label: 'Opreration', class: 'text-right' },
			],

			show: true, // 表单显示
			editor: ClassicEditor,
			showReportLink: false,
			showReportEnclosure: false,
			editId: window.location.search,
		}
	},
	watch: {},
	computed: {
		thresholdPriceState() {
			if (
				this.form.thresholdPrice == '' ||
				this.form.discountedPrice == ''
			) {
				return null
			} else if (
				parseFloat(this.form.thresholdPrice) >
				parseFloat(this.form.discountedPrice)
			) {
				return true
			} else {
				return false
			}
		},
		availableQuantityState() {
			if (
				this.form.availableQuantity == '' ||
				this.form.issuanceType == 1
			) {
				return null
			} else if (
				parseInt(this.form.availableQuantity) >
				parseInt(this.form.issuanceQuantity)
			) {
				return false
			} else {
				true
			}
		},
		usageTimeState() {},
	},
	beforeCreate() {
		this.editId = window.location.search
	},
	created() {},
	async mounted() {
		await this.init()
		if (/edit/i.test(window.location.href)) {
			this.selectInfo()
		}
	},
	methods: {
		async init() {
			await this.$api.Dropdown.selectModelBySupplierId({
				supplierId: localStorage.supplierId,
			})
				.then((res) => {
					if (res.success) {
						this.types.modelList = this.types.modelList.concat(
							res.data
						)
					} else {
						this.$toast(res)
					}
				})
				.catch((error) => {
					this.$toast(res)
				})
		},
		selectInfo() {
			if (this.$route.query.id) {
				this.$api.Marketing.selectCouponCodeById(
					this.$route.query
				).then((res) => {
					if (res.success) {
						this.form = res.data
						this.$set(this.form, 'releaseTime', [
							new Date(res.data.issuanceStartTime),
							new Date(res.data.issuanceEndTime),
						])
						if (this.form.issuanceType == 1) {
							this.form.issuanceQuantity = ''
						}
						if (this.form.availableType == 1) {
							this.form.availableQuantity = ''
						}
						if (this.form.scopeOfUseId.length > 0) {
							this.types.modelList.forEach((item) => {
								this.form.scopeOfUseId.forEach((element) => {
									if (item.id === element)
										this.selectModelList.push(item)
								})
							})
							this.selectModel = this.form.scopeOfUseId[0]
						}
						this.form.startTime = new Date(this.form.startTime)
						this.form.endTime = new Date(this.form.endTime)
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
			} else {
				this.$bvToast.toast('id?')
			}
		},
		//禁止选择早于今天的时间
		notBeforeToday(date) {
			return date < new Date(new Date().setHours(0, 0, 0, 0))
		},
		notBeforeUsageStartToday(date) {
			return (
				date >
				new Date(new Date(this.form.endTime).setHours(0, 0, 0, 0))
			)
		},
		notBeforeUsageStartTime(date) {
			return date < new Date(new Date(this.form.startTime))
		},
		notBeforeUsageEndToday(date) {
			return (
				date <
				new Date(new Date(this.form.startTime).setHours(0, 0, 0, 0))
			)
		},
		notBeforeUsageEndTime(date) {
			return date < new Date(new Date(this.form.startTime))
		},
		onReady: onReady,
		onSubmit(evt) {
			evt.preventDefault()
			if (
				this.thresholdPriceState == false ||
				this.availableQuantityState == false
			) {
				return
			}

			//时间类型组件非空验证
			if (this.form.releaseTime.length <= 0) {
				this.$toastWarning('Please select Release Time.')
				return
			} else if (
				this.form.typeOfUse == 0 &&
				this.form.startTime.length <= 0
			) {
				this.$toastWarning('Please select Usage Start Time.')
				return
			} else if (
				this.form.typeOfUse == 0 &&
				this.form.endTime.length <= 0
			) {
				this.$toastWarning('Please select Usage End Time.')
				return
			}

			if (this.form.typeOfUse == 1) {
				this.form.usageTime = []
				this.form.startTime = ''
				this.form.endTime = ''
			} else if (this.form.typeOfUse == 0) {
				this.form.days = ''
			}
			this.form.days = parseInt(this.form.days)
			this.form.startTime = timeZoneConversion.queryTime(
				this.form.startTime
			)
			this.form.endTime = timeZoneConversion.queryTime(this.form.endTime)
			//构造使用范围参数
			if (this.selectModelList.length > 0) {
				this.selectModelList.forEach((element) => {
					this.form.scopeOfUseId += element.id + ','
				})
				this.form.scopeOfUseId = this.form.scopeOfUseId.substring(
					0,
					this.form.scopeOfUseId.length - 1
				)
			}
			if (this.form.scopeOfUse != 1 && this.form.scopeOfUse != 2) {
				this.selectModelList = []
				this.form.scopeOfUseId = ''
			}

			//转换\n
			this.form.illustrate = this.form.illustrate.replace(/\n/g, '<br>')

			if (/edit/i.test(window.location.href)) {
				this.$api.Marketing.saveOrUpdateCoupon(this.form).then(
					(res) => {
						if (res.success) {
							this.$toastBack(res)
						} else {
							if (res.message) {
								this.$toast(res)
							}
						}
					}
				)
			} else {
				this.$api.Marketing.saveOrUpdateCoupon(this.form).then(
					(res) => {
						if (res.success) {
							this.$toastBack(res)
						} else {
							if (res.message) {
								this.$toast(res)
							}
						}
					}
				)
			}
		},
		onReset(evt) {
			evt.preventDefault()
			;(this.form = {
				supplierId: localStorage.supplierId,
			}),
				(this.show = false)
			this.$nextTick(() => {
				// this.show = true;
				this.$router.go(-1)
			})
		},
		//发放时间
		onChangeReleaseTime(val) {
			this.form.issuanceStartTime = val[0]
				? timeZoneConversion.queryTime(val[0])
				: val[0]
			if (val[1]) {
				this.form.issuanceEndTime = timeZoneConversion.queryTime(val[1])
			} else {
				this.form.issuanceEndTime = val[1]
			}
		},
		onChangeIssuanceQuantity(value) {
			if (value == 1) {
				this.form.issuanceQuantity = ''
			}
		},
		onChangeDayIssuanceQuantity(value) {
			if (value == 1) {
				this.form.dayIssuanceQuantity = ''
			}
		},
		onChangeAvailableQuantity(value) {
			if (value == 1) {
				this.form.availableQuantity = ''
				this.form.limitedAvailableType = ''
			}
		},
		onClickAddModel() {
			let model = this.types.modelList.find(
				(item) => item.id === this.selectModel
			)
			if (this.selectModelList.find((item) => item.id === model.id)) {
				this.$toastWarning('The goods has been added.')
			} else {
				this.selectModelList.push(model)
			}
		},
		onClickDelete(item) {
			this.selectModelList.splice(this.selectModelList.indexOf(item), 1)
		},
	},
}
</script>

<style lang="scss" scoped>
.model-choose {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
}
</style>
