var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-form",
        {
          attrs: { action: "#" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.typeForm($event)
            },
            reset: _vm.reset_form
          }
        },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.typeform.title
                                        ? _vm.typeform.title.length
                                        : 0,
                                      max: 100
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Blog Title:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "blog-title",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "blog-title",
                                  placeholder: "Please enter",
                                  trim: "",
                                  required: "",
                                  maxlength: "100",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.typeform.title,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "title", $$v)
                                  },
                                  expression: "typeform.title"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.typeform.keywords
                                        ? _vm.typeform.keywords.length
                                        : 0,
                                      max: 100
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Blog Keywords:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "blog-keywords",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "blog-keywords",
                                  placeholder: "Please enter",
                                  trim: "",
                                  required: "",
                                  maxlength: "100",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.typeform.keywords,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "keywords", $$v)
                                  },
                                  expression: "typeform.keywords"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.typeform.description
                                        ? _vm.typeform.description.length
                                        : 0,
                                      max: 2000
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Blog Description:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "blog-description",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "blog-description",
                                  placeholder: "Please enter",
                                  trim: "",
                                  required: "",
                                  maxlength: "2000",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.typeform.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "description", $$v)
                                  },
                                  expression: "typeform.description"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Associated Types:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "Associated Types",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "custom-control-inline pt-2",
                                  attrs: { value: "1", required: "" },
                                  model: {
                                    value: _vm.typeform.relevanceBrandType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.typeform,
                                        "relevanceBrandType",
                                        $$v
                                      )
                                    },
                                    expression: "typeform.relevanceBrandType"
                                  }
                                },
                                [_vm._v("All Brand ")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "custom-control-inline pt-2",
                                  attrs: { value: "2", required: "" },
                                  model: {
                                    value: _vm.typeform.relevanceBrandType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.typeform,
                                        "relevanceBrandType",
                                        $$v
                                      )
                                    },
                                    expression: "typeform.relevanceBrandType"
                                  }
                                },
                                [_vm._v("Designated Brand ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.typeform.relevanceBrandType == 2
                        ? _c(
                            "FormRow",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Belong To Brand:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "blog-description",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    staticClass: "custom-placeholder",
                                    attrs: {
                                      options: _vm.types.brandOptions,
                                      label: "text",
                                      reduce: function(option) {
                                        return option.value
                                      },
                                      placeholder: "--All Brand--",
                                      multiple: ""
                                    },
                                    model: {
                                      value: _vm.typeform.brandId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.typeform, "brandId", $$v)
                                      },
                                      expression: "typeform.brandId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "FormRow",
                        [
                          _c(
                            "b-form-group",
                            {
                              ref: "businessType",
                              attrs: {
                                label: "Business Type:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "company-business",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-wrap" },
                                _vm._l(_vm.types.companyBusiness, function(
                                  label,
                                  index
                                ) {
                                  return _c(
                                    "b-form-checkbox",
                                    {
                                      key: index,
                                      staticClass: "mr-2 mt-2",
                                      attrs: {
                                        required: true,
                                        value: label.value
                                      },
                                      model: {
                                        value: _vm.typeform.businessTypeId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.typeform,
                                            "businessTypeId",
                                            $$v
                                          )
                                        },
                                        expression: "typeform.businessTypeId"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(label.text) + " ")]
                                  )
                                }),
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Blog text:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "pushContent",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("div", {
                            staticStyle: {
                              "max-width": "1260px",
                              position: "relative",
                              "z-index": "1"
                            },
                            attrs: { id: "wangeEditor" }
                          })
                        ]
                      ),
                      _c(
                        "FormRow",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Cover Picture:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "brand-logo",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("input", {
                                staticClass: "filestyle",
                                staticStyle: {
                                  position: "absolute",
                                  clip: "rect(0px, 0px, 0px, 0px)"
                                },
                                attrs: {
                                  type: "file",
                                  "data-input": "false",
                                  id: "filestyle-1",
                                  tabindex: "-1",
                                  required: !_vm.typeform.coverImage,
                                  oninvalid:
                                    "setCustomValidity('Please upload');",
                                  accept: "image/*",
                                  oninput: "setCustomValidity('');"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.upload($event)
                                  }
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "bootstrap-filestyle input-group"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "group-span-filestyle",
                                      attrs: { tabindex: "0" }
                                    },
                                    [
                                      _vm.typeform.coverImage
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "upload-img-wrap"
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "upload-img",
                                                  attrs: {
                                                    src: _vm.typeform.coverImage
                                                  }
                                                }),
                                                _c("img", {
                                                  staticClass: "close-img",
                                                  attrs: {
                                                    src: require("@/assets/images/brand/ic_close.png")
                                                  },
                                                  on: {
                                                    click: _vm.onRemoveBrandLogo
                                                  }
                                                })
                                              ]
                                            )
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "custom-width",
                                          attrs: { variant: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.uploadFile()
                                            }
                                          }
                                        },
                                        [_vm._v("upload picture")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          " Recommended size of picture: 120 pixels * 120 pixels, supporting JPG and PNG formats. "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Blog Status:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "Blog Status",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "custom-control-inline pt-2",
                                  attrs: { value: "1", required: "" },
                                  model: {
                                    value: _vm.typeform.startStatus,
                                    callback: function($$v) {
                                      _vm.$set(_vm.typeform, "startStatus", $$v)
                                    },
                                    expression: "typeform.startStatus"
                                  }
                                },
                                [_vm._v("Enable ")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "custom-control-inline pt-2",
                                  attrs: { value: "0", required: "" },
                                  model: {
                                    value: _vm.typeform.startStatus,
                                    callback: function($$v) {
                                      _vm.$set(_vm.typeform, "startStatus", $$v)
                                    },
                                    expression: "typeform.startStatus"
                                  }
                                },
                                [_vm._v("Disable ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { "label-cols-lg": "3" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "custom-width",
                  attrs: { type: "submit", variant: "primary" }
                },
                [_vm._v("Save")]
              ),
              _c(
                "b-button",
                { staticClass: "ml-4 custom-width", attrs: { type: "reset" } },
                [_vm._v("Cancel ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/success.png"), noBtn: "OK" },
          on: { cancel: _vm.cancel, hide: _vm.hide },
          model: {
            value: _vm.successAlear,
            callback: function($$v) {
              _vm.successAlear = $$v
            },
            expression: "successAlear"
          }
        },
        [_vm._v(" Saved successfully ")]
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/confirm.svg"), noBtn: "OK" },
          on: { cancel: _vm.cancel1, hide: _vm.hide1 },
          model: {
            value: _vm.errorAlear,
            callback: function($$v) {
              _vm.errorAlear = $$v
            },
            expression: "errorAlear"
          }
        },
        [_vm._v(" " + _vm._s(_vm.errorContent) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }