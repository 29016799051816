var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _vm.show
            ? _c(
                "b-form",
                { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Activity Name:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "activity-name",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "activity-name",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                          placeholder: "Please enter"
                        },
                        model: {
                          value: _vm.form.activityName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "activityName", $$v)
                          },
                          expression: "form.activityName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Activity Time:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "activity-time",
                        "label-class": "requiredRow",
                        description: "Activity time cannot overlap"
                      }
                    },
                    [
                      _c("date-picker", {
                        attrs: {
                          format: "MM/DD/YYYY HH:mm:ss",
                          range: "",
                          "append-to-body": "",
                          lang: "en",
                          placeholder: "Select date",
                          type: "datetime",
                          "disabled-date": _vm.notBeforeToday
                        },
                        on: { change: _vm.onChangeActivityTime },
                        model: {
                          value: _vm.form.activityTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "activityTime", $$v)
                          },
                          expression: "form.activityTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Invitees:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "prizes-for-invitees",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "prizes-for-invitees",
                          options: _vm.types.rewardList,
                          "value-field": "value",
                          "text-field": "text",
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('')",
                          disabled: _vm.form.activityTime.length <= 0
                        },
                        model: {
                          value: _vm.form.couponId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "couponId", $$v)
                          },
                          expression: "form.couponId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Page Description:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "page-description"
                      }
                    },
                    [
                      _c("div", {
                        staticStyle: { "max-width": "1310px" },
                        attrs: { id: "wangeEditor" }
                      })
                    ]
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { "label-cols-lg": "2" } },
                    [
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "primary" } },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "b-button",
                        { staticClass: "m-l-5 ml-1", attrs: { type: "reset" } },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }