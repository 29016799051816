<template>
	<Layout>
		<PageHeader :title="page.title" :items="page.items" />

		<b-card>
			<b-form @submit="onSubmit" @reset="onReset">
				<!-- 问题 -->
				<b-form-group
					label="Blog Title:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="title"
					label-class="requiredRow"
				>
					<b-form-input
						id="title"
						v-model="form.title"
						placeholder="Please enter"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 100)value = value.slice(0, 100)"
					></b-form-input>
					<span>Title is up to 100 characters long</span>
				</b-form-group>

				<!-- 问题分类 -->
				<b-form-group
					label="Blog Category:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="categoryId"
					label-class="requiredRow"
				>
					<b-form-select
						id="categoryId"
						v-model="form.categoryId"
						:options="types.questionCategorys"
						value-field="id"
						text-field="name"
						required
						oninvalid="setCustomValidity('Please select');"
						oninput="setCustomValidity('')"
					>
					</b-form-select>
				</b-form-group>

				<b-form-group
					label="Product/Batch Association:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="modelId"
					label-class="requiredRow"
				>
					<multiselect
						v-model="selectedModel"
						:options="types.modelIds"
						track-by="id"
						label="modelName"
						placeholder="Please select"
						:searchable="false"
						:close-on-select="false"
						:multiple="true"
						@input="selectModel"
						required
						oninvalid="setCustomValidity('Please select');"
						oninput="setCustomValidity('')"
					>
					</multiselect>
				</b-form-group>

				<!-- 排序 -->
				<b-form-group
					label="Display Priority:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="sort"
					label-class="requiredRow"
				>
					<b-form-input
						id="sort"
						v-model="form.sort"
						trim
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('')"
						placeholder="Please enter"
						type="number"
					></b-form-input>
				</b-form-group>

				<b-form-group
					label="Cover Picture:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="frontCover"
					label-class="requiredRow"
				>
					<input
						type="file"
						class="filestyle"
						data-input="false"
						id="filestyle-1"
						style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
						tabindex="-1"
						@change="upload($event)"
						:required="!form.frontCover"
						oninvalid="setCustomValidity('Please upload');"
						oninput="setCustomValidity('')"
					/>

					<div class="bootstrap-filestyle input-group">
						<div
							name="filedrag"
							style="position: absolute; width: 100%; height: 35px; z-index: -1"
						></div>
						<span class="group-span-filestyle" tabindex="0">
							<div
								v-if="form.frontCover"
								style="margin-bottom: 10px"
							>
								<img
									:src="form.frontCover"
									style="width: 100px; height: 100px; border: 2px dashed #ccc"
								/>
								<span
									style="margin-left: 10px; vertical-align: bottom"
									v-if="!editId"
								>
									<span style="font-weight: 500"
										>Picture name : </span
									>{{ photoName }}</span
								>
							</div>
							<label
								for="filestyle-1"
								style="margin-bottom: 0"
								class="btn btn-secondary"
							>
								<span class="buttonText"> upload picture </span>
							</label>
							<span>
								Recommended size of picture: 980 pixels * 450
								pixels, supporting JPG and PNG formats, within
								2m.
							</span>
						</span>
					</div>
				</b-form-group>

				<!-- 问题答案 -->
				<b-form-group
					label="Blog Type:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="answer"
					label-class="requiredRow"
				>
					<b-form-radio-group
						v-model="form.contentType"
						id="contentType"
						class="pt-2"
					>
						<b-form-radio value="0" @change="changeCustom"
							>Rich Text</b-form-radio
						>
						<b-form-radio value="1" @change="changeLink"
							>External links</b-form-radio
						>
					</b-form-radio-group>
				</b-form-group>

				<!-- 答案内容  -->
				<b-form-group
					v-show="showCustom"
					label="Blog Content:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="answerContent"
					label-class="requiredRow"
				>
					<!-- <ckeditor
            v-model="form.content"
            :editor="editor"
            @ready="onReady"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('')"
          ></ckeditor> -->
					<div id="wangeEditor" style="max-width: 1310px"></div>
				</b-form-group>

				<!-- 链接地址 -->
				<b-form-group
					v-if="showLink"
					label="Link Address:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="Link"
					label-class="requiredRow"
				>
					<b-form-input
						id="Link"
						v-model="form.contentUrl"
						placeholder="Please Enter"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('')"
					></b-form-input>
				</b-form-group>

				<!-- 摘要描述 -->
				<b-form-group
					v-if="showLink"
					label="Abstract Notes"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="remarks"
				>
					<b-form-textarea
						id="remarks"
						v-model="form.abstractRemarks"
						placeholder="Enter comments"
						rows="3"
						max-rows="6"
					></b-form-textarea>
				</b-form-group>

				<b-form-group label-cols-lg="2">
					<b-button type="submit" variant="primary">Confirm</b-button>
					<b-button type="reset" variant="danger" class="m-l-5 ml-1"
						>Cancel</b-button
					>
				</b-form-group>
			</b-form>
		</b-card>
	</Layout>
</template>
<script>
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import baseUrl from '@/config/env.js'

import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Multiselect from 'vue-multiselect'
import E from 'wangeditor'
const editor = new E('#wangeEditor')

import { onReady, upload_img } from '@/api/upload'
/**
 * FAQ Add component
 */
export default {
	components: {
		Layout,
		PageHeader,
		ckeditor: CKEditor.component,
		Multiselect,
	},
	data() {
		return {
			page: {
				title: this.editId ? 'Community Modify' : 'Community Create',
				items: [
					{
						text: 'UNI-CORE',
						// href: "/"
					},
					{
						text: 'Content Mgmt',
						// href: "/"
					},
					{
						text: this.editId
							? 'Community Modify'
							: 'Community Create',
						active: true,
					},
				],
			},
			form: {
				sort: '',
				modelId: [],
				consultationModelList: [],
				categoryId: '',
				contentType: 0,
				content: '',
				contentUrlL: '',
				abstractRemarks: '',
				frontCover: '',
				title: '',
				supplierId: localStorage.supplierId,
			},
			showCustom: true,
			showLink: false,
			types: {
				questionCategorys: [{ id: '', name: 'Please select' }],
				modelIds: [],
			},
			editor: ClassicEditor,
			editId: window.location.search,
			photoName: '',
			selectedModel: [],
		}
	},
	beforeCreate() {
		this.editId = window.location.search
	},
	created() {
		if (this.editId) {
			let id = this.editId.split('=')[1]
			this.formData(id)
		}
	},
	mounted() {
		if (!this.editId) {
			this.init()
		}

		this.createEditor(editor)
	},
	methods: {
		onReady: onReady,
		init() {
			this.$api.Category.list().then((res) => {
				console.log({ Category: res })
				if (res.success) {
					this.types.questionCategorys = this.types.questionCategorys.concat(
						res.data
					)
				}
			})

			this.$api.Dropdown.selectModelAndPasteType({
				id: this.editId ? this.editId.split('=')[1] : '',
				supplierId: localStorage.supplierId,
			}).then((res) => {
				if (res.success) {
					res.data.forEach((element) => {
						this.form.consultationModelList.forEach((item) => {
							if (element.id == item.id) {
								this.selectedModel.push(element)
							}
						})
					})
					this.types.modelIds = res.data
				} else {
					if (res.message) {
						this.$toast(res)
					}
				}
			})
		},
		onSubmit(evt) {
			evt.preventDefault()
			if (this.form.id) {
				this.form.content = editor.txt.html()
				this.$api.medicalManage.updateById(this.form).then((res) => {
					if (res.success) {
						this.$toastBack(res)
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
			} else {
				//this.form.content = this.form.content.replace(/&lt;/g, "<");
				//this.form.content = this.form.content.replace(/&gt;/g, ">");
				//console.log(this.form.content);
				console.log(editor.txt.html())
				this.form.content = editor.txt.html()
				//return;
				this.$api.medicalManage
					.saveConsultation(this.form)
					.then((res) => {
						if (res.success) {
							this.$toastBack(res)
						} else {
							if (res.message) {
								this.$toast(res)
							}
						}
					})
			}
		},
		onReset(evt) {
			evt.preventDefault()
			this.$nextTick(() => {
				this.$router.go(-1)
			})
		},
		upload(e) {
			let file = e.target.files[0]
			this.photoName = file.name
			console.log(file)
			if (/image/.test(file.type)) {
				console.log('uploading', file)
				upload_img(file).then((res) => {
					if (res.message) {
						this.$toast(res)
					}
					if (res.success) {
						this.form.frontCover = res.data
						console.log('zzzzzzzz', this.form)
					}
				})
			} else {
				e.target.files = []
				this.$bvToast.toast('only support jpg/png file')
			}
		},
		formData(id) {
			this.$api.medicalManage.findById({ id: id }).then((res) => {
				if (res.success) {
					this.form = res.data
					if (this.form.contentType == 0) {
						this.showLink = false
						this.showCustom = true
					} else if (this.form.contentType == 1) {
						this.showLink = true
						this.showCustom = false
					}
					console.log(res.data.content)
					editor.txt.html(res.data.content)
					this.init()
				} else {
					if (res.message) {
						this.$toast(res)
					}
				}
			})
		},
		selectModel(val) {
			this.form.consultationModelList = val.map((item) => {
				return item
			})
			// this.form.modelId = val.map((item) => {
			//   return item.id;
			// });
		},
		changeCustom() {
			this.showLink = false
			this.showCustom = true
			this.form.contentUrl = ''
			this.form.abstractRemarks = ''

			//this.createEditor();
		},
		changeLink() {
			this.showLink = true
			this.showCustom = false
			this.form.content = ''
		},
		createEditor(object) {
			object.customConfig.lang = {
				设置标题: 'title',
				正文: 'p',
				链接文字: 'link text',
				链接: 'link',
				插入: 'insert ',
				视频: 'video',
				创建: 'init',
				图片链接: 'pictures linking',
				网络: 'internet',
				上传: 'upload ',
				图片: 'picture',
				默认: 'default',
				代码: 'code',
				格式如: 'format',
				新浪: 'sina',
				字号: 'Font size',
				字体: 'Font',
				设置列表: 'Settings list',
				有序列表: 'Ordered list',
				无序列表: 'Unordered list',
				插入: 'insert ',
				插入表格: 'insert table',
				编辑: 'edit ',
				最大宽度: 'maximum width',
				删除: 'delete ',
				行: 'row',
				列: 'column',
				的: ' ',
				表格: 'table',
				上传图片: 'Upload picture',
				网络图片: 'Network picture',
				插入表格: 'Insert table',
				插入视频: 'Insert video',
				插入代码: 'Insert code',
				文字颜色: 'Text color',
				背景色: 'Background color',
				对齐方式: 'Alignment',
				靠左: 'left',
				居中: 'Center',
				靠右: 'right',
				// 还可自定添加更多
			}

			object.customConfig.uploadImgServer =
				baseUrl + '/supplier/uploadFile'
			object.customConfig.uploadImgHeaders = {
				'supplier-token': window.localStorage.getItem('auth-token'),
			}
			object.customConfig.uploadFileName = 'file'

			object.customConfig.uploadImgHooks = {
				// 上传图片之前
				before: function(xhr) {
					console.log(xhr)

					// 可阻止图片上传
					// return {
					//   prevent: true,
					//   msg: "需要提示给用户的错误信息",
					// };
				},
				// 图片上传并返回了结果，图片插入已成功
				success: function(xhr) {
					console.log('success', xhr)
				},
				// 图片上传并返回了结果，但图片插入时出错了
				fail: function(xhr, editor, resData) {
					console.log('fail', resData)
				},
				// 上传图片出错，一般为 http 请求的错误
				error: function(xhr, editor, resData) {
					console.log('error', xhr, resData)
				},
				// 上传图片超时
				timeout: function(xhr) {
					console.log('timeout')
				},
				// 图片上传并返回了结果，想要自己把图片插入到编辑器中
				// 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
				customInsert: function(insertImgFn, result) {
					// result 即服务端返回的接口
					console.log('customInsert', result)

					// insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
					result.data.forEach((element) => {
						insertImgFn(element.url)
					})
				},
			}

			object.create()
		},
	},
}
</script>
<style>
.w-e-menu {
	z-index: 2 !important;
}
.w-e-text-container,
.w-e-toolbar {
	z-index: 1 !important;
}
</style>
