
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="4">
              <b-form-group
                label="Subject Name"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="subject-name"
              >
                <b-form-input
                  id="subject-name"
                  v-model="search_condition.subjectName"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <b-button
            v-has="'subjectManage::Create'"
            @click="gotoAdd"
            variant="primary"
            >Create</b-button
          >
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="No records"
              id="device-table"
              head-variant="light"
              ref="table"
              :busy="isBusy"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">{{
                data.index +
                (search_condition.pageNum - 1) * search_condition.pageSize +
                1
              }}</template>

              <template v-slot:cell(actions)="row">
                <b-button
                  v-has="'subjectManage::Modify'"
                  v-if="row.item.supplierId > 0"
                  size="sm"
                  variant="primary"
                  @click="edit(row.item, row.index, $event.target)"
                  class="mr-1"
                  >Modify
                </b-button>

                <b-button
                  v-has="'subjectManage::Details'"
                  v-if="row.item.supplierId > 0"
                  size="sm"
                  variant="info"
                  @click="onClickDetails(row.item, row.index, $event.target)"
                  class="mr-1"
                  >Details
                </b-button>
                <b-button
                  v-has="'subjectManage::Disable'"
                  v-if="row.item.status"
                  size="sm"
                  variant="secondary"
                  @click="disableOrEnable(row.item, row.index, $event.target)"
                  class="mr-1"
                  >Disable</b-button
                >

                <b-button
                  v-has="'subjectManage::Enable'"
                  v-else
                  size="sm"
                  variant="success"
                  @click="disableOrEnable(row.item, row.index, $event.target)"
                  class="mr-1"
                  >Enable</b-button
                >

                <b-button
                  v-has="'subjectManage::Delete'"
                  v-if="row.item.supplierId > 0"
                  size="sm"
                  variant="danger"
                  @click="onClickDelete(row.item, row.index, $event.target)"
                  class="mr-1"
                >
                  Delete
                </b-button>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    ref="page"
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Flavor List component
 */
export default {
  components: {
    DatePicker,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Manage Your Subject",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "My Brands",
          // href: "/"
        },
        {
          text: "Manage Your Subject",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "clientName", label: "Client Name", class: "text-center" },
        { key: "subjectName", label: "Subject Name", class: "text-center" },
        { key: "remarks", label: "Notes", class: "text-center" },
        {
          key: "status",
          label: "Status",
          formatter: (value) => {
            return value == 1 ? "Enabled" : "Disabled";
          },
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        subjectName: "",
      },
      isBusy: false,
      has_data: false,
    };
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  created() {},
  mounted() {},
  methods: {
    provider(ctx) {
      this.isBusy = true;
      return this.$api.subject
        .selectSubjectList(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
      //this.$bvToast.toast(JSON.stringify(this.search_condition));
    },

    disableOrEnable(a, b, c) {
      this.$api.subject
        .disableOrEnableSubject({ id: a.id, status: a.status === 0 ? 1 : 0 })
        .then((res) => {
          if (res.message) {
            this.$toast(res);
          }
          if (res.success) {
            this.$refs.table.refresh();
          }
        });
    },
    onClickDelete(item, index, button) {
      this.$bvModal
        .msgBoxConfirm("Are you sure to delete the subject?", {
          title: "Operation Brand",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$api.subject
              .deleteSubject({ id: item.id })
              .then((res) => {
                if (res.success) {
                  this.$refs.table.refresh();
                }
                if (res.message) {
                  this.$toast(res);
                }
              })
              .catch((res) => {
                this.$bvToast.toast(res.message);
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    onClickDetails(item, index, button) {
      this.$router.push("brandMgmtDetails?id=" + item.id);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.search_condition.pageNum = 1;
    },
    gotoAdd() {
      this.$router.push("manageYourSubjectAdd");
    },
    edit(item, index, button) {
      this.$router.push({ name: "manageYourSubjectEdit", params: item });
    },
  },
};
</script>
