var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-box" }, [
    _c("div", { staticStyle: { "margin-top": "120px" } }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-md-6 col-lg-4 col-xl-3" }, [
          _c(
            "div",
            { staticClass: "overflow-hidden" },
            [
              _c("div", { staticClass: "text-primary text-center p-4" }, [
                _c("img", {
                  attrs: { src: _vm.imageUrl, height: _vm.height, alt: "logo" }
                })
              ]),
              _c(
                "b-form",
                { on: { submit: _vm.forgotPassword } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          placeholder: "Enter Your Login Email",
                          required: "",
                          disabled: _vm.disabledClass,
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 40)value = value.slice(0, 40)"
                        },
                        model: {
                          value: _vm.loginForm.username,
                          callback: function($$v) {
                            _vm.$set(_vm.loginForm, "username", $$v)
                          },
                          expression: "loginForm.username"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "7" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: "Enter code",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');if(value.length > 4)value = value.slice(0, 4)"
                                },
                                model: {
                                  value: _vm.loginForm.verificationCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.loginForm,
                                      "verificationCode",
                                      $$v
                                    )
                                  },
                                  expression: "loginForm.verificationCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { md: "5" } }, [
                        _c("img", {
                          staticStyle: { "padding-left": "30%" },
                          attrs: {
                            title: "Change One",
                            src: _vm.codeUrl,
                            width: "100%"
                          },
                          on: { click: _vm.getCode }
                        })
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "7" } },
                        [
                          _c("b-form-group", [
                            _c(
                              "p",
                              {
                                staticStyle: { color: "white", float: "left" },
                                on: {
                                  click: function($event) {
                                    return _vm.login()
                                  }
                                }
                              },
                              [_vm._v(" Login ")]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-inherit btn-lg",
                          staticStyle: { width: "300px" },
                          attrs: { type: "submit", variant: "primary" }
                        },
                        [_vm._v("SEND EMAIL VERIFICATION CODE")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "text-center", staticStyle: { "margin-top": "120px" } },
      [
        _c("p", { staticClass: "mb-0", staticStyle: { color: "#ffffff" } }, [
          _vm._v(
            " Copyright © " +
              _vm._s(new Date().getFullYear()) +
              " " +
              _vm._s(this.brand) +
              " "
          ),
          _c("span", { staticClass: "d-none d-sm-inline-block" }, [
            _vm._v(" . All rights reserved ")
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }