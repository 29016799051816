var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal",
        size: "lg",
        centered: "",
        title: "Create New Flavor",
        "ok-title": "Confirm",
        "no-close-on-esc": "",
        "no-close-on-backdrop": ""
      },
      on: { ok: _vm.onConfirm, cancel: _vm.onCancel, close: _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function() {
            return [_c("div", { staticClass: "w-100" })]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.showCreateFlavor,
        callback: function($$v) {
          _vm.showCreateFlavor = $$v
        },
        expression: "showCreateFlavor"
      }
    },
    [
      _c(
        "b-card",
        [
          _c(
            "b-form",
            { on: { submit: _vm.onConfirm, reset: _vm.onCancel } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Flavor Name:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "model-name",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "model-name",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput:
                        "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                      placeholder: "Please enter"
                    },
                    model: {
                      value: _vm.form.modelName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "modelName", $$v)
                      },
                      expression: "form.modelName"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Flavor Type:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "cartridge-type",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "cartridge-type",
                      options: _vm.types.TypeEncoding,
                      required: "",
                      oninvalid: "setCustomValidity('Please select');",
                      oninput: "setCustomValidity('')"
                    },
                    on: {
                      change: function($event) {
                        return _vm.changeType(_vm.form.type)
                      }
                    },
                    model: {
                      value: _vm.form.type,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "type", $$v)
                      },
                      expression: "form.type"
                    }
                  })
                ],
                1
              ),
              _vm.isUnicoreus
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Device Model:",
                        "label-cols-lg": "3",
                        "label-align-lg": "right",
                        "label-for": "device-model",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "device-model",
                          options: _vm.types.deviceList,
                          "value-field": "id",
                          "text-field": "modelName",
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('')"
                        },
                        model: {
                          value: _vm.form.deviceModelId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "deviceModelId", $$v)
                          },
                          expression: "form.deviceModelId"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isIkrusher
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Devices Type:",
                        "label-cols-lg": "3",
                        "label-align-lg": "right",
                        "label-for": "cartridge-model",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "cartridge-model",
                          options: _vm.types.selectAllTestSmokeBombModel,
                          "value-field": "id",
                          "text-field": "modelName",
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('')"
                        },
                        model: {
                          value: _vm.form.deviceType,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "deviceType", $$v)
                          },
                          expression: "form.deviceType"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Suggested Price:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "suggested-price",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "suggested-price",
                      required: "",
                      type: "number",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput:
                        "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                      placeholder: "Please enter"
                    },
                    model: {
                      value: _vm.form.suggestRetailPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "suggestRetailPrice", $$v)
                      },
                      expression: "form.suggestRetailPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Actual Selling Price:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "actual-selling-price",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "actual-selling-price",
                      required: "",
                      type: "number",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput:
                        "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                      placeholder: "Please enter"
                    },
                    model: {
                      value: _vm.form.retailPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "retailPrice", $$v)
                      },
                      expression: "form.retailPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Product Genre Tags:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "product-genre-tags",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("multiselect", {
                    attrs: {
                      id: "product-genre-tags",
                      options: _vm.types.productGenreList,
                      "track-by": "id",
                      label: "labelName",
                      placeholder: "Please select",
                      searchable: false,
                      "close-on-select": false,
                      multiple: true,
                      max: _vm.maxFive,
                      required: "",
                      oninvalid: "setCustomValidity('Please select');",
                      oninput: "setCustomValidity('')"
                    },
                    on: { input: _vm.selectGenre },
                    model: {
                      value: _vm.selectProductGenre,
                      callback: function($$v) {
                        _vm.selectProductGenre = $$v
                      },
                      expression: "selectProductGenre"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Usage Effects:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "usage-effects",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("multiselect", {
                    attrs: {
                      id: "usage-effects",
                      options: _vm.types.usageEffectsList,
                      "track-by": "id",
                      label: "labelName",
                      placeholder: "Please select",
                      searchable: false,
                      "close-on-select": false,
                      multiple: true,
                      max: _vm.maxThree,
                      required: "",
                      oninvalid: "setCustomValidity('Please select');",
                      oninput: "setCustomValidity('')"
                    },
                    on: { input: _vm.selectEffects },
                    model: {
                      value: _vm.selectUsageEffects,
                      callback: function($$v) {
                        _vm.selectUsageEffects = $$v
                      },
                      expression: "selectUsageEffects"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Flavor Profiles:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "flavor-profiles",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("multiselect", {
                    attrs: {
                      options: _vm.types.flavorProfilesList,
                      "track-by": "id",
                      label: "labelName",
                      placeholder: "Please select",
                      searchable: false,
                      "close-on-select": false,
                      multiple: true,
                      max: _vm.maxThree,
                      required: "",
                      oninvalid: "setCustomValidity('Please select');",
                      oninput: "setCustomValidity('')"
                    },
                    on: { input: _vm.selectProfiles },
                    model: {
                      value: _vm.selectFlavorProfiles,
                      callback: function($$v) {
                        _vm.selectFlavorProfiles = $$v
                      },
                      expression: "selectFlavorProfiles"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Intensity:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "intensity",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "intensity",
                      options: _vm.types.IntensityList,
                      required: "",
                      oninvalid: "setCustomValidity('Please select');",
                      oninput: "setCustomValidity('')"
                    },
                    on: {
                      change: function($event) {
                        return _vm.changeType(_vm.form.type)
                      }
                    },
                    model: {
                      value: _vm.form.strengthId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "strengthId", $$v)
                      },
                      expression: "form.strengthId"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Brand Name:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "brand-name",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "brand-name",
                      options: _vm.types.brandNames,
                      required: "",
                      oninvalid: "setCustomValidity('Please select');",
                      oninput: "setCustomValidity('')"
                    },
                    model: {
                      value: _vm.form.brand,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "brand", $$v)
                      },
                      expression: "form.brand"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Flavor Picture:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "logo",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("input", {
                    staticClass: "filestyle",
                    staticStyle: {
                      position: "absolute",
                      clip: "rect(0px, 0px, 0px, 0px)"
                    },
                    attrs: {
                      type: "file",
                      "data-input": "false",
                      id: "filestyle-1",
                      tabindex: "-1",
                      required: !_vm.editId,
                      oninvalid: "setCustomValidity('Please upload');",
                      oninput: "setCustomValidity('');"
                    },
                    on: {
                      change: function($event) {
                        return _vm.upload($event)
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "bootstrap-filestyle input-group" },
                    [
                      _c("div", {
                        staticStyle: {
                          position: "absolute",
                          width: "100%",
                          height: "35px",
                          "z-index": "-1"
                        },
                        attrs: { name: "filedrag" }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "group-span-filestyle",
                          attrs: { tabindex: "0" }
                        },
                        [
                          _vm.form.flavorPicture
                            ? _c(
                                "div",
                                { staticStyle: { position: "relative" } },
                                [
                                  _c("i", {
                                    staticClass: "ion ion-md-close-circle",
                                    staticStyle: {
                                      position: "absolute",
                                      left: "70px",
                                      top: "5px",
                                      "font-size": "25px"
                                    },
                                    on: { click: _vm.onDeleteFlavorPicture }
                                  }),
                                  _c("img", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                      border: "2px dashed #ccc"
                                    },
                                    attrs: { src: _vm.form.flavorPicture }
                                  }),
                                  _vm.photoName
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                            "vertical-align": "bottom"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "500"
                                              }
                                            },
                                            [_vm._v("Picture name : ")]
                                          ),
                                          _vm._v(_vm._s(_vm.photoName))
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          !_vm.form.flavorPicture
                            ? _c(
                                "label",
                                {
                                  staticClass: "btn btn-secondary",
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { for: "filestyle-1" }
                                },
                                [
                                  _c("span", { staticClass: "buttonText" }, [
                                    _vm._v(" upload picture ")
                                  ])
                                ]
                              )
                            : _vm._e(),
                          !_vm.form.flavorPicture
                            ? _c("span", [
                                _vm._v(
                                  " Recommended size of picture: 512 pixels * 512 pixels, supporting JPG and PNG formats. "
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Carousel:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "carousel",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("input", {
                    staticClass: "filestyle",
                    staticStyle: {
                      position: "absolute",
                      clip: "rect(0px, 0px, 0px, 0px)"
                    },
                    attrs: {
                      type: "file",
                      "data-input": "false",
                      id: "filestyle-2",
                      tabindex: "-2",
                      required: !_vm.editId,
                      oninvalid: "setCustomValidity('Please upload');",
                      oninput: "setCustomValidity('');"
                    },
                    on: {
                      change: function($event) {
                        return _vm.uploadCarouse($event)
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "bootstrap-filestyle input-group" },
                    [
                      _c("div", {
                        staticStyle: {
                          position: "absolute",
                          width: "100%",
                          height: "35px",
                          "z-index": "-1"
                        },
                        attrs: { name: "filedrag" }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "group-span-filestyle",
                          attrs: { tabindex: "0" }
                        },
                        [
                          _vm.form.commodityCarouselMap
                            ? _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                _vm._l(_vm.form.commodityCarouselMap, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: {
                                        width: "100px",
                                        position: "relative",
                                        "margin-right": "10px"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "ion ion-md-close-circle",
                                        staticStyle: {
                                          position: "absolute",
                                          right: "5px",
                                          top: "5px",
                                          "font-size": "25px"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onDeleteCarouselMap(
                                              item,
                                              index
                                            )
                                          }
                                        }
                                      }),
                                      _c("img", {
                                        staticStyle: {
                                          width: "100px",
                                          height: "100px",
                                          border: "2px dashed #ccc"
                                        },
                                        attrs: { src: item }
                                      })
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm.form.commodityCarouselMap.length < 5
                            ? _c(
                                "label",
                                {
                                  staticClass: "btn btn-secondary",
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { for: "filestyle-2" }
                                },
                                [
                                  _c("span", { staticClass: "buttonText" }, [
                                    _vm._v(" upload picture ")
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.form.commodityCarouselMap.length < 5
                            ? _c("span", [
                                _vm._v(
                                  " Recommended size of picture: 750 pixels * 750 pixels, supporting JPG and PNG formats. "
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Creators Notes:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "creators-notes",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "creators-notes",
                      placeholder: "Enter Notes",
                      rows: "3",
                      required: "",
                      "max-rows": "15"
                    },
                    model: {
                      value: _vm.form.commodityCreationStory,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "commodityCreationStory", $$v)
                      },
                      expression: "form.commodityCreationStory"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Details:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "details",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("input", {
                    staticClass: "filestyle",
                    staticStyle: {
                      position: "absolute",
                      clip: "rect(0px, 0px, 0px, 0px)"
                    },
                    attrs: {
                      type: "file",
                      "data-input": "false",
                      id: "filestyle-3",
                      tabindex: "-3",
                      required: !_vm.editId,
                      oninvalid: "setCustomValidity('Please upload');",
                      oninput: "setCustomValidity('');"
                    },
                    on: {
                      change: function($event) {
                        return _vm.uploadDetails($event)
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "bootstrap-filestyle input-group" },
                    [
                      _c("div", {
                        staticStyle: {
                          position: "absolute",
                          width: "100%",
                          height: "35px",
                          "z-index": "-1"
                        },
                        attrs: { name: "filedrag" }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "group-span-filestyle",
                          attrs: { tabindex: "0" }
                        },
                        [
                          _vm.form.commodityDetailsMap
                            ? _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                _vm._l(_vm.form.commodityDetailsMap, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: {
                                        width: "100px",
                                        position: "relative",
                                        "margin-right": "10px"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "ion ion-md-close-circle",
                                        staticStyle: {
                                          position: "absolute",
                                          right: "5px",
                                          top: "5px",
                                          "font-size": "25px"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onDeleteDetailsMap(
                                              item,
                                              index
                                            )
                                          }
                                        }
                                      }),
                                      _c("img", {
                                        staticStyle: {
                                          width: "100px",
                                          height: "100px",
                                          border: "2px dashed #ccc"
                                        },
                                        attrs: { src: item }
                                      })
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm.form.commodityDetailsMap.length < 5
                            ? _c(
                                "label",
                                {
                                  staticClass: "btn btn-secondary",
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { for: "filestyle-3" }
                                },
                                [
                                  _c("span", { staticClass: "buttonText" }, [
                                    _vm._v(" upload picture ")
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.form.commodityDetailsMap.length < 5
                            ? _c("span", [
                                _vm._v(
                                  " Recommended image width 750px, supporting JPG and PNG formats. "
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Find it link:",
                    "label-cols-sm": "2",
                    "label-align-sm": "right",
                    "label-for": "cart-url"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "cart-url",
                      placeholder: "Please enter",
                      maxlength: "100",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput: "setCustomValidity('')"
                    },
                    model: {
                      value: _vm.form.cartUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "cartUrl", $$v)
                      },
                      expression: "form.cartUrl"
                    }
                  })
                ],
                1
              ),
              _vm.form.type == 1 || _vm.form.type == 5
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Consistence:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "consistence",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "consistence",
                          required: "",
                          type: "number",
                          min: "0",
                          step: "0.0001",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d\\d\\d).*$/, '$1$2.$3')"
                        },
                        model: {
                          value: _vm.form.consistence,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "consistence", $$v)
                          },
                          expression: "form.consistence"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.type == 5
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Medical Care:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "medical",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c(
                        "b-form-radio",
                        {
                          staticClass: "custom-control-inline pt-2",
                          attrs: {
                            name: "medicalCare",
                            value: "0",
                            required: ""
                          },
                          model: {
                            value: _vm.form.medicalCare,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "medicalCare", $$v)
                            },
                            expression: "form.medicalCare"
                          }
                        },
                        [_vm._v("YES")]
                      ),
                      _c(
                        "b-form-radio",
                        {
                          staticClass: "custom-control-inline pt-2",
                          attrs: {
                            name: "medicalCare",
                            value: "1",
                            required: ""
                          },
                          model: {
                            value: _vm.form.medicalCare,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "medicalCare", $$v)
                            },
                            expression: "form.medicalCare"
                          }
                        },
                        [_vm._v("NO")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.type == 5
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Seconds/Day:",
                        "label-cols-sm": "2",
                        "label-align-sm": "right",
                        "label-for": "daySeconds",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "remark",
                          placeholder: "Please enter",
                          maxlength: "20",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput: "setCustomValidity('')"
                        },
                        model: {
                          value: _vm.form.daySeconds,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "daySeconds", $$v)
                          },
                          expression: "form.daySeconds"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Notes:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "remarks"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "remarks",
                      placeholder: "Enter Notes",
                      rows: "3",
                      "max-rows": "6"
                    },
                    model: {
                      value: _vm.form.remarks,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "5" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-l-5 ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }