var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "vo-items-center", attrs: { "align-content": "center" } },
    [
      _c(
        "b-col",
        { attrs: { cols: _vm.lg, offset: _vm.offset, "align-self": "center" } },
        [_vm._t("default")],
        2
      ),
      _vm.$slots.right ? [_vm._t("right")] : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }