<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row>
        <b-col lg="8">
          <b-form action="#" @submit.prevent="submit_form" @reset="reset_form">
            <!-- 萜烯名称 -->
            <b-form-group
              label="Terpenes Name"
              label-cols-lg="3"
              label-align-sm="right"
              label-for="terpenes-name"
              label-class="requiredRow"
            >
              <b-form-input
                id="terpenes-name"
                v-model="typeform.terpenesName"
                placeholder="Please enter"
                trim
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
              >
              </b-form-input>
            </b-form-group>
            <!-- 萜烯说明 -->
            <b-form-group
              label="Terpenes Explains"
              label-cols-lg="3"
              label-align-sm="right"
              label-for="terpenes-statement"
            >
              <b-form-textarea
                id="explains"
                v-model="typeform.explains"
                placeholder="Please enter"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <!-- 备注 -->
            <b-form-group
              label="Terpenes Notes"
              label-cols-lg="3"
              label-align-sm="right"
              label-for="terpenes-remark"
            >
              <b-form-textarea
                id="remark"
                v-model="typeform.remarks"
                placeholder="Please enter"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <div class="form-group mb-0">
              <div class="text-center">
                <button type="submit" class="btn btn-primary">Confirm</button>
                <button type="reset" class="btn btn-secondary m-l-5 ml-1">
                  Cancel
                </button>
              </div>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { dropzoneOptions } from "@/api/upload";
import vue2Dropzone from "vue2-dropzone";
import { mapGetters } from "vuex";

export default {
  name: "terpenes",
  page: {
    title: "terpenes",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },
  data() {
    return {
      dropzoneOptions: dropzoneOptions,
      title: "Manage Your Terpenes",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "My Brands",
          // href: "/"
        },
        {
          text: this.$route.params.id ? "Terpenes Modify" : "Terpenes Create",
        },
      ],
      typeform: {
        terpenesName: "",
        explains: "",
        remarks: "",
      },
      submit: false,
      typesubmit: false,
    };
  },
  computed: {
    ...mapGetters(["isIkrusher", "isUnicoreus"]),
    is_add: function () {
      return /add/i.test(location.href);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    show1() {
      this.typeform = {
        id: this.$route.params.id,
        terpenesName: this.$route.params.terpenesName,
        explains: this.$route.params.explains,
        remarks: this.$route.params.remarks,
      };
    },
    init() {
      if (this.is_add) {
      } else {
        this.show1();
      }
    },
    add() {
      this.$api.Flavor.terpenesAdd(this.typeform).then((res) => {
        if (res.success) {
          this.$toastBack(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    update() {
      this.$api.Flavor.terpenesUpdate(this.typeform).then((res) => {
        if (res.success) {
          this.$toastBack(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    submit_form() {
      this.typesubmit = true;
      if (this.is_add) {
        this.add();
      } else {
        this.update();
      }
    },
    reset_form() {
      this.$router.go(-1);
    },
  },
};
</script>
