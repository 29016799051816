<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-form action="#" @submit.prevent="typeForm" @reset="reset_form">
      <b-card>
        <b-row>
          <b-col cols="10">
            <FormRow>
              <!-- 品牌名称 -->
              <b-form-group
                label="Brand Name:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="brand-name"
                label-class="requiredRow"
              >
                <b-form-input
                  id="brand-name"
                  v-model="typeform.brandName"
                  placeholder="Please enter"
                  trim
                  required
                  maxlength="50"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                >
                </b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="typeform.brandName ? typeform.brandName.length : 0"
                />
              </template>
            </FormRow>

            <FormRow>
              <b-form-group
                label="Business Type:"
                ref="businessType"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="company-business"
                label-class="requiredRow"
              >
                <div class="d-flex flex-wrap">
                  <b-form-checkbox
                    v-for="(label, index) in companyBusiness"
                    :required="true"
                    :key="index"
                    v-model="typeform.companyBusinessId"
                    :value="label.value"
                    class="mr-2 mt-2"
                  >
                    {{ label.text }}
                  </b-form-checkbox>
                </div>
              </b-form-group>
            </FormRow>
            <FormRow>
              <!-- 品牌社交 -->
              <b-form-group
                label="Social Media Url:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="brand-ins"
                label-class="requiredRow"
              >
                <b-form-input
                  id="brand-ins"
                  v-model="typeform.brandIns"
                  placeholder="Please enter"
                  trim
                  required
                  maxlength="200"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                >
                </b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="typeform.brandIns ? typeform.brandIns.length : 0"
                  :max="200"
                />
              </template>
            </FormRow>

            <FormRow>
              <!-- 品牌官网 -->
              <b-form-group
                label="Brand Official Website:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="brand-url"
                label-class="requiredRow"
              >
                <b-form-input
                  id="brand-url"
                  v-model="typeform.brandUrl"
                  placeholder="Please enter"
                  trim
                  required
                  maxlength="200"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                >
                </b-form-input>
              </b-form-group>
              <template #right>
                <CountText
                  :len="typeform.brandUrl ? typeform.brandUrl.length : 0"
                  :max="200"
                />
              </template>
            </FormRow>

            <FormRow>
              <!-- 品牌简介 -->
              <b-form-group
                label="Brand Introduction:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="brand-introduction"
                label-class="requiredRow"
              >
                <b-form-textarea
                  id="brand-introduction"
                  v-model="typeform.brandIntroduction"
                  placeholder="Please enter"
                  maxlength="2000"
                  rows="3"
                  required
                  max-rows="6"
                >
                  >
                </b-form-textarea>
              </b-form-group>
              <template #right>
                <CountText
                  :len="
                    typeform.brandIntroduction
                      ? typeform.brandIntroduction.length
                      : 0
                  "
                  :max="2000"
                />
              </template>
            </FormRow>

            <FormRow>
              <!-- 品牌logo -->
              <b-form-group
                label="Brand Logo:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="brand-logo"
                label-class="requiredRow"
              >
                <input
                  type="file"
                  class="filestyle"
                  data-input="false"
                  id="filestyle-1"
                  style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
                  tabindex="-1"
                  @change="upload($event)"
                  :required="!typeform.file"
                  oninvalid="setCustomValidity('Please upload');"
                  accept="image/*"
                  oninput="setCustomValidity('');"
                />

                <div class="bootstrap-filestyle input-group">
                  <!-- <div
                    name="filedrag"
                    style="
                      position: absolute;
                      width: 100%;
                      height: 35px;
                      z-index: -1;
                    "
                  ></div> -->
                  <span class="group-span-filestyle" tabindex="0">
                    <div v-if="typeform.file">
                      <div class="upload-img-wrap">
                        <img :src="typeform.file" class="upload-img" />
                        <img
                          @click="onRemoveBrandLogo"
                          :src="require('@/assets/images/brand/ic_close.png')"
                          class="close-img"
                        />
                      </div>

                      <!-- <span
                        style="margin-left: 10px; vertical-align: bottom"
                        v-if="photoName"
                      >
                        <span style="font-weight: 500">Picture name : </span
                        >{{ photoName }}
                      </span> -->
                    </div>
                    <b-button
                      variant="primary"
                      class="custom-width"
                      @click="uploadFile()"
                      >upload picture</b-button
                    >
                    <span>
                      Recommended size of picture: 120 pixels * 120 pixels,
                      supporting JPG and PNG formats.
                    </span>
                  </span>
                </div>
              </b-form-group>
            </FormRow>

            <FormRow v-if="false">
              <!-- 首页展示logo -->
              <b-form-group
                label="Homepage Display Logo"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="homepage-logo"
                label-class="requiredRow"
              >
                <input
                  type="file"
                  class="filestyle"
                  data-input="false"
                  id="homepage-logo"
                  style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
                  tabindex="-1"
                  @change="uploadHomePicture($event)"
                  :required="!typeform.homePicture"
                  oninvalid="setCustomValidity('Please upload');"
                  oninput="setCustomValidity('');"
                />

                <div class="bootstrap-filestyle input-group">
                  <div
                    name="filedrag"
                    style="
                      position: absolute;
                      width: 100%;
                      height: 35px;
                      z-index: -1;
                    "
                  ></div>
                  <span class="group-span-filestyle" tabindex="0">
                    <div
                      v-if="typeform.homePicture"
                      style="margin-bottom: 10px"
                    >
                      <img
                        :src="typeform.homePicture"
                        style="
                          width: 100px;
                          height: 100px;
                          border: 2px dashed #ccc;
                        "
                      />
                      <span
                        style="margin-left: 10px; vertical-align: bottom"
                        v-if="homePictureName"
                      >
                        <span style="font-weight: 500">Picture name : </span
                        >{{ homePictureName }}</span
                      >
                    </div>
                    <label
                      for="homepage-logo"
                      style="margin-bottom: 0"
                      class="btn btn-secondary"
                    >
                      <span class="buttonText"> upload picture </span>
                    </label>
                    <span>
                      Recommended size of picture: 120 pixels * 120 pixels,
                      supporting JPG and PNG formats.
                    </span>
                  </span>
                </div>
              </b-form-group>
            </FormRow>

            <FormRow v-if="false">
              <!-- 口味详情图片 -->
              <b-form-group
                v-if="isUnicoreus"
                label="Brand Details Picture"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="brand-details"
                label-class="requiredRow"
              >
                <input
                  type="file"
                  class="filestyle"
                  data-input="false"
                  id="brand-details"
                  style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
                  tabindex="-1"
                  @change="uploadBrandDetailsPicture($event)"
                  :required="!typeform.brandDetailsPicture"
                  oninvalid="setCustomValidity('Please upload');"
                  oninput="setCustomValidity('');"
                />

                <div class="bootstrap-filestyle input-group">
                  <div
                    name="filedrag"
                    style="
                      position: absolute;
                      width: 100%;
                      height: 35px;
                      z-index: -1;
                    "
                  ></div>
                  <span class="group-span-filestyle" tabindex="0">
                    <div
                      v-if="typeform.brandDetailsPicture"
                      style="margin-bottom: 10px"
                    >
                      <img
                        :src="typeform.brandDetailsPicture"
                        style="
                          width: 100px;
                          height: 100px;
                          border: 2px dashed #626ed4;
                        "
                      />
                      <span
                        style="margin-left: 10px; vertical-align: bottom"
                        v-if="brandDetailsPictureName"
                      >
                        <span style="font-weight: 500">Picture name : </span
                        >{{ brandDetailsPictureName }}</span
                      >
                    </div>
                    <label
                      for="brand-details"
                      style="margin-bottom: 0"
                      class="btn btn-secondary"
                    >
                      <span class="buttonText"> upload picture </span>
                    </label>
                    <span>
                      Recommended size of picture: 360 pixels * 88 pixels,
                      supporting JPG and PNG formats.
                    </span>
                  </span>
                </div>
              </b-form-group>
            </FormRow>

            <FormRow v-if="false">
              <!-- Intensity 主题 -->
              <b-form-group
                v-if="isIkrusher"
                label="Subject:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="subject"
                label-class="requiredRow"
              >
                <div class="d-flex flex-wrap">
                  <b-form-checkbox
                    v-for="(label, index) in subjectList"
                    :key="index"
                    v-model="typeform.subjectId"
                    :value="label.value"
                    class="mr-2 mt-2"
                  >
                    {{ label.text }}
                  </b-form-checkbox>
                </div>
              </b-form-group>
            </FormRow>

            <FormRow v-if="false">
              <!-- 口味备注 -->
              <b-form-group
                label="Brand Notes"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="brand-remark"
              >
                <b-form-textarea
                  id="remark"
                  v-model="typeform.remarks"
                  placeholder="Enter Notes"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </FormRow>

            <FormRow>
              <!-- 状态 -->
              <b-form-group
                label="Status:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="brand-remark"
              >
                <b-form-select
                  v-model="typeform.enableStatus"
                  :options="statusOptions"
                  required
                  oninvalid="setCustomValidity('Please select');"
                  oninput="setCustomValidity('')"
                  class="col-md-3"
                ></b-form-select>
              </b-form-group>
            </FormRow>
          </b-col>
        </b-row>
      </b-card>
      <b-form-group label-cols-lg="3">
        <b-button type="submit" variant="primary" class="custom-width"
          >Save</b-button
        >
        <b-button type="reset" class="ml-4 custom-width">Cancel </b-button>
      </b-form-group>
    </b-form>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel"
      @hide="hide"
    >
      Saved successfully
    </Dialog>
    <!-- 错误提示框 -->
    <Dialog
      v-model="errorAlear"
      :imageSrc="require('@/assets/confirm.svg')"
      noBtn="OK"
      @cancel="cancel1"
      @hide="hide1"
    >
      {{ errorContent }}
    </Dialog>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { dropzoneOptions, upload_img } from "@/api/upload";
import vue2Dropzone from "vue2-dropzone";
import { mapGetters } from "vuex";

import {
  alpha,
  alphaNum,
  numeric,
  between,
  email,
  ipAddress,
  macAddress,
  maxLength,
  minLength,
  required,
  requiredIf,
  requiredUnless,
  sameAs,
  url,
  or,
  and,
  not,
  minValue,
  maxValue,
  integer,
  decimal,
  helpers,
} from "vuelidate/lib/validators";
import FormRow from "@/components/FormRow.vue";
import CountText from "@/components/CountText.vue";
import OperationLog from "@/components/operation/OperationLog.vue";
import Dialog from "@/components/Dialog";

export default {
  name: "brand",
  page: {
    title: "brand",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    OperationLog,
    CountText,
    FormRow,
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,
    Dialog,
  },

  data() {
    return {
      businessTypeOptions: [
        {
          id: 1,
          value: 1,
          text: "CBD/THC Concentrates",
        },
        {
          id: 2,
          value: 2,
          text: "Nicotine",
        },
      ],
      dropzoneOptions: dropzoneOptions,
      title: this.$route.query.id ? "Modify Brand" : "Create Brand",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "Client&Brand",
        },
        {
          text: "Brand Mgmt",
        },
        {
          text: this.$route.query.id ? "Modify Brand" : "Create Brand",
          active: true,
        },
      ],

      typeform: {
        //"id":3,
        brandName: "",
        brandIntroduction: "",
        remarks: "",
        file: "",
        homePicture: "",
        brandDetailsPicture: "",
        supplierId: this.$route.query.supplierId
          ? this.$route.query.supplierId
          : localStorage.supplierId,
        brnadEmail: "",
        brandUrl: "",
        brandIns: "",
        subjectId: 0,
        companyBusinessId: [],
        enableStatus: "",
        status: "",
      },
      photoName: "",
      homePictureName: "",
      brandDetailsPictureName: "",
      submit: false,
      typesubmit: false,
      subjectList: [{ text: "blue", value: "0" }], //主题集合

      statusOptions: [
        { text: "--All Status--", value: "", disabled: false },
        { text: "Enable", value: 0, disabled: false },
        { text: "Disable", value: 1, disabled: false },
      ],
      reviewOptions: [
        { text: "--Review Status--", value: "", disabled: false },
        { text: "Pending Review", value: 0, disabled: false },
        { text: "Passed", value: 1, disabled: false },
        { text: "No passed", value: 2, disabled: false },
      ],
      companyBusiness: [
        { text: "CBD/THC Concentrates", value: 2 },
        { text: "Nicotine", value: 1 },
      ],
      successAlear: false,
      errorAlear: false,
      errorContent: "",
    };
  },
  validations: {
    typeform: {
      file: { required, minLength: minLength(1) },
    },
  },
  computed: {
    ...mapGetters(["isIkrusher", "isUnicoreus"]),
    is_add: function () {
      return /add/i.test(location.href);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    onRemoveBrandLogo() {
      this.typeform.file = "";
    },
    init() {
      if (this.is_add) {
      } else {
        //查看详情
        this.$api.Flavor.findBrandById({ id: this.$route.query.id }).then(
          (res) => {
            if (res.success) {
              this.typeform = {
                id: res.data.id,
                brandName: res.data.brandName || "",
                brandIntroduction: res.data.brandIntroduction,
                remarks: res.data.remarks,
                file: res.data.brandPicture,
                homePicture: res.data.brandPicture,
                brandDetailsPicture: res.data.brandDetailsPicture,
                supplierId: res.data.supplierId,
                brandIns: res.data.brandIns,
                brandUrl: res.data.brandUrl,
                subjectId: 0,
                enableStatus: res.data.enableStatus,
                status: res.data.status,
              };
              if (res.data.companyBusinessId) {
                this.typeform.companyBusinessId =
                  res.data.companyBusinessId.split(",");
              }
            } else {
              if (res.message) {
                this.errorAlear = true;
                this.errorContent = res.message;
              }
            }
          }
        );

        //this.show1();
      }
      this.$api.Dropdown.selectAllBrandSubject().then((res) => {
        if (res.success) {
          for (let i = 0; i < res.data.length; i++) {
            this.subjectList.push({
              text: res.data[i].subjectName,
              value: res.data[i].id,
            });
          }
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },

    upload(e) {
      let file = e.target.files[0];
      this.photoName = file.name;
      console.log(file);
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          // if (res.message) {
          //   this.$toast(res);
          // }
          if (res.success) {
            this.typeform.file = res.data;
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    uploadHomePicture(e) {
      let file = e.target.files[0];
      this.homePictureName = file.name;
      console.log(file);
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          if (res.message) {
            this.$toast(res);
          }
          if (res.success) {
            this.typeform.homePicture = res.data;
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    uploadBrandDetailsPicture(e) {
      let file = e.target.files[0];
      this.brandDetailsPictureName = file.name;
      console.log(file);
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          if (res.message) {
            this.$toast(res);
          }
          if (res.success) {
            this.typeform.brandDetailsPicture = res.data;
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    add() {
      if (this.typeform.companyBusinessId.length == 0) {
        this.errorAlear = true;
        this.errorContent = "The company business  is required";
      } else {
        this.typeform.companyBusinessId =
          this.typeform.companyBusinessId.toString();
        this.$api.Flavor.brandAdd(this.typeform).then((res) => {
          if (res.success) {
            this.successAlear = true;
          } else {
            if (res.message) {
              this.errorAlear = true;
              this.errorContent = res.message;
            }
          }
        });
      }
    },
    update() {
      if (this.typeform.companyBusinessId.length == 0) {
        this.errorAlear = true;
        this.errorContent = "The company business  is required";
      } else {
        this.typeform.companyBusinessId =
          this.typeform.companyBusinessId.toString();
        this.$api.Flavor.brandUpdate(this.typeform).then((res) => {
          if (res.success) {
            this.successAlear = true;
          } else {
            if (res.message) {
              this.errorAlear = true;
              this.errorContent = res.message;
            }
          }
        });
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    hide() {
      this.$router.go(-1);
    },
    cancel1() {
      this.errorAlear = false;
    },
    hide1() {
      this.errorAlear = false;
    },
    rangeform(e) {
      this.submit = true;
      this.$v.$touch();
    },
    submit_form() {
      this.typesubmit = true;
      this.$v.$touch();
      if (this.is_add) {
        this.add();
      } else {
        this.update();
      }
    },
    typeForm(e) {
      if (this.$v.typeform.$invalid) {
        this.submitStatus = "ERROR";
        this.$bvToast.toast("Please upload pictures");
      } else {
        this.submit_form();
      }
    },
    reset_form() {
      this.$router.go(-1);
    },
    uploadFile() {
      const filestyle = document.getElementById("filestyle-1");
      filestyle.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.dropzone {
  width: 150px;
  height: 150px;
  border: 1px dashed #ced4da !important;
}

.dropzone .dz-message {
  margin: 1em 0;
}

.dropzone .dz-message {
  font-size: 16px !important;
}

.dropzone .dz-message {
  color: #ced4da;
  margin: 27px 0 !important;
}

.dropzone .dz-preview {
  margin: -6px;
}

.upload-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.close-img {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  padding: 4px;
  background: #02a7f0;
  border-radius: 50%;
  object-fit: contain;
}

.upload-img-wrap {
  margin-bottom: 1em;
  width: 200px;
  height: 200px;
  position: relative;
  border: 2px dashed #02a7f0;
}
</style>
