<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row>
        <b-col lg="8">
          <b-form action="#" @submit.prevent="submit_form" @reset="reset_form">
            <!-- 厂家名称 -->
            <b-form-group
              label="Manufacturer Name"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="manufacturer-name"
              label-class="requiredRow"
            >
              <b-form-input
                id="manufacturer-name"
                v-model="typeform.name"
                placeholder="Please enter"
                trim
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group
              label="Contacts"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="contacts"
            >
              <b-form-input
                id="contacts"
                v-model="typeform.contacts"
                placeholder="Please enter"
                trim
                oninput="if(value.length > 50)value = value.slice(0, 50)"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group
              label="Email"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="typeform.email"
                placeholder="Please enter"
                type="email"
                trim
                oninput="if(value.length > 50)value = value.slice(0, 50)"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group
              label="Contact Number"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="contact-number"
            >
              <b-form-input
                id="contact-number"
                v-model="typeform.phone"
                placeholder="Please enter"
                trim
                oninput="if(value.length > 50)value = value.slice(0, 50)"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group
              label="Contact Address"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="contact-address"
            >
              <b-form-input
                id="contact-address"
                v-model="typeform.address"
                placeholder="Please enter"
                trim
                oninput="if(value.length > 50)value = value.slice(0, 50)"
              >
              </b-form-input>
            </b-form-group>
            <!-- 备注 -->
            <b-form-group
              label="Notes"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="flavor-remark"
            >
              <b-form-textarea
                id="remark"
                v-model="typeform.remarks"
                placeholder="Enter Notes"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group label-cols-lg="3">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { dropzoneOptions } from "@/api/upload";
import vue2Dropzone from "vue2-dropzone";
import Cards from "../../../../../组件/Admin/src/router/views/ui/cards.vue";

export default {
  name: "Manufacturer",
  page: {
    title: "Manufacturer",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },

  data() {
    return {
      dropzoneOptions: dropzoneOptions,
      title: "Manufacturer",
      items: [
        {
          text: "Data Dictionary",
          // href: "/"
        },
        {
          text: "Manufacturer",
          // href: "/"
        },
        {
          text: /add/i.test(location.href)
            ? "Manufacturer Create"
            : "Manufacturer Modify",
          active: true,
        },
      ],
      typeform: {
        //"id":3,
        name: "",
        //areaEncodingId: "",
        contacts: "",
        phone: "",
        email: "",
        address: "",
        remarks: "",
        supplierId: localStorage.supplierId,
      },
      types: {
        areaEncodingList: [{ cityName: "Please select", id: "" }],
      },
      submit: false,
      typesubmit: false,
    };
  },
  computed: {
    is_add: function () {
      return /add/i.test(location.href);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    show1() {
      if (this.$route.query.id) {
        this.$api.Flavor.manufacturerFind(this.$route.query).then((res) => {
          if (res.success) {
            this.typeform = res.data;
            this.$set(this.typeform, "supplierId", localStorage.supplierId);
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    init() {
      if (this.is_add) {
      } else {
        this.show1();
      }
      this.$api.Dropdown.allCountry().then((res) => {
        if (res.success) {
          this.types.areaEncodingList = this.types.areaEncodingList.concat(
            res.data
          );
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    add() {
      this.$api.Flavor.manufacturerAdd(this.typeform).then((res) => {
        if (res.success) {
          this.$toastBack(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    update() {
      this.$api.Flavor.manufacturerUpdate(this.typeform).then((res) => {
        if (res.success) {
          this.$toastBack(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    rangeform(e) {
      this.submit = true;
    },
    submit_form() {
      this.typesubmit = true;
      if (this.is_add) {
        this.add();
      } else {
        this.update();
      }
    },
    reset_form() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.dropzone {
  width: 150px;
  height: 150px;
  border: 1px dashed #ced4da !important;
}
.dropzone .dz-message {
  margin: 1em 0;
}
.dropzone .dz-message {
  font-size: 16px !important;
}
.dropzone .dz-message {
  color: #ced4da;
  margin: 27px 0 !important;
}
.dropzone .dz-preview {
  margin: -6px;
}
</style>
