var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "text-align": "center" }, style: _vm.margin },
    [_vm._v(" " + _vm._s(_vm.len) + "/" + _vm._s(_vm.max))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }