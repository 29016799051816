var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c("div", { staticClass: "poupol" }, [
        _c(
          "div",
          { staticClass: "flavor" },
          [
            _c(
              "b-card",
              {
                staticStyle: { "max-width": "23rem", height: "780px" },
                attrs: {
                  header: "Brand Popup",
                  "header-text-variant": "#333547",
                  "header-tag": "header",
                  "header-bg-variant": "#333547",
                  title: "",
                  footer:
                    "Brand Popup, the interface displays brand information, flavors and other information.",
                  "footer-tag": "footer",
                  "footer-bg-variant": "#333547",
                  "footer-border-variant": "#333547"
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "header-div-flavor" },
                          [
                            _c("div", { staticClass: "header-div-div" }, [
                              _vm._v("Brand Popup")
                            ]),
                            _vm.popupType != 1
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "header-div-button",
                                    on: {
                                      click: function($event) {
                                        return _vm.clickPopup(1)
                                      }
                                    }
                                  },
                                  [_vm._v("Unused")]
                                )
                              : _vm._e(),
                            _vm.popupType == 1
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "header-div-button1",
                                    attrs: { disabled: "" }
                                  },
                                  [_vm._v("Using")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _c("b-card-img", {
                  attrs: {
                    src:
                      "https://app.unicoreus.com/supplement/static/files/popup-flavor.png",
                    alt: "Image",
                    height: "100%",
                    bottom: ""
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flavor" },
          [
            _c(
              "b-card",
              {
                staticStyle: { "max-width": "23rem", height: "780px" },
                attrs: {
                  header: "Brand URL Popup",
                  "header-text-variant": "#333547",
                  "header-tag": "header",
                  "header-bg-variant": "#333547",
                  title: "",
                  footer:
                    "Brand URL Popup,Jump to the URL corresponding to the brand.",
                  "footer-tag": "footer",
                  "footer-bg-variant": "#333547",
                  "footer-border-variant": "#333547"
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "header-div-flavor" },
                          [
                            _c("div", { staticClass: "header-div-div" }, [
                              _vm._v("Brand URL Popup")
                            ]),
                            _vm.popupType != 4
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "header-div-button",
                                    on: {
                                      click: function($event) {
                                        return _vm.clickPopup(4)
                                      }
                                    }
                                  },
                                  [_vm._v("Unused")]
                                )
                              : _vm._e(),
                            _vm.popupType == 4
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "header-div-button1",
                                    attrs: { disabled: "" }
                                  },
                                  [_vm._v("Using")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _c("b-card-img", {
                  attrs: {
                    src:
                      "https://app.unicoreus.com/supplement/static/files/popup-brandUrl.jpg",
                    alt: "Image",
                    bottom: "",
                    height: "100%"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }