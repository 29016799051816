// export const initCanvasRatio = canvasEl => {
//   const canvas = canvasEl.getContext("2d");
//   const backingStore =
//     canvas.backingStorePixelRatio ||
//     canvas.webkitBackingStorePixelRatio ||
//     canvas.mozBackingStorePixelRatio ||
//     canvas.msBackingStorePixelRatio ||
//     // canvas.oBackingStorePixelRatio ||
//     canvas.backingStorePixelRatio ||
//     1;
//   const ratio = (window.devicePixelRatio || 1) / backingStore;
//
//   const oldWidth = canvasEl.width;
//   const oldHeight = canvasEl.height;
//
//   canvasEl.width = oldWidth * ratio;
//   canvasEl.height = oldHeight * ratio;
//   canvasEl.style.width = `${oldWidth}px`;
//   canvasEl.style.height = `${oldHeight}px`;
//   canvas.scale(ratio, ratio);
//
//   return {
//     canvas,
//     width: oldWidth,
//     height: oldHeight,
//     ratio
//   };
// };

export const initCanvasRatioWidthSize = (canvasEl, width, height) => {
  const canvas = canvasEl.getContext("2d");
  const backingStore =
    canvas.backingStorePixelRatio ||
    canvas.webkitBackingStorePixelRatio ||
    canvas.mozBackingStorePixelRatio ||
    canvas.msBackingStorePixelRatio ||
    canvas.oBackingStorePixelRatio ||
    canvas.backingStorePixelRatio ||
    1;
  const ratio = (window.devicePixelRatio || 1) / backingStore;

  canvasEl.width = width * ratio;
  canvasEl.height = height * ratio;
  canvasEl.style.width = `${width}px`;
  canvasEl.style.height = `${height}px`;
  canvas.scale(ratio, ratio);

  return {
    canvas,
    width,
    height,
    ratio
  };
};
