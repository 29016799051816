var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "8" } },
                [
                  _vm.show
                    ? _c(
                        "b-form",
                        { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Tags Name:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "tags-name",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "tags-name",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                                  placeholder: "Please enter"
                                },
                                model: {
                                  value: _vm.form.labelName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "labelName", $$v)
                                  },
                                  expression: "form.labelName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Tags Icon:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "tags-icon",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("input", {
                                staticClass: "filestyle",
                                staticStyle: {
                                  position: "absolute",
                                  clip: "rect(0px, 0px, 0px, 0px)"
                                },
                                attrs: {
                                  type: "file",
                                  "data-input": "false",
                                  id: "filestyle-1",
                                  tabindex: "-1",
                                  required: !_vm.editId,
                                  oninvalid:
                                    "setCustomValidity('Please upload');",
                                  oninput: "setCustomValidity('');"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.upload($event)
                                  }
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "bootstrap-filestyle input-group"
                                },
                                [
                                  _c("div", {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100%",
                                      height: "35px",
                                      "z-index": "-1"
                                    },
                                    attrs: { name: "filedrag" }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "group-span-filestyle",
                                      attrs: { tabindex: "0" }
                                    },
                                    [
                                      _vm.form.labelIcon
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "10px"
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "100px",
                                                  border: "2px dashed #ccc"
                                                },
                                                attrs: {
                                                  src: _vm.form.labelIcon
                                                }
                                              }),
                                              _vm.photoName
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "10px",
                                                        "vertical-align":
                                                          "bottom"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight": "500"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Picture name : "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        _vm._s(_vm.photoName)
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "btn btn-secondary",
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: { for: "filestyle-1" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "buttonText" },
                                            [_vm._v(" upload picture ")]
                                          )
                                        ]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          " Recommended size of picture: 120 pixels * 120 pixels, supporting JPG and PNG formats. "
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Notes:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "remarks"
                              }
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "remarks",
                                  placeholder: "Enter Notes",
                                  rows: "3",
                                  "max-rows": "6"
                                },
                                model: {
                                  value: _vm.form.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "remarks", $$v)
                                  },
                                  expression: "form.remarks"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            { attrs: { "label-cols-lg": "3" } },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { type: "submit", variant: "primary" }
                                },
                                [_vm._v("Confirm")]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "m-l-5 ml-1",
                                  attrs: { type: "reset" }
                                },
                                [_vm._v("Cancel")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }