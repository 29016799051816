var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MaskView",
    {
      attrs: {
        "border-style": "1px solid #eee",
        outerBorderStyle: "1px dashed #02a7f0",
        "background-color":
          _vm.item.status === 1 ? "rgba(242, 242, 242, 1)" : ""
      },
      on: {
        click: function($event) {
          return _vm.$emit("clickView")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "top",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "vo-row align-items-center vo-gap-4 justify-content-end p-2 cursor"
                },
                [
                  _vm.item.status == 0 &&
                  (_vm.btnMenu("flavorMgmt::Disable") ||
                    (!_vm.isAccountLevel() && _vm.btnMenu("brandMgmt::Create")))
                    ? _c("el-image", {
                        attrs: { src: require("@/assets/enable.svg") },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("clickStatus")
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.item.status == 1 &&
                  (_vm.btnMenu("flavorMgmt::Disable") ||
                    (!_vm.isAccountLevel() && _vm.btnMenu("brandMgmt::Create")))
                    ? _c("el-image", {
                        attrs: { src: require("@/assets/disable.svg") },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("clickStatus")
                          }
                        }
                      })
                    : _vm._e(),
                  _c("el-image", {
                    attrs: {
                      src: require("@/assets/images/brand/ic_eyes.svg")
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("clickView")
                      }
                    }
                  }),
                  _vm.btnMenu("flavorMgmt::Modify") ||
                  (!_vm.isAccountLevel() && _vm.btnMenu("brandMgmt::Create"))
                    ? _c("el-image", {
                        attrs: { src: require("@/assets/edit.svg") },
                        on: {
                          click: function($event) {
                            return _vm.$emit("clickEdit")
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "bottom",
          fn: function() {
            return undefined
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "vo-column align-items-center position-relative w-100" },
        [
          _c(
            "div",
            {
              ref: "container",
              staticClass: "vo-row align-items-center w-100"
            },
            [
              _c("el-image", {
                staticStyle: { flex: "1", height: "200px" },
                attrs: { fit: "contain", src: _vm.item.flavorPicture }
              }),
              _c(
                "div",
                { staticClass: "status position-absolute" },
                [
                  _vm.item.status == 1
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "custom-badge",
                          attrs: { href: "#", variant: _vm.statusVariant }
                        },
                        [_vm._v(_vm._s(_vm.statusText))]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.pieChartWidth !== 0
                ? _c("FlavorPieChart", {
                    staticStyle: { flex: "1" },
                    attrs: {
                      legend: _vm.legend,
                      series: _vm.series,
                      height: "200px",
                      width: _vm.pieChartWidth
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "mt-4" }, [
            _vm._v(_vm._s(_vm.item.modelName))
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }