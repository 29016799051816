<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->

    <b-card>
      <b-row class="vo-items-center">
        <b-col lg="4">
          <div class="vo-row vo-items-center">
            <ReferenceBy label="REFINED BY" />
            <b-input
              style="flex: 1; margin-left: 10px"
              v-model="search_condition.invitationCode"
              placeholder="Search Email,Name,Invitation Code"
            />
          </div>
        </b-col>
        <b-col>
          <date-picker
            v-model="selectTime"
            format="MM/DD/YYYY"
            range
            append-to-body
            lang="en"
            placeholder="Select birthday"
          ></date-picker>
        </b-col>

        <b-col>
          <b-form-select
            id="client-status"
            v-model="search_condition.gender"
            :options="types.sex"
          ></b-form-select>
        </b-col>

        <!-- <b-col>
          <v-select
            v-model="search_condition.gender"
            :options="types.sex"
            label="text"
            :reduce="(option) => option.value"
            placeholder="--All Sex--"
            multiple
          ></v-select>
        </b-col> -->

        <b-col>
          <b-form-select
            id="client-status"
            v-model="search_condition.status"
            :options="types.status"
          ></b-form-select>
        </b-col>

        <b-col>
          <div class="vo-row vo-items-center vo-justify-between">
            <div class="custom-color cursor" @click="clearAll">Clear All</div>
            <b-button variant="info" @click="search">Search</b-button>
          </div>
        </b-col>
      </b-row>
      <div class="box-list"></div>
      <div class="table-responsive mb-0" v-if="isUnicoreus">
        <b-table
          show-empty
          empty-text="No records"
          id="device-table"
          head-variant="light"
          :striped="true"
          ref="table"
          :busy="isBusy"
          :items="provider"
          :fields="fields"
          :per-page="search_condition.pageSize"
          :current-page="search_condition.pageNum"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-includedfields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template v-slot:cell(index)="data">{{
            data.index +
            (search_condition.pageNum - 1) * search_condition.pageSize +
            1
          }}</template>

          <template v-slot:cell(picture)="data">
            <img
              v-if="data.item.picture"
              :src="`${data.item.picture}`"
              alt
              class="avatar-xs rounded-circle mr-2"
            />
            <img
              v-else
              src="../../../../assets/face.jpg"
              alt
              class="avatar-xs rounded-circle mr-2"
            />
          </template>
          <template v-slot:cell(actions)="row">
            <b-button-group>
              <b-button
                v-has="'userAccounts::Disable'"
                v-if="row.item.status"
                size="sm"
                variant="secondary"
                @click="clickStatus(row.item)"
                class="mr-1"
                >Disable</b-button
              >
              <b-button
                v-has="'userAccounts::Disable'"
                v-if="row.item.status"
                size="sm"
                variant="secondary"
                @click="clickStatus(row.item)"
                class="mr-1"
                >Disable</b-button
              >
              <b-button
                v-has="'userAccounts::Delete'"
                v-if="row.item.status"
                size="sm"
                variant="danger"
                @click="del(row.item, row.index, $event.target)"
                class="mr-1"
                >Delete</b-button
              >
              <b-button
                v-has="'userAccounts::Details'"
                size="sm"
                variant="info"
                @click="show(row.item, row.index, $event.target)"
                class="mr-1"
                >Details</b-button
              >
            </b-button-group>
          </template>
        </b-table>
      </div>

      <!-- ikrusher table -->
      <div class="table-responsive mb-0" v-if="isIkrusher">
        <b-table
          show-empty
          empty-text="No records"
          id="device-table"
          head-variant="light"
          :striped="true"
          ref="table"
          :busy="isBusy"
          :items="provider"
          :fields="ikrusherFields"
          :per-page="search_condition.pageSize"
          :current-page="search_condition.pageNum"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-includedfields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template v-slot:cell(index)="data">{{
            data.index +
            (search_condition.pageNum - 1) * search_condition.pageSize +
            1
          }}</template>

          <template v-slot:cell(picture)="data">
            <img
              v-if="data.item.picture"
              :src="`${data.item.picture}`"
              alt
              class="avatar-xs rounded-circle mr-2"
            />
            <img
              v-else
              src="../../../../assets/face.jpg"
              alt
              class="avatar-xs rounded-circle mr-2"
            />
            <!-- {{ data.item.nickName }} -->
          </template>
          <template v-slot:cell(actions)="row">
            <b-button-group>
              <b-button
                v-has="'userAccounts::Details'"
                size="sm"
                variant="info"
                @click="show(row.item, row.index, $event.target)"
                class="mr-1"
                >Details</b-button
              >
              <b-button
                v-has="'userAccounts::Disable'"
                v-if="row.item.status"
                size="sm"
                variant="secondary"
                @click="clickStatus(row.item)"
                class="mr-1"
                >Disable</b-button
              >

              <b-button
                v-has="'userAccounts::Enable'"
                v-else
                size="sm"
                variant="success"
                @click="clickStatus(row.item)"
                class="mr-1"
                >Enable</b-button
              >
              <b-button
                v-has="'userAccounts::Delete'"
                v-if="row.item.status"
                size="sm"
                variant="danger"
                @click="del(row.item, row.index, $event.target)"
                class="mr-1"
                >Delete</b-button
              >
            </b-button-group>
          </template>
          <template v-slot:cell(status)="row">
            <p v-if="row.item.status == 0" class="clientStyle">Disable</p>
            <p v-if="row.item.status == 1">Enable</p>
          </template>
          <template v-slot:cell(registrationArea)>
            <p>Not to disclose</p>
          </template>
        </b-table>
      </div>
      <!-- end table -->
      <!-- pagination -->
      <b-row v-if="has_data">
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="rows != 0">
              <li>
                <p
                  class="pagination mb-0"
                  style="margin-top: 7px; margin-right: 20px"
                >
                  {{ rows }} results
                </p>
              </li>
              <b-pagination
                v-model="search_condition.pageNum"
                :per-page="10"
                :total-rows="rows"
                aria-controls="table"
              ></b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
      <!-- end pagination -->
    </b-card>

    <!-- end main -->

    <!-- <div class="text-center">
			<b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>-->
    <Dialog
      v-model="showAlert"
      :imageSrc="require('@/assets/confirm.svg')"
      okBtn="Yes"
      noBtn="No"
      @ok="disableOrEnable"
      @cancel="cancel"
    >
      <p>{{ showContent }}</p>
    </Dialog>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel1"
    >
      <p>Saved successfully</p>
    </Dialog>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import { download } from "@/api/excel";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import { mapGetters } from "vuex";
import Dialog from "@/components/Dialog";
import ReferenceBy from "@/components/FindByLabel.vue";
import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";
/**
 * User component
 */
export default {
  components: {
    DatePicker,
    Layout,
    PageHeader,
    timeZoneConversion,
    Dialog,
    ReferenceBy,
    VSelect,
  },
  data() {
    return {
      title: "User List",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "User Mgmt",
          // href: "/"
        },
        {
          text: "User List",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "id", label: "User ID", class: "text-center" },
        { key: "picture", label: "Avatar", class: "text-center" },
        { key: "source", label: "Source", class: "text-center" },
        { key: "nickName", label: "Nickname", class: "text-center" },
        //		{key: 'source', label: 'Source',
        //			formatter: (value) => {
        //				return value == 0 ? 'Platform' : 'Supplier';
        //			}
        //		},
        // {
        //   key: "gender",
        //   label: "Gender",
        //   formatter: (value) => {
        //     return value == 1 ? "Female" : value == 0 ? "Male" : "Secret";
        //   },
        // },
        {
          key: "binding",
          label: "Batteries Own by User",
          class: "text-center",
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          formatter: (value) => {
            return value == 0 ? "Disabled" : "Enabled";
          },
        },
        // { key: "createTime", label: "Registration Time", class: "text-center" },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      ikrusherFields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "email", label: "Email", class: "text-center" },
        { key: "picture", label: "Avatar", class: "text-center" },
        { key: "nickName", label: "Name", class: "text-center" },
        { key: "gender", label: "Sex", class: "text-center" },
        { key: "dateOfBirth", label: "Birth Day", class: "text-center" },
        {
          key: "registrationArea",
          label: "Registration Area",
          class: "text-center",
        },
        {
          key: "createTime",
          label: "Registration Time",
          formatter: (value) => {
            return timeZoneConversion.getLocalTimes(value);
          },
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          formatter: (value) => {
            return value == 0 ? "Disabled" : "Enabled";
          },
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      selectTime: [],
      gender: [
        { text: "Please select", value: "", disabled: false },
        { text: "Male", value: 0, disabled: false },
        { text: "Female", value: 1, disabled: false },
      ], //平台
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        birthdayCreateTime: "",
        birthdayEndTime: "",
        invitationCode: "",
        gender: "",
        status: "",
        supplierId: localStorage.supplierId,
      },
      types: {
        sex: [
          { text: "--All Sex--", value: "", disabled: false },
          { text: "Male", value: 0, disabled: false },
          { text: "Female", value: 1, disabled: false },
          { text: "Others", value: 2, disabled: false },
          { text: "Not to disclose", value: 3, disabled: false },
        ],
        status: [
          { text: "--All Status--", value: "", disabled: false },
          { text: "Enable", value: 1, disabled: false },
          { text: "Disable", value: 0, disabled: false },
        ],
      },
      isBusy: false,
      has_data: false,
      showAlert: false,
      successAlear: false,
      showContent: "",
    };
  },
  watch: {
    selectTime: function (val) {
      this.search_condition.birthdayCreateTime = timeZoneConversion.formatTime(
        val[0],
        1
      );
      this.search_condition.birthdayEndTime = timeZoneConversion.formatTime(
        val[1],
        1
      );
    },
  },
  computed: {
    ...mapGetters(["isIkrusher", "isUnicoreus"]),
    rows() {
      return this.totalRows;
    },
  },
  methods: {
    provider(ctx) {
      this.isBusy = true;
      return this.$api.userManage
        .userList(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            this.tableData = x.data;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    gotoAdd() {
      this.$router.push("CartridgeAdd");
    },
    del(a, b, c) {
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$api.userManage.deleteUser({ id: a.id }).then((res) => {
              if (res.message) {
                this.$toast(res);
              }
              if (res.success) {
                this.$refs.table.refresh();
              }
            });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    clickStatus(item) {
      let status = 0;
      if (item.status == 0) {
        status = 1;
        this.showContent = "Are sure to enable this user?";
      } else {
        this.showContent = "Are sure to disable this user?";
      }
      this.showAlert = true;
      this.postData = {
        id: item.id,
        status: status,
      };
    },
    cancel() {
      this.showAlert = false;
    },
    cancel1() {
      this.successAlear = false;
    },
    disableOrEnable() {
      this.$api.userManage.disableOrEnable(this.postData).then((res) => {
        if (res.success) {
          this.showAlert = false;
          this.successAlear = true;
          this.$refs.table.refresh();
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    resetPassword(a, b, c) {
      this.$api.userManage.resetPassword({ id: a.id }).then((res) => {
        if (res.message) {
          this.$toast(res);
        }
        if (res.success) {
          // this.$refs.table.refresh()
          this.$bvToast.toast("new password 666666");
        }
      });
    },
    export_excel() {
      // this.$api.excel.download(this.tableData, "user");
      this.$api.userManage.export_excel(this.search_condition);
    },
    // 导出校验
    export_checkout() {
      // this.searchCheck();
      //时间范围三个月内
      if (this.search_condition.createTime && this.search_condition.endTime) {
        let three = 90 * 24 * 3600 * 1000;
        let date = new Date(this.search_condition.endTime).getTime();
        let threeMonths = date - three;
        if (
          threeMonths > new Date(this.search_condition.createTime).getTime()
        ) {
          this.$bvToast.toast("Please select the time frame within 3 months");
          return;
        }
      }
      this.export_excel();
    },
    show(item, index, button) {
      this.$router.push("userAccountsInfo?id=" + item.id);
    },
    edit(item, index, button) {
      this.$router.push("UserEdit?id=" + item.id);
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    clearAll() {
      this.search_condition = {
        pageNum: 1,
        pageSize: 10,
        birthdayCreateTime: "",
        birthdayEndTime: "",
        invitationCode: "",
        gender: "",
        status: "",
        supplierId: localStorage.supplierId,
      };
      this.$refs.table.refresh();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    searchCheck() {},
  },
};
</script>
