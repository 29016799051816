var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ingredient-wrap" },
    [
      _c(
        "div",
        _vm._l(_vm.ingredientValue, function(item, index) {
          return _c(
            "FormRow",
            {
              key: item.id,
              staticStyle: { "margin-top": "10px" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "right",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vo-row align-items-center input-percent-wrap"
                          },
                          [
                            _c("b-input", {
                              staticClass: "percent-input",
                              class: [
                                item.id === -1000 ? "input-disabled" : "",
                                item.showInputError ? "select-error" : ""
                              ],
                              attrs: { disabled: item.id === -1000 },
                              on: {
                                blur: function($event) {
                                  return _vm.onIngredientBlur(
                                    "ingredients",
                                    index
                                  )
                                },
                                input: function(e) {
                                  return _vm.onIngredientValueChange(index, e)
                                }
                              },
                              model: {
                                value: item.proportion,
                                callback: function($$v) {
                                  _vm.$set(item, "proportion", $$v)
                                },
                                expression: "item.proportion"
                              }
                            }),
                            _c("span", [_vm._v("%")]),
                            item.id !== -1000 && index > 0
                              ? _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onIngredientDelete(
                                          "ingredients",
                                          index
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Delete")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            [
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: index === 0 ? "Ingredients:" : "",
                      "label-cols-lg": "3",
                      "label-align-lg": "right",
                      "label-for": "flavor-ingredients",
                      "label-class": index === 0 ? "requiredRow" : ""
                    }
                  },
                  [
                    _c("b-form-select", {
                      class: item.showSelectError ? "select-error" : "",
                      attrs: {
                        options: _vm.filterIngredientOptions,
                        "value-field": "id",
                        disabled: item.id === -1000,
                        value: item.id
                      },
                      on: {
                        input: function(selectedItem) {
                          return _vm.onIngredientSelectChange(
                            "ingredients",
                            index,
                            selectedItem
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            ],
            2
          )
        }),
        1
      ),
      _c(
        "FormRow",
        {
          scopedSlots: _vm._u([
            {
              key: "right",
              fn: function() {
                return undefined
              },
              proxy: true
            }
          ])
        },
        [
          _c("b-col", { attrs: { offset: "3" } }, [
            _c(
              "a",
              {
                staticClass: "mt-4",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.onIngredientAdd("ingredients")
                  }
                }
              },
              [_vm._v("+Add ingredirents")]
            )
          ])
        ],
        1
      ),
      _vm.terpenesValue.length > 0
        ? _c(
            "div",
            [
              _vm._l(_vm.terpenesValue, function(item, index) {
                return _c(
                  "FormRow",
                  {
                    key: "terpenesValue" + item.id,
                    staticStyle: { "margin-top": "10px" },
                    attrs: { "right-col": 3 },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "right",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vo-row align-items-center input-percent-wrap"
                                },
                                [
                                  _c("b-input", {
                                    staticClass: "percent-input",
                                    class: [
                                      item.id === -1000 ? "input-disabled" : "",
                                      item.showInputError ? "select-error" : ""
                                    ],
                                    attrs: { disabled: item.id === -1000 },
                                    on: {
                                      blur: function($event) {
                                        return _vm.onIngredientBlur(
                                          "terpenes",
                                          index
                                        )
                                      },
                                      update: function(e) {
                                        return _vm.onTerpenesValueChange(
                                          index,
                                          e
                                        )
                                      }
                                    },
                                    model: {
                                      value: item.proportion,
                                      callback: function($$v) {
                                        _vm.$set(item, "proportion", $$v)
                                      },
                                      expression: "item.proportion"
                                    }
                                  }),
                                  _c("span", [_vm._v("%")]),
                                  item.id !== -1000
                                    ? _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:void(0)" },
                                          on: {
                                            click: function($event) {
                                              return _vm.onIngredientDelete(
                                                "terpenes",
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Delete")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: index === 0 ? "Terpenes:" : "",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "flavor-terpenes",
                            "label-class": index === 0 ? "requiredRow" : ""
                          }
                        },
                        [
                          _c("b-select", {
                            class: item.showSelectError ? "select-error" : "",
                            attrs: {
                              value: item.id,
                              "value-field": "id",
                              options:
                                item.id === -1000
                                  ? _vm.otherOptions
                                  : _vm.filterTerpenesOptions,
                              disabled: item.id === -1000
                            },
                            on: {
                              input: function(selectedItem) {
                                return _vm.onIngredientSelectChange(
                                  "terpenes",
                                  index,
                                  selectedItem
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ],
                  2
                )
              }),
              _c(
                "FormRow",
                [
                  _c("b-col", { attrs: { offset: "3" } }, [
                    _c(
                      "a",
                      {
                        staticClass: "mt-4",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.onIngredientAdd("terpenes")
                          }
                        }
                      },
                      [_vm._v("+Add terpenes")]
                    )
                  ])
                ],
                1
              )
            ],
            2
          )
        : _c(
            "div",
            [
              _c(
                "FormRow",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Terpenes:",
                        "label-cols-lg": "3",
                        "label-align-lg": "right"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vo-row align-items-center",
                          staticStyle: { height: "100%", padding: "0 10px" }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.onIngredientAdd("terpenes")
                                }
                              }
                            },
                            [_vm._v("+Add terpenes")]
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }