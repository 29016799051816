<template>
  <div class="vo-row vo-items-center" style="width:110px">
    <el-image :src="icon" fit="contain"/>
    <span>{{ label }}</span>
  </div>

</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: require('@/assets/images/common/ic_reference_by.jpg')
    },
    label: {
      type: String,
      require: true,
    }
  }
}
</script>

<style scoped lang="scss">

</style>