var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "12" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-row",
                    { staticClass: "vo-items-center" },
                    [
                      _c("b-col", { attrs: { lg: "4" } }, [
                        _c(
                          "div",
                          { staticClass: "vo-row vo-items-center" },
                          [
                            _c("ReferenceBy", {
                              attrs: { label: "REFINED BY" }
                            }),
                            _c("b-input", {
                              staticStyle: { flex: "1", "margin-left": "10px" },
                              attrs: { placeholder: "Search Flavor" },
                              model: {
                                value: _vm.search_condition.productName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.search_condition,
                                    "productName",
                                    $$v
                                  )
                                },
                                expression: "search_condition.productName"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticStyle: { "margin-top": "4px" } },
                    [
                      _c(
                        "b-col",
                        { staticStyle: { "margin-left": "120px" } },
                        [
                          _c("b-select", {
                            attrs: { options: _vm.statusOptions },
                            model: {
                              value: _vm.search_condition.status,
                              callback: function($$v) {
                                _vm.$set(_vm.search_condition, "status", $$v)
                              },
                              expression: "search_condition.status"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c("b-select", {
                            attrs: { options: _vm.brandOptions },
                            model: {
                              value: _vm.search_condition.brandName,
                              callback: function($$v) {
                                _vm.$set(_vm.search_condition, "brandName", $$v)
                              },
                              expression: "search_condition.brandName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c("b-select", {
                            attrs: { options: _vm.oilType },
                            model: {
                              value: _vm.search_condition.businessType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.search_condition,
                                  "businessType",
                                  $$v
                                )
                              },
                              expression: "search_condition.businessType"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c("b-select", {
                            attrs: { options: _vm.productType },
                            model: {
                              value: _vm.search_condition.productType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.search_condition,
                                  "productType",
                                  $$v
                                )
                              },
                              expression: "search_condition.productType"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c("b-select", {
                            attrs: { options: _vm.tankCapacityOptios },
                            model: {
                              value: _vm.search_condition.tankCapacity,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.search_condition,
                                  "tankCapacity",
                                  $$v
                                )
                              },
                              expression: "search_condition.tankCapacity"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c("b-select", {
                            attrs: { options: _vm.usageEffectOptions },
                            model: {
                              value: _vm.search_condition.usageEffect,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.search_condition,
                                  "usageEffect",
                                  $$v
                                )
                              },
                              expression: "search_condition.usageEffect"
                            }
                          })
                        ],
                        1
                      ),
                      _c("b-col", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vo-row vo-items-center vo-justify-between"
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: { click: _vm.clearAll }
                              },
                              [_vm._v("Clear All")]
                            ),
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "primary" },
                                on: { click: _vm.search }
                              },
                              [_vm._v("Search")]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "table-responsive mb-0 cursor" },
                    [
                      _vm.isBusy
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "text-center text-danger my-2" },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle"
                                }),
                                _c("strong", [_vm._v("Loading...")])
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "b-row",
                        {
                          staticStyle: { margin: "0" },
                          attrs: {
                            "cols-sm": "1",
                            "cols-md": "2",
                            "cols-lg": "3",
                            "cols-xl": "4"
                          }
                        },
                        _vm._l(_vm.flavorList, function(item) {
                          return _c(
                            "b-col",
                            { key: item.id, staticClass: "mt-4" },
                            [
                              item.viewType === "add"
                                ? _c("CreateProduct", {
                                    attrs: { name: "Create Flavor" },
                                    on: { click: _vm.gotoAdd }
                                  })
                                : _c("FlavorItem", {
                                    attrs: { item: item },
                                    on: {
                                      clickView: function($event) {
                                        return _vm.clickView(item)
                                      },
                                      clickStatus: function($event) {
                                        return _vm.clickStatus(item)
                                      },
                                      clickEdit: function($event) {
                                        return _vm.clickEdit(item)
                                      }
                                    }
                                  })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.has_data
                    ? _c(
                        "b-row",
                        { staticClass: "mt-4" },
                        [
                          _c("b-col", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dataTables_paginate paging_simple_numbers float-right"
                              },
                              [
                                _vm.rows != 0
                                  ? _c(
                                      "ul",
                                      { staticClass: "pagination mb-0" },
                                      [
                                        _c("li", [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "pagination mb-0",
                                              staticStyle: {
                                                "margin-top": "7px",
                                                "margin-right": "20px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.rows) +
                                                  " results "
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("b-pagination", {
                                          ref: "page",
                                          attrs: {
                                            "per-page":
                                              _vm.btnMenu(
                                                "flavorMgmt::Create"
                                              ) ||
                                              (!_vm.isAccountLevel() &&
                                                _vm.btnMenu(
                                                  "brandMgmt::Create"
                                                ))
                                                ? 7
                                                : 8,
                                            "total-rows": _vm.rows
                                          },
                                          on: { change: _vm.onPageChange },
                                          model: {
                                            value: _vm.search_condition.pageNum,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.search_condition,
                                                "pageNum",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "search_condition.pageNum"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: {
            imageSrc: require("@/assets/confirm.svg"),
            okBtn: "Yes",
            noBtn: "No"
          },
          on: { ok: _vm.enableOrDisableFlavor, cancel: _vm.cancel },
          model: {
            value: _vm.showAlert,
            callback: function($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert"
          }
        },
        [_c("p", [_vm._v(_vm._s(_vm.showContent))])]
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/success.png"), noBtn: "OK" },
          on: { cancel: _vm.cancel1 },
          model: {
            value: _vm.successAlear,
            callback: function($$v) {
              _vm.successAlear = $$v
            },
            expression: "successAlear"
          }
        },
        [_c("p", [_vm._v("Saved successfully")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }