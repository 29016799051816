var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("b-card", [
        _c(
          "div",
          { staticClass: "vo-column", staticStyle: { width: "100%" } },
          [
            _c("div", { staticClass: "productDetailRow" }, [
              _c("div", { staticClass: "productDetailColumn" }, [
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productDetailContentColor" }, [
                    _c("strong", [_vm._v("Company Name:")])
                  ]),
                  _c("p", { staticClass: "productDetailContent" }, [
                    _vm._v(_vm._s(_vm.info.companyName))
                  ])
                ]),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productDetailContentColor" }, [
                    _c("strong", [_vm._v("Company Address:")])
                  ]),
                  _c("p", { staticClass: "productDetailContent" }, [
                    _vm._v(_vm._s(_vm.info.addressDetails))
                  ])
                ]),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productDetailContentColor" }, [
                    _c("strong", [_vm._v("Company business:")])
                  ]),
                  _c("p", { staticClass: "productDetailContent" }, [
                    _vm._v(_vm._s(_vm.info.companyBusinessId))
                  ])
                ]),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productDetailContentColor" }, [
                    _c("strong", [_vm._v("Company Email:")])
                  ]),
                  _c("p", { staticClass: "productDetailContent" }, [
                    _vm._v(_vm._s(_vm.info.email))
                  ])
                ]),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productDetailContentColor" }, [
                    _c("strong", [_vm._v("Company Phone:")])
                  ]),
                  _c("p", { staticClass: "productDetailContent" }, [
                    _vm._v(_vm._s(_vm.info.phoneNumber))
                  ])
                ]),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productDetailContentColor" }, [
                    _c("strong", [_vm._v("Company Contacts:")])
                  ]),
                  _c("p", { staticClass: "productDetailContent" }, [
                    _vm._v(_vm._s(_vm.info.name))
                  ])
                ]),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productDetailContentColor" }, [
                    _c("strong", [_vm._v("Company Introduction:")])
                  ]),
                  _c(
                    "div",
                    { staticClass: "productDetailContent" },
                    [
                      _c(
                        "b-row",
                        { staticStyle: { "margin-left": "-10px" } },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "company-introduction",
                                  placeholder: _vm.info.companyIntroduction,
                                  disabled: true,
                                  rows: "10",
                                  "max-rows": "10",
                                  maxlength: "2000"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "vo-row",
                    staticStyle: { "padding-top": "10px" }
                  },
                  [
                    _c("p", { staticClass: "productDetailContentColor" }, [
                      _c("strong", [_vm._v("Brand:")])
                    ]),
                    _c(
                      "div",
                      { staticClass: "productDetailContent" },
                      [
                        _c(
                          "b-row",
                          {
                            staticStyle: { "margin-left": "-10px" },
                            attrs: { "cols-xl": "5" }
                          },
                          _vm._l(_vm.brandList, function(item, i) {
                            return _c(
                              "b-col",
                              { key: i, staticClass: "pd cursor" },
                              [
                                _c("ImageComponets", {
                                  attrs: { image: item.brandPicture }
                                })
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ])
            ])
          ]
        )
      ]),
      _c(
        "b-form-group",
        { attrs: { "label-cols-lg": "2" } },
        [
          _c(
            "b-button",
            {
              staticClass: "custom-width",
              attrs: { type: "reset" },
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_vm._v("Cancel ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }