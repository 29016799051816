var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _vm.show
            ? _c(
                "b-form",
                { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Activity Name:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "activity-name",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "activity-name",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                          placeholder: "Please enter"
                        },
                        model: {
                          value: _vm.form.activityName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "activityName", $$v)
                          },
                          expression: "form.activityName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Activity Time:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "activity-time",
                        "label-class": "requiredRow",
                        description: "Activity time cannot overlap"
                      }
                    },
                    [
                      _c("date-picker", {
                        attrs: {
                          format: "MM/DD/YYYY HH:mm:ss",
                          range: "",
                          "append-to-body": "",
                          lang: "en",
                          placeholder: "Select date",
                          type: "datetime",
                          "disabled-date": _vm.notBeforeToday
                        },
                        on: { change: _vm.onChangeActivityTime },
                        model: {
                          value: _vm.form.activityTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "activityTime", $$v)
                          },
                          expression: "form.activityTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Reward:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "reward",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("div", { staticClass: "reward" }, [
                        _c(
                          "div",
                          { staticClass: "activation" },
                          [
                            _c("span", { staticClass: "activation-title" }, [
                              _vm._v(" Activate the device to get ")
                            ]),
                            _c("b-form-input", {
                              staticStyle: { width: "10%" },
                              attrs: {
                                id: "reward",
                                required: "",
                                type: "number",
                                oninvalid: "setCustomValidity('Please enter');",
                                oninput:
                                  "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                                placeholder: "Please enter"
                              },
                              model: {
                                value: _vm.form.activatedAwardIntegral,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "activatedAwardIntegral",
                                    $$v
                                  )
                                },
                                expression: "form.activatedAwardIntegral"
                              }
                            }),
                            _c("span", { staticClass: "activation-title" }, [
                              _vm._v(" integral ")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "invitation" },
                          [
                            _c("span", { staticClass: "invitation-title" }, [
                              _vm._v(" Invite friends to get ")
                            ]),
                            _c("b-form-input", {
                              staticStyle: { width: "10%" },
                              attrs: {
                                id: "reward",
                                required: "",
                                type: "number",
                                oninvalid: "setCustomValidity('Please enter');",
                                oninput:
                                  "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                                placeholder: "Please enter"
                              },
                              model: {
                                value: _vm.form.inviteAwardIntegral,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "inviteAwardIntegral", $$v)
                                },
                                expression: "form.inviteAwardIntegral"
                              }
                            }),
                            _c("span", { staticClass: "invitation-title" }, [
                              _vm._v(" integral，Invitees get ")
                            ]),
                            _c("b-form-input", {
                              staticStyle: { width: "10%" },
                              attrs: {
                                id: "reward",
                                required: "",
                                type: "number",
                                oninvalid: "setCustomValidity('Please enter');",
                                oninput:
                                  "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                                placeholder: "Please enter"
                              },
                              model: {
                                value: _vm.form.beInvitedAwardIntegral,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "beInvitedAwardIntegral",
                                    $$v
                                  )
                                },
                                expression: "form.beInvitedAwardIntegral"
                              }
                            }),
                            _c("span", { staticClass: "invitation-title" }, [
                              _vm._v(" integral ")
                            ])
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Notes:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "remarks"
                      }
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "remarks",
                          placeholder: "Enter Notes",
                          rows: "3",
                          "max-rows": "6"
                        },
                        model: {
                          value: _vm.form.remarks,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "remarks", $$v)
                          },
                          expression: "form.remarks"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { "label-cols-lg": "2" } },
                    [
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "primary" } },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "b-button",
                        { staticClass: "m-l-5 ml-1", attrs: { type: "reset" } },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }