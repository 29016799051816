import { render, staticRenderFns } from "./curve-recommend.vue?vue&type=template&id=45af0596&scoped=true&"
import script from "./curve-recommend.vue?vue&type=script&lang=js&"
export * from "./curve-recommend.vue?vue&type=script&lang=js&"
import style0 from "./curve-recommend.vue?vue&type=style&index=0&id=45af0596&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45af0596",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\公司文件\\web\\omni\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('45af0596')) {
      api.createRecord('45af0596', component.options)
    } else {
      api.reload('45af0596', component.options)
    }
    module.hot.accept("./curve-recommend.vue?vue&type=template&id=45af0596&scoped=true&", function () {
      api.rerender('45af0596', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/my-curves/curve-recommend/curve-recommend.vue"
export default component.exports