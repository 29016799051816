var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("Steps", { attrs: { Steps: _vm.registSpets, SetData: _vm.SetData } }),
      _c(
        "b-card",
        [
          _c("b-col", { attrs: { lg: "8" } }, [
            _c(
              "p",
              {
                staticClass: "title",
                staticStyle: { "font-size": "16px", "font-weight": "bolder" }
              },
              [
                _c("span", {
                  staticStyle: {
                    display: "inline-block",
                    width: "10px",
                    height: "20px",
                    "vertical-align": "text-top",
                    "background-color": "#626ed4"
                  }
                }),
                _vm._v(" Filling Batch Information ")
              ]
            )
          ]),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "8" } },
                [
                  _c(
                    "b-form",
                    {
                      ref: "firstTabForm",
                      on: { submit: _vm.onSubmit, reset: _vm.onReset }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Batch Name:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "batch-name",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "batch-name",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please enter"
                            },
                            model: {
                              value: _vm.form.batchName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "batchName", $$v)
                              },
                              expression: "form.batchName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Devices Type:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "product-model",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "product-model",
                              options: _vm.types.selectAllTestSmokeBombModel,
                              "value-field": "id",
                              "text-field": "modelName",
                              required: "",
                              oninvalid: "setCustomValidity('Please select');",
                              oninput: "setCustomValidity('')"
                            },
                            on: {
                              change: function($event) {
                                return _vm.changeModelId(
                                  _vm.form.testSmokeBombModeId
                                )
                              }
                            },
                            model: {
                              value: _vm.form.testSmokeBombModeId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "testSmokeBombModeId", $$v)
                              },
                              expression: "form.testSmokeBombModeId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Oil Flavor:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "content-model",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "content-model",
                              options: _vm.types.ModelBySupplierId,
                              required: "",
                              oninvalid: "setCustomValidity('Please select');",
                              oninput: "setCustomValidity('')"
                            },
                            on: {
                              change: function($event) {
                                return _vm.changeModel(
                                  _vm.form.smokeBombModelId,
                                  _vm.types.ModelBySupplierId
                                )
                              }
                            },
                            model: {
                              value: _vm.form.smokeBombModelId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "smokeBombModelId", $$v)
                              },
                              expression: "form.smokeBombModelId"
                            }
                          }),
                          _c("div", { staticClass: "b-row-point" }, [
                            _c("span", { staticClass: "b-row-point-msg" }, [
                              _vm._v("Not on the list? ？")
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "b-row-poin-button",
                                on: { click: _vm.onClickCreateFlavor }
                              },
                              [_vm._v(" Create New Flavor ")]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm.types.flavorOptions.length
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.flavorLabel,
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "product-taste",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-radio-group", {
                                staticClass: "pt-2",
                                attrs: {
                                  options: _vm.types.flavorOptions,
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.flavorId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "flavorId", $$v)
                                  },
                                  expression: "form.flavorId"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Amount to Fill Per Product(mg/ml):",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "capacity",
                            "label-class": "requiredRow",
                            description:
                              "Product Status Will Be Calculated Base on this."
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "capacity",
                              type: "number",
                              min: "0",
                              step: "0.01",
                              required: "",
                              oninvalid:
                                "setCustomValidity('Please enter (g)');",
                              oninput:
                                "setCustomValidity('');value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, '$1$2.$3')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.capacity,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "capacity", $$v)
                              },
                              expression: "form.capacity"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Numbers to Fill on This Batch:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "product-quantity",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "product-quantity",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.batchQuantity,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "batchQuantity", $$v)
                              },
                              expression: "form.batchQuantity"
                            }
                          })
                        ],
                        1
                      ),
                      this.isReplaceable == 1
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Max seconds:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "maxFrequency",
                                "label-class": "requiredRow",
                                description:
                                  "To Prevent Counterfeit by Refill, Cartridge Will Stop Working After Time Reaches."
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "maxFrequency",
                                  type: "number",
                                  min: "0",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, '$1$2.$3');if(value>60000)value=60000;",
                                  placeholder: "Please Enter"
                                },
                                model: {
                                  value: _vm.form.maxFrequency,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "maxFrequency", $$v)
                                  },
                                  expression: "form.maxFrequency"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      this.isReplaceable == 1
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Voltage:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "curveOne",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "voltage-one" },
                                _vm._l(_vm.temperatureList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "voltage-number"
                                    },
                                    [_vm._v(" " + _vm._s(item.value) + " v ")]
                                  )
                                }),
                                0
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "voltage-two",
                                  staticStyle: { width: "100%" }
                                },
                                _vm._l(_vm.temperatureList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "voltage-list" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-plus voltage-plus",
                                        on: {
                                          click: function($event) {
                                            return _vm.onVoltagePlus(index)
                                          }
                                        }
                                      }),
                                      _c("el-slider", {
                                        attrs: {
                                          vertical: "",
                                          step: _vm.step,
                                          min: _vm.min,
                                          max: _vm.max,
                                          height: "150px"
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      }),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-minus voltage-reduce",
                                        on: {
                                          click: function($event) {
                                            return _vm.onVoltageReduce(index)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                              _c(
                                "div",
                                { staticClass: "voltage-three" },
                                _vm._l(8, function(index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "voltage-number"
                                    },
                                    [_vm._v(" " + _vm._s(index) + "s ")]
                                  )
                                }),
                                0
                              ),
                              _c("p", [
                                _vm._v(
                                  " The Voltage curve is between 0.4v-3.4v, after the 8th second, the same as the 8th second "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Oil FIlling Plant:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "manufacturer"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "manufacturer",
                              options: _vm.types.manufacturerEncodingList,
                              "value-field": "id",
                              "text-field": "name"
                            },
                            model: {
                              value: _vm.form.manufacturerEncodingId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "manufacturerEncodingId",
                                  $$v
                                )
                              },
                              expression: "form.manufacturerEncodingId"
                            }
                          }),
                          _c("div", { staticClass: "b-row-point" }, [
                            _c("span", { staticClass: "b-row-point-msg" }, [
                              _vm._v("Not on the List ？")
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "b-row-poin-button",
                                on: { click: _vm.onClickCreatePlant }
                              },
                              [_vm._v(" Add new Oil Filling Plant ")]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Notes:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "remarks"
                          }
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              id: "remarks",
                              placeholder: "Enter Notes",
                              rows: "3",
                              "max-rows": "6"
                            },
                            model: {
                              value: _vm.form.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { lg: "12" }
                        },
                        [
                          _c(
                            "b-button",
                            { attrs: { type: "submit", variant: "primary" } },
                            [_vm._v("Confirm")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticStyle: { "margin-left": "30px" },
                              attrs: { type: "reset", variant: "danger" }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("CreateFlavor", {
        attrs: {
          showCreateFlavor: _vm.showCreateFlavor,
          deviceModelId: _vm.deviceModelId
        },
        on: {
          CancelEvent: _vm.cancelEvent,
          AddFlavorComplete: _vm.addFlavorComplete
        }
      }),
      _c("CreatePlant", {
        attrs: { showCreatePlant: _vm.showCreatePlant },
        on: {
          CancelPlantEvent: _vm.cancelPlantEvent,
          AddPlantComplete: _vm.addPlantComplete
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }