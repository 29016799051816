<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="4">
              <b-form-group
                label="Batch Name"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="batch-name"
              >
                <b-form-input
                  id="batch-name"
                  v-model="search_condition.batchName"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                label="Batch ID"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="batch-number"
              >
                <b-form-input
                  id="batch-number"
                  trim
                  v-model="search_condition.number"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <!-- <b-col lg="4">
              <b-form-group
                label="Brand Owner"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="supplier-name"
              >
                <b-form-input
                  id="supplier-name"
                  trim
                  v-model="search_condition.brandName"
                ></b-form-input>
              </b-form-group>
            </b-col> -->

            <b-col lg="4">
              <b-form-group
                label="Create Time"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="create-time"
              >
                <date-picker
                  v-model="createTime"
                  format="MM/DD/YYYY"
                  range
                  append-to-body
                  lang="en"
                  placeholder="Select date"
                ></date-picker>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                label="Flavor"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="create-time"
              >
                <b-form-input
                  id="modelName"
                  v-model="search_condition.modelName"
                  placeholder=""
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                label="Status"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="status"
              >
                <b-form-select
                  id="preheating"
                  :options="types.statuss"
                  v-model="search_condition.produceStatus"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <b-button
            v-has="'disposableFilling::Create'"
            @click="gotoAdd"
            variant="primary"
            >Create</b-button
          >
          <div class="table-responsive mb-0">
            <b-table
              id="device-table"
              head-variant="light"
              show-empty
              empty-text="No records"
              ref="table"
              :busy="isBusy"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">
                {{
                  data.index +
                  (search_condition.pageNum - 1) * search_condition.pageSize +
                  1
                }}
              </template>
              <template v-slot:cell(batchName)="data">
                <div @click="show(data.item, data.index, $event.target)">
                  {{ data.item.batchName }}
                </div>
              </template>

              <template v-slot:cell(actions)="row">
                <b-button-group>
                  <b-button
                    v-has="'disposableFilling::Details'"
                    v-if="
                      row.item.produceStatus == 1 || row.item.produceStatus == 3
                    "
                    size="sm"
                    variant="info"
                    @click="show(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    Details
                  </b-button>

                  <b-button
                    v-has="'disposableFilling::Perfect flavor info'"
                    v-if="row.item.produceStatus == 2"
                    size="sm"
                    variant="primary"
                    @click="edit(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    Enter Lab Test Results
                  </b-button>
                  <b-button
                    v-has="'disposableFilling::Modify flavor info'"
                    v-if="row.item.produceStatus == 3"
                    size="sm"
                    variant="primary"
                    @click="edit(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    Modify Lab Test Results
                  </b-button>
                </b-button-group>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    ref="page"
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  >
                  </b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

/**
 * Cartridge Batch component
 */
export default {
  components: {
    DatePicker,
    Layout,
    PageHeader,
    timeZoneConversion,
  },
  data() {
    return {
      title: "Disposable Filling",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "Production Manage",
          // href: "/"
        },
        {
          text: "Disposable Filling",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "batchName", label: "Batch Name", class: "text-center" },
        { key: "typeName", label: "Type", class: "text-center" },
        { key: "number", label: "Batch ID", class: "text-center" },
        { key: "batchQuantity", label: "Total Quantity", class: "text-center" },
        {
          key: "quantity",
          label: "Produced Quantity",
          class: "text-center",
        },
        // {
        //   key: "abnormalQuantity",
        //   label: "Abnormal Quantity",
        //   class: "text-center",
        // },
        {
          key: "manufacturerName",
          label: "Production Factory",
          class: "text-center",
        },
        { key: "modelName", label: "Product Model", class: "text-center" },
        { key: "flavorName", label: "Flavor", class: "text-center" },
        {
          key: "produceStatus",
          label: "Status",
          formatter: (value) => {
            let a = "";
            this.types.statuss.filter((item) => {
              if (value === item.value) {
                a = item.text;
              }
            });
            return a;
          },
          class: "text-center",
        },
        {
          key: "createTime",
          label: "Creation Time",
          class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.getLocalTime(value);
          },
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        batchName: "",
        brandName: "",
        modelName: "",
        number: "",
        startTime: "",
        endTime: "",
        produceStatus: "",
        supplierId: localStorage.supplierId,
        type: 3, //类型(1是油弹 2是膏弹 3是电池烟弹以及可换弹 4是电磁烟弹 5是NIC)
      },
      createTime: ["", ""],
      types: {
        statuss: [
          { text: "Please select", value: "", disabled: false },
          { text: "In production", value: 1, disabled: false },
          { text: "Content info to be perfect", value: 2, disabled: false },
          { text: "Completed", value: 3, disabled: false },
        ],
      },
      isBusy: false,
      has_data: false,
    };
  },
  watch: {
    createTime: function (val) {
      this.search_condition.startTime = val[0]
        ? timeZoneConversion.queryTime(val[0])
        : val[0];
      if (val[1]) {
        this.search_condition.endTime = timeZoneConversion.queryTime(
          val[1].getTime() + 24 * 3600 * 1000 - 1
        );
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  methods: {
    provider(ctx) {
      this.isBusy = true;
      return this.$api.CartridgeBatch.list(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
      //this.$bvToast.toast(JSON.stringify(this.search_condition));
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.search_condition.pageNum = 1;
    },
    gotoAdd() {
      this.$router.push("disposableFillingAdd");
    },
    show(item, index, button) {
      this.$router.push("disposableFillingInfo?id=" + item.id);
    },
    // import_excel(item, index, button) {
    //   this.$router.push("CartridgeImport?id=" + item.id);
    // },
    // export_excel(item, index, button) {
    //   this.$api.CartridgeBatch.export_excel(item);
    //   //this.$api.excel.download(this.tableData,"CartridgeBatch")
    // },
    edit(item, index, button) {
      this.$router.push("disposableFillingEdit?id=" + item.id);
    },
    del(a, b, c) {
      this.$api.CartridgeBatch.del({ id: a.id }).then((res) => {
        if (res.message) {
          this.$toast(res);
        }
        if (res.success) {
          this.$refs.table.refresh();
        }
      });
    },
  },
};
</script>
