<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="4">
              <b-form-group
                label="Wax Type"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="model-name"
              >
                <b-form-input
                  id="model-name"
                  trim
                  v-model="search_condition.pasteTypName"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <b-button v-has="'waxType::Create'" @click="gotoAdd" variant="primary"
            >Create</b-button
          >
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="No records"
              id="device-table"
              head-variant="light"
              ref="table"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">{{
                data.index +
                (search_condition.pageNum - 1) * search_condition.pageSize +
                1
              }}</template>

              <template v-slot:cell(flavorPicture)="data">
                <img
                  v-if="data.item.flavorPicture"
                  :src="`${data.item.flavorPicture}`"
                  alt
                  class="avatar-xs rounded-circle mr-2"
                />
              </template>

              <template v-slot:cell(actions)="row">
                <b-button-group>
                  <b-button
                    v-has="'waxType::Modify'"
                    v-if="row.item.supplierId != 0"
                    size="sm"
                    variant="primary"
                    @click="edit(row.item, row.index, $event.target)"
                    class="mr-1"
                    >Modify</b-button
                  >
                  <b-button
                    v-has="'waxType::Preheat Mode'"
                    v-if="row.item.supplierId != 0"
                    size="sm"
                    variant="primary"
                    @click="warmUpMode(row.item, row.index, $event.target)"
                    class="mr-1"
                    >Preheat Mode</b-button
                  >
                  <b-button
                    v-has="'waxType::Heating Mode'"
                    v-if="row.item.supplierId != 0"
                    size="sm"
                    variant="info"
                    @click="heatingMode(row.item, row.index, $event.target)"
                    class="mr-1"
                    >Heating Mode</b-button
                  >
                </b-button-group>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    ref="page"
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>

        <!-- 预热模式 -->
        <b-modal
          ref="modal"
          v-model="warmUpModeShow"
          title="Preheat Mode"
          @hidden="resetWarmUpMode"
          hide-footer
					centered
					no-close-on-esc
					no-close-on-backdrop
        >
          <form
            ref="form"
            @submit="onSubmitWarmUpMode"
            @reset="onResetWarmUpMode"
          >
            <b-form-group
              label="Mode:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="mode"
            >
              <multiselect
                v-model="warmUpModeId"
                :options="types.selectByWarmUpModeId"
                track-by="id"
                label="warmUpName"
                placeholder="Please select"
                :searchable="false"
                :close-on-select="false"
                :multiple="true"
                @input="changeWarmUpModeId(warmUpModeId)"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              >
              </multiselect>
            </b-form-group>
            <b-form-group label-cols-lg="2">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
            </b-form-group>
          </form>
        </b-modal>

        <!-- 加热模式 -->
        <b-modal
          ref="modal"
          v-model="heatingModeShow"
          title="Heating Mode"
          @hidden="resetHeatingMode"
          hide-footer
					centered
					no-close-on-esc
					no-close-on-backdrop
        >
          <form
            ref="form"
            @submit="onSubmitHeatingMode"
            @reset="onResetHeatingMode"
          >
            <b-form-group
              label="Mode Type:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="heatingType"
              style="display: none"
            >
              <b-form-radio-group
                id="radio-group-1"
                v-model="heating.heatingType"
                :options="types.heatingTypes"
                name="radio-options"
                required
                style="margin-top: 5px"
                @change.native="selectByHeatingModeId(heating.heatingModeId)"
              ></b-form-radio-group>
            </b-form-group>
            <b-form-group
              v-if="heating.heatingType == 1"
              label="Mode:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="mode"
            >
              <b-form-select
                id="heatingModeId"
                v-model="heating.singleTemperatureId"
                :options="types.singleHeatingModeId"
                value-field="id"
                text-field="modeName"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              >
              </b-form-select>
            </b-form-group>
            <b-form-group
              v-if="heating.heatingType == 2"
              label="Default Model:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="default-model"
              label-class="requiredRow"
            >
              <b-form-select
                id="default-model"
                v-model="heating.defaultHeatingModeId"
                :options="types.selectByHeatingModeIds"
                value-field="id"
                text-field="modeName"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              >
              </b-form-select>
            </b-form-group>
            <b-form-group
              v-if="heating.heatingType == 2"
              label="Mode:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="mode"
            >
              <multiselect
                v-model="heatingModeId"
                :options="types.selectByHeatingModeId"
                track-by="id"
                label="modeName"
                placeholder="Please select"
                :searchable="false"
                :close-on-select="false"
                :multiple="true"
                @input="changeHeatingModeId(heatingModeId)"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              >
              </multiselect>
            </b-form-group>
            <b-form-group label-cols-lg="3">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
            </b-form-group>
          </form>
        </b-modal>
      </b-col>
    </b-row>
    <!-- end main -->
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "Wax Type",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Wax Type",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#" },
        { key: "pasteTypeName", label: "Wax Type", class: "text-center" },
        {
          key: "typeDescription",
          label: "Type Description",
          class: "text-center",
        },
        { key: "remarks", label: "Notes", class: "text-center" },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        pasteTypName: "",
        supplierId: localStorage.supplierId,
      },
      isBusy: false,
      has_data: false,
      types: {
        selectByWarmUpModeId: [],
        selectByHeatingModeId: [],
        selectByHeatingModeIds: [{ modeName: "Please select", id: 0 }],
        singleHeatingModeId: [{ modeName: "Please select", id: 0 }],
        heatingTypes: [
          { text: "Fixed Temperature", value: 1 },
          { text: "High Wave", value: 2 },
        ],
      },
      warmUp: {
        id: "",
        warmUpModeId: [],
      },
      warmUpModeId: [],
      heatingType: "",
      heatingModeId: [],
      heating: {
        id: "",
        heatingType: "2",
        heatingModeId: [],
        defaultHeatingModeId: 0,
        singleTemperatureId: 0,
      },
      warmUpModeShow: false,
      heatingModeShow: false,
    };
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  methods: {
    provider(ctx) {
      this.isBusy = true;
      return this.$api.warmUpMode
        .pasteTypeList(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    gotoAdd() {
      this.$router.push("waxTypeAdd");
    },
    edit(item, index, button) {
      this.$router.push({ name: "waxTypeEdit", params: item });
    },
    warmUpMode(a, b, c) {
      this.warmUpModeShow = !this.warmUpModeShow;
      this.types.selectByWarmUpModeId = [];
      this.$api.warmUpMode
        .pasteTypeSelectByWarmUpModeId({ id: a.id })
        .then((res) => {
          if (res.success && res.data) {
            this.types.selectByWarmUpModeId =
              this.types.selectByWarmUpModeId.concat(res.data);
            if (a.warmUpModeId.length > 0) {
              this.warmUpModeId = [];
              this.types.selectByWarmUpModeId.forEach((item) => {
                if (a.warmUpModeId.indexOf(item.id) > -1) {
                  this.warmUpModeId.push(item);
                }
              });
            }
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      this.warmUp.id = a.id;
      this.warmUp.warmUpModeId = a.warmUpModeId;
    },
    changeWarmUpModeId(warmUpModeId) {
      let arr = [];
      warmUpModeId.forEach((item) => {
        arr.push(item.id);
      });
      this.warmUp.warmUpModeId = arr;
    },
    resetWarmUpMode() {
      this.warmUp = {
        id: "",
        warmUpModeId: [],
      };
      this.warmUpModeId = [];
    },
    onResetWarmUpMode(evt) {
      evt.preventDefault();
      this.warmUp = {
        id: "",
        warmUpModeId: [],
      };
      this.warmUpModeId = [];
      this.warmUpModeShow = !this.warmUpModeShow;
    },
    onSubmitWarmUpMode(evt) {
      evt.preventDefault();
      this.$api.warmUpMode.associatedWarmUp(this.warmUp).then((res) => {
        if (res.success) {
          this.warmUpModeShow = !this.warmUpModeShow;
          this.$refs.table.refresh();
        }
        if (res.message) {
          this.$toast(res);
        }
      });
    },
    heatingMode(a, b, c) {
      this.heatingModeShow = !this.heatingModeShow;
      this.heating = {
        id: a.id,
        heatingType: 2, //a.singleTemperatureId ? 1 : a.heatingModeId ? 2 : "",
        heatingModeId: a.heatingModeId ? a.heatingModeId : [],
        defaultHeatingModeId: a.defaultHeatingModeId,
        singleTemperatureId: a.singleTemperatureId ? a.singleTemperatureId : 0,
      };
      if (this.heating.heatingType) {
        this.selectByHeatingModeId(a.heatingModeId);
      }
    },
    selectByHeatingModeId(ele) {
      this.types.selectByHeatingModeId = [];
      this.types.selectByHeatingModeIds = [
        { modeName: "Please select", id: 0 },
      ];
      this.types.singleHeatingModeId = [{ modeName: "Please select", id: 0 }];
      this.$api.warmUpMode
        .pasteTypeSelectByHeatingModeId({
          id: this.heating.id,
          heatingType: this.heating.heatingType,
        })
        .then((res) => {
          if (res.success && res.data) {
            if (this.heating.heatingType == 1) {
              this.types.singleHeatingModeId =
                this.types.singleHeatingModeId.concat(res.data);
            }
            if (this.heating.heatingType == 2) {
              this.types.selectByHeatingModeId =
                this.types.selectByHeatingModeId.concat(res.data);
              this.types.selectByHeatingModeIds =
                this.types.selectByHeatingModeIds.concat(res.data);
              if (ele.length > 0) {
                this.heatingModeId = [];
                this.types.selectByHeatingModeId.forEach((item) => {
                  if (ele.indexOf(item.id) > -1) {
                    this.heatingModeId.push(item);
                  }
                });
              }
            }
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
    },
    changeHeatingModeId(heatingModeId) {
      let arr = [];
      heatingModeId.forEach((item) => {
        arr.push(item.id);
      });
      this.heating.heatingModeId = arr;
      console.log(this.heating.heatingModeId);
    },
    resetHeatingMode() {
      this.heating = {
        id: "",
        heatingType: "2",
        heatingModeId: [],
        singleTemperatureId: 0,
      };
      this.heatingModeId = [];
    },
    onResetHeatingMode(evt) {
      evt.preventDefault();
      this.heating = {
        id: "",
        heatingType: "2",
        heatingModeId: [],
        singleTemperatureId: 0,
      };
      this.heatingModeId = [];
      this.heatingModeShow = !this.heatingModeShow;
    },
    onSubmitHeatingMode(evt) {
      evt.preventDefault();
      this.$api.warmUpMode.associatedHeatingMode(this.heating).then((res) => {
        if (res.success) {
          this.heatingModeShow = !this.heatingModeShow;
          this.$refs.table.refresh();
        }
        if (res.message) {
          this.$toast(res);
        }
      });
    },
  },
};
</script>
