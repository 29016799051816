var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("Steps", { attrs: { Steps: _vm.registSpets, SetData: _vm.SetData } }),
      _c(
        "b-card",
        [
          _c("b-col", { attrs: { lg: "12" } }, [
            _c(
              "p",
              {
                staticClass: "title",
                staticStyle: { "font-size": "16px", "font-weight": "bolder" }
              },
              [
                _c("span", {
                  staticStyle: {
                    display: "inline-block",
                    width: "10px",
                    height: "20px",
                    "vertical-align": "text-top",
                    "background-color": "#626ed4"
                  }
                }),
                _vm._v(" Wax Cartridge Information ")
              ]
            )
          ]),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "12" } },
                [
                  _c(
                    "b-form",
                    {
                      ref: "firstTabForm",
                      on: { submit: _vm.onSubmit, reset: _vm.onReset }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Batch Name:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "batch-name",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "batch-name",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please enter"
                            },
                            model: {
                              value: _vm.form.batchName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "batchName", $$v)
                              },
                              expression: "form.batchName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Cartridge Model:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "cartridge-model",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "cartridge-model",
                              options: _vm.types.selectAllTestSmokeBombModel,
                              "value-field": "id",
                              "text-field": "modelName",
                              required: "",
                              oninvalid: "setCustomValidity('Please select');",
                              oninput: "setCustomValidity('')"
                            },
                            model: {
                              value: _vm.form.testSmokeBombModeId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "testSmokeBombModeId", $$v)
                              },
                              expression: "form.testSmokeBombModeId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Wax Type(Multiple Choice):",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "cartridge-model",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("multiselect", {
                            attrs: {
                              options: _vm.types.selectAllPasteTypeList,
                              "track-by": "id",
                              label: "pasteTypeName",
                              placeholder:
                                "Please select , You can multiple choice",
                              searchable: false,
                              "close-on-select": false,
                              multiple: true,
                              required: "",
                              oninvalid:
                                "setCustomValidity('Please select , You can multiple choice');",
                              oninput: "setCustomValidity('')"
                            },
                            on: {
                              input: function($event) {
                                return _vm.changeWaxTypeId(_vm.selectWaxType)
                              }
                            },
                            model: {
                              value: _vm.selectWaxType,
                              callback: function($$v) {
                                _vm.selectWaxType = $$v
                              },
                              expression: "selectWaxType"
                            }
                          }),
                          _c("div", { staticClass: "b-row-point" }, [
                            _c("i", {
                              staticClass: "mdi mdi-refresh mdi-18px",
                              on: { click: _vm.onClickRefreshPasteTypeList }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "b-row-poin-button",
                                on: { click: _vm.onClickCreatePasteType }
                              },
                              [_vm._v(" Create New Wax Type ")]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Numbers to Fill on This Batch:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "cartridge-quantity",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "batch-quantity",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.batchQuantity,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "batchQuantity", $$v)
                              },
                              expression: "form.batchQuantity"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Consumable Brands:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "brand",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "brand",
                              options: _vm.types.selectAllBrandList,
                              required: "",
                              "value-field": "id",
                              "text-field": "brandName",
                              oninvalid: "setCustomValidity('Please select');",
                              oninput: "setCustomValidity('')"
                            },
                            model: {
                              value: _vm.form.brandId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "brandId", $$v)
                              },
                              expression: "form.brandId"
                            }
                          }),
                          _c("div", { staticClass: "b-row-point" }, [
                            _c("i", {
                              staticClass: "mdi mdi-refresh mdi-18px",
                              on: { click: _vm.onClickRefreshBrandList }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "b-row-poin-button",
                                on: { click: _vm.onClickCreateBrand }
                              },
                              [_vm._v(" Create New Consumable Brands ")]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Oil FIlling Plant:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "manufacturer"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "manufacturer",
                              options: _vm.types.manufacturerEncodingList,
                              "value-field": "id",
                              "text-field": "name"
                            },
                            model: {
                              value: _vm.form.manufacturerEncodingId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "manufacturerEncodingId",
                                  $$v
                                )
                              },
                              expression: "form.manufacturerEncodingId"
                            }
                          }),
                          _c("div", { staticClass: "b-row-point" }, [
                            _c("span", { staticClass: "b-row-point-msg" }, [
                              _vm._v("Not on the List ？")
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "b-row-poin-button",
                                on: { click: _vm.onClickCreatePlant }
                              },
                              [_vm._v(" Add new Oil Filling Plant ")]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Notes:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "remarks"
                          }
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              id: "remarks",
                              placeholder: "Enter Notes",
                              rows: "3",
                              "max-rows": "6"
                            },
                            model: {
                              value: _vm.form.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { lg: "12" }
                        },
                        [
                          _c(
                            "b-button",
                            { attrs: { type: "submit", variant: "primary" } },
                            [_vm._v("Confirm")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticStyle: { "margin-left": "30px" },
                              attrs: { type: "reset", variant: "danger" }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("CreatePlant", {
        attrs: { showCreatePlant: _vm.showCreatePlant },
        on: {
          CancelPlantEvent: _vm.cancelPlantEvent,
          AddPlantComplete: _vm.addPlantComplete
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }