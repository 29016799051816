var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("Steps", { attrs: { Steps: _vm.registSpets, SetData: _vm.SetData } }),
      _c(
        "b-card",
        [
          _c("b-col", { attrs: { lg: "12" } }, [
            _c(
              "p",
              {
                staticClass: "title",
                staticStyle: { "font-size": "16px", "font-weight": "bolder" }
              },
              [
                _c("span", {
                  staticStyle: {
                    display: "inline-block",
                    width: "10px",
                    height: "20px",
                    "vertical-align": "text-top",
                    "background-color": "#626ed4"
                  }
                }),
                _vm._v(" Batch Info ")
              ]
            )
          ]),
          _c(
            "b-tabs",
            { staticStyle: { margin: "20px 0" }, attrs: { pills: "" } },
            [
              _c(
                "b-tab",
                { attrs: { title: "Batch Details", active: "" } },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        { attrs: { cols: "3" } },
                        [
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Batch Name：")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.batchName))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Batch ID：")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.number))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Cartridge Model：")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.smokeModel))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Batch Quantity:")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.batchQuantity))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Brand Name:")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.brandName))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Production Factory:")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(_vm.batchDetailsVo.manufacturerName)
                              )
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Notes:")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.remarks))
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Wax Type Details" } },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        { attrs: { cols: "1" } },
                        [
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Wax Type: ")
                            ]),
                            _c(
                              "span",
                              { staticClass: "p-3" },
                              _vm._l(_vm.pasteTypeList, function(item) {
                                return _c(
                                  "b-button",
                                  {
                                    key: item.id,
                                    staticStyle: { "margin-left": "15px" },
                                    attrs: {
                                      pill: "",
                                      variant: "outline-primary"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.pasteTypeName))]
                                )
                              }),
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Cartridge Details" } },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        { attrs: { cols: "3" } },
                        [
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Model Name: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.testSmokeBombModelVo.modelName))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Heating Wire Material: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.testSmokeBombModelVo.heatingWireMaterial
                                )
                              )
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Heating Wire Size: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(_vm.testSmokeBombModelVo.heatingWireSize)
                              )
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Heating Wire Porosity: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.testSmokeBombModelVo.heatingWirePorosity
                                )
                              )
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Oil Hole: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.testSmokeBombModelVo.oilInlet))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Resistance: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.testSmokeBombModelVo.resistance
                                    ? _vm.testSmokeBombModelVo.resistance + "Ω"
                                    : ""
                                )
                              )
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Package Cotton: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.testSmokeBombModelVo.cottonBag == 0
                                    ? "Yes"
                                    : _vm.testSmokeBombModelVo.cottonBag == 1
                                    ? "No"
                                    : ""
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-button",
            {
              attrs: {
                size: "lg",
                type: "reset",
                variant: "outline-secondary"
              },
              on: { click: _vm.onReset }
            },
            [_vm._v("Go Back")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }