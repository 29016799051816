import {format} from "./time"
import axios from "../config/http.js"
import baseUrl from "../config/env.js"
import qs from "qs"


// 使用状态(0是未连接 1是连接中 2是未激活)
function list(params,ctx){
    params.startTime=format(params.startTime)
    params.endTime=format(params.endTime)
   return axios.fetchGet(baseUrl + '/smokeRecord/selectSmokeRecord',params)
}

function add(params){
    return axios.fetchPost(baseUrl + '/smokeRecord/add', params);
}

//{ file:"", supplierId:"",templateNumberId:""}
function addBatch(params){
    return axios.fileUpload(baseUrl + '/smokeRecord/addBatch', params);
}

function find(params){
   return axios.fetchGet(baseUrl + '/smokeRecord/findById',params)
}

function update(params){
    return axios.fetchPost(baseUrl + '/smokeRecord/updateById', params);
}

function disable(params){
    return axios.fetchPost(baseUrl + '/smokeRecord/disable', params);
}

function del(params){
    return axios.fetchPost(baseUrl + '/smokeRecord/deleteById', params);
}

function exportSmokeRecordModel(params){
    let data={
          'supplier-token':localStorage.getItem("auth-token"),
           id:params.id,
    }
    let url=baseUrl+"/smokeRecord/exportSmokeRecordModel"+"?"+qs.stringify(data)
    console.log(url)
    location.href=url
}





export default {
    list,
    find,
    add,
    update,
    del,
    disable,
    exportSmokeRecordModel,
}
