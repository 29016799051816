var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "8" } },
                [
                  _c(
                    "b-form",
                    {
                      ref: "firstTabForm",
                      on: { submit: _vm.onSubmit, reset: _vm.onReset }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Batch Name:",
                            "label-cols-lg": "4",
                            "label-align-lg": "right",
                            "label-for": "batch-name",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "batch-name",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please enter"
                            },
                            model: {
                              value: _vm.form.orderName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "orderName", $$v)
                              },
                              expression: "form.orderName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Content Production Batch:",
                            "label-cols-lg": "4",
                            "label-align-lg": "right",
                            "label-for": "content-batch",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "content-batch",
                              options: _vm.types.batchNumberList,
                              "value-field": "id",
                              "text-field": "number",
                              required: "",
                              oninvalid: "setCustomValidity('Please select');",
                              oninput: "setCustomValidity('')"
                            },
                            model: {
                              value: _vm.form.batchNumberId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "batchNumberId", $$v)
                              },
                              expression: "form.batchNumberId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Planned Clearance Quantity:",
                            "label-cols-lg": "4",
                            "label-align-lg": "right",
                            "label-for": "production-quantity",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "production-quantity",
                              type: "number",
                              min: "0",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.batchQuantity,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "batchQuantity", $$v)
                              },
                              expression: "form.batchQuantity"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Notes:",
                            "label-cols-lg": "4",
                            "label-align-lg": "right",
                            "label-for": "remarks"
                          }
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              id: "remarks",
                              placeholder: "Enter Notes",
                              rows: "3",
                              "max-rows": "6"
                            },
                            model: {
                              value: _vm.form.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { lg: "12" }
                        },
                        [
                          _c(
                            "b-button",
                            { attrs: { type: "submit", variant: "primary" } },
                            [_vm._v("Confirm")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticStyle: { "margin-left": "30px" },
                              attrs: { type: "reset" }
                            },
                            [_vm._v("Return")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }