<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row>
        <b-col lg="8">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <!-- Tags Name 标签名称-->
        <b-form-group
          label="Tags Name:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="tags-name"
          label-class="requiredRow"
        >
          <b-form-input
            id="tags-name"
            v-model="form.labelName"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
            placeholder="Please enter"
          ></b-form-input>
        </b-form-group>
        <!-- Effects Icon 标签表情-->
        <b-form-group
          label="Tags Icon:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="tags-icon"
          label-class="requiredRow"
        >
          <input
            type="file"
            class="filestyle"
            data-input="false"
            id="filestyle-1"
            style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
            tabindex="-1"
            @change="upload($event)"
            :required="!editId"
            oninvalid="setCustomValidity('Please upload');"
            oninput="setCustomValidity('');"
          />

          <div class="bootstrap-filestyle input-group">
            <div
              name="filedrag"
              style="position: absolute; width: 100%; height: 35px; z-index: -1"
            ></div>
            <span class="group-span-filestyle" tabindex="0">
              <div v-if="form.labelIcon" style="margin-bottom: 10px">
                <img
                  :src="form.labelIcon"
                  style="width: 100px; height: 100px; border: 2px dashed #ccc"
                />
                <span
                  style="margin-left: 10px; vertical-align: bottom"
                  v-if="photoName"
                >
                  <span style="font-weight: 500">Picture name : </span
                  >{{ photoName }}</span
                >
              </div>
              <label
                for="filestyle-1"
                style="margin-bottom: 0"
                class="btn btn-secondary"
              >
                <span class="buttonText"> upload picture </span>
              </label>
              <span>
                Recommended size of picture: 120 pixels * 120 pixels, supporting
                JPG and PNG formats.
              </span>
            </span>
          </div>
        </b-form-group>
        <!-- remarks 备注 -->
        <b-form-group
          label="Notes:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="remarks"
        >
          <b-form-textarea
            id="remarks"
            v-model="form.remarks"
            placeholder="Enter Notes"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-cols-lg="3">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
        </b-form-group>
      </b-form>
      <!-- <p>{{form}}</p> -->
        </b-col>
      </b-row>
    </b-card>
  </Layout>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import { onReady, upload_img } from "@/api/upload";

/**
 * Cartridge Model Add
 */
export default {
  components: {
    ckeditor: CKEditor.component,
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: this.editId ? "Usage Effects Modify" : "Usage Effects Create",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "Settings",
          // href: "/"
        },
        {
          text: this.editId ? "Usage Effects Modify" : "Usage Effects Create",
          active: true,
        },
      ],
      photoName: "",
      form: {
        supplierId: localStorage.supplierId,
        labelName: "",
        labelColor: "",
        labelIcon: "",
        remarks: "",
      },
      types: {},
      show: true, // 表单显示
      editor: ClassicEditor,
      editId: this.$route.params.id,
    };
  },
  beforeCreate() {
    this.editId = this.$route.params.id;
  },
  created() {},
  mounted() {
    this.init();
    if (/edit/i.test(window.location.href)) {
      this.show1();
    }
  },
  methods: {
    init() {},
    show1() {
      if (this.$route.params.id) {
        this.form = this.$route.params.item;
      } else {
        this.$bvToast.toast("id?");
      }
    },
    onReady: onReady,
    onSubmit(evt) {
      evt.preventDefault();

      if (/edit/i.test(window.location.href)) {
        this.$api.Label.effectsSaveOrUpdateLabel(this.form).then((res) => {
          if (res.success) {
            this.$toastBack(res);
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      } else {
        this.$api.Label.effectsSaveOrUpdateLabel(this.form).then((res) => {
          if (res.success) {
            this.$toastBack(res);
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      (this.form = {
        supplierId: localStorage.supplierId,
        labelName: "",
        labelColor: "",
        remarks: "",
      }),
        (this.show = false);
      this.$nextTick(() => {
        // this.show = true;
        this.$router.go(-1);
      });
    },
    upload(e) {
      let file = e.target.files[0];
      this.photoName = file.name;
      console.log(file);
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          if (res.message) {
            this.$toast(res);
          }
          if (res.success) {
            this.form.labelIcon = res.data;
            console.log("zzzzzzzz", this.form);
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-color-picker__trigger {
  width: 100px;
}
</style>