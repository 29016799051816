<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-card>
      <div class="productOption">
        <div>
          <strong style="font-size: 18px; color: black">Product Option</strong>
        </div>
        <div class="box-list">
          <div class="vo-row">
            <div class="infoBorder">
              <div class="infoContent">
                <strong style="color: black">Tank Size</strong>
                <b-list-group class="list-group-container">
                  <b-list-group-item
                    :variant="item.variant"
                    v-for="(item, i) in availableTankSize"
                    :key="i"
                  >
                    <div class="vo-row vo-justify-between">
                      <div class="vo-row" v-if="!item.editMode">
                        {{ item.value }}
                      </div>
                      <input v-model="item.value" v-else />
                      <div class="vo-row vo-gap-4">
                        <img
                          v-if="item.editMode"
                          src="@/assets/edit.svg"
                          class="cursor"
                          @click="clickProductEditStatus(item, 1)"
                        />
                        <img
                          v-if="item.status == 1 && item.showButton"
                          src="@/assets/enable.svg"
                          class="cursor"
                          @click="clickProductStatus(item, 1)"
                        />
                        <img
                          v-if="item.status == 0 && item.showButton"
                          src="@/assets/disable.svg"
                          class="cursor"
                          @click="clickProductStatus(item, 1)"
                        />
                        <img
                          v-if="item.showButton"
                          src="@/assets/edit.svg"
                          class="cursor"
                          @click="clickProductEdit(item, i, 1)"
                        />
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <b-input v-model="tankSizeValue" placeholder="" />
                <p class="custom-color cursor" @click="clickAddProduct(1)">
                  +ADD
                </p>
              </div>
            </div>

            <div class="infoBorder">
              <div class="infoContent">
                <strong style="color: black">Other Option</strong>
                <b-list-group class="list-group-container">
                  <b-list-group-item
                    :variant="item.variant"
                    v-for="(item, i) in otherOption"
                    :key="i"
                  >
                    <div class="vo-row vo-justify-between">
                      <div class="vo-row" v-if="!item.editMode">
                        {{ item.value }}
                      </div>
                      <input v-model="item.value" v-else />
                      <div class="vo-row vo-gap-4">
                        <img
                          v-if="item.editMode"
                          src="@/assets/edit.svg"
                          class="cursor"
                          @click="clickProductEditStatus(item, 2)"
                        />
                        <img
                          v-if="item.status == 1 && item.showButton"
                          src="@/assets/enable.svg"
                          class="cursor"
                          @click="clickProductStatus(item, 2)"
                        />
                        <img
                          v-if="item.status == 0 && item.showButton"
                          src="@/assets/disable.svg"
                          class="cursor"
                          @click="clickProductStatus(item, 2)"
                        />
                        <img
                          v-if="item.showButton"
                          src="@/assets/edit.svg"
                          class="cursor"
                          @click="clickProductEdit(item, i, 2)"
                        />
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <b-input v-model="otherOptionValue" placeholder="" />
                <p class="custom-color cursor" @click="clickAddProduct(2)">
                  +ADD
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flavorOption">
        <div>
          <strong style="font-size: 18px; color: black">Flavor Option</strong>
        </div>
        <div class="box-list">
          <div class="vo-row">
            <div class="infoBorder">
              <div class="infoContent">
                <strong style="color: black">Ingredients Type</strong>
                <b-list-group class="list-group-container">
                  <b-list-group-item
                    :variant="item.variant"
                    v-for="(item, i) in componentEncoding"
                    :key="i"
                  >
                    <div class="vo-row vo-justify-between">
                      <div class="vo-row" v-if="!item.editMode">
                        {{ item.value }}
                      </div>
                      <input v-model="item.value" v-else />
                      <div class="vo-row vo-gap-4">
                        <img
                          v-if="item.editMode"
                          src="@/assets/edit.svg"
                          class="cursor"
                          @click="clickProductEditStatus(item, 10)"
                        />
                        <img
                          v-if="item.status == 1 && item.showButton"
                          src="@/assets/enable.svg"
                          class="cursor"
                          @click="clickProductStatus(item, 10)"
                        />
                        <img
                          v-if="item.status == 0 && item.showButton"
                          src="@/assets/disable.svg"
                          class="cursor"
                          @click="clickProductStatus(item, 10)"
                        />
                        <img
                          v-if="item.showButton"
                          src="@/assets/edit.svg"
                          class="cursor"
                          @click="clickProductEdit(item, i, 10)"
                        />
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <b-input v-model="componentEncodingValue" placeholder="" />
                <p class="custom-color cursor" @click="clickAddProduct(10)">
                  +ADD
                </p>
              </div>
            </div>

            <div class="infoBorder">
              <div class="infoContent">
                <strong style="color: black">Terpenes Type</strong>
                <b-list-group class="list-group-container">
                  <b-list-group-item
                    :variant="item.variant"
                    v-for="(item, i) in terpenes"
                    :key="i"
                  >
                    <div class="vo-row vo-justify-between">
                      <div class="vo-row" v-if="!item.editMode">
                        {{ item.value }}
                      </div>
                      <input v-model="item.value" v-else />
                      <div class="vo-row vo-gap-4">
                        <img
                          v-if="item.editMode"
                          src="@/assets/edit.svg"
                          class="cursor"
                          @click="clickProductEditStatus(item, 11)"
                        />
                        <img
                          v-if="item.status == 1 && item.showButton"
                          src="@/assets/enable.svg"
                          class="cursor"
                          @click="clickProductStatus(item, 11)"
                        />
                        <img
                          v-if="item.status == 0 && item.showButton"
                          src="@/assets/disable.svg"
                          class="cursor"
                          @click="clickProductStatus(item, 11)"
                        />
                        <img
                          v-if="item.showButton"
                          src="@/assets/edit.svg"
                          class="cursor"
                          @click="clickProductEdit(item, i, 11)"
                        />
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <b-input v-model="terpenesValue" placeholder="" />
                <p class="custom-color cursor" @click="clickAddProduct(11)">
                  +ADD
                </p>
              </div>
            </div>

            <div class="infoBorder">
              <div class="infoContent">
                <strong style="color: black">Usage Effects Tags</strong>
                <b-list-group class="list-group-container">
                  <b-list-group-item
                    :variant="item.variant"
                    v-for="(item, i) in commodityLabel"
                    :key="i"
                  >
                    <div class="vo-row vo-justify-between">
                      <div class="vo-row" v-if="!item.editMode">
                        {{ item.value }}
                      </div>
                      <input v-model="item.value" v-else />
                      <div class="vo-row vo-gap-4">
                        <img
                          v-if="item.editMode"
                          src="@/assets/edit.svg"
                          class="cursor"
                          @click="clickProductEditStatus(item, 12)"
                        />
                        <img
                          v-if="item.status == 1 && item.showButton"
                          src="@/assets/enable.svg"
                          class="cursor"
                          @click="clickProductStatus(item, 12)"
                        />
                        <img
                          v-if="item.status == 0 && item.showButton"
                          src="@/assets/disable.svg"
                          class="cursor"
                          @click="clickProductStatus(item, 12)"
                        />
                        <img
                          v-if="item.showButton"
                          src="@/assets/edit.svg"
                          class="cursor"
                          @click="clickProductEdit(item, i, 12)"
                        />
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <b-input v-model="commodityLabelValue" placeholder="" />
                <p class="custom-color cursor" @click="clickAddProduct(12)">
                  +ADD
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <!-- end main -->
    <Dialog
      v-model="showAlert"
      :imageSrc="require('@/assets/confirm.svg')"
      okBtn="Yes"
      noBtn="No"
      @ok="ok"
      @cancel="cancel"
    >
      <p>{{ showContent }}</p>
    </Dialog>
    <Dialog
      v-model="successAlear"
      :imageSrc="image"
      noBtn="OK"
      @cancel="cancel1"
    >
      {{ successConent }}
    </Dialog>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import Dialog from "@/components/Dialog";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    timeZoneConversion,
    Dialog,
  },
  data() {
    return {
      title: "Public Info Mgmt",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "System Settings",
          // href: "/"
        },
        {
          text: "Public Info Mgmt",
          active: true,
        },
      ],
      availableTankSize: [],
      otherOption: [],
      componentEncoding: [],
      terpenes: [],
      commodityLabel: [],
      showAlert: false,
      successAlear: false,
      showContent: "",
      tankSizeValue: "",
      otherOptionValue: "",
      componentEncodingValue: "",
      terpenesValue: "",
      commodityLabelValue: "",
      optionType: 1,
      optionModel: 1,
      postData: {},
      successConent: "Saved successfully",
      image: require("@/assets/success.png"),
    };
  },
  computed: {},
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTankSize();
      this.getOtherOption();
      this.getComponentEncoding();
      this.getTerpenes();
      this.getCommodityLabel();
    },
    //查询所有容量
    getTankSize() {
      this.$api.specifications
        .getTankSize({ type: 1 })
        .then((x) => {
          this.availableTankSize = x.data.map((item, i) => {
            let variant = "";
            if (!((i + 1) % 2 === 0)) {
              variant = "secondary";
            }
            return {
              id: item.id,
              value: item.value,
              status: item.status,
              variant: variant,
              type: item.type,
              editMode: false,
              showButton: true,
            };
          });
        })
        .catch((e) => {
          this.has_data = false;
        });
    },
    // 查询所有其他选项
    getOtherOption() {
      this.$api.specifications
        .getTankSize({ type: 2 })
        .then((x) => {
          this.otherOption = x.data.map((item, i) => {
            let variant = "";
            if (!((i + 1) % 2 === 0)) {
              variant = "secondary";
            }
            return {
              id: item.id,
              value: item.value,
              status: item.status,
              variant: variant,
              type: item.type,
              editMode: false,
              showButton: true,
            };
          });
        })
        .catch((e) => {
          this.has_data = false;
        });
    },

    //查询所有成分
    getComponentEncoding() {
      this.$api.specifications
        .getComponentEncoding()
        .then((x) => {
          this.componentEncoding = x.data.map((item, i) => {
            let variant = "";
            if (!((i + 1) % 2 === 0)) {
              variant = "secondary";
            }
            return {
              id: item.id,
              value: item.component,
              status: item.isDelete,
              variant: variant,
              editMode: false,
              showButton: true,
            };
          });
        })
        .catch((e) => {
          this.has_data = false;
        });
    },
    // 查询所有萜烯
    getTerpenes() {
      this.$api.specifications
        .getTerpenes()
        .then((x) => {
          this.terpenes = x.data.map((item, i) => {
            let variant = "";
            if (!((i + 1) % 2 === 0)) {
              variant = "secondary";
            }
            return {
              id: item.id,
              value: item.terpenesName,
              status: item.isDelete,
              variant: variant,
              editMode: false,
              showButton: true,
            };
          });
        })
        .catch((e) => {
          this.has_data = false;
        });
    },
    //查询所有标签
    getCommodityLabel() {
      this.$api.specifications
        .getCommodityLabel()
        .then((x) => {
          this.commodityLabel = x.data.map((item, i) => {
            let variant = "";
            if (!((i + 1) % 2 === 0)) {
              variant = "secondary";
            }
            return {
              id: item.id,
              value: item.labelName,
              status: item.status,
              variant: variant,
              editMode: false,
              showButton: true,
            };
          });
        })
        .catch((e) => {
          this.has_data = false;
        });
    },
    //启用/禁用容量或者其他选项
    disableOrEnableById() {
      this.$api.specifications
        .disableOrEnableById(this.postData)
        .then((res) => {
          if (res.success) {
            this.showAlert = false;
            this.successAlear = true;
            this.getTankSize();
            this.getOtherOption();
            this.image = require("@/assets/success.png");
            this.successConent = "Saved successfully";
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
    },
    // 禁用或者启用成分
    disableOrEnableByComponentId() {
      this.$api.specifications
        .disableOrEnableByComponentId(this.postData)
        .then((res) => {
          if (res.success) {
            this.showAlert = false;
            this.successAlear = true;
            this.getComponentEncoding();
            this.image = require("@/assets/success.png");
            this.successConent = "Saved successfully";
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
    },
    // 禁用或者启用萜烯
    disableOrEnableByTerpenesId() {
      this.$api.specifications
        .disableOrEnableByTerpenesId(this.postData)
        .then((res) => {
          if (res.success) {
            this.showAlert = false;
            this.successAlear = true;
            this.getTerpenes();
            this.image = require("@/assets/success.png");
            this.successConent = "Saved successfully";
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
    },
    // 禁用或者启标签
    disableOrEnableByCommodityLabelId() {
      this.$api.specifications
        .disableOrEnableByCommodityLabelId(this.postData)
        .then((res) => {
          if (res.success) {
            this.showAlert = false;
            this.successAlear = true;
            this.getCommodityLabel();
            this.image = require("@/assets/success.png");
            this.successConent = "Saved successfully";
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
    },
    //添加容量或者其他选项
    addProduct() {
      this.$api.specifications.saveSpecifications(this.postData).then((res) => {
        if (res.success) {
          this.showAlert = false;
          this.successAlear = true;
          this.getTankSize();
          this.getOtherOption();
          this.image = require("@/assets/success.png");
          this.successConent = "Saved successfully";
          this.clearValue();
        } else {
          this.showAlert = false;
          this.successAlear = true;
          this.image = require("@/assets/confirm.svg");
          this.successConent = res.message;
        }
      });
    },
    // 添加成分
    saveComponentEncoding() {
      this.$api.specifications
        .saveComponentEncoding(this.postData)
        .then((res) => {
          if (res.success) {
            this.showAlert = false;
            this.successAlear = true;
            this.getComponentEncoding();
            this.image = require("@/assets/success.png");
            this.successConent = "Saved successfully";
            this.clearValue();
          } else {
            this.showAlert = false;
            this.successAlear = true;
            this.image = require("@/assets/confirm.svg");
            this.successConent = res.message;
          }
        });
    },
    // 添加萜烯
    saveTerpenes() {
      this.$api.specifications.saveTerpenes(this.postData).then((res) => {
        if (res.success) {
          this.showAlert = false;
          this.successAlear = true;
          this.getTerpenes();
          this.image = require("@/assets/success.png");
          this.successConent = "Saved successfully";
          this.clearValue();
        } else {
          this.showAlert = false;
          this.successAlear = true;
          this.image = require("@/assets/confirm.svg");
          this.successConent = res.message;
        }
      });
    },
    // 添加标签
    saveCommodityLabel() {
      this.$api.specifications.saveCommodityLabel(this.postData).then((res) => {
        if (res.success) {
          this.showAlert = false;
          this.successAlear = true;
          this.getCommodityLabel();
          this.image = require("@/assets/success.png");
          this.successConent = "Saved successfully";
          this.clearValue();
        } else {
          this.showAlert = false;
          this.successAlear = true;
          this.image = require("@/assets/confirm.svg");
          this.successConent = res.message;
        }
      });
    },
    clickProductStatus(item, optionModel) {
      this.optionType = 1;
      this.optionModel = optionModel;
      let status = 0;
      if (item.status == 1) {
        status = 0;
        this.showContent = "Are sure to disable this option?";
      } else {
        this.showContent = "Are sure to enble this option?";
        status = 1;
      }
      this.showAlert = true;
      this.postData = {
        id: item.id,
        status: status,
      };
    },
    clickProductEditStatus(item, optionModel) {
      this.optionType = 3;
      this.optionModel = optionModel;
      this.showContent = "Are sure to modify this option?";
      this.postData = { id: item.id, value: item.value };
      this.showAlert = true;
    },
    clickAddProduct(optionModel) {
      if (
        (optionModel == 1 && this.tankSizeValue) ||
        (optionModel == 2 && this.otherOptionValue) ||
        (optionModel == 10 && this.componentEncodingValue) ||
        (optionModel == 11 && this.terpenesValue) ||
        (optionModel == 12 && this.commodityLabelValue)
      ) {
        this.optionType = 2;
        this.optionModel = optionModel;
        if (optionModel == 1) {
          this.showContent = "Are sure to add  tank size?";
          this.postData = { value: this.tankSizeValue, type: 1 };
        } else if (optionModel == 2) {
          this.showContent = "Are sure to add other option?";
          this.postData = { value: this.otherOptionValue, type: 2 };
        } else if (optionModel == 10) {
          this.showContent = "Are sure to ddd ingredients type?";
          this.postData = { value: this.componentEncodingValue };
        } else if (optionModel == 11) {
          this.showContent = "Are sure to ddd terpenes type?";
          this.postData = { value: this.terpenesValue };
        } else if (optionModel == 12) {
          this.showContent = "Are sure to ddd usage effects tags?";
          this.postData = { value: this.commodityLabelValue };
        }
        this.showAlert = true;
      }
    },

    ok() {
      if (this.optionType == 1) {
        if (this.optionModel == 1 || this.optionModel == 2) {
          this.disableOrEnableById();
        } else if (this.optionModel == 10) {
          this.disableOrEnableByComponentId();
        } else if (this.optionModel == 11) {
          this.disableOrEnableByTerpenesId();
        } else if (this.optionModel == 12) {
          this.disableOrEnableByCommodityLabelId();
        }
      } else if (this.optionType == 2 || this.optionType == 3) {
        if (this.optionModel == 1 || this.optionModel == 2) {
          this.addProduct();
        } else if (this.optionModel == 10) {
          this.saveComponentEncoding();
        } else if (this.optionModel == 11) {
          this.saveTerpenes();
        } else if (this.optionModel == 12) {
          this.saveCommodityLabel();
        }
      }
    },
    clearValue() {
      this.tankSizeValue = "";
      this.otherOptionValue = "";
      this.componentEncodingValue = "";
      this.terpenesValue = "";
      this.commodityLabelValue = "";
    },
    cancel() {
      this.showAlert = false;
    },
    cancel1() {
      this.successAlear = false;
    },
    clickProductEdit(item, index, optionModel) {
      if (optionModel == 1) {
        this.availableTankSize.map((item, i) => {
          if (i == index) {
            item.editMode = true;
          }
          item.showButton = false;
        });
        return item;
      } else if (optionModel == 2) {
        this.otherOption.map((item, i) => {
          if (i == index) {
            item.editMode = true;
          }
          item.showButton = false;
        });
        return item;
      } else if (optionModel == 10) {
        this.componentEncoding.map((item, i) => {
          if (i == index) {
            item.editMode = true;
          }
          item.showButton = false;
        });
        return item;
      } else if (optionModel == 11) {
        this.terpenes.map((item, i) => {
          if (i == index) {
            item.editMode = true;
          }
          item.showButton = false;
        });
        return item;
      } else if (optionModel == 12) {
        this.commodityLabel.map((item, i) => {
          if (i == index) {
            item.editMode = true;
          }
          item.showButton = false;
        });
        return item;
      }
    },
  },
};
</script>
<style scoped>
.infoBorder {
  border: 1px solid;
  width: 250px;
  height: 280px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5); /* 添加阴影效果 */
  margin-right: 20px;
}
.infoContent {
  padding: 6px;
}
.flavorOption {
  margin-top: 20px;
}
.list-group-container {
  padding-top: 10px;
  max-height: 170px; /* 设置最大高度 */
  overflow-y: auto; /* 竖直方向超出部分显示滚动条 */
}
.custom-color {
  width: 40px;
}
</style>
