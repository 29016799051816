var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Cartridge Info")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { cols: "3" } },
                [
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Cartridge Type:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.deviceNumber))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Cartridge Id:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.deviceModel))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Heating Wire Material:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.form.bindStatus == null
                              ? ""
                              : _vm.form.bindStatus == 0
                              ? "Unbound"
                              : "Bound"
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Heating Wire Size:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.email))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Heating Wire Porosity:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.repaymentPeriod))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Oil Hole:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.validPeriod))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Resistance:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.activationTime))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Package Cotton:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.versionNo))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("br"),
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Used Info")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { cols: "3" } },
                [
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Preheating Time:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(
                        _vm._s(
                          _vm.form.renewalType == null
                            ? ""
                            : _vm.form.renewalType == 0
                            ? "Automatic"
                            : "Manual"
                        )
                      )
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Preheating Temperature:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.graceDays))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Single Hot Temperature:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.graceDays))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Temperature Curve:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.graceDays))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Time Spent:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.graceDays))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Last Used Date:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.graceDays))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("br"),
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Device Info")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { cols: "3" } },
                [
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Brand:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.graceDays))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("MAC:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.graceDays))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-button",
        {
          attrs: { variant: "outline-secondary" },
          on: { click: _vm.returnPage }
        },
        [_vm._v("Return")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }