<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- 用户资料 -->
    <b-card>
      <b-container>
        <b-row style="margin-left: 20px">
          <b-col lg="8">
            <label class="col-form-label">Apply For:</label>
            <span class="p-3">{{ info.applicationInstructions }}</span>
          </b-col>
          <b-col lg="8">
            <label class="col-form-label">Model:</label>
            <span class="p-3">
              <b-table
                style="display: inline; vertical-align: text-top"
                striped
                hover
                :items="info.modelNumber"
                :fields="fields"
              ></b-table>
            </span>
          </b-col>
          <b-col lg="8">
            <label class="col-form-label">Notes:</label>
            <span class="p-3">{{ info.remarks }}</span>
          </b-col>
          <b-col lg="8">
            <label class="col-form-label">Status:</label>
            <span class="p-3">{{
              info.status == 0
                ? "To be verified"
                : info.status == 1
                ? "Passed"
                : "Rejected"
            }}</span>
          </b-col>
          <b-col lg="8" style="margin-top: 20px">
            <b-button
              type="reset"
              variant="outline-secondary"
              @click="returnPage"
              >Go Back</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <!-- end card -->
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * User Info component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Device Manage",
          // href: "/"
        },
        {
          text: "Order Status Details",
          active: true,
        },
      ],
      info: {},
      fields: [
        {
          key: "modelName",
          label: "Cartridge model",
          class: "text-center",
        },
        {
          key: "number",
          label: "Quantity",
          class: "text-center",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.$api.emptyCartridges
          .smokeRecordApplicationInfo(this.$route.query)
          .then((res) => {
            if (res.success) {
              this.info = res.data;
            } else {
              if (res.message) {
                this.$toast(res);
              }
            }
          });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    returnPage() {
      this.$router.go(-1);
    },
  },
};
</script>
