var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { attrs: { id: "page-topbar" } }, [
    _c("div", { staticClass: "navbar-header" }, [
      _c("div", { staticClass: "d-flex" }, [
        _vm._m(0),
        _c(
          "button",
          {
            staticClass: "btn btn-sm mr-2 font-size-24 d-lg-none header-item",
            attrs: { type: "button" },
            on: { click: _vm.toggleMenu }
          },
          [_c("i", { staticClass: "mdi mdi-menu" })]
        )
      ]),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _vm._m(1),
          _c(
            "b-dropdown",
            {
              staticClass: "d-none d-md-block ml-2",
              attrs: {
                "toggle-class": "header-item",
                right: "",
                variant: "white"
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [
                      _c("img", {
                        staticClass: "mr-2",
                        attrs: {
                          src: require("@/assets/images/flags/us_flag.jpg"),
                          alt: "Header Language",
                          height: "16"
                        }
                      }),
                      _vm._v(" English "),
                      _c("span", { staticClass: "mdi mdi-chevron-down" })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "a",
                {
                  staticClass: "dropdown-item notify-item",
                  attrs: { href: "javascript:void(0);" }
                },
                [
                  _c("img", {
                    staticClass: "mr-1",
                    attrs: {
                      src: require("@/assets/images/flags/germany_flag.jpg"),
                      alt: "user-image",
                      height: "12"
                    }
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("German")
                  ])
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "dropdown-item notify-item",
                  attrs: { href: "javascript:void(0);" }
                },
                [
                  _c("img", {
                    staticClass: "mr-1",
                    attrs: {
                      src: require("@/assets/images/flags/italy_flag.jpg"),
                      alt: "user-image",
                      height: "12"
                    }
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("Italian")
                  ])
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "dropdown-item notify-item",
                  attrs: { href: "javascript:void(0);" }
                },
                [
                  _c("img", {
                    staticClass: "mr-1",
                    attrs: {
                      src: require("@/assets/images/flags/french_flag.jpg"),
                      alt: "user-image",
                      height: "12"
                    }
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("French")
                  ])
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "dropdown-item notify-item",
                  attrs: { href: "javascript:void(0);" }
                },
                [
                  _c("img", {
                    staticClass: "mr-1",
                    attrs: {
                      src: require("@/assets/images/flags/spain_flag.jpg"),
                      alt: "user-image",
                      height: "12"
                    }
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("Spanish")
                  ])
                ]
              ),
              _c(
                "a",
                {
                  staticClass: "dropdown-item notify-item",
                  attrs: { href: "javascript:void(0);" }
                },
                [
                  _c("img", {
                    staticClass: "mr-1",
                    attrs: {
                      src: require("@/assets/images/flags/russia_flag.jpg"),
                      alt: "user-image",
                      height: "12"
                    }
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("Russian")
                  ])
                ]
              )
            ]
          ),
          _c("div", { staticClass: "dropdown d-none d-lg-inline-block" }, [
            _c(
              "button",
              {
                staticClass: "btn header-item noti-icon",
                attrs: { type: "button" },
                on: { click: _vm.initFullScreen }
              },
              [_c("i", { staticClass: "mdi mdi-fullscreen" })]
            )
          ]),
          _c(
            "b-dropdown",
            {
              staticClass: "d-inline-block",
              attrs: {
                "toggle-class": "header-item noti-icon",
                "menu-class": "dropdown-menu-lg p-0",
                right: "",
                variant: "white"
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [
                      _c("i", { staticClass: "mdi mdi-bell-outline" }),
                      _c(
                        "span",
                        { staticClass: "badge badge-danger badge-pill" },
                        [_vm._v("3")]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("div", { staticClass: "p-3" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h5", { staticClass: "m-0 font-size-16" }, [
                      _vm._v("Notifications (258)")
                    ])
                  ])
                ])
              ]),
              _c(
                "div",
                {
                  staticStyle: { "max-height": "230px" },
                  attrs: { "data-simplebar": "" }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "text-reset notification-item",
                      attrs: { href: "" }
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "avatar-xs mr-3" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "avatar-title bg-success rounded-circle font-size-16"
                            },
                            [_c("i", { staticClass: "mdi mdi-cart-outline" })]
                          )
                        ]),
                        _c("div", { staticClass: "media-body" }, [
                          _c("h6", { staticClass: "mt-0 mb-1" }, [
                            _vm._v("Your order is placed")
                          ]),
                          _c(
                            "div",
                            { staticClass: "font-size-12 text-muted" },
                            [
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v(
                                  "Dummy text of the printing and typesetting industry."
                                )
                              ])
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "text-reset notification-item",
                      attrs: { href: "" }
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "avatar-xs mr-3" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "avatar-title bg-warning rounded-circle font-size-16"
                            },
                            [
                              _c("i", {
                                staticClass: "mdi mdi-message-text-outline"
                              })
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "media-body" }, [
                          _c("h6", { staticClass: "mt-0 mb-1" }, [
                            _vm._v("New Message received")
                          ]),
                          _c(
                            "div",
                            { staticClass: "font-size-12 text-muted" },
                            [
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v("You have 87 unread messages")
                              ])
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "text-reset notification-item",
                      attrs: { href: "" }
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "avatar-xs mr-3" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "avatar-title bg-info rounded-circle font-size-16"
                            },
                            [_c("i", { staticClass: "mdi mdi-glass-cocktail" })]
                          )
                        ]),
                        _c("div", { staticClass: "media-body" }, [
                          _c("h6", { staticClass: "mt-0 mb-1" }, [
                            _vm._v("Your item is shipped")
                          ]),
                          _c(
                            "div",
                            { staticClass: "font-size-12 text-muted" },
                            [
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v(
                                  "It is a long established fact that a reader will"
                                )
                              ])
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "text-reset notification-item",
                      attrs: { href: "" }
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "avatar-xs mr-3" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "avatar-title bg-primary rounded-circle font-size-16"
                            },
                            [_c("i", { staticClass: "mdi mdi-cart-outline" })]
                          )
                        ]),
                        _c("div", { staticClass: "media-body" }, [
                          _c("h6", { staticClass: "mt-0 mb-1" }, [
                            _vm._v("Your order is placed")
                          ]),
                          _c(
                            "div",
                            { staticClass: "font-size-12 text-muted" },
                            [
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v(
                                  "Dummy text of the printing and typesetting industry."
                                )
                              ])
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "text-reset notification-item",
                      attrs: { href: "" }
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "avatar-xs mr-3" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "avatar-title bg-danger rounded-circle font-size-16"
                            },
                            [
                              _c("i", {
                                staticClass: "mdi mdi-message-text-outline"
                              })
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "media-body" }, [
                          _c("h6", { staticClass: "mt-0 mb-1" }, [
                            _vm._v("New Message received")
                          ]),
                          _c(
                            "div",
                            { staticClass: "font-size-12 text-muted" },
                            [
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v("You have 87 unread messages")
                              ])
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                ]
              )
            ]
          ),
          _c(
            "b-dropdown",
            {
              staticClass: "d-inline-block",
              attrs: {
                right: "",
                "toggle-class": "header-item",
                variant: "white"
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [
                      _c("img", {
                        staticClass: "rounded-circle header-profile-user",
                        attrs: {
                          src: require("@/assets/images/users/user-4.jpg"),
                          alt: "Header Avatar"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
                _c("i", {
                  staticClass:
                    "mdi mdi-account-circle font-size-17 align-middle mr-1"
                }),
                _vm._v(" Profile ")
              ]),
              _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
                _c("i", {
                  staticClass: "mdi mdi-wallet font-size-17 align-middle mr-1"
                }),
                _vm._v(" My Wallet ")
              ]),
              _c(
                "a",
                { staticClass: "dropdown-item d-block", attrs: { href: "#" } },
                [
                  _c(
                    "span",
                    { staticClass: "badge badge-success float-right" },
                    [_vm._v("11")]
                  ),
                  _c("i", {
                    staticClass:
                      "mdi mdi-settings font-size-17 align-middle mr-1"
                  }),
                  _vm._v(" Settings ")
                ]
              ),
              _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
                _c("i", {
                  staticClass:
                    "mdi mdi-lock-open-outline font-size-17 align-middle mr-1"
                }),
                _vm._v(" Lock screen ")
              ]),
              _c("div", { staticClass: "dropdown-divider" }),
              _c(
                "a",
                {
                  staticClass: "dropdown-item text-danger",
                  attrs: { href: "#" }
                },
                [
                  _c("i", {
                    staticClass:
                      "bx bx-power-off font-size-17 align-middle mr-1 text-danger"
                  }),
                  _vm._v(" Logout ")
                ]
              )
            ]
          ),
          _c("div", { staticClass: "dropdown d-inline-block" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn header-item noti-icon right-bar-toggle toggle-right",
                attrs: { type: "button" },
                on: { click: _vm.toggleRightSidebar }
              },
              [
                _c("i", {
                  staticClass: "mdi mdi-settings-outline toggle-right"
                })
              ]
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "navbar-brand-box" }, [
      _c("a", { staticClass: "logo logo-dark", attrs: { href: "/" } }, [
        _c("span", { staticClass: "logo-sm" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/unicoreusLogo.png"),
              alt: "",
              height: "22"
            }
          })
        ]),
        _c("span", { staticClass: "logo-lg" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/unicoreusLogo.png"),
              alt: "",
              height: "17"
            }
          })
        ])
      ]),
      _c("a", { staticClass: "logo logo-light", attrs: { href: "/" } }, [
        _c("span", { staticClass: "logo-sm" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/unicoreusLogo.png"),
              alt: "",
              height: "22"
            }
          })
        ]),
        _c("span", { staticClass: "logo-lg" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/unicoreusLogo.png"),
              alt: "",
              height: "18"
            }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("form", { staticClass: "app-search d-none d-lg-block" }, [
      _c("div", { staticClass: "position-relative" }, [
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "Search..." }
        }),
        _c("span", { staticClass: "fa fa-search" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }