<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <b-card> -->
    <Steps :Steps="registSpets" :SetData="SetData" />
    <!-- </b-card> -->
    <b-card>
      <b-col lg="12">
        <p class="title" style="font-size: 16px; font-weight: bolder">
          <span
            style="
              display: inline-block;
              width: 10px;
              height: 20px;
              vertical-align: text-top;
              background-color: #626ed4;
            "
          ></span>
          Wax Cartridge Information
        </p>
      </b-col>
      <b-row>
        <b-col lg="12">
          <b-form ref="firstTabForm" @submit="onSubmit" @reset="onReset">
            <!-- Batch Name 批次名称 -->
            <b-form-group
              label="Batch Name:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="batch-name"
              label-class="requiredRow"
            >
              <b-form-input
                id="batch-name"
                v-model="form.batchName"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please enter"
              ></b-form-input>
            </b-form-group>

            <!-- 烟弹型号 -->
            <b-form-group
              label="Cartridge Model:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="cartridge-model"
              label-class="requiredRow"
            >
              <b-form-select
                id="cartridge-model"
                v-model="form.testSmokeBombModeId"
                :options="types.selectAllTestSmokeBombModel"
                value-field="id"
                text-field="modelName"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
            </b-form-group>

            <!-- 膏类型（多选） -->
            <b-form-group
              label="Wax Type(Multiple Choice):"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="cartridge-model"
              label-class="requiredRow"
            >
              <multiselect
                v-model="selectWaxType"
                :options="types.selectAllPasteTypeList"
                track-by="id"
                label="pasteTypeName"
                placeholder="Please select , You can multiple choice"
                :searchable="false"
                :close-on-select="false"
                :multiple="true"
                @input="changeWaxTypeId(selectWaxType)"
                required
                oninvalid="setCustomValidity('Please select , You can multiple choice');"
                oninput="setCustomValidity('')"
              >
              </multiselect>
              <div class="b-row-point">
                <i
                  class="mdi mdi-refresh mdi-18px"
                  @click="onClickRefreshPasteTypeList"
                ></i>
                <div class="b-row-poin-button" @click="onClickCreatePasteType">
                  Create New Wax Type
                </div>
              </div>
            </b-form-group>

            <!-- Batch Quantity 批次数量 -->
            <b-form-group
              label="Numbers to Fill on This Batch:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="cartridge-quantity"
              label-class="requiredRow"
            >
              <b-form-input
                id="batch-quantity"
                v-model="form.batchQuantity"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <!-- Brand 选择品牌 -->
            <b-form-group
              label="Consumable Brands:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="brand"
              label-class="requiredRow"
            >
              <b-form-select
                id="brand"
                v-model="form.brandId"
                :options="types.selectAllBrandList"
                required
                value-field="id"
                text-field="brandName"
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
              <div class="b-row-point">
                <i
                  class="mdi mdi-refresh mdi-18px"
                  @click="onClickRefreshBrandList"
                ></i>
                <div class="b-row-poin-button" @click="onClickCreateBrand">
                  Create New Consumable Brands
                </div>
              </div>
            </b-form-group>

            <!-- Manufacturer Name 生产厂家 -->
            <b-form-group
              label="Oil FIlling Plant:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="manufacturer"
            >
              <b-form-select
                id="manufacturer"
                v-model="form.manufacturerEncodingId"
                :options="types.manufacturerEncodingList"
                value-field="id"
                text-field="name"
              ></b-form-select>
              <div class="b-row-point">
                <span class="b-row-point-msg">Not on the List ？</span>
                <div class="b-row-poin-button" @click="onClickCreatePlant">
                  Add new Oil Filling Plant
                </div>
              </div>
            </b-form-group>

            <!-- 备注 -->
            <b-form-group
              label="Notes:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="remarks"
            >
              <b-form-textarea
                id="remarks"
                v-model="form.remarks"
                placeholder="Enter Notes"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-col lg="12" style="text-align: center">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" variant="danger" style="margin-left: 30px"
                >Cancel</b-button
              >
            </b-col>
          </b-form>
        </b-col>
        <!-- end col -->
      </b-row>

      <!-- end row -->
      <!-- </tab-content> -->
      <!-- </form-wizard> -->
    </b-card>
    <CreatePlant
      :showCreatePlant="showCreatePlant"
      @CancelPlantEvent="cancelPlantEvent"
      @AddPlantComplete="addPlantComplete"
    ></CreatePlant>
  </Layout>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import Multiselect from "vue-multiselect";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Steps from "./wax-cartridge-step.vue";
import CreateFlavor from "@/components/modal/create-new-flavor";
import CreatePlant from "@/components/modal/create-new-plant";

/**
 * Cartridge Batch Add component
 */

export default {
  components: {
    Layout,
    PageHeader,
    FormWizard,
    TabContent,
    Multiselect,
    Steps,
    CreateFlavor,
    CreatePlant,
  },
  computed: {
    is_add: function () {
      return /add/i.test(location.href);
    },
  },
  beforeCreate() {
    // this.init();
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      title: "Wax Cartridge Create",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Manage",
          // href: "/"
        },
        {
          text: "Wax Cartridge Create",
          active: true,
        },
      ],
      registSpets: 0,
      //SetData: ["Create Batch", "Import Production Details", "Complete"],
      SetData: [
        "Create Production Batch Order",
        "In Production",
        "Production Completed",
      ],
      types: {
        flavorOptions: [],
        manufacturerEncodingList: [{ name: "Please select", id: "" }],
        selectAllTestSmokeBombModel: [{ modelName: "Please select", id: "" }],
        selectAllPasteTypeList: [],
        selectAllBrandList: [{ brandName: "Please select", id: "" }],
      },
      selectWaxType: [],
      form: {
        batchName: "",
        smokeBombModelId: "",
        flavorId: "",
        testSmokeBombModeId: "",
        capacity: "",
        maxFrequency: "",
        batchQuantity: 100,
        manufacturerEncodingId: "",
        remarks: "",
        defaultWarmHeatingId: "",
        defaultWarmUpId: "",
        type: 2, //类型(1是油弹 2是膏弹 3是电池烟弹 4是电磁烟弹 5是NIC)
        brandId: "", //品牌编号
        pasteTypeId: [], //膏类型编号
      },
      flavorLabel: "Flavor:",
      showCreatePlant: false,
    };
  },
  methods: {
    init() {
      this.$api.Dropdown.manufacturerEncodingList().then((res) => {
        if (res.success && res.data) {
          this.types.manufacturerEncodingList =
            this.types.manufacturerEncodingList.concat(res.data);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
      this.$api.Dropdown.selectAllTestSmokeBombModel({ type: 2 }).then(
        (res) => {
          if (res.success && res.data) {
            this.types.selectAllTestSmokeBombModel =
              this.types.selectAllTestSmokeBombModel.concat(res.data);
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        }
      );
      this.$api.Dropdown.selectAllPasteType().then((res) => {
        if (res.success && res.data) {
          this.types.selectAllPasteTypeList =
            this.types.selectAllPasteTypeList.concat(res.data);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
      this.$api.Dropdown.brandList().then((res) => {
        if (res.success && res.data) {
          this.types.selectAllBrandList = this.types.selectAllBrandList.concat(
            res.data
          );
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$api.CartridgeBatch.add(this.form).then((res) => {
        if (res.success) {
          this.$toastBack(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.$router.go(-1);
    },

    //#region Create New Plant
    onClickCreatePlant() {
      this.showCreatePlant = true;
    },
    //#endregion
    //#region Accept child components Value
    cancelPlantEvent(value) {
      this.showCreatePlant = value;
    },
    //#endregion
    //#region  Create New Plant Complete
    addPlantComplete(value) {
      this.$api.Dropdown.manufacturerEncodingList().then((res) => {
        if (res.success && res.data) {
          this.types.manufacturerEncodingList = [
            { name: "Please select", id: "" },
          ];
          this.types.manufacturerEncodingList =
            this.types.manufacturerEncodingList.concat(res.data);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    //#endregion
    changeWaxTypeId(waxTypeId) {
      let arr = [];
      waxTypeId.forEach((item) => {
        arr.push(item.id);
      });
      this.form.pasteTypeId = arr;
    },
    onClickRefreshPasteTypeList() {
      this.$api.Dropdown.selectAllPasteType().then((res) => {
        if (res.success && res.data) {
          this.types.selectAllPasteTypeList = [];
          this.types.selectAllPasteTypeList =
            this.types.selectAllPasteTypeList.concat(res.data);
          this.$toast(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onClickCreatePasteType() {
      let routeUrl = this.$router.resolve({
        path: "/productionMgmt/waxTypeAdd",
      });
      window.open(routeUrl.href, "_blank");
      //this.$router.push("pasteTypeAdd");
    },
    onClickRefreshBrandList() {
      this.$api.Dropdown.brandList().then((res) => {
        if (res.success && res.data) {
          this.types.selectAllBrandList = [
            { brandName: "Please select", id: "" },
          ];
          this.types.selectAllBrandList = this.types.selectAllBrandList.concat(
            res.data
          );
          this.$toast(res);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onClickCreateBrand() {
      let routeUrl = this.$router.resolve({
        path: "/dataDictionary/consumableBrandsAdd",
      });
      window.open(routeUrl.href, "_blank");
      //this.$router.push({ path: "/dataDictionary/brandAdd" });
    },
  },
};
</script>
