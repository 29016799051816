/**
 * 客服模块 模块接口
 */
// import base from "./base.js";
import axios from "../config/http.js"
import baseUrl from "../config/env.js"

const assistantManage = {
	//查询客服List
	selectAssistantList(params) {
		return axios.fetchGet(baseUrl + '/assistant/selectAssistantList',params);
	},

}

export default assistantManage;
