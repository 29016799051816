var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal",
        centered: "",
        title: "Assistant",
        "ok-title": "Confirm",
        "no-close-on-esc": "",
        "no-close-on-backdrop": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function() {
            return [_c("div", { staticClass: "w-100" })]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.showAssistant,
        callback: function($$v) {
          _vm.showAssistant = $$v
        },
        expression: "showAssistant"
      }
    },
    [
      _c("div", { staticClass: "col-xl-4" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "chat-conversation" }, [
              _c(
                "ul",
                {
                  staticClass: "conversation-list",
                  staticStyle: { "max-height": "367px" },
                  attrs: { "data-simplebar": "init" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "simplebar-wrapper",
                      staticStyle: { margin: "0px -10px" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "simplebar-height-auto-observer-wrapper"
                        },
                        [
                          _c("div", {
                            staticClass: "simplebar-height-auto-observer"
                          })
                        ]
                      ),
                      _c("div", { staticClass: "simplebar-mask" }, [
                        _c(
                          "div",
                          {
                            staticClass: "simplebar-offset",
                            staticStyle: { right: "-17px", bottom: "0px" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "simplebar-content-wrapper",
                                staticStyle: {
                                  height: "auto",
                                  overflow: "hidden scroll"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "simplebar-content",
                                    staticStyle: { padding: "0px 10px" }
                                  },
                                  [
                                    _c("li", { staticClass: "clearfix" }, [
                                      _c(
                                        "div",
                                        { staticClass: "chat-avatar" },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "avatar-xs rounded-circle",
                                            attrs: {
                                              src:
                                                "https://themesbrand.com/veltrix/layouts/assets/images/users/user-2.jpg",
                                              alt: "男性"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "time" },
                                            [
                                              _c(
                                                "font",
                                                {
                                                  staticStyle: {
                                                    "vertical-align": "inherit"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "font",
                                                    {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "inherit"
                                                      }
                                                    },
                                                    [_vm._v("10:00")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "conversation-text" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "ctext-wrap" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "user-name" },
                                                [
                                                  _c(
                                                    "font",
                                                    {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "inherit"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "font",
                                                        {
                                                          staticStyle: {
                                                            "vertical-align":
                                                              "inherit"
                                                          }
                                                        },
                                                        [_vm._v("约翰·迪奥")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "p",
                                                [
                                                  _c(
                                                    "font",
                                                    {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "inherit"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "font",
                                                        {
                                                          staticStyle: {
                                                            "vertical-align":
                                                              "inherit"
                                                          }
                                                        },
                                                        [_vm._v(" 你好！ ")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("li", { staticClass: "clearfix odd" }, [
                                      _c(
                                        "div",
                                        { staticClass: "chat-avatar" },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "avatar-xs rounded-circle",
                                            attrs: {
                                              src:
                                                "https://themesbrand.com/veltrix/layouts/assets/images/users/user-3.jpg",
                                              alt: "女性"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "time" },
                                            [
                                              _c(
                                                "font",
                                                {
                                                  staticStyle: {
                                                    "vertical-align": "inherit"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "font",
                                                    {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "inherit"
                                                      }
                                                    },
                                                    [_vm._v("10:01")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "conversation-text" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "ctext-wrap" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "user-name" },
                                                [
                                                  _c(
                                                    "font",
                                                    {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "inherit"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "font",
                                                        {
                                                          staticStyle: {
                                                            "vertical-align":
                                                              "inherit"
                                                          }
                                                        },
                                                        [_vm._v("史密斯")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "p",
                                                [
                                                  _c(
                                                    "font",
                                                    {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "inherit"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "font",
                                                        {
                                                          staticStyle: {
                                                            "vertical-align":
                                                              "inherit"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " 你好你好吗？我们下次见面怎么样？ "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("li", { staticClass: "clearfix" }, [
                                      _c(
                                        "div",
                                        { staticClass: "chat-avatar       " },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "avatar-xs rounded-circle",
                                            attrs: {
                                              src:
                                                "https://themesbrand.com/veltrix/layouts/assets/images/users/user-2.jpg",
                                              alt: "男性"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "time" },
                                            [
                                              _c(
                                                "font",
                                                {
                                                  staticStyle: {
                                                    "vertical-align": "inherit"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "font",
                                                    {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "inherit"
                                                      }
                                                    },
                                                    [_vm._v("10:04")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "conversation-text" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "ctext-wrap" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "user-name" },
                                                [
                                                  _c(
                                                    "font",
                                                    {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "inherit"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "font",
                                                        {
                                                          staticStyle: {
                                                            "vertical-align":
                                                              "inherit"
                                                          }
                                                        },
                                                        [_vm._v("约翰·迪奥")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "p",
                                                [
                                                  _c(
                                                    "font",
                                                    {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "inherit"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "font",
                                                        {
                                                          staticStyle: {
                                                            "vertical-align":
                                                              "inherit"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " 是的，一切都很好 "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("li", { staticClass: "clearfix odd" }, [
                                      _c(
                                        "div",
                                        { staticClass: "chat-avatar" },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "avatar-xs rounded-circle",
                                            attrs: {
                                              src:
                                                "https://themesbrand.com/veltrix/layouts/assets/images/users/user-3.jpg",
                                              alt: "男性"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "time" },
                                            [
                                              _c(
                                                "font",
                                                {
                                                  staticStyle: {
                                                    "vertical-align": "inherit"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "font",
                                                    {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "inherit"
                                                      }
                                                    },
                                                    [_vm._v("10:05")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "conversation-text" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "ctext-wrap" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "user-name" },
                                                [
                                                  _c(
                                                    "font",
                                                    {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "inherit"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "font",
                                                        {
                                                          staticStyle: {
                                                            "vertical-align":
                                                              "inherit"
                                                          }
                                                        },
                                                        [_vm._v("史密斯")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "p",
                                                [
                                                  _c(
                                                    "font",
                                                    {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "inherit"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "font",
                                                        {
                                                          staticStyle: {
                                                            "vertical-align":
                                                              "inherit"
                                                          }
                                                        },
                                                        [_vm._v(" 哇，太棒了 ")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("li", { staticClass: "clearfix odd" }, [
                                      _c(
                                        "div",
                                        { staticClass: "chat-avatar" },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "avatar-xs rounded-circle",
                                            attrs: {
                                              src:
                                                "https://themesbrand.com/veltrix/layouts/assets/images/users/user-3.jpg",
                                              alt: "男性"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "time" },
                                            [
                                              _c(
                                                "font",
                                                {
                                                  staticStyle: {
                                                    "vertical-align": "inherit"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "font",
                                                    {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "inherit"
                                                      }
                                                    },
                                                    [_vm._v("10:08")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "conversation-text" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "ctext-wrap" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "user-name mb-2"
                                                },
                                                [
                                                  _c(
                                                    "font",
                                                    {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "inherit"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "font",
                                                        {
                                                          staticStyle: {
                                                            "vertical-align":
                                                              "inherit"
                                                          }
                                                        },
                                                        [_vm._v("史密斯")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c("img", {
                                                staticClass: "rounded me-2",
                                                attrs: {
                                                  src:
                                                    "https://themesbrand.com/veltrix/layouts/assets/images/small/img-1.jpg",
                                                  alt: "",
                                                  height: "48"
                                                }
                                              }),
                                              _c("img", {
                                                staticClass: "rounded",
                                                attrs: {
                                                  src:
                                                    "https://themesbrand.com/veltrix/assets/images/small/img-2.jpg",
                                                  alt: "",
                                                  height: "48"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]),
                      _c("div", {
                        staticClass: "simplebar-placeholder",
                        staticStyle: { width: "auto", height: "480px" }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "simplebar-track simplebar-horizontal",
                      staticStyle: { visibility: "hidden" }
                    },
                    [
                      _c("div", {
                        staticClass: "simplebar-scrollbar",
                        staticStyle: {
                          transform: "translate3d(0px, 0px, 0px)",
                          display: "none"
                        }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "simplebar-track simplebar-vertical",
                      staticStyle: { visibility: "visible" }
                    },
                    [
                      _c("div", {
                        staticClass: "simplebar-scrollbar",
                        staticStyle: {
                          height: "280px",
                          transform: "translate3d(0px, 87px, 0px)",
                          display: "block"
                        }
                      })
                    ]
                  )
                ]
              ),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-9 col-8 chat-inputbar" }, [
                  _c("input", {
                    staticClass: "form-control chat-input",
                    attrs: { type: "text", placeholder: "输入您的文字" }
                  })
                ]),
                _c("div", { staticClass: "col-sm-3 col-4 chat-send" }, [
                  _c("div", { staticClass: "d-grid" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "submit" }
                      },
                      [
                        _c(
                          "font",
                          { staticStyle: { "vertical-align": "inherit" } },
                          [
                            _c(
                              "font",
                              { staticStyle: { "vertical-align": "inherit" } },
                              [_vm._v("发送")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }