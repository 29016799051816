<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="4">
              <!-- 分享用户 -->
              <b-form-group
                label="Share User"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="share-user"
              >
                <b-form-input
                  id="share-user"
                  v-model="search_condition.shareUser"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="No records"
              id="table"
              head-variant="light"
              ref="table"
              :busy="isBusy"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">
                {{
                  data.index +
                  (search_condition.pageNum - 1) * search_condition.pageSize +
                  1
                }}
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <br />
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

/**
 * Device component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    timeZoneConversion,
  },
  data() {
    return {
      title: "Share List",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Marketing Manage",
          // href: "/"
        },
        {
          text: "Share List",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [
        "deviceNumber",
        "deviceCoding",
        "deviceLocation",
        "activationTime",
      ],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        //分享者
        { key: "shareUser", label: "Share User", class: "text-center" },
        //分享次数
        { key: "shareCount", label: "Share Count", class: "text-center" },
        //分享打开次数
        {
          key: "openFrequency",
          label: "Share Opens",
          class: "text-center",
        },
        //成功注册数
        {
          key: "registerFrequency",
          label: "Share Registration Number",
          class: "text-center",
        },
      ],
      invitationTime: [], //邀请时间（显示用）
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        shareUser: "", //分享用户
      },
      isBusy: false,
      has_data: false,
      form: { brandId: "", file: "", remarks: "", id: "" },
      types: {
        //奖励类型
        rewardList: [{ text: "Please select", value: "" }],
        status: [
          { text: "Please select", value: "", disabled: false },
          { text: "Disabled", value: 0, disabled: false },
          { text: "Enable", value: 1, disabled: false },
        ],
      },
    };
  },
  watch: {
    invitationTime: function (val) {
      this.search_condition.startTime = val[0]
        ? timeZoneConversion.queryTime(val[0])
        : val[0];
      if (val[1]) {
        this.search_condition.endTime = timeZoneConversion.queryTime(val[1]);
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  created() {
    this.getAllRewardList();
  },
  mounted() {
    this.resetModal();
  },
  methods: {
    provider(ctx) {
      this.isBusy = true;
      return this.$api.Marketing.selectUserShareRecordsList(
        this.search_condition,
        ctx
      )
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            x.data.forEach((element) => {
              element.invitationTime = "";
              let startTime = timeZoneConversion.getLocalTime(
                element.startTime
              );
              let endTime = timeZoneConversion.getLocalTime(element.endTime);
              element.invitationTime = startTime + " ~ " + endTime;
            });
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    resetModal() {
      this.form = { brandId: "", file: "", remarks: "", id: "" };
      this.form = new FormData();
    },
    handleSubmit() {
      this.resetModal();
    },
    getAllRewardList() {
      this.$api.Dropdown.selectAllAwardCoupon()
        .then((res) => {
          if (res.success) {
            for (let i = 0; i < res.data.length; i++) {
              this.types.rewardList.push({
                text: res.data[i].couponName,
                value: res.data[i].id,
              });
            }
          } else {
            if (res.message) {
              that.$bvToast.toast(res.message);
            }
          }
        })
        .catch((res) => {
          if (res.message) this.$bvToast.toast(res.message);
        });
    },
  },
};
</script>
