<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <simplebar class="h-100">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul v-if="menu" class="metismenu list-unstyled" id="side-menu" ref="sideMenu">
          <li v-for="item in menu" :key="item.id">
            <router-link
                v-if="!item.children"
                tag="a"
                :to="item.href"
                class="side-nav-link"
            >
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </router-link>
            <a
                v-if="item.children"
                href="javascript: void(0);"
                class="has-arrow"
            >
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </a>
            <ul class="sub-menu"
                aria-expanded="false">
              <li v-for="child in item.children" :key="child.id">
                <router-link :to="child.href" class="side-nav-link">{{
                    child.title
                  }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- Sidebar -->
    </simplebar>
  </div>
  <!-- Left Sidebar End -->
</template>

<style></style>

<script>
import simplebar from "simplebar-vue";
import MetisMenu from "metismenujs/dist/metismenujs";
import store from "@/state/store";

import {layoutMethods} from "@/state/helpers";

/**
 * Sidebar component
 */
export default {
  data() {
    return {
      menuRef: null
      // menu: JSON.parse(sessionStorage.getItem("menu")),
    };
  },
  computed: {
    menu() {
      // 获取store中的状态
      return store.state.menu;
    },
  },

  components: {
    simplebar,
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    // var menuRef = new MetisMenu("#side-menu");
    // var links = document.getElementsByClassName("side-nav-link");
    // var matchingMenuItem = null;
    // if (window.location.pathname == "/") {
    //   matchingMenuItem = links[0]
    // } else {
    //   for (var i = 0; i < links.length; i++) {
    //     var m = window.location.pathname.split("/")[1];
    //     var m1 = links[i].pathname.split("/")[1];
    //     if (m == m1) {
    //       matchingMenuItem = links[i];
    //       break;
    //     }
    //   }
    // }
    //
    // for (var i = 0; i < links.length; i++) {
    //   if (
    //     window.location.pathname.replace(/add|edit|info|review|Details|OmniIDList|OmniIDInfo/i, "") ===
    //     links[i].pathname
    //   ) {
    //     matchingMenuItem = links[i];
    //     break;
    //   }
    // }

    // if (matchingMenuItem) {
    //   matchingMenuItem.classList.add("active");
    //   var parent = matchingMenuItem.parentElement;
    //   /**
    //    * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
    //    * We should come up with non hard coded approach
    //    */
    //   if (parent) {
    //     parent.classList.add("mm-active");
    //     const parent2 = parent.parentElement.closest("ul");
    //     if (parent2 && parent2.id !== "side-menu") {
    //       parent2.classList.add("mm-show");
    //       const parent3 = parent2.parentElement;
    //       if (parent3) {
    //         parent3.classList.add("mm-active");
    //         var childAnchor = parent3.querySelector(".has-arrow");
    //         var childDropdown = parent3.querySelector(".has-dropdown");
    //         if (childAnchor) childAnchor.classList.add("mm-active");
    //         if (childDropdown) childDropdown.classList.add("mm-active");
    //         const parent4 = parent3.parentElement;
    //         if (parent4) parent4.classList.add("mm-show");
    //         const parent5 = parent4.parentElement;
    //         if (parent5) parent5.classList.add("active");
    //       }
    //     } else {
    //       parent.classList.add("mm-active");
    //     }
    //   }
    // }
    this.handleMenu()
    //
    //     break;
    //   }
    // }
    //
    // if (matchingMenuItem) {
    //   matchingMenuItem.classList.add("active");
    //   var parent = matchingMenuItem.parentElement;
    //   /**
    //    * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
    //    * We should come up with non hard coded approach
    //    */
    //   if (parent) {
    //     parent.classList.add("mm-active");
    //     const parent2 = parent.parentElement.closest("ul");
    //     if (parent2 && parent2.id !== "side-menu") {
    //       parent2.classList.add("mm-show");
    //       const parent3 = parent2.parentElement;
    //       if (parent3) {
    //         parent3.classList.add("mm-active");
    //         var childAnchor = parent3.querySelector(".has-arrow");
    //         var childDropdown = parent3.querySelector(".has-dropdown");
    //         if (childAnchor) childAnchor.classList.add("mm-active");
    //         if (childDropdown) childDropdown.classList.add("mm-active");
    //         const parent4 = parent3.parentElement;
    //         if (parent4) parent4.classList.add("mm-show");
    //         const parent5 = parent4.parentElement;
    //         if (parent5) parent5.classList.add("active");
    //       }
    //     } else {
    //       parent.classList.add("mm-active");
    //     }
    //   }
    // }
    // this.$nextTick(()=> {
    //   console.log('mount....', this.menuRef)
    //   this.handleMenu()
    //   this.handleMenu()
    // })
  },
  updated() {
    console.log('update....', this.menuRef)
    this.handleMenu()
  },
  watch: {},
  methods: {
    ...layoutMethods,
    changeLayout(layout) {
      this.changeLayoutType({
        layoutType: layout,
      });
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-keep-enlarged", "true");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    iconSidebar() {
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-keep-enlarged", "true");
    },
    // init() {
    //   this.$api.account.findUserMenu().then((res) => {
    //     if (res.success && res.data) {
    //       this.menu = res.data;
    //       sessionStorage["menu"] = JSON.stringify(res.data);
    //     } else {
    //       if(res.message){
    //         this.$toast(res);
    //        }
    //     }
    //   });
    // },

    handleMenu() {
      if (!this.$refs.sideMenu) {
        return
      }

      if (!this.menuRef) {
        this.menuRef = new MetisMenu(this.$refs.sideMenu);
      }
      // eslint-disable-next-line no-unused-vars
      var links = document.getElementsByClassName("side-nav-link");
      var matchingMenuItem = null;
      if (window.location.pathname === "/") {
        for (let i = 0; i < links.length; i++) {
          this.menuRef.hide(links[i])
          console.log(`hide ${links[i]}`)
        }
      } else {
        for (var i = 0; i < links.length; i++) {
          var m = window.location.pathname.split("/")[1];
          var m1 = links[i].pathname.split("/")[1];
          if (m == m1) {
            matchingMenuItem = links[i];
            break;
          }
        }
      }

      for (var i = 0; i < links.length; i++) {
        if (
            window.location.pathname.replace(/add|edit|info|review|Details|OmniIDList|OmniIDInfo|BrandSkuBtn|BatchBtn|FlavorBtn|CurvesBtn/i, "") ===
            links[i].pathname
        ) {
          matchingMenuItem = links[i];
          break;
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        var parent = matchingMenuItem.parentElement;
        /**
         * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
         * We should come up with non hard coded approach
         */
        if (parent) {
          parent.classList.add("mm-active");
          const parent2 = parent.parentElement.closest("ul");
          if (parent2 && parent2.id !== "side-menu") {
            parent2.classList.add("mm-show");
            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.add("mm-active");
              var childAnchor = parent3.querySelector(".has-arrow");
              var childDropdown = parent3.querySelector(".has-dropdown");
              if (childAnchor) childAnchor.classList.add("mm-active");
              if (childDropdown) childDropdown.classList.add("mm-active");
              const parent4 = parent3.parentElement;
              if (parent4) parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5) parent5.classList.add("active");
            }
          } else {
            parent.classList.add("mm-active");
          }
        }
      }
    }
  },
};
</script>

<style lang="scss">
.requiredRow {
  &:before {
    content: "*";
    color: red;
    padding-right: 2px;
  }
}

body[data-sidebar="dark"] .mm-active .active {
  color: #b4c9de !important;
  background-color: #707070;
}

.sub-menu .mm-active {
  background-color: #707070;
}
</style>
