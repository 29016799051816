<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-card>
      <b-row>
        <b-col>
          <div class="vo-row">
            <p class="productInfo">
              <strong>Flavor ID:</strong>
            </p>
            <p class="productInfoContent">
              {{ info.id }}
            </p>
          </div>

          <div class="vo-row">
            <p class="productInfo">
              <strong>Flavor Name:</strong>
            </p>
            <p class="productInfoContent">
              {{ info.modelName }}
            </p>
          </div>

          <div class="vo-row">
            <p class="productInfo">
              <strong>Ingredients:</strong>
            </p>
            <p class="productInfoContent">
              <b-row
                v-for="(item, i) in info.ingredientsVoList"
                :key="i"
                style="margin-bottom: 10px"
              >
                <b-col cols="4">{{ item.name }}</b-col>
                <b-col cols="4">{{ item.proportion }}%</b-col>
              </b-row>
            </p>
          </div>

          <div class="vo-row">
            <p class="productInfo">
              <strong>Terpenes:</strong>
            </p>
            <p class="productInfoContent">
              <b-row
                v-for="(item, i) in info.terpenesList"
                :key="i"
                style="margin-bottom: 10px"
              >
                <b-col cols="4">{{ item.name }}</b-col>
                <b-col cols="4">{{ item.proportion }}%</b-col>
              </b-row>
            </p>
          </div>

          <div class="vo-row">
            <p class="productInfo">
              <strong>Usage Effects Tags:</strong>
            </p>
            <p class="productInfoContent">
              {{ info.commodityLabelName }}
            </p>
          </div>
          <div class="vo-row">
            <p class="productInfo">
              <strong> Flavor Picture:</strong>
            </p>
            <b-col cols="5" style="margin-left: -10px" class="pd cursor">
              <ImageComponets :image="info.flavorPicture" />
            </b-col>
          </div>
          <div class="vo-row">
            <p class="productInfo">
              <strong>Status:</strong>
            </p>
            <p class="productInfoContent">
              {{ info.status == 0 ? "Eanable" : "Disable" }}
            </p>
          </div>
        </b-col>
        <b-col ref="container">
          <FlavorPieChart
            v-if="pieChartWidth !== 0"
            :legend="legend"
            :series="series"
            height="300px"
            :width="pieChartWidth"
          />
        </b-col>
      </b-row>
      <b-form-group label-cols-lg="2" style="margin-top: 40px;">
      <b-button type="reset" class="custom-width" @click="$router.back()"
        >Return
      </b-button>
    </b-form-group>
    </b-card>
    <b-card v-if="productDetail.id">
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Product Info</h4>
          </div>
        </b-col>
      </b-row>
      <div class="productDetailRow" style="margin-left: 40px;">
        <!-- 左边 -->
        <div>
          <div class="productDetailBorder">
            <img :src="productDetail.deviceImage" />
          </div>
          <div class="productDetailImageRow">
            <img
              :src="item.value"
              v-for="(item, key) in productDetail.productPicture"
              :key="key"
            />
          </div>
        </div>
        <!-- 右边 -->
        <div class="productDetailContent">
          <p style="font-size: 18px; color: #3eb1c8; font-weight: 700">
            {{ productDetail.productSeriesName }}
          </p>
          <p
            style="
              font-size: 13px;
              color: #aaaaaa;
              font-family: 'Arial Normal', 'Arial', sans-serif;
            "
          >
            {{ productDetail.remarks }}
          </p>
          <p>
            <strong class="productDetailContentColor">Product Model #:</strong>
            {{ productDetail.modelName }}
          </p>
          <p>
            <strong class="productDetailContentColor">Variant Code #:</strong>
            {{ productDetail.variantCode }}
          </p>
          <p>
            <strong class="productDetailContentColor">Marketing Name:</strong>
            {{ productDetail.deviceName }}
          </p>
          <p>
            <strong class="productDetailContentColor">Business Type:</strong>
            {{
              productDetail.oilType == 2 ? "CBD/THC Concentrates" : "Nicotine"
            }}
          </p>
          <p>
            <strong class="productDetailContentColor">Product Type:</strong>
            {{ productDetail.type == 3 ? "Disposable" : "Batteries" }}
          </p>
          <p>
            <strong class="productDetailContentColor"
              >Available Compatibility:</strong
            >
            {{
              productDetail.availableCompatibilityId == 1
                ? "OMNI NFC"
                : "OMNI Hub"
            }}
          </p>
          <p>
            <strong class="productDetailContentColor"
              >Available Tank Size:</strong
            >
            {{ productDetail.taskCapacityValue }}
          </p>
        </div>
         <div class="productDetailContent">
          <p style="font-size: 18px; color: #3eb1c8; font-weight: 700;visibility: hidden;">
            {{ productDetail.productSeriesName }}
          </p>
          <p
            style="
              font-size: 13px;
              color: #aaaaaa;
              font-family: 'Arial Normal', 'Arial', sans-serif;
              visibility: hidden;
            "
          >
            {{ productDetail.remarks }}
          </p>
          <p>
            <strong class="productDetailContentColor">Brand:</strong>
            {{ productDetail.brandName }}
          </p>
          <p>
            <strong class="productDetailContentColor">Brand Product Model #:</strong>
            {{ productDetail.brandProductName }}
          </p>
          <p>
            <strong class="productDetailContentColor">Brand Marketing Name:</strong>
            {{ productDetail.brandMarketingName }}
          </p>
        </div>
      </div>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import FlavorPieChart from "@/components/flavor/FlavorPieChart.vue";
import ImageComponets from "@/components/brand/ImageComponets.vue";

/**
 * Cartridge Info component
 */
export default {
  components: {
    FlavorPieChart,
    Layout,
    PageHeader,
    ImageComponets,
  },
  data() {
    return {
      title: "Flavor Details",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "Client&Brand",
          // href: "/"
        },
        {
          text: "Flavor Mgmt",
          // href: "/"
        },
        {
          text: "Flavor Details",
          active: true,
        },
      ],
      info: {},
      productDetail: {},
      legend: {
        orient: "horizontal",
        top: "bottom",
        left: "center",
        bottom: "0", // 图例顶部垂直居中
        icon: "circle",
      },

      series: {
        radius: ["55%", "65%"],
        center: ["50%", "50%"], // 将饼图移到最右边
        data: [],
      },
      pieChartWidth: 0,
      pieChartHeight: 0,
    };
  },
  created() {
    // 获取 烟弹信息
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.$api.CartridgeModel.find(this.$route.query).then((res) => {
          if (res.success) {
            this.info = res.data;
            this.productDetail = res.data.brandProductVo;
            let list = [];
            if (
              res.data.ingredientsVoList &&
              res.data.ingredientsVoList.length > 0
            ) {
              res.data.ingredientsVoList.map((ingredients, i) => {
                list.push({
                  id: ingredients.id,
                  name: ingredients.name,
                  value: ingredients.proportion,
                });
              });
            }
            if (res.data.terpenesList && res.data.terpenesList.length > 0) {
              res.data.terpenesList.map((terpenes, i) => {
                list.push({
                  id: terpenes.id,
                  name: terpenes.name,
                  value: terpenes.proportion,
                });
              });
            }
            this.series.data = list;
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      } else {
        this.$bvToast.toast("id?");
      }

      this.$nextTick(() => {
        const pieChartWidth = `${this.$refs.container.clientWidth}px`;
        this.pieChartWidth = pieChartWidth;
        this.pieChartHeight = `${this.$refs.container.clientHeight}px`;
      });
    },
  },
};
</script>

<style scoped>
.flavor-info-item-wrap {
  & > * {
    margin-bottom: 20px;
  }
}

.no-padding {
  padding: 0;
}
.flavor-img-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #00a0e9;
}

.productDetailBorder {
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.productDetailRow {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}
.productDetailImageRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5px;
  height: 125px;
}

.modal-header {
  display: none;
}
.modal-footer {
  display: none;
}
.productDetailContent {
  margin-left: 40px;
  width: 400px;
}
.productDetailContentColor {
  color: #333333;
}
.productDetailColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.productInfo {
  width: 200px;
  text-align: right;
  color: #333333;
}

.productInfoContent {
  text-align: left;
  flex: 1;
  margin-left: 5px;
}
.pd {
  padding-bottom: 10px;
}

.overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  text-align: center;
  justify-content: center;
  align-items: center;
}
</style>
