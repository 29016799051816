var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.statData, function(stat) {
          return _c(
            "div",
            { key: stat.icon, staticClass: "col-xl-3 col-md-6" },
            [
              _c("Stat", {
                attrs: {
                  title: stat.title,
                  image: stat.image,
                  subText: stat.subText,
                  value: stat.value,
                  color: stat.color,
                  popoverText: stat.popoverText
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xl: "12" } },
            [
              _c(
                "b-card",
                { attrs: { title: "Inventory data" } },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.bottom",
                        value: _vm.popoverInfo,
                        expression: "popoverInfo",
                        modifiers: { hover: true, bottom: true }
                      }
                    ],
                    staticClass: "ti-help-alt",
                    staticStyle: {
                      cursor: "pointer",
                      "font-size": "20px",
                      position: "absolute",
                      top: "20px",
                      left: "140px"
                    }
                  }),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "8" } },
                        [
                          _c("apexchart", {
                            ref: "selectInactiveIcon",
                            attrs: {
                              type: "area",
                              height: "320",
                              options: _vm.selectInactiveIcon.chartOptions,
                              series: _vm.selectInactiveIcon.series
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { lg: "4" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { md: "4" } }, [
                                _c(
                                  "div",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "text-muted mb-4" },
                                      [_vm._v("Unused Cartridges")]
                                    ),
                                    _c("h3", [
                                      _vm._v(_vm._s(_vm.cartridges.noActivated))
                                    ]),
                                    _c("apexchart", {
                                      staticClass: "apex-charts",
                                      attrs: {
                                        height: "150",
                                        type: "radialBar",
                                        series: [_vm.percent.noActivated],
                                        options: _vm.radialBarChart.chartOptions
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("b-col", { attrs: { md: "4" } }, [
                                _c(
                                  "div",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "text-muted mb-4" },
                                      [_vm._v("Activated Cartridges")]
                                    ),
                                    _c("h3", [
                                      _vm._v(_vm._s(_vm.cartridges.activated))
                                    ]),
                                    _c("apexchart", {
                                      staticClass: "apex-charts",
                                      attrs: {
                                        height: "150",
                                        type: "radialBar",
                                        series: [_vm.percent.activated],
                                        options: _vm.radialBarChart.chartOptions
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("b-col", { attrs: { md: "4" } }, [
                                _c(
                                  "div",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "text-muted mb-4" },
                                      [_vm._v("Depleted Cartridges")]
                                    ),
                                    _c("h3", [
                                      _vm._v(_vm._s(_vm.cartridges.invalid))
                                    ]),
                                    _c("apexchart", {
                                      staticClass: "apex-charts",
                                      attrs: {
                                        height: "150",
                                        type: "radialBar",
                                        series: [_vm.percent.invalid],
                                        options: _vm.radialBarChart.chartOptions
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xl: "8" } },
            [
              _c(
                "b-card",
                { attrs: { title: "Latest Active Users" } },
                [
                  _c("b-table", {
                    attrs: {
                      "show-empty": "",
                      "empty-text": "No records",
                      "head-variant": "light",
                      striped: true,
                      items: _vm.user_provider,
                      fields: _vm.activeUsersFields,
                      busy: _vm.user_isBusy
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "table-busy",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-center text-danger my-2" },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle"
                                }),
                                _c("strong", [_vm._v("Loading...")])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "cell(index)",
                        fn: function(data) {
                          return [_vm._v(_vm._s(data.index + 1))]
                        }
                      },
                      {
                        key: "cell(nickName)",
                        fn: function(data) {
                          return [
                            data.item.picture
                              ? _c("img", {
                                  staticClass: "avatar-xs rounded-circle mr-2",
                                  attrs: {
                                    src: "" + data.item.picture,
                                    alt: ""
                                  }
                                })
                              : _c("img", {
                                  staticClass: "avatar-xs rounded-circle mr-2",
                                  attrs: {
                                    src: require("../../assets/face.jpg"),
                                    alt: ""
                                  }
                                }),
                            _vm._v(" " + _vm._s(data.item.nickName) + " ")
                          ]
                        }
                      },
                      {
                        key: "cell(status)",
                        fn: function(data) {
                          return [_c("span", [_vm._v("active")])]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xl: "4" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-card",
                        { attrs: { title: "Flavor Ranking" } },
                        [
                          _c("b-table", {
                            attrs: {
                              "show-empty": "",
                              "empty-text": "No records",
                              id: "flavor-table",
                              "head-variant": "light",
                              striped: true,
                              items: _vm.flavor_provider,
                              fields: _vm.fields,
                              busy: _vm.flavor_isBusy
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "table-busy",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center text-danger my-2"
                                      },
                                      [
                                        _c("b-spinner", {
                                          staticClass: "align-middle"
                                        }),
                                        _c("strong", [_vm._v("Loading...")])
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "cell(index)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "badge",
                                        class: {
                                          "badge-danger": data.index === 0,
                                          "badge-warning": data.index === 1,
                                          "badge-success": data.index === 2
                                        }
                                      },
                                      [_vm._v(_vm._s(data.index + 1))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-card",
                        { attrs: { title: "User Feedback" } },
                        _vm._l(_vm.feedback, function(item) {
                          return _c("div", { key: item.index }, [
                            _c(
                              "p",
                              {
                                staticClass: "text-muted",
                                staticStyle: { "margin-bottom": "0" }
                              },
                              [_vm._v(' "' + _vm._s(item.content) + '" ')]
                            ),
                            _c("h6", { staticClass: "mb-0" }, [
                              item.picture
                                ? _c("img", {
                                    staticClass:
                                      "avatar-xs rounded-circle mr-2",
                                    attrs: { src: item.picture, alt: "" }
                                  })
                                : _vm._e(),
                              _vm._v(" " + _vm._s(item.nickName) + " "),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "20px" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.getLocalTime(item.createTime))
                                  )
                                ]
                              )
                            ]),
                            _c("br")
                          ])
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }