<!-- 添加新的口味弹窗组件 -->
<template>
  <b-modal
    id="modal"
    size="lg"
    v-model="showCreateFlavor"
    centered
    title="Create New Flavor"
    ok-title="Confirm"
    @ok="onConfirm"
    @cancel="onCancel"
    @close="onClose"
    no-close-on-esc
    no-close-on-backdrop
  >
    <b-card>
      <b-form @submit="onConfirm" @reset="onCancel">
        <!-- Flavor Name 口味名称-->
        <b-form-group
          label="Flavor Name:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name"
          label-class="requiredRow"
        >
          <b-form-input
            id="model-name"
            v-model="form.modelName"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
            placeholder="Please enter"
          ></b-form-input>
        </b-form-group>

        <!-- Flavor Type 烟弹类型 -->
        <b-form-group
          label="Flavor Type:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="cartridge-type"
          label-class="requiredRow"
        >
          <b-form-select
            id="cartridge-type"
            v-model="form.type"
            :options="types.TypeEncoding"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
            @change="changeType(form.type)"
          ></b-form-select>
        </b-form-group>

         <!-- Device Model 适用设备 -->
         <b-form-group
          v-if="isUnicoreus"
          label="Device Model:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="device-model"
          label-class="requiredRow"
        >
          <b-form-select
            id="device-model"
            v-model="form.deviceModelId"
            :options="types.deviceList"
            value-field="id"
            text-field="modelName"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
          ></b-form-select>
        </b-form-group>

        <!-- Devices Type: 设备类型 -->
        <b-form-group
          v-if="isIkrusher"
          label="Devices Type:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="cartridge-model"
          label-class="requiredRow"
        >
          <b-form-select
            id="cartridge-model"
            v-model="form.deviceType"
            :options="types.selectAllTestSmokeBombModel"
            value-field="id"
            text-field="modelName"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
          ></b-form-select>
        </b-form-group>

        <!-- Custom Temp 是否可自定义温度 -->
        <!-- <b-form-group
          label="Custom Temp:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="custom-temp"
          label-class="requiredRow"
        >
          <b-form-checkbox
            id="custom-temp"
            v-model="form.customTemperature"
            name="customTemperature"
            :value="0"
            :unchecked-value="1"
            switch
            size="lg"
            style="margin-top: 5px"
          >
            {{ form.customTemperature == 0 ? "Yes" : "No" }}
          </b-form-checkbox>
        </b-form-group> -->

        <!-- Suggested Price 建议零售价 -->
        <b-form-group
          label="Suggested Price:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="suggested-price"
          label-class="requiredRow"
        >
          <b-form-input
            id="suggested-price"
            v-model="form.suggestRetailPrice"
            required
            type="number"
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
            placeholder="Please enter"
          ></b-form-input>
        </b-form-group>

        <!-- Actual Selling Price 实际销售价格 -->
        <b-form-group
          label="Actual Selling Price:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="actual-selling-price"
          label-class="requiredRow"
        >
          <b-form-input
            id="actual-selling-price"
            v-model="form.retailPrice"
            required
            type="number"
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
            placeholder="Please enter"
          ></b-form-input>
        </b-form-group>

        <!-- Product Genre Tags 商品标签 -->
        <b-form-group
          label="Product Genre Tags:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="product-genre-tags"
          label-class="requiredRow"
        >
          <multiselect
            id="product-genre-tags"
            v-model="selectProductGenre"
            :options="types.productGenreList"
            track-by="id"
            label="labelName"
            placeholder="Please select"
            :searchable="false"
            :close-on-select="false"
            :multiple="true"
            :max="maxFive"
            @input="selectGenre"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
          >
          </multiselect>
        </b-form-group>

        <!-- Usage Effects 使用感受标签 -->
        <b-form-group
          label="Usage Effects:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="usage-effects"
          label-class="requiredRow"
        >
          <multiselect
            id="usage-effects"
            v-model="selectUsageEffects"
            :options="types.usageEffectsList"
            track-by="id"
            label="labelName"
            placeholder="Please select"
            :searchable="false"
            :close-on-select="false"
            :multiple="true"
            :max="maxThree"
            @input="selectEffects"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
          >
          </multiselect>
        </b-form-group>

        <!-- Flavor Profiles 口感标签 -->
        <b-form-group
          label="Flavor Profiles:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="flavor-profiles"
          label-class="requiredRow"
        >
          <multiselect
            v-model="selectFlavorProfiles"
            :options="types.flavorProfilesList"
            track-by="id"
            label="labelName"
            placeholder="Please select"
            :searchable="false"
            :close-on-select="false"
            :multiple="true"
            :max="maxThree"
            @input="selectProfiles"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
          >
          </multiselect>
        </b-form-group>

        <!-- Intensity 强度 -->
        <b-form-group
          label="Intensity:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="intensity"
          label-class="requiredRow"
        >
          <b-form-select
            id="intensity"
            v-model="form.strengthId"
            :options="types.IntensityList"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
            @change="changeType(form.type)"
          ></b-form-select>
        </b-form-group>

        <!-- Brand Name 所属品牌-->
        <b-form-group
          label="Brand Name:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="brand-name"
          label-class="requiredRow"
        >
          <b-form-select
            id="brand-name"
            v-model="form.brand"
            :options="types.brandNames"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
          ></b-form-select>
        </b-form-group>

        <!-- 烟油口味图片 -->
        <b-form-group
          label="Flavor Picture:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="logo"
          label-class="requiredRow"
        >
          <input
            type="file"
            class="filestyle"
            data-input="false"
            id="filestyle-1"
            style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
            tabindex="-1"
            @change="upload($event)"
            :required="!editId"
            oninvalid="setCustomValidity('Please upload');"
            oninput="setCustomValidity('');"
          />

          <div class="bootstrap-filestyle input-group">
            <div
              name="filedrag"
              style="position: absolute; width: 100%; height: 35px; z-index: -1"
            ></div>
            <span class="group-span-filestyle" tabindex="0">
              <div v-if="form.flavorPicture" style="position: relative">
                <i
                  class="ion ion-md-close-circle"
                  style="
                    position: absolute;
                    left: 70px;
                    top: 5px;
                    font-size: 25px;
                  "
                  @click="onDeleteFlavorPicture"
                />
                <img
                  :src="form.flavorPicture"
                  style="width: 100px; height: 100px; border: 2px dashed #ccc"
                />
                <span
                  style="margin-left: 10px; vertical-align: bottom"
                  v-if="photoName"
                >
                  <span style="font-weight: 500">Picture name : </span
                  >{{ photoName }}</span
                >
              </div>
              <label
                for="filestyle-1"
                style="margin-bottom: 0"
                class="btn btn-secondary"
                v-if="!form.flavorPicture"
              >
                <span class="buttonText"> upload picture </span>
              </label>
              <span v-if="!form.flavorPicture">
                Recommended size of picture: 512 pixels * 512 pixels, supporting
                JPG and PNG formats.
              </span>
            </span>
          </div>
        </b-form-group>

        <!-- Carousel 商品轮播图 -->
        <b-form-group
          label="Carousel:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="carousel"
          label-class="requiredRow"
        >
          <input
            type="file"
            class="filestyle"
            data-input="false"
            id="filestyle-2"
            style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
            tabindex="-2"
            @change="uploadCarouse($event)"
            :required="!editId"
            oninvalid="setCustomValidity('Please upload');"
            oninput="setCustomValidity('');"
          />

          <div class="bootstrap-filestyle input-group">
            <div
              name="filedrag"
              style="position: absolute; width: 100%; height: 35px; z-index: -1"
            ></div>
            <span class="group-span-filestyle" tabindex="0">
              <div v-if="form.commodityCarouselMap" style="display: flex">
                <div
                  v-for="(item, index) of form.commodityCarouselMap"
                  :key="index"
                  style="width: 100px; position: relative; margin-right: 10px"
                >
                  <i
                    class="ion ion-md-close-circle"
                    style="
                      position: absolute;
                      right: 5px;
                      top: 5px;
                      font-size: 25px;
                    "
                    @click="onDeleteCarouselMap(item, index)"
                  />
                  <img
                    :src="item"
                    style="width: 100px; height: 100px; border: 2px dashed #ccc"
                  />
                </div>
              </div>
              <label
                for="filestyle-2"
                style="margin-bottom: 0"
                class="btn btn-secondary"
                v-if="form.commodityCarouselMap.length < 5"
              >
                <span class="buttonText"> upload picture </span>
              </label>
              <span v-if="form.commodityCarouselMap.length < 5">
                Recommended size of picture: 750 pixels * 750 pixels, supporting
                JPG and PNG formats.
              </span>
            </span>
          </div>
        </b-form-group>

        <!-- Creators Notes 创作故事 -->
        <b-form-group
          label="Creators Notes:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="creators-notes"
          label-class="requiredRow"
        >
          <b-form-textarea
            id="creators-notes"
            v-model="form.commodityCreationStory"
            placeholder="Enter Notes"
            rows="3"
            required
            max-rows="15"
          ></b-form-textarea>
        </b-form-group>

        <!-- Details 商品详情图 -->
        <b-form-group
          label="Details:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="details"
          label-class="requiredRow"
        >
          <input
            type="file"
            class="filestyle"
            data-input="false"
            id="filestyle-3"
            style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
            tabindex="-3"
            @change="uploadDetails($event)"
            :required="!editId"
            oninvalid="setCustomValidity('Please upload');"
            oninput="setCustomValidity('');"
          />

          <div class="bootstrap-filestyle input-group">
            <div
              name="filedrag"
              style="position: absolute; width: 100%; height: 35px; z-index: -1"
            ></div>
            <span class="group-span-filestyle" tabindex="0">
              <div v-if="form.commodityDetailsMap" style="display: flex">
                <div
                  v-for="(item, index) of form.commodityDetailsMap"
                  :key="index"
                  style="width: 100px; position: relative; margin-right: 10px"
                >
                  <i
                    class="ion ion-md-close-circle"
                    style="
                      position: absolute;
                      right: 5px;
                      top: 5px;
                      font-size: 25px;
                    "
                    @click="onDeleteDetailsMap(item, index)"
                  />
                  <img
                    :src="item"
                    style="width: 100px; height: 100px; border: 2px dashed #ccc"
                  />
                </div>
              </div>
              <label
                for="filestyle-3"
                style="margin-bottom: 0"
                class="btn btn-secondary"
                v-if="form.commodityDetailsMap.length < 5"
              >
                <span class="buttonText"> upload picture </span>
              </label>
              <span v-if="form.commodityDetailsMap.length < 5">
                Recommended image width 750px, supporting JPG and PNG formats.
              </span>
            </span>
          </div>
        </b-form-group>

        <!-- On sale 是否上架 -->
        <!-- <b-form-group
          label="On Sale:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="on-sale"
        >
          <b-form-checkbox
            id="on-sale"
            v-model="form.onSale"
            name="onSale"
            :value="0"
            :unchecked-value="1"
            switch
            size="lg"
            style="margin-top: 5px"
          >
            {{ form.onSale == 0 ? "Yes" : "No" }}
          </b-form-checkbox>
        </b-form-group> -->

        <!-- Cart Url 购物车URL -->
        <b-form-group
          label="Find it link:"
          label-cols-sm="2"
          label-align-sm="right"
          label-for="cart-url"
        >
          <b-form-input
            id="cart-url"
            v-model="form.cartUrl"
            placeholder="Please enter"
            maxlength="100"
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('')"
          ></b-form-input>
        </b-form-group>

        <!-- Flavor Sort 排序 -->
        <!-- <b-form-group
          label="Flavor Sort:"
          label-cols-sm="2"
          label-align-sm="right"
          label-for="flavor-sort"
          label-class="requiredRow"
        >
          <b-form-input
            id="flavor-sort"
            required
            v-model="form.flavorSort"
            placeholder="Please enter"
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('')"
          ></b-form-input>
        </b-form-group> -->

        <!-- Consistence 烟油浓稠度 -->
        <b-form-group
          v-if="form.type == 1 || form.type == 5"
          label="Consistence:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="consistence"
          label-class="requiredRow"
        >
          <b-form-input
            id="consistence"
            v-model="form.consistence"
            required
            type="number"
            min="0"
            step="0.0001"
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3')"
          ></b-form-input>
        </b-form-group>

        <!-- Medical Care 是否医用-->
        <b-form-group
          v-if="form.type == 5"
          label="Medical Care:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="medical"
          label-class="requiredRow"
        >
          <b-form-radio
            class="custom-control-inline pt-2"
            v-model="form.medicalCare"
            name="medicalCare"
            value="0"
            required
            >YES</b-form-radio
          >
          <b-form-radio
            class="custom-control-inline pt-2"
            v-model="form.medicalCare"
            name="medicalCare"
            value="1"
            required
            >NO</b-form-radio
          >
        </b-form-group>

        <!-- Seconds/Day 吸入上限设置 -->
        <b-form-group
          v-if="form.type == 5"
          label="Seconds/Day:"
          label-cols-sm="2"
          label-align-sm="right"
          label-for="daySeconds"
          label-class="requiredRow"
        >
          <b-form-input
            id="remark"
            v-model="form.daySeconds"
            placeholder="Please enter"
            maxlength="20"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('')"
          ></b-form-input>
        </b-form-group>

        <!-- remarks 备注 -->
        <b-form-group
          label="Notes:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="remarks"
        >
          <b-form-textarea
            id="remarks"
            v-model="form.remarks"
            placeholder="Enter Notes"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-cols-lg="5">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" variant="danger" class="m-l-5 ml-1"
            >Cancel</b-button
          >
        </b-form-group>
      </b-form>
      <!-- <p>{{form}}</p> -->
    </b-card>
    <template v-slot:modal-footer>
      <div class="w-100"></div>
    </template>
  </b-modal>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img } from "@/api/upload";
import { mapGetters } from 'vuex';

export default {
  name: "CreateNewFlavor",
  components: {
    ckeditor: CKEditor.component,
    Multiselect,
  },
  data() {
    return {
      maxFive: 5,
      maxThree: 3,
      form: {
        supplierId: localStorage.supplierId,

        modelName: "", //口味名称
        type: "", //烟弹类型
        deviceModelId: "", //使用设备
        customTemperature: 1, //是否可自定义温度 0是 1否
        suggestRetailPrice: "", //建议零售价
        retailPrice: "", //实际零售价
        commodityLabelId: "", //商品标签编号
        experienceFeelingsLabelId: "", //使用感受标签编号
        tasteLabelId: "", //口感标签编号
        strengthId: "", //强度标签编号
        brand: "", //所属品牌
        flavorPicture: "", //烟油口味图片
        commodityCarouselMap: [], //商品轮播图
        commodityCreationStory: "", //创作故事
        commodityDetailsMap: [], //商品详情图
        onSale: 1, //是否上架 0是 1否
        cartUrl: "", //购物车URL
        flavorSort: "", //排序
        remarks: "", //备注

        consistence: 0, //烟油浓稠度
        medicalCare: "", //是否医用
        daySeconds: "", //吸入上限设置
        deleteFileUrl: [], //删除照片集合 服务端处理
      },
      reportType: "",
      photoName: "",
      types: {
        brandNames: [{ text: "Please select", value: "" }],
        flavorOptions: [],
        cartridgeComponentData: [{ text: "Please select", value: "" }],
        SolventEncoding: [{ text: "Please select", value: 0 }],
        TypeEncoding: [{ text: "Please select", value: "" }],
        statuss: [
          { text: "true", value: 1, disabled: false },
          { text: "false", value: 0, disabled: false },
        ],
        deviceList: [{ modelName: "Please select", id: "" }], //适用设备列表
        productGenreList: [], //商品标签列表
        usageEffectsList: [], //使用感受标签列表
        flavorProfilesList: [], //口感标签列表
        IntensityList: [{ text: "Please select", value: "" }], //强度标签列表
      },
      selectProductGenre: [],
      selectUsageEffects: [],
      selectFlavorProfiles: [],

      show: true, // 表单显示
      editor: ClassicEditor,
      showReportLink: false,
      showReportEnclosure: false,
      flavor: [],
      flavorLabel: "",
      editId: window.location.search,
    };
  },
  props: {
    showCreateFlavor: {
      type: Boolean,
      default: false,
    },
    deviceModelId: {
      type: String,
      default: "",
    },
  },
  watch: {
    showCreateFlavor(value) {
      if (value) {
        this.form.deviceModelId = this.deviceModelId;
      }
    },
  },
  mounted() {
    this.init();
    if (this.form.commodityCarouselMap == null)
      this.form.commodityCarouselMap = [];
  },
  methods: {
    init() {
      let that = this;
      this.$api.Dropdown.brandList().then((res) => {
        if (res.success) {
          for (let i = 0; i < res.data.length; i++) {
            this.types.brandNames.push({
              text: res.data[i].brandName,
              value: res.data[i].id,
            });
          }
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });

      this.$api.Dropdown.selectTypeByOil().then((res) => {
        if (res.success) {
          for (let i = 0; i < res.data.length; i++) {
            this.types.TypeEncoding.push({
              text: res.data[i].typeName,
              value: res.data[i].id,
            });
          }
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });

      //加载商品标签列表
      this.$api.Dropdown.selectAllCommodityLabel().then((res) => {
        if (res.success && res.data) {
          that.types.productGenreList = res.data;
          console.log(that.form.commodityLabelId);
          if (this.form.commodityLabelId) {
            that.types.productGenreList.forEach((element) => {
              that.form.commodityLabelId.forEach((item) => {
                if (element.id == item) {
                  that.selectProductGenre.push(element);
                }
              });
            });
          }
        } else {
          if (res.message) {
            this.$toast(res.message);
          }
        }
      });

      //加载使用感受标签列表
      this.$api.Dropdown.selectAllExperienceFeelingsLabel().then((res) => {
        if (res.success && res.data) {
          that.types.usageEffectsList = res.data;

          that.types.usageEffectsList.forEach((element) => {
            if (that.form.experienceFeelingsLabelId) {
              that.form.experienceFeelingsLabelId.forEach((item) => {
                if (element.id == item) {
                  that.selectUsageEffects.push(element);
                }
              });
            }
          });
        } else {
          if (res.message) {
            this.$toast(res.message);
          }
        }
      });

      //加载口感标签列表
      this.$api.Dropdown.selectAllTasteLabel().then((res) => {
        if (res.success && res.data) {
          this.types.flavorProfilesList = res.data;

          that.types.flavorProfilesList.forEach((element) => {
            if (that.form.tasteLabelId) {
              that.form.tasteLabelId.forEach((item) => {
                if (element.id == item) {
                  that.selectFlavorProfiles.push(element);
                }
              });
            }
          });
        } else {
          if (res.message) {
            this.$toast(res.message);
          }
        }
      });

      //加载强度标签列表
      this.$api.Dropdown.selectAllStrength().then((res) => {
        if (res.success && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.types.IntensityList.push({
              text: res.data[i].labelName,
              value: res.data[i].id,
            });
          }
        } else {
          if (res.message) {
            this.$toast(res.message);
          }
        }
      });

      //加载适用设备列表
      this.$api.Dropdown.selectAllDeviceModel().then((res) => {
        if (res.success && res.data) {
          this.types.deviceList = this.types.deviceList.concat(res.data);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    changeType(type) {
      // this.types.flavorOptions = [];
      if (type) {
        if (type == 1) {
          // this.flavorLabel = "Flavor:";
          this.form.medicalCare = 1;
          this.form.daySeconds = "";
        } else if (type == 4) {
          // this.flavorLabel = "Graphic Name:";
          this.form.medicalCare = 1;
          this.form.consistence = "";
          this.form.daySeconds = "";
        } else if (type == 5) {
          // this.flavorLabel = "Flavor:";
          this.form.medicalCare = 0;
        } else {
          // this.flavorLabel = "Flavor:";
          this.form.medicalCare = 1;
          this.form.consistence = "";
          this.form.daySeconds = "";
        }
      }
    },
    onReady: onReady,
    selectGenre(val) {
      this.form.commodityLabelId = val.map((item) => {
        return item;
      });
    },
    selectEffects(val) {
      this.form.experienceFeelingsLabelId = val.map((item) => {
        return item;
      });
    },
    selectProfiles(val) {
      this.form.tasteLabelId = val.map((item) => {
        return item;
      });
    },
    //上传烟油口味图片
    upload(e) {
      let file = e.target.files[0];
      this.photoName = file.name;
      if (/image/.test(file.type)) {
        upload_img(file).then((res) => {
          if (res.message) {
            this.$toast(res);
          }
          if (res.success) {
            this.form.flavorPicture = res.data;
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    uploadCarouse(e) {
      let file = e.target.files[0];
      if (this.form.commodityCarouselMap.length >= 5) {
        this.$bvToast.toast("Upload up to five pictures.");
        return;
      }
      if (/image/.test(file.type)) {
        upload_img(file).then((res) => {
          if (res.message) {
            this.$toast(res);
          }
          if (res.success) {
            this.form.commodityCarouselMap.push(res.data);
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    uploadDetails(e) {
      let file = e.target.files[0];
      if (this.form.commodityDetailsMap.length >= 5) {
        this.$bvToast.toast("Upload up to five pictures.");
        return;
      }
      if (/image/.test(file.type)) {
        upload_img(file).then((res) => {
          if (res.message) {
            this.$toast(res);
          }
          if (res.success) {
            this.form.commodityDetailsMap.push(res.data);
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    //删除商品口味图片
    onDeleteFlavorPicture() {
      let that = this;
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Operation tips",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            that.form.deleteFileUrl.push(that.form.flavorPicture);
            that.form.flavorPicture = "";
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    //删除商品轮播图
    onDeleteCarouselMap(item, index) {
      let that = this;
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Operation tips",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            that.form.deleteFileUrl.push(item);
            that.form.commodityCarouselMap.splice(index, 1);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    //删除商品详情图
    onDeleteDetailsMap(item, index) {
      let that = this;
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Operation tips",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            that.form.deleteFileUrl.push(item);
            that.form.commodityDetailsMap.splice(index, 1);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    onReset(evt) {
      this.form = {
        supplierId: localStorage.supplierId,

        modelName: "", //口味名称
        type: "", //烟弹类型
        deviceModelId: "", //使用设备
        customTemperature: 1, //是否可自定义温度 0是 1否
        suggestRetailPrice: "", //建议零售价
        retailPrice: "", //实际零售价
        commodityLabelId: "", //商品标签编号
        experienceFeelingsLabelId: "", //使用感受标签编号
        tasteLabelId: "", //口感标签编号
        strengthId: "", //强度标签编号
        brand: "", //所属品牌
        flavorPicture: "", //烟油口味图片
        commodityCarouselMap: [], //商品轮播图
        commodityCreationStory: "", //创作故事
        commodityDetailsMap: [], //商品详情图
        onSale: 1, //是否上架 0是 1否
        cartUrl: "", //购物车URL
        flavorSort: "", //排序
        remarks: "", //备注

        consistence: 0, //烟油浓稠度
        medicalCare: "", //是否医用
        daySeconds: "", //吸入上限设置
        deleteFileUrl: [], //删除照片集合 服务端处理
        deviceType: "", //设备类型
      };
      this.reportType = "";
      this.photoName = "";
    },
    onConfirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.form.deviceModelId == 0) {
        this.form.deviceModelId = "";
      }
      if (this.selectProductGenre.length > 0) {
        this.form.commodityLabelId = "";
        for (let i = 0; i < this.selectProductGenre.length; i++) {
          this.form.commodityLabelId += this.selectProductGenre[i].id + ",";
        }
        this.form.commodityLabelId = this.form.commodityLabelId.substring(
          0,
          this.form.commodityLabelId.length - 1
        );
      } else {
        this.$bvToast.toast("Please select Product Genre Tags.");
        return;
      }
      if (this.selectUsageEffects.length > 0) {
        this.form.experienceFeelingsLabelId = "";
        for (let i = 0; i < this.selectUsageEffects.length; i++) {
          this.form.experienceFeelingsLabelId +=
            this.selectUsageEffects[i].id + ",";
        }
        this.form.experienceFeelingsLabelId =
          this.form.experienceFeelingsLabelId.substring(
            0,
            this.form.experienceFeelingsLabelId.length - 1
          );
      } else {
        this.$bvToast.toast("Please select Usage Effects.");
        return;
      }
      if (this.selectFlavorProfiles.length > 0) {
        this.form.tasteLabelId = "";
        for (let i = 0; i < this.selectFlavorProfiles.length; i++) {
          this.form.tasteLabelId += this.selectFlavorProfiles[i].id + ",";
        }
        this.form.tasteLabelId = this.form.tasteLabelId.substring(
          0,
          this.form.tasteLabelId.length - 1
        );
      } else {
        this.$bvToast.toast("Please select Flavor Profiles.");
        return;
      }

      if (this.form.commodityCarouselMap.length <= 0) {
        this.$bvToast.toast("Please upload Carousel.");
        return;
      }
      if (this.form.commodityDetailsMap.length <= 0) {
        this.$bvToast.toast("Please upload Details.");
        return;
      }
      this.$api.CartridgeModel.add(this.form).then((res) => {
        if (res.success) {
          //添加完成
          this.onReset();
          this.$emit("AddFlavorComplete", false);
          this.$emit("CancelEvent", false);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onCancel(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.onReset();
      this.$emit("CancelEvent", false);
    },
    onClose(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.onReset();
      this.$emit("CancelEvent", false);
    },
  },
  computed: {
    ...mapGetters(["isIkrusher","isUnicoreus"]),
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-footer {
  display: none;
}
::v-deep .card-body {
  height: 550px;
  overflow: hidden;
  overflow-y: auto;
}
</style>