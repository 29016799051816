var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Layout", [
    _c(
      "div",
      { staticStyle: { "margin-top": "1rem" }, attrs: { role: "tablist" } },
      [
        _c(
          "b-card",
          { staticClass: "mb-1", attrs: { "no-body": "" } },
          [
            _c(
              "b-card-header",
              {
                staticClass: "p-1",
                attrs: { "header-tag": "header", role: "tab" }
              },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle.accordion-1",
                        modifiers: { "accordion-1": true }
                      }
                    ],
                    staticStyle: {
                      "text-align": "left",
                      "line-height": "3",
                      "font-size": "1rem"
                    },
                    attrs: { block: "", href: "#", variant: "outline-primary" }
                  },
                  [_vm._v("Step 1: Basic Data")]
                )
              ],
              1
            ),
            _c(
              "b-collapse",
              {
                attrs: {
                  id: "accordion-1",
                  visible: _vm.basicDataVisible,
                  accordion: "my-accordion",
                  role: "tabpanel"
                }
              },
              [
                _c(
                  "b-card-body",
                  {
                    staticStyle: { "padding-top": "0", "padding-bottom": "0" }
                  },
                  _vm._l(_vm.basicDataList, function(basicData, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: {
                              "text-align": "left",
                              "line-height": "2",
                              margin: "5px"
                            },
                            attrs: {
                              block: "",
                              variant: basicData.indeterminate
                                ? "outline-dark"
                                : "outline-success"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goPage(
                                  basicData,
                                  index,
                                  "setBasicDataList"
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: { disabled: basicData.indeterminate },
                                model: {
                                  value: basicData.indeterminate,
                                  callback: function($$v) {
                                    _vm.$set(basicData, "indeterminate", $$v)
                                  },
                                  expression: "basicData.indeterminate"
                                }
                              },
                              [
                                basicData.indeterminate
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "haveToClick",
                                        staticStyle: { cursor: "pointer" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0"
                                            }
                                          },
                                          [_vm._v(_vm._s(basicData.text))]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(basicData.explain))
                                        ])
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticStyle: { cursor: "pointer" } },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0"
                                            }
                                          },
                                          [_vm._v(_vm._s(basicData.text))]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(basicData.explain))
                                        ])
                                      ]
                                    )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "b-card",
          { staticClass: "mb-1", attrs: { "no-body": "" } },
          [
            _c(
              "b-card-header",
              {
                staticClass: "p-1",
                attrs: { "header-tag": "header", role: "tab" }
              },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle.accordion-2",
                        modifiers: { "accordion-2": true }
                      }
                    ],
                    staticStyle: {
                      "text-align": "left",
                      "line-height": "3",
                      "font-size": "1rem"
                    },
                    attrs: { block: "", href: "#", variant: "outline-primary" }
                  },
                  [_vm._v("Step 2: Flavor Info")]
                )
              ],
              1
            ),
            _c(
              "b-collapse",
              {
                attrs: {
                  id: "accordion-2",
                  accordion: "my-accordion",
                  role: "tabpanel",
                  visible: _vm.flavorInfoVisible
                }
              },
              [
                _c(
                  "b-card-body",
                  {
                    staticStyle: { "padding-top": "0", "padding-bottom": "0" }
                  },
                  _vm._l(_vm.flavorInfoList, function(flavorInfo, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: {
                              "text-align": "left",
                              "line-height": "2",
                              margin: "5px"
                            },
                            attrs: {
                              block: "",
                              variant: flavorInfo.indeterminate
                                ? "outline-dark"
                                : "outline-success"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goPage(
                                  flavorInfo,
                                  index,
                                  "setFlavorInfoList"
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: { disabled: flavorInfo.indeterminate },
                                model: {
                                  value: flavorInfo.indeterminate,
                                  callback: function($$v) {
                                    _vm.$set(flavorInfo, "indeterminate", $$v)
                                  },
                                  expression: "flavorInfo.indeterminate"
                                }
                              },
                              [
                                flavorInfo.indeterminate
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "haveToClick",
                                        staticStyle: { cursor: "pointer" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0"
                                            }
                                          },
                                          [_vm._v(_vm._s(flavorInfo.text))]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(flavorInfo.explain))
                                        ])
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticStyle: { cursor: "pointer" } },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0"
                                            }
                                          },
                                          [_vm._v(_vm._s(flavorInfo.text))]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(flavorInfo.explain))
                                        ])
                                      ]
                                    )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "b-card",
          { staticClass: "mb-1", attrs: { "no-body": "" } },
          [
            _c(
              "b-card-header",
              {
                staticClass: "p-1",
                attrs: { "header-tag": "header", role: "tab" }
              },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle.accordion-3",
                        modifiers: { "accordion-3": true }
                      }
                    ],
                    staticStyle: {
                      "text-align": "left",
                      "line-height": "3",
                      "font-size": "1rem"
                    },
                    attrs: { block: "", href: "#", variant: "outline-primary" }
                  },
                  [_vm._v("Step 3: Production Process")]
                )
              ],
              1
            ),
            _c(
              "b-collapse",
              {
                attrs: {
                  id: "accordion-3",
                  accordion: "my-accordion",
                  role: "tabpanel",
                  visible: _vm.productionProcessVisible
                }
              },
              [
                _c(
                  "b-card-body",
                  {
                    staticStyle: { "padding-top": "0", "padding-bottom": "0" }
                  },
                  _vm._l(_vm.productionProcessList, function(
                    productionProcess,
                    index
                  ) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: {
                              "text-align": "left",
                              "line-height": "2",
                              margin: "5px"
                            },
                            attrs: {
                              block: "",
                              variant: productionProcess.indeterminate
                                ? "outline-dark"
                                : "outline-success"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goPage(
                                  productionProcess,
                                  index,
                                  "setProductionProcessList"
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: {
                                  disabled: productionProcess.indeterminate
                                },
                                model: {
                                  value: productionProcess.indeterminate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      productionProcess,
                                      "indeterminate",
                                      $$v
                                    )
                                  },
                                  expression: "productionProcess.indeterminate"
                                }
                              },
                              [
                                productionProcess.indeterminate
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "haveToClick",
                                        staticStyle: { cursor: "pointer" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(productionProcess.text) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(productionProcess.explain)
                                          )
                                        ])
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticStyle: { cursor: "pointer" } },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(productionProcess.text) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(productionProcess.explain)
                                          )
                                        ])
                                      ]
                                    )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "b-card",
          { staticClass: "mb-1", attrs: { "no-body": "" } },
          [
            _c(
              "b-card-header",
              {
                staticClass: "p-1",
                attrs: { "header-tag": "header", role: "tab" }
              },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle.accordion-4",
                        modifiers: { "accordion-4": true }
                      }
                    ],
                    staticStyle: {
                      "text-align": "left",
                      "line-height": "3",
                      "font-size": "1rem"
                    },
                    attrs: { block: "", href: "#", variant: "outline-primary" }
                  },
                  [_vm._v("Step 4: View The Data")]
                )
              ],
              1
            ),
            _c(
              "b-collapse",
              {
                attrs: {
                  id: "accordion-4",
                  accordion: "my-accordion",
                  role: "tabpanel",
                  visible: _vm.viewVisible
                }
              },
              [
                _c(
                  "b-card-body",
                  {
                    staticStyle: { "padding-top": "0", "padding-bottom": "0" }
                  },
                  _vm._l(_vm.viewList, function(view, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: {
                              "text-align": "left",
                              "line-height": "2",
                              margin: "5px"
                            },
                            attrs: {
                              block: "",
                              variant: view.indeterminate
                                ? "outline-dark"
                                : "outline-success"
                            },
                            on: {
                              click: function($event) {
                                return _vm.goPage(view, index, "setViewList")
                              }
                            }
                          },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: { disabled: view.indeterminate },
                                model: {
                                  value: view.indeterminate,
                                  callback: function($$v) {
                                    _vm.$set(view, "indeterminate", $$v)
                                  },
                                  expression: "view.indeterminate"
                                }
                              },
                              [
                                view.indeterminate
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "haveToClick",
                                        staticStyle: { cursor: "pointer" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0"
                                            }
                                          },
                                          [_vm._v(_vm._s(view.text))]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(view.explain))
                                        ])
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticStyle: { cursor: "pointer" } },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0"
                                            }
                                          },
                                          [_vm._v(_vm._s(view.text))]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(view.explain))
                                        ])
                                      ]
                                    )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }