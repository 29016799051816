var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "form-row",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.username
                                        ? _vm.form.username.length
                                        : 0,
                                      max: "100"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Account Email:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "account",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "account",
                                  placeholder: "Please enter",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  maxlength: "100",
                                  state:
                                    _vm.form.username &&
                                    _vm.form.username.length >= 6 &&
                                    _vm.form.username.length <= 100,
                                  oninput: "setCustomValidity('')",
                                  disabled: _vm.editType
                                },
                                model: {
                                  value: _vm.form.username,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "username", $$v)
                                  },
                                  expression: "form.username"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.firstName
                                        ? _vm.form.firstName.length
                                        : 0,
                                      max: "60"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "First Name:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "firstName",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "firstName",
                                  trim: "",
                                  placeholder: "Please enter",
                                  required: "",
                                  maxlength: "60",
                                  oninput: "setCustomValidity('')"
                                },
                                model: {
                                  value: _vm.form.firstName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "firstName", $$v)
                                  },
                                  expression: "form.firstName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.lastName
                                        ? _vm.form.lastName.length
                                        : 0,
                                      max: "60"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Last Name:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "lastName",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "lastName",
                                  trim: "",
                                  placeholder: "Please enter",
                                  required: "",
                                  maxlength: "60",
                                  oninput: "setCustomValidity('')"
                                },
                                model: {
                                  value: _vm.form.lastName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "lastName", $$v)
                                  },
                                  expression: "form.lastName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("form-row", [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Role:",
                                  "label-cols-lg": "3",
                                  "label-align-lg": "right",
                                  "label-for": "role",
                                  "label-class": "requiredRow"
                                }
                              },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    id: "role",
                                    options: _vm.types.roleList,
                                    "value-field": "id",
                                    "text-field": "roleName",
                                    required: "",
                                    oninvalid:
                                      "setCustomValidity('Please select');",
                                    oninput: "setCustomValidity('');"
                                  },
                                  model: {
                                    value: _vm.form.roleId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "roleId", $$v)
                                    },
                                    expression: "form.roleId"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "form-row",
                        {
                          scopedSlots: _vm._u(
                            [
                              !_vm.editType
                                ? {
                                    key: "right",
                                    fn: function() {
                                      return [
                                        _c("CountText", {
                                          attrs: {
                                            len: _vm.form.password
                                              ? _vm.form.password.length
                                              : 0,
                                            max: "60"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                : null
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Password:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "password",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "password",
                                  trim: "",
                                  required: "",
                                  placeholder: "Please enter",
                                  type: "password",
                                  disabled: _vm.editType,
                                  state:
                                    _vm.form.password &&
                                    _vm.form.password.length >= 6 &&
                                    _vm.form.password.length <= 60,
                                  maxlength: "60",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.form.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password"
                                }
                              }),
                              _vm.editType
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "outline-secondary" },
                                      on: {
                                        click: function($event) {
                                          _vm.modalShow = !_vm.modalShow
                                        }
                                      }
                                    },
                                    [_vm._v("Reset Passwords")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !_vm.editType
                        ? _c(
                            "form-row",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "right",
                                    fn: function() {
                                      return [
                                        _c("CountText", {
                                          attrs: {
                                            len: _vm.confirmPassword
                                              ? _vm.confirmPassword.length
                                              : 0,
                                            max: "60"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3650571582
                              )
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Confirm password:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "password",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "password",
                                      trim: "",
                                      required: "",
                                      placeholder: "Please enter",
                                      type: "password",
                                      state:
                                        _vm.confirmPassword &&
                                        _vm.confirmPassword.length >= 6 &&
                                        _vm.confirmPassword.length <= 60 &&
                                        _vm.form.password ==
                                          _vm.confirmPassword,
                                      maxlength: "60",
                                      oninvalid:
                                        "setCustomValidity('Please enter');",
                                      oninput: "setCustomValidity('')"
                                    },
                                    model: {
                                      value: _vm.confirmPassword,
                                      callback: function($$v) {
                                        _vm.confirmPassword = $$v
                                      },
                                      expression: "confirmPassword"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Profile Photo:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "Photo"
                              }
                            },
                            [
                              _c("input", {
                                staticClass: "filestyle",
                                staticStyle: {
                                  position: "absolute",
                                  clip: "rect(0px, 0px, 0px, 0px)"
                                },
                                attrs: {
                                  type: "file",
                                  "data-input": "false",
                                  id: "filestyle-1",
                                  tabindex: "-1"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.upload($event)
                                  }
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "bootstrap-filestyle input-group"
                                },
                                [
                                  _c("div", {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100%",
                                      height: "35px",
                                      "z-index": "-1"
                                    },
                                    attrs: { name: "filedrag" }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "group-span-filestyle",
                                      attrs: { tabindex: "0" }
                                    },
                                    [
                                      _vm.form.picture
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "10px"
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "100px",
                                                  border: "2px dashed #ccc"
                                                },
                                                attrs: { src: _vm.form.picture }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "btn btn-info",
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: { for: "filestyle-1" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "buttonText" },
                                            [_vm._v(" upload picture ")]
                                          )
                                        ]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          " Recommended size of picture: 120 pixels * 120 pixels, supporting JPG and PNG formats. "
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-modal",
                        {
                          ref: "modal",
                          attrs: {
                            title: "Edit Password",
                            "hide-header": "",
                            "hide-footer": "",
                            centered: ""
                          },
                          on: { hidden: _vm.resetModal },
                          model: {
                            value: _vm.modalShow,
                            callback: function($$v) {
                              _vm.modalShow = $$v
                            },
                            expression: "modalShow"
                          }
                        },
                        [
                          _c(
                            "form",
                            {
                              ref: "form",
                              on: { submit: _vm.onSure, reset: _vm.onClose }
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Password:",
                                    "label-cols-lg": "4",
                                    "label-align-lg": "right",
                                    "label-for": "password",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "password",
                                      trim: "",
                                      required: "",
                                      placeholder: "Enter password",
                                      type: "password",
                                      state:
                                        _vm.modalData.password &&
                                        _vm.modalData.password.length >= 6 &&
                                        _vm.modalData.password.length <= 60,
                                      oninvalid:
                                        "setCustomValidity('Please enter');",
                                      oninput: "setCustomValidity('');"
                                    },
                                    model: {
                                      value: _vm.modalData.password,
                                      callback: function($$v) {
                                        _vm.$set(_vm.modalData, "password", $$v)
                                      },
                                      expression: "modalData.password"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Confirm password:",
                                    "label-cols-lg": "4",
                                    "label-align-lg": "right",
                                    "label-for": "password",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "password",
                                      trim: "",
                                      required: "",
                                      placeholder: "Enter password",
                                      type: "password",
                                      state:
                                        _vm.modalData.confirmPassword &&
                                        _vm.modalData.confirmPassword.length >=
                                          6 &&
                                        _vm.modalData.confirmPassword.length <=
                                          60 &&
                                        _vm.modalData.password ==
                                          _vm.modalData.confirmPassword,
                                      oninvalid:
                                        "setCustomValidity('Please enter');",
                                      oninput: "setCustomValidity('');"
                                    },
                                    model: {
                                      value: _vm.modalData.confirmPassword,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.modalData,
                                          "confirmPassword",
                                          $$v
                                        )
                                      },
                                      expression: "modalData.confirmPassword"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                { attrs: { "label-cols-lg": "4" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "custom-width",
                                      attrs: {
                                        type: "submit",
                                        variant: "primary"
                                      }
                                    },
                                    [_vm._v("Save")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "ml-4 custom-width",
                                      attrs: { type: "reset" }
                                    },
                                    [_vm._v("Cancel")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { "label-cols-lg": "3" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "custom-width",
                  attrs: { type: "submit", variant: "primary" }
                },
                [_vm._v("Save")]
              ),
              _c(
                "b-button",
                { staticClass: "ml-4 custom-width", attrs: { type: "reset" } },
                [_vm._v("Cancel")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/success.png"), noBtn: "OK" },
          on: { cancel: _vm.cancel, hide: _vm.hide },
          model: {
            value: _vm.successAlear,
            callback: function($$v) {
              _vm.successAlear = $$v
            },
            expression: "successAlear"
          }
        },
        [_vm._v(" Saved successfully ")]
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/confirm.svg"), noBtn: "OK" },
          on: { cancel: _vm.cancel1, hide: _vm.hide1 },
          model: {
            value: _vm.errorAlear,
            callback: function($$v) {
              _vm.errorAlear = $$v
            },
            expression: "errorAlear"
          }
        },
        [_vm._v(" " + _vm._s(_vm.errorContent) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }