<!--
 * @Author: Code-HHX
 * @Date: 2022-04-26 18:24:29
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-28 17:59:16
 * @Description: 
-->
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- 用户资料 -->
    <b-card>
      <div class="vo-column" style="width: 100%">
        <div class="productDetailRow">
          <div class="productDetailColumn">
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Company Name:</strong>
              </p>
              <p class="productDetailContent">{{ info.companyName }}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Company Address:</strong>
              </p>
              <p class="productDetailContent">{{ info.addressDetails }}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Company business:</strong>
              </p>
              <p class="productDetailContent">{{ info.companyBusinessId }}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Company Email:</strong>
              </p>
              <p class="productDetailContent">{{ info.email }}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Company Phone:</strong>
              </p>
              <p class="productDetailContent">{{ info.phoneNumber }}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Company Contacts:</strong>
              </p>
              <p class="productDetailContent">{{ info.name }}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Company Introduction:</strong>
              </p>

              <div class="productDetailContent">
                <b-row style="margin-left: -10px">
                  <b-col cols="8">
                    <b-form-textarea
                      id="company-introduction"
                      :placeholder="info.companyIntroduction"
                      :disabled="true"
                      rows="10"
                      max-rows="10"
                      maxlength="2000"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
              </div>
            </div>

            <div class="vo-row" style="padding-top: 10px">
              <p class="productDetailContentColor">
                <strong>Brand:</strong>
              </p>
              <div class="productDetailContent">
                <b-row cols-xl="5" style="margin-left: -10px">
                  <b-col
                    class="pd cursor"
                    v-for="(item, i) in brandList"
                    :key="i"
                  >
                    <ImageComponets :image="item.brandPicture" />
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <b-form-group label-cols-lg="2">
      <b-button type="reset" class="custom-width" @click="$router.back()"
        >Cancel
      </b-button>
    </b-form-group>

  </Layout>
</template>
<style scoped>
.productDetailRow {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.productDetailColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.productDetailContent {
  text-align: left;
  flex: 1;
  margin-left: 5px;
}
.productDetailContentColor {
  width: 200px;
  text-align: right;
  color: #333333;
}
.pd {
  padding-bottom: 10px;
}
</style>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import CreateProduct from "@/components/product/CreateProduct.vue";
import ImageComponets from "@/components/brand/ImageComponets.vue";

/**
 * User Info component
 */
export default {
  components: {
    Layout,
    PageHeader,
    timeZoneConversion,
    CreateProduct,
    ImageComponets,
  },
  data() {
    return {
      title: "View Client Info",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "Client&Brand",
        },
        {
          text: "Client Mgmt",
        },
        {
          text: "Client List",
        },
        {
          text: "View Client info",
          active: true,
        },
      ],
      // 绑定的设备信息
      recordsFields: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "operationType",
          label: "Operation type",
          class: "text-center",
          formatter: (value) => {
            let message = "";
            switch (value) {
              case 1:
                message = "Apply";
                break;
              case 2:
                message = "Verify";
                break;
              case 3:
                message = "Create";
                break;
              case 4:
                message = "Modify";
                break;
              default:
                message = "";
            }
            return message;
          },
        },
        {
          key: "verifyStatus",
          label: "Describe",
          class: "text-center",
          formatter: (value) => {
            return value == 1 ? "Pass" : value == 2 ? "Not Pass" : "-";
          },
        },
        {
          key: "operationName",
          label: "Operator",
          class: "text-center",
        },
      ],
      info: {},
      records: {
        pageNum: 1,
        pageSize: 10,
        id: this.$route.query.id,
      },
      records_isBusy: false,
      records_totalRows: 0,
      brandList: [],
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    //获取使用记录（分页）
    records_provider(ctx) {
      this.records_isBusy = true;
      return this.$api.Client.selectClientOperationRecord(
        this.records,
        ctx
      ).then((x) => {
        this.records_totalRows = x.total;
        this.records_isBusy = false;
        return x.data;
      });
    },
    init() {
      if (this.$route.query.id) {
        this.$api.Client.getClientDetails(this.$route.query).then((res) => {
          if (res.success) {
            this.info = res.data;
            this.brandList = res.data.brandList;
            if (res.data.companyBusinessId) {
              const newBusiness = res.data.companyBusinessId.map((value) => {
                if (value == 1) {
                  return "Nicotine";
                } else if (value == 2) {
                  return "CBD/THC Concentrates";
                }
              });
              this.info.companyBusinessId = newBusiness.join(",");
            }
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    returnPage() {
      this.$router.go(-1);
    },
    getLocalTime(status, value) {
      return status == 1
        ? timeZoneConversion.getLocalTimes(value)
        : timeZoneConversion.getLocalTime(value);
    },
  },
};
</script>
