<!--
 * @Author: Code-HHX
 * @Date: 2022-03-29 11:30:16
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-03-31 16:03:39
 * @Description: 兑换记录
-->
<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<!-- main -->
		<b-row>
			<b-col lg="12">
				<!-- 查询条件 -->
				<b-card>
					<b-row>
						<!-- Nickname 用户 -->
						<b-col lg="3">
							<b-form-group
								label="Nickname"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="nick-name"
							>
								<b-form-input
									id="nick-name"
									trim
									v-model="search_condition.nickName"
									oninput="if(value.length > 20)value = value.slice(0, 20)"
								></b-form-input>
							</b-form-group>
						</b-col>
						<!-- Exchange Mode 兑换方式 -->
						<b-col lg="3">
							<b-form-group
								label="Exchange Mode"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="exchange-mode"
							>
								<b-form-select
									id="exchange-mode"
									:options="types.modeList"
									v-model="search_condition.exchangeType"
								></b-form-select>
							</b-form-group>
						</b-col>
						<!-- Exchange Time 兑换时间 -->
						<b-col lg="3">
							<b-form-group
								label="Exchange Time"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="exchange-time"
							>
								<date-picker
									v-model="selectTime"
									format="MM/DD/YYYY"
									range
									append-to-body
									lang="en"
									placeholder="Select date"
								></date-picker>
							</b-form-group>
						</b-col>

						<b-col lg="3">
							<!-- <b-form-group label label-cols-sm="4" label-align-sm="right"> -->
							<b-button variant="primary" @click="search()"
								>Search</b-button
							>
							<!-- </b-form-group> -->
						</b-col>
					</b-row>

					<b-row class="text-center">
						<b-col lg="12"></b-col>
					</b-row>
				</b-card>

				<b-card>
					<b-button-group>
						<b-button
							v-has="'exchangeRecord::Export'"
							@click="export_checkout"
							variant="primary"
							>Export</b-button
						>
					</b-button-group>
					<!-- table -->
					<div class="table-responsive mb-0">
						<b-table
							show-empty
							empty-text="No records"
							id="device-table"
							head-variant="light"
							:striped="true"
							ref="table"
							:busy="isBusy"
							:items="provider"
							:fields="fields"
							:per-page="search_condition.pageSize"
							:current-page="search_condition.pageNum"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:filter="filter"
							:filter-includedfields="filterOn"
							@filtered="onFiltered"
						>
							<template v-slot:table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>

							<template v-slot:cell(index)="data">{{
								data.index +
									(search_condition.pageNum - 1) *
										search_condition.pageSize +
									1
							}}</template>

							<template v-slot:cell(actions)="row">
								<b-button-group>
									<b-button
										v-has="'exchangeRecord::Details'"
										size="sm"
										variant="info"
										@click="
											details(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
									>
										Details
									</b-button>
								</b-button-group>
							</template>
						</b-table>
					</div>
					<!-- end table -->
					<!-- pagination -->
					<b-row v-if="has_data">
						<b-col>
							<div
								class="dataTables_paginate paging_simple_numbers float-right"
							>
								<ul class="pagination mb-0" v-if="rows != 0">
									<b-pagination
										v-model="search_condition.pageNum"
										:per-page="10"
										:total-rows="rows"
										aria-controls="table"
									></b-pagination>
								</ul>
							</div>
						</b-col>
					</b-row>
					<!-- end pagination -->
				</b-card>
			</b-col>
		</b-row>
		<!-- end main -->

		<!-- <div class="text-center">
			<b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>-->
	</Layout>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'

/**
 * User component
 */
export default {
	components: {
		DatePicker,
		Layout,
		PageHeader,
		timeZoneConversion,
	},
	data() {
		return {
			title: 'Exchange Record',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'Marketing Mgmt',
					// href: "/"
				},
				{
					text: 'Exchange Record',
					active: true,
				},
			],

			search_condition: {
				pageNum: 1,
				pageSize: 10,
				nickName: '', //用户名
				exchangeType: '', //1是优惠券 2是实物
				orderNumber: '', //订单号
				startTime: '', //开始时间
				endTime: '', //结束时间

				operationMethod: '', //获得方式 1是激活设备,2是兑换消耗 3是邀请好友
				supplierId: localStorage.supplierId,
			},
			selectTime: ['', ''],

			types: {
				//兑换方式
				modeList: [
					{
						text: 'Please select',
						value: '',
					},
					{
						text: 'Coupon', //优惠券
						value: 1,
					},
					{
						text: 'Object', //实物
						value: 2,
					},
				],
			},

			fields: [
				{ key: 'index', label: '#', class: 'text-center' },
				{
					key: 'orderNumber', //订单号
					label: 'Order No',
					class: 'text-center',
				},
				{
					key: 'nickName', //兑换用户
					label: 'Nickname',
					class: 'text-center',
				},
				{
					key: 'exchangeType', //兑换方式
					label: 'Exchange Type',
					class: 'text-center',
				},
				{
					key: 'productName', //兑换商品
					label: 'Exchange Commodity',
					class: 'text-center',
				},
				{
					key: 'integral', //消耗积分数量
					label: 'Consume Gems',
					class: 'text-center',
				},

				{
					key: 'createTime', //兑换时间
					label: 'Exchange Time',
					formatter: (value) => {
						return timeZoneConversion.getLocalTimes(value)
					},
					class: 'text-center',
				},
				{ key: 'actions', label: 'Opreration', class: 'text-center' },
			],

			tableData: [],
			totalRows: 0,
			currentPage: 1,
			perPage: 10,
			pageOptions: [10, 25, 50, 100],
			filter: null,
			filterOn: [],
			sortBy: 'id',
			sortDesc: false,
			isBusy: false,
			has_data: false,
		}
	},
	watch: {
		selectTime: function(val) {
			this.search_condition.startTime = val[0]
				? timeZoneConversion.queryTime(val[0])
				: val[0]
			if (val[1]) {
				this.search_condition.endTime = timeZoneConversion.queryTime(
					val[1].getTime() + 24 * 3600 * 1000 - 1
				)
			} else {
				this.search_condition.endTime = val[1]
			}
		},
	},
	computed: {
		rows() {
			return this.totalRows
		},
	},
	mounted() {},
	created() {},
	methods: {
		provider(ctx) {
			this.isBusy = true
			return this.$api.Marketing.selectUserExchangeRecord(
				this.search_condition,
				ctx
			)
				.then((x) => {
					this.isBusy = false
					if (x.total) {
						this.has_data = true
						this.totalRows = x.total
						return x.data
					} else {
						this.has_data = false
						return []
					}
				})
				.catch((e) => {
					this.isBusy = false
					this.has_data = false
					return []
				})
		},

		export_excel() {
			this.$api.Marketing.exportUserExchangeRecord(this.search_condition)
		},
		export_checkout() {
			//校验是否存在搜索条件
			if (
				//!this.search_condition.nickName ||
				this.search_condition.startTime == '' ||
				this.search_condition.endTime == ''
			) {
				this.$toastWarning('Please select the search criteria first')
				return
			}
			//时间范围三个月内
			if (
				this.search_condition.startTime != '' &&
				this.search_condition.endTime != ''
			) {
				let three = 90 * 24 * 3600 * 1000
				let date = new Date(this.search_condition.endTime).getTime()
				let threeMonths = date - three
				if (
					threeMonths >
					new Date(this.search_condition.startTime).getTime()
				) {
					this.$toastWarning(
						'Please select the time frame within 3 months'
					)
					return
				}
			}
			this.export_excel()
		},
		details(item, index, button) {
			this.$router.push('exchangeRecordInfo?id=' + item.id)
		},
		search() {
			this.search_condition.pageNum = 1
			this.$refs.table.refresh()
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
	},
}
</script>
