<template>

  <div
      class="container"
      :style="containerStyles"
      @mouseenter="showMenu = true"
      @mouseleave="showMenu = false"
  >
    <slot/>
  
    <div v-if="showMenu" class="outerBox" :style="outerBoxStyles" @click="click">
      <div class="topButton">
        <slot name="top"/>
      </div>
      <div class="belowButton">
        <slot name="bottom"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MaskView",
  props: {
    backgroundColor: {
      type:String,
      default: ''
    },
    borderStyle: {
      type: String,
      default: '1px solid #02a7f0'
    },
    borderRadius: {
      type: [Number,String],
      default: 0
    },
    outerBorderStyle: {
      type: String,
      default: '1px solid #02a7f0'
    },
    maskBackgroundColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0.7)'
    },

  },
  data() {
    return {
      showMenu: false
    }
  },
  methods: {

    click() {
      this.$emit('click')
    }
  },
  computed: {
    outerBoxStyles() {
      return {
        border: this.outerBorderStyle,
        backgroundColor: this.maskBackgroundColor,
        borderRadius: `${this.borderRadius}px`
      }
    },
    containerStyles() {
      return {
        border: this.borderStyle,
        borderRadius: `${this.borderRadius}px`,
        backgroundColor: this.backgroundColor
      }
    },
  }
}
</script>


<style scoped lang="scss">
.container {
  position: relative;

  height:250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #d7d7d7;
  padding: 0;
}

.outerBox {
  border: 2px dashed #02a7f0;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5); /* 添加阴影效果 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex; /* 使用 Flexbox 来居中图片和文字 */
  z-index: 3;
  flex-direction: column;
  justify-content: space-between;
}
</style>