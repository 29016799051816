<template>
  <div class="vo-column align-items-center">

    <MaskView mask-background-color="transparent">
      <div class="position-relative vo-column align-items-center w-100">
        <el-image fit="contain" :src="item.value" style="height:200px" />
        <div class="cover position-absolute ">
          <b-badge variant="dark" v-if="item.status" class="cover-text">Cover</b-badge>
        </div>
      </div>
    </MaskView>
    <div class="vo-row align-items-center vo-space-around mt-2 belowButton w-100">
      <div class="custom-color cursor" @click="deletePicture">
        Delete
      </div>
      <div class="custom-color cursor" @click="setCover">
        Set as cove
      </div>
    </div>
  </div>
</template>

<script>
import MaskView from "@/components/MaskView.vue";

export default {
  name: "ProductPicture",
  components: {MaskView},
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
    }
  },
  methods:{
    deletePicture() {
      this.$emit("deletePicture");
    },
    setCover(){
      this.$emit("setCover");
    }
  }
}
</script>


<style scoped lang="scss">

::v-deep .badge {
  border-radius: 0;

}
.cover {
  top:-24px;
  left:0;
}
.cover-text{
  width: 70px;
  line-height: 29px;
  text-align: center;
  background-color: #555555;
}
</style>