var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Mode Name:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "application-name",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "application-name",
                      placeholder: "Please enter",
                      trim: "",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput:
                        "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                    },
                    model: {
                      value: _vm.form.warmUpName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "warmUpName", $$v)
                      },
                      expression: "form.warmUpName"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Preheat Temperature(℉):",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "path",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "application-name",
                      placeholder: "Please enter a number between 100 and 1000",
                      trim: "",
                      type: "number",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput: "setCustomValidity('');",
                      onblur: "if(value<100||value>1000)value=''"
                    },
                    model: {
                      value: _vm.form.temperature,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "temperature", $$v)
                      },
                      expression: "form.temperature"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Preheat Time(s):",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "path",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "application-name",
                      placeholder:
                        "Please enter a number between 10 and 120.The unit is in seconds",
                      trim: "",
                      type: "number",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput: "setCustomValidity('');",
                      onblur: "if(value<10||value>120)value=''"
                    },
                    model: {
                      value: _vm.form.warmUpTimes,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "warmUpTimes", $$v)
                      },
                      expression: "form.warmUpTimes"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Preheat Photo:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "logo"
                  }
                },
                [
                  _c("input", {
                    staticClass: "filestyle",
                    staticStyle: {
                      position: "absolute",
                      clip: "rect(0px, 0px, 0px, 0px)"
                    },
                    attrs: {
                      type: "file",
                      "data-input": "false",
                      id: "filestyle-1",
                      tabindex: "-1"
                    },
                    on: {
                      change: function($event) {
                        return _vm.upload($event)
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "bootstrap-filestyle input-group" },
                    [
                      _c("div", {
                        staticStyle: {
                          position: "absolute",
                          width: "100%",
                          height: "35px",
                          "z-index": "-1"
                        },
                        attrs: { name: "filedrag" }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "group-span-filestyle",
                          attrs: { tabindex: "0" }
                        },
                        [
                          _vm.form.warmUpPhoto
                            ? _c(
                                "div",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                      border: "2px dashed #ccc"
                                    },
                                    attrs: { src: _vm.form.warmUpPhoto }
                                  }),
                                  !_vm.editId
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                            "vertical-align": "bottom"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "500"
                                              }
                                            },
                                            [_vm._v("Picture name : ")]
                                          ),
                                          _vm._v(_vm._s(_vm.photoName))
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "label",
                            {
                              staticClass: "btn btn-secondary",
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { for: "filestyle-1" }
                            },
                            [
                              _c("span", { staticClass: "buttonText" }, [
                                _vm._v(" upload picture ")
                              ])
                            ]
                          ),
                          _c("span", [
                            _vm._v(
                              " Recommended size of picture: 120 pixels * 120 pixels, supporting JPG and PNG formats. "
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Status:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "status",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-radio-group", {
                    staticStyle: { "margin-top": "5px" },
                    attrs: {
                      id: "radio-group-1",
                      options: _vm.types.statuss,
                      name: "radio-options",
                      required: ""
                    },
                    model: {
                      value: _vm.form.status,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Notes",
                    "label-cols-lg": "2",
                    "label-align-sm": "right",
                    "label-for": "remark"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "remark",
                      placeholder: "Enter Notes",
                      rows: "3",
                      "max-rows": "6"
                    },
                    model: {
                      value: _vm.form.remarks,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "2" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-l-5 ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }