<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />
    <b-card>
      <b-row class="vo-items-center">
        <b-col lg="4">
          <div class="vo-row vo-items-center">
            <ReferenceBy label="REFINED BY" />
            <b-input
              style="flex: 1; margin-left: 10px"
              v-model="search_condition.roleName"
              placeholder="Search Role Name"
            />
          </div>
        </b-col>
        <b-col>
          <div class="vo-row vo-items-center vo-justify-between">
            <div class="custom-color cursor" @click="clearAll">Clear All</div>
            <b-button variant="info" @click="search">Search</b-button>
          </div>
        </b-col>
      </b-row>
      <div class="box-list">
        <!-- table -->
        <b-button v-has="'roleMgmt::Create'" @click="gotoAdd" variant="primary"
          >+Create Role</b-button
        >
        <div class="table-responsive mb-0">
          <b-table
            show-empty
            empty-text="No records"
            id="table"
            head-variant="light"
            ref="table"
            :busy="isBusy"
            :striped="true"
            :items="provider"
            :fields="fields"
            :per-page="search_condition.pageSize"
            :current-page="search_condition.pageNum"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-includedfields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template v-slot:cell(index)="data">
              {{
                data.index +
                (search_condition.pageNum - 1) * search_condition.pageSize +
                1
              }}
            </template>

            <template v-slot:cell(actions)="row">
              <b-button-group>
                <b-button
                  v-has="'roleMgmt::Modify'"
                  size="sm"
                  variant="primary"
                  @click="edit(row.item, row.index, $event.target)"
                  class="mr-1"
                >
                  Modify
                </b-button>
                <b-button
                  v-if="row.item.isDelete == 1"
                  v-has="'roleMgmt::Modify'"
                  size="sm"
                  variant="secondary"
                  @click="clickStatus(row.item)"
                  class="mr-1"
                >
                  Disabled
                </b-button>
                <b-button
                  v-if="row.item.isDelete == 0"
                  v-has="'roleMgmt::Modify'"
                  size="sm"
                  variant="success"
                  @click="clickStatus(row.item)"
                  class="mr-1"
                >
                  Enabled
                </b-button>
              </b-button-group>
            </template>
            <template v-slot:cell(isDelete)="row">
              <p v-if="row.item.isDelete == 0" class="clientStyle">Disabled</p>
              <p v-if="row.item.isDelete == 1">Enable</p>
            </template>
          </b-table>
        </div>
      </div>
      <!-- end table -->
      <!-- pagination -->
      <b-row v-if="has_data">
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="rows != 0">
              <li>
                <p
                  class="pagination mb-0"
                  style="margin-top: 7px; margin-right: 20px"
                >
                  {{ rows }} results
                </p>
              </li>
              <b-pagination
                v-model="search_condition.pageNum"
                :per-page="10"
                :total-rows="rows"
                aria-controls="table"
              >
              </b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
      <!-- end pagination -->
    </b-card>
    <Dialog
      v-model="showAlert"
      :imageSrc="require('@/assets/confirm.svg')"
      okBtn="Yes"
      noBtn="No"
      @ok="enableOrDisable"
      @cancel="cancel"
    >
      <p>{{ showContent }}</p>
    </Dialog>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel1"
    >
      Saved successfully
    </Dialog>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import ReferenceBy from "@/components/FindByLabel.vue";
import Dialog from "@/components/Dialog";

/**
 * FAQ component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    timeZoneConversion,
    ReferenceBy,
    Dialog,
  },
  data() {
    return {
      page: {
        title: "Role Mgmt",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "System Settings",
            // href: "/"
          },
          {
            text: "Role Mgmt",
            active: true,
          },
        ],
      },
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "id", label: "Role ID", class: "text-center" },
        { key: "roleName", label: "Role", class: "text-center" },
        {
          key: "isDelete",
          label: "Status",
          formatter: (value) => {
            return value == 1 ? "Enabled" : "Disabled";
          },
        },
        {
          key: "createTime",
          label: "Create Time",
          class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.getLocalTime(value);
          },
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        roleName: "",
      },
      isBusy: false,
      has_data: false,
      successAlear: false,
      showAlert: false,
      showContent: "",
      postData: {},
    };
  },
  watch: {},
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  created() {},
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    gotoAdd() {
      this.$router.push("roleMgmtAdd");
    },
    provider(ctx) {
      this.isBusy = true;
      return this.$api.systemManage
        .roleList(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    edit(item, index, button) {
      this.$router.push("roleMgmtEdit?id=" + item.id);
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    clearAll() {
      this.search_condition = {
        pageNum: 1,
        pageSize: 10,
        roleName: "",
      };
      this.$refs.table.refresh();
    },
    //确认是否禁用或者启用弹框
    clickStatus(item) {
      this.showAlert = true;
      let status = 0;
      if (item.isDelete == 0) {
        status = 1;
        this.showContent = "Are sure to enble this role?";
      } else {
        this.showContent = "Are sure to disable this role?";
      }
      this.postData = {
        id: item.id,
        status: status,
      };
    },
    cancel() {
      this.showAlert = false;
    },
    cancel1() {
      this.successAlear = false;
    },
    // 停用启用
    enableOrDisable() {
      this.$api.systemManage.disableOrEnableRole(this.postData).then((res) => {
        if (res.success) {
          this.showAlert = false;
          this.successAlear = true;
          this.$refs.table.refresh();
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
  },
};
</script>