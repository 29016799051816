var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _vm.show
            ? _c(
                "b-form",
                { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Commodity Name:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "product-name",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "product-name",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                          placeholder: "Please enter"
                        },
                        model: {
                          value: _vm.form.productName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "productName", $$v)
                          },
                          expression: "form.productName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Commodity Picture:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "productPicture",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("input", {
                        staticClass: "filestyle",
                        staticStyle: {
                          position: "absolute",
                          clip: "rect(0px, 0px, 0px, 0px)"
                        },
                        attrs: {
                          type: "file",
                          "data-input": "false",
                          id: "filestyle-1",
                          tabindex: "-1",
                          required: !_vm.form.productPicture,
                          oninvalid: "setCustomValidity('Please upload');",
                          oninput: "setCustomValidity('')"
                        },
                        on: {
                          change: function($event) {
                            return _vm.upload($event)
                          }
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "bootstrap-filestyle input-group" },
                        [
                          _c("div", {
                            staticStyle: {
                              position: "absolute",
                              width: "100%",
                              height: "35px",
                              "z-index": "-1"
                            },
                            attrs: { name: "filedrag" }
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "group-span-filestyle",
                              attrs: { tabindex: "0" }
                            },
                            [
                              _vm.form.productPicture
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { "margin-bottom": "10px" }
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "100px",
                                          height: "100px",
                                          border: "2px dashed #ccc"
                                        },
                                        attrs: { src: _vm.form.productPicture }
                                      }),
                                      !_vm.editId
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                                "vertical-align": "bottom"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "500"
                                                  }
                                                },
                                                [_vm._v("Picture name : ")]
                                              ),
                                              _vm._v(_vm._s(_vm.photoName))
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "label",
                                {
                                  staticClass: "btn btn-secondary",
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { for: "filestyle-1" }
                                },
                                [
                                  _c("span", { staticClass: "buttonText" }, [
                                    _vm._v(" upload picture ")
                                  ])
                                ]
                              ),
                              _c("span", [
                                _vm._v(
                                  " Recommended size of picture: 980 pixels * 500 pixels, supporting JPG and PNG formats, within 2m. "
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Can be exchanged Time:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "launch-time",
                        "label-class": "requiredRow",
                        description: "Launch time cannot overlap"
                      }
                    },
                    [
                      _c("date-picker", {
                        attrs: {
                          format: "MM/DD/YYYY HH:mm:ss",
                          range: "",
                          "append-to-body": "",
                          lang: "en",
                          placeholder: "Select date",
                          type: "datetime",
                          "disabled-date": _vm.notBeforeToday
                        },
                        on: { change: _vm.onChangeLaunchTime },
                        model: {
                          value: _vm.form.launchTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "launchTime", $$v)
                          },
                          expression: "form.launchTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Commodity Type:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "exchange-type",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "exchange-type",
                          options: _vm.types.exchangeTypeList,
                          "value-field": "value",
                          "text-field": "text",
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('')"
                        },
                        on: { change: _vm.onChangeExchangeType },
                        model: {
                          value: _vm.form.exchangeType,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "exchangeType", $$v)
                          },
                          expression: "form.exchangeType"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.form.exchangeType == 1
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Coupon:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "exchange-type",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "exchange-type",
                              options: _vm.types.couponList,
                              "value-field": "value",
                              "text-field": "text",
                              required: "",
                              oninvalid: "setCustomValidity('Please select');",
                              oninput: "setCustomValidity('')"
                            },
                            model: {
                              value: _vm.form.couponId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "couponId", $$v)
                              },
                              expression: "form.couponId"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Consume Gems:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "integral-number",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "integral-number",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                          placeholder: "Please enter"
                        },
                        model: {
                          value: _vm.form.integral,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "integral", $$v)
                          },
                          expression: "form.integral"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.form.exchangeType != 1
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Quantity:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "convertible-quantity",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "convertible-quantity",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput:
                                "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                              placeholder: "Please enter"
                            },
                            model: {
                              value: _vm.form.convertibleQuantity,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "convertibleQuantity", $$v)
                              },
                              expression: "form.convertibleQuantity"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.exchangeType != 1
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "All limit Per day:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "day-convertible-quantity",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "day-convertible-quantity",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput:
                                "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50);value=value.replace(/[^\\d]/g,'')",
                              placeholder: "Please enter",
                              disabled: _vm.form.dayExchangeType == 1
                            },
                            model: {
                              value: _vm.form.dayConvertibleQuantity,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "dayConvertibleQuantity",
                                  $$v
                                )
                              },
                              expression: "form.dayConvertibleQuantity"
                            }
                          }),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "checkbox-1",
                                name: "checkbox-1",
                                "unchecked-value": "0",
                                value: "1"
                              },
                              on: { change: _vm.onChangeDayExchangeType },
                              model: {
                                value: _vm.form.dayExchangeType,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "dayExchangeType", $$v)
                                },
                                expression: "form.dayExchangeType"
                              }
                            },
                            [_vm._v(" Unlimited ")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.exchangeType != 1
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Exchange Time/Per user:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "available-quantity",
                            "label-class": "requiredRow",
                            description: "Maximum number of coupons per user"
                          }
                        },
                        [
                          _vm.form.availableType == 0
                            ? _c("b-form-select", {
                                attrs: {
                                  id: "limited-availableType",
                                  options: _vm.types.limitedAvailableTypeList,
                                  "value-field": "value",
                                  "text-field": "text",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please select');",
                                  oninput: "setCustomValidity('')"
                                },
                                model: {
                                  value: _vm.form.limitedAvailableType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "limitedAvailableType",
                                      $$v
                                    )
                                  },
                                  expression: "form.limitedAvailableType"
                                }
                              })
                            : _vm._e(),
                          _c("b-form-input", {
                            attrs: {
                              id: "available-quantity",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput:
                                "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50);value=value.replace(/[^\\d]/g,'')",
                              placeholder: "Please enter",
                              disabled: _vm.form.availableType == 1,
                              state: _vm.availableQuantityState,
                              "aria-describedby": "input-available-feedback"
                            },
                            model: {
                              value: _vm.form.availableQuantity,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "availableQuantity", $$v)
                              },
                              expression: "form.availableQuantity"
                            }
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-available-feedback" } },
                            [
                              _vm._v(
                                " The available quantity cannot be greater than the issued quantity. "
                              )
                            ]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "checkbox-2",
                                name: "checkbox-2",
                                "unchecked-value": "0",
                                value: "1"
                              },
                              on: { change: _vm.onChangeAvailableQuantity },
                              model: {
                                value: _vm.form.availableType,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "availableType", $$v)
                                },
                                expression: "form.availableType"
                              }
                            },
                            [_vm._v(" Unlimited ")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Display at Exchange Center:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "enable-status"
                      }
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticStyle: { "margin-top": "5px" },
                          attrs: {
                            id: "enable-status",
                            name: "onSale",
                            value: 1,
                            "unchecked-value": 0,
                            switch: "",
                            size: "lg"
                          },
                          model: {
                            value: _vm.form.status,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.status == 0 ? "No" : "Yes") +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Commodity Details:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "product-details"
                      }
                    },
                    [
                      _c("div", {
                        staticStyle: { "max-width": "1310px" },
                        attrs: { id: "wangeEditor" }
                      })
                    ]
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { "label-cols-lg": "2" } },
                    [
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "primary" } },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "b-button",
                        { staticClass: "m-l-5 ml-1", attrs: { type: "reset" } },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }