<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-form @submit="submitFlavor" @reset="onReset" v-if="show">
      <b-card>
        <b-row>
          <b-col cols="8">
            <!-- Flavor Name 口味名称-->
            <FormRow>
              <b-form-group
                label="Flavor Name:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="flavor-name"
                label-class="requiredRow"
              >
                <div class="vo-column">
                  <b-form-input
                    v-model="form.modelName"
                    required
                    oninvalid="setCustomValidity('Please enter');"
                    oninput="setCustomValidity('');if(value.length > 128)value = value.slice(0, 128)"
                    placeholder="Please enter"
                  ></b-form-input>
                </div>
              </b-form-group>
              <template #right>
                <CountText
                  margin=""
                  :len="form.modelName ? form.modelName.length : 0"
                  max="100"
                />
              </template>
            </FormRow>

            <IngredirentEditor
              @change="onIngredientChange"
              :ingredientOptions="types.ingredientsList"
              :terpenesOptions="types.terpenesList"
              :ingredientValue="form.ingredientValue"
              :terpenesValue="form.terpenesValue"
            />
          </b-col>
          <b-col ref="container">
            <FlavorPieChart
              v-if="pieChartWidth !== 0"
              :legend="legend"
              :series="series"
              height="200px"
              :width="pieChartWidth"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="8">
            <FormRow>
              <b-form-group
                label="Usage Effects Tags:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="usage-effects-tags"
                label-class="requiredRow"
              >
                <div class="d-flex flex-wrap">
                  <b-form-checkbox
                    v-for="(tag, index) in types.productGenreList"
                    :key="index"
                    v-model="form.commodityLabelId"
                    :value="tag.id"
                    class="mr-2 mt-2"
                    >{{ tag.labelName }}
                  </b-form-checkbox>
                </div>
              </b-form-group>
            </FormRow>
          </b-col>

          <b-col lg="8">
            <FormRow>
              <!-- 品牌logo -->
              <b-form-group
                label="Flavor Picture:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="brand-logo"
                label-class="requiredRow"
              >
                <input
                  type="file"
                  class="filestyle"
                  data-input="false"
                  id="filestyle-1"
                  style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
                  tabindex="-1"
                  @change="upload($event)"
                  :required="!form.flavorPicture"
                  oninvalid="setCustomValidity('Please upload');"
                  accept="image/*"
                  oninput="setCustomValidity('');"
                />

                <div class="bootstrap-filestyle input-group">
                  <span class="group-span-filestyle" tabindex="0">
                    <div v-if="form.flavorPicture">
                      <div class="upload-img-wrap">
                        <img :src="form.flavorPicture" class="upload-img" />
                        <img
                          @click="onRemoveBrandLogo"
                          :src="require('@/assets/images/brand/ic_close.png')"
                          class="close-img"
                        />
                      </div>
                    </div>
                    <b-button
                      variant="primary"
                      class="custom-width"
                      @click="uploadFile()"
                      >upload picture</b-button
                    >
                    <span>
                      Recommended size of picture: 512 pixels * 512 pixels,
                      supporting JPG and PNG formats.
                    </span>
                  </span>
                </div>
              </b-form-group>
            </FormRow>
          </b-col>
        </b-row>
      </b-card>
      <b-col lg="6">
        <FormRow>
          <b-col offset="4">
            <div class="vo-row align-items-center justify-content-between">
              <b-button
                class="custom-width"
                variant="primary"
                type="submit"
                @click="addFlavor(1)"
                >Save
              </b-button>
              <b-button
                variant="primary"
                class="operate-btn"
                type="submit"
                @click="addFlavor(2)"
                >Save&Add Other Flavor
              </b-button>
              <b-button class="custom-width" type="reset">Cancel</b-button>
            </div>
          </b-col>
        </FormRow>
      </b-col>
    </b-form>
    <UploadFile
      :showUploadModal="showUploadModal"
      :oldFileList="editUploadList"
      :path="uploadPath"
      :fileType="fileType"
      :maxNumber="maxNumber"
      @FinishEvent="finishEvent"
      @CancelEvent="cancelEvent"
    ></UploadFile>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel"
      @hide="hide"
    >
      Saved successfully
    </Dialog>
  </Layout>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import { onReady, upload_img } from "@/api/upload";
import Video from "../../../../../组件/Admin/src/router/views/ui/video.vue";
import UploadFile from "@/components/modal/upload-file.vue";
import { mapGetters } from "vuex";
import FormRow from "@/components/FormRow.vue";
import CountText from "@/components/CountText.vue";
import FlavorPieChart from "@/components/flavor/FlavorPieChart.vue";
import IngredirentEditor from "@/components/IngredirentEditor.vue";
import { applyAll } from "@fullcalendar/core";
import Dialog from "@/components/Dialog";

/**
 * Cartridge Model Add
 */
export default {
  components: {
    IngredirentEditor,
    FlavorPieChart,
    CountText,
    FormRow,
    ckeditor: CKEditor.component,
    Layout,
    PageHeader,
    Multiselect,
    Video,
    UploadFile,
    Dialog,
  },
  data() {
    return {
      title: this.$route.query.id ? "Modify Flavor" : "Create Flavor",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "My Brands",
          // href: "/"
        },
        {
          text: this.$route.query.id ? "Modify Flavor" : "Create Flavor",
          active: true,
        },
      ],
      maxFive: 5,
      maxThree: 3,
      form: {
        supplierId: localStorage.supplierId,
        brandId:  this.$route.query.brandId, //品牌编号
        deviceType: this.$route.query.brandSeriesId, //设备类型
        modelName: "", //口味名称
        ingredientValue: [{ proportion: "", id: null, text: "" }], //成分
        terpenesValue: [
          { text: "Other", id: -1000, proportion: "100", disabled: true },
        ], //萜烯
        commodityLabelId: "", //商品标签
        flavorPicture: "", //口味图片
      },
      reportType: "",
      photoName: "",
      types: {
        selectAllTestSmokeBombModel: [],
        brandNames: [],
        flavorOptions: [],
        cartridgeComponentData: [{ text: "Please select", value: "" }],
        SolventEncoding: [{ text: "Please select", value: 0 }],
        TypeEncoding: [{ text: "Please select", value: "" }],
        statuss: [
          { text: "true", value: 1, disabled: false },
          { text: "false", value: 0, disabled: false },
        ],
        deviceList: [{ modelName: "Please select", id: "" }], //适用设备列表
        productGenreList: [], //商品标签列表
        usageEffectsList: [], //使用感受标签列表
        flavorProfilesList: [], //口感标签列表
        IntensityList: [], //强度标签列表
        ingredientsList: [
          {
            text: "--Choose Ingredients Tag--",
            id: null,
            disabled: true,
          },
        ], //成分
        terpenesList: [
          {
            text: "--Choose Terpenes Tag--",
            id: null,
            disabled: true,
          },
        ], //萜烯
      },
      selectProductGenre: [],
      selectUsageEffects: [],
      selectFlavorProfiles: [],
      show: true, // 表单显示
      editor: ClassicEditor,
      showReportLink: false,
      showReportEnclosure: false,
      flavor: [],
      flavorLabel: "",
      showUploadModal: false,
      editUploadList: [],
      uploadPath: "",
      fileType: /image|video|mp4/,
      maxNumber: 5,
      confirmDisabled: false,
      successAlear: false,
      alertType: 1,

      /**
       * 图表数据
       */
      legend: {
        orient: "horizontal",
        top: "bottom",
        left: "center",
        bottom: "0", // 图例顶部垂直居中
        icon: "circle",
      },
      series: {
        radius: ["55%", "65%"],
        center: ["50%", "50%"], // 将饼图移到最右边
        data: [
          {
            id: -1000,
            name: "Other",
            value: 100,
          },
        ],
      },
      pieChartWidth: 0,
      pieChartHeight: 0,
    };
  },
  created() {},
  async mounted() {
    if (this.$route.query.id) {
      await this.show1();
    }
    this.init();
  },
  methods: {
    init() {
      //查询成分和萜烯
      this.selectIngredients();
      //查询商品标签
      this.getCommodityLabel();
      // this.$api.Dropdown.selectAllTestSmokeBombModelByType({ type: 3 }).then(
      //   (res) => {
      //     if (res.success && res.data) {
      //       this.types.selectAllTestSmokeBombModel =
      //         this.types.selectAllTestSmokeBombModel.concat(res.data);
      //     } else {
      //       if (res.message) {
      //         this.$bvToast.toast(res.message);
      //       }
      //     }
      //   }
      // );

      // this.$api.Dropdown.brandList().then((res) => {
      //   if (res.success) {
      //     for (let i = 0; i < res.data.length; i++) {
      //       this.types.brandNames.push({
      //         text: res.data[i].brandName,
      //         value: res.data[i].id,
      //       });
      //     }
      //   } else {
      //     if (res.message) {
      //       this.$toast(res);
      //     }
      //   }
      // });

      // this.$api.Dropdown.selectTypeByOil().then((res) => {
      //   if (res.success) {
      //     for (let i = 0; i < res.data.length; i++) {
      //       this.types.TypeEncoding.push({
      //         text: res.data[i].typeName,
      //         value: res.data[i].id,
      //       });
      //     }
      //   } else {
      //     if (res.message) {
      //       this.$toast(res);
      //     }
      //   }
      // });

      //加载商品标签列表
      // this.$api.Dropdown.selectAllCommodityLabel().then((res) => {
      //   if (res.success && res.data) {
      //     that.types.productGenreList = res.data;
      //     console.log(that.form.commodityLabelId);
      //     if (this.form.commodityLabelId) {
      //       that.types.productGenreList.forEach((element) => {
      //         that.form.commodityLabelId.forEach((item) => {
      //           if (element.id == item) {
      //             that.selectProductGenre.push(element);
      //           }
      //         });
      //       });
      //     }
      //   } else {
      //     if (res.message) {
      //       this.$toast(res.message);
      //     }
      //   }
      // });

      //加载使用感受标签列表
      // this.$api.Dropdown.selectAllExperienceFeelingsLabel().then((res) => {
      //   if (res.success && res.data) {
      //     that.types.usageEffectsList = res.data;
      //     that.types.usageEffectsList.forEach((element) => {
      //       if (that.form.experienceFeelingsLabelId) {
      //         that.form.experienceFeelingsLabelId.forEach((item) => {
      //           if (element.id == item) {
      //             that.selectUsageEffects.push(element);
      //           }
      //         });
      //       }
      //     });
      //   } else {
      //     if (res.message) {
      //       this.$toast(res.message);
      //     }
      //   }
      // });

      //加载口感标签列表
      // this.$api.Dropdown.selectAllTasteLabel().then((res) => {
      //   if (res.success && res.data) {
      //     this.types.flavorProfilesList = res.data;
      //     that.types.flavorProfilesList.forEach((element) => {
      //       if (that.form.tasteLabelId) {
      //         that.form.tasteLabelId.forEach((item) => {
      //           if (element.id == item) {
      //             that.selectFlavorProfiles.push(element);
      //           }
      //         });
      //       }
      //     });
      //   } else {
      //     if (res.message) {
      //       this.$toast(res.message);
      //     }
      //   }
      // });

      //加载强度标签列表
      // this.$api.Dropdown.selectAllStrength().then((res) => {
      //   if (res.success && res.data) {
      //     for (let i = 0; i < res.data.length; i++) {
      //       this.types.IntensityList.push({
      //         text: res.data[i].labelName,
      //         value: res.data[i].id,
      //       });
      //     }
      //   } else {
      //     if (res.message) {
      //       this.$toast(res.message);
      //     }
      //   }
      // });

      //加载适用设备列表,过滤掉OMNI设备
      // this.$api.Dropdown.selectAllDeviceModel().then((res) => {
      //   if (res.success && res.data) {
      //     for (let i = 0; i < res.data.length; i++) {
      //       if (res.data[i].id != 11) {
      //         this.types.deviceList.push(res.data[i]);
      //       }
      //     }
      //   } else {
      //     if (res.message) {
      //       this.$toast(res);
      //     }
      //   }
      // });

      this.$nextTick(() => {
        const pieChartWidth = `${this.$refs.container.clientWidth}px`;
        this.pieChartWidth = pieChartWidth;
        this.pieChartHeight = `${this.$refs.container.clientHeight}px`;
      });
    },

    //查询成分和萜烯
    selectIngredients() {
      this.$api.CartridgeModel.selectIngredients()
        .then((x) => {
          this.types.ingredientsList = this.types.ingredientsList.concat(
            x.data.ingredientsList.map((item, i) => {
              return {
                text: item.component,
                id: item.id,
                disabled: false,
              };
            })
          );
          this.types.terpenesList = this.types.terpenesList.concat(
            x.data.terpenesList.map((item, i) => {
              return {
                text: item.terpenesName,
                id: item.id,
                disabled: false,
              };
            })
          );
        })
        .catch((e) => {
          alert(e);
          this.has_data = false;
        });
    },
    //查询商品标签
    getCommodityLabel() {
      this.$api.specifications
        .getCommodityLabel()
        .then((res) => {
          if (res.success && res.data) {
            this.types.productGenreList = res.data;
            if (this.form.commodityLabelId) {
              this.types.productGenreList.forEach((element) => {
                this.form.commodityLabelId.forEach((item) => {
                  if (element.id == item) {
                    this.selectProductGenre.push(element);
                  }
                });
              });
            }
          } else {
            if (res.message) {
              this.$toast(res.message);
            }
          }
        })
        .catch((e) => {
          this.has_data = false;
        });
    },

    async show1() {
      if (this.$route.query.id) {
        await this.$api.CartridgeModel.selectFlavorById(this.$route.query).then(
          (res) => {
            if (res.success) {
              
              var ingredientsList = res.data.ingredientsVoList.map((item) => {
                return {
                  id: item.id,
                  text: item.name,
                  proportion: parseFloat(item.proportion),
                };
              });
              var terpenesList = res.data.terpenesList.map((item) => {
                return {
                  id: item.id,
                  text: item.name,
                  proportion: parseFloat(item.proportion),
                };
              });
              this.form = {
                id: this.$route.query.id,
                supplierId: localStorage.supplierId,
                modelName: res.data.flavorName, //口味名称
                ingredientsList: [], //成分
                terpenesList: [], //萜烯
                ingredientValue: ingredientsList,
                terpenesValue: terpenesList,
                commodityLabelId: res.data.labelId, //商品标签
                flavorPicture: res.data.flavorPicture, //口味图片
              };
              this.onIngredientChange(ingredientsList, terpenesList);
              if (res.data.remarks == null) {
                this.form.remarks = "";
              }
              if (res.data.flavorPicture) {
                let arr = res.data.flavorPicture.split("/");
                this.photoName = arr[arr.length - 1];
              }
              if (res.data.commodityCarouselMap == null)
                this.form.commodityCarouselMap = [];
              if (res.data.commodityDetailsMap == null)
                this.form.commodityDetailsMap = [];
              this.form.deleteFileUrl = [];
            } else {
              if (res.message) {
                this.$toast(res);
              }
            }
          }
        );
      } else {
        this.$bvToast.toast("id?");
      }
    },
    onReady: onReady,
    submitFlavor(evt) {
      evt.preventDefault();
      if (
        this.form.remarks &&
        (this.form.remarks.length > 250 ||
          this.form.commodityCreationStory.length > 1000)
      ) {
        this.$bvToast.toast("Do not exceed max length.");
        return;
      }

      if (
        !this.$route.query.id &&
        this.$store.getters.isIkrusher &&
        (this.form.deviceType == "" || this.form.deviceType == null)
      ) {
        this.$bvToast.toast("Please select Devices Type.");
        return;
      }
      if (!this.form.commodityLabelId) {
        this.$bvToast.toast("Please select Usage Effects Tags.");
        return;
      }
      // if (this.selectProductGenre.length > 0) {
      //   this.form.commodityLabelId = "";
      //   for (let i = 0; i < this.selectProductGenre.length; i++) {
      //     this.form.commodityLabelId += this.selectProductGenre[i].id + ",";
      //   }
      //   this.form.commodityLabelId = this.form.commodityLabelId.substring(
      //     0,
      //     this.form.commodityLabelId.length - 1
      //   );
      // } else {
      //   this.$bvToast.toast("Please select Product Genre Tags.");
      //   return;
      // }
      // if (this.selectUsageEffects.length > 0) {
      //   this.form.experienceFeelingsLabelId = "";
      //   for (let i = 0; i < this.selectUsageEffects.length; i++) {
      //     this.form.experienceFeelingsLabelId +=
      //       this.selectUsageEffects[i].id + ",";
      //   }
      //   this.form.experienceFeelingsLabelId =
      //     this.form.experienceFeelingsLabelId.substring(
      //       0,
      //       this.form.experienceFeelingsLabelId.length - 1
      //     );
      // } else {
      //   this.$bvToast.toast("Please select Usage Effects.");
      //   return;
      // }
      // if (this.selectFlavorProfiles.length > 0) {
      //   this.form.tasteLabelId = "";
      //   for (let i = 0; i < this.selectFlavorProfiles.length; i++) {
      //     this.form.tasteLabelId += this.selectFlavorProfiles[i].id + ",";
      //   }
      //   this.form.tasteLabelId = this.form.tasteLabelId.substring(
      //     0,
      //     this.form.tasteLabelId.length - 1
      //   );
      // } else {
      //   this.$bvToast.toast("Please select Flavor Profiles.");
      //   return;
      // }
      // if (this.form.strengthId == "" || this.form.strengthId == null) {
      //   this.$bvToast.toast("Please select Intensity.");
      //   return;
      // }

      if (!this.$route.query.id && !this.form.brandId) {
        this.$bvToast.toast("Please select Brand.");
        return;
      }
      // if (this.form.commodityCarouselMap.length <= 0) {
      //   this.$bvToast.toast("Please upload Carousel.");
      //   return;
      // }
      // if (
      //   this.$store.getters.isUnicoreus &&
      //   this.form.commodityDetailsMap.length <= 0
      // ) {
      //   this.$bvToast.toast("Please upload Details.");
      //   return;
      // }
      this.$api.CartridgeModel.add(this.form)
        .then((res) => {
          if (res.success) {
            this.successAlear = true;
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        })
        .catch((error) => {})
        .finally(() => {});
    },

    addFlavor(type) {
      this.alertType = type;
    },
    //保存并添加其他口味
    onReset(evt) {
      evt.preventDefault();
      (this.form = {
        supplierId: localStorage.supplierId,

        modelName: "", //口味名称
        type: "", //烟弹类型
        deviceModelId: "", //使用设备
        customTemperature: 1, //是否可自定义温度 0是 1否
        suggestRetailPrice: "", //建议零售价
        retailPrice: "", //实际零售价
        commodityLabelId: "", //商品标签编号
        experienceFeelingsLabelId: "", //使用感受标签编号
        tasteLabelId: "", //口感标签编号
        strengthId: "", //强度标签编号
        brand: "", //所属品牌
        flavorPicture: "", //烟油口味图片
        commodityCarouselMap: [], //商品轮播图
        commodityCreationStory: "", //创作故事
        commodityDetailsMap: [], //商品详情图
        onSale: 0, //是否上架 0是 1否
        cartUrl: "", //购物车URL
        flavorSort: "", //排序
        remarks: "", //备注
        consistence: 0, //烟油浓稠度
        medicalCare: "", //是否医用
        daySeconds: "", //吸入上限设置
        deleteFileUrl: [], //删除照片集合 服务端处理
      }),
        (this.photoName = "");
      this.show = false;
      this.$nextTick(() => {
        // this.show = true;
        this.$router.go(-1);
      });
    },
    selectGenre(val) {
      this.form.commodityLabelId = val.map((item) => {
        return item;
      });
    },
    selectEffects(val) {
      this.form.experienceFeelingsLabelId = val.map((item) => {
        return item;
      });
    },
    selectProfiles(val) {
      this.form.tasteLabelId = val.map((item) => {
        return item;
      });
    },
    changeType(type) {
      // this.types.flavorOptions = [];
      if (type) {
        if (type == 1) {
          // this.flavorLabel = "Flavor:";
          this.form.medicalCare = 1;
          this.form.daySeconds = "";
        } else if (type == 4) {
          // this.flavorLabel = "Graphic Name:";
          this.form.medicalCare = 1;
          this.form.consistence = "";
          this.form.daySeconds = "";
        } else if (type == 5) {
          // this.flavorLabel = "Flavor:";
          this.form.medicalCare = 0;
        } else {
          // this.flavorLabel = "Flavor:";
          this.form.medicalCare = 1;
          this.form.consistence = "";
          this.form.daySeconds = "";
        }
      }
    },
    //上传烟油口味图片
    upload(e) {
      let file = e.target.files[0];
      if (file == undefined) return;
      this.photoName = file.name;
      if (/image/.test(file.type)) {
        //增加上传中loading...提示
        upload_img(file).then((res) => {
          // if (res.message) {
          //   this.$toast(res);
          // }
          if (res.success) {
            this.form.flavorPicture = res.data;
          }
        });
      } else {
        this.$bvToast.toast("only support jpg/png file");
      }
    },

    uploadFile() {
      const filestyle = document.getElementById("filestyle-1");
      filestyle.click();
    },
    onRemoveBrandLogo() {
      this.form.flavorPicture = "";
    },
    uploadCarouse(e) {
      let file = e.target.files[0];
      if (file == undefined) return;
      if (this.form.commodityCarouselMap.length >= 10) {
        this.$bvToast.toast("Upload up to five pictures.");
        return;
      }
      if (/image|video|mp4/.test(file.type)) {
        upload_img(file).then((res) => {
          if (res.message) {
            this.$toast(res);
          }
          if (res.success) {
            this.form.commodityCarouselMap.push(res.data);
          }
        });
      } else {
        e.target.files = null;
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    uploadDetails(e) {
      let file = e.target.files[0];
      if (file == undefined) return;
      if (this.form.commodityDetailsMap.length >= 10) {
        this.$bvToast.toast("Upload up to five pictures.");
        return;
      }
      if (/image/.test(file.type)) {
        upload_img(file).then((res) => {
          if (res.message) {
            this.$toast(res);
          }
          if (res.success) {
            this.form.commodityDetailsMap.push(res.data);
          }
        });
      } else {
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    //商品口味图片
    onDeleteFlavorPicture() {
      let that = this;
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Operation tips",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            that.form.deleteFileUrl.push(that.form.flavorPicture);
            that.form.flavorPicture = "";
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    //删除商品轮播图
    onDeleteCarouselMap(item, index) {
      let that = this;
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Operation tips",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            that.form.deleteFileUrl.push(item);
            that.form.commodityCarouselMap.splice(index, 1);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    //删除商品详情图
    onDeleteDetailsMap(item, index) {
      let that = this;
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Operation tips",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            that.form.deleteFileUrl.push(item);
            that.form.commodityDetailsMap.splice(index, 1);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    //#region 图片上传组件函数
    onClickUploadCarousel() {
      this.editUploadList = this.form.commodityCarouselMap;
      this.showUploadModal = true;
      this.uploadPath = "Carousel";
      this.maxNumber = 10;
    },
    onClickUploadDetails() {
      this.editUploadList = this.form.commodityDetailsMap;
      this.fileType = /image/;
      this.showUploadModal = true;
      this.uploadPath = "Details";
      this.maxNumber = 5;
    },
    finishEvent(fileList, deleteFileList, type) {
      if (type == "Carousel") {
        this.form.commodityCarouselMap = fileList;
      } else if (type == "Details") {
        this.form.commodityDetailsMap = fileList;
      }
      deleteFileList.forEach((element) => {
        this.form.deleteFileUrl.push(element);
      });

      this.fileType = /image|video|mp4/;
      this.maxNumber = 5;
      this.uploadPath = "";
      this.editUploadList = [];
      this.showUploadModal = false;
    },
    cancelEvent() {
      this.fileType = /image|video|mp4/;
      this.maxNumber = 5;
      this.uploadPath = "";
      this.editUploadList = [];
      this.showUploadModal = false;
    },
    //#endregion
    onIngredientChange(ingredients, terpenes) {
      this.form.ingredientsList = ingredients.map((item) => {
        return {
          id: item.id,
          name: item.text,
          proportion: parseFloat(item.proportion),
        };
      });
      this.form.terpenesList = terpenes.map((item) => {
        return {
          id: item.id,
          name: item.text,
          proportion: parseFloat(item.proportion),
        };
      });
      const data = []
        .concat(ingredients)
        .concat(terpenes)
        .filter((item) => item.proportion > 0)
        .map((item) => {
          return { value: item.proportion || 0, name: item.text, id: item.id };
        })
        .sort((prev, current) => {
          return current.value - prev.value >= 0 ?  0:-1;
        });
        console.log(data)
      this.series.data = data;
    },
    cancel() {
      if (this.alertType == 1) {
        this.$router.go(-1);
        // this.$router.push("flavorMgmt");
      } else {
        this.cancelFunction();
      }
    },

    hide() {
      if (this.alertType == 1) {
        this.$router.go(-1);
        // this.$router.push("flavorMgmt");
      } else {
        this.cancelFunction();
      }
    },

    //点击添加其他口味时触发方法
    cancelFunction() {
      this.successAlear = false;
      this.form = {
        supplierId: localStorage.supplierId,
        brandId: this.$route.query.brandId, //品牌编号
        deviceType: this.$route.query.brandSeriesId, //设备类型
        modelName: "", //口味名称
        ingredientValue: [{ proportion: "", id: null, text: "" }], //成分
        terpenesValue: [
          { text: "Other", id: -1000, proportion: "100", disabled: true },
        ], //萜烯
        commodityLabelId: "", //商品标签
        flavorPicture: "", //口味图片
      };
      this.series.data = [
        {
          id: -1000,
          name: "Other",
          value: 100,
        },
      ];
    },
  },
  computed: {
    ...mapGetters(["isIkrusher", "isUnicoreus"]),
  },
};
</script>

<style scoped lang="scss">
::v-deep .form-group {
  margin: 0;
}

.col-lg-8 {
  & > * {
    margin-top: 1rem;
  }
}

.operate-btn {
  width: 200px;
}

.upload-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.close-img {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  padding: 4px;
  background: #02a7f0;
  border-radius: 50%;
  object-fit: contain;
}

.upload-img-wrap {
  margin-bottom: 1em;
  width: 200px;
  height: 200px;
  position: relative;
  border: 2px dashed #02a7f0;
}
</style>