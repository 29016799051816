<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row class="vo-items-center">
        <b-col lg="4">
          <div class="vo-row vo-items-center">
            <ReferenceBy label="REFINED BY" />
            <b-input
              style="flex: 1; margin-left: 10px"
              v-model="search_condition.orderName"
              placeholder="Search Production Batch# or Name"
            />
          </div>
        </b-col>
        <b-col>
          <b-input
            v-model="search_condition.product"
            placeholder="Search Product Model# or Marketing Name"
          />
        </b-col>

        <b-col>
          <b-form-select
            id="oil-type"
            v-model="search_condition.status"
            :options="types.status"
          ></b-form-select>
        </b-col>

        <b-col>
          <div class="vo-row vo-items-center vo-justify-between">
            <div class="custom-color cursor" @click="clearAll">Clear All</div>
            <b-button variant="info" @click="search">Search</b-button>
          </div>
        </b-col>
      </b-row>

      <!-- table -->
      <div class="box-list">
        <b-button
          v-has="'productionBatchMgmt::Create'"
          @click="gotoAdd"
          variant="primary"
          >+Create Batch</b-button
        >
        <div class="table-responsive mb-0">
          <b-table
            id="device-table"
            head-variant="light"
            show-empty
            empty-text="no records"
            ref="table"
            :busy="isBusy"
            :striped="true"
            :items="provider"
            :fields="fields"
            :per-page="search_condition.pageSize"
            :current-page="search_condition.pageNum"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-includedfields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template v-slot:cell(index)="data">
              {{
                data.index +
                (search_condition.pageNum - 1) * search_condition.pageSize +
                1
              }}
            </template>
            <template v-slot:cell(actions)="row">
              <b-button-group>
                <b-button
                  size="sm"
                  variant="secondary"
                  @click="show(row.item, row.index, $event.target)"
                  class="mr-1"
                >
                  View
                </b-button>
                <b-button
                  v-has="'productionBatchMgmt::Modify'"
                  size="sm"
                  variant="primary"
                  @click="modify(row.item, row.index, $event.target)"
                  class="mr-1"
                >
                  Modify
                </b-button>
                <b-button
                  v-if="
                    btnMenu('productionBatchMgmt::OmniIDList') ||
                    (!isAccountLevel() && btnMenu('brandMgmt::Create'))
                  "
                  size="sm"
                  variant="primary"
                  @click="omniIdList(row.item, row.index, $event.target)"
                  class="mr-1"
                >
                  Omni ID List
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </div>
      </div>
      <!-- end table -->
      <!-- pagination -->
      <b-row v-if="has_data">
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="rows != 0">
              <li>
                <p
                  class="pagination mb-0"
                  style="margin-top: 7px; margin-right: 20px"
                >
                  {{ rows }} results
                </p>
              </li>
              <b-pagination
                ref="page"
                v-model="search_condition.pageNum"
                :per-page="10"
                :total-rows="rows"
                aria-controls="table"
              >
              </b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
      <!-- end pagination -->

      <BatchDetail
        v-model="showBatchDetail"
        noBtn="OK"
        @cancel="cancel"
        :batchDetail="batchDetail"
      />
    </b-card>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import ReferenceBy from "@/components/FindByLabel.vue";
import BatchDetail from "@/components/product/BatchDetail.vue";

/**
 * Cartridge Batch component
 */
export default {
  components: {
    DatePicker,
    Layout,
    PageHeader,
    timeZoneConversion,
    ReferenceBy,
    BatchDetail,
  },
  data() {
    return {
      title: "Production Batch",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Production Batch",
          active: true,
        },
      ],
      readySelectItem: 0,
      tableData: [],
      totalRows: 0,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "number", label: "Batch#", class: "text-center" },
        {
          key: "orderName",
          label: "Production Batch Name",
          class: "text-center",
        },
        { key: "modelName", label: "Product Models#", class: "text-center" },
        { key: "deviceName", label: "Marketing Name", class: "text-center" },
        {
          key: "batchQuantity",
          label: "Produced Qty/Batch Qty",
          class: "text-center",
        },
        { key: "operationName", label: "Created By", class: "text-center" },
        {
          key: "createTime",
          label: "Create Time",
          class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.getLocalTime(value);
          },
        },
        { key: "statusName", label: "Status", class: "text-center" },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        orderName: "",
        status: "",
        product: "",
        productId: this.$route.query.productId
          ? this.$route.query.productId
          : "",
      },
      createTime: ["", ""],
      types: {
        status: [
          { text: "--All Status--", value: "", disabled: false },
          {
            text: "To be confirmed",
            value: 0,
            disabled: false,
          },
          { text: "IN Production", value: 1, disabled: false },
          { text: "Done", value: 2, disabled: false },
        ],
        cartridgeType: [{ name: "Please select", id: "" }],
      },
      isBusy: false,
      has_data: false,
      dowmDisable: false,
      showBatchDetail: false,
      batchDetail: {},
    };
  },
  watch: {
    createTime: function (val) {
      this.search_condition.startTime = val[0]
        ? timeZoneConversion.queryTime(val[0])
        : val[0];
      if (val[1]) {
        this.search_condition.endTime = timeZoneConversion.queryTime(
          val[1].getTime() + 24 * 3600 * 1000 - 1
        );
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.$api.Dropdown.selectAllCartridgeType({ status: "1" }).then((res) => {
        if (res.success && res.data) {
          this.types.cartridgeType = this.types.cartridgeType.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    provider(ctx) {
      this.isBusy = true;
      return this.$api.emptyCartridges
        .selectAllOrder(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            x.data.map((item, i) => {
              item.batchQuantity =
                item.normalQuantity + "/" + item.batchQuantity;
              return item;
            });
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    clearAll() {
      this.search_condition = {
        pageNum: 1,
        pageSize: 10,
        orderName: "",
        status: "",
        product: "",
        productId: this.$route.query.productId
          ? this.$route.query.productId
          : "",
      };
      this.$refs.table.refresh();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.search_condition.pageNum = 1;
    },
    gotoAdd() {
      this.$router.push("productionBatchMgmtAdd");
    },
    show(item, index, button) {
      this.$api.emptyCartridges
        .selectByOrderId({ id: item.id })
        .then((res) => {
          if (res.success) {
            this.batchDetail = res.data;
            this.showBatchDetail = true;
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        })
        .catch((error) => {});
    },
    cancel() {
      this.showBatchDetail = false;
    },
    modify(item, index, button) {
      this.$router.push("productionBatchMgmtAdd?id=" + item.id);
    },
    omniIdList(item, index, button) {
      if(this.isAccountLevel()){
        this.$router.push("productionBatchMgmtOmniIDList?id=" + item.orderId);
      }else{
        this.$router.push("brandProductionBatchMgmtOmniIDList?id=" + item.orderId);
      }
    },
    del(a, b, c) {
      this.$api.CartridgeBatch.del({ id: a.id }).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$refs.table.refresh();
        }
      });
    },
    selectItem(item, index) {
      this.readySelectItem = index;
    },
    modalOk(bvModalEvt) {
      // Prevent modal from closing
      if (this.readySelectItem == 0) {
        this.$router.push({
          name: "emptyCartridgeOrdersAdd",
          params: { selectItem: 1 },
        });
      } else if (this.readySelectItem == 1) {
        this.$router.push({
          name: "emptyCartridgeOrdersAdd",
          params: { selectItem: 2 },
        });
      } else if (this.readySelectItem == 2) {
        this.$router.push({
          name: "emptyCartridgeOrdersAdd",
          params: { selectItem: 3 },
        });
      } else if (this.readySelectItem == 3) {
        this.$router.push({
          name: "emptyCartridgeOrdersAdd",
          params: { selectItem: 4 },
        });
      } else {
        this.$bvToast.toast("Please Select!");
      }
    },
    //#region 关闭订单
    onClickClose(item, index, button) {
      this.$bvModal
        .msgBoxConfirm("Are you sure to close the order?", {
          title: "Operation Tips",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$api.emptyCartridges
              .cancelEmptyCartridgeOrderById({ id: item.id })
              .then((res) => {
                if (res.success) {
                  this.$refs.table.refresh();
                }
                if (res.message) {
                  this.$bvToast.toast(res.message);
                }
              })
              .catch((res) => {
                this.$bvToast.toast(res.message);
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    dowmQrCode(item, index, button) {
      this.dowmDisable = true;
      let data = {
        id: item.id,
      };
      this.$bvToast.toast("Creating...");
      this.$api.Dropdown.exportCartridgeQrCode(data);
      setTimeout(() => {
        this.dowmDisable = false;
      }, 6000);
    },
    //按钮权限判断
    btnMenu(value) {
      return this.$_has(value);
    },
    //账户层级
    isAccountLevel() {
      return localStorage.accountLevel == 0;
    },
    //#endregion
  },
};
</script>