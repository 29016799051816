<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Cartridge Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Cartridge Type:</label>
            <span class="p-3">{{ form.deviceNumber }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Cartridge Id:</label>
            <span class="p-3">{{ form.deviceModel }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Heating Wire Material:</label>
            <span class="p-3">
              {{
                form.bindStatus == null
                  ? ""
                  : form.bindStatus == 0
                  ? "Unbound"
                  : "Bound"
              }}
            </span>
          </b-col>

          <b-col>
            <label class="col-form-label">Heating Wire Size:</label>
            <span class="p-3">{{ form.email }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Heating Wire Porosity:</label>
            <span class="p-3">{{ form.repaymentPeriod }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Oil Hole:</label>
            <span class="p-3">{{ form.validPeriod }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Resistance:</label>
            <span class="p-3">{{ form.activationTime }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Package Cotton:</label>
            <span class="p-3">{{ form.versionNo }}</span>
          </b-col>
        </b-row>
      </b-container>
      <br />
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Used Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Preheating Time:</label>
            <span class="p-3">{{
              form.renewalType == null
                ? ""
                : form.renewalType == 0
                ? "Automatic"
                : "Manual"
            }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Preheating Temperature:</label>
            <span class="p-3">{{ form.graceDays }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Single Hot Temperature:</label>
            <span class="p-3">{{ form.graceDays }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Temperature Curve:</label>
            <span class="p-3">{{ form.graceDays }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Time Spent:</label>
            <span class="p-3">{{ form.graceDays }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Last Used Date:</label>
            <span class="p-3">{{ form.graceDays }}</span>
          </b-col>
        </b-row>
      </b-container>
      <br />
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Device Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Brand:</label>
            <span class="p-3">{{ form.graceDays }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">MAC:</label>
            <span class="p-3">{{ form.graceDays }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-button variant="outline-secondary" @click="returnPage">Return</b-button>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Developer Test Record Details",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Manage",
          // href: "/"
        },
        {
          text: "Developer Test Record Details",
          active: true,
        },
      ],
      fields: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "operationContent",
          label: "Operating Content",
          class: "text-center",
        },
        { key: "nickName", label: "Operation Of", class: "text-center" },
        { key: "createTime", label: "Operating Time", class: "text-center" },
        { key: "remarks", label: "Notes", class: "text-center" },
      ],
      form: {},
    };
  },
  computed: {},

  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.$api.Device.selectByDeviceId(this.$route.query).then((res) => {
          if (res.success && res.data) {
            this.form = res.data;
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    returnPage() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
.blockquote {
  padding: 10px 10px;
  border-left: 5px solid #aaaaff;
}
</style>
