/**
 * 主题 模块接口
 */
// import base from "./base.js";
import axios from "../config/http.js"
import baseUrl from "../config/env.js"

const subjectManage = {
	//查询弹出页详情
	selectSubjectList(params) {
		return axios.fetchGet(baseUrl + '/brandSubject/selectSubjectList',params);
	},
	//保存主题
	saveSubject(params){
		return axios.fetchPost(baseUrl + '/brandSubject/save',params);
	},
	//删除主题
	 deleteSubject(params){
		return axios.fetchPost(baseUrl + '/brandSubject/delete', params);
	},
	//禁用或者启用主题
	disableOrEnableSubject(params){
		return axios.fetchPost(baseUrl + '/brandSubject/disableOrEnable', params);
	}
}

export default subjectManage;
