<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="3">
              <b-form-group
                label="Tags Name"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="tags-name"
              >
                <b-form-input
                  id="tags-name"
                  trim
                  v-model="search_condition.labelName"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <b-button
            v-has="'productGenreTags::Create'"
            @click="gotoAdd"
            variant="primary"
            >Create</b-button
          >
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="No records"
              id="device-table"
              head-variant="light"
              ref="table"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">{{
                data.index +
                (search_condition.pageNum - 1) * search_condition.pageSize +
                1
              }}</template>

              <template v-slot:cell(flavorPicture)="data">
                <img
                  v-if="data.item.flavorPicture"
                  :src="`${data.item.flavorPicture}`"
                  alt
                  class="avatar-xs rounded-circle mr-2"
                />
              </template>
              <template v-slot:cell(labelColor)="data">
                <div
                  style="
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div
                    style="width: 40px; height: 20px; margin-right: 10px"
                    :style="'backgroundColor:' + data.item.labelColor"
                  ></div>
                  <span>{{ data.item.labelColor }}</span>
                </div>
              </template>

              <template v-slot:cell(actions)="row">
                <b-button-group>
                  <b-button
                    v-if="row.item.supplierId != 0"
                    v-has="'productGenreTags::Modify'"
                    size="sm"
                    variant="primary"
                    @click="edit(row.item, row.index, $event.target)"
                    class="mr-1"
                    >Modify</b-button
                  >
                  <b-button
                    v-if="row.item.supplierId != 0"
                    v-show="row.item.status == 1"
                    v-has="'productGenreTags::Disable'"
                    size="sm"
                    variant="secondary"
                    @click="onClickDisabled(row.item, row.index, $event.target)"
                    class="mr-1"
                    >Disable</b-button
                  >
                  <b-button
                    v-if="row.item.supplierId != 0"
                    v-show="row.item.status == 0"
                    v-has="'productGenreTags::Disable'"
                    size="sm"
                    variant="success"
                    @click="onClickEnabled(row.item, row.index, $event.target)"
                    class="mr-1"
                    >Enable</b-button
                  >
                </b-button-group>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    ref="page"
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    timeZoneConversion
  },
  data() {
    return {
      title: "Product Genre Tags",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "Settings",
          // href: "/"
        },
        {
          text: "Product Genre Tags",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#" },
        { key: "labelName", label: "Tags Name", class: "text-center" },
        { key: "labelColor", label: "Tags Style", class: "text-center" },
        { key: "remarks", label: "Notes", class: "text-center" },
        {
          key: "createTime",
          label: "Create Time",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return timeZoneConversion.getLocalTime(value);
            }
          },
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        labelName: "", //标签名称
        supplierId: localStorage.supplierId,
      },
      isBusy: false,
      has_data: false,
      types: {},
    };
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    init() {},
    provider(ctx) {
      this.isBusy = true;
      return this.$api.Label.selectLabelList(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    gotoAdd() {
      this.$router.push("productGenreTagsAdd");
    },
    edit(item, index, button) {
      //   this.$router.push("commodityTagsEdit?item=" + item.id);
      this.$router.push({
        name: "productGenreTagsEdit",
        params: {
          id: item.id,
          item: item,
        },
      });
    },

    //禁用优惠券
    onClickDisabled(item, index, row) {
      let postData = {
        id: item.id,
        status: 0,
      };
      this.$api.Label.enableOrDisableById(postData)
        .then((res) => {
          console.log(res);
          if (res.success && res.message == "Disable success.") {
            this.$toast(res);
            //this.device_provider();
            this.$refs.table.refresh();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        })
        .catch((error) => {});
    },
    //启用优惠券
    onClickEnabled(item, index, row) {
      let postData = {
        id: item.id,
        status: 1,
      };
      this.$api.Label.enableOrDisableById(postData)
        .then((res) => {
          console.log(res);
          if (res.success && res.message == "Enable success.") {
            this.$toast(res);
            //this.device_provider();
            this.$refs.table.refresh();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        })
        .catch((error) => {});
    },
  },
};
</script>
