var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "4" } },
            [
              _c("b-card", { staticClass: "text-center" }, [
                _c("h3", [
                  _vm._v(" " + _vm._s(_vm.selectUserReport.totalUsers) + " ")
                ]),
                _c("p", [_vm._v("Total Users")])
              ])
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "4" } },
            [
              _c("b-card", { staticClass: "text-center" }, [
                _c("h3", [
                  _vm._v(" " + _vm._s(_vm.selectUserReport.todayNewUsers) + " ")
                ]),
                _c("p", [_vm._v("Today New Users")])
              ])
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { lg: "4" } },
            [
              _c("b-card", [
                _c("h3", [
                  _vm._v(" " + _vm._s(_vm.selectUserReport.activeUsers) + " ")
                ]),
                _c("p", [_vm._v("Active Users")])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { lg: "6" } }, [
                _c(
                  "div",
                  { staticClass: "button-items" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-primary" },
                        on: {
                          click: function($event) {
                            return _vm.get_user(1, ["", ""])
                          }
                        }
                      },
                      [_vm._v("Today")]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-primary" },
                        on: {
                          click: function($event) {
                            return _vm.get_user(2, ["", ""])
                          }
                        }
                      },
                      [_vm._v("Yesterday")]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-primary" },
                        on: {
                          click: function($event) {
                            return _vm.get_user(3, ["", ""])
                          }
                        }
                      },
                      [_vm._v("Last Week")]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-primary" },
                        on: {
                          click: function($event) {
                            return _vm.get_user(4, ["", ""])
                          }
                        }
                      },
                      [_vm._v("Last Months")]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "b-col",
                { attrs: { lg: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Select Time",
                        "label-cols-md": "6",
                        "label-align-md": "right",
                        "label-for": "select-time"
                      }
                    },
                    [
                      _c("date-picker", {
                        attrs: {
                          format: "MM/DD/YYYY",
                          range: "",
                          "append-to-body": "",
                          lang: "en",
                          placeholder: "Select date"
                        },
                        on: {
                          change: function($event) {
                            return _vm.get_user("", _vm.selectTime)
                          }
                        },
                        model: {
                          value: _vm.selectTime,
                          callback: function($$v) {
                            _vm.selectTime = $$v
                          },
                          expression: "selectTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { lg: "6" } },
                [
                  _c(
                    "b-card",
                    {
                      staticStyle: { height: "340px" },
                      attrs: { title: "Active Users" }
                    },
                    [
                      _c("chartist", {
                        attrs: {
                          ratio: "ct-chart wid",
                          data: _vm.activeUsersLineChart.data,
                          options: _vm.usre_options,
                          type: "Line"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { lg: "6" } },
                [
                  _c(
                    "b-card",
                    {
                      staticStyle: { height: "340px" },
                      attrs: { title: "New Users" }
                    },
                    [
                      _c("chartist", {
                        attrs: {
                          ratio: "ct-chart wid",
                          data: _vm.newUsersLineChart.data,
                          options: _vm.usre_options,
                          type: "Line"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "8" } },
                [
                  _c(
                    "b-card",
                    { attrs: { title: "User distribution" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-card",
                                { attrs: { title: "Age distribution" } },
                                [
                                  _c("apexchart", {
                                    ref: "ageUsersBarChart",
                                    staticClass: "apex-charts",
                                    attrs: {
                                      dir: "ltr",
                                      type: "bar",
                                      height: "300",
                                      series: _vm.basicColumChart.series,
                                      options: _vm.basicColumChart.chartOptions
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-card",
                                {
                                  attrs: {
                                    title: "Mobile phone model distribution"
                                  }
                                },
                                [
                                  _c("apexchart", {
                                    staticClass: "apex-charts",
                                    attrs: {
                                      height: "320",
                                      type: "pie",
                                      series: _vm.simplePieChart.series,
                                      options: _vm.simplePieChart.chartOptions
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { lg: "4" } },
                [
                  _c(
                    "b-card",
                    { attrs: { title: "Regional distribution of users" } },
                    [
                      _c(
                        "div",
                        { staticClass: "table-responsive mb-0" },
                        [
                          _c("b-table", {
                            attrs: {
                              "show-empty": "",
                              "empty-text": "No records",
                              id: "region-table",
                              "head-variant": "light",
                              striped: "",
                              fields: _vm.fields,
                              items: _vm.provider,
                              busy: _vm.isBusy
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "table-busy",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center text-danger my-2"
                                      },
                                      [
                                        _c("b-spinner", {
                                          staticClass: "align-middle"
                                        }),
                                        _c("strong", [_vm._v("Loading...")])
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "cell(ranking)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "badge",
                                        class: {
                                          "badge-danger": data.value === 1,
                                          "badge-warning": data.value === 2,
                                          "badge-success": data.value === 3
                                        }
                                      },
                                      [_vm._v(_vm._s(data.value))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }