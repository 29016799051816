<template>
  <Dialog v-model="modalVisible" size="lg" centered :showLogo="false">
    <template>
      <div class="vo-column" style="width: 100%" v-if="batchDetail.id">
        <div class="productDetailRow">
          <div class="productDetailColumn">
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Batch#:</strong>
              </p>
              <p class="productDetailContent">{{ batchDetail.number }}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Production Batch Name:</strong>
              </p>
              <p class="productDetailContent">{{ batchDetail.orderName }}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Ceate time:</strong>
              </p>
              <p class="productDetailContent">
                {{ getLocalTime(batchDetail.createTime) }}
              </p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Batch Qty:</strong>
              </p>
              <p class="productDetailContent">{{batchDetail.batchQuantity}} pcs</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Produced Qty:</strong>
              </p>
              <p class="productDetailContent">{{batchDetail.normalQuantity}} pcs</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Status:</strong>
              </p>
              <p class="productDetailContent">{{batchDetail.statusName}}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Completed Time:</strong>
              </p>
              <p class="productDetailContent">-</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor"><strong>Notes:</strong></p>
              <p class="productDetailContent">{{batchDetail.remarks}}</p>
            </div>
          </div>

          <div class="productDetailColumn">
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Product Model#:</strong>
              </p>
              <p class="productDetailContent">AD2601</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Variant Code:</strong>
              </p>
              <p class="productDetailContent">{{batchDetail.variantCode}}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Marketing Name:</strong>
              </p>
              <p class="productDetailContent">{{batchDetail.deviceName}}</p>
            </div>

            <div class="vo-row">
              <p class="productDetailContentColor"><strong>Client:</strong></p>
              <p class="productDetailContent">{{batchDetail.clientName}}</p>
            </div>

            <div class="vo-row">
              <p class="productDetailContentColor"><strong>Brand:</strong></p>
              <p class="productDetailContent">{{batchDetail.brandName}}</p>
            </div>

            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Brand ProductModel#:</strong>
              </p>
              <p class="productDetailContent">{{batchDetail.brandProductModel}}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Brand Marketing Name:</strong>
              </p>
              <p class="productDetailContent">{{batchDetail.brandMarketingName}}</p>
            </div>
          </div>
        </div>

        <div class="productDetailRowButton">
          <b-button variant="outline-secondary" @click="cancel">
            Close
          </b-button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<style scoped>
.productDetailRow {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.productDetailColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.productDetailRowButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.modal-header {
  display: none;
}
.modal-footer {
  display: none;
}
.productDetailContent {
  text-align: left;
  flex: 1;
  margin-left: 5px;
}
.productDetailContentColor {
  width: 200px;
  text-align: right;
  color: #333333;
}
</style>

<script>
import Dialog from "@/components/Dialog";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
export default {
  props: {
    batchDetail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    noBtn: {
      type: String,
      default: "Close",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Dialog,
    timeZoneConversion,
  },
  data() {
    return {};
  },
  computed: {
    modalVisible: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  watch: {},
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    getLocalTime(time) {
      return timeZoneConversion.getLocalTime(time);
    },
  },
  mounted() {},
};
</script>
