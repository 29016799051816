import { render, staticRenderFns } from "./wax-type.vue?vue&type=template&id=bddaabaa&"
import script from "./wax-type.vue?vue&type=script&lang=js&"
export * from "./wax-type.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\公司文件\\web\\omni\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bddaabaa')) {
      api.createRecord('bddaabaa', component.options)
    } else {
      api.reload('bddaabaa', component.options)
    }
    module.hot.accept("./wax-type.vue?vue&type=template&id=bddaabaa&", function () {
      api.rerender('bddaabaa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/production-manage/wax-type/wax-type.vue"
export default component.exports