var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("b-card", [
        _c("div", { staticClass: "productOption" }, [
          _c("div", [
            _c(
              "strong",
              { staticStyle: { "font-size": "18px", color: "black" } },
              [_vm._v("Product Option")]
            )
          ]),
          _c("div", { staticClass: "box-list" }, [
            _c("div", { staticClass: "vo-row" }, [
              _c("div", { staticClass: "infoBorder" }, [
                _c(
                  "div",
                  { staticClass: "infoContent" },
                  [
                    _c("strong", { staticStyle: { color: "black" } }, [
                      _vm._v("Tank Size")
                    ]),
                    _c(
                      "b-list-group",
                      { staticClass: "list-group-container" },
                      _vm._l(_vm.availableTankSize, function(item, i) {
                        return _c(
                          "b-list-group-item",
                          { key: i, attrs: { variant: item.variant } },
                          [
                            _c(
                              "div",
                              { staticClass: "vo-row vo-justify-between" },
                              [
                                !item.editMode
                                  ? _c("div", { staticClass: "vo-row" }, [
                                      _vm._v(" " + _vm._s(item.value) + " ")
                                    ])
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.value,
                                          expression: "item.value"
                                        }
                                      ],
                                      domProps: { value: item.value },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            item,
                                            "value",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                _c("div", { staticClass: "vo-row vo-gap-4" }, [
                                  item.editMode
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/edit.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductEditStatus(
                                              item,
                                              1
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.status == 1 && item.showButton
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/enable.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductStatus(
                                              item,
                                              1
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.status == 0 && item.showButton
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/disable.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductStatus(
                                              item,
                                              1
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.showButton
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/edit.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductEdit(
                                              item,
                                              i,
                                              1
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ])
                              ]
                            )
                          ]
                        )
                      }),
                      1
                    ),
                    _c("b-input", {
                      attrs: { placeholder: "" },
                      model: {
                        value: _vm.tankSizeValue,
                        callback: function($$v) {
                          _vm.tankSizeValue = $$v
                        },
                        expression: "tankSizeValue"
                      }
                    }),
                    _c(
                      "p",
                      {
                        staticClass: "custom-color cursor",
                        on: {
                          click: function($event) {
                            return _vm.clickAddProduct(1)
                          }
                        }
                      },
                      [_vm._v(" +ADD ")]
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "infoBorder" }, [
                _c(
                  "div",
                  { staticClass: "infoContent" },
                  [
                    _c("strong", { staticStyle: { color: "black" } }, [
                      _vm._v("Other Option")
                    ]),
                    _c(
                      "b-list-group",
                      { staticClass: "list-group-container" },
                      _vm._l(_vm.otherOption, function(item, i) {
                        return _c(
                          "b-list-group-item",
                          { key: i, attrs: { variant: item.variant } },
                          [
                            _c(
                              "div",
                              { staticClass: "vo-row vo-justify-between" },
                              [
                                !item.editMode
                                  ? _c("div", { staticClass: "vo-row" }, [
                                      _vm._v(" " + _vm._s(item.value) + " ")
                                    ])
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.value,
                                          expression: "item.value"
                                        }
                                      ],
                                      domProps: { value: item.value },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            item,
                                            "value",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                _c("div", { staticClass: "vo-row vo-gap-4" }, [
                                  item.editMode
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/edit.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductEditStatus(
                                              item,
                                              2
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.status == 1 && item.showButton
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/enable.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductStatus(
                                              item,
                                              2
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.status == 0 && item.showButton
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/disable.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductStatus(
                                              item,
                                              2
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.showButton
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/edit.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductEdit(
                                              item,
                                              i,
                                              2
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ])
                              ]
                            )
                          ]
                        )
                      }),
                      1
                    ),
                    _c("b-input", {
                      attrs: { placeholder: "" },
                      model: {
                        value: _vm.otherOptionValue,
                        callback: function($$v) {
                          _vm.otherOptionValue = $$v
                        },
                        expression: "otherOptionValue"
                      }
                    }),
                    _c(
                      "p",
                      {
                        staticClass: "custom-color cursor",
                        on: {
                          click: function($event) {
                            return _vm.clickAddProduct(2)
                          }
                        }
                      },
                      [_vm._v(" +ADD ")]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "flavorOption" }, [
          _c("div", [
            _c(
              "strong",
              { staticStyle: { "font-size": "18px", color: "black" } },
              [_vm._v("Flavor Option")]
            )
          ]),
          _c("div", { staticClass: "box-list" }, [
            _c("div", { staticClass: "vo-row" }, [
              _c("div", { staticClass: "infoBorder" }, [
                _c(
                  "div",
                  { staticClass: "infoContent" },
                  [
                    _c("strong", { staticStyle: { color: "black" } }, [
                      _vm._v("Ingredients Type")
                    ]),
                    _c(
                      "b-list-group",
                      { staticClass: "list-group-container" },
                      _vm._l(_vm.componentEncoding, function(item, i) {
                        return _c(
                          "b-list-group-item",
                          { key: i, attrs: { variant: item.variant } },
                          [
                            _c(
                              "div",
                              { staticClass: "vo-row vo-justify-between" },
                              [
                                !item.editMode
                                  ? _c("div", { staticClass: "vo-row" }, [
                                      _vm._v(" " + _vm._s(item.value) + " ")
                                    ])
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.value,
                                          expression: "item.value"
                                        }
                                      ],
                                      domProps: { value: item.value },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            item,
                                            "value",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                _c("div", { staticClass: "vo-row vo-gap-4" }, [
                                  item.editMode
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/edit.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductEditStatus(
                                              item,
                                              10
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.status == 1 && item.showButton
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/enable.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductStatus(
                                              item,
                                              10
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.status == 0 && item.showButton
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/disable.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductStatus(
                                              item,
                                              10
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.showButton
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/edit.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductEdit(
                                              item,
                                              i,
                                              10
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ])
                              ]
                            )
                          ]
                        )
                      }),
                      1
                    ),
                    _c("b-input", {
                      attrs: { placeholder: "" },
                      model: {
                        value: _vm.componentEncodingValue,
                        callback: function($$v) {
                          _vm.componentEncodingValue = $$v
                        },
                        expression: "componentEncodingValue"
                      }
                    }),
                    _c(
                      "p",
                      {
                        staticClass: "custom-color cursor",
                        on: {
                          click: function($event) {
                            return _vm.clickAddProduct(10)
                          }
                        }
                      },
                      [_vm._v(" +ADD ")]
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "infoBorder" }, [
                _c(
                  "div",
                  { staticClass: "infoContent" },
                  [
                    _c("strong", { staticStyle: { color: "black" } }, [
                      _vm._v("Terpenes Type")
                    ]),
                    _c(
                      "b-list-group",
                      { staticClass: "list-group-container" },
                      _vm._l(_vm.terpenes, function(item, i) {
                        return _c(
                          "b-list-group-item",
                          { key: i, attrs: { variant: item.variant } },
                          [
                            _c(
                              "div",
                              { staticClass: "vo-row vo-justify-between" },
                              [
                                !item.editMode
                                  ? _c("div", { staticClass: "vo-row" }, [
                                      _vm._v(" " + _vm._s(item.value) + " ")
                                    ])
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.value,
                                          expression: "item.value"
                                        }
                                      ],
                                      domProps: { value: item.value },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            item,
                                            "value",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                _c("div", { staticClass: "vo-row vo-gap-4" }, [
                                  item.editMode
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/edit.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductEditStatus(
                                              item,
                                              11
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.status == 1 && item.showButton
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/enable.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductStatus(
                                              item,
                                              11
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.status == 0 && item.showButton
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/disable.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductStatus(
                                              item,
                                              11
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.showButton
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/edit.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductEdit(
                                              item,
                                              i,
                                              11
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ])
                              ]
                            )
                          ]
                        )
                      }),
                      1
                    ),
                    _c("b-input", {
                      attrs: { placeholder: "" },
                      model: {
                        value: _vm.terpenesValue,
                        callback: function($$v) {
                          _vm.terpenesValue = $$v
                        },
                        expression: "terpenesValue"
                      }
                    }),
                    _c(
                      "p",
                      {
                        staticClass: "custom-color cursor",
                        on: {
                          click: function($event) {
                            return _vm.clickAddProduct(11)
                          }
                        }
                      },
                      [_vm._v(" +ADD ")]
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "infoBorder" }, [
                _c(
                  "div",
                  { staticClass: "infoContent" },
                  [
                    _c("strong", { staticStyle: { color: "black" } }, [
                      _vm._v("Usage Effects Tags")
                    ]),
                    _c(
                      "b-list-group",
                      { staticClass: "list-group-container" },
                      _vm._l(_vm.commodityLabel, function(item, i) {
                        return _c(
                          "b-list-group-item",
                          { key: i, attrs: { variant: item.variant } },
                          [
                            _c(
                              "div",
                              { staticClass: "vo-row vo-justify-between" },
                              [
                                !item.editMode
                                  ? _c("div", { staticClass: "vo-row" }, [
                                      _vm._v(" " + _vm._s(item.value) + " ")
                                    ])
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.value,
                                          expression: "item.value"
                                        }
                                      ],
                                      domProps: { value: item.value },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            item,
                                            "value",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                _c("div", { staticClass: "vo-row vo-gap-4" }, [
                                  item.editMode
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/edit.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductEditStatus(
                                              item,
                                              12
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.status == 1 && item.showButton
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/enable.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductStatus(
                                              item,
                                              12
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.status == 0 && item.showButton
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/disable.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductStatus(
                                              item,
                                              12
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.showButton
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/edit.svg")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickProductEdit(
                                              item,
                                              i,
                                              12
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ])
                              ]
                            )
                          ]
                        )
                      }),
                      1
                    ),
                    _c("b-input", {
                      attrs: { placeholder: "" },
                      model: {
                        value: _vm.commodityLabelValue,
                        callback: function($$v) {
                          _vm.commodityLabelValue = $$v
                        },
                        expression: "commodityLabelValue"
                      }
                    }),
                    _c(
                      "p",
                      {
                        staticClass: "custom-color cursor",
                        on: {
                          click: function($event) {
                            return _vm.clickAddProduct(12)
                          }
                        }
                      },
                      [_vm._v(" +ADD ")]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ]),
      _c(
        "Dialog",
        {
          attrs: {
            imageSrc: require("@/assets/confirm.svg"),
            okBtn: "Yes",
            noBtn: "No"
          },
          on: { ok: _vm.ok, cancel: _vm.cancel },
          model: {
            value: _vm.showAlert,
            callback: function($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert"
          }
        },
        [_c("p", [_vm._v(_vm._s(_vm.showContent))])]
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: _vm.image, noBtn: "OK" },
          on: { cancel: _vm.cancel1 },
          model: {
            value: _vm.successAlear,
            callback: function($$v) {
              _vm.successAlear = $$v
            },
            expression: "successAlear"
          }
        },
        [_vm._v(" " + _vm._s(_vm.successConent) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }