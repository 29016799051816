import {format} from "./time"
import axios from "../config/http.js"
import baseUrl from "../config/env.js"


function selectInvitationCodeList(params){
   return axios.fetchGet(baseUrl + '/invitationCode/list',params)
}

function invitationCodeAdd(params){
    return axios.fetchPost(baseUrl + '/invitationCode/add', params);
}

function selectInvitationBrand(params){
    return axios.fetchGet(baseUrl + '/invitationCode/selectInvitationBrand',params)
}

function selectInvitationClient(params){
    return axios.fetchGet(baseUrl + '/invitationCode/selectInvitationClient',params)
}

function selectInvitationRole(params){
    return axios.fetchGet(baseUrl + '/invitationCode/selectInvitationRole',params)
}
function selectClientBrand(params){
    return axios.fetchGet(baseUrl + '/invitationCode/selectClientBrand',params)
}
export default {
    selectInvitationCodeList,
    invitationCodeAdd,
    selectInvitationBrand,
    selectInvitationClient,
    selectInvitationRole,
    selectClientBrand,
}
