/**
 * app Manage 模块接口
 */
// import base from "./base.js";
import axios from "../config/http.js"
import baseUrl from "../config/env.js"
import {format} from "./time"

const appManage = {
	// 获取关于我们、用户协议、启动页设置、客服电话 get
	async getSetupInfo (){
		return axios.fetchGet(baseUrl + '/systemSetup/setupInfo');
	},

	// 更新 post
	updateSetupInfo (params) {
		return axios.fetchPost(baseUrl + '/systemSetup/update', params);
	},

	// 查询用户反馈
	feedbackList (params) {
		params.startTime=format(params.startTime)
        params.endTime=format(params.endTime)
		return axios.fetchGet(baseUrl + '/feedback/list', params);
	}
}

export default appManage;
