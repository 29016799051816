<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="3">
              <!-- 名称 -->
              <b-form-group
                label="Activity Name"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="share-name"
              >
                <b-form-input
                  id="share-name"
                  v-model="search_condition.activityName"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- 状态 -->
            <b-col lg="3">
              <b-form-group
                label="Status"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="register-status"
              >
                <b-form-select
                  id="register-status"
                  :options="types.status"
                  v-model="search_condition.status"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <!-- 活动时间 -->
            <b-col lg="3">
              <b-form-group
                label="Activated Time"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="active-time"
              >
                <date-picker
                  v-model="activeTime"
                  format="MM/DD/YYYY HH:mm:ss"
                  type="datetime"
                  range
                  append-to-body
                  lang="en"
                  placeholder="Select date"
                ></date-picker>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <b-button-group>
            <b-button
              v-has="'registerSetting::Create'"
              @click="create()"
              variant="primary"
              >Create</b-button
            >
          </b-button-group>
          <!-- table -->
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="No records"
              id="table"
              head-variant="light"
              ref="table"
              :busy="isBusy"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">
                {{
                  data.index +
                  (search_condition.pageNum - 1) * search_condition.pageSize +
                  1
                }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button-group>
                  <b-button
                    v-has="'registerSetting::Modify'"
                    size="sm"
                    variant="primary"
                    @click="show(row.item, row.index, $event.target)"
                    class="mr-1"
                    >Modify</b-button
                  >
                  <b-button
                    v-show="row.item.status == 1"
                    v-has="'registerSetting::Disable'"
                    size="sm"
                    variant="secondary"
                    @click="onClickDisabled(row.item, row.index, $event.target)"
                    class="mr-1"
                    >Disable</b-button
                  >
                  <b-button
                    v-show="row.item.status == 0"
                    v-has="'registerSetting::Disable'"
                    size="sm"
                    variant="success"
                    @click="onClickEnabled(row.item, row.index, $event.target)"
                    class="mr-1"
                    >Enable</b-button
                  >
                </b-button-group>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <br />
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

/**
 * Device component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    timeZoneConversion,
  },
  data() {
    return {
      title: "Register Setting",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Marketing Manage",
          // href: "/"
        },
        {
          text: "Register Setting",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [
        "deviceNumber",
        "deviceCoding",
        "deviceLocation",
        "activationTime",
      ],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        //活动名称
        { key: "activityName", label: "Activity Name", class: "text-center" },
        //活动时间
        {
          key: "activeTime",
          label: "Activity Time",
          class: "text-center",
        },
        //创建时间
        {
          key: "createTime",
          label: "Creation Time",
          class: "text-center",
        },
        //状态
        {
          key: "status",
          label: "Status",
          formatter: (value) => {
            return value == 0 ? "Disabled" : value == 1 ? "Enable" : "";
          },
          class: "text-center",
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        activityName: "", //活动名称
        status: "", //状态(0是停用 1是启用)
        startTime: "", //活动开始时间
        endTime: "", //活动结束时间
      },
      activeTime: ["", ""],
      isBusy: false,
      has_data: false,
      types: {
        status: [
          { text: "Please select", value: "", disabled: false },
          { text: "Disabled", value: 0, disabled: false },
          { text: "Enable", value: 1, disabled: false },
        ],
      },
    };
  },
  watch: {
    activeTime: function (val) {
      this.search_condition.startTime = val[0]
        ? timeZoneConversion.queryTime(val[0])
        : val[0];
      if (val[1]) {
        this.search_condition.endTime = timeZoneConversion.queryTime(val[1]);
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  created() {},
  mounted() {},
  methods: {
    provider(ctx) {
      this.isBusy = true;
      return this.$api.Marketing.selectRegisterSettings(
        this.search_condition,
        ctx
      )
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            x.data.forEach((element) => {
              element.activeTime = "";
              let startTime = timeZoneConversion.getLocalTime(
                element.startTime
              );
              let endTime = timeZoneConversion.getLocalTime(element.endTime);
              element.activeTime = startTime + " ~ " + endTime;
              element.createTime = timeZoneConversion.getLocalTime(
                element.createTime
              );
            });
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    show(item, index, button) {
      // this.$router.push("registerSettingEdit?id=" + item.id);
      this.$router.push({
        name: "registerSettingEdit",
        query: {
          id: item.id,
          startTime: item.startTime,
          endTime: item.endTime,
        },
      });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    //禁用优惠券
    onClickDisabled(item, index, row) {
      let postData = {
        id: item.id,
        status: 0,
      };
      this.$api.Marketing.registerEnableOrDisableById(postData)
        .then((res) => {
          console.log(res);
          if (res.success && res.message == "Disable success.") {
            this.$toast(res);
            //this.device_provider();
            this.$refs.table.refresh();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        })
        .catch((error) => {});
    },
    //启用优惠券
    onClickEnabled(item, index, row) {
      let postData = {
        id: item.id,
        status: 1,
      };
      this.$api.Marketing.shareEnableOrDisableById(postData)
        .then((res) => {
          console.log(res);
          if (res.success && res.message == "Enable success.") {
            this.$toast(res);
            //this.device_provider();
            this.$refs.table.refresh();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        })
        .catch((error) => {});
    },
    create() {
      this.$router.push({
        name: "registerSettingAdd",
      });
    },
  },
};
</script>
