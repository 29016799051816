<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<!-- main -->
		<b-row>
			<b-col lg="12">
				<!-- 查询条件 -->
				<b-card>
					<b-row>
						<b-col lg="4">
							<!-- 领取用户 -->
							<b-form-group
								label="Nick Name"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="nick-number"
							>
								<b-form-input
									id="nick-name"
									v-model="search_condition.nickName"
									trim
								></b-form-input>
							</b-form-group>
						</b-col>
						<!-- 优惠券类型 -->
						<b-col lg="4">
							<b-form-group
								label="Coupon Type"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="coupon-type"
							>
								<b-form-select
									id="coupon-type"
									:options="types.couponType"
									v-model="search_condition.couponType"
								></b-form-select>
							</b-form-group>
						</b-col>
						<!-- 领取来源 -->
						<b-col lg="4">
							<b-form-group
								label="Receiving Source"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="receiving-source"
							>
								<b-form-select
									id="receiving-source"
									:options="types.receivingSourceList"
									v-model="search_condition.sharingSettingsId"
								></b-form-select>
							</b-form-group>
						</b-col>
						<!-- 领取状态 -->
						<b-col lg="4">
							<b-form-group
								label="Claim Status"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="claim-status"
							>
								<b-form-select
									id="claim-status"
									:options="types.claimStatusList"
									v-model="search_condition.claimStatus"
								></b-form-select>
							</b-form-group>
						</b-col>
						<!-- 使用状态 -->
						<b-col lg="4">
							<b-form-group
								label="Usage Status"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="usage-status"
							>
								<b-form-select
									id="usage-status"
									:options="types.usageStatusList"
									v-model="search_condition.status"
								></b-form-select>
							</b-form-group>
						</b-col>
						<!-- 使用时间 -->
						<b-col lg="4">
							<b-form-group
								label="Usage Time"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="usage-time"
							>
								<date-picker
									v-model="usageTime"
									format="MM/DD/YYYY HH:mm:ss"
									type="datetime"
									range
									append-to-body
									lang="en"
									placeholder="Select date"
								></date-picker>
							</b-form-group>
						</b-col>
						<!-- 有效时间 -->
						<b-col lg="4">
							<b-form-group
								label="Effective Time"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="effective-time"
							>
								<date-picker
									v-model="effectiveTime"
									format="MM/DD/YYYY HH:mm:ss"
									type="datetime"
									range
									append-to-body
									lang="en"
									placeholder="Select date"
								></date-picker>
							</b-form-group>
						</b-col>

						<b-col lg="4">
							<b-button variant="primary" @click="search()"
								>Search</b-button
							>
						</b-col>
					</b-row>
				</b-card>

				<b-card>
					<b-button-group>
						<b-button
							v-has="'couponList::Download'"
							@click="download()"
							variant="primary"
							>Download</b-button
						>
					</b-button-group>
					<!-- table -->
					<div class="table-responsive mb-0">
						<b-table
							show-empty
							empty-text="No records"
							id="table"
							head-variant="light"
							ref="table"
							:busy="isBusy"
							:striped="true"
							:items="provider"
							:fields="fields"
							:per-page="search_condition.pageSize"
							:current-page="search_condition.pageNum"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:filter="filter"
							:filter-includedfields="filterOn"
							@filtered="onFiltered"
						>
							<template v-slot:table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>

							<template v-slot:cell(index)="data">
								{{
									data.index +
										(search_condition.pageNum - 1) *
											search_condition.pageSize +
										1
								}}
							</template>

							<template v-slot:cell(actions)="row">
								<b-button-group>
									<b-button
										v-has="'coupon::Modify'"
										size="sm"
										variant="primary"
										@click="
											show(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
										>Modify</b-button
									>
								</b-button-group>
							</template>
						</b-table>
					</div>
					<!-- end table -->
					<br />
					<!-- pagination -->
					<b-row >
						<b-col>
							<div
								class="dataTables_paginate paging_simple_numbers float-right"
							>
								<ul class="pagination mb-0" v-if="rows != 0">
									<b-pagination
										v-model="search_condition.pageNum"
										:per-page="10"
										:total-rows="rows"
										aria-controls="table"
									></b-pagination>
								</ul>
							</div>
						</b-col>
					</b-row>
					<!-- end pagination -->
				</b-card>
			</b-col>
		</b-row>
		<!-- end main -->
	</Layout>
</template>
<script>
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import DatePicker from 'vue2-datepicker'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'

/**
 * Device component
 */
export default {
	components: {
		Layout,
		PageHeader,
		DatePicker,
		timeZoneConversion,
	},
	data() {
		return {
			title: 'Coupon List',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'Marketing Manage',
					// href: "/"
				},
				{
					text: 'Coupon List',
					active: true,
				},
			],
			tableData: [],
			totalRows: 0,
			currentPage: 1,
			perPage: 10,
			pageOptions: [10, 25, 50, 100],
			filter: null,
			filterOn: [
				'deviceNumber',
				'deviceCoding',
				'deviceLocation',
				'activationTime',
			],
			sortBy: 'id',
			sortDesc: false,
			fields: [
				{ key: 'index', label: '#', class: 'text-center' },
				{
					key: 'couponName',
					label: 'Coupon Name',
					class: 'text-center',
				},
				{
					key: 'couponType', //优惠券类型
					label: 'Coupon Type',
					class: 'text-center',
					formatter: (value) => {
						return value == 0
							? 'Rebate coupon'
							: value == 1
							? 'Discount voucher'
							: value == 2
							? 'Unconditional coupon'
							: value == 3
							? 'Exchange coupon'
							: ''
					},
				},
				{
					key: 'methodOfObtaining', //获取方式
					label: 'Acquisition Method',
					class: 'text-center',
					formatter: (value) => {
						return value == 0
							? 'Automatic distribution'
							: value == 1
							? 'Link acquisition'
							: value == 2
							? 'Activity collection'
							: ''
					},
				},
				{
					key: 'discountedPrice', //优惠金额
					label: 'Discounted Price',
					class: 'text-center',
				},
				{
					key: 'effectiveTime', //有效时间
					label: 'Effective Time',
					class: 'text-center',
				},
				{
					key: 'nickName', //领取用户
					label: 'Nick Name',
					class: 'text-center',
				},
				{
					key: 'collectionTime', //领取时间
					label: 'Collection Time',
					class: 'text-center',
				},
				{
					key: 'status', //状态
					label: 'Waiting to be use',
					formatter: (value) => {
						return value == 0
							? 'Waiting used'
							: value == 1
							? 'Already used'
							: value == 2
							? 'Expired'
							: ''
					},
					class: 'text-center',
				},
				{ key: 'useTime', label: 'Usage Time', class: 'text-center' }, //使用时间
				// { key: "actions", label: "Opreration", class: "text-center" },
			],
			search_condition: {
				pageNum: 1,
				pageSize: 10,

				nickName: '', //领取用户名称
				couponType: '', //优惠券类型(0是满减 1是折扣 2无门槛)
				sharingSettingsId: '', //领取来源
				claimStatus: '', //领取状态
				status: '', //使用状态(0待使用 1是已使用 2是已过期)
				useStartTime: '', //使用开始时间
				useEndTime: '', //使用结束时间
				startTime: '', //有效开始时间
				endTime: '', //有效结束时间
			},
			usageTime: ['', ''],
			effectiveTime: ['', ''],

			isBusy: false,
			has_data: false,
			form: { nickName: '', file: '', remarks: '', id: '' },
			types: {
				statuss: [
					{ text: 'Please select', value: '', disabled: false },
					{ text: 'Disabled', value: 0, disabled: false },
					{ text: 'Enable', value: 1, disabled: false },
				],
				deviceList: [
					{ text: 'Please select', value: '', disabled: false },
				],
				//领取来源
				receivingSourceList: [{ text: 'Please select', value: '' }],
				//领取状态
				claimStatusList: [
					{ text: 'Pleaset select', value: '' },
					{ text: 'Unclaimed', value: 0 },
					{ text: 'Received', value: 1 },
				],
				//使用状态
				usageStatusList: [
					{ text: 'Pleaset select', value: '' },
					{
						text: 'Waiting used',
						value: '0',
					},
					{
						text: 'Already used',
						value: '1',
					},
					{
						text: 'Expired',
						value: '2',
					},
				],
				//优惠券类型
				couponType: [
					{ text: 'Please select', value: '', disabled: false },
					{
						text: 'Cash Back',
						value: 0, //满减
					},
					{
						text: 'Discount',
						value: 1, //折扣
					},
					{
						text: 'No Threshold',
						value: 2, // 无门槛
					},
				],
				//优惠券使用范围
				useRange: [
					{ text: 'Please select', value: '', disabled: false },
					{
						text: 'All Products',
						value: 0,
					},
					{
						text: 'Designated category',
						value: 1,
					},
					{
						text: 'Designated Products',
						value: 2,
					},
				],
			},
		}
	},
	watch: {
		usageTime: function(val) {
			this.search_condition.useStartTime = val[0]
				? timeZoneConversion.queryTime(val[0])
				: val[0]
			if (val[1]) {
				this.search_condition.useEndTime = timeZoneConversion.queryTime(
					val[1]
				)
			} else {
				this.search_condition.useEndTime = val[1]
			}
		},
		effectiveTime: function(val) {
			this.search_condition.startTime = val[0]
				? timeZoneConversion.queryTime(val[0])
				: val[0]
			if (val[1]) {
				this.search_condition.endTime = timeZoneConversion.queryTime(
					val[1]
				)
			} else {
				this.search_condition.endTime = val[1]
			}
		},
	},
	computed: {
		/**
		 * 计算rows
		 */
		rows() {
			return this.totalRows
		},
	},
	created() {
		this.getSharingOptions()
	},
	mounted() {},
	methods: {
		provider(ctx) {
			this.isBusy = true
			return this.$api.Marketing.selectCouponCodeList(
				this.search_condition,
				ctx
			)
				.then((x) => {
					this.isBusy = false
					if (x.total) {
						this.has_data = true
						this.totalRows = x.total
						x.data.forEach((element) => {
							element.effectiveTime = ''
							let startTime = timeZoneConversion.getLocalTime(
								element.startTime
							)
							let endTime = timeZoneConversion.getLocalTime(
								element.endTime
							)
							element.effectiveTime = startTime + ' ~ ' + endTime
							element.collectionTime = timeZoneConversion.getLocalTime(
								element.collectionTime
							)
							element.useTime = timeZoneConversion.getLocalTime(
								element.useTime
							)
						})
						return x.data
					} else {
						this.has_data = false
						return []
					}
				})
				.catch((e) => {
					this.isBusy = false
					this.has_data = false
					return []
				})
		},
		show(item, index, button) {
			this.$router.push('couponEdit?id=' + item.id)
		},
		search() {
			this.search_condition.pageNum = 1
			this.$refs.table.refresh()
		},
		download() {
			if (
				this.search_condition.startTime == '' ||
				this.search_condition.endTime == ''
			) {
				this.$bvToast.toast('Please select effective time.', {
					title: 'Tips',
					variant: 'warning',
					solid: true,
				})
				return
			}
			this.$api.Marketing.downCouponCodeList(this.search_condition)
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		handleOk(bvModalEvt) {
			bvModalEvt.preventDefault()
			let formData = new FormData()
			formData.append('id', localStorage.supplierId)
			formData.append('file', this.form.file)
			formData.append('remarks', this.form.remarks)
			let that = this
			that.$api.Device.addBatch(formData).then((res) => {
				that.$bvToast.toast(res.data.message)
				if (res.data.success) {
					that.$refs.table.refresh()
				}
			})
			this.handleSubmit()
		},
		handleSubmit() {},
		getSharingOptions() {
			let that = this
			this.$api.Dropdown.selectAllSharingSettings()
				.then((res) => {
					if (res.success) {
						for (let i = 0; i < res.data.length; i++) {
							this.types.receivingSourceList.push({
								text: res.data[i].activityName,
								value: res.data[i].id,
							})
						}
					} else {
						if (res.message) {
							that.$bvToast.toast(res.message)
						}
					}
				})
				.catch((res) => {})
		},
		create() {
			this.$router.push({
				name: 'couponAdd',
			})
		},
	},
}
</script>
