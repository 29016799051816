<!--
 * @Author: Code-HHX
 * @Date: 2022-04-27 15:38:23
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-27 18:45:36
 * @Description: 
-->
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="3">
              <b-form-group
                label="Connection Name"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="device-name"
              >
                <b-form-input
                  id="device-name"
                  trim
                  v-model="search_condition.deviceName"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <b-row class="align-items-center">
            <b-col lg="12">
              <div class="blockquote">
                <h4>Connection Type</h4>
              </div>
            </b-col>
          </b-row>
          <!-- table -->
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="No records"
              id="device-table"
              head-variant="light"
              ref="table"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">{{
                data.index +
                (search_condition.pageNum - 1) * search_condition.pageSize +
                1
              }}</template>

              <template v-slot:cell(flavorPicture)="data">
                <img
                  v-if="data.item.flavorPicture"
                  :src="`${data.item.flavorPicture}`"
                  alt
                  class="avatar-xs rounded-circle mr-2"
                />
              </template>

              <template v-slot:cell(actions)="row">
                <b-button-group>
                  <b-button
                    v-has="'curveRecommend::voltageCurve'"
                    v-if="row.item.supplierId != 0"
                    size="sm"
                    variant="info"
                    @click="heatingMode(row.item, row.index, $event.target)"
                    class="mr-1"
                    >Voltage Curve</b-button
                  >
                </b-button-group>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <!-- <b-row v-if="has_data">
						<b-col>
							<div
								class="dataTables_paginate paging_simple_numbers float-right"
							>
								<ul class="pagination mb-0" v-if="rows != 0">
									<b-pagination
										ref="page"
										v-model="search_condition.pageNum"
										:per-page="10"
										:total-rows="rows"
										aria-controls="table"
									></b-pagination>
								</ul>
							</div>
						</b-col>
					</b-row> -->
          <!-- end pagination -->
        </b-card>

        <b-card>
          <b-row class="align-items-center">
            <b-col lg="12">
              <div class="blockquote">
                <h4>Curve Recommend</h4>
              </div>
            </b-col>
          </b-row>
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="No records"
              id="table"
              head-variant="light"
              ref="table"
              :busy="isBusy"
              :striped="true"
              :items="voltageProvider"
              :fields="voltageCurve"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">
                {{
                  data.index +
                  (search_condition.pageNum - 1) * search_condition.pageSize +
                  1
                }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button-group>
                  <b-button
                    v-has="'curvesMgmt::Modify'"
                    v-if="row.item.supplierId != 0"
                    size="sm"
                    variant="primary"
                    @click="edit(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    Modify
                  </b-button>
                </b-button-group>
              </template>
              <template v-slot:cell(temperature)="row">
                <div class="temperature">
                  <history-curve :power="row.item.temperature" />
                </div>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <!-- <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  >
                  </b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row> -->
          <!-- end pagination -->
        </b-card>

        <!-- 加热模式 -->
        <b-modal
          size="md"
          ref="modal"
          v-model="heatingModeShow"
          title="Voltage Curve"
          @hidden="resetHeatingMode"
          hide-footer
          centered
          no-close-on-esc
          no-close-on-backdrop
        >
          <form
            ref="form"
            @submit="onSubmitHeatingMode"
            @reset="onResetHeatingMode"
          >
            <b-form-group
              label="Mode:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="mode"
            >
              <multiselect
                v-model="heatingModeId"
                :options="types.selectByHeatingModeId"
                track-by="id"
                label="modeName"
                placeholder="Please select"
                :searchable="false"
                :close-on-select="false"
                :multiple="true"
                @input="changeHeatingModeId(heatingModeId)"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              >
              </multiselect>
            </b-form-group>
            <b-form-group label-cols-lg="4">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
            </b-form-group>
          </form>
        </b-modal>
      </b-col>
    </b-row>
    <!-- end main -->
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import HistoryCurve from "../../../../components/HistoryCurve.vue";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    timeZoneConversion,
    HistoryCurve,
  },
  data() {
    return {
      title: "Curve Recommend",
      items: [
        {
          text: this.$store.getters.getTitle,
        },
        {
          text: "My Curves",
        },
        {
          text: "Curve Recommend",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#" },
        {
          key: "modelName",
          label: "Connection Name",
          class: "text-center",
        },
        {
          key: "modelIdentification",
          label: "Connection Mark",
          class: "text-center",
        },
        {
          key: "modelPicture",
          label: "Picture",
          class: "text-center",
        },
        {
          key: "createTime",
          label: "Create Time",
          class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.getLocalTime(value);
          },
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      voltageCurve: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "modeName",
          label: "Voltage Curve Name",
          class: "text-center",
        },
        {
          key: "temperature",
          label: "Voltage Curve",
          class: "text-center",
        },
        {
          key: "position",
          label: "Position",
          formatter: (value) => {
            return value == 0
              ? "-"
              : value == 1
              ? "Low position"
              : "High position";
          },
          class: "text-center",
        },
        {
          key: "deviceName",
          label: "Devices Type",
          formatter: (value) => {
            return value == null ? "-" : value;
          },
          class: "text-center",
        },
        {
          key: "heatingRemarks",
          label: "Voltage Curve Description",
          class: "text-center",
        },
        // {
        //   key: "supplierId",
        //   label: "Affiliated Customer",
        //   formatter: (value) => {
        //     let a = ""
        //     this.types.allSupplier.forEach(item=>{
        //       if(item.id==value){
        //         a = item.brandName
        //       }
        //     })
        //     return a;
        //   },
        //   class: "text-center",
        // },
        {
          key: "status",
          label: "Status",
          formatter: (value) => {
            return value == 0 ? "Enabled" : "Disabled";
          },
          class: "text-center",
        },
        {
          key: "remarks",
          label: "Notes",
          class: "text-center",
        },
        {
          key: "createTime",
          label: "Create Time",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return timeZoneConversion.getLocalTime(value);
            }
          },
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        deviceName: "",

        pageNum: 1,
        pageSize: 10,
        supplierId: localStorage.supplierId,
      },
      curve_search_condition: {
        pageNum: 1,
        pageSize: 10,
      },
      isBusy: false,
      has_data: false,
      types: {
        selectByHeatingModeId: [],
        singleHeatingModeId: [{ modeName: "Please select", id: 0 }],
      },
      heatingModeId: [],
      heating: {
        id: "",
        heatingModeId: [],
        type: 0
      },
      heatingModeShow: false,
    };
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    init() {},
    provider(ctx) {
      this.isBusy = true;
      return this.$api.Device.selectDeviceModelList(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    voltageProvider(ctx) {
      this.isBusy = true;
      return this.$api.warmUpMode
        .selectRecommendList(this.curve_search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            x.data.forEach(function (value) {
              var curve = value.temperature.split(",");
              var curveData = curve.map((item) =>
                parseFloat(
                  (Math.pow(parseInt(item) / 1000, 2) / 1.2).toFixed(2)
                )
              );
              value.temperature = curveData;
            });
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    heatingMode(a, b, c) {
      this.heatingModeShow = !this.heatingModeShow;
      this.heating = {
        id: a.id,
        heatingModeId: a.heatingModeArrayId ? a.heatingModeArrayId : [],
        type :0
      };
      this.selectByHeatingModeId(a.heatingModeArrayId);
    },
    selectByHeatingModeId(ele) {
      this.types.selectByHeatingModeId = [];
      this.types.singleHeatingModeId = [{ modeName: "Please select", id: 0 }];
      this.$api.Dropdown.selectAllDeviceHeatingMode({}).then((res) => {
        if (res.success && res.data) {
          this.types.selectByHeatingModeId =
            this.types.selectByHeatingModeId.concat(res.data);
          const serverIds = this.types.selectByHeatingModeId.map(
            (item) => item.id
          );
          if (ele.length > 0) {
            this.heatingModeId = [];
            ele.forEach((item) => {
              const index = serverIds.indexOf(item);
              if (index > -1) {
                this.heatingModeId.push(
                  this.types.selectByHeatingModeId[index]
                );
              }
            });
          }
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    changeHeatingModeId(heatingModeId) {
      let arr = [];
      heatingModeId.forEach((item) => {
        arr.push(item.id);
      });
      this.heating.heatingModeId = arr;
      console.log(this.heating.heatingModeId);
    },
    resetHeatingMode() {
      this.heating = {
        id: "",
        heatingModeId: [],
        type :0,
      };
      this.heatingModeId = [];
    },
    onResetHeatingMode(evt) {
      evt.preventDefault();
      this.heating = {
        id: "",
        heatingModeId: [],
        type: 0,
      };
      this.heatingModeId = [];
      this.heatingModeShow = !this.heatingModeShow;
    },
    onSubmitHeatingMode(evt) {
      evt.preventDefault();
      this.$api.Device.associatedHeatingMode(this.heating).then((res) => {
        if (res.success) {
          this.heatingModeShow = !this.heatingModeShow;
          this.$refs.table.refresh();
        }
        if (res.message) {
          this.$toast(res);
        }
      });
    },
    edit(item, index, button) {
      this.$router.push("curvesMgmtEdit?id=" + item.id);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .multiselect__option {
  white-space: normal;
}
.temperature {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
