<template>
  <div class="vo-column">
    <h4 class="font-size-20 mt-2">Operation Log</h4>

    <b-card>
      <div class="table-responsive mb-0">
        <b-table
            striped
            borderless
            show-empty
            small
            empty-text="No records"
            id="device-table"
            head-variant="none"
            ref="table"
            :striped="true"
            :items="provider"
            :fields="fields"
            :per-page="filterParams.pageSize"
            :current-page="filterParams.pageNum"
            :sort-by.sync="filterParams.sortBy"
            :sort-desc.sync="filterParams.sortDesc"
            :filter="filter"
            :filter-includedfields="filterOn"
            @filtered="onFiltered"
        >
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template v-slot:cell(index)="data">{{
              data.index +
              (filterParams.pageNum - 1) * filterParams.pageSize +
              1
            }}
          </template>

          <template v-slot:cell(operationContent)="row">
            <span>Modify Status</span>
          </template>

          <template v-slot:cell(OperationTime)="row">
            <span>April 8th 2024 19:04:46</span>
          </template>

          <template v-slot:cell(OperationAccount)="row">
            <span>mark@ikrusher.cn</span>
          </template>
          <template v-slot:cell(Operator)="row">
            <span>mark</span>
          </template>
        </b-table>
      </div>


    </b-card>
  </div>
</template>

<script>

import HistoryCurve from "@/components/HistoryCurve.vue";

export default {
  name: "OperationLog",
  components: {HistoryCurve},

  data() {
    return {
      fields: [],
      filterParams: {
        pageNum: 1,
        pageSize: 10,
        sortBy: "id",
        sortDesc: false,
      },
      filter: null,
      filterOn: []
    }
  },
  methods: {
    provider(ctx) {
      this.isBusy = true;
      // return this.$api.Label.selectLabelList(this.search_condition, ctx)
      //     .then((x) => {
      //       this.isBusy = false;
      //       if (x.total) {
      //         this.has_data = true;
      //         this.totalRows = x.total;
      //         return x.data;
      //       } else {
      //         this.has_data = false;
      //         return [];
      //       }
      //     })
      //     .catch((e) => {
      //       this.isBusy = false;
      //       this.has_data = false;
      //       return [];
      //     });
      return [{
        operationContent: "1231",
        OperationTime: "1231",
        OperationAccount: "1231",
        Operator: "1231",
      }, {
        operationContent: "1231",
        OperationTime: "1231",
        OperationAccount: "1231",
        Operator: "1231",
      },
        {
          operationContent: "1231",
          OperationTime: "1231",
          OperationAccount: "1231",
          Operator: "1231",
        }]
    },

    onFiltered() {
    },
  }
}
</script>


<style scoped lang="scss">
::v-deep .table .thead-light th {
  background: white;
}
</style>