<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<b-row>
			<b-col lg="12">
				<!-- 查询条件 -->
				<b-card>
					<b-row>
						<b-col lg="4">
							<b-form-group
								label="Coupon Name"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="coupon-number"
							>
								<b-form-input
									id="coupon-name"
									v-model="search_condition.couponName"
									trim
								></b-form-input>
							</b-form-group>
						</b-col>

						<b-col lg="4">
							<b-form-group
								label="Coupon Type"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="coupon-type"
							>
								<b-form-select
									id="coupon-type"
									:options="types.couponType"
									v-model="search_condition.couponType"
								></b-form-select>
							</b-form-group>
						</b-col>

						<b-col lg="4">
							<b-form-group
								label="Scope Of Use"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="scope-of-use"
							>
								<b-form-select
									id="scope-of-use"
									:options="types.useRange"
									v-model="search_condition.scopeOfUse"
								></b-form-select>
							</b-form-group>
						</b-col>

						<b-col lg="4">
							<b-form-group
								label="Coupon Status"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="coupon-status"
							>
								<b-form-select
									id="coupon-status"
									:options="types.statuss"
									v-model="search_condition.status"
								></b-form-select>
							</b-form-group>
						</b-col>

						<b-col lg="4">
							<b-form-group
								label="Usage Time"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="usage-time"
							>
								<date-picker
									v-model="activeTime"
									format="MM/DD/YYYY HH:mm:ss"
									type="datetime"
									range
									append-to-body
									lang="en"
									placeholder="Select date"
								></date-picker>
							</b-form-group>
						</b-col>

						<b-col lg="4">
							<b-button variant="primary" @click="search()"
								>Search</b-button
							>
						</b-col>
					</b-row>
				</b-card>

				<b-card>
					<b-button-group>
						<b-button
							v-has="'coupon::Create'"
							@click="create()"
							variant="primary"
							>Create</b-button
						>
					</b-button-group>
					<!-- table -->
					<div class="table-responsive mb-0">
						<b-table
							show-empty
							empty-text="No records"
							id="table"
							head-variant="light"
							ref="table"
							:busy="isBusy"
							:striped="true"
							:items="provider"
							:fields="fields"
							:per-page="search_condition.pageSize"
							:current-page="search_condition.pageNum"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:filter="filter"
							:filter-includedfields="filterOn"
							@filtered="onFiltered"
						>
							<template v-slot:table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>

							<template v-slot:cell(index)="data">
								{{
									data.index +
										(search_condition.pageNum - 1) *
											search_condition.pageSize +
										1
								}}
							</template>

							<template v-slot:cell(actions)="row">
								<b-button-group>
									<b-button
										v-has="'coupon::Modify'"
										size="sm"
										variant="primary"
										@click="
											show(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
										>Modify</b-button
									>
									<b-button
										v-show="row.item.status == 1"
										v-has="'coupon::Disable'"
										size="sm"
										variant="secondary"
										@click="
											onClickDisabled(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
										>Disable</b-button
									>
									<b-button
										v-show="row.item.status == 0"
										v-has="'coupon::Disable'"
										size="sm"
										variant="success"
										@click="
											onClickEnabled(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
										>Enable</b-button
									>
								</b-button-group>
							</template>
						</b-table>
					</div>
					<!-- end table -->
					<br />
					<!-- pagination -->
					<b-row v-if="has_data">
						<b-col>
							<div
								class="dataTables_paginate paging_simple_numbers float-right"
							>
								<ul class="pagination mb-0" v-if="rows != 0">
									<b-pagination
										v-model="search_condition.pageNum"
										:per-page="10"
										:total-rows="rows"
										aria-controls="table"
									></b-pagination>
								</ul>
							</div>
						</b-col>
					</b-row>
					<!-- end pagination -->
				</b-card>
			</b-col>
		</b-row>
		<!-- end main -->
	</Layout>
</template>
<script>
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import DatePicker from 'vue2-datepicker'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'

/**
 * Device component
 */
export default {
	components: {
		Layout,
		PageHeader,
		DatePicker,
		timeZoneConversion,
	},
	data() {
		return {
			title: 'Coupon',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'Marketing Manage',
					// href: "/"
				},
				{
					text: 'Coupon',
					active: true,
				},
			],
			tableData: [],
			totalRows: 0,
			currentPage: 1,
			perPage: 10,
			pageOptions: [10, 25, 50, 100],
			filter: null,
			filterOn: [
				'deviceNumber',
				'deviceCoding',
				'deviceLocation',
				'activationTime',
			],
			sortBy: 'id',
			sortDesc: false,
			fields: [
				{ key: 'index', label: '#', class: 'text-center' },
				{
					key: 'couponName',
					label: 'Coupon Name',
					class: 'text-center',
				},
				{
					key: 'discountedPrice',
					label: 'Coupon Value',
					class: 'text-center',
				},
				{
					key: 'issuanceQuantity', //发放数量
					label: 'Issued Quantity',
					class: 'text-center',
				},
				{
					key: 'remainingCount', //剩余数
					label: 'Remaining Quantity',
					class: 'text-center',
				},
				{
					key: 'receiveCount', //已领取数量
					label: 'Claimed by Users',
					class: 'text-center',
				},
				{
					key: 'issuanceTime', //发放时间
					label: 'Coupon Period',
					class: 'text-center',
				},
				{ key: 'actions', label: 'Opreration', class: 'text-center' },
			],
			search_condition: {
				pageNum: 1,
				pageSize: 10,
				couponName: '', //优惠券名称
				couponType: '', //优惠券类型(0是满减 1是折扣 2无门槛)
				scopeOfUse: '', //使用范围(0是全部商品 1是指定分类 2是指定商品)
				status: '', //状态(0是停用 1是启用)
				startTime: '',
				endTime: '',
				supplierId: localStorage.supplierId,
			},
			activeTime: ['', ''],
			isBusy: false,
			has_data: false,
			form: { brandId: '', file: '', remarks: '', id: '' },
			types: {
				statuss: [
					{ text: 'Please select', value: '', disabled: false },
					{ text: 'Disabled', value: 0, disabled: false },
					{ text: 'Enable', value: 1, disabled: false },
				],
				brand: [{ text: 'Please select', value: '' }],
				deviceList: [
					{ text: 'Please select', value: '', disabled: false },
				],
				//优惠券类型
				couponType: [
					{ text: 'Please select', value: '', disabled: false },
					{
						text: 'Cash Back',
						value: 0, //满减
					},
					{
						text: 'Discount',
						value: 1, //折扣
					},
					{
						text: 'No Threshold',
						value: 2, // 无门槛
					},
				],
				//优惠券使用范围
				useRange: [
					{ text: 'Please select', value: '', disabled: false },
					{
						text: 'All Products',
						value: 0,
					},
					{
						text: 'Designated category',
						value: 1,
					},
					{
						text: 'Designated Products',
						value: 2,
					},
				],
			},
		}
	},
	watch: {
		activeTime: function(val) {
			this.search_condition.startTime = val[0]
				? timeZoneConversion.queryTime(val[0])
				: val[0]
			if (val[1]) {
				this.search_condition.endTime = timeZoneConversion.queryTime(
					val[1]
				)
			} else {
				this.search_condition.endTime = val[1]
			}
		},
	},
	computed: {
		/**
		 * 计算rows
		 */
		rows() {
			return this.totalRows
		},
	},
	created() {},
	mounted() {},
	methods: {
		provider(ctx) {
			this.isBusy = true
			return this.$api.Marketing.selectCouponCode(
				this.search_condition,
				ctx
			)
				.then((x) => {
					this.isBusy = false
					if (x.total) {
						this.has_data = true
						this.totalRows = x.total
						x.data.forEach((element) => {
							element.issuanceTime = '1'
							let startTime = timeZoneConversion.getLocalTime(
								element.startTime
							)
							let endTime = timeZoneConversion.getLocalTime(
								element.endTime
							)
							element.issuanceTime = startTime + ' ~ ' + endTime
						})
						return x.data
					} else {
						this.has_data = false
						return []
					}
				})
				.catch((e) => {
					this.isBusy = false
					this.has_data = false
					return []
				})
		},
		show(item, index, button) {
			this.$router.push('couponEdit?id=' + item.id)
		},
		search() {
			this.search_condition.pageNum = 1
			this.$refs.table.refresh()
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		//禁用优惠券
		onClickDisabled(item, index, row) {
			let postData = {
				id: item.id,
				status: 0,
			}
			this.$api.Marketing.enableOrDisableById(postData)
				.then((res) => {
					console.log(res)
					if (res.success && res.message == 'Disable success.') {
						this.$toast(res)
						//this.device_provider();
						this.$refs.table.refresh()
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
				.catch((error) => {})
		},
		//启用优惠券
		onClickEnabled(item, index, row) {
			let postData = {
				id: item.id,
				status: 1,
			}
			this.$api.Marketing.enableOrDisableById(postData)
				.then((res) => {
					console.log(res)
					if (res.success && res.message == 'Enable success.') {
						this.$toast(res)
						//this.device_provider();
						this.$refs.table.refresh()
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
				.catch((error) => {})
		},
		create() {
			this.$router.push({
				name: 'couponAdd',
			})
		},
	},
}
</script>
