var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-6" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v("Image thumbnails")
              ]),
              _c("p", { staticClass: "card-title-desc" }, [
                _vm._v(
                  " In addition to our border-radius utilities, you can use "
                ),
                _c("code", { staticClass: "highlighter-rouge" }, [
                  _vm._v("thumbnail")
                ]),
                _vm._v(" to give an image a rounded 1px border appearance. ")
              ]),
              _c(
                "div",
                {},
                [
                  _c("b-img", {
                    attrs: {
                      src: require("@/assets/images/small/img-3.jpg"),
                      width: "200",
                      thumbnail: "",
                      alt: "200x200"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v("Image Rounded & Circle")
              ]),
              _c("p", { staticClass: "card-title-desc" }, [
                _vm._v(" Use attribute "),
                _c("code", [_vm._v("rounded")]),
                _vm._v(" and "),
                _c("code", [_vm._v("rounded='circle'")]),
                _vm._v(". ")
              ]),
              _c(
                "div",
                {},
                [
                  _c("b-img", {
                    staticClass: "mr-2 mo-mb-2",
                    attrs: {
                      src: require("@/assets/images/small/img-4.jpg"),
                      rounded: "",
                      width: "200",
                      alt: "200x200"
                    }
                  }),
                  _c("b-img", {
                    attrs: {
                      src: require("@/assets/images/users/user-4.jpg"),
                      rounded: "circle",
                      alt: "200x200"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "col-lg-6" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v("Responsive images")
              ]),
              _c("p", { staticClass: "card-title-desc" }, [
                _vm._v(" Images in Bootstrap are made responsive with "),
                _c("code", { staticClass: "highlighter-rouge" }, [
                  _vm._v("fluid")
                ]),
                _vm._v(". "),
                _c("code", { staticClass: "highlighter-rouge" }, [
                  _vm._v("max-width: 100%;")
                ]),
                _vm._v(" and "),
                _c("code", { staticClass: "highlighter-rouge" }, [
                  _vm._v("height: auto;")
                ]),
                _vm._v(
                  " are applied to the image so that it scales with the parent element. "
                )
              ]),
              _c(
                "div",
                {},
                [
                  _c("b-img", {
                    attrs: {
                      src: require("@/assets/images/small/img-2.jpg"),
                      fluid: "",
                      alt: "Responsive image"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Default media object")
                ]),
                _c("p", { staticClass: "card-title-desc" }, [
                  _vm._v(
                    " The default media displays a media object (images, video, audio) to the left or right of a content block. "
                  )
                ]),
                _c(
                  "b-media",
                  {
                    staticClass: "mb-4",
                    scopedSlots: _vm._u([
                      {
                        key: "aside",
                        fn: function() {
                          return [
                            _c("b-img", {
                              attrs: {
                                src: require("@/assets/images/users/user-6.jpg"),
                                rounded: "circle",
                                height: "64",
                                alt: "Generic placeholder image"
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c("h5", { staticClass: "mt-0 font-16" }, [
                      _vm._v("Media heading")
                    ]),
                    _vm._v(
                      "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. "
                    )
                  ]
                ),
                _c(
                  "b-media",
                  {
                    staticClass: "mb-4",
                    scopedSlots: _vm._u([
                      {
                        key: "aside",
                        fn: function() {
                          return [
                            _c("b-img", {
                              attrs: {
                                src: require("@/assets/images/users/user-2.jpg"),
                                rounded: "circle",
                                height: "64",
                                alt: "Generic placeholder image"
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c(
                      "b-media-body",
                      [
                        _c("h5", { staticClass: "mt-0 font-16" }, [
                          _vm._v("Media heading")
                        ]),
                        _vm._v(
                          "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. "
                        ),
                        _c(
                          "b-media",
                          {
                            staticClass: "mt-3",
                            scopedSlots: _vm._u([
                              {
                                key: "aside",
                                fn: function() {
                                  return [
                                    _c("b-img", {
                                      attrs: {
                                        src: require("@/assets/images/users/user-3.jpg"),
                                        rounded: "circle",
                                        height: "64",
                                        alt: "Generic placeholder image"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          },
                          [
                            _c("b-media-body", [
                              _c("h5", { staticClass: "mt-0 font-16" }, [
                                _vm._v("Media heading")
                              ]),
                              _vm._v(
                                "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. "
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-media",
                  {
                    attrs: { "right-align": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "aside",
                        fn: function() {
                          return [
                            _c("b-img", {
                              attrs: {
                                src: require("@/assets/images/users/user-4.jpg"),
                                rounded: "circle",
                                height: "64",
                                alt: "Generic placeholder image"
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c("b-media-body", [
                      _c("h5", { staticClass: "mt-0 mb-1 font-16" }, [
                        _vm._v("Media object")
                      ]),
                      _vm._v(
                        "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. "
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Media alignment")
                ]),
                _c("p", { staticClass: "card-title-desc" }, [
                  _vm._v(
                    " The images or other media can be aligned top, middle, or bottom. The default is top aligned. "
                  )
                ]),
                _c(
                  "b-media",
                  {
                    staticClass: "mb-4",
                    attrs: { "vertical-align": "top" },
                    scopedSlots: _vm._u([
                      {
                        key: "aside",
                        fn: function() {
                          return [
                            _c("b-img", {
                              attrs: {
                                src: require("@/assets/images/users/user-3.jpg"),
                                rounded: "",
                                height: "64",
                                alt: "Generic placeholder image"
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c("h5", { staticClass: "mt-0 font-16" }, [
                      _vm._v("Top-aligned media")
                    ]),
                    _c("p", [
                      _vm._v(
                        "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus."
                      )
                    ]),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        "Donec sed odio dui. Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
                      )
                    ])
                  ]
                ),
                _c(
                  "b-media",
                  {
                    staticClass: "mb-4",
                    attrs: { "vertical-align": "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "aside",
                        fn: function() {
                          return [
                            _c("b-img", {
                              attrs: {
                                src: require("@/assets/images/users/user-5.jpg"),
                                rounded: "",
                                height: "64",
                                alt: "Generic placeholder image"
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c("h5", { staticClass: "mt-0 font-16" }, [
                      _vm._v("Center-aligned media")
                    ]),
                    _c("p", [
                      _vm._v(
                        "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus."
                      )
                    ]),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        "Donec sed odio dui. Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
                      )
                    ])
                  ]
                ),
                _c(
                  "b-media",
                  {
                    staticClass: "mb-4",
                    attrs: { "vertical-align": "end" },
                    scopedSlots: _vm._u([
                      {
                        key: "aside",
                        fn: function() {
                          return [
                            _c("b-img", {
                              attrs: {
                                src: require("@/assets/images/users/user-1.jpg"),
                                rounded: "",
                                height: "64",
                                alt: "Generic placeholder image"
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c("h5", { staticClass: "mt-0 font-16" }, [
                      _vm._v("Bottom-aligned media")
                    ]),
                    _c("p", [
                      _vm._v(
                        "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus."
                      )
                    ]),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        "Donec sed odio dui. Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }