import * as echarts from "echarts";

const sparklineChartData = {
  sparkChart1: [6, 2, 8, 4, 3, 8, 1, 3, 6, 5, 9, 2, 8, 1, 4, 8, 9, 8, 2, 1],
  sparkChart2: [6, 2, 8, 4, -3, 8, 1, -3, 6, -5, 9, 2, -8, 1, 4, 8, 9, 8, 2, 1],
  sparkChart3: [6, 2, 8, 4, 3, 8, 1, 3, 6, 5, 9, 2, 8, 1, 4, 8, 9, 8, 2, 1],
  reflineStyle: {
    strokeOpacity: 1,
    strokeDasharray: "3, 3",
  },
  style: {
    stroke: "#02a499",
    fill: "#02a499",
  },
};

const salesDonutChart = {
  data: {
    series: [54, 28, 17],
    labels: [1, 2, 3],
  },
  options: {
    donut: true,
    showLabel: false,
  },
};

const ageBarChart = {
  data: {
    series: [[10, 400, 300, 700, 155, 110, 30]],
    labels: ["17 down", "18-24", "25-29", "30-39", "40-49", "50 up", "un know"],
  },
  options: {
    seriesBarDistance: 10,
    reverseData: true,
    horizontalBars: true,
    axisY: {
      offset: 70,
    },
    height: 450,
  },
};

const radialBarChart = {
  chartOptions: {
    plotOptions: {
      radialBar: {
        hollow: {
          size: "45%",
        },
        dataLabels: {
          value: {
            // show: false
          },
          name: {
            show: false,
          },
        },
      },
    },
    colors: ["#02a499"],
  },
};

// 烟弹库存数据
const inventoryCartridgeBar = {
  chartOptions: {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "65%",
      },
    },
    dataLabels: {
      enabled: false,
      //enabledOnSeries: [3]
    },
    stroke: {
      width: [0, 2, 4, 6],
      curve: "smooth",
    },
    colors: ["#02a499", "#38a4f8", "#dee2e6", "#aaaaff"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    legend: {
      offsetY: 5,
    },
    yaxis: {
      title: {
        text: " (thousands)",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f3fa",
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter(y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;
        },
      },
    },
  },
  series: [
    {
      name: "Active",
      type: "column",
      data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 31],
    },
    {
      name: "Activated",
      type: "column",
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 28, 29, 34],
    },
    {
      name: "Consume",
      type: "column",
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 35, 29, 37],
    },
  ],
};

// 口味排行
const flavorData = [
  { ranking: 1, flavor: "Apple", pacent: "21.21%" },
  { ranking: 2, flavor: "Orange", pacent: "19.21%" },
  { ranking: 3, flavor: "Grape", pacent: "18.99%" },
  { ranking: 4, flavor: "Coke", pacent: "17.55%" },
  { ranking: 5, flavor: "Mango", pacent: "2.31%" },
];

// 最近活跃用户
const activeUsersData = [
  {
    id: "14256",
    image: require("@/assets/images/users/avatar-2.jpg"),
    name: "Philip Smead",
    date: "5/5/2020",
    amount: "94",
    status: "Active",
  },
  {
    id: "14257",
    image: require("@/assets/images/users/avatar-3.jpg"),
    name: "Brent Shipley",
    date: "1/5/2020",
    amount: "112",
    status: "New",
  },
  {
    id: "14258",
    image: require("@/assets/images/users/avatar-4.jpg"),
    name: "Robert Sitton",
    date: "27/4/2020",
    amount: "116",
    status: "Active",
  },
  {
    id: "14259",
    image: require("@/assets/images/users/avatar-5.jpg"),
    name: "Alberto Jackson",
    date: "25/4/2020",
    amount: "109",
    status: "New",
  },
  {
    id: "14260",
    image: require("@/assets/images/users/avatar-6.jpg"),
    name: "David Sanchez",
    date: "15/4/2020",
    amount: "94",
    status: "Active",
  },
  {
    id: "14261",
    image: require("@/assets/images/users/avatar-7.jpg"),
    name: "Philip Smead",
    date: "15/4/2020",
    amount: "94",
    status: "New",
  },
  {
    id: "14258",
    image: require("@/assets/images/users/avatar-8.jpg"),
    name: "Robert Sitton",
    date: "27/4/2020",
    amount: "116",
    status: "Active",
  },
  {
    id: "14259",
    image: require("@/assets/images/users/avatar-4.jpg"),
    name: "Alberto Jackson",
    date: "25/4/2020",
    amount: "109",
    status: "Active",
  },
  {
    id: "14260",
    image: require("@/assets/images/users/avatar-6.jpg"),
    name: "David Sanchez",
    date: "15/4/2020",
    amount: "94",
    status: "Active",
  },
  {
    id: "14261",
    image: require("@/assets/images/users/avatar-7.jpg"),
    name: "Philip Smead",
    date: "15/4/2020",
    amount: "94",
    status: "New",
  },
];

// 烟弹未激活数量折线图
const selectInactiveIcon = {
  series: [
    {
      name: "count",
      data: [],
    },
  ],
  chartOptions: {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: { categories: [] },
  },
};

// 年龄统计
const ageConsumptionData = {
  title: {
    text: "Age Consumption Distribution",
    textStyle: {
      color: "#fff",
      fontWeight: "normal",
      fontSize: 14,
    },
    top: 10,
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    data: ["21-25", "26-30", "31-35", "36-40", "40-50", "over50"],
    axisLine: {
      lineStyle: {
        color: "#fff",
      },
    },
    axisLabel: {
      textStyle: {
        color: "#fff",
        fontSize: 12,
        padding: [10, 0, 0, 0],
      },
      interval: 0,
      rotate: 30,
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: "#fff",
      },
    },
    z: 10,
  },
  yAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#fff",
      },
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      textStyle: {
        color: "#999",
      },
    },
    splitLine: {
      show: false,
    },
  },
  // dataZoom: [
  //   {
  //     type: "inside",
  //   },
  // ],
  series: [
    {
      type: "bar",
      itemStyle: {
        emphasis: {
          barBorderRadius: [10, 10, 10, 10],
        },
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius: [10, 10, 1, 1],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "rgba(53, 139, 210,1)" },
            { offset: 0.3, color: "#188df0" },
            { offset: 1, color: "rgba(83, 93, 174,1)" },
          ]),
        },
      },
      data: [2500, 3100, 4200, 2800, 4500, 1600],
      barWidth: 10,
    },
  ],
};


// 近30天的品牌统计
const brandStatistics = {
  title: {
    text: "New brands added in the past 30 days",
    textStyle: {
      color: "#fff",
      fontWeight: "normal",
      fontSize: 14,
    },
    top: 10,
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    data: ["1-5", "6-10", "11-15", "16-20", "21-25", "26-30"],
    axisLine: {
      lineStyle: {
        color: "#fff",
      },
    },
    axisLabel: {
      textStyle: {
        color: "#fff",
        fontSize: 12,
        padding: [10, 0, 0, 0],
      },
      interval: 0,
      rotate: 30,
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: "#fff",
      },
    },
    z: 10,
  },
  yAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#fff",
      },
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      textStyle: {
        color: "#fff",
      },
    },
    splitLine: {
      show: false,
    },
  },
  // dataZoom: [
  //   {
  //     type: "inside",
  //   },
  // ],
  series: [
    {
      type: "bar",
      itemStyle: {
        emphasis: {
          barBorderRadius: [10, 10, 10, 10],
        },
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius: [10, 10, 1, 1],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "rgba(53, 139, 210,1)" },
            { offset: 0.3, color: "#188df0" },
            { offset: 1, color: "rgba(83, 93, 174,1)" },
          ]),
        },
      },
      data: [2500, 3100, 4200, 2800, 4500, 1600],
      barWidth: 10,
    },
  ],
};

//近30天的使用频次
const frequencyStatistics = {
  title: {
    text: "Frequency of use in the past 30 days",
    textStyle: {
      color: "#fff",
      fontWeight: "normal",
      fontSize: 14,
    },
    top: 10,
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    data: ["1-5", "6-10", "11-15", "16-20", "21-25", "26-30"],
    axisLine: {
      lineStyle: {
        color: "#fff",
      },
    },
    axisLabel: {
      textStyle: {
        color: "#fff",
        fontSize: 12,
        padding: [10, 0, 0, 0],
      },
      interval: 0,
      rotate: 30,
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: "#fff",
      },
    },
    z: 10,
  },
  yAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#fff",
      },
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      textStyle: {
        color: "#999",
      },
    },
    splitLine: {
      show: false,
    },
  },
  // dataZoom: [
  //   {
  //     type: "inside",
  //   },
  // ],
  series: [
    {
      type: "bar",
      itemStyle: {
        emphasis: {
          barBorderRadius: [10, 10, 10, 10],
        },
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius: [10, 10, 1, 1],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "rgba(53, 139, 210,1)" },
            { offset: 0.3, color: "#188df0" },
            { offset: 1, color: "rgba(83, 93, 174,1)" },
          ]),
        },
      },
      data: [2500, 3100, 4200, 2800, 4500, 1600],
      barWidth: 10,
    },
  ],
};
const frequencyStatisticsToCurve = {
  title: {
    text: "Frequency of use in the past 30 days",
    textStyle: {
      color: "#fff",
      fontWeight: "normal",
      fontSize: 14,
    },
    top: 10,
  },
  tooltip: {
    trigger: 'axis'
  },
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    axisLine: {
      lineStyle: {
        color: "#fff",
      },
    },
  },
  yAxis: {
    type: 'value',
    lineStyle: {
      color: "#fff",
    },
    axisLabel: {
      textStyle: {
        color: "#fff",
      },
    },
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320],
      type: 'line',
      smooth: true
    }
  ],
  color: "#188df0",
};
//近30天的口味使用频次
const flavorStatistics = {
  title: {
    text: "New flavor added in the past 30 days",
    textStyle: {
      color: "#fff",
      fontWeight: "normal",
      fontSize: 14,
    },
    top: 10,
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    data: ["1-5", "6-10", "11-15", "16-20", "21-25", "26-30"],
    axisLine: {
      lineStyle: {
        color: "#fff",
      },
    },
    axisLabel: {
      textStyle: {
        color: "#fff",
        fontSize: 12,
        padding: [10, 0, 0, 0],
      },
      interval: 0,
      rotate: 30,
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: "#fff",
      },
    },
    z: 10,
  },
  yAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#fff",
      },
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      textStyle: {
        color: "#fff",
      },
    },
    splitLine: {
      show: false,
    },
  },
  // dataZoom: [
  //   {
  //     type: "inside",
  //   },
  // ],
  series: [
    {
      type: "bar",
      itemStyle: {
        emphasis: {
          barBorderRadius: [10, 10, 10, 10],
        },
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius: [10, 10, 1, 1],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "rgba(53, 139, 210,1)" },
            { offset: 0.3, color: "#188df0" },
            { offset: 1, color: "rgba(83, 93, 174,1)" },
          ]),
        },
      },
      data: [2500, 3100, 4200, 2800, 4500, 1600],
      barWidth: 10,
    },
  ],
};

//近30天的曲线使用频次
const curvesStatistics = {
  title: {
    text: "New curves added in the past 30 days",
    textStyle: {
      color: "#fff",
      fontWeight: "normal",
      fontSize: 14,
    },
    top: 10,
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    data: ["1-5", "6-10", "11-15", "16-20", "21-25", "26-30"],
    axisLine: {
      lineStyle: {
        color: "#fff",
      },
    },
    axisLabel: {
      textStyle: {
        color: "#fff",
        fontSize: 12,
        padding: [10, 0, 0, 0],
      },
      interval: 0,
      rotate: 30,
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: "#fff",
      },
    },
    z: 10,
  },
  yAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#fff",
      },
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      textStyle: {
        color: "#fff",
      },
    },
    splitLine: {
      show: false,
    },
  },
  // dataZoom: [
  //   {
  //     type: "inside",
  //   },
  // ],
  series: [
    {
      type: "bar",
      itemStyle: {
        emphasis: {
          barBorderRadius: [10, 10, 10, 10],
        },
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius: [10, 10, 1, 1],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "rgba(53, 139, 210,1)" },
            { offset: 0.3, color: "#188df0" },
            { offset: 1, color: "rgba(83, 93, 174,1)" },
          ]),
        },
      },
      data: [2500, 3100, 4200, 2800, 4500, 1600],
      barWidth: 10,
    },
  ],
};
// 品牌消费Top5
const consumeOfBrandData = {
  title: {
    text: "Consume of Brand Top5",
    textStyle: {
      color: "#fff",
      fontWeight: "normal",
      fontSize: 14,
    },
    top: 10,
  },
  // tooltip: {
  //   trigger: "axis",
  //   axisPointer: {
  //     type: "shadow",
  //   },
  // },
  grid: {
    left: "1%",
    right: "10%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
    splitLine: {
      //去掉网格线
      show: false,
    },
    type: "value",
    boundaryGap: [0, 0.01],
    axisLabel: {
      //坐标轴刻度标签的相关设置
      //  rotate:'45',//坐标轴文字旋转角度
      show: false,
    },
    z: 10,
  },
  yAxis: {
    type: "category",
    inverse: true,
    data: ["Brand1", "Brand2", "Brand3", "Brand4", "Brand5"],
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: "#999",
        fontSize: 12,
      },
      // 调整左侧文字的3个属性，缺一不可
      // verticalAlign: "bottom",
      // align: "right",
      //调整文字上右下左
      // padding: [0, -50, 5, 0],
    },
  },
  series: [
    {
      type: "bar",
      itemStyle: {
        emphasis: {
          barBorderRadius: [10, 10, 10, 10],
        },
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius: [10, 10, 10, 10],
          color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            { offset: 0, color: "rgba(53, 139, 210,1)" },
            { offset: 0.3, color: "#188df0" },
            { offset: 1, color: "rgba(83, 93, 174,1)" },
          ]),
        },
      },
      data: [19236, 15632, 14235, 12876, 11980],
      barWidth: 10,
      label: {
        normal: {
          show: true, //显示数字
          position: "right",
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          // verticalAlign: "bottom",
          // align: "right",
          // padding: [0, 10, 5, 0],
        },
      },
    },
  ],
};

// 消费统计1
const consumptionOneData = {
  title: {
    text: "Consumption Statistics",
    textStyle: {
      color: "#fff",
      fontWeight: "normal",
      fontSize: 14,
    },
    top: 10,
  },
  series: [
    {
      type: "gauge",
      radius: "70%",
      axisLine: {
        lineStyle: {
          width: 16,
          color: [
            [0.3, "rgba(239, 99, 106,1)"],
            [0.7, "rgba(98, 110, 212,1)"],
            [1, "rgba(2, 164, 153,1)"],
          ],
        },
      },
      pointer: {
        show: false,
      },
      axisTick: {
        distance: -30,
        length: 4,
        lineStyle: {
          color: "rgba(37, 44, 59,1)",
          width: 1,
        },
      },
      splitLine: {
        distance: -30,
        length: 30,
        lineStyle: {
          color: "rgba(37, 44, 59,1)",
          width: 4,
        },
      },
      axisLabel: {
        show: false,
      },
      title: {
        show: true,
        color: "#999",
        fontSize: 12,
        offsetCenter: [0, "100%"],
      },
      detail: {
        valueAnimation: true,
        // formatter: '{value} km/h',
        color: "#fff",
        fontSize: 26,
        offsetCenter: [0, 0],
      },
      data: [],
    },
  ],
};

//用户使用记录统计
const userRecordsOneData = {
  title: {
    text: "Total Curve Record",
    textStyle: {
      color: "#fff",
      fontWeight: "normal",
      fontSize: 14,
    },
    top: 10,
  },
  series: [
    {
      type: "gauge",
      radius: "70%",
      axisLine: {
        lineStyle: {
          width: 16,
          color: [
            [0.3, "rgba(239, 99, 106,1)"],
            [0.7, "rgba(98, 110, 212,1)"],
            [1, "rgba(2, 164, 153,1)"],
          ],
        },
      },
      pointer: {
        show: false,
      },
      axisTick: {
        distance: -30,
        length: 4,
        lineStyle: {
          color: "rgba(37, 44, 59,1)",
          width: 1,
        },
      },
      splitLine: {
        distance: -30,
        length: 30,
        lineStyle: {
          color: "rgba(37, 44, 59,1)",
          width: 4,
        },
      },
      axisLabel: {
        show: false,
      },
      title: {
        show: true,
        color: "#999",
        fontSize: 12,
        offsetCenter: [0, "100%"],
      },
      detail: {
        valueAnimation: true,
        // formatter: '{value} km/h',
        color: "#fff",
        fontSize: 26,
        offsetCenter: [0, 0],
      },
      data: [],
    },
  ],
};


// 消费统计2
const consumptionTwoData = {
  series: [
    {
      type: "gauge",
      radius: "66%",
      axisLine: {
        lineStyle: {
          width: 10,
          color: [
            [0.3, "rgba(239, 99, 106,1)"],
            [0.7, "rgba(98, 110, 212,1)"],
            [1, "rgba(2, 164, 153,1)"],
          ],
        },
      },
      pointer: {
        show: false,
      },
      axisTick: {
        distance: -30,
        length: 2,
        lineStyle: {
          color: "rgba(37, 44, 59,1)",
          width: 1,
        },
      },
      splitLine: {
        distance: -30,
        length: 30,
        lineStyle: {
          color: "rgba(37, 44, 59,1)",
          width: 2,
        },
      },
      axisLabel: {
        show: false,
      },
      title: {
        show: true,
        color: "#999",
        fontSize: 12,
        offsetCenter: [0, "100%"],
      },
      detail: {
        valueAnimation: true,
        // formatter: '{value} km/h',
        color: "#fff",
        fontSize: 20,
        offsetCenter: [0, 0],
      },
      data: [
        {
          value: 8596320,
          name: "Actived Amount",
        },
      ],
    },
  ],
};

// 消费统计3
const consumptionThreeData = {
  series: [
    {
      type: "gauge",
      radius: "66%",
      axisLine: {
        lineStyle: {
          width: 10,
          color: [
            [0.3, "rgba(239, 99, 106,1)"],
            [0.7, "rgba(98, 110, 212,1)"],
            [1, "rgba(2, 164, 153,1)"],
          ],
        },
      },
      pointer: {
        show: false,
      },
      axisTick: {
        distance: -30,
        length: 2,
        lineStyle: {
          color: "rgba(37, 44, 59,1)",
          width: 1,
        },
      },
      splitLine: {
        distance: -30,
        length: 30,
        lineStyle: {
          color: "rgba(37, 44, 59,1)",
          width: 2,
        },
      },
      axisLabel: {
        show: false,
      },
      title: {
        show: true,
        color: "#999",
        fontSize: 12,
        offsetCenter: [0, "100%"],
      },
      detail: {
        valueAnimation: true,
        // formatter: '{value} km/h',
        color: "#fff",
        fontSize: 20,
        offsetCenter: [0, 0],
      },
      data: [
        {
          value: 29013000,
          name: "Total Cartridge",
        },
      ],
    },
  ],
};

// 批量消费
const batchConsumeData = {
  title: {
    text: "Batch Consume(pcs)",
    textStyle: {
      color: "#fff",
      fontWeight: "normal",
      fontSize: 14,
    },
    top: 10,
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  legend: {
    data: ["Remaining", "Activated", "Consumed"],
    textStyle: {
      color: "#999",
      fontSize: 12,
    },
    right: 0,
    orient: "vertical",
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    y: "25%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    data: ["20114001", "20114002", "20114003", "20114004", "20114005"],
    axisLine: {
      lineStyle: {
        color: "#fff",
      },
    },
    axisLabel: {
      textStyle: {
        color: "#fff",
        fontSize: 12,
        padding: [10, 0, 0, 5],
      },
      interval: 0,
      rotate: 45,
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: "#fff",
      },
    },
    z: 10,
  },
  yAxis: {
    axisLine: {
      lineStyle: {
        color: "#fff",
      },
    },
    type: "value",
    axisTick: {
      show: false,
    },
    axisLabel: {
      textStyle: {
        color: "#999",
      },
    },
    splitLine: {
      show: false,
    },
  },
  series: [
    {
      name: "Consumed",
      type: "bar",
      barWidth: 14,
      itemStyle: {
        normal: {
          color: "rgba(198, 88, 96,1)",
        },
      },
      stack: "batch",
      data: [1010, 1320, 1010, 1340, 900],
    },
    {
      name: "Actived",
      type: "bar",
      barWidth: 14,
      itemStyle: {
        normal: {
          color: "rgba(9, 140, 134,1)",
        },
      },
      stack: "batch",
      data: [1200, 300, 1450, 600, 1290],
    },
    {
      name: "Remaining",
      type: "bar",
      barWidth: 14,
      itemStyle: {
        emphasis: {
          barBorderRadius: [10, 10, 10, 10],
        },
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius: [10, 10, 1, 1],
          color: "rgba(86, 97, 181,1)",
        },
      },
      stack: "batch",
      data: [1500, 400, 2000, 1540, 500],
    },
  ],
};

// 美国地图
const usaMapData = {
  tooltip: {
    trigger: "item",
    showDelay: 0,
    transitionDuration: 0.2,
    formatter: function (params) {
      var value = (params.value + "").split(".");
      value = value[0].replace(/(\d{1,3})(?=(?:\d{3})+(?!\d))/g, "$1,");
      var consumedToday = (params.data.consumedToday + "").split(".");
      consumedToday = consumedToday[0].replace(
        /(\d{1,3})(?=(?:\d{3})+(?!\d))/g,
        "$1,"
      );
      var activedToday = (params.data.activedToday + "").split(".");
      activedToday = activedToday[0].replace(
        /(\d{1,3})(?=(?:\d{3})+(?!\d))/g,
        "$1,"
      );
      return (
        params.seriesName +
        "<br/>" +
        params.name +
        "<br/>" +
        "Consumed Total: " +
        value +
        "<br/>" +
        "Consumed Today: " +
        consumedToday +
        "<br/>" +
        "Activated Today: " +
        activedToday
      );
    },
  },
  visualMap: {
    left: "right",
    min: 0,
    max: 10000,
    inRange: {
      color: [
        // "#313695",
        "#4575b4",
        "#74add1",
        "#abd9e9",
        "#e0f3f8",
        "#ffffbf",
        "#fee090",
        "#fdae61",
        "#f46d43",
        "#d73027",
        // "#a50026",
      ],
    },
    text: ["High", "Low"], // 文本，默认为数值文本
    calculable: true,
    textStyle: {
      color: "#999",
    },
  },
  toolbox: {
    show: false,
    // orient: 'vertical',
    left: "left",
    top: "top",
    feature: {
      dataView: { readOnly: false },
      restore: {},
      saveAsImage: {},
    },
  },
  series: [
    {
      name: "USA",
      type: "map",
      roam: false,
      map: "USA",
      emphasis: {
        label: {
          show: true,
        },
      },
      // 文本位置修正
      textFixed: {
        Alaska: [20, -20],
      },
      data: [
        { name: "Alabama", value: 4822023, text: 200 },
        { name: "Alaska", value: 731449, text: 300 },
        { name: "Arizona", value: 6553255, text: 400 },
        { name: "Arkansas", value: 2949131, text: 500 },
        { name: "California", value: 38041430, text: 601 },
        { name: "Colorado", value: 5187582, text: 602 },
        { name: "Connecticut", value: 3590347, text: 603 },
        { name: "Delaware", value: 917092, text: 604 },
        { name: "District of Columbia", value: 632323, text: 600 },
        { name: "Florida", value: 19317568, text: 600 },
        { name: "Georgia", value: 9919945, text: 600 },
        { name: "Hawaii", value: 1392313, text: 600 },
        { name: "Idaho", value: 1595728, text: 600 },
        { name: "Illinois", value: 12875255, text: 600 },
        { name: "Indiana", value: 6537334, text: 600 },
        { name: "Iowa", value: 3074186, text: 600 },
        { name: "Kansas", value: 2885905, text: 600 },
        { name: "Kentucky", value: 4380415, text: 600 },
        { name: "Louisiana", value: 4601893, text: 600 },
        { name: "Maine", value: 1329192, text: 600 },
        { name: "Maryland", value: 5884563, text: 600 },
        { name: "Massachusetts", value: 6646144, text: 600 },
        { name: "Michigan", value: 9883360, text: 600 },
        { name: "Minnesota", value: 5379139, text: 600 },
        { name: "Mississippi", value: 2984926, text: 600 },
        { name: "Missouri", value: 6021988, text: 600 },
        { name: "Montana", value: 1005141, text: 600 },
        { name: "Nebraska", value: 1855525, text: 600 },
        { name: "Nevada", value: 2758931, text: 600 },
        { name: "New Hampshire", value: 1320718, text: 600 },
        { name: "New Jersey", value: 8864590, text: 600 },
        { name: "New Mexico", value: 2085538, text: 600 },
        { name: "New York", value: 19570261, text: 600 },
        { name: "North Carolina", value: 9752073, text: 600 },
        { name: "North Dakota", value: 699628, text: 600 },
        { name: "Ohio", value: 11544225, text: 600 },
        { name: "Oklahoma", value: 3814820, text: 600 },
        { name: "Oregon", value: 3899353, text: 600 },
        { name: "Pennsylvania", value: 12763536, text: 600 },
        { name: "Rhode Island", value: 1050292, text: 600 },
        { name: "South Carolina", value: 4723723, text: 600 },
        { name: "South Dakota", value: 833354, text: 600 },
        { name: "Tennessee", value: 6456243, text: 600 },
        { name: "Texas", value: 26059203, text: 600 },
        { name: "Utah", value: 2855287, text: 600 },
        { name: "Vermont", value: 626011, text: 600 },
        { name: "Virginia", value: 8185867, text: 600 },
        { name: "Washington", value: 6897012, text: 600 },
        { name: "West Virginia", value: 1855413, text: 600 },
        { name: "Wisconsin", value: 5726398, text: 600 },
        { name: "Wyoming", value: 576412, text: 600 },
        { name: "Puerto Rico", value: 3667084, text: 600 },
      ],
    },
  ],
};



// 美国地图
const ikrusherUsaMapData = {
  tooltip: {
    trigger: "item",
    showDelay: 0,
    transitionDuration: 0.2,
    formatter: function (params) {
      var value = (params.value + "").split(".");
      value = value[0].replace(/(\d{1,3})(?=(?:\d{3})+(?!\d))/g, "$1,");
      var consumedToday = (params.data.consumedToday + "").split(".");
      consumedToday = consumedToday[0].replace(
        /(\d{1,3})(?=(?:\d{3})+(?!\d))/g,
        "$1,"
      );
      var activedToday = (params.data.activedToday + "").split(".");
      activedToday = activedToday[0].replace(
        /(\d{1,3})(?=(?:\d{3})+(?!\d))/g,
        "$1,"
      );
      return (
        params.seriesName +
        "<br/>" +
        params.name +
        "<br/>" +
        "Activated Total: " +
        value +
        "<br/>" +
        "Activated Today: " +
        activedToday
      );
    },
  },
  visualMap: {
    left: "right",
    min: 0,
    max: 10000,
    inRange: {
      color: [
        // "#313695",
        "#4575b4",
        "#74add1",
        "#abd9e9",
        "#e0f3f8",
        "#ffffbf",
        "#fee090",
        "#fdae61",
        "#f46d43",
        "#d73027",
        // "#a50026",
      ],
    },
    text: ["High", "Low"], // 文本，默认为数值文本
    calculable: true,
    textStyle: {
      color: "#999",
    },
  },
  toolbox: {
    show: false,
    // orient: 'vertical',
    left: "left",
    top: "top",
    feature: {
      dataView: { readOnly: false },
      restore: {},
      saveAsImage: {},
    },
  },
  series: [
    {
      name: "USA",
      type: "map",
      roam: false,
      map: "USA",
      emphasis: {
        label: {
          show: true,
        },
      },
      // 文本位置修正
      textFixed: {
        Alaska: [20, -20],
      },
      data: [
        { name: "Alabama", value: 4822023, text: 200 },
        { name: "Alaska", value: 731449, text: 300 },
        { name: "Arizona", value: 6553255, text: 400 },
        { name: "Arkansas", value: 2949131, text: 500 },
        { name: "California", value: 38041430, text: 601 },
        { name: "Colorado", value: 5187582, text: 602 },
        { name: "Connecticut", value: 3590347, text: 603 },
        { name: "Delaware", value: 917092, text: 604 },
        { name: "District of Columbia", value: 632323, text: 600 },
        { name: "Florida", value: 19317568, text: 600 },
        { name: "Georgia", value: 9919945, text: 600 },
        { name: "Hawaii", value: 1392313, text: 600 },
        { name: "Idaho", value: 1595728, text: 600 },
        { name: "Illinois", value: 12875255, text: 600 },
        { name: "Indiana", value: 6537334, text: 600 },
        { name: "Iowa", value: 3074186, text: 600 },
        { name: "Kansas", value: 2885905, text: 600 },
        { name: "Kentucky", value: 4380415, text: 600 },
        { name: "Louisiana", value: 4601893, text: 600 },
        { name: "Maine", value: 1329192, text: 600 },
        { name: "Maryland", value: 5884563, text: 600 },
        { name: "Massachusetts", value: 6646144, text: 600 },
        { name: "Michigan", value: 9883360, text: 600 },
        { name: "Minnesota", value: 5379139, text: 600 },
        { name: "Mississippi", value: 2984926, text: 600 },
        { name: "Missouri", value: 6021988, text: 600 },
        { name: "Montana", value: 1005141, text: 600 },
        { name: "Nebraska", value: 1855525, text: 600 },
        { name: "Nevada", value: 2758931, text: 600 },
        { name: "New Hampshire", value: 1320718, text: 600 },
        { name: "New Jersey", value: 8864590, text: 600 },
        { name: "New Mexico", value: 2085538, text: 600 },
        { name: "New York", value: 19570261, text: 600 },
        { name: "North Carolina", value: 9752073, text: 600 },
        { name: "North Dakota", value: 699628, text: 600 },
        { name: "Ohio", value: 11544225, text: 600 },
        { name: "Oklahoma", value: 3814820, text: 600 },
        { name: "Oregon", value: 3899353, text: 600 },
        { name: "Pennsylvania", value: 12763536, text: 600 },
        { name: "Rhode Island", value: 1050292, text: 600 },
        { name: "South Carolina", value: 4723723, text: 600 },
        { name: "South Dakota", value: 833354, text: 600 },
        { name: "Tennessee", value: 6456243, text: 600 },
        { name: "Texas", value: 26059203, text: 600 },
        { name: "Utah", value: 2855287, text: 600 },
        { name: "Vermont", value: 626011, text: 600 },
        { name: "Virginia", value: 8185867, text: 600 },
        { name: "Washington", value: 6897012, text: 600 },
        { name: "West Virginia", value: 1855413, text: 600 },
        { name: "Wisconsin", value: 5726398, text: 600 },
        { name: "Wyoming", value: 576412, text: 600 },
        { name: "Puerto Rico", value: 3667084, text: 600 },
      ],
    },
  ],
};
// 导出
export {
  sparklineChartData,
  salesDonutChart,
  radialBarChart,
  ageBarChart,
  inventoryCartridgeBar,
  flavorData,
  activeUsersData,
  selectInactiveIcon,
  ageConsumptionData,
  consumeOfBrandData,
  consumptionOneData,
  consumptionTwoData,
  consumptionThreeData,
  batchConsumeData,
  usaMapData,
  brandStatistics,
  frequencyStatistics,
  flavorStatistics,
  curvesStatistics,
  userRecordsOneData,
  ikrusherUsaMapData,
  frequencyStatisticsToCurve,
};
