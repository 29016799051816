var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("User Info")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { cols: "3" } },
                [
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Avatar:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm.info.picture
                        ? _c("img", {
                            staticClass: "avatar-xs rounded-circle mr-2",
                            attrs: { src: _vm.info.picture, alt: "" }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("User ID:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.id))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Sex:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.sex))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Registration Time:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.createTime))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Name:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.nickName))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Birth day:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.dateOfBirth))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Registration Area:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.registrationArea))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Email:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.email))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Status:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(
                        _vm._s(_vm.info.status == 1 ? "Enabled" : "Disabled")
                      )
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Invitation Code:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.invitationCode))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("History Products")])
                ])
              ])
            ],
            1
          ),
          _c("br"),
          _c("b-table", {
            ref: "tag_table",
            attrs: {
              id: "tag_table",
              "head-variant": "light",
              striped: "",
              fields: _vm.tagFields,
              items: _vm.tag_provider,
              "per-page": _vm.device.pageSize,
              "current-page": _vm.device.pageNum,
              busy: _vm.device_isBusy,
              "show-empty": "",
              "empty-text": "No records"
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-danger my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle" }),
                        _c("strong", [_vm._v("Loading...")])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "cell(index)",
                fn: function(data) {
                  return [
                    _vm._v(
                      _vm._s(
                        data.index +
                          (_vm.device.pageNum - 1) * _vm.device.pageSize +
                          1
                      )
                    )
                  ]
                }
              },
              {
                key: "cell(actions)",
                fn: function(row) {
                  return [
                    _c(
                      "b-button-group",
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "has",
                                rawName: "v-has",
                                value: "userAccounts::Details",
                                expression: "'userAccounts::Details'"
                              }
                            ],
                            staticClass: "mr-1",
                            attrs: { size: "sm", variant: "info" },
                            on: {
                              click: function($event) {
                                return _vm.show(
                                  row.item,
                                  row.index,
                                  $event.target
                                )
                              }
                            }
                          },
                          [_vm._v("Details")]
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(status)",
                fn: function(row) {
                  return [
                    row.item.status == 2
                      ? _c("p", { staticClass: "clientStyle" }, [
                          _vm._v("Unactivated")
                        ])
                      : _vm._e(),
                    row.item.status == 1
                      ? _c("p", [_vm._v("Activated")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  {
                    staticClass:
                      "dataTables_paginate paging_simple_numbers float-right"
                  },
                  [
                    _vm.device_totalRows
                      ? _c(
                          "ul",
                          { staticClass: "pagination mb-0" },
                          [
                            _c("li", [
                              _c(
                                "p",
                                {
                                  staticClass: "pagination mb-0",
                                  staticStyle: {
                                    "margin-top": "7px",
                                    "margin-right": "20px"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.device_totalRows) +
                                      " results "
                                  )
                                ]
                              )
                            ]),
                            _c("b-pagination", {
                              attrs: {
                                "per-page": 10,
                                "total-rows": _vm.device_totalRows,
                                "aria-controls": "device_table"
                              },
                              model: {
                                value: _vm.device.pageNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.device, "pageNum", $$v)
                                },
                                expression: "device.pageNum"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }