<template>
  <Layout>
    <div class="page" id="fullScreenpage">
      <!-- <b-card class="title">
        <img src="@/assets/title.jpg" alt="" style="width:100%" />
        <p class="headline">CONSUMPTION STATISTICAL ANALYSIS</p>
      </b-card> -->
      <div class="title">
        <p class="headline">OMNI DATA CENTER</p>
        <i
          v-if="!btnText"
          class="mdi mdi-fullscreen fullscreen"
          @click="requestFullScreen"
          >Full Screen</i
        >
        <i v-else class="mdi mdi-fullscreen fullscreen" @click="exitFullscreen"
          >Exit Full Screen</i
        >
      </div>
      <b-row style="padding: 0 1vh">
        <b-col xl="3" style="height: 100%">
          <!-- 使用频次统计 -->
          <!-- <div
            class="bodyCard"
            id="frequencyStatistics"
            style="width: 100%; height: calc(26vh); margin-bottom: 1.2vh"
          ></div> -->
          <div
            class="bodyCard"
            id="frequencyStatisticsToCurve"
            style="width: 100%; height: calc(26vh); margin-bottom: 1.2vh"
          ></div>
          <!-- 品牌添加统计 -->
          <div
            class="bodyCard"
            id="brandStatistics"
            style="width: 100%; height: calc(26vh)"
          ></div>
        </b-col>
        <!-- 地图  -->
        <b-col xl="6">
          <div class="bodyCard" style="height: 100%">
            <div id="usaMap" style="width: 100%; height: calc(40vh)"></div>
            <div
              style="
                padding: calc(0.9vh);
                border-top: 1px solid rgba(44, 50, 77, 1);
              "
            >
              <div
                style="
                  display: inline-block;
                  text-align: center;
                  border-right: 1px solid rgba(44, 50, 77, 1);
                "
                class="col-md-4"
              >
                <p style="font-size: 3.4vh; color: #fff; margin-bottom: 0">
                  {{ total }}
                </p>
                <p style="font-size: 1.6vh; color: #999">Total Product</p>
              </div>
              <div
                style="
                  display: inline-block;
                  text-align: center;
                  border-right: 1px solid rgba(44, 50, 77, 1);
                "
                class="col-md-4"
              >
                <p style="font-size: 3.4vh; color: #fff; margin-bottom: 0">
                  {{ activationTotal }}
                </p>
                <p style="font-size: 1.6vh; color: #999">Activated Total</p>
              </div>
              <div
                style="display: inline-block; text-align: center"
                class="col-md-4"
              >
                <p style="font-size: 3.4vh; color: #fff; margin-bottom: 0">
                  {{ activationToday }}
                </p>
                <p style="font-size: 1.6vh; color: #999">Activated Today</p>
              </div>
            </div>
          </div>
        </b-col>
        <!-- 按设备类型统计(使用的次数) -->
        <b-col xl="3">
          <div class="bodyCard" style="height: 100%">
            <div
              id="consumptionOne"
              style="width: 100%; height: calc(32vh)"
            ></div>
            <!-- <div style="padding:0 10%;"> -->
            <span
              id="consumptionTwo"
              style="display: inline-block; width: 50%; height: calc(18vh)"
            ></span>
            <span
              id="consumptionThree"
              style="display: inline-block; width: 50%; height: calc(18vh)"
            ></span>
            <!-- </div> -->
          </div>
        </b-col>
      </b-row>
      <b-row style="padding: 1vh">
        <!-- 口味统计 -->
        <b-col xl="3">
          <div
            class="bodyCard"
            id="flavorStatistics"
            style="width: 100%; height: calc(34vh); margin-bottom: 1.2vh"
          ></div>
        </b-col>
        <!-- 激活用户 -->
        <b-col xl="6">
          <div
            class="bodyCard"
            style="width: 100%; height: calc(34vh); overflow: hidden"
          >
            <p
              style="
                color: #fff;
                margin: 10px 0 0 5px;
                font-size: 14px;
                font-family: sans-serif;
              "
            >
              User's Activities
            </p>
            <div class="text-center" style="height: 100%" v-if="spinnerShow">
              <b-spinner
                variant="primary"
                label="Text Centered"
                style="margin: 10% auto"
              ></b-spinner>
            </div>
            <div
              v-for="item in userList"
              :key="item.userId"
              :style="
                'margin:0.8vh;line-height: 5vh;' +
                { marginTop: marginTop + 'vh' }
              "
            >
              <span style="color: #fff">
                <img
                  v-if="item.userPicture"
                  :src="`${item.userPicture}`"
                  alt
                  class="avatar-xs rounded-circle mr-2"
                />
                <img
                  v-else
                  src="../../assets/face.jpg"
                  alt
                  class="avatar-xs rounded-circle mr-2"
                />{{ item.nickName }}</span
              >
              <b-badge
                pill
                :variant="`${item.color}`"
                style="margin-left: 20px"
                >{{ item.flavorName }}</b-badge
              >
              <span
                style="
                  float: right;
                  line-height: 38px;
                  margin-right: 20%;
                  color: #999;
                "
                >{{ item.activeTime }}</span
              >
            </div>
          </div>
        </b-col>
        <!-- 统计新增曲线 -->
        <b-col xl="3">
          <div
            class="bodyCard"
            id="curvesStatistics"
            style="width: 100%; height: calc(34vh)"
          ></div>
        </b-col>
      </b-row>
    </div>
  </Layout>
</template>
<script>
import Layout from "../layouts/main";
import * as echarts from "echarts";
import USA from "./../../api/json/USA.json";

import {
  brandStatistics,
  userRecordsOneData,
  consumptionTwoData,
  consumptionThreeData,
  batchConsumeData,
  ikrusherUsaMapData,
  frequencyStatistics,
  frequencyStatisticsToCurve,
  flavorStatistics,
  curvesStatistics,
} from "./data";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      userList: [],
      flavorList: [],
      total: "",
      activationTotal: "",
      activationToday: "",
      btnText: false,
      spinnerShow: false,
      marginTop: 0, // 数据的margin-top
      timer: "", // 定时器
      refresh: "",
      timeZone: this.getTimeZone(),
      map: [
        {
          name: "Alabama",
          value: 1400,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Alaska",
          value: 2100,
          consumedToday: 460,
          activedToday: 0,
        },
        {
          name: "Arizona",
          value: 1853,
          consumedToday: 744,
          activedToday: 0,
        },
        {
          name: "Arkansas",
          value: 1410,
          consumedToday: 205,
          activedToday: 0,
        },
        {
          name: "California",
          value: 2234,
          consumedToday: 177,
          activedToday: 13,
        },
        {
          name: "Colorado",
          value: 1770,
          consumedToday: 695,
          activedToday: 0,
        },
        {
          name: "Connecticut",
          value: 1300,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Delaware",
          value: 1000,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "District of Columbia",
          value: 1200,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Florida",
          value: 1700,
          consumedToday: 114,
          activedToday: 0,
        },
        {
          name: "Georgia",
          value: 1200,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Hawaii",
          value: 1550,
          consumedToday: 200,
          activedToday: 0,
        },
        {
          name: "Idaho",
          value: 0,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Illinois",
          value: 2100,
          consumedToday: 643,
          activedToday: 0,
        },
        {
          name: "Indiana",
          value: 1005,
          consumedToday: 264,
          activedToday: 0,
        },
        {
          name: "Iowa",
          value: 900,
          consumedToday: 104,
          activedToday: 0,
        },
        {
          name: "Kansas",
          value: 0,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Kentucky",
          value: 1100,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Louisiana",
          value: 790,
          consumedToday: 55,
          activedToday: 0,
        },
        {
          name: "Maine",
          value: 1850,
          consumedToday: 200,
          activedToday: 0,
        },
        {
          name: "Maryland",
          value: 1300,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Massachusetts",
          value: 1400,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Michigan",
          value: 1690,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Minnesota",
          value: 1400,
          consumedToday: 540,
          activedToday: 0,
        },
        {
          name: "Mississippi",
          value: 1000,
          consumedToday: 200,
          activedToday: 0,
        },
        {
          name: "Missouri",
          value: 1040,
          consumedToday: 294,
          activedToday: 0,
        },
        {
          name: "Montana",
          value: 1683,
          consumedToday: 146,
          activedToday: 0,
        },
        {
          name: "Nebraska",
          value: 0,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Nevada",
          value: 1844,
          consumedToday: 416,
          activedToday: 0,
        },
        {
          name: "New Hampshire",
          value: 1600,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "New Jersey",
          value: 1300,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "New Mexico",
          value: 2460,
          consumedToday: 880,
          activedToday: 0,
        },
        {
          name: "New York",
          value: 2100,
          consumedToday: 808,
          activedToday: 0,
        },
        {
          name: "North Carolina",
          value: 1000,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "North Dakota",
          value: 1200,
          consumedToday: 114,
          activedToday: 0,
        },
        {
          name: "Ohio",
          value: 900,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Oklahoma",
          value: 1100,
          consumedToday: 432,
          activedToday: 0,
        },
        {
          name: "Oregon",
          value: 1855,
          consumedToday: 343,
          activedToday: 0,
        },
        {
          name: "Pennsylvania",
          value: 800,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Rhode Island",
          value: 1150,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "South Carolina",
          value: 1350,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "South Dakota",
          value: 1200,
          consumedToday: 33,
          activedToday: 0,
        },
        {
          name: "Tennessee",
          value: 1660,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Texas",
          value: 800,
          consumedToday: 65,
          activedToday: 0,
        },
        {
          name: "Utah",
          value: 1300,
          consumedToday: 98,
          activedToday: 0,
        },
        {
          name: "Vermont",
          value: 1300,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Virginia",
          value: 1880,
          consumedToday: 713,
          activedToday: 0,
        },
        {
          name: "Washington",
          value: 2034,
          consumedToday: 284,
          activedToday: 0,
        },
        {
          name: "West Virginia",
          value: 2100,
          consumedToday: 0,
          activedToday: 0,
        },
        {
          name: "Wisconsin",
          value: 900,
          consumedToday: 144,
          activedToday: 0,
        },
        {
          name: "Wyoming",
          value: 1000,
          consumedToday: 244,
          activedToday: 0,
        },
        {
          name: "Puerto Rico",
          value: 1000,
          consumedToday: 0,
          activedToday: 0,
        },
      ],
      section: [],
      brandData: {
        count: [60, 16, 44, 30, 68, 90],
      },
      flavorData: {
        count: [11, 18, 23, 20, 23, 30],
      },
      curvesData: {
        count: [185, 233, 153, 213, 150, 106],
      },
    };
  },
  created() {
    this.init();
  },
  async mounted() {
    await this.getBrandStatistics();
    this.getFrequencyStatistics();
    this.getFrequencyStatisticsToCurve();
    this.getFlavorStatistics();
    this.getCurvesStatistics();
    this.getActiveUser();
    this.getConsumptionStatistical();
    this.getUsaMap();
    this.timer = setInterval(this.showNotice, 300);
    this.refresh = setInterval(this.getActiveUser, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.refresh);
  },
  methods: {
    init() {
      // this.$api.account.findUserMenu().then((res) => {
      //   if (res.success && res.data) {
      //     this.$store.commit("setMenu", res.data);
      //   } else {
      //     if (res.message) {
      //       this.$toast(res);
      //     }
      //   }
      // });
      this.$api.account.selectAllSupplierButton().then((res) => {
        if (res.success && res.data) {
          sessionStorage["buttenpremissions"] = JSON.stringify(res.data);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
      
    },
    // 数据循环滚动
    showNotice() {
      this.marginTop -= 1;
      // this.getActiveUser();
      if (this.marginTop < -5) {
        // 滚上去后把上一条数据拉下来
        this.userList.push(this.userList[0]);
        this.userList.shift();
        this.marginTop = 0;
      }
    },
    showBrandData() {
      if (document.getElementById("brandStatistics")) {
        let myChartAge = echarts.init(
          document.getElementById("brandStatistics")
        );
        myChartAge.setOption(brandStatistics);
        // myChartAge.resize();
        window.addEventListener("resize", () => {
          // 自动渲染echarts
          myChartAge.resize();
        });
      }
    },
    showFrequencyStatistics() {
      if (document.getElementById("frequencyStatistics")) {
        let myChartAge = echarts.init(
          document.getElementById("frequencyStatistics")
        );
        myChartAge.setOption(frequencyStatistics);
        // myChartAge.resize();
        window.addEventListener("resize", () => {
          // 自动渲染echarts
          myChartAge.resize();
        });
      }
    },
    showFrequencyStatisticsToCurve() {
      if (document.getElementById("frequencyStatisticsToCurve")) {
        let myChartAge = echarts.init(
          document.getElementById("frequencyStatisticsToCurve")
        );
        myChartAge.setOption(frequencyStatisticsToCurve);
        // myChartAge.resize();
        window.addEventListener("resize", () => {
          // 自动渲染echarts
          myChartAge.resize();
        });
      }
    },
    showFlavorStatistics() {
      if (document.getElementById("flavorStatistics")) {
        let myChartAge = echarts.init(
          document.getElementById("flavorStatistics")
        );
        myChartAge.setOption(flavorStatistics);
        // myChartAge.resize();
        window.addEventListener("resize", () => {
          // 自动渲染echarts
          myChartAge.resize();
        });
      }
    },
    showCurvesStatistics() {
      if (document.getElementById("curvesStatistics")) {
        let myChartAge = echarts.init(
          document.getElementById("curvesStatistics")
        );
        myChartAge.setOption(curvesStatistics);
        // myChartAge.resize();
        window.addEventListener("resize", () => {
          // 自动渲染echarts
          myChartAge.resize();
        });
      }
    },
    showConsumptionStatistical() {
      if (document.getElementById("consumptionOne")) {
        let myChartConsumptionOne = echarts.init(
          document.getElementById("consumptionOne")
        );
        myChartConsumptionOne.setOption(userRecordsOneData);
        window.addEventListener("resize", () => {
          // 自动渲染echarts
          myChartConsumptionOne.resize();
        });
      }

      if (document.getElementById("consumptionTwo")) {
        let myChartConsumptionTwo = echarts.init(
          document.getElementById("consumptionTwo")
        );
        myChartConsumptionTwo.setOption(consumptionTwoData);
        window.addEventListener("resize", () => {
          // 自动渲染echarts
          myChartConsumptionTwo.resize();
        });
      }

      if (document.getElementById("consumptionThree")) {
        {
          let myChartConsumptionThree = echarts.init(
            document.getElementById("consumptionThree")
          );
          myChartConsumptionThree.setOption(consumptionThreeData);
          window.addEventListener("resize", () => {
            // 自动渲染echarts
            myChartConsumptionThree.resize();
          });
        }
      }
    },
    showBatchConsume() {
      if (document.getElementById("batchConsume")) {
        let myChartBatch = echarts.init(
          document.getElementById("batchConsume")
        );
        myChartBatch.setOption(batchConsumeData);
        window.addEventListener("resize", () => {
          // 自动渲染echarts
          myChartBatch.resize();
        });
      }
    },
    showUsaMap() {
      if (document.getElementById("usaMap")) {
        let myChartMap = echarts.init(document.getElementById("usaMap"));

        echarts.registerMap("USA", USA, {
          Alaska: {
            // 把阿拉斯加移到美国主大陆左下方
            left: -131,
            top: 25,
            width: 15,
          },
          Hawaii: {
            left: -110, // 夏威夷
            top: 28,
            width: 5,
          },
          "Puerto Rico": {
            // 波多黎各
            left: -76,
            top: 26,
            width: 2,
          },
        });
        myChartMap.setOption(ikrusherUsaMapData);
        myChartMap.on("click", function (data) {
          console.log(data);
        });
        // window.addEventListener("resize", () => {
        //   // 自动渲染echarts
        //   myChartMap.resize();
        // });
        window.onresize = myChartMap.resize;
      }
    },
    getTimeZone() {
      // 添加时区
      var nowTimeZone = 0 - new Date().getTimezoneOffset() / 60;
      var time = String(nowTimeZone);
      if (nowTimeZone >= 0) {
        time = "+" + time;
      }
      var splitTime = time.split(".");
      if (null != splitTime[1]) {
        if (splitTime[1] > 0) {
          time = splitTime[0] + ":" + splitTime[1] * 6;
        } else {
          time = splitTime[0] + ":0" + splitTime[1] * 6;
        }
      } else {
        time += ":00";
      }
      return time;
    },
    getActiveUser() {
      this.spinnerShow = true;
      let params = {};
      if (process.env.NODE_ENV == "prod") {
        params = { supplierId: localStorage.supplierId };
      } else {
        params = { timeZone: this.timeZone };
      }
      this.$api.home.selectActiveUserRecords(params).then((res) => {
        if (res.success) {
          let badgeList = [
            { color: "primary" },
            { color: "success" },
            { color: "info" },
            { color: "warning" },
            { color: "danger" },
            { color: "primary" },
            { color: "success" },
            { color: "info" },
            { color: "warning" },
            { color: "danger" },
          ];
          res.data.forEach((item, i) => {
            badgeList.forEach((ele, e) => {
              if (i == e) {
                item.color = ele.color;
              }
            });
          });
          this.userList = res.data;
          this.spinnerShow = false;
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    getConsumptionStatistical() {
      userRecordsOneData.series[0].data = [];
      userRecordsOneData.series[0].axisLine.lineStyle.color = [];
      consumptionTwoData.series[0].data = [];
      consumptionTwoData.series[0].axisLine.lineStyle.color = [];
      consumptionThreeData.series[0].data = [];
      this.$api.home
        .selectUserRecord({
          supplierId: localStorage.supplierId,
        })
        .then((res) => {
          if (res.success) {
            userRecordsOneData.series[0].data.push(res.data[0]);
            userRecordsOneData.series[0].axisLine.lineStyle.color = [
              [res.data[0].ratio, "rgba(2, 164, 153,1)"],
              [1, "rgba(150,150, 150,0.3)"],
            ];
            consumptionTwoData.series[0].data.push(res.data[1]);
            consumptionTwoData.series[0].axisLine.lineStyle.color = [
              [res.data[1].ratio, "rgba(2, 164, 153,1)"],
              [1, "rgba(150,150, 150,0.3)"],
            ];

            consumptionThreeData.series[0].data.push(res.data[2]);
            consumptionThreeData.series[0].axisLine.lineStyle.color = [
              [res.data[2].ratio, "rgba(2, 164, 153,1)"],
              [1, "rgba(150,150, 150,0.3)"],
            ];

            this.showConsumptionStatistical();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
    },
    getBatchConsume() {
      batchConsumeData.xAxis.data = [];
      batchConsumeData.series = [];
      this.$api.home
        .selectBatchConsumed({
          supplierId: localStorage.supplierId,
        })
        .then((res) => {
          if (res.success) {
            batchConsumeData.xAxis.data = res.data.xaxis;
            res.data.series.forEach((item) => {
              item.type = "bar";
              item.barWidth = 14;
              item.stack = "batch";
              if (item.name == "Remaining") {
                item.itemStyle = {
                  emphasis: {
                    barBorderRadius: [10, 10, 10, 10],
                  },
                  normal: {
                    //柱形图圆角，初始化效果
                    barBorderRadius: [10, 10, 1, 1],
                    color: "rgba(86, 97, 181,1)",
                  },
                };
              } else if (item.name == "Activated") {
                item.itemStyle = {
                  normal: {
                    color: "rgba(9, 140, 134,1)",
                  },
                };
              } else if (item.name == "Consumed") {
                item.itemStyle = {
                  normal: {
                    color: "rgba(198, 88, 96,1)",
                  },
                };
              }
            });
            batchConsumeData.series = res.data.series.reverse();
            this.showBatchConsume();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
    },
    async getBrandStatistics() {
      if (localStorage.supplierActualId == 828) {
       const res =  await this.$api.home.selectBrandsLastDays({ type: 2 })
          if (res.success) {
            this.section = res.data.ageSection;
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        brandStatistics.xAxis.data = this.section;
        brandStatistics.series[0].data = this.brandData.count;
        this.showBrandData();
      } else {
        brandStatistics.xAxis.data = [];
        brandStatistics.series[0].data = [];
        const res = await this.$api.home.selectBrandsLastDays({ type: 1 });
        if (res.success) {
          brandStatistics.xAxis.data = res.data.ageSection;
          brandStatistics.series[0].data = res.data.count;
          this.showBrandData();
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      }
    },
    getFrequencyStatistics() {
      frequencyStatistics.xAxis.data = [];
      frequencyStatistics.series[0].data = [];
      this.$api.home.selectBrandsLastDays({ type: 3 }).then((res) => {
        if (res.success) {
          frequencyStatistics.xAxis.data = res.data.ageSection;
          frequencyStatistics.series[0].data = res.data.count;
          this.showFrequencyStatistics();
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    getFrequencyStatisticsToCurve() {
      frequencyStatisticsToCurve.xAxis.data = [];
      frequencyStatisticsToCurve.series[0].data = [];
      this.$api.home.selectBrandsLastDays({ type: 3 }).then((res) => {
        if (res.success) {
          frequencyStatisticsToCurve.xAxis.data = res.data.ageSection;
          frequencyStatisticsToCurve.series[0].data = res.data.count;
          this.showFrequencyStatisticsToCurve();
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    getFlavorStatistics() {
      if (localStorage.supplierActualId == 828) {
        flavorStatistics.xAxis.data = this.section;
        flavorStatistics.series[0].data = this.flavorData.count;
        this.showFlavorStatistics();
      } else {
        flavorStatistics.xAxis.data = [];
        flavorStatistics.series[0].data = [];
        this.$api.home.selectBrandsLastDays({ type: 2 }).then((res) => {
          if (res.success) {
            flavorStatistics.xAxis.data = res.data.ageSection;
            flavorStatistics.series[0].data = res.data.count;
            this.showFlavorStatistics();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      }
    },
    getCurvesStatistics() {
      if (localStorage.supplierActualId == 828) {
        curvesStatistics.xAxis.data = this.section;
        curvesStatistics.series[0].data = this.curvesData.count;
        this.showCurvesStatistics();
      } else {
        curvesStatistics.xAxis.data = [];
        curvesStatistics.series[0].data = [];
        this.$api.home.selectBrandsLastDays({ type: 4 }).then((res) => {
          if (res.success) {
            curvesStatistics.xAxis.data = res.data.ageSection;
            curvesStatistics.series[0].data = res.data.count;
            this.showCurvesStatistics();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      }
    },

    getUsaMap() {
      // if ("WebSocket" in window) {
      //   alert("您的浏览器支持 WebSocket!");

      //   // 打开一个 web socket
      //   // var ws = new WebSocket("ws://localhost:9998/echo");
      //   let url = baseUrl.replace("http://", "ws://");
      //   var ws = new WebSocket(
      //     url +
      //       "/business/selectUsaMapCartridgeDetails?" +
      //       "supplierId=" +
      //       localStorage.supplierId
      //   );

      //   ws.onopen = function() {
      //     // Web Socket 已连接上，使用 send() 方法发送数据
      //     ws.send();
      //     alert("数据发送中...");
      //   };

      //   ws.onmessage = function(evt) {
      //     // var received_msg = evt.data;
      //     console.log(evt.data);
      //     alert("数据已接收...");
      //   };

      //   ws.onclose = function() {
      //     // 关闭 websocket
      //     alert("连接已关闭...");
      //   };
      // } else {
      //   // 浏览器不支持 WebSocket
      //   alert("您的浏览器不支持 WebSocket!");
      // }
      if (localStorage.supplierActualId == 828) {
        ikrusherUsaMapData.series[0].data = this.map;
        ikrusherUsaMapData.visualMap.max = 2000;
        this.total = 28455;
        this.$api.home.selectMapDetails({}).then((res) => {
          if (res.success) {
            this.activationTotal = res.data.activationTotal + 4837;
            this.activationToday = res.data.activationToday+88;
            this.showUsaMap();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      } else {
        ikrusherUsaMapData.series[0].data = [];
        this.$api.home.selectMapDetails({}).then((res) => {
          if (res.success) {
            this.total = res.data.total;
            this.activationTotal = res.data.activationTotal;
            this.activationToday = res.data.activationToday;
            ikrusherUsaMapData.series[0].data = res.data.usaMapVo;
            ikrusherUsaMapData.visualMap.max = res.data.valueInterval;
            this.showUsaMap();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      }
    },
    requestFullScreen() {
      var element = document.getElementById("fullScreenpage");
      var requestMethod =
        element.requestFullScreen ||
        element.webkitRequestFullScreen ||
        element.mozRequestFullScreen ||
        element.msRequestFullScreen;
      if (requestMethod) {
        requestMethod.call(element);
      } else if (typeof window.ActiveXObject !== "undefined") {
        var wscript = new ActiveXObject("WScript.Shell");
        if (wscript !== null) {
          wscript.SendKeys("{F11}");
        }
      }
      this.btnText = true;
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      this.btnText = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background-color: rgba(33, 39, 53, 1);
  height: calc(100vh);
  margin: 0 -24px;
  //   .title {
  //     .card-body {
  //       padding: 0;
  //     }
  //     .headline {
  //       position: absolute;
  //       margin-bottom: 0;
  //       font-size: 28px;
  //       line-height: 50px;
  //       top: 10px;
  //       width: 100%;
  //       text-align: center;
  //       background: linear-gradient(to bottom, white, rgba(55, 135, 250));
  //       background-clip: text;
  //       -webkit-text-fill-color: transparent;
  //     }
  //   }
  .title {
    text-align: center;
    width: 100%;
    background: url("../../assets/title.jpg");
    background-size: 100% auto;
    background-position: 0 0;
    background-repeat: no-repeat;
    position: relative;
    .headline {
      font-size: 3.6vh;
      line-height: 8vh;
      background: linear-gradient(to bottom, white, rgba(55, 135, 250, 1));
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .fullscreen {
      color: #fff;
      font-style: normal;
      font-size: 2.2vh;
      cursor: pointer;
      position: absolute;
      right: 1vh;
      top: 1vh;
    }
  }
  .bodyCard {
    // background-color: rgba(37, 43, 59, 1);
    border: 1px solid rgba(44, 50, 77, 1);
    width: 100%;
    // height: 100%;
    margin: 0 auto;
    padding: 2px;
    background: linear-gradient(
          to left,
          rgba(98, 110, 212, 1),
          rgba(98, 110, 212, 1)
        )
        no-repeat left top,
      linear-gradient(to left, rgba(98, 110, 212, 1), rgba(98, 110, 212, 1))
        no-repeat right top,
      linear-gradient(to left, rgba(98, 110, 212, 1), rgba(98, 110, 212, 1))
        no-repeat left bottom,
      linear-gradient(to left, rgba(98, 110, 212, 1), rgba(98, 110, 212, 1))
        no-repeat left bottom,
      linear-gradient(to left, rgba(98, 110, 212, 1), rgba(98, 110, 212, 1))
        no-repeat right bottom,
      linear-gradient(to left, rgba(98, 110, 212, 1), rgba(98, 110, 212, 1))
        no-repeat right bottom,
      rgba(37, 43, 59, 1);
    background-size: 4vh 3px, 4vh 3px, 2vh 2px, 2px 2vh, 2vh 2px, 2px 2vh;
    // &:before {
    //   content: "";
    //   position: absolute;
    //   height: 3px;
    //   width: 40px;
    //   background-color: rgba(98, 110, 212,1);
    // }
  }
}
</style>
