<!--
 * @Author: Code-HHX
 * @Date: 2022-03-28 16:21:12
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-01 17:34:42
 * @Description: 积分商品管理列表
-->
<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<b-row>
			<b-col lg="12">
				<!-- 查询条件 -->
				<b-card>
					<b-row>
						<!-- Commodity Name 商品名称 -->
						<b-col lg="3">
							<b-form-group
								label="Commodity Name"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="commodity-name"
							>
								<b-form-input
									id="commodity-name"
									v-model="search_condition.productName"
									trim
								></b-form-input>
							</b-form-group>
						</b-col>
						<!-- Commodity No 商品编号 -->
						<b-col lg="3">
							<b-form-group
								label="Commodity No"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="commodity-no"
							>
								<b-form-input
									id="commodity-no"
									v-model="search_condition.productNumber"
									trim
								></b-form-input>
							</b-form-group>
						</b-col>
						<!-- Can be exchanged Time 上架时间 -->
						<b-col lg="4">
							<b-form-group
								label="Can be exchanged Time"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="launch-time"
							>
								<date-picker
									v-model="launchTime"
									format="MM/DD/YYYY HH:mm:ss"
									type="datetime"
									range
									append-to-body
									lang="en"
									placeholder="Select date"
								></date-picker>
							</b-form-group>
						</b-col>
						<b-col lg="2">
							<b-button variant="primary" @click="search()"
								>Search</b-button
							>
						</b-col>
					</b-row>
				</b-card>

				<b-card>
					<b-button-group>
						<b-button
							v-has="'integralCommodity::Create'"
							@click="create()"
							variant="primary"
							>Create</b-button
						>
					</b-button-group>
					<!-- table -->
					<div class="table-responsive mb-0">
						<b-table
							show-empty
							empty-text="No records"
							id="table"
							head-variant="light"
							ref="table"
							:busy="isBusy"
							:striped="true"
							:items="provider"
							:fields="fields"
							:per-page="search_condition.pageSize"
							:current-page="search_condition.pageNum"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:filter="filter"
							:filter-includedfields="filterOn"
							@filtered="onFiltered"
						>
							<template v-slot:table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>

							<template v-slot:cell(index)="data">
								{{
									data.index +
										(search_condition.pageNum - 1) *
											search_condition.pageSize +
										1
								}}
							</template>

							<template v-slot:cell(actions)="row">
								<b-button-group>
									<b-button
										v-has="'integralCommodity::Details'"
										size="sm"
										variant="info"
										@click="
											details(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
										>Details</b-button
									>
									<b-button
										v-has="'integralCommodity::Modify'"
										size="sm"
										variant="primary"
										@click="
											modify(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
										>Modify</b-button
									>
									<b-button
										v-show="row.item.status == 1"
										v-has="'integralCommodity::Disable'"
										size="sm"
										variant="secondary"
										@click="
											onClickDisabled(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
										>Disable</b-button
									>
									<b-button
										v-show="row.item.status == 0"
										v-has="'integralCommodity::Disable'"
										size="sm"
										variant="success"
										@click="
											onClickEnabled(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
										>Enable</b-button
									>
								</b-button-group>
							</template>
						</b-table>
					</div>
					<!-- end table -->
					<br />
					<!-- pagination -->
					<b-row v-if="has_data">
						<b-col>
							<div
								class="dataTables_paginate paging_simple_numbers float-right"
							>
								<ul class="pagination mb-0" v-if="rows != 0">
									<b-pagination
										v-model="search_condition.pageNum"
										:per-page="10"
										:total-rows="rows"
										aria-controls="table"
									></b-pagination>
								</ul>
							</div>
						</b-col>
					</b-row>
					<!-- end pagination -->
				</b-card>
			</b-col>
		</b-row>
		<!-- end main -->
	</Layout>
</template>
<script>
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import DatePicker from 'vue2-datepicker'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'

/**
 * Device component
 */
export default {
	components: {
		Layout,
		PageHeader,
		DatePicker,
		timeZoneConversion,
	},
	data() {
		return {
			title: 'Integral Commodity',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'Marketing Manage',
					// href: "/"
				},
				{
					text: 'Integral Commodity',
					active: true,
				},
			],
			tableData: [],
			totalRows: 0,
			currentPage: 1,
			perPage: 10,
			pageOptions: [10, 25, 50, 100],
			filter: null,
			filterOn: [
				'deviceNumber',
				'deviceCoding',
				'deviceLocation',
				'activationTime',
			],
			sortBy: 'id',
			sortDesc: false,
			fields: [
				{ key: 'index', label: '#', class: 'text-center' },
				{
					key: 'productName', //商品名称
					label: 'Commodity Name',
					class: 'text-center',
				},
				{
					key: 'productNumber', //商品编号
					label: 'Commodity No',
					class: 'text-center',
				},
				{
					key: 'convertibleQuantity', //可兑换总数量
					label: 'Convertible Quantity',
					class: 'text-center',
				},
				{
					key: 'remainingRedeemableQuantity', //剩余可兑换数量
					label: 'Remaining Quantity',
					class: 'text-center',
				},
				{
					key: 'status', //是否展示
					label: 'Display at Exchange Center',
					formatter: (value) => {
						return value == 0 ? 'No' : value == 1 ? 'Yes' : ''
					},
					class: 'text-center',
				},
				//上架时间
				{
					key: 'launchTime',
					label: 'Can be exchanged Time',
					class: 'text-center',
				},
				{ key: 'actions', label: 'Opreration', class: 'text-center' },
			],
			search_condition: {
				pageNum: 1,
				pageSize: 10,
				productName: '', //商品名称
				productNumber: '', //商品编号
				startTime: '', //商品上架时间
				endTime: '', //商品下架时间
			},
			launchTime: ['', ''], //上架时间
			isBusy: false,
			has_data: false,
			types: {
				status: [
					{ text: 'Please select', value: '', disabled: false },
					{ text: 'Disabled', value: 0, disabled: false },
					{ text: 'Enable', value: 1, disabled: false },
				],
			},
		}
	},
	watch: {
		launchTime: function(val) {
			this.search_condition.startTime = val[0]
				? timeZoneConversion.queryTime(val[0])
				: val[0]
			if (val[1]) {
				this.search_condition.endTime = timeZoneConversion.queryTime(
					val[1]
				)
			} else {
				this.search_condition.endTime = val[1]
			}
		},
	},
	computed: {
		/**
		 * 计算rows
		 */
		rows() {
			return this.totalRows
		},
	},
	created() {},
	mounted() {},
	methods: {
		provider(ctx) {
			this.isBusy = true
			return this.$api.Marketing.selectExchangeSettingsList(
				this.search_condition,
				ctx
			)
				.then((x) => {
					this.isBusy = false
					if (x.total) {
						this.has_data = true
						this.totalRows = x.total
						x.data.forEach((element) => {
							element.launchTime = ''
							let startTime = timeZoneConversion.getLocalTime(
								element.startTime
							)
							let endTime = timeZoneConversion.getLocalTime(
								element.endTime
							)
							element.launchTime = startTime + ' ~ ' + endTime
							element.createTime = timeZoneConversion.getLocalTime(
								element.createTime
							)
						})
						return x.data
					} else {
						this.has_data = false
						return []
					}
				})
				.catch((e) => {
					this.isBusy = false
					this.has_data = false
					return []
				})
		},
		create() {
			this.$router.push({
				name: 'integralCommodityAdd',
			})
		},
		modify(item, index, button) {
			this.$router.push('integralCommodityEdit?id=' + item.id)
		},
		details(item, index, button) {
			this.$router.push('integralCommodityInfo?id=' + item.id)
		},
		search() {
			this.search_condition.pageNum = 1
			this.$refs.table.refresh()
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		//禁用积分兑换商品
		onClickDisabled(item, index, row) {
			let postData = {
				id: item.id,
				status: 0,
			}
			this.$api.Marketing.integralEnableOrDisableById(postData)
				.then((res) => {
					console.log(res)
					if (res.success && res.message == 'Disable success.') {
						this.$toast(res)
						//this.device_provider();
						this.$refs.table.refresh()
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
				.catch((error) => {})
		},
		//启用积分兑换商品
		onClickEnabled(item, index, row) {
			let postData = {
				id: item.id,
				status: 1,
			}
			this.$api.Marketing.integralEnableOrDisableById(postData)
				.then((res) => {
					console.log(res)
					if (res.success && res.message == 'Enable success.') {
						this.$toast(res)
						//this.device_provider();
						this.$refs.table.refresh()
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
				.catch((error) => {})
		},
	},
}
</script>
